import React, {Component, useContext, useEffect, useState, useRef, createRef, useMemo, memo } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumbContext from "../contexts/breadCrump";
import { Link } from "react-router-dom";
import API2 from "../components/api2";
import {
    Table,
    Input,
    Button,
    Card,
    Avatar,
    Progress,
    Tooltip,
    Select,
    Checkbox,
    DatePicker,
    Modal,
    Dropdown,
    Menu
} from 'antd';

import { DownOutlined } from '@ant-design/icons';
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";

import useToken from "../hooks/useToken";
import SearchTable from "../components/SearchTable";

import ReactDOM from 'react-dom';
import { Bar,Column,Line } from '@ant-design/plots';

import "../css/dashboard.css";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import moment from 'moment';
import dayjs from "dayjs";
import axios from "axios/index";
import ComparisonGraph from "../graphs/comparisonChart";


const { Option } = Select;

const ReactGridLayout = WidthProvider(RGL);

//navigator.clipboard.writeText('Copy this text to clipboard')

export default function AdminDashboardMembers(props) {

    const [data, setData] = useState(false);
    const AP = API2();
    const history = useHistory();
    const { user, setUser } = useToken();

    const [regByType, setRegByType] = useState('region');
    const [regBySplit, setRegBySpit] = useState('');

    const [regByData, setRegByData] = useState([]);
    const [regionRegistrationData, setRegionRegistrationData] = useState([]);
    const [regionRegistrationSplitData, setRegionRegistrationSplitData] = useState([]);
    const [totalRegistrations, setTotalRegistrations] = useState({monthly:0,yearly:0});
    const [userStats, setUserStats] = useState([]);
    const [newRegistrationData, setNewRegistrationData] = useState([]);
    const [newRegistrationTableData, setNewRegistrationTableData] = useState([]);

    const [incomeByType, setIncomeByType] = useState('region');
    const [incomeData, setIncomeData] = useState([]);
    const [countryIncomeData, setCountryIncomeData] = useState([]);
    const [incomeMonth, setIncomeMonth] = useState('');
    const [incomeYear, setIncomeYear] = useState('');

    const [yearlyDueData, setYearlyDueData] = useState([]);
    const [overdueSummaryData, setOverdueSummaryData] = useState([]);
    const [debtSummaryData, setDebtSummaryData] = useState([]);




    const [isLoading, setIsLoading] = useState(true);
    const [dashboardLayout, setDashboardLayout] = useState([]);
    const [dashboardEdit, setDashboardEdit] = useState(false);
    const [dashboardVerticalCompact, setdashboardVerticalCompact] = useState(true);
    const [dashboardAddModal, setDashboardAddModal] = useState(false);
    const [gridRef, setGridRef] = useState(true);
    const [overdueMemberPaginate, setOverdueMemberPaginate] = useState(true);

	const setBreadCrumbs = useContext(BreadCrumbContext);

	function saveDashboard(layout){

	    if(isLoading) return true;

	    /*
        if (global.localStorage){
            global.localStorage.setItem(
                user.id+":dashboard",
                JSON.stringify({
                    layout: layout,
                    verticalCompact: dashboardVerticalCompact,
                    overdueMemberPaginate: overdueMemberPaginate
                })
            );
        }
        */

        AP.post('/my/dashboard',{
                "dashboard" : {
                    for: 'members',
                    layout: layout,
                    verticalCompact: dashboardVerticalCompact,
                    overdueMemberPaginate: overdueMemberPaginate
                }
            })
            .then(function(res){

            });
    }

    useEffect(() => {
        if(!regByData){
            return true;
        }

        let RRD = {};

        if(regByType == 'region'){

            let currentStatuses = {};
            let currentPeriods = {};


            let countryData      = {};
            let regionNames = {
                'AS' : 'Asia',
                'AU' : 'Australia',
                'CA' : 'Canada',
                'EU' : 'Europe',
                'NZ' : 'New Zealand',
                'US' : 'United Sates Of America'
            };
            for (const [k, v] of Object.entries(regionNames)) {
                countryData[k] = {
                    name:v,
					count:0,
					periodTotal:0,
                    statuses:{},
                    periods:{}
                };
            }


            for(let d of regByData){

                if(['AU','CA','NZ'].includes(d.iso)){
					countryData[d.iso].count += d.count;
					countryData[d.iso].periodTotal += d.periodTotal;
                    currentStatuses = countryData[d.iso].statuses;
                    currentPeriods  = countryData[d.iso].periods;
                }
                else if(['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','GB'].includes(d.iso)){
                    countryData['EU'].count += d.count;
					countryData['EU'].periodTotal += d.periodTotal;
                    currentStatuses = countryData['EU'].statuses;
                    currentPeriods  = countryData['EU'].periods;
                }
                else if(['AF','AM','AZ','BH',',BT','BN','KH','CN','CX','CC','IO','GE','HK','IN','ID','IR','IQ','IL','JP','JO','KZ','KW','KG','LA','LB','MO','MY','MV','MN','MM','NP','KP','OM','PK','PS','PH','QA','SA','SG','KR','LK','SY','TW','TJ','TH','TR','TM','AE','UZ','VN','YE'].includes(d.iso)){
                    countryData['AS'].count += d.count;
					countryData['AS'].periodTotal += d.periodTotal;
                    currentStatuses = countryData['AS'].statuses;
                    currentPeriods  = countryData['AS'].periods;
                }
                else{
                    countryData['US'].count += d.count;
					countryData['US'].periodTotal += d.periodTotal;
                    currentStatuses = countryData['US'].statuses;
                    currentPeriods  = countryData['US'].periods;
                }

                for (const [s, c] of Object.entries(d.statuses)) {
                    if(typeof currentStatuses[s] == 'undefined' ){
                        currentStatuses[s] = 0;
                    }
                    currentStatuses[s] += c;
                }
                for (const [s, c] of Object.entries(d.periods)) {
                    if(typeof currentPeriods[s] == 'undefined' ){
                        currentPeriods[s] = 0;
                    }
                    currentPeriods[s] += c;
                }

            }

            RRD = Object.values(countryData);
        }else{
            RRD = regByData;
        }

        let splitRRD = [];
        for(let i of RRD){
            for (const [k, v] of Object.entries(i.periods)) {
                splitRRD.push({
                    "name": i.name,
                    "count": v,
                    "period": k
                });
            }
        }

        setRegionRegistrationData(RRD);
        setRegionRegistrationSplitData(splitRRD);

    }, [regByData,regByType]);


    useEffect(() => {

        if(!incomeData || !incomeData.length){
            return true;
        }

        let newData = [];

        if(incomeByType == 'region'){

            let asYA = 0;
            let asMA = 0;
            let auYA = 0;
            let auMA = 0;
            let caYA = 0;
            let caMA = 0;
            let euYA = 0;
            let euMA = 0;
            let nzYA = 0;
            let nzMA = 0;
            let usYA = 0;
            let usMA = 0;
            let asYAA = 0;
            let asMAA = 0;
            let auYAA = 0;
            let auMAA = 0;
            let caYAA = 0;
            let caMAA = 0;
            let euYAA = 0;
            let euMAA = 0;
            let nzYAA = 0;
            let nzMAA = 0;
            let usYAA = 0;
            let usMAA = 0;


            let asMC = 0;
            let asYC = 0;
            let auMC = 0;
            let auYC = 0;
            let caMC = 0;
            let caYC = 0;
            let euMC = 0;
            let euYC = 0;
            let nzMC = 0;
            let nzYC = 0;
            let usMC = 0;
            let usYC = 0;

            for(let d of incomeData){
                if(d.iso == 'AU'){
                    auMA += d.monthly_amount;
                    auYA += d.yearly_amount;
                    auMAA += d.monthly_amount_actual;
                    auYAA += d.yearly_amount_actual;
                    auMC += d.monthly_count;
                    auYC += d.yearly_count;
                }
                else if(d.iso == 'CA'){
                    caMA += d.monthly_amount;
                    caYA += d.yearly_amount;
                    caMAA += d.monthly_amount_actual;
                    caYAA += d.yearly_amount_actual;
                    caMC += d.monthly_count;
                    caYC += d.yearly_count;
                }
                else if(['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','GB'].includes(d.iso)){
                    euMA += d.monthly_amount;
                    euYA += d.yearly_amount;
                    euMAA += d.monthly_amount_actual;
                    euYAA += d.yearly_amount_actual;
                    euMC += d.monthly_count;
                    euYC += d.yearly_count;
                }
                else if(d.iso == 'NZ'){
                    nzMA += d.monthly_amount;
                    nzYA += d.yearly_amount;
                    nzMAA += d.monthly_amount_actual;
                    nzYAA += d.yearly_amount_actual;
                    nzMC += d.monthly_count;
                    nzYC += d.yearly_count;
                }
                else if(['AF','AM','AZ','BH',',BT','BN','KH','CN','CX','CC','IO','GE','HK','IN','ID','IR','IQ','IL','JP','JO','KZ','KW','KG','LA','LB','MO','MY','MV','MN','MM','NP','KP','OM','PK','PS','PH','QA','SA','SG','KR','LK','SY','TW','TJ','TH','TR','TM','AE','UZ','VN','YE'].includes(d.iso)){
                    asMA += d.monthly_amount;
                    asYA += d.yearly_amount;
                    asMAA += d.monthly_amount_actual;
                    asYAA += d.yearly_amount_actual;
                    asMC += d.monthly_count;
                    asYC += d.yearly_count;
                }
                else{
                    usMA += d.monthly_amount;
                    usYA += d.yearly_amount;
                    usMAA += d.monthly_amount_actual;
                    usYAA += d.yearly_amount_actual;
                    usMC += d.monthly_count;
                    usYC += d.yearly_count;
                }

            }

            newData = [
                {
                    name: 'Asia',
                    monthly_amount:asMA,
                    yearly_amount:asYA,
                    monthly_amount_actual:asMAA,
                    yearly_amount_actual:asYAA,
                    monthly_count:asMC,
                    yearly_count:asYC
                },
                {
                    name: 'Australia',
                    monthly_amount:auMA,
                    yearly_amount:auYA,
                    monthly_amount_actual:auMAA,
                    yearly_amount_actual:auYAA,
                    monthly_count:auMC,
                    yearly_count:auYC
                },
                {
                    name: 'Canada',
                    monthly_amount:caMA,
                    yearly_amount:caYA,
                    monthly_amount_actual:caMAA,
                    yearly_amount_actual:caYAA,
                    monthly_count:caMC,
                    yearly_count:caYC
                },
                {
                    name: 'Europe',
                    monthly_amount:euMA,
                    yearly_amount:euYA,
                    monthly_amount_actual:euMAA,
                    yearly_amount_actual:euYAA,
                    monthly_count:euMC,
                    yearly_count:euYC
                },
                {
                    name: 'New Zealand',
                    monthly_amount:nzMA,
                    yearly_amount:nzYA,
                    monthly_amount_actual:nzMAA,
                    yearly_amount_actual:nzYAA,
                    monthly_count:nzMC,
                    yearly_count:nzYC
                },
                {
                    name: 'United Sates Of America',
                    monthly_amount:usMA,
                    yearly_amount:usYA,
                    monthly_amount_actual:usMAA,
                    yearly_amount_actual:usYAA,
                    monthly_count:usMC,
                    yearly_count:usYC
                }
            ];


        }else{
            newData = incomeData;
        }

        let totalRow = {
            monthly_count: 0,
            yearly_count: 0
        };
        for (let r of newData) {
            totalRow.monthly_count += r.monthly_count;
            totalRow.yearly_count += r.yearly_count;
        }


        setCountryIncomeData(newData);

        setTotalRegistrations({monthly:totalRow.monthly_count,yearly:totalRow.yearly_count});

    }, [incomeData,incomeByType]);

    useEffect(() => {
        saveDashboard(dashboardLayout);
    }, [overdueMemberPaginate]);




    useEffect(() => {

        setBreadCrumbs([<span><i className="fas fa-home"></i> Dashboard</span>]);

        AP.get('/dashboard?for=members')
            .then(function (res) {

                setData(res);
                if(res.invite_url) res.invite_url = window.location.origin+res.invite_url;

                setRegByData(res.regionSubscriptions);

                setIncomeData(res.countryIncome);

                setUserStats(res.userStats);


                setYearlyDueData(res.yearlyDue);

                setOverdueSummaryData(res.overdueData);
                setDebtSummaryData(res.debtData);


                let formattedNewRegistrations = [];
                let formattedNewRegistrationsTable = {};
                for(const [k, nrM] of Object.entries(res.newRegistrations)){
                    for(const [date, count] of Object.entries(nrM)) {
                        formattedNewRegistrations.push({
                            category: k,
                            date: date,
                            count: count
                        });

                        if(formattedNewRegistrationsTable[date]){
                            formattedNewRegistrationsTable[date][k] = count;
                        }else{
                            formattedNewRegistrationsTable[date] = {};
                            formattedNewRegistrationsTable[date].month = date;
                            formattedNewRegistrationsTable[date][k] = count;
                        }

                    }
                }

                setNewRegistrationData(formattedNewRegistrations);
                setNewRegistrationTableData(Object.values(formattedNewRegistrationsTable));





                if(res.dashboard && res.dashboard.members){
                    const data = res.dashboard.members;

                    setOverdueMemberPaginate(data.overdueMemberPaginate);

                    const dashboardLayoutByY = {};
                    for(const l of data.layout){
                        let y = l.y;
                        while(dashboardLayoutByY[y]){
                            y++;
                        }
                        dashboardLayoutByY[y] = l;
                    }

                    let z = Object.keys(dashboardLayoutByY).length;
                    for(const k in dashboardLayoutByY){
                        dashboardLayoutByY[k].zIndex = z;
                        z--;
                    }
                    setDashboardLayout(data.layout);
                }else{
                    setDashboardLayout(getDefaultLayout());
                }


                setIsLoading(false);
            });
    }, []);




    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    let overdueColumns = [];
    let overdueSummaryColumns = [];
    let statusColumns = [];
    let periodColumns = [];
    let yearlyDueColumns = [];
    let incomeColumns = [];
    let newRegistrationColumns = [];
    if(user.type=="admin") {
        overdueColumns = [
            {
                title: 'Name',
                key: 'users.first_name',
                dataIndex: 'users.first_name',
                sorter: (a, b) => {
                    return (!a.first_name) ? -1 : a.first_name.localeCompare(b.first_name)
                },
                align: 'left',
                render: (text, record) => (
					<div className="container">
                        <div className="overdueMembers-name">
                            <span>
                                <Avatar src={(record.image) ? record.image.endpoint : null} icon={<i className="far fa-user"></i>} size="large"/>
                            </span>
                            <span className="avatar-name">{record.first_name+" "+record.last_name}</span>
                        </div>
                    </div>
                )
            },
            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                sorter: (a, b) => {
                    return (!a.email) ? -1 : a.email.localeCompare(b.email)
                },
                align: 'left',
				render: (text, record) => (
					<div>
						<div style={{textOverflow:"ellipsis",overflow:"hidden", whiteSpace:"nowrap"}}>
							{record.email}
						</div>
                    </div>
				)
            },
            {
                title: 'Plan',
                key: 'plan',
                dataIndex: 'plan',
                sorter: true,
                align: 'left',
                width: 110
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                sorter: true,
                align: 'left',
                width: '150px'
            },
            {
                title: 'Missing',
                key: 'missing',
                dataIndex: 'missing',
                width: 110,
        //        sortOrder: 'descend',
                sorter: true,
                render: function(text, record){
                    return <div style={{textAlign:"left"}}>
                        <div style={{display:"flex"}}>
                            <div style={{width:76}}>Payments:</div><div>{record.missing}</div>
                        </div>
                    </div>
                }
            },
            {
                title: 'Amount',
                key: 'amount',
                dataIndex: 'amount',
                width: 100,
                sorter: true,
                render: function(text, record){
                    return <div>${record.amount / 100}</div>
                }
            },
            {
                title: 'Attempts',
                key: 'attempts',
                dataIndex: 'attempts',
                width: 110,
                defaultSortOrder: 'descend',
                sorter: true
            },
            {
                title: 'Actions',
                key: 'actions',
                width: '100px',
                render: (text, record) => (
                    <div>
                        <Link to={"/user/"+record.id}>
                            <span><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></span>
                        </Link>
                    </div>
                ),
            },
        ];

        overdueSummaryColumns = [
            {
                title: 'Billing Period',
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => {
                    return (!a.name) ? -1 : a.name.localeCompare(b.name)
                },
                align: 'left',
                render: (text, record) => (
                    record.name
                )
            },{
                title: 'Missing Payments',
                key: 'invoiceCount',
                dataIndex: 'invoiceCount',
                sorter: (a, b) => a.invoiceCount - b.invoiceCount,
                align: 'left',
                render: (text, record) => (
                    record.invoiceCount
                )
            },{
                title: 'User Count',
                key: 'userCount',
                dataIndex: 'userCount',
                sorter: (a, b) => a.userCount - b.userCount,
                align: 'left',
                render: (text, record) => (
                    record.userCount
                )
            },{
                title: 'Amount Overdue',
                key: 'amountOverdue',
                dataIndex: 'amountOverdue',
                sorter: (a, b) => a.amountOverdue - b.amountOverdue,
                align: 'left',
                render: (text, record) => (
                    '$'+(record.amountOverdue/100)
                )
            },{
                title: 'Fees',
                key: 'feesTotal',
                dataIndex: 'feesTotal',
                sorter: (a, b) => a.feesTotal - b.feesTotal,
                align: 'left',
                render: function(text, record) {
                    if(!record.feeCount) return "";
                    return <div>${record.feesTotal/100} ({record.feeCount} x ${(record.feesTotal/record.feeCount)/100})</div>
                }
            }
        ];

        statusColumns = [
            {
                title: 'Country',
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => {
                    return (!a.name) ? -1 : a.name.localeCompare(b.name)
                },
                align: 'left',
                render: (text, record) => (
                    <div>{record.name}</div>
                )
            },
			{
				title: 'Active',
				align: 'left',
				width: 100,
				render: (text, record) => (
					<div>{record.statuses.active}</div>
				)
			},
            /*
			{
				title: 'Completed',
				align: 'left',
				width: 100,
				render: (text, record) => (
					<div>{record.statuses.completed}</div>
				)
			},
			*/
            {
                title: 'On Hold',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.statuses["on-hold"]}</div>
                )
            },
            {
                title: 'Pending Cancel',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.statuses["pending-cancel"]}</div>
                )
            },
            /*
            {
                title: 'Canceled',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.statuses.cancelled}</div>
                )
            },
            */
            {
                title: 'Pending Deregistered',
                align: 'left',
                width: 105,
                render: (text, record) => (
                    <div>{record.statuses["pending-deregister"]}</div>
                )
            },
            /*
			{
				title: 'Deregistered',
				align: 'left',
				width: 130,
				render: (text, record) => (
					<div>{record.statuses["deregistered"]}</div>
				)
			},
			{
				title: 'Debt',
				align: 'left',
				width: 100,
				render: (text, record) => (
					<div>{record.statuses["debt"]}</div>
				)
			},
			*/
			{
				title: 'Hybernating',
				align: 'left',
				width: 105,
				render: (text, record) => (
					<div>{record.statuses["hybernating"]}</div>
				)
			},
			{
				title: 'Cancelled',
				align: 'left',
				width: 100,
				render: (text, record) => (
					<div>{record.statuses["cancelled"]}</div>
				),
				className: "lightGreyColumn"
			},
			{
				title: 'Deregistered',
				align: 'left',
				width: 105,
				render: (text, record) => (
					<div>{record.statuses["deregistered"]}</div>
				),
				className: "lightGreyColumn"
			},
			{
				title: 'Debt',
				align: 'left',
				width: 100,
				render: (text, record) => (
					<div>{record.statuses["debt"]}</div>
				),
				className: "lightGreyColumn"
			},
            {
                title: 'Total',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.count}</div>
                ),
                className: "totalColumn"
            },
        ];

        periodColumns = [
            {
                title: 'Country',
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => {
                    return (!a.name) ? -1 : a.name.localeCompare(b.name)
                },
                align: 'left',
                render: (text, record) => (
                    <div>{record.name}</div>
                )
            },
            {
                title: 'Monthly',
                align: 'left',
                width: 150,
                render: (text, record) => (
                    <div>{record.periods.monthly}</div>
                )
            },
            {
                title: 'Yearly',
                align: 'left',
                width: 150,
                render: (text, record) => (
                    <div>{record.periods.yearly}</div>
                )
            },
            {
                title: 'Total',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.periodTotal}</div>
                ),
                className: "totalColumn"
            },
        ];

        incomeColumns = [
            {
                title: 'Country',
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => {
                    return (!a.name) ? -1 : a.name.localeCompare(b.name)
                },
                align: 'left',
                render: (text, record) => (
                    <div>{record.name}</div>
                )
            },
            {
                title: 'Monthly',
                align: 'left',
                width:200,
                render: function(text, record){
                    return <>
                        <div style={{display:"flex", fontWeight:"bold",fontSize:18}}>
                            <div style={{width:70}}>Actual:</div>
                            <div>
                                {record.monthly_amount_actual.toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                })}
                            </div>
                        </div>
                        <div style={{display:"flex",fontSize:12}}>
                            <div style={{width:70}}>Estimated:</div>
                            <div>
                                {record.monthly_amount.toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                })}
                            </div>
                        </div>
                    </>
                }
            },
            {
                title: 'Yearly',
                align: 'left',
                width:200,
                render: function(text, record){
                    return <>
                        <div style={{display:"flex", fontWeight:"bold",fontSize:18}}>
                            <div style={{width:70}}>Actual:</div>
                            <div>
                                {record.yearly_amount_actual.toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                })}
                            </div>
                        </div>
                        <div style={{display:"flex",fontSize:12}}>
                            <div style={{width:70}}>Estimated:</div>
                            <div>
                                {record.yearly_amount.toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                })}
                            </div>
                        </div>
                    </>
                }
            }
        ];

        yearlyDueColumns = [
            {
                title: 'Date',
                key: 'date',
                dataIndex: 'date',
                align: 'left',
                width: 200,
                render: (text, record) => (
                    <div>{moment().month(record.month - 1).format('MMM')}-{(record.year+'').substr(2)}</div>
                )
            },
            {
                title: 'Annual Count',
                align: 'left',
                width: 200,
                render: (text, record) => (
                    <div>{record.annual_count}</div>
                )
            },
			{
				title: 'Monthly Count',
				align: 'left',
				width: 200,
				render: (text, record) => (
					<div>{record.monthly_count}</div>
				)
			}
        ];

        newRegistrationColumns = [
            {
                title: 'Billing Period',
                key: 'month',
                dataIndex: 'month',
                sorter: (a, b) => {
                    return (!a.month) ? -1 : a.month.localeCompare(b.month)
                },
                align: 'left',
                render: (text, record) => (
                    record.month
                )
            },
            {
                title: 'Monthly',
                key: 'monthly',
                dataIndex: 'monthly',
                width: 200
            },
            {
                title: 'Yearly',
                key: 'yearly',
                dataIndex: 'yearly',
                width: 200
            }
        ];

    }

    function getRegByConfig(){

        if(regBySplit == ''){
            return {
                data: regionRegistrationData,
                xField: 'count',
                yField: 'name',
                legend: {
                    position: 'top-left',
                },
                barBackground: {
                    style: {
                        fill: 'rgba(0,0,0,0.1)',
                    },
                },
                interactions: [
                    {
                        type: 'active-region',
                        enable: false,
                    },
                ],
                label: {
                    position: 'middle',
                    // 'left', 'middle', 'right'

                }
            }
        }
        else if(regBySplit == 'period'){
            return {
                data: regionRegistrationSplitData,
                isStack: true,
                xField: 'count',
                yField: 'name',
                seriesField: 'period',
                legend: {
                    position: 'top-left',
                },
                barBackground: {
                    style: {
                        fill: 'rgba(0,0,0,0.1)',
                    },
                },
                interactions: [
                    {
                        type: 'active-region',
                        enable: false,
                    },
                ],
                label: {
                    position: 'middle',
                    // 'left', 'middle', 'right'

                }
            }
        }
    }

    /* On Change */
    function regByChange(value){
        setRegByType(value);
    }
    function regBySplitChange(value){
        setRegBySpit(value);
    }
    function dashboardEditChange(e){
        setDashboardEdit(e.target.checked);
    }
    function dashboardVerticalCompactChange(e){
        setdashboardVerticalCompact(e.target.checked);
    }
    function overdueMemberPaginateChange(e){
        setOverdueMemberPaginate(e.target.checked);
    }
    function dashboardRemoveItem(i){
        setDashboardLayout(_.reject(dashboardLayout, { i: i }));
    }
    function dashboardAddItem(l){
        l.y = Infinity;
        l.x = 0;

        dashboardLayout.push(l);
        setDashboardLayout(dashboardLayout);

    }
    function incomeByChange(value){
        setIncomeByType(value);
    }
    function onLayoutChange(layout) {
        saveDashboard(layout);
        //setDashboardLayout(layout);
    }
    function onLayoutReset() {
        setDashboardLayout(getDefaultLayout());
    }
    function onIncomeMonthChange(value) {
        var date = new Date(value);
        setIncomeMonth(date.getFullYear()+'-'+(date.getMonth()+1));
    }






    function getDefaultLayout(){

        return [
            {
                "x": 0,
                "y": 0,
                "w": 12,
                "h": 28,
                "i": "overdueMembersTable",
                "type": "overdueMembersTable"
            },
            {
                "x": 4,
                "y": 28,
                "w": 4,
                "h": 5,
                "i": "totalRegistrationsCount",
                "type": "totalRegistrationsCount"
            },
            {
                "x": 7,
                "y": 48,
                "w": 5,
                "h": 19,
                "i": "registrationsGraph",
                "type": "registrationsGraph"
            },
            {
                "x": 0,
                "y": 33,
                "w": 6,
                "h": 15,
                "i": "registrationsStateTable",
                "type": "registrationsStateTable"
            },
            {
                "x": 6,
                "y": 33,
                "w": 6,
                "h": 15,
                "i": "registrationsPeriodTable",
                "type": "registrationsPeriodTable"
            },
            {
                "x": 0,
                "y": 48,
                "w": 6,
                "h": 19,
                "i": "incomePeriodTable",
                "type": "incomePeriodTable"
            },
            {
                "x": 1,
                "y": 67,
                "w": 5,
                "h": 21,
                "i": "yearlyDueGraph",
                "type": "yearlyDueGraph"
            },
            {
                "x": 7,
                "y": 77,
                "w": 3,
                "h": 22,
                "i": "yearlyDueTable",
                "type": "yearlyDueTable"
            },
            {
                "x": 0,
                "y": 99,
                "w": 12,
                "h": 8,
                "i": "overdueSummaryTable",
                "type": "overdueSummaryTable"
            },
            {
                "x": 0,
                "y": 99,
                "w": 12,
                "h": 8,
                "i": "debtSummaryTable",
                "type": "debtSummaryTable"
            },{
                "x": 0,
                "y": 107,
                "w": 6,
                "h": 12,
                "i": "newRegistrationsGraph",
                "type": "newRegistrationsGraph"
            },{
                "x": 6,
                "y": 107,
                "w": 6,
                "h": 12,
                "i": "newRegistrationsTable",
                "type": "newRegistrationsTable"
            },{
                "x": 0,
                "y": 119,
                "w": 12,
                "h": 12,
                "i": "comparisonGraph",
                "type": "comparisonGraph"
            }


        ];
    }


    function GenerateWidget(props){
        let item = props.item;
        let i = props.index;

        if(item.i == 'overdueMembersTable'){

            return <div key={item.i} style={{marginTop:"-60px"}}>
                <div className="dashboard-card">
                    <h4>Overdue Members</h4>
                    {dashboardEdit &&
                        <div style={{textAlign:"right"}}>
                            <Checkbox checked={overdueMemberPaginate} onChange={overdueMemberPaginateChange}>Auto Paginate</Checkbox>
                        </div>
                    }
                    <div style={{paddingTop:30}}>
                        <SearchTable
                            columns={overdueColumns}
                            url="/users/overdue?for=member"
                            container={gridRef}
                            containerIndex={0}
                            rowHeight={overdueMemberPaginate?86:null}
                            pagination={overdueMemberPaginate?null:{ pageSize: 99999}}
                            className="noPadding"
                            formatData={function(data){console.log(["???? HERE",data]); return  data}}
							scroll={{ x: 1000  }}
                        />
                    </div>
                </div>
            </div>
        }
        else if(item.i == 'registrationsGraph'){
            return <div key={item.i}  className="dashboard-card">
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>
                        Registrations by <Select className="massSelect" style={{width:100}} defaultValue="region" onChange={regByChange}>
                        <Option value="region">Region</Option>
                        <Option value="country">Country</Option>
                    </Select>
                    </h4>
                    <div>
                        Split By <Select className="massSelect" style={{width:100}} defaultValue="" onChange={regBySplitChange}>
                        <Option value="">None</Option>
                        <Option value="period">Period</Option>
                    </Select>
                    </div>
                </div>
                <Bar {...getRegByConfig()}/>
            </div>
        }
        else if(item.i == 'totalRegistrationsCount'){
            return <div key={item.i}>

                <div style={{display:"flex",gap:20,justifyContent:"center"}}>
                    {Object.entries(userStats).map(function(d){

                        let total = 0;
                        let statusHtml = Object.entries(d[1]).map(function(statuses){
                            total += statuses[1];
                            return <span key={statuses[0]}>{statuses[0]}: {statuses[1]}</span>
                        });

                        return <div title={d[0]+" Members"} bordered={false} style={{ width: 300, textAlign:"center" }} className="cardFlex dashboard-card" key={"userStat_"+d[0]}>
                            <div style={{display:"flex",flexDirection:"column",height:"100%"}}>
                                <h6>{d[0]+" Members"}</h6>
                                <p style={{fontSize:"30px",textAlign:"center"}}>{total}</p>
                                <p style={{fontSize:"12px",flex:1, display:"flex", flexDirection:"column"}}>
                                    {statusHtml}
                                </p>
                                <div style={{borderBottom:"1px solid lightgrey", width:"80%",margin:"auto", position:"relative", top:"-6px"}}></div>
                                <p>
                                    Overdue: {data.userStatsOverdue[d[0]]}
                                </p>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        }
        else if(item.i == 'registrationsStateTable'){
            return <div key={item.i} className="dashboard-card">
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>Registrations by Status</h4>
                    <div>
                        <Select className="massSelect" style={{width:100}} defaultValue="region" onChange={regByChange}>
                            <Option value="region">Region</Option>
                            <Option value="country">Country</Option>
                        </Select>
                    </div>
                </div>

                <Table
                    dataSource={regionRegistrationData}
                    columns = {statusColumns}
                    rowKey="name"
                    rowClassName={(record, index) => ((record.name=="Total")?"totalRow":"")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    summary={pageData => {

                        const statuss = ["active","on-hold","pending-cancel","pending-deregister","hybernating","cancelled","deregistered","debt"];
                        const statusValues = {};
                        for(const s of statuss){
							statusValues[s] = 0;
						}
						statusValues['total'] = 0;

                        for(let p of pageData){
							for(const s of statuss){
							    if(p.statuses[s]) {
									statusValues[s] += p.statuses[s];
								}
                            }
							statusValues['total'] += parseInt(p.count);
                        };

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{backgroundColor:"#d3d3d3"}}>
                                    <Table.Summary.Cell >
                                        <div style={{textAlign:"left"}}>Total</div>
                                    </Table.Summary.Cell>
                                    {Object.values(statusValues).map((value) => (
										<Table.Summary.Cell style={{textAlign: "left"}}>
											<div style={{textAlign: "left"}}>{value}</div>
										</Table.Summary.Cell>
                                    ))}
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                    scroll={{ y: 360, x:1090 }}
                    className="scrollTable"
                />

            </div>
        }
        else if(item.i == 'registrationsPeriodTable'){
            return <div key={item.i} className="dashboard-card">
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>Registrations by Billing Period</h4>
                    <div>
                        <Select className="massSelect" style={{width:100}} defaultValue="region" onChange={regByChange}>
                            <Option value="region">Region</Option>
                            <Option value="country">Country</Option>
                        </Select>
                    </div>
                </div>

                <Table
                    dataSource={regionRegistrationData}
                    columns = {periodColumns}
                    rowKey="name"
                    rowClassName={(record, index) => ((record.name=="Total")?"totalRow":"")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    summary={pageData => {

                        let totalMonthly = 0;
                        let totalYearly = 0;
                        let totalTotal = 0;

                        for(let p of pageData){
                            totalMonthly += p.periods.monthly||0;
                            totalYearly  += p.periods.yearly||0;
                            totalTotal   += p.count;
                        };

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{backgroundColor:"#d3d3d3"}}>
                                    <Table.Summary.Cell >
                                        <div style={{textAlign:"left"}}>Total</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalMonthly}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalYearly}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalTotal}</div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                    scroll={{ y: 360 }}
                    className="scrollTable"
                />

            </div>
        }
        else if(item.i == 'incomePeriodTable'){
            return <div key={item.i} className="dashboard-card">
                <div style={{display:"flex",gap:10}}>
                    <h4 style={{flex:1}}>Income by Status</h4>
                    <div>
                        <DatePicker defaultValue={dayjs()} format={'YYYY-MM'}  onChange={onIncomeMonthChange} picker="month" />
                    </div>
                    <div>
                        <Select
                            className="massSelect"
                            style={{width: 100}}
                            defaultValue="region"
                            onChange={incomeByChange}
                        >
                            <Option value="region">Region</Option>
                            <Option value="country">Country</Option>
                        </Select>
                    </div>
                </div>

                <div style={{paddingTop:30}}>
                    <SearchTable
                        columns={incomeColumns}
                        url="/dashboard/income"
                        search={false}
                        filters={{'date':incomeMonth}}
                        //pagination={false}
                        formatData={function(incomeData){

                            let newData = [];

                            if(incomeByType == 'region'){

                                let asYA = 0;
                                let asMA = 0;
                                let auYA = 0;
                                let auMA = 0;
                                let caYA = 0;
                                let caMA = 0;
                                let euYA = 0;
                                let euMA = 0;
                                let nzYA = 0;
                                let nzMA = 0;
                                let usYA = 0;
                                let usMA = 0;
                                let asYAA = 0;
                                let asMAA = 0;
                                let auYAA = 0;
                                let auMAA = 0;
                                let caYAA = 0;
                                let caMAA = 0;
                                let euYAA = 0;
                                let euMAA = 0;
                                let nzYAA = 0;
                                let nzMAA = 0;
                                let usYAA = 0;
                                let usMAA = 0;


                                let asMC = 0;
                                let asYC = 0;
                                let auMC = 0;
                                let auYC = 0;
                                let caMC = 0;
                                let caYC = 0;
                                let euMC = 0;
                                let euYC = 0;
                                let nzMC = 0;
                                let nzYC = 0;
                                let usMC = 0;
                                let usYC = 0;

                                for(let d of incomeData){
                                    if(d.iso == 'AU'){
                                        auMA += d.monthly_amount;
                                        auYA += d.yearly_amount;
                                        auMAA += d.monthly_amount_actual;
                                        auYAA += d.yearly_amount_actual;
                                        auMC += d.monthly_count;
                                        auYC += d.yearly_count;
                                    }
                                    else if(d.iso == 'CA'){
                                        caMA += d.monthly_amount;
                                        caYA += d.yearly_amount;
                                        caMAA += d.monthly_amount_actual;
                                        caYAA += d.yearly_amount_actual;
                                        caMC += d.monthly_count;
                                        caYC += d.yearly_count;
                                    }
                                    else if(['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','GB'].includes(d.iso)){
                                        euMA += d.monthly_amount;
                                        euYA += d.yearly_amount;
                                        euMAA += d.monthly_amount_actual;
                                        euYAA += d.yearly_amount_actual;
                                        euMC += d.monthly_count;
                                        euYC += d.yearly_count;
                                    }
                                    else if(d.iso == 'NZ'){
                                        nzMA += d.monthly_amount;
                                        nzYA += d.yearly_amount;
                                        nzMAA += d.monthly_amount_actual;
                                        nzYAA += d.yearly_amount_actual;
                                        nzMC += d.monthly_count;
                                        nzYC += d.yearly_count;
                                    }
                                    else if(['AF','AM','AZ','BH',',BT','BN','KH','CN','CX','CC','IO','GE','HK','IN','ID','IR','IQ','IL','JP','JO','KZ','KW','KG','LA','LB','MO','MY','MV','MN','MM','NP','KP','OM','PK','PS','PH','QA','SA','SG','KR','LK','SY','TW','TJ','TH','TR','TM','AE','UZ','VN','YE'].includes(d.iso)){
                                        asMA += d.monthly_amount;
                                        asYA += d.yearly_amount;
                                        asMAA += d.monthly_amount_actual;
                                        asYAA += d.yearly_amount_actual;
                                        asMC += d.monthly_count;
                                        asYC += d.yearly_count;
                                    }
                                    else{
                                        usMA += d.monthly_amount;
                                        usYA += d.yearly_amount;
                                        usMAA += d.monthly_amount_actual;
                                        usYAA += d.yearly_amount_actual;
                                        usMC += d.monthly_count;
                                        usYC += d.yearly_count;
                                    }

                                }

                                newData = [
                                    {
                                        name: 'Asia',
                                        monthly_amount:asMA,
                                        yearly_amount:asYA,
                                        monthly_amount_actual:asMAA,
                                        yearly_amount_actual:asYAA,
                                        monthly_count:asMC,
                                        yearly_count:asYC
                                    },
                                    {
                                        name: 'Australia',
                                        monthly_amount:auMA,
                                        yearly_amount:auYA,
                                        monthly_amount_actual:auMAA,
                                        yearly_amount_actual:auYAA,
                                        monthly_count:auMC,
                                        yearly_count:auYC
                                    },
                                    {
                                        name: 'Canada',
                                        monthly_amount:caMA,
                                        yearly_amount:caYA,
                                        monthly_amount_actual:caMAA,
                                        yearly_amount_actual:caYAA,
                                        monthly_count:caMC,
                                        yearly_count:caYC
                                    },
                                    {
                                        name: 'Europe',
                                        monthly_amount:euMA,
                                        yearly_amount:euYA,
                                        monthly_amount_actual:euMAA,
                                        yearly_amount_actual:euYAA,
                                        monthly_count:euMC,
                                        yearly_count:euYC
                                    },
                                    {
                                        name: 'New Zealand',
                                        monthly_amount:nzMA,
                                        yearly_amount:nzYA,
                                        monthly_amount_actual:nzMAA,
                                        yearly_amount_actual:nzYAA,
                                        monthly_count:nzMC,
                                        yearly_count:nzYC
                                    },
                                    {
                                        name: 'United Sates Of America',
                                        monthly_amount:usMA,
                                        yearly_amount:usYA,
                                        monthly_amount_actual:usMAA,
                                        yearly_amount_actual:usYAA,
                                        monthly_count:usMC,
                                        yearly_count:usYC
                                    }
                                ];


                            }else{
                                newData = incomeData;
                            }


                            return newData;
                        }}
                        rowKey="name"
                        pagination={{ pageSize: 99999}}
                        container={gridRef}
                        containerIndex={i}
                        className="scrollTable"
                    />
                </div>

            </div>
        }
        else if(item.i == 'yearlyDueGraph'){
            return <div key={item.i} className="dashboard-card">
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>
                        Yearly Subscriptions Due
                    </h4>
                </div>
                {/*
                <Column
                    data   = {yearlyDueData}
                    xField = 'date'
                    yField = 'count'
                    label  = {{
                        position: 'middle',
                        // 'top', 'bottom', 'middle',
                        style: {
                            fill: '#FFFFFF',
                            opacity: 0.6
                        }
                    }}
                    xAxis = {{
                        label: {
                            autoHide: true,
                            autoRotate: false,
                        },
                    }}
                />*/}


            </div>
        }
        else if(item.i == 'yearlyDueTable'){
            return <div key={item.i} className="dashboard-card">
                <h4>Subscription Renew Dates</h4>
                <Table
                    dataSource={yearlyDueData}
                    columns = {yearlyDueColumns}
                    rowKey="date"
                    rowClassName={(record, index) => ((record.date=="Total")?"totalRow":"")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    summary={pageData => {
                        let totalACount = 0;
						let totalMCount = 0;

                        for(let p of pageData){
							totalACount += p.annual_count;
							totalMCount += p.monthly_count;
                        };

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{backgroundColor:"#d3d3d3"}}>
                                    <Table.Summary.Cell >
                                        <div style={{textAlign:"left"}}>Total</div>
                                    </Table.Summary.Cell>
									<Table.Summary.Cell style={{textAlign:"left"}}>
										<div style={{textAlign:"left"}}>{totalACount}</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell style={{textAlign:"left"}}>
										<div style={{textAlign:"left"}}>{totalMCount}</div>
									</Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                    rowClassName={function(record,index){
                        if(record.month===1){
                            return 'dividerRow';
                        }
                        return '';
                    }}
                />
            </div>
        }
        else if(item.i == 'overdueSummaryTable'){
            return <div key={item.i} className="dashboard-card">
                <h4>Overdue Summary</h4>

                <Table
                    dataSource={overdueSummaryData}
                    columns={overdueSummaryColumns}
                    rowKey="name"
                    rowClassName={(record, index) => ((record.date == "Total") ? "totalRow" : "")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    expandable={{
                        expandedRowRender: function(record){

                            return <div style={{padding:"50px 0 10px 0"}}>
                                <SearchTable
                                    columns={overdueColumns}
                                    url={"/users/overdue?planId="+record.id}
                                    container={gridRef}
                                    containerIndex={0}
                                    rowHeight={overdueMemberPaginate?86:null}
                                    pagination={overdueMemberPaginate?null:{ pageSize: 99999}}
                                    className="noPadding"
                                />
                            </div>},
                    }}
                    summary={pageData => {

                        let totalInvoices = 0;
                        let totalUsers = 0;
                        let totalOverdue = 0;
                        let totalFees = 0;

                        for(let p of pageData){
                            totalInvoices += p.invoiceCount;
                            totalUsers += p.userCount;
                            totalOverdue += parseInt(p.amountOverdue);
                            totalFees += parseInt(p.feesTotal);
                        };

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{backgroundColor:"#d3d3d3"}}>
                                    <Table.Summary.Cell >
                                        <div style={{textAlign:"left"}}>Total</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell/>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalInvoices}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalUsers}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>${totalOverdue/100}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>${totalFees/100}</div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
            </div>
        }
        else if(item.i == 'debtSummaryTable'){
            return <div key={item.i} className="dashboard-card">
                <h4>Debt Summary</h4>

                <Table
                    dataSource={debtSummaryData}
                    columns={overdueSummaryColumns}
                    rowKey="name"
                    rowClassName={(record, index) => ((record.date == "Total") ? "totalRow" : "")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    expandable={{
                        expandedRowRender: function(record){

                            return <div style={{padding:"50px 0 10px 0"}}>
                                <SearchTable
                                    columns={overdueColumns}
                                    url={"/users/debt?planId="+record.id}
                                    container={gridRef}
                                    containerIndex={0}
                                    rowHeight={overdueMemberPaginate?86:null}
                                    pagination={overdueMemberPaginate?null:{ pageSize: 99999}}
                                    className="noPadding"
                                />
                            </div>},
                    }}
                    summary={pageData => {

                        let totalInvoices = 0;
                        let totalUsers = 0;
                        let totalOverdue = 0;
                        let totalFees = 0;

                        for(let p of pageData){
                            totalInvoices += p.invoiceCount;
                            totalUsers += p.userCount;
                            totalOverdue += parseInt(p.amountOverdue);
                            totalFees += parseInt(p.feesTotal);
                        };

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{backgroundColor:"#d3d3d3"}}>
                                    <Table.Summary.Cell >
                                        <div style={{textAlign:"left"}}>Total</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell/>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalInvoices}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalUsers}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>${totalOverdue/100}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>${totalFees/100}</div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
            </div>
        }
        else if(item.i == 'newRegistrationsGraph'){
            return <div key={item.i} className="dashboard-card">
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>
                        New Subscriptions Graph
                    </h4>
                </div>
                <Line
                    data   = {newRegistrationData}
                    xField = 'date'
                    yField = 'count'
                    seriesField = 'category'
                    label  = {{
                        position: 'middle',
                        // 'top', 'bottom', 'middle',
                        style: {
                            fill: '#FFFFFF',
                            opacity: 0.6
                        }
                    }}
                    xAxis = {{
                        label: {
                            autoHide: true,
                            autoRotate: false,
                        },
                    }}
                />


            </div>
        }
        else if(item.i == 'newRegistrationsTable'){
            return <div key={item.i} className="dashboard-card">
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>New Subscriptions Table</h4>
                </div>


                <Table
                    dataSource={newRegistrationTableData}
                    columns={newRegistrationColumns}
                    rowKey="month"
                    rowClassName={(record, index) => ((record.name == "Total") ? "totalRow" : "")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    scroll={{y: 360}}
                    className="scrollTable"
                />


            </div>
        }
        else if(item.i == 'comparisonGraph'){
            return <div key={item.i} className="dashboard-card">
                <ComparisonGraph/>
            </div>
        }
        else

        return '';
    }



    return (
        <div className="dashboard">

            <div style={{display:"flex",position:"absolute", right:0, top:-50}}>
                <Checkbox onChange={dashboardEditChange}>Edit Dashboard</Checkbox>
                {dashboardEdit &&
                <div style={{display:"flex",flex:1}}>
                    <Checkbox checked={dashboardVerticalCompact} onChange={dashboardVerticalCompactChange}>Vertical Compact</Checkbox>
                    <Button type="primary" size="small" shape="round" onClick={()=>setDashboardAddModal(true)}>Add Item</Button>
                    <Button type="danger" size="small" shape="round" onClick={onLayoutReset} style={{marginLeft:"auto"}}>Reset Layout</Button>
                </div>
                }
            </div>
            {!isLoading &&
            <ReactGridLayout
                layout={dashboardLayout}
                onLayoutChange={onLayoutChange}
                verticalCompact={dashboardVerticalCompact}
                isDraggable={dashboardEdit}
                isResizable={dashboardEdit}
                //innerRef={function(ref){console.log(["ref2",ref])}}
                ref={function(ref){if(!ref)return; setGridRef(ref);}}
                className="layout"
                items={5}
                rowHeight={30}
                cols={12}
            >
                {dashboardLayout.map(function(l,i) {
                    return (
                        <div key={l.i} style={{zIndex:l.zIndex}}>
                            {dashboardEdit &&
                            <div style={{
                                position: "absolute",
                                right: "2px",
                                top: 0,
                                zIndex: 1
                            }}>
                                <Button
                                    className="remove"
                                    type="danger" size="small" shape="round"
                                    onClick={() => dashboardRemoveItem(l.i)}
                                ><i className="fas fa-times"></i></Button>
                            </div>
                            }
                            <GenerateWidget item={l} index={i}/>
                        </div>
                    );
                })}

            </ReactGridLayout>
            }

            <Modal
                visible={dashboardAddModal}
                title="Add Dashboard Item"
                onCancel={()=>setDashboardAddModal(false)}
                //centered={true}
                footer={<Button type="default" size="small" shape="round" onClick={()=>setDashboardAddModal(false)}>Done</Button>}
            >
                {_.map(getDefaultLayout(), function(l) {

                    let onDashboard = false;
                    for(let i of dashboardLayout){
                        if(i.i == l.i){
                            onDashboard = true;
                            break;
                        }
                    }

                    return (
                        <div style={{display:"flex",paddingBottom:10}} key={l.i}>
                            <div style={{flex:1}}>{l.i}</div>
                            <div><Button type="primary" size="small" shape="round" disabled={onDashboard} onClick={()=>dashboardAddItem(l)}>Add</Button></div>
                        </div>
                    );
                })}
            </Modal>

        </div>
    );
}