import React, {useContext, useEffect, useState} from 'react';
import API2 from "../../components/api2";
import Moment from 'react-moment';
import {NavLink, useParams, useHistory} from "react-router-dom";




import {
    Tabs,
    Button,Menu, Dropdown,
    Spin,
    Table,
    Modal,
    Checkbox
} from 'antd';
import moment from "moment/moment";
import {Form, notification, Select} from "antd/lib/index";
import BreadCrumbContext from "../../contexts/breadCrump";
import DeregisterForm from "../../forms/deregister";

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

export default function Deregister(props) {

    let subscriptionId = props.match.params.subscriptionId ?? false;

    const AP = API2();
    const setBreadCrumbs = useContext(BreadCrumbContext);
    const [deregData, setDeregData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>,
            (!deregData)?<Spin size="small"/>:<span><i className="fas fa-user"/> Create User</span>
        ]);

		let url;
		if(props.subscriptionId){
			url = '/subscription/'+props.subscriptionId+'/deregister';
		}else{
			url = '/subscription/deregister';
		}

		AP.get(url).then(function(resp){
			setDeregData(resp);
		});

    }, []);

    useEffect(() => {
        if(!deregData) return false;
        setBreadCrumbs([
            <NavLink to="/subscriptions"><span><i className="fas fa-users"></i> Subscription</span></NavLink>,
            <span><i className="fas fa-users"></i> {deregData.subscription.plan.for} - {deregData.subscription.plan.name}</span>,
            <span><i className="fas fa-users"></i> Deregister</span>
        ]);
    }, [deregData]);


	function finishDeregisterForm() {
		history.push("/subscriptions");
	}

    return(
        <div className="content_inner">
			{deregData &&
			<DeregisterForm subscription={deregData.subscription} deregData={deregData} onFinish={finishDeregisterForm}/>
			}
        </div>
	);
}