import React, {useEffect, useState} from 'react';
import { Route, Switch } from "react-router-dom";

import Home from "../pages/Home";
import Contact from "../pages/Contact";
import ExpressInterest from "../pages/ExpressInterest";
import Pricing from "../pages/Pricing";
import SportsNutrition from "../pages/SportsNutrition";
import CertificatePage from "../pages/public/Certificate";
import AdvisoryBoardPage from "../pages/public/AdvisoryBoard";
import CodeOfConductPage from "../pages/public/CodeOfConduct";
import PublicTermsAndConditionsPage from "../pages/public/TermsAndConditions";
import ProvisionalPeriodPage from "../pages/public/ProvisionalPeriod";
import ContactPage from "../pages/public/Contact";
import EnrolPage from "../pages/public/Enrol";
import InsurancePage from "../pages/public/Insurance";
import AboutPage from "../pages/public/About";
import Login from "../pages/Login";
import CreateAccountPage from "../pages/public/createAccount";
import PublicCreateClientAccount from "../pages/Client/PublicCreateAccount";
import RegistryPage from "../pages/Registry/Registry";
import ForgotPassword from "../pages/ForgetPassword/ForgotPassword";



import Dashboard from "../pages/Dashboard";
import DashboardMember from "../pages/DashboardMember";

import Users from "../pages/User/Users";
import User from "../pages/User/User";
import Students from "../pages/Student/Students";
import StaffPage from "../pages/User/Staff";
import MembersPage from "../pages/User/Members";
import RegisterPage from "../pages/User/MemberRegistration";

import CouponsPage from "../pages/Coupon/List";
import CouponPage from "../pages/Coupon/Item";
import AdminCertificatesPage from "../pages/Certificate/List";
import PlansPage from "../pages/Plan/List";
import PlanPage from "../pages/Plan/Item";
import UrlsPage from "../pages/Url/List";
import UrlPage from "../pages/Url/Item";
import PlanGroupPage from "../pages/Plan/Group";
import ResourcesPage from "../pages/Resource/List";
import ResourcePage from "../pages/Resource/Item";
import DownloadsPage from "../pages/Resource/MyList";
import InvoicePage from "../pages/Invoice/Item";
import DiscountPage from "../pages/Discount/Item";
import APIKey from "../pages/API/APIKey";
import APIDocumentation from "../pages/API/Documentation";
import LinkedCompanies from "../pages/Company/Linked";
import EmailTemplates from "../pages/email/Templates";
import EmailTemplate from "../pages/email/Template";
import Profile from "../pages/Profile";
import FinancialPage from "../pages/Financial";
import DeregisterPage from "../pages/Subscripion/Deregister";

import TermsAndConditionsPage from "../pages/TermsAndConditions";
import EndOfMonthsPage from "../pages/Reporting/EndOfMonths";
import EndOfMonthPage from "../pages/Reporting/EndOfMonth";
import DailyStatsPage from "../pages/Reporting/DailyStats";
import DailyStatPage from "../pages/Reporting/DailyStat";
import DailyTestsPage from "../pages/Reporting/DailyTests";
import DailyTestPage from "../pages/Reporting/DailyTest";
import MembersSummaryPage from "../pages/Reporting/MembersSummary";
import StudentsSummaryPage from "../pages/Reporting/StudentsSummary";
import DataExport from "../pages/DataExport";
import PrivateRoute from '../hooks/PrivateRoute'
import PublicRoute from '../hooks/PublicRoute'
import useToken from "../hooks/useToken";
import APIIFrame from "../pages/API/iframe";
import EmailHistory from "../pages/email/History";
import RegistrySettings from "../pages/Registry/settings";
import Exports from "../pages/Reporting/Exports";

export default function Routes(props) {

    const { user } = useToken();

    let subdomain = window.location.host.split('.')[0];

    let baseUrl = '';
    if(window.location.pathname.substring(0, 8) === '/iframe/'){
        baseUrl = '/iframe';
    }

    if(subdomain == 'enrol'){
        return <Switch>
            <Route path="/:token?" component={EnrolPage}/>
        </Switch>
    }

    if(window.location.pathname == '/' && window.location.host.substr(0,5)=='www2.' && !user){
        window.location = 'https://www.'+window.location.host.split('.')[1]+'.com';
    }

    return <Switch>
        {!user &&
        <Route exact path="/" render={(props) => <Home {...props}/>}/>
        }
        <Route path="/certificate" component={CertificatePage}/>
        <Route path="/advisory-board" component={AdvisoryBoardPage}/>
        <Route path="/insurance" component={InsurancePage}/>
        <Route path="/code-of-conduct" component={CodeOfConductPage}/>
        <Route path="/terms" component={PublicTermsAndConditionsPage}/>
        <Route path="/provisional-period" component={ProvisionalPeriodPage}/>
        <Route path="/contact" component={ContactPage}/>
        <Route path={baseUrl+"/enrol/:token?"} component={EnrolPage}/>

        <Route path={baseUrl+"/nutritionists/:region?"} component={RegistryPage}/>

        <Route path="/about" component={AboutPage}/>
        <Route path="/sportsNutrition" component={SportsNutrition}/>
        <Route path="/contact" component={Contact}/>
        <Route path="/pricing" component={Pricing}/>
        <Route path="/expressInterest" component={ExpressInterest}/>
        <Route path="/join/:id" component={PublicCreateClientAccount}/>

        <PublicRoute path={baseUrl+"/signUp/:plan?"} component={CreateAccountPage}/>
        <PublicRoute path="/login" component={Login}/>
        <PublicRoute path="/forgotPassword/:token?" component={ForgotPassword}/>

        {user &&
            <Route exact path="/" component={Dashboard}/>
        }

        {user && (user.type == 'member' || user.type == 'student') &&
            <PrivateRoute path="/login" component={DashboardMember}/>
        }

        {(!user || (user && (user.type == 'member' || user.type == 'student'))) &&
        <PrivateRoute path="/dashboard" component={DashboardMember}/>
        }
        {user && user.type != 'member' &&
            <PrivateRoute path="/login" component={Dashboard}/>
        }
        {user && user.type != 'member' &&
            <PrivateRoute path="/dashboard" component={Dashboard}/>
        }
        <PrivateRoute path="/api/key" component={APIKey}/>
        <PrivateRoute path="/api/iframe" component={APIIFrame}/>
        <PrivateRoute path="/api/documentation/:group?/:controller?/:action?" component={APIDocumentation}/>
        <PrivateRoute path="/companies" component={LinkedCompanies}/>
        <PrivateRoute path="/dataExport" component={DataExport}/>
        <PrivateRoute path="/email/templates" component={EmailTemplates}/>
        <PrivateRoute path="/email/template/:id" component={EmailTemplate}/>
        <PrivateRoute path="/email/history" component={EmailHistory}/>
        <PrivateRoute path="/profile/:tab?" component={Profile}/>

        <PrivateRoute path="/registrySettings" component={RegistrySettings}/>
        <PrivateRoute path="/subscriptions/:subscriptionId/deregister" component={DeregisterPage}/>
        <PrivateRoute path="/subscriptions/deregister" component={DeregisterPage}/>
        <PrivateRoute path="/subscriptions" component={FinancialPage}/>
        <PrivateRoute path="/subscriptions/invoices" component={FinancialPage}/>
        <PrivateRoute path="/downloads" component={DownloadsPage}/>
        <PrivateRoute path="/resources" component={ResourcesPage}/>
        <PrivateRoute path="/resource/:resourceId" component={ResourcePage}/>
        <PrivateRoute path="/certificates" component={AdminCertificatesPage}/>

        <PrivateRoute path="/users" component={Users}/>
        <PrivateRoute path="/user/:userId/invoice/:invoiceId" component={InvoicePage}/>
        <PrivateRoute path="/user/:userId/discount/:discountId" component={DiscountPage}/>
        <PrivateRoute path="/user/:id/:tab?" component={User}/>
        <PrivateRoute path="/students" component={Students}/>
        <PrivateRoute path="/student/:id" component={User}/>

        <PrivateRoute path="/staff" component={StaffPage}/>
        <PrivateRoute path="/members" component={MembersPage}/>
        <PrivateRoute path="/member/registration" component={RegisterPage}/>
		<PrivateRoute path="/member/:id" component={User}/>

        <PrivateRoute path="/coupons" component={CouponsPage}/>
        <PrivateRoute path="/coupon/:couponid" component={CouponPage}/>
        <PrivateRoute path="/plans" component={PlansPage}/>
        <PrivateRoute path="/plan/:planid" component={PlanPage}/>
        <PrivateRoute path="/planGroup/:name" component={PlanGroupPage}/>
        <PrivateRoute path="/urls" component={UrlsPage}/>
        <PrivateRoute path="/url/:urlid" component={UrlPage}/>

        <PrivateRoute path="/reporting/endofmonth/:date" component={EndOfMonthPage}/>
		<PrivateRoute path="/reporting/endofmonth" component={EndOfMonthsPage}/>

        <PrivateRoute path="/reporting/dailystats/:id" component={DailyStatPage}/>
        <PrivateRoute path="/reporting/dailystats" component={DailyStatsPage}/>
        <PrivateRoute path="/reporting/dailyTests/:id" component={DailyTestPage}/>
        <PrivateRoute path="/reporting/dailyTests" component={DailyTestsPage}/>

        <PrivateRoute path="/reporting/membersSummary" component={MembersSummaryPage}/>
        <PrivateRoute path="/reporting/studentsSummary" component={StudentsSummaryPage}/>

        <PrivateRoute path="/reporting/exports" component={Exports}/>


        <PrivateRoute path="/termsAndConditions" component={TermsAndConditionsPage}/>

        <Route path="/" render={(props) => <Home {...props}/>}  />
    </Switch>;
}