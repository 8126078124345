import React, { Component,useState, useEffect } from "react";

import countryList from "react-select-country-list";
import { useHistory } from "react-router-dom";
import {
    Form,Input,Spin
} from 'antd';
import {Link} from "react-router-dom";
import '../../forms/enrol';
import '../../css/hurryTimer.css';
import EnrolForm from "../../forms/enrol";
import moment from "moment/moment";
import API2 from "../../components/api2";

export default function CountDown(props) {

    const calculateTimeLeft = () => {

        if(!props.timeLeftStart) return false;

    //    let year = new Date().getFullYear();
    //    debugger;
        let difference = props.timeLeftStart*1000 - +new Date();

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: (Math.floor((difference / (1000 * 60 * 60)) % 24)+"").padStart(2,"0"),
                minutes: (Math.floor((difference / 1000 / 60) % 60)+"").padStart(2,"0"),
                seconds: (Math.floor((difference / 1000) % 60)+"").padStart(2,"0")
            };
        }

        setTimeLeft(timeLeft);
    };

    const [timeLeft, setTimeLeft] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            calculateTimeLeft();
        }, 1000);
    });

    useEffect(() => {
        if(!props.timeLeftStart) return false;
        calculateTimeLeft();
    }, [props.timeLeftStart]);

    return (
        <div className="hurrytimer-campaign hurrytimer-campaign-951">
            {!timeLeft && <Spin size="large" style={{height: 110}}/>}
            {timeLeft && timeLeft &&
            <div className=" hurrytimer-timer">
                <div className="hurrytimer-timer-block ">
                    <div
                        className="hurrytimer-timer-digit ">{timeLeft.days}</div>
                    <div className="hurrytimer-timer-label ">days</div>
                </div>
                <div className=" hurrytimer-timer-sep"></div>
                <div className="hurrytimer-timer-block ">
                    <div
                        className="hurrytimer-timer-digit ">{timeLeft.hours}</div>
                    <div className="hurrytimer-timer-label ">hrs</div>
                </div>
                <div className=" hurrytimer-timer-sep"></div>
                <div className="hurrytimer-timer-block ">
                    <div
                        className="hurrytimer-timer-digit ">{timeLeft.minutes}</div>
                    <div className="hurrytimer-timer-label ">mins</div>
                </div>
                <div className=" hurrytimer-timer-sep"></div>
                <div className="hurrytimer-timer-block ">
                    <div
                        className="hurrytimer-timer-digit ">{timeLeft.seconds}</div>
                    <div className="hurrytimer-timer-label ">secs</div>
                </div>
            </div>
            }
        </div>
    );
}