import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input,Spin,Collapse } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import moment from 'moment'
import countryList from "react-select-country-list";
import ComparisonGraph from "../../graphs/comparisonChart";
import ProgressGraph from "../../graphs/progressChart";
import {Form, notification} from "antd/lib/index";

const { Panel } = Collapse;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

export default function StudentsSummary(props) {

    const AP = API2();
    const [form] = Form.useForm();
	const setBreadCrumbs = useContext(BreadCrumbContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState(false);

  
	useEffect(() => {
        AP.get('/studentsSummary')
            .then(function(res){
                setData(res);
                setIsLoading(false);
            })
	}, []);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'AUD',
        currencyDisplay: 'narrowSymbol'
    });

    return (
        <div className="content_inner" style={{maxWidth:1300,paddingTop:40}}>
            {!!isLoading && <Spin/>}
            {!isLoading && <>

                <div style={{display:"flex",gap:20,justifyContent:"center"}}>
                    {Object.entries(data.overdueSummary).map((os)=>(
                        <div title={os[0]} bordered={false} style={{ width: 300, textAlign:"center" }} className="dashboard-card">
                            <h6>
                                {os[0]=="sumMapped" && "Overdue Mapped"}
                                {os[0]=="sumCalculated" && "Overdue Calculated"}
                            </h6>
                            <p style={{fontSize:"30px",textAlign:"center"}}>{formatter.format(os[1]/100).replace(/\D00$/, '')}</p>
                        </div>
                    ))}
                    
                </div>


                <div style={{marginTop:40}}>
                    <h3>Type Summary</h3>
                    <Table
                        rowKey="id"
                        pagination={false}
                        dataSource={data.typeSummary}
                        columns={[
                            {
                                title: 'Plan Type',
                                key: 'name',
                                dataIndex: 'plan_type',
                                align:'left'
                            },
                            {
                                title: 'Status',
                                key: 'subscription_status',
                                dataIndex: 'subscription_status',
                                align:'left'
                            },
                            {
                                title: 'Count',
                                key: 'count',
                                dataIndex: 'count',
                                align:'left'
                            }
                        ]}
                    />
                </div>


                <div style={{marginTop:40}}>
                    <h3>Overdue Students</h3>
                    <Table
                        rowKey="id"
                        pagination={false}
                        dataSource={data.overdueStudents}
                        columns={[
                            {
                                title: 'Name',
                                key: 'name',
                                align:'left',
                                render: function(text, record) {
                                    return <div style={{textAlign:"left"}}>
                                        <div>{record.first_name} {record.last_name}</div>
                                    </div>
                                }
                            },
                            {
                                title: 'Plan Type',
                                key: 'plan_type',
                                dataIndex: 'plan_type',
                                align:'left'
                            },
                            {
                                title: 'Subscription Status',
                                key: 'subscription_status',
                                dataIndex: 'subscription_status',
                                align:'left'
                            },
                            {
                                title: 'Actions',
                                key: 'actions',
                                width: "80px",
                                fixed: 'right',
                                render: (text, record) => (
                                    <div>
                                        <span><Link to={"/user/"+record.user_id+"/invoices"}><Button type="primary"  shape="round"><i className="far fa-edit"/></Button></Link></span>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
                
            </>
            }
        </div>
	);
};