import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {NavLink,Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';

export default function Resources(props) {


    const AP = API2();
	const setBreadCrumbs = useContext(BreadCrumbContext);

    function downloadCertificate(){
        AP.file('/my/certificate');
    }

	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-cloud-download"/> Resources</span>
        ]);

        //
	}, []);

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => {return (!a.name)?-1:a.name.localeCompare(b.name)},
        	align:'left'
		},
		{
			title: 'Actions',
            key: 'actions',
			fixed: 'right',
            render: (text, record) => (
				<div>
                    {record.name == "Certificate" &&
                    <span><Button type="primary" shape="round" onClick={downloadCertificate}><i className="far fa-download"/>&nbsp;Download</Button></span>
                    }
                    {record.name != "Certificate" &&
					<>
                        {record.attachment_id &&
                        <span><a href={record.attachment.endpoint}><Button type="primary" shape="round"><i className="far fa-download"/>&nbsp;Download</Button></a></span>
                        }
                        {record.link &&
                        <span><a href={record.link}><Button type="primary" shape="round" style={{width:"100%"}}><i className="fal fa-paper-plane"/>&nbsp;Visit</Button></a></span>
                        }
					</>
                    }
				</div>
			),
            width: 150
		},
	];



	return (
        <div className="content_inner">
            <div style={{paddingTop:40}}>
                <SearchTable
                    columns={columns}
                    url="/my/resources"
                    scroll={{ x: "max-content" }}
				/>
            </div>
        </div>
	);
};