import React, { useState, useEffect  } from "react";
import useToken from "../hooks/useToken";
import axios from 'axios';


class apiCall {

    constructor(token) {
        this.token = token;
        this.response = null;
    }

    get(url,data){
        this.method = 'get';
        this.url = url;
        this.data = null;
        if(data){
            let appendCar;
            if(url.indexOf("?") == -1){
                appendCar = '?';
            }else{
                appendCar = '&';
            }
            let paramStr = new URLSearchParams(data).toString();
            this.url += appendCar+paramStr;
        }
        return this.run();
		this.progress = null;
    }
	post(url,data){

		this.method = 'post';
        this.url = url;
        this.data = data;
        return this.run();
	}
    patch(url,data){
        this.method = 'patch';
        this.url = url;
        this.data = data;
        return this.run();
    }
	put(url,data){
		this.method = 'put';
		this.url = url;
		this.data = data;
		return this.run();
	}
    delete(url,data){
        this.method = 'delete';
        this.url = url;
        this.data = data;
        return this.run();
    }
	file(url){
        this.method = 'get';
        this.url = url;
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Response-Type': 'blob'
        };

        let headers = this.headers;
        headers = {};
        if(this.token){
            headers['Authorization'] = 'Bearer '+this.token
        }else{
            let queryParams = new URLSearchParams(window.location.search);
            let jwt = queryParams.get('jwt');
            if(jwt){
                headers['Authorization'] = 'Bearer '+jwt;
            }
        }

        fetch(process.env.REACT_APP_API_ENDPOINT+url, {
            headers: headers,
        })
            .then(response => {

                if(response.status >= 200 && response.status < 400){
                    let headers = response.headers;
                    response.blob().then(blob => {
                        const contentDisposition = headers.get('content-disposition');
                        let fileName = contentDisposition.split("filename=")[1];
                        if(fileName[0] == '"'){
                            fileName = fileName.substring(1,fileName.length-1)
                        }
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        a.remove();
                    });
                }else{
                    response.json().then(json => {
                        console.log(['Error:', json]);
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        /*
        let resp = this.run();
        let self = this;
        resp.then(function (res) {
            let headers = self.response.headers;
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            const contentDisposition = headers['content-disposition'];
            let fileName = contentDisposition.split("filename=")[1];
            if(fileName[0] == '"'){
                fileName = fileName.substring(1,fileName.length-1)
            }

            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
        */
    }
    options(url,data){
        this.method = 'options';
        this.url = url;
        this.data = data;
        return this.run();
    }


	run() {
        let headers;
        let self = this;
        if (this.headers){
            headers = this.headers;
        }else{
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        if(this.token){
            headers['Authorization'] = 'Bearer '+this.token
        }else{
            let queryParams = new URLSearchParams(window.location.search);
            let jwt = queryParams.get('jwt');
            if(jwt){
                headers['Authorization'] = 'Bearer '+jwt;
            }
        }

		return axios.request({
			method: this.method,
			url: process.env.REACT_APP_API_ENDPOINT+this.url,
			data: this.data,
			headers: headers,
			onUploadProgress: (p) => {
				if(this.progress){
					this.progress(p.total);
				}
			}
		}).then (data => {
            self.response = data;
			return data.data;
		});
	}
}



export default function API2() {

		const {token} = useToken();

		return new apiCall(token);
};