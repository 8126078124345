import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {
    Form,Input,
} from 'antd';

export default function ProvisionalPeriod(props) {

    const [form] = Form.useForm();
    const history = useHistory();


    function getStartedClicked(data){
        history.push({
            pathname: '/signUp',
            state: data
        });
    }


    return (
        <div className="content_inner_padded" >
            <div className="container">

                <div className="ibn ibn-sb sec">
                    <div className="ebn ud-bn ud-pt obg uix-alter ebn-p9x sec">
                        <div className="cpnw cov xg">
                            <div className="main">
                                <div className="cpn al-g cl-g bg-g wd-g">
                                    <div className="title">
                                        <h1>3-Year Provisional Period
                                            <div className="breadcrumb">
                                                <span className="separator"> &gt; </span>
                                                <span className="current">3-Year Provisional Period</span>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg hs-bg is-basic" data-height="auto" style={{"backgroundImage":"url('https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/adversary-banner.jpg')"}}>
                            <div className="over tpb op01" style={{"opacity":"0.01"}}></div>
                        </div>
                    </div>
                </div>

                <div className="site-wrap ipg iopg ipg-n3-year sec">
                    <div className="wrap wrap-wd wrap-page gb-sd no-aside sec">
                        <div className="icontent sec">
                            <article className="epc epg">
                                <div className="ientry">
                                    <div
                                        className="section sec-sc sec basic row-xd sec-rg sec-intro ud-pad tpd-s bpd-s sec-a897190z">
                                        <div className="main">
                                            <div className="content">
                                                <p>The Certificate in Applied Sports Nutrition is the minimum
                                                    educational requirement to receive provisional Registration and
                                                    Insurance eligibility as an Accredited Sports Nutritionist.</p>
                                                <p>This is the ONLY way to be qualified and insured to provide nutrition
                                                    programming (macros and meal plans) for clients without a university
                                                    degree. The reason the Sports Nutrition Association is the only
                                                    organisation able to offer this is due to the policy they have
                                                    established (the 3-year provisional period) ensuring that after 3
                                                    years, its members continue their education in the field. Sports
                                                    Nutrition is an area that is very much still in it’s infancy and is
                                                    seeing regular continual advancements annually. Which is why the
                                                    Association provides annual education in the form of it’s CPD
                                                    (Continuing Professional Development) to it’s professional members
                                                    to ensure they are always in line with the current evidence informed
                                                    Best Practises ensuring that the public who engage them are
                                                    receiving a satisfactory minimum standar experience that considers
                                                    their health and nutritional progress at all times.</p>
                                                <h3>THE PROCESS</h3>
                                                <ul>
                                                    <li className="first"><strong>Study your Certificate in Applied
                                                        Sports Nutrition with the Sports Nutrition Association</strong>
                                                    </li>
                                                    <li><strong>Become Insured and Registered to practice as an
                                                        Accredited Sports Nutritionist for 3 years while keeping your
                                                        skills current with annual CPD</strong></li>
                                                    <li className="last"><strong>After the 3 year provisional period
                                                        either undertake additional study to be openly accredited to
                                                        cease working as a sports nutritionist.</strong></li>
                                                </ul>
                                                <p>The reason that the Association is one of a kind and able to make
                                                    this possible is due to its policy and pathways for continuing
                                                    education and experience development which our members highly
                                                    value.</p>
                                                <h3>What is the 3-year provisional period?</h3>
                                                <p>The 3-year provisional period is a period where we focus on
                                                    developing the experience of the professional member through time in
                                                    the role, annual CPD support and access to the most current
                                                    available research. During this period the member will be able to
                                                    ascertain if this is the career path for them, and for a small
                                                    percentage of people it won’t be, and that is perfectly fine. This
                                                    way they’ve been able to work this out without having to spend 4+
                                                    years at university and 50k+ on tuition fees, comparatively they’ve
                                                    invested a minimum of 5-7 months and 4k for the certificate.</p>
                                                <p>During or at the completion of this period the member will have been
                                                    able to establish whether this is the profession and career for
                                                    them, as well as continually gaining experience along the way. Once
                                                    they have made the decision that they want to become an Openly
                                                    Accredited Sports Nutritionist &amp; Professional member the
                                                    Association requires that they enrol in and complete either of the
                                                    following programs:</p>
                                                <ul>
                                                    <li className="first">A relevant* Bachelors in Applied Exercise,
                                                        Sports or Nutrition Sciences/Physiology
                                                    </li>
                                                    <li className="last">A relevant* PGDip. In Applied
                                                        Sports/Performance or Human/Clinical Nutrition
                                                    </li>
                                                </ul>
                                                <p>Only after completing a Relevant Bachelors, PGdip or any other
                                                    relevant post graduate qualification in the field does a member
                                                    become Openly Accredited.</p>
                                                <h3>Continued study is to ensure that you have the knowledge to provide
                                                    the best service to your clients.</h3>
                                                <p>The Association has many private institutes and universities that it
                                                    affiliates with and graduates of the certificate are either granted
                                                    immediate entry into, or afforded favourable applications to certain
                                                    PGDip and Bachelors programs.</p>
                                                <p>As such, study timeframes and costs to become Openly Accredited can
                                                    range from 9-months and 3.5k (3 recommended programs) through to 3-4
                                                    years and 40k+ (10+ other programs) with MSc and PHD programs adding
                                                    more time and expense. The Association has no preference as to which
                                                    program the member elects to go in.</p>
                                                <h3>If you want to work in a client centric sector you need to embody
                                                    that the learning never stops and be excited in knowing your skills
                                                    will be continually developed!</h3>
                                                <h3>Why the 3-year period? Why not just stop at the Certificate?</h3>
                                                <p>There is an existing precedent of minimum standard (bachelor’s or
                                                    GDip) in personalised nutrition services in the industry, and this
                                                    is shared globally. While the Association does not align with the
                                                    current model of education timeframes and costs superseding
                                                    experience, we also acknowledge the extensive depth of information
                                                    that encompasses human nutrition &amp; exercise physiology. The
                                                    position that no ‘short course’ can substantially cover this subject
                                                    matter that would provide a professional with the tools to be an
                                                    excellent practitioner is one we hold.</p>
                                                <ul>
                                                    <li className="first">To Provide the Absolute best service for your
                                                        clients.
                                                    </li>
                                                    <li className="last">Experience, Education &amp; constant
                                                        Progression of these.
                                                    </li>
                                                </ul>
                                                <h3>*Find out if this is the field for you without committing yourself
                                                    to a 4 year degree*</h3>
                                                <p>These values are ones that we want all current, future &amp; aspiring
                                                    members to hold. You should want the very best for your clients, and
                                                    a part of this is understanding that you don’t know everything. As a
                                                    result, respect &amp; consideration for the sound education and
                                                    experience required to provide your clients with your best is held,
                                                    along with an understanding that anything less than those will only
                                                    limit your ability to help them, is a paramount foundation.
                                                    Understanding and accepting that anything less is not in the best
                                                    interest of the public or your clients, and is ultimately a ‘quick
                                                    fix’. The exact thing you tell your clients to be wary of.</p>
                                                <p>It is the Association’s position that the Certificate Program, and
                                                    Accreditation Pathway is not for those looking for a ‘quick fix’,
                                                    and to make them aware that any claims for ‘quicker’ and ‘easier’
                                                    pathways are illegitimate and leave both the client exposed and the
                                                    coach liable as they are not insured and adequately qualified for
                                                    the services they are providing. ‘Hobby’ courses,
                                                    program &amp; workshops are not legitimate education or professional
                                                    registration.</p>
                                                <h3>There are no shortcuts to becoming a qualified and registered
                                                    Accredited Sports Nutritionist.<br/>
                                                        <em>And you should seriously question any claims that suggest or
                                                            imply otherwise</em></h3>
                                                <p>So if you align with the Association’s following values and
                                                    principles of:</p>
                                                <ul>
                                                    <li className="first">Valuing continual education so that you can be
                                                        the best possible professional and provide your clients with the
                                                        best possible service
                                                    </li>
                                                    <li className="last">You understand that the only other legitimate
                                                        comparison for actually practising in this profession and career
                                                        is 4-years of full time university,
                                                        <ul>
                                                            <li className="first">With that in mind following an
                                                                educational pathway that:
                                                            </li>
                                                            <li>Is 5-7 months of study initially before you start
                                                                working and es equally balanced in experiential
                                                                development as well as academic progression
                                                            </li>
                                                            <li className="last">Provides you with 3-years of experience
                                                                before you are required to enrol in further formal
                                                                education (but it can be done anytime within the 3-year
                                                                period) more
                                                                <ul>
                                                                    <li className="first">So you can confirm that this
                                                                        is the career for you
                                                                    </li>
                                                                    <li>And graduate that with a full client list</li>
                                                                    <li>Without spending 50k+ and years and years
                                                                        studying
                                                                    </li>
                                                                    <li className="last">While providing entry into
                                                                        specific Post graduate Programs
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="section sec-sc sec basic row-xd sec-rg sec-who-is light ud-pad ud-bgc sec-a471933z">
                                        <div className="main">
                                            <div className="content">
                                                <h2 style={{textAlign:"center"}}>Register &amp; Enrol in Your Region
                                                    Today</h2>
                                                <p style={{textAlign:"center"}}>Select your region</p>
                                                <div className="columns row-sc row ec-flex ec-nowrap">
                                                    <div className="eclm clm-sc g3 animated" data-animate="fadeInUp"
                                                         data-duration="1">
                                                        <div className="content">
                                                            <p style={{textAlign:"center"}}><a
                                                                href="https://sportsnutrition.org.au/course-enrolment/"
                                                                className="button btn-normal"
                                                                target="_blank"><span>Australia</span></a></p>
                                                        </div>
                                                    </div>
                                                    <div className="eclm clm-sc g3 animated" data-animate="fadeInUp"
                                                         data-duration="1">
                                                        <div className="content">
                                                            <p style={{textAlign:"center"}}><a
                                                                href="https://www.sportsnutrition-usa.com/course-enrolment/"
                                                                className="button btn-normal" target="_blank"><span>United States</span></a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="eclm clm-sc g3 animated" data-animate="fadeInUp"
                                                         data-duration="1">
                                                        <div className="content">
                                                            <p style={{textAlign:"center"}}><a
                                                                href="https://www.sportsnutrition.nz/course-enrolment/"
                                                                className="button btn-normal" target="_blank"><span>New Zealand</span></a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="eclm clm-sc g3 animated" data-animate="fadeInUp"
                                                         data-duration="1">
                                                        <div className="content">
                                                            <p style={{textAlign:"center"}}><a
                                                                href="https://www.sportsnutrition-asia.com/course-enrolment/"
                                                                className="button btn-normal"
                                                                target="_blank"><span>Asia</span></a></p>
                                                        </div>
                                                    </div>
                                                    <div className="eclm clm-sc g3 animated" data-animate="fadeInUp"
                                                         data-duration="1">
                                                        <div className="content">
                                                            <p style={{textAlign:"center"}}><a
                                                                href="https://www.sportsnutritionassociation.com/enrol/europe/"
                                                                className="button btn-normal"><span>Europe</span></a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}