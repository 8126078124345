import React, {useEffect, useState} from 'react';

import countryList from "react-select-country-list";
import { Link } from "react-router-dom";
import TermsAndConditionsText2 from "../pages/TermsAndConditionsText2";
import StudentIndemnityReleaseText from "../pages/StudentIndemnityReleaseText";

import { useHistory } from "react-router-dom";
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    Modal,
	Spin,
    DatePicker,
    Tag
} from 'antd';

import useToken from "../hooks/useToken";
import API2 from "../components/api2";
import {notification} from "antd/lib/index";
import Autocomplete from "react-google-autocomplete";
import useWindowDimensions from "../components/useWindowDimensions";

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

export default function EnrolForm(props) {

    const [form] = Form.useForm();
    const { user, setUser, geo } = useToken();
    const AP = API2();
    const { width } = useWindowDimensions();


    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [plans, setPlans] = useState([]);
    const [startDates, setStartDates] = useState([]);
    const [checkedCoupons, setCheckedCoupons] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const [modelOpen, setModalOpen] = useState(false);
    const [studentIndemnityModelOpen, setstudentIndemnityModelOpen] = useState(false);

    const [selectedDate, setSelectedDate] = useState('');
    const [planCost, setPlanCost] = useState('-');
    const history = useHistory();

    const [country, setCountry] = useState('');
    const [place, setPlace] = useState(null);
    const [region, setRegion] = useState('');
    const [planId, setPlanId] = useState('');


	const [authCardUrl, setAuthCardUrl] = useState('');
	const [transactionId, setTransactionId] = useState('');
	function close3DSecureModal(){
		setAuthCardUrl(null);
		form.submit();
	}

	const [cardCvc,    setCardCvc]    = useState('');
	const [cardExpiry, setCardExpiry] = useState('');
	const [cardFocus,  setCardFocus]  = useState('');
	const [cardName,   setCardName]   = useState('');
	const [cardNumber, setCardNumber] = useState('');
    const [applicationQuestions, setApplicationQuestions] = useState(false);

    const [discountPlan, setDiscountPlan] = useState('');
    const [couponMsg, setCouponMsg] = useState('');

    const rootContainer = document.getElementById('root');

    useEffect(() => {
        updatePlanCost();
    }, [planId]);

    useEffect(() => {
        setStartDates(props.startDates);

        setPlans(props.plans);

        let formData = {...props.formData};
        if(!formData) formData = {};

        if(formData.start_at) {
            setSelectedDate(formData.start_at);
            if(Object.keys(props.plans).length === 1){
                if(props.plans[formData.start_at].length === 1){
                    formData.plan_id = props.plans[formData.start_at][0].id;
                }
            }

            checkApplicationQuestions(formData.start_at);
        }

        if(!formData.country){
            let defaultCountry = null;
            try{
                const cBits = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/");
                if(cBits[0]){
                    for(const c of countryList().getData()){
                        if(c.label == cBits[0]){
                            defaultCountry = c.value;
                            setCountry(defaultCountry);
                            break;
                        }
                    }
                }
            }catch(error){

            }
            formData.country = defaultCountry;
        }

        if(props.formData.coupons){
            formData.coupons = [];
            for(const c of props.formData.coupons){
                formData.coupons.push(c.code);
            }
        }


        form.setFieldsValue(formData);

        if(formData.country) {
            countryOnChange(formData.country);
        }
        if(formData.plan_id){
            setPlanId(formData.plan_id);
        }

        
        

    }, [props.plans, props.startDates, props.formData]);

    useEffect(() => {
        if(isLoaded) return;
        if(!Object.keys(props.plans).length) return;

        if(props.formData.coupons){
            setIsLoaded(true);
            checkCoupon();
        }

    }, [plans]);

    const handleSubmit = async (data) => {
        
        if(!place){
            form.setFields([
                {
                    name: 'address',
                    errors: ['An address needs to be selected from the dropdown'],
                },
            ]);
            return false;
        }

        setIsSaving(true);

        data.region = props.match.params.region;
        data.place = place;
        if(transactionId){
			data.transactionId = transactionId;
		}

        if(data.dob){
            data.dob = data.dob.unix();
        }

        AP.post('/enrolCheck',data)
            .then(function(res){
                setIsSaving(false);
                props.onSave(data);
            })
            .catch(function(res){
				setIsSaving(false);

				if(res.response.data.action) {
					if(res.response.data.action.type == 'three_d_secure_redirect'){
						setAuthCardUrl(res.response.data.action.url);
						setTransactionId(res.response.data.action.transaction_id);
						setIsSaving(true);
					}
					else if(res.response.data.action.clear){
						if(res.response.data.action.clear == 'transaction_id'){
							setTransactionId(null);
						}
					}
				}

				if (res.response.data.errors) {
					let errorList = {};
					for (let error of res.response.data.errors) {
						if (!errorList[error.input]) errorList[error.input] = [];
						errorList[error.input].push(error.message);
					}
					for (let [input, errors] of Object.entries(errorList)) {
						form.setFields([
							{
								name: input,
								errors: errors,
							},
						]);
					}
				}
            });
    };

	const handleInputFocus = (e) => {
        if(e.type == "focus") {
            let id = e.target.id.substr(10);
            setCardFocus(id);
        }else{
            setCardFocus(null);
        }
	}

	function onFormChange(changedFields, allFields){

		let name  = changedFields[0].name[0];
		let value = changedFields[0].value;

		if(name=='cardcvc')    setCardCvc(value);
		if(name=='cardexpiry') setCardExpiry(value);
		if(name=='cardname')   setCardName(value);
		if(name=='cardnumber') setCardNumber(value);
	}

	function checkCoupon(){
		let coupons = form.getFieldValue("coupons");

        for (const [date, planz] of Object.entries(plans)) {
            for (let p of planz) {
                for (let pa of p.payments) {
                    pa.discount = 0;
                }
            }
        }

		if(!coupons){
			setPlans({...plans});
            setCouponMsg("");
            updatePlanCost();
			return;
        }

        AP.get('/coupon/check/'+coupons.join('|'))
            .then(function (res) {
            	if(res.total_records){

                    for(const c of res.data){
                        if(!checkedCoupons.find((cc)=>cc.id==c.id)){
                            checkedCoupons.push(c);
                        }
                    }
                    setCheckedCoupons([...checkedCoupons]);

                    for (const [date, planz] of Object.entries(plans)) {
                        for (let plan of planz) {
                            //check for deposits
                            let depositTotal = {}
                            let totalAmount  = {};
                            for (let payment of plan.payments) {
                                if(!depositTotal[payment.region]) depositTotal[payment.region] = 0;
                                if(!totalAmount[payment.region])  totalAmount[payment.region] = 0;

                                if(payment.billing_start == 'deposit'){
                                    depositTotal[payment.region] += (payment.amount*payment.billing_rounds);
                                }
                                totalAmount[payment.region] += (payment.amount*payment.billing_rounds);
                            }

                            //check discount after deposit
                            const regionDiscount = {};
                            for (const [region, amount] of Object.entries(totalAmount)) {
                                if(!regionDiscount[region]) regionDiscount[region] = 0;

                                for(const cc of res.data){

                                    
                                    let discountedAmount = amount;
                                    if (cc.type == 'fixed') {
                                        discountedAmount -= cc.discount;
                                    }
                                    else {
                                        discountedAmount *=  (cc.discount / 100);
                                    }

                                    if(!regionDiscount[region]) regionDiscount[region] = 0;
                                    regionDiscount[region] += discountedAmount/(amount-depositTotal[region]);
                                }
                            }

                            for (let payment of plan.payments) {

                                if(payment.billing_start == 'deposit') continue;

                                let d;
                                if (res.data[0].type == 'fixed') {
                                    d = regionDiscount[payment.region];
                                }
                                else {
                                    d = payment.amount * (regionDiscount[payment.region]);
                                }
                                payment.discount = d;
                            }
                        }
                    }

                    setCouponMsg(<span style={{color:"green"}}><i className="fas fa-check-circle"></i></span>);
				}else{
                    setCouponMsg(<span style={{color:"red"}}><i className="fas fa-times-circle"></i></span>);
				}

                setPlans({...plans});
                updatePlanCost();
            });

	}

	function updatePlanCost(){
	    if(!planId) return false;

        for(let plan of plans[selectedDate]){
            if(plan.id == planId){
                let total = 0;
                let firstPayment;
                for(let payment of plan.payments){
                    if(payment.region && payment.region != region) continue;
                    if(!firstPayment) firstPayment = payment;
                    total += payment.amount*payment.billing_rounds;
                    if(payment.discount){
                        total -= payment.discount;
                    }
                }

                if(firstPayment && firstPayment.amount > 0) {
                    let firstAmount = firstPayment.amount;
                    if (firstPayment.discount) {
                        firstAmount -= firstPayment.discount;
                    }
                    setPlanCost('$'+(Math.round(firstAmount)/100)+" "+firstPayment.currency.toUpperCase());
                }else{
                    setPlanCost('Free');
                }


            }
        }
    }

    function countryOnChange(country){
        setCountry(country);

        if(!country) return false;

        let region;
        if(country == 'AU'){
            region = 'australia';
        }
        else if(country == 'NZ'){
            region = 'newzealand';
        }
        else if(country == 'US'){
            region = 'unitedstates';
        }
        else if(country == 'CA'){
            region = 'canada';
        }
        else if(['AT','BE','BG','HR','CY','CZ','CH','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','GB'].indexOf(country) !== -1){
            region = 'europe';
        }
        else if(['AF','AM','AZ','BH',',BT','BN','KH','CN','CX','CC','IO','GE','HK','IN','ID','IR','IQ','IL','JP','JO','KZ','KW','KG','LA','LB','MO','MY','MV','MN','MM','NP','KP','OM','PK','PS','PH','QA','SA','SG','KR','LK','SY','TW','TJ','TH','TR','TM','AE','UZ','VN','YE'].indexOf(country) !== -1){
            region = 'asia';
        }
        else{
            region = 'unitedstates';
        }

        setRegion(region);
    }

    const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length).length;

    const options = {
        componentRestrictions: { country: country },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["address"]
    };

    function checkApplicationQuestions(selected){
        if(selected == 'December Intake - 05/12/22'){
            setApplicationQuestions(true);
        }else{
            setApplicationQuestions(false);
        }
    }

    let formatter;
    if(props.exchangeRates){
        try{
            formatter = new Intl.NumberFormat(geo?.languages, {
                style: 'currency',
                currency: geo?.currency?.code,
                currencyDisplay: 'narrowSymbol',
                maximumFractionDigits: 0,
            });
        }catch(error){

        }
    }

    return(
    	<>
            {isSaved &&
            <h1 style={{textAlign:"center",color:"green", paddingTop:100}}><i className="fas fa-check"></i> Successfully Enrolled</h1>
            }
            {!isSaved &&
            <Form
                {...formItemLayout}
                layout="vertical"
                form={form}
                name="enrol"
                onFinish={handleSubmit}
                initialValues={{
                    residence: ['zhejiang', 'hangzhou', 'xihu'],
                    prefix: '86',
                }}
                scrollToFirstError
                style={{margin: "auto"}}
                onFieldsChange={onFormChange}
                className="style1"
            >
                <h3>Date of Intake</h3>
                <Form.Item
                    name="start_at"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your last name!',
                            whitespace: true
                        }
                    ]}
                >
                    <Select
                        placeholder="Select An Intake"
                        onChange={function (e) {
                            setSelectedDate(e);
                            checkApplicationQuestions(e);
                        }}
                        getPopupContainer={() => rootContainer}
                    >
                        {startDates.map(item => (
                            <Option key={item} value={item}>
                                {item}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <h3 style={{color: "#01a4f9"}}>Personal Details</h3>
                <div className="ant-row col2">
                    <div>
                        <div className="custom-form-item required">
                            <Form.Item
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your first name!',
                                        whitespace: true
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <label htmlFor={`enrol_first_name`} className="custom-label">First Name</label>
                        </div>
                    </div>
                    <div>
                        <div className="custom-form-item required">
                            <Form.Item
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your last name!',
                                        whitespace: true
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <label htmlFor={`enrol_last_name`} className="custom-label">Last Name</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="custom-form-item required">
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                    whitespace: true
                                },
                                {
                                    type: "email",
                                    message: "The input is not valid E-mail!"
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <label htmlFor={`enrol_email`} className="custom-label">Email</label>
                    </div>
                </div>
                <div>
                    <div className="custom-form-item required">
                        <Form.Item
                            name="country"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your country!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                autoComplete="none"
                                onChange={countryOnChange}
                                placeholder=<span style={{color:"pink"}}>*Country - This will affect the currency of the plans</span>
                                filterOption={function(input, option){
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                getPopupContainer={() => rootContainer}
                                >
                                {countryList().getData().map(item => (
                                    <Select.Option key={item.value} value={item.value}>
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <label htmlFor={`enrol_country`} className="custom-label">Country</label>
                    </div>
                </div>



                <div>
                    {country &&
                        <div className="custom-form-item required forceFocus">
                            <Form.Item
                                name="address"
                                rules={[
                                    {
                                        required:true,
                                        message:'Required!'
                                    }
                                ]}
                            >
                                <Autocomplete
                                    apiKey={'AIzaSyB81mQ2qjrgeCBEb-FsKtzKusncSPlu_nQ'}
                                    onPlaceSelected={(place, inputRef, autocomplete) => {
                                        if(!place.address_components){
                                            setPlace(null);
                                            return false;
                                        }
                                        form.setFieldValue('address',inputRef.value);
                                        setPlace(place);
                                    }}
                                    className="ant-input "
                                    type="text"
                                    options={options}
                                    disabled={!country}
                                />
                            </Form.Item>
                            <label htmlFor={`enrol_address`} className="custom-label">Address</label>
                        </div>
                    }
                    {!country &&
                        <div className="custom-form-item">
                            <Form.Item>
                                <Input
                                    disabled
                                    placeholder="Please select country first"
                                />
                            </Form.Item>
                            <label htmlFor={`enrol_address`} className="custom-label">Address</label>
                        </div>
                    }
                </div>

                <div className="ant-row col2">
                    <div>
                        <div className="custom-form-item">
                            <Form.Item
                                name="dob"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <DatePicker placeholder=" " format="DD/MM/YYYY"/>
                            </Form.Item>
                            <label htmlFor={`enrol_dob`} className="custom-label">Date Of Birth</label>
                        </div>
                    </div>
                    <div>
                        <div className="custom-form-item required">
                            <Form.Item
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <label htmlFor={`enrol_mobile`} className="custom-label">Mobile</label>
                        </div>
                    </div>
                </div>

                <h3 style={{color: "#01a4f9"}}>Password</h3>
                <div className="ant-row col2">
                    <div>
                        <div className="custom-form-item required">
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                                hasFeedback
                                className="required"
                            >
                                <Input.Password style={{padding:"17px 15px 18px"}} placeholder=" " readOnly={!!isLoading2} onFocus={()=>setIsLoading2(false)}/>
                            </Form.Item>
                            <label htmlFor={`enrol_password`} className="custom-label">Password</label>
                        </div>
                    </div>
                    <div>
                        <div className="custom-form-item required">
                            <Form.Item
                                name="confirm"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('The two passwords that you entered do not match!');
                                        },
                                    }),
                                ]}
                                className="required"
                            >
                                <Input.Password style={{padding:"17px 15px 18px"}} placeholder=" " readOnly={!!isLoading2} onFocus={()=>setIsLoading2(false)}/>
                            </Form.Item>
                            <label htmlFor={`enrol_confirm`} className="custom-label">Confirm Password</label>
                        </div>
                    </div>
                </div>


                <h3>Additional Information</h3>
                {applicationQuestions && <>
                    <div className="custom-form-item required">
                        <Form.Item
                            name="why_details"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Required'
                                }
                            ]}
                            style={{flexDirection: "initial"}}
                        >
                            <TextArea rows={6} placeholder="Please provide a couple of sentences explaining this for us"/>
                        </Form.Item>
                        <label htmlFor={`enrol_why_details`} className="custom-label">Why does the December intake work the best for your situation?</label>
                    </div>

                    <div className="custom-form-item required">
                        <Form.Item
                            name="achieve_details"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Required'
                                }
                            ]}
                            style={{flexDirection: "initial"}}
                        >
                            <TextArea rows={6} placeholder=" "/>
                        </Form.Item>
                        <label htmlFor={`enrol_achieve_details`} className="custom-label">Specifically, what are you hoping to get out of the Certificate in Applied Sports Nutrition?</label>
                    </div>

                    <div className="custom-form-item required">
                        <Form.Item
                            name="handle_details"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Required'
                                }
                            ]}
                            style={{flexDirection: "initial"}}
                        >
                            <TextArea rows={6}
                                    placeholder="Include in your response how you would approach asking questions, requesting extensions and responding to feedback"/>
                        </Form.Item>
                        <label htmlFor={`enrol_handle_details`} className="custom-label">Please provide a few sentences on how you will approach timely, open and professional communication with the team.</label>
                    </div>

                </>
                }



                {/*
                <div className="custom-form-item">
                    <Form.Item
                        name="qualifications"
                    >
                        <Input/>
                    </Form.Item>
                    <label htmlFor={`enrol_qualifications`} className="custom-label">Current Qualifications</label>
                </div>
                */}

                <div className="custom-form-item">
                    <Form.Item
                        name="instagram"
                    >
                        <Input/>
                    </Form.Item>
                    <label htmlFor={`enrol_instagram`} className="custom-label">Instagram Handle</label>
                </div>

                <div className="custom-form-item">
                    <Form.Item
                        name="referred_by"
                    >
                        <Input/>
                    </Form.Item>
                    <label htmlFor={`enrol_referred_by`} className="custom-label">Referred By</label>
                </div>

                <div className="custom-form-item">
                    <Form.Item
                        name="additional_info"
                    >
                        <Input.TextArea rows={6} placeholder=" "/>
                    </Form.Item>
                    <label htmlFor={`enrol_additional_info`} className="custom-label">Any additional information to support your application</label>
                </div>


                
                <h3 style={{color: "#01a4f9"}}>Payment Details</h3>
                <h4>Certificate In Applied Sports Nutrition</h4>
                <Form.Item
                    name="plan_id"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a plan!'
                        }
                    ]}
                >
                    <Select
                        placeholder={selectedDate ? <span>Select a plan option (required)</span> : "Please select a date first"}
                        disabled={!selectedDate || !country}
                        onChange={function (e) {
                            setPlanId(e)
                        }}
                        getPopupContainer={() => rootContainer}
                    >
                        {region && selectedDate && plans[selectedDate] && plans[selectedDate].map(item => {
                            if(item.disabled_regions && item.disabled_regions[region]) return '';

                            let x = 0;
                            let x2 = 0;
                            return <Option key={item.id} value={item.id}>
                                <div style={{display: "flex", height: 0}}>
                                    <div style={{paddingRight:20, minWidth:100}}>{item.name}</div>
                                    <div style={{fontSize: 12}}>
                                        {item.payments.map(payment => {
                                            if(payment.region && payment.region != region) return "";
                                            let html = "";
                                            if(x>0) html += ", ";
                                            html += "(" + (payment.billing_rounds?payment.billing_rounds + " x ":"")+"$"+Math.round(payment.amount-(payment.discount?payment.discount:0)) / 100 + " "+payment.currency.toUpperCase()+") ";

                                            x++;
                                            return html;
                                        })}
                                    </div>
                                    {!!props.exchangeRates && formatter && <>
                                        <div style={{marginLeft:"auto", color:"lightgray"}}>
                                            <span>estimated </span>
                                            {item.payments.map(payment => {
                                                //abort if no exchange rate
                                                if(!props.exchangeRates[payment.currency.toLowerCase()]) return " - ";

                                                if(payment.region && payment.region != region) return "";
                                                let html = "";
                                                if(x2>0) html += ", ";
                                                html += "(" + (payment.billing_rounds?payment.billing_rounds + " x ":"")+formatter.format(Math.round(((payment.amount-(payment.discount?payment.discount:0))*props.exchangeRates[payment.currency.toLowerCase()]) / 100)) + " "+geo.currency.code.toUpperCase()+") ";

                                                x2++;
                                                return html;
                                            })}
                                        </div>
                                    </>}
                                </div>
                            </Option>
                        })}
                    </Select>

                </Form.Item>


                {/*
                    {planCost != 'Free' &&
                    <Form.Item>

                        <Row gutter="20" style={{justifyContent:"center"}}>
                            <Col className="zoomed">
                                <Cards
                                    cvc={cardCvc}
                                    expiry={cardExpiry}
                                    focused={cardFocus}
                                    name={cardName}
                                    number={cardNumber}
                                />


                            </Col>


                            <Col flex="1">
                                <div style={{paddingTop: "20px", minWidth:240}}>
                                    <div className="custom-form-item">
                                        <Form.Item
                                            name="cardnumber"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required!',
                                                    whitespace: true
                                                }
                                            ]}
                                        >
                                            <Input
                                                type="tel"
                                                onFocus={handleInputFocus}
                                            />
                                        </Form.Item>
                                        <label htmlFor={`enrol_cardnumber`} className="custom-label">Number</label>
                                    </div>

                                    <div className="custom-form-item">
                                        <Form.Item
                                            name="cardname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required!',
                                                    whitespace: true
                                                }
                                            ]}
                                        >
                                            <Input
                                                onFocus={handleInputFocus}
                                            />
                                        </Form.Item>
                                        <label htmlFor={`enrol_cardname`} className="custom-label">Name</label>
                                    </div>

                                
                                    <Row gutter="20">
                                        <Col style={{width: "125px"}}>
                                            <div className="custom-form-item">
                                                <Form.Item
                                                    name="cardexpiry"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Required!',
                                                            whitespace: true
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        onFocus={handleInputFocus}
                                                    />
                                                </Form.Item>
                                                <label htmlFor={`enrol_cardexpiry`} className="custom-label">Expiry</label>
                                            </div>
                                        </Col>
                                        <Col flex={"1"}>
                                            <div className="custom-form-item">
                                                <Form.Item
                                                    name="cardcvc"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Required!',
                                                            whitespace: true
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        onFocus={handleInputFocus}
                                                        onBlur={handleInputFocus}
                                                    />
                                                </Form.Item>
                                                <label htmlFor={`enrol_cardcvc`} className="custom-label">CVC</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </Form.Item>
                    }
                */}


                <Form.Item
                    style={{flex: 1, margin: 0}}
                    name="terms_agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Accepting agreement is required'),
                        },
                    ]}
                >
                    <Checkbox style={{zoom:1.5}}>
                        <span>I have read and agree to the </span>
                        <a href="https://sportsnutritionassociation.com/terms/" target="_blank" style={{borderBottom:"1px solid #50A6F1", color:"black"}}>terms & Conditions</a>
                        {/* 
                        <a onClick={() => setModalOpen(true)} style={{borderBottom:"1px solid #50A6F1", color:"black"}}>terms and conditions</a>
                        */}
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    style={{flex: 1}}
                    name="indemnity_agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Accepting agreement is required'),
                        },
                    ]}
                >
                    <Checkbox style={{zoom:1.5}}>
                        <span>I have read and agree to the </span>
                        <a onClick={() => setstudentIndemnityModelOpen(true)} style={{borderBottom:"1px solid #50A6F1", color:"black"}}>student indemnity release</a>
                    </Checkbox>
                </Form.Item>

                <div style={{display: "flex", gap:"0 50px", flexWrap:width<400?'wrap':'nowrap', alignItems: "center", marginTop:50}}>
                    <div style={{display: "flex", minWidth:190, flex:1}}>
                        <h3 style={{width: 100, padding:0, margin:0}}>Total:</h3>
                        <div style={{flex: 1, fontSize: 18, color: "#060", lineHeight: "30px"}}>{planCost}</div>
                    </div>
                    
                    {planCost != 'Free' &&
                        <div style={{width: 600, display:"none"}}>
                            <div style={{display: "flex"}}>
                                <div style={{position: "relative", flex: 1}}>
                                    <Form.Item
                                        name="coupons"
                                        label="Coupons"
                                    >
                                        <Select
                                            mode="tags"
                                            options={props?.formData?.coupons?.map((coupon)=>({
                                                value:coupon.code,
                                                label:coupon.code
                                            }))||[]}
                                            tagRender={(props)=>{
                                                const { label, value, closable, onClose } = props;
                                                return <Tag
                                                // onMouseDown={onPreventMouseDown}
                                                    closable={closable}
                                                    onClose={onClose}
                                                    style={{
                                                        marginRight: 3,
                                                    }}
                                                >
                                                    <span style={{color:"black"}}>{label} {checkedCoupons.find(cc=>cc.code==label)
                                                        ?<i className="far fa-check-circle" style={{color:"green"}}/>
                                                        :<Spin size="small"/>}
                                                    </span>
                                                </Tag>
                                            }}
                                            /*
                                            onKeyPress={function (event) {
                                                if (event.key === 'Enter') {
                                                    checkCoupon();
                                                    event.preventDefault();
                                                    return false;
                                                }
                                            }}
                                            */
                                            onSelect={function (event) {
                                                checkCoupon();
                                            }}
                                        />
                                    </Form.Item>
                                    <span style={{position: "absolute", right: 10, top: 9, fontSize: 30}}>{couponMsg}</span>
                                </div>
                                <Button
                                    type="primary"
                                    shape="round"
                                    size={"large"}
                                    onClick={() => checkCoupon()}
                                    style={{margin: 9}}
                                >Apply</Button>
                            </div>
                        </div>
                    }

                    <div className="ant-row demo-row" style={{width:width<1000?'100%':300}}>
                        <div style={{flex: 1, display:"flex", justifyContent:"right"}}>
                            {!!hasErrors &&
                                <span style={{paddingRight:30, lineHeight: "40px", color:"red"}}>Form requires attention</span>
                            }
                            <Button
                                type="primary"
                                shape="round" size={"large"}
                                loading={isSaving}
                                danger={hasErrors}
                                style={{height:70}}
                                onClick={()=>(form.submit())}

                            >{(isSaving) ? 'Saving' : 'Enrol Now'}</Button>
                        </div>
                    </div>
                </div>
                {/* 
                <div style={{marginTop:50, maxWidth:520, fontFamily:'Segoe UI'}}>
                    <p>We're thrilled about your interest in our nutrition program! To ensure you have a rewarding learning experience and thrive in your future career, students are required to complete a short application process.</p>
                    <p>This process helps us get to know you better and assess your:
                        <ul style={{listStyleType:"disc", paddingInlineStart:40}}>
                            <li>Readiness for online learning</li>
                            <li>Existing nutrition knowledge</li>
                            <li>Commitment to openness, proactive communication, responsiveness and follow-through</li>
                        </ul>
                    </p>
                    <p>We believe these qualities are essential for building a supportive learning community and empowering you to succeed as a nutrition professional.</p>
                    <p>Ready to take the next step? Secure your deposit now and let's explore your potential together!</p>
                </div>
                */}
                

                <Form.Item
                    name="url_id"
                >
                    <Input type="hidden"/>
                </Form.Item>

                
            </Form>
            }

            <Modal
                visible={modelOpen}
                title={<h3 style={{fontSize:"23px"}}>Terms and Conditions of Accreditation and Registered Membership</h3>}
                onCancel={()=>setModalOpen(false)}
				//centered={true}
                width={"80vw"}
                footer={<Button type="primary" size="small" shape="round" onClick={()=>setModalOpen(false)}>Close</Button>}
            >
				<div  style={{maxHeight:"70vh", overflow:"auto"}}>
					<TermsAndConditionsText2/>
				</div>

            </Modal>

            <Modal
                open={studentIndemnityModelOpen}
                title={<h4>Student Indemnity Release</h4>}
                onCancel={()=>setstudentIndemnityModelOpen(false)}
                //centered={true}
                width={"80vw"}
                style={{maxWidth:770}}
                footer={<Button type="primary" size="small" shape="round" onClick={()=>setstudentIndemnityModelOpen(false)}>Close</Button>}
            >
                <StudentIndemnityReleaseText/>
            </Modal>


			{!!authCardUrl &&
                <Modal
                    visible={authCardUrl}
                    title={<h3 style={{fontSize: "23px"}}>3D Secure Authentication</h3>}
                    onCancel={() => close3DSecureModal()}
                    //centered={true}
                    width={"80vw"}
                    footer={<Button
                        type="primary"
                        size="small"
                        shape="round"
                        className="btn-red"
                        onClick={() => close3DSecureModal()}
                        style={{textTransform:"initial"}}
                    >Please close only after completing 3D secure.</Button>}
                >
                    <div style={{maxHeight: "70vh", overflow: "auto", display:"flex", height:"70vh"}}>
                        <iframe src={authCardUrl} style={{width:"100%"}}></iframe>
                    </div>

                </Modal>
			}
		</>
    )
}

