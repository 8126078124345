import React, {Component, useContext, useEffect, useState, useRef, createRef } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumbContext from "../contexts/breadCrump";
import { Link } from "react-router-dom";
import API2 from "../components/api2";
import {
    Table,
    Input,
    Button,
    Card,
    Avatar,
    Progress,
    Tooltip,
    Select,
    Checkbox,
    DatePicker,
    Modal,
    Dropdown,
    Menu
} from 'antd';

import { DownOutlined } from '@ant-design/icons';
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";

import useToken from "../hooks/useToken";
import SearchTable from "../components/SearchTable";

import ReactDOM from 'react-dom';
import { Bar,Column,Line } from '@ant-design/plots';

import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import moment from 'moment';
import axios from "axios/index";
import useWindowDimensions from "../components/useWindowDimensions";


const { Option } = Select;
const { RangePicker } = DatePicker;
const ReactGridLayout = WidthProvider(RGL);

//navigator.clipboard.writeText('Copy this text to clipboard')

export default function AdminDashboardAssociation(props) {

    var CanvasJSReact = require('../assets/canvasjs.react');
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.default.CanvasJSChart;
    const { height, width } = useWindowDimensions();

    const [data, setData] = useState(false);
    const AP = API2();
    const history = useHistory();
    const { user, setUser } = useToken();

    const [isLoading, setIsLoading] = useState(true);
    const [dashboardLayout, setDashboardLayout] = useState([]);
    const [origDashboardLayout, setOrigDashboardLayout] = useState([]);
    const [forceDashboardLayout, setForceDashboardLayout] = useState(0);
    const [dashboardEdit, setDashboardEdit] = useState(false);
    const [dashboardVerticalCompact, setdashboardVerticalCompact] = useState(true);
    const [dashboardAddModal, setDashboardAddModal] = useState(false);
    const [gridRef, setGridRef] = useState(true);

	const setBreadCrumbs = useContext(BreadCrumbContext);

	function saveDashboard(layout){

	    if(isLoading) return true;

        const newOrig  = [];
	    let hasChanged = false;
	    if(origDashboardLayout.length != layout.length) hasChanged = true;
	    else{
	        checkLoop:
	        for(const k in layout){
	            for(const k2 of ['h','static','w','x']){ //,'y'
                    if(layout[k][k2] != origDashboardLayout[k][k2]){

                        if(k2 === 'h'){

                            if(width < 2275){
                                if(layout[k].i == "studentRegionStats"){
                                    if(layout[k]['h'] == origDashboardLayout[k][k2]*2) {
                                        continue;
                                    }
                                }
                                else if(layout[k].i == "memberRegionStats"){
                                    if(layout[k]['h'] == origDashboardLayout[k][k2]*2) {
                                        continue;
                                    }
                                }

                            }else{
                                if(layout[k].i == "studentRegionStats"){
                                    if(layout[k]['h'] == origDashboardLayout[k][k2]) {
                                        continue;
                                    }
                                }
                                else if(layout[k].i == "memberRegionStats"){
                                    if(layout[k]['h'] == origDashboardLayout[k][k2]) {
                                        continue;
                                    }
                                }
                            }
                        }

                        hasChanged = true;
                        break checkLoop;
                    }
                }
         //       newOrig.push({...layout[k]});
            }
        }

        if(!hasChanged) return true;
        debugger;
    //    setOrigDashboardLayout(newOrig);

        AP.post('/my/dashboard',{
                "dashboard" : {
                    for: "association",
                    layout: layout,
                    verticalCompact: dashboardVerticalCompact,
                }
            })
            .then(function(res){

            });
    }


    useEffect(() => {

        setBreadCrumbs([<span><i className="fas fa-home"></i> Dashboard</span>]);

        AP.get('/dashboard?for=association')
            .then(function (res) {

                setData(res);
                let lod;
                if(res.dashboard && res.dashboard.association){
                    const data = res.dashboard.association;
                    lod = data.layout;
                }else{
                    lod = getDefaultLayout();
                }

                const origDashboardLayout = [];
                for(const lo of lod){
                    origDashboardLayout.push({...lo});
                    lo.origH = lo.h;
                }

                setOrigDashboardLayout(origDashboardLayout);
                setDashboardLayout(lod);
                setIsLoading(false);
                setForceDashboardLayout(forceDashboardLayout+1);
            });
    }, []);

    useEffect(() => {
        if(forceDashboardLayout === 0) return;
        updateLayout();
    }, [width,forceDashboardLayout]);


    function updateLayout(){
        if(!dashboardLayout.length) return true;

        if(width < 2275){
            for(const item of dashboardLayout){
                if(item.i == "studentRegionStats"){
                    item.h = item.origH * 2;
                }
                else if(item.i == "memberRegionStats"){
                    item.h = item.origH * 2;
                }
            }
        }else{
            for(const item of dashboardLayout){
                if(item.i == "studentRegionStats"){
                    item.h = item.origH;
                }
                else if(item.i == "memberRegionStats"){
                    item.h = item.origH;
                }
            }
        }


        setIsLoading(true);
        setTimeout(function(){
            setIsLoading(false);
        },1);

        setDashboardLayout([...dashboardLayout]);
    }



    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    const trackingColumns = [
        {
            title: 'Association Global',
            key: 'users.first_name',
            dataIndex: 'users.first_name',
            sorter: (a, b) => {
                return (!a.first_name) ? -1 : a.first_name.localeCompare(b.first_name)
            },
            align: 'left',
            render: (text, record) => (
                <div></div>
            )
        },
        {
            title: 'Association Global',
            children: [
                {
                    title: '02/08/2022',
                    dataIndex: 'age',
                    key: 'age',
                    width: 150,
                    sorter: (a, b) => a.age - b.age,
                },
            ],
        },
        {
            title: 'Date',
            children: [
                {
                    title: '02/08/2022',
                    dataIndex: 'age',
                    key: 'age',
                    width: 150,
                    sorter: (a, b) => a.age - b.age,
                },
            ],
        },
    ];

    function dashboardEditChange(e){
        setDashboardEdit(e.target.checked);
    }
    function dashboardVerticalCompactChange(e){
        setdashboardVerticalCompact(e.target.checked);
    }
    function dashboardRemoveItem(i){
        setDashboardLayout(_.reject(dashboardLayout, { i: i }));
    }
    function dashboardAddItem(l){
        l.y = Infinity;
        l.x = 0;

        dashboardLayout.push(l);
        setDashboardLayout(dashboardLayout);

    }

    function onLayoutChange(layout) {
        saveDashboard(layout);
        //setDashboardLayout(layout);
    }
    function onLayoutReset() {
        setDashboardLayout(getDefaultLayout());
    }

    function getDefaultLayout(){
        return [
            /*
            {
                "x": 100,
                "y": 0,
                "w": 12,
                "h": 28,
                "i": "trackingTable",
                "type": "trackingTable"
            },
            */
            {
                "x": 1,
                "y": 0,
                "w": 5,
                "h": 9,
                "i": "studentStats",
                "type": "studentStats"
            },
            {
                "x": 7,
                "y": 0,
                "w": 5,
                "h": 9,
                "i": "memberStats",
                "type": "memberStats"
            },
            {
                "x": 0,
                "y": 10,
                "w": 12,
                "h": 11,
                "i": "studentRegionStats",
                "type": "studentRegionStats"
            },
            {
                "x": 0,
                "y": 20,
                "w": 12,
                "h": 11,
                "i": "memberRegionStats",
                "type": "memberRegionStats"
            },
        ];
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function GenerateWidget(props){
        let item = props.item;
        let i = props.index;

        if(item.i == 'trackingTable'){

            return <div key={item.i}>
                <h4>Tracking</h4>

                <div style={{paddingTop:30}}>
                    <SearchTable
                        columns={trackingColumns}
                        url="/users/overdue?for=students"
                        container={gridRef}
                        containerIndex={i}
                        pagination={{ pageSize: 99999}}
                        className="noPadding"
                    />
                </div>
            </div>
        }
        else if(item.i == 'studentStats'){
            return <div key={item.i}>
                <Card bordered={false} style={{ width: 600 }} className="cardFlex">
                    <div style={{display:"flex",flexDirection:"column",height:"100%"}}>
                        <p style={{fontSize:"20px",textAlign:"center"}}>Enrolled Students:  {data.totalRegionUsers.total.student.COUNTYTD} <span style={{fontSize:10}}>YTD</span></p>
                        <p style={{fontSize:"20px",textAlign:"center"}}>Outstanding Revenue: ${data.studentGroupedIncomeData.total.total.unpaid/100}</p>
                        <p style={{fontSize:"20px",textAlign:"center"}}>Average Cost/Student:  ${Math.round(data.studentGroupedIncomeData.total.total.paid2021/data.totalRegionUsers.total.student.COUNT2021)/100}</p>
                        <div style={{borderBottom:"1px solid lightgrey", width:"80%",margin:"auto", position:"relative", top:"-6px"}}></div>
                        <p style={{fontSize:"20px",textAlign:"center"}}>Previous: ${data.studentGroupedIncomeData.past.total.unpaid/100}</p>
                        <p style={{fontSize:"20px",textAlign:"center"}}>Current:  ${data.studentGroupedIncomeData.current.total.unpaid/100}</p>
                        <p style={{fontSize:"20px",textAlign:"center"}}>Future:  ${data.studentGroupedIncomeData.future.total.unpaid/100}</p>
                    </div>
                </Card>
            </div>
        }
        else if(item.i == 'memberStats'){
            return <div key={item.i}>

                <Card bordered={false} style={{ width: 600 }} className="cardFlex">
                    <div style={{display:"flex",flexDirection:"column",height:"100%"}}>
                        <p style={{fontSize:"20px",textAlign:"center"}}>Members: {data.totalRegionUsers.total.member.total.COUNT}</p>
                        <p style={{fontSize:"20px",textAlign:"center"}}>Annual: {data.totalRegionUsers.total.member.Annual.COUNT}</p>
                        <p style={{fontSize:"20px",textAlign:"center"}}>Monthly: {data.totalRegionUsers.total.member.Monthly.COUNT}</p>
                        <div style={{borderBottom:"1px solid lightgrey", width:"80%",margin:"auto", position:"relative", top:"-6px"}}></div>

                        <p style={{fontSize:"20px",display:"flex", justifyContent:"center", gap:10}}>
                            <div style={{width:200,textAlign:"right"}}>Income Summary:</div>
                            <div style={{width:140,textAlign:"left"}}>${data.memberGroupedIncomeData.total.total.paid/100}</div>
                        </p>
                        <p style={{fontSize:"20px",display:"flex", justifyContent:"center", gap:10}}>
                            <div style={{width:200,textAlign:"right"}}>Overdue Summary:</div>
                            <div style={{width:140,textAlign:"left"}}>${data.memberGroupedIncomeData.total.total.unpaid/100}</div>
                        </p>
                        <p style={{fontSize:"20px", display:"flex", justifyContent:"center", gap:10}}>
                            <div style={{width:200,textAlign:"right"}}>Debt Summary:</div>
                            <div style={{width:140,textAlign:"left"}}>${data.memberGroupedIncomeData.total.total.unpaidDebt/100}</div>
                        </p>
                    </div>
                </Card>
            </div>
        }
        else if(item.i == 'studentRegionStats'){
            return <div key={item.i}>
                <div>Students</div>
                <div style={{display:"flex",gap:10, flexWrap:"wrap", justifyContent:"center"}}>
                    {Object.entries(data.totalRegionUsers).map(([region,rData]) => {
                        if(region == 'total') return true;
                        let avgCS = '-';
                        if(rData.student.COUNT2021){
                            avgCS = '$'+Math.round(data.studentGroupedIncomeData.total[region].paid2021/rData.student.COUNT2021)/100;
                        }
                        return <Card title={<div style={{textAlign: "center"}}>{capitalizeFirstLetter(region)}</div>}
                                  bordered={false} style={{minWidth: 300}} className="cardFlex">
                                <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                                    <p style={{fontSize: "20px", textAlign: "center"}}>Students: {rData.student?rData.student.COUNTYTD:0} <span style={{fontSize:10}}>YTD</span></p>
                                    <p style={{fontSize: "20px", textAlign: "center"}}>Outstanding Revenue: ${data.studentGroupedIncomeData.total[region].unpaid/100}</p>
                                    <p style={{fontSize: "20px", textAlign: "center"}}>Avg Cost/Student: {avgCS}</p>
                                    <div style={{
                                        borderBottom: "1px solid lightgrey",
                                        width: "80%",
                                        margin: "auto",
                                        position: "relative",
                                        top: "-6px"
                                    }}></div>
                                    <p style={{fontSize:"20px",textAlign:"center"}}>Previous: ${data.studentGroupedIncomeData.past[region]?data.studentGroupedIncomeData.past[region].unpaid/100:0}</p>
                                    <p style={{fontSize:"20px",textAlign:"center"}}>Current:  ${data.studentGroupedIncomeData.current[region]?data.studentGroupedIncomeData.current[region].unpaid/100:0}</p>
                                    <p style={{fontSize:"20px",textAlign:"center"}}>Future:  ${data.studentGroupedIncomeData.future[region]?data.studentGroupedIncomeData.future[region].unpaid/100:0}</p>
                                </div>
                            </Card>
                    })}
                </div>
            </div>
        }
        else if(item.i == 'memberRegionStats'){
            return <div key={item.i}>
                <div>Members</div>
                <div style={{display:"flex",gap:10, flexWrap:"wrap", justifyContent:"center"}}>
                    {Object.entries(data.totalRegionUsers).map(([region,rData]) => {
                        if (region == 'total') return true;
                        console.log(["rData",rData])
                        return <Card title={<div style={{textAlign: "center"}}>{capitalizeFirstLetter(region)}</div>}
                                     bordered={false} style={{minWidth: 300}} className="cardFlex">
                                <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                                    <p style={{fontSize: "20px", textAlign: "center"}}>Members: {rData.member.total.COUNT}</p>
                                    <p style={{fontSize: "20px", textAlign: "center"}}>Annual: {rData.member.Annual.COUNT}</p>
                                    <p style={{fontSize: "20px", textAlign: "center"}}>Monthly: {rData.member.Monthly?rData.member.Monthly.COUNT:0}</p>
                                    <div style={{
                                        borderBottom: "1px solid lightgrey",
                                        width: "80%",
                                        margin: "auto",
                                        position: "relative",
                                        top: "-6px"
                                    }}></div>
                                    <p style={{fontSize:"20px",display:"flex", justifyContent:"center", gap:10}}>
                                        <div style={{width:150, textAlign:"right", fontSize:16}}>Income Summary:</div>
                                        <div style={{width:100,textAlign:"left"}}>${data.memberGroupedIncomeData.total[region].paid/100}</div>
                                    </p>
                                    <p style={{fontSize:"20px",display:"flex", justifyContent:"center", gap:10}}>
                                        <div style={{width:150, textAlign:"right", fontSize:16}}>Overdue Summary:</div>
                                        <div style={{width:100,textAlign:"left"}}>${data.memberGroupedIncomeData.total[region].unpaid/100}</div>
                                    </p>
                                    <p style={{fontSize:"20px", display:"flex", justifyContent:"center", gap:10}}>
                                        <div style={{width:150,textAlign:"right", fontSize:16}}>Debt Summary:</div>
                                        <div style={{width:100,textAlign:"left"}}>${data.memberGroupedIncomeData.total[region].unpaidDebt/100}</div>
                                    </p>
                                </div>
                            </Card>
                    })}
                </div>
            </div>
        }
        else

        return '';
    }

    return (
        <div style={{paddingRight:20, width:"100%", position:"relative"}}>      

            <div style={{display:"flex",position:"absolute", right:0, top:-80}}>
                <Checkbox onChange={dashboardEditChange}>Edit Dashboard</Checkbox>
                {dashboardEdit &&
                <div style={{display:"flex",flex:1}}>
                    <Checkbox checked={dashboardVerticalCompact} onChange={dashboardVerticalCompactChange}>Vertical Compact</Checkbox>
                    <Button type="primary" size="small" shape="round" onClick={()=>setDashboardAddModal(true)}>Add Item</Button>
                    <Button type="danger" size="small" shape="round" onClick={onLayoutReset} style={{marginLeft:"auto"}}>Reset Layout</Button>
                </div>
                }
            </div>

            {!isLoading &&
            <ReactGridLayout
                layout={dashboardLayout}
                onLayoutChange={onLayoutChange}
                verticalCompact={dashboardVerticalCompact}
                isDraggable={dashboardEdit}
                isResizable={dashboardEdit}
                //innerRef={function(ref){console.log(["ref2",ref])}}
                ref={function(ref){if(!ref)return; setGridRef(ref);}}
                className="layout"
                items={5}
                rowHeight={30}
                cols={12}
            >
                {dashboardLayout.map(function(l,i) {

                    return (
                        <div key={l.i}>
                            {dashboardEdit &&
                            <div style={{
                                position: "absolute",
                                right: "2px",
                                top: 0,
                                zIndex: 1
                            }}>
                                <Button
                                    className="remove"
                                    type="danger" size="small" shape="round"
                                    onClick={() => dashboardRemoveItem(l.i)}
                                ><i className="fas fa-times"></i></Button>
                            </div>
                            }
                            <GenerateWidget item={l} index={i}/>

                        </div>
                    );
                })}

            </ReactGridLayout>
            }

            <Modal
                visible={dashboardAddModal}
                title="Add Dashboard Item"
                onCancel={()=>setDashboardAddModal(false)}
                //centered={true}
                footer={<Button type="default" size="small" shape="round" onClick={()=>setDashboardAddModal(false)}>Done</Button>}
            >
                {_.map(getDefaultLayout(), function(l) {

                    let onDashboard = false;
                    for(let i of dashboardLayout){
                        if(i.i == l.i){
                            onDashboard = true;
                            break;
                        }
                    }

                    let x = dashboardLayout;

                    return (
                        <div style={{display:"flex",paddingBottom:10}} key={l.i}>
                            <div style={{flex:1}}>{l.i}</div>
                            <div><Button type="primary" size="small" shape="round" disabled={onDashboard} onClick={()=>dashboardAddItem(l)}>Add</Button></div>
                        </div>
                    );
                })}
            </Modal>
        </div>
    );
}