import React, {useEffect, useState,useRef} from 'react';
import API from "../components/api";
import {
    Form,
    Input,
    Select,
    Checkbox,
    Button,
	notification,
	Row, Col,
    List
} from 'antd';

import JoditEditor from "jodit-react";
import API2 from "../components/api2";
import useToken from "../hooks/useToken";


const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function EmailTemplateForm(props) {
    const [form] = Form.useForm();

    const { user, setUser }             = useToken();
    const [readOnly, setReadOnly]       = useState(true);
    const [editorState, setEditorState] = useState();
    const editor                        = useRef(null);
    const [apiErrors, setApiErrors]     = useState(false);
    const [body, setBody]               = useState();


    const config = {
        readonly: readOnly // all options from https://xdsoft.net/jodit/doc/
    }


    const AP = API2();
    useEffect(() => {
        if(!props.data) return true;

        if(user.type=="admin" || props.data.template.access=="custom" || !props.data.template){
            setReadOnly(false);
        }

        form.setFieldsValue(props.data.template);
        setBody(props.data.template.body);
    }, [props.data]);

	function saveForm(data){

	    let id = '';
	    if(props.data.template) id = '/'+props.data.template.id;

        data.body = body;

        AP.post('/email/template'+id,data)
            .then(function(res){
                if(props.id=='new'){
                    window.location.replace("/email/template/"+res.id);
                }
            });

		notification.success({
			message: `Saved`,
			placement: 'bottomRight',
			duration:1,
			closeIcon : (<div></div>)
		});
    }

    function sendTestEmail(){
        AP.post('/email/template/'+props.data.template.id+"/testEmail")
            .then(function(res){
                if(res.success) {
					notification.success({
						message: `Test Email Sent`,
						placement: 'bottomRight',
						duration: 1,
						closeIcon: (<div></div>)
					});
				}else{
					notification.error({
						message: `Test Email Failed`,
						placement: 'bottomRight',
						duration: 1,
						closeIcon: (<div></div>)
					});
                }
            });


    }

    return(
        <>
            <Form
                {...formItemLayout}
                form={form}
                name="emailTemplate"
                onFinish={saveForm}
                scrollToFirstError
            >

                <Form.Item name="name" label="Name" rules={[{  required: true }]}>
                    <Input disabled={readOnly}/>
                </Form.Item>
                <Form.Item name="subject" label="Subject" rules={[{ required: true }]}>
                    <Input disabled={readOnly} />
                </Form.Item>
                <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                    <Select disabled={readOnly}>
                        {props.data && props.data.types.map((value, index) => {
                            return <Option value={value} key={index}>{value}</Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Body"
                >
                    <div style={{backgroundColor:"white"}}>
                        <Form.Item
                            name="body"
                            rules={[{ required: false, whitespace: true }]}
                        >
                            <JoditEditor
                                ref={editor}
                                value={body}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => setBody(newContent)} //.srcElement.innerHTML
                                onChange={newContent => {}}
                            />
                        </Form.Item>

                    </div>

                </Form.Item>

                <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                    <Select>
                        <Option value="active"   key="active">Active</Option>
                        <Option value="inactive" key="inactive">Inactive</Option>
                    </Select>
                </Form.Item>
                {props.data.tags &&
                <Form.Item label={true} >


                    <List className="antList1"
                          header="Tags"
                          bordered
                          dataSource={Object.keys(props.data.tags)}
                          renderItem={(item,k) => (
                              <List.Item key={k} style={{justifyContent:"flex-start"}}>
                                  <i className="fas fa-circle" style={{fontSize:"8px", top:"-2px", position:"relative"}}/>
                                  <div style={{display:"flex"}}>
                                      <div style={{width:"200px",textAlign:"right",paddingRight:"10px"}}>{item}</div> <i className="fas fa-long-arrow-alt-right"/> <div style={{paddingLeft:"10px"}}>{'{{'+props.data.tags[item]+'}}'}</div>
                                  </div>
                              </List.Item>
                          )}
                    />
                </Form.Item>
                }


                <div style={{display:"flex", paddingLeft:"21%"}}>
                    <Button type="default" htmlType="button" onClick={sendTestEmail}><i className="far fa-envelope"/>&nbsp;Send Me A Test Email</Button>
                    <Button type="primary" htmlType="submit" style={{marginLeft: "auto"}}>Save</Button>
                </div>

            </Form>


        </>
    )
}

