import '../App.css';
import React, { useState } from 'react';
import {BrowserRouter, NavLink , Route, Switch} from "react-router-dom";
import Profile from "../pages/Profile";

import PrivateRoute from '../hooks/PrivateRoute'
import BreadCrumbContext from "../contexts/breadCrump";

import { Layout, Menu, Breadcrumb } from 'antd';
import Header from './header';
import Footer from './footer';
import Sider from './sider';
import useToken from "../hooks/useToken";
import Routes from "../components/Routes";

const { Content } = Layout;
const { SubMenu } = Menu;




function BackendLayout(props) {


    const { user, setUser } = useToken();
	const [breadcrumbs,setBreadcrumbs] = useState([]);
	const isPaid = !user.paid_until || user.paid_until > ((Date.now() / 1000) - 172800);

	return (
		<Layout style={{ minHeight: '100vh',maxWidth:"100%" }}>


			<BreadCrumbContext.Provider value={setBreadcrumbs}>
				<BrowserRouter>



                    {isPaid &&
                    <Sider/>
                    }
					<Layout className="site-layout">
						<Header  setUser={props.setUser}/>
						<Content className={"contentBackend"} style={{ margin: '0 16px'}}>
							<Breadcrumb style={{ margin: '16px 0' }}>
								{breadcrumbs.map((value, index) => {
									return <Breadcrumb.Item key={index}>{value}</Breadcrumb.Item>
								})}
							</Breadcrumb>
                            {isPaid &&
                            <Switch>
                                <Routes/>
                            </Switch>
                            || <Switch>
                                <PrivateRoute component={Profile}/>
                            </Switch>
                            }

						</Content>
						<Footer/>
					</Layout>

                    <link rel="stylesheet" id="gforms_reset_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formreset.min.css?ver=2.5.9" media="all"/>
                    <link rel="stylesheet" id="gforms_formsmain_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formsmain.min.css?ver=2.5.9" media="all"/>
                    <link rel="stylesheet" id="gforms_ready_class_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/readyclass.min.css?ver=2.5.9" media="all"/>
                    <link rel="stylesheet" id="gforms_browsers_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/browsers.min.css?ver=2.5.9" media="all"/>
                    <link rel="stylesheet" id="global-styles-inline-css" href="/css/global-styles-inline.css"></link>
                    {false && <link rel="stylesheet" id="other-css" href="https://www.sportsnutritionassociation.com/wp-content/themes/scsnassoc/assets/others.css?ver=170601.1.0.2" media="all"/>}
                    <link rel="stylesheet" id="theme-css" href="/css/wp_style.css" media="all"/>


				</BrowserRouter>
			</BreadCrumbContext.Provider>
		</Layout>
	);
}
// setBreadcrumbs={setBreadcrumbs}
export default BackendLayout;



