import { useRef, useEffect } from "react";

import {
    Input
} from 'antd';

const LocationAutoComplete = () => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        componentRestrictions: { country: "au" },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["address"]
    };
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            //inputRef.current.value = "YAY";
            console.log({ place });
        });
    }, []);

    return (
        <input ref={inputRef} />
    );
};
export default LocationAutoComplete;