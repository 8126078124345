
import React, { useState } from 'react';

export default function User() {
	const getToken = () => {
	    if(!localStorage) return false;

        const tokenString = localStorage.getItem('token');
        return tokenString;
    };
    const getUser = () => {
        if(!localStorage) return false;

        const user = JSON.parse(localStorage.getItem('user'));
        if(user) {
            user.hasPermissions = function (perms) {
                if(!user.permissions) return false;

                if (typeof perms == 'string') {
                    perms = [perms];
                }
                for (const p of perms) {
                    if (!user.permissions.includes(p)) return false;
                }

                return true;
            };

            user.hasPermissionsAny = function (perms) {
                if (typeof perms == 'string') {
                    perms = [perms];
                }
                for (const p of perms) {
                    if (user.permissions.includes(p)) return true;
                }

                return false;
            }
        };

        return user;
    };
    const getGEO = () => {
        if(!localStorage) return false;

        let geo = localStorage.getItem('geo');
        if(geo === null){
            localStorage.setItem('geo', '');
            fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_KEY}`)
                .then(response => response.json())
                .then(data => {
                    localStorage.setItem('geo', JSON.stringify(data));
                    setGEO(data);
                })
                .catch(error => console.error('GEO Error:', error));
        }else if(geo){
            geo = JSON.parse(geo);
        }

        return geo;
    };


	const [token, setToken] = useState(getToken());
    const [user, setUser]   = useState(getUser());
    const [geo, setGEO]     = useState(getGEO());

    function loginAs(data,token){
        localStorage.setItem('originalToken', localStorage.getItem('token'));
        localStorage.setItem('originalUser', localStorage.getItem('user'));
        storeUser(data,token);
    }
    function restoreUser(){
        storeUser(JSON.parse(localStorage.getItem('originalUser')),localStorage.getItem('originalToken'));
        localStorage.removeItem('originalToken');
        localStorage.removeItem('originalUser');
    }
	function storeUser(data,token){
		if(data){
		    if(token) {
                localStorage.setItem('token', token);
                setToken(token);
            }

            data.isMember  = false;
            data.isStudent = false;

            if(data.subscriptions){
		        for(const sub of data.subscriptions){
		            if(sub.plan.for == "member"){
                        data.isMember = true;
                    }
                    if(sub.plan.for == "student"){
                        data.isStudent = true;
                    }
                }
            }

            localStorage.setItem('user', JSON.stringify(data));
		}else{
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            setToken(null);
		}

        setUser(data);
	};

	return {
		token,
        user,
        geo,
		setUser: storeUser,
        setLoginAsUser: loginAs,
        RestoreUser: restoreUser,
	}
}

/*
export const isLoggedIn = () => {
	if (localStorage.getItem('token')) return true;
	return false;
}
*/