import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";
import useToken from "../hooks/useToken";





const { Option, OptGroup } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
};



export default function UserMembershipForm(props) {

    const [form] = Form.useForm();
    const [user, setUser] = useState(props.user);
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [userType, setUserType] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

    const [isStudent, setIsStudent] = useState(false);
    const [isMember, setIsMember] = useState(false);

    const setBreadCrumbs = useContext(BreadCrumbContext);

    const AP = API2();
    useEffect(() => {


        if(!props.user || !props.user.billing_dom) props.user.billing_dom = "";

        if(props.user){
            for(let subscription of props.user.subscriptions){
                if(subscription.plan.for == "student") setIsStudent(true);
                else if(subscription.plan.for == "member") setIsMember(true);
                else{
                    setIsStudent(true);
                    setIsMember(true);
                }
            }
        }

        form.setFieldsValue(props.user);
    }, []);

    function saveForm(data){
        setIsSaving(true);

        let url = '/user';
        if(props.user.id) {
            url += '/'+props.user.id;
        }


        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            history.push("/user/"+res.id);
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="profile"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            {false &&
            <Form.Item
                name="billing_status"
                label="Status"
                rules={[
                    {
                        required: true,
                        message: 'Please select a type',
                    }
                ]}
            >
                <Select
                    placeholder="Select a option and change input text above"
                >
                    <Option value="active">Active</Option>
                    <Option value="on-hold">On Hold</Option>
                    <Option value="pending-cancel">Pending Cancel</Option>
                    <Option value="cancelled">Cancelled</Option>
                </Select>
            </Form.Item>
            }

            {isMember &&
            <Form.Item
                name="accreditation_level"
                label="Accreditation Level"
                rules={[
                    {
                        required: true,
                        message: 'Please input an accreditation level!',
                        whitespace: true
                    }
                ]}
            >
                <Select>
                    <Option value="student">Student</Option>
                    <Option value="provisional">Provisional</Option>
                    <Option value="open_pending">Open (not confirmed)</Option>
                    <Option value="open">Open</Option>
                </Select>
            </Form.Item>
            }

            {false &&
            <Form.Item
                name="billing_dom"
                label="Subscription Billed On"
                rules={[
                    {
                        message: 'Please input billing dom!',
                        whitespace: true
                    }
                ]}
            >
                <Select>
                    <OptGroup label={"Special"}>
                        <Option value="">Based on last invoice</Option>
                        <Option value="monday">1st Monday</Option>
                        <Option value="eom">Last day of month</Option>
                    </OptGroup>
                    <OptGroup label={"Day Of Month"}>
                        <Option value="1">1st</Option>
                        <Option value="2">2nd</Option>
                        <Option value="3">3rd</Option>
                        <Option value="4">4th</Option>
                        <Option value="5">5th</Option>
                        <Option value="6">6th</Option>
                        <Option value="7">7th</Option>
                        <Option value="8">8th</Option>
                        <Option value="9">9th</Option>
                        <Option value="10">10th</Option>
                        <Option value="11">11th</Option>
                        <Option value="12">12th</Option>
                        <Option value="13">13th</Option>
                        <Option value="14">14th</Option>
                        <Option value="15">15th</Option>
                        <Option value="16">16th</Option>
                        <Option value="17">17th</Option>
                        <Option value="18">18th</Option>
                        <Option value="19">19th</Option>
                        <Option value="20">20th</Option>
                        <Option value="21">21st</Option>
                        <Option value="22">22nd</Option>
                        <Option value="23">23rd</Option>
                        <Option value="24">24th</Option>
                        <Option value="25">25th</Option>
                        <Option value="26">26th</Option>
                        <Option value="27">27th</Option>
                        <Option value="28">28th</Option>
                        <Option value="29">29th</Option>
                        <Option value="30">30th</Option>
                        <Option value="31">31st</Option>
                    </OptGroup>
                </Select>
            </Form.Item>
            }

            <div style={{display:"flex",gap:20, flexWrap:"wrap"}}>
                <Button type="primary" htmlType="submit" loading={isSaving} style={{marginLeft:"auto"}}>{(isSaving)?'Saving':'Save'}</Button>
            </div>
        </Form>
	);
}