import React, {useEffect, useState} from 'react';
import API from "../components/api";
import {
    Form,
    Input,
    Select,
    Checkbox,
    Button,
	notification,
	Row, Col
} from 'antd';

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function RegisterApp(props) {
    const [form] = Form.useForm();
	const [clients, clientsLoading, clientsError] = API('GET','/apps',[]);
	const [resp, isLoading, isError, doPost] = API('POST','/appRequest');

	/*
	useEffect(() => {
		form.resetFields();
	}, [resp]);
	*/

	function saveForm(data){
		doPost(data);

		notification.success({
			message: `Saved`,
			placement: 'bottomRight',
			duration:1,
			closeIcon : (<div></div>)
		});
    }

    useEffect(() => {
        if(!resp) return true;
        window.location.reload();
    }, [resp]);



    return(
		<Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={saveForm}
			/*
            initialValues={{
				app_name:data.name,
				app_username:data.username,
				details:data.details,
				contact_me:data.notify
            }}
            */
            scrollToFirstError
        >
			<Form.Item name="client_id" label="App/Website Name" rules={[{ required: true }]}>
				<Select
					placeholder="Select a option and change input text above"
					allowClear
				>
					{clients.map((value, index) => {
						return <Option value={value.id} key={value.id}>{value.name}</Option>
					})}
					<Option value="0" key={0}>other</Option>
				</Select>
			</Form.Item>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) => prevValues.client_id !== currentValues.client_id}
			>
				{({ getFieldValue }) => {
					return getFieldValue('client_id') === '0' ? (
						<Form.Item
							name="app_name_custom"
							label=" "
							rules={[{ required: true }]}
						>
							<Input placeholder="Please Specify"/>
						</Form.Item>
					) : null;
				}}
			</Form.Item>

			<Form.Item name={'email'} label="Your Email" rules={[{ type: 'email', required: true }]}>
				<Input />
			</Form.Item>


            <Form.Item
                name="details"
                label="Additional Details"
                rules={[{ required: false, whitespace: true }]}
            >
                <Input.TextArea rows={4} />
            </Form.Item>


				<Form.Item {...tailFormItemLayout}>
					<Col span={24} style={{ textAlign: 'right' }}>
					<Button type="primary" htmlType="submit" style={{marginLeft:"auto"}}>
						Express Interest
					</Button>
					</Col>
				</Form.Item>

        </Form>
    )
}

