import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import { Layout, Menu, Drawer,Button } from 'antd';
import {
	WifiOutlined,
	DatabaseOutlined,
	CameraOutlined,
	CreditCardOutlined,
	TeamOutlined,
	ControlOutlined,
	QuestionCircleOutlined,
} from '@ant-design/icons';
import useToken from "../hooks/useToken";
import useWindowDimensions from "../components/useWindowDimensions";




//import './sider.less';
//import logo from '../../public/image/Enhance-MHS.jpg';


const { Sider } = Layout;
const { SubMenu } = Menu;


export default function SiderNav(props) {
	const { user:authUser, setUser } = useToken();
	const [collapsed, setCollapsed] = useState(false);
    const [visible, setVisible]     = useState(false);

    const { height, width } = useWindowDimensions();

    var showDrawer = () => {
        setVisible(true);
    };
    var onClose = () => {
        setVisible(false);
    };

	var onCollapse = collapsed => {
		setCollapsed(collapsed);
	};

    let href=window.location.href.split('/');
    href.shift();
    href.shift();
    href.shift();

    let selectedSubMenu = [];

    if(href.length>1){
        selectedSubMenu = [href[0]];
        href = [href[1]];
	}

	function getMenu(){
    	return <Menu
            style={{ width: (collapsed)?80:200 }}
            defaultSelectedKeys={href}
            defaultOpenKeys={selectedSubMenu}
            mode="inline"
            theme="light"
        >
            <Menu.Item key="dashboard" icon={<i className="fas fa-home"/>}>
                <NavLink to="/dashboard" activeClassName="current">
                    Dashboard
                </NavLink>
            </Menu.Item>


            {authUser.type == "admin" &&
            <>
                <Menu.Item key="users" icon={<i className="fas fa-users"/>}>
                    <NavLink to="/users" activeClassName="current">
                        Users
                    </NavLink>
                </Menu.Item>

                {authUser.permissions.includes("staff_access") &&
                    <Menu.Item key="staff" icon={<i className="fas fa-user-crown"/>} className="submenuItem">
                        <NavLink to="/staff" activeClassName="current">
                            Staff
                        </NavLink>
                    </Menu.Item>
                }
                {authUser.permissions.includes("student_access") &&
                    <Menu.Item key="students" icon={<i className="fas fa-user-graduate"/>} className="submenuItem">
                        <NavLink to="/students" activeClassName="current">
                            Students
                        </NavLink>
                    </Menu.Item>
                }
                {authUser.permissions.includes("member_access") &&
                    <Menu.Item key="members" icon={<i className="fas fa-user-tie"/>} className="submenuItem">
                        <NavLink to="/members" activeClassName="current">
                            Members
                        </NavLink>
                    </Menu.Item>
                }



                <Menu.Item key="coupons" icon={<i className="fas fa-ticket-alt"/>}>
                    <NavLink to="/coupons" activeClassName="current">
                        Coupons
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="resources" icon={<i className="fas fa-cloud-download"/>}>
                    <NavLink to="/resources" activeClassName="current">
                        Resources
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="plans" icon={<i className="fas fa-box-usd"/>}>
                    <NavLink to="/plans" activeClassName="current">
                        Plans
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="urls" icon={<i className="fas fa-link"/>}>
                    <NavLink to="/urls" activeClassName="current">
                        Invite Urls
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="certificates" icon={<i className="fad fa-certificate"/>}>
                    <NavLink to="/certificates" activeClassName="current">
                        Certificates
                    </NavLink>
                </Menu.Item>

				<hr style={{margin:0, borderColor:"lightgray"}}/>

                {authUser.permissions.includes("reporting") &&
                    <SubMenu key="reporting" icon={<span className="anticon"><i className="fas fa-file-chart-line"/></span>} title="Reporting">
                        <Menu.Item key="endofmonth" style={{paddingLeft:13}}>
                            <NavLink to="/reporting/endofmonth" activeClassName="current"><i className="fal fa-calendar-day"/> End Of Month</NavLink>
                        </Menu.Item>
                        <Menu.Item key="dailyStats" style={{paddingLeft:13}}>
                            <NavLink to="/reporting/dailyStats" activeClassName="current"><i className="fal fa-calendar-day"/> Daily Stats</NavLink>
                        </Menu.Item>
                        <Menu.Item key="dailyTests" style={{paddingLeft:13}}>
                            <NavLink to="/reporting/dailyTests" activeClassName="current"><i className="fal fa-calendar-day"/> Daily Tests</NavLink>
                        </Menu.Item>
                        <Menu.Item key="membersSummary" style={{paddingLeft:13}}>
                            <NavLink to="/reporting/membersSummary" activeClassName="current"><i className="fad fa-list"/> Members Summary</NavLink>
                        </Menu.Item>
                        <Menu.Item key="studentsSummary" style={{paddingLeft:13}}>
                            <NavLink to="/reporting/studentsSummary" activeClassName="current"><i className="fad fa-list"/> Students Summary</NavLink>
                        </Menu.Item>
                        <Menu.Item key="exports" style={{paddingLeft:13}}>
                            <NavLink to="/reporting/exports" activeClassName="current"><i className="fal fa-file-export"/> Exports</NavLink>
                        </Menu.Item>
                    </SubMenu>
                }

				<SubMenu key="email" icon={<span className="anticon"><i className="fas fa-envelope"/></span>} title="Email">
					<Menu.Item key="history">
						<NavLink to="/email/history" activeClassName="current">History</NavLink>
					</Menu.Item>
					<Menu.Item key="templates">
						<NavLink to="/email/templates" activeClassName="current">Templates</NavLink>
					</Menu.Item>
				</SubMenu>

				<Menu.Item key="crons" icon={<i className="fas fa-robot"/>}>
					<NavLink to="/crons" activeClassName="current">
						Crons
					</NavLink>
				</Menu.Item>
			</>
            }

            {(authUser.type == "member") &&
            <>
                <Menu.Item key="subscriptions" icon={<i className="fas fa-dollar-sign"></i>}>
                    <NavLink to="/subscriptions" activeClassName="current">
                        Subscriptions
                    </NavLink>
                </Menu.Item>
                {!!authUser.isMember && <>
                    <Menu.Item key="registry" icon={<i className="far fa-id-card"/>}>
                        <NavLink to="/registrySettings" activeClassName="current">
                            Registry
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="downloads" icon={<i className="fas fa-cloud-download"></i>}>
                        <NavLink to="/downloads" activeClassName="current">
                            Downloads
                        </NavLink>
                    </Menu.Item>
                    </>
                }
                <Menu.Item key="termsandconditions" icon={<i className="fad fa-handshake-alt"/>}>
                    {/* 
                    <NavLink to="/termsAndConditions" activeClassName="current">
                        Terms & Conditions
                    </NavLink>
                    */}
                    <a href="https://sportsnutritionassociation.com/terms/" target="_blank">Terms & Conditions</a>
                </Menu.Item>
                <Menu.Item key="profile" icon={<i className="fas fa-user-edit"/>}>
                    <NavLink to="/profile" activeClassName="current">
                        Profile
                    </NavLink>
                </Menu.Item>
            </>
            }



        </Menu>
	}

	return (

		<>
			{width<768 &&
            <>
                <Button className="barsMenu" type="default" onClick={showDrawer} style={{position:"fixed", top:"17px",left:"10px",zIndex:"99999"}}>
                    <i className="fas fa-bars"/>
                </Button>
                <Drawer
                    title="Navigation"
                    placement="left"
                    closable={true}
                    onClose={onClose}
                    visible={visible}
					className="navigation-drawer"
					width="200px"
                >
                    {getMenu()}
                </Drawer>
			</>
            }
            {width >= 768 &&
				<Sider collapsible collapsed={collapsed} onCollapse={onCollapse} theme="light" style={{backgroundColor:"#e4e8ef"}}>
					<div style={{display:'flex',backgroundColor:"white"}}>
						<img className="logo" src="/images/logo-inner.png" style={{padding:"2px", height:"64px", margin:'auto'}}/>
					</div>
					{getMenu()}
				</Sider>
            }


		</>
	);
}