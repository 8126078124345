import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input,Checkbox } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import SearchTable from "../../components/SearchTable";
import countryList from "react-select-country-list";
import Moment from 'react-moment';
import moment from "moment/moment";
import API2 from "../../components/api2";

export default function Students(props) {

    const AP = API2();

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-user-graduate" /> Students</span>
        ]);
	}, []);


    const [showFinished, setShowFinished] = useState(true);



	const [startDates, setStartDates] = useState({});
	const [plans, setPlans] = useState({});
	const [planOptions, setPlanOptions] = useState([]);
	useEffect(() => {
		/*
		if(history.location.state){
			form.setFieldsValue(history.location.state);
		}
		*/

		AP.get('/plans?for=student&with=stats&showPrivate=true&pageSize=999')
			.then(function (res) {
				let startDates = {};
				let plans = {
					total:{
						totalAmountPaid:0,
						totalAmountUnPaid:0,
						userCount:0
					}
				};
				let planOptions = {};


				for(let plan of res.data){
					planOptions[plan.name] = {
						text:plan.name,
						value:plan.name
					};

					const m = moment.unix(plan.starts_at);
					const date = m.format("MMMM");
					const dateS = m.format("M");
					const year = m.year();
					plan.totalAmountPaid   = parseInt(plan.totalAmountPaid);
					plan.totalAmountUnPaid = parseInt(plan.totalAmountUnPaid);
					plan.userCount         = parseInt(plan.userCount);

					if(!startDates[year]){
						startDates[year] = {};
					}
					if(!startDates[year][dateS]){
						startDates[year][dateS] = date;
					}
					plans[plan.starts_at] = plan;

					if(!plans[year]) plans[year] = {
						totalAmountPaid:0,
						totalAmountUnPaid:0,
						userCount:0
					};
					if(!plans[year+'-'+dateS]) plans[year+'-'+dateS] = {
						totalAmountPaid:0,
						totalAmountUnPaid:0,
						userCount:0
					};
					plans[year+'-'+dateS].totalAmountPaid      += plan.totalAmountPaid;
					plans[year+'-'+dateS].totalAmountUnPaid    += plan.totalAmountUnPaid;
					plans[year+'-'+dateS].userCount            += plan.userCount;
					plans[year].totalAmountPaid      += plan.totalAmountPaid;
					plans[year].totalAmountUnPaid    += plan.totalAmountUnPaid;
					plans[year].userCount            += plan.userCount;
					plans["total"].totalAmountPaid   += plan.totalAmountPaid;
					plans["total"].totalAmountUnPaid += plan.totalAmountUnPaid;
					plans["total"].userCount         += plan.userCount;
				}

				setPlanOptions(Object.values(planOptions));
				setPlans(plans);
				setStartDates(startDates);
			});
	}, []);

	const regionMap = {
		'australia': 'Australia',
		'newzealand': 'New Zealand',
		'unitedstates': 'United States',
		'canada': 'Canada',
		'asia': 'Asia',
		'europe': 'Europe'
	};

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'first_name',
            sorter: true,
        	align:'left',
            fixed: 'left',
            width: 250,
			searchable: true,
			render: (text, record) => (
				<div style={{display:"flex"}}>
					<div>
						<Avatar src={(record.image)?record.image.endpoint:null} icon={<i className="far fa-user"></i>} size="large" style={{opacity:record.image?1:.2}}/>
					</div>
					<div style={{paddingLeft:"20px",whiteSpace:"nowrap",overflow:"hidden"}}>
						<div>{record.name}</div>
						<div style={{overflow:"hidden",textOverflow:"ellipsis"}}>{record.email}</div>
					</div>
				</div>
			)
		},
		{
			title: 'No',
			key: 'users.id',
			dataIndex: 'id',
			width: 70,
			sorter: true,
			align:'left',
			searchable: true
		},
        /*
		{
			title: 'Email',
			key: 'email',
            dataIndex: 'email',
            sorter: true,
            width: 250,
			align:'left',
            render: (text, record) => (
			    <div style={{width: 220, whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}>
                    {record.email}
                </div>
            )
		},
		*/
        {
            title: 'Region',
            key: 'region',
            dataIndex: 'region',
            width: "120px",
            sorter: true,
            align:'left',
			filters: [
				{
					text: 'Australia',
					value: 'australia',
				},
				{
					text: 'New Zealand',
					value: 'newzealand'
				},
				{
					text: 'United States',
					value: 'unitedstates'
				},
				{
					text: 'Canada',
					value: 'canada'
				},
				{
					text: 'Asia',
					value: 'asia'
				},
				{
					text: 'Europe',
					value: 'europe'
				}
			],
			render: (text, record) => {
				if (regionMap[text]) return regionMap[text];
				return text;
			}
        },
        {
            title: 'Status',
            key: 'billing_status',
            dataIndex: 'billing_status',
            width: "110px",
            sorter: true,
            align:'left',
			filters: [
				{
					text: 'Active',
					value: 'active',
				},
				{
					text: 'Pending Cancel',
					value: 'pending-cancel'
				},
				{
					text: 'Cancelled',
					value: 'cancelled'
				},
				{
					text: 'Completed',
					value: 'completed'
				},
				{
					text: 'Debt',
					value: 'debt'
				},
				{
					text: 'Hibernating',
					value: 'hybernating'
				},
				{
					text: 'On Hold',
					value: 'on-hold'
				}
			],
            render: function(text, record) {

                const subMap = {};
                for(const subscription of record.subscriptions){
                    const key = subscription.plan.for+'-'+subscription.plan.name;
                    if(subMap[key] == undefined){
                        subMap[key] = subscription;
                    }else if(subMap[key].created_at < subscription.created_at){
                        subMap[key] = subscription;
                    }
                }

                return Object.values(subMap).map(subscription => (
                    <div>{subscription.status}</div>
                ));
            }
        },
        {
            title: 'Subscriptions',
            key: 'subscription',
            dataIndex: 'subscription',
        //    width: "300px",
            sorter: true,
			filters: planOptions,
            // filterMode: 'tree',
            // filterSearch: true,
            align:'left',
            render: function(text, record) {

                const subMap = {};
                for(const subscription of record.subscriptions){
                    const key = subscription.plan.for+'-'+subscription.plan.name;
                    if(subMap[key] == undefined){
                        subMap[key] = subscription;
                    }else if(subMap[key].created_at < subscription.created_at){
                        subMap[key] = subscription;
                    }
                }

                return Object.values(subMap).map(subscription => {
                    let linkStr = '';
                    if(subscription.linkedData){
                        linkStr = ' (D'+subscription.linkedData.depth+')';
                    }

                    return <div style={{display:"flex"}}>
                        (<div style={{width:60, textAlign:"center"}}>{subscription.plan.for}</div>)
                        <div style={{paddingLeft:10}}>
                            {subscription.plan.name} {linkStr} {subscription.plan.starts_at && <>- <Moment format="MMMYY" unix>{subscription.plan.starts_at}</Moment></>}
                        </div>
                    </div>
                });
            }
        },
        {
            title: 'Paid',
            key: 'paid',
            dataIndex: 'paid',
            width: 80,
            sorter: true,
            align:'right',
            searchable: 'range',
            render: function(text, record) {
                const subMap = {};


                for(const subscription of record.subscriptions){
                    const key = subscription.plan.for+'-'+subscription.plan.name;
                    if(subMap[key] == undefined){
                        subMap[key] = subscription;
                    }else if(subMap[key].created_at < subscription.created_at){
                        subMap[key] = subscription;
                    }
                }

                let currency;
				if(Object.values(subMap)[0].invoices.length){
					currency = Object.values(subMap)[0].invoices[0].currency;
				}else{
					currency = 'aud';
				}

				const formatter = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: currency,
					currencyDisplay: 'narrowSymbol'
				});

                return Object.values(subMap).map(subscription => {
                    return <div>{formatter.format(subscription.paid/100).replace(/\D00$/, '')}</div>
                });


            }
        },
        {
            title: 'Remaining',
            key: 'remaining',
            //dataIndex: 'paid',
            width: 100,
            //sorter: true,
			align:'right',
			searchable: 'range',
            render: function(text, record) {

                const subMap = {};
                for(const subscription of record.subscriptions){
                    const key = subscription.plan.for+'-'+subscription.plan.name;
                    if(subMap[key] == undefined){
                        subMap[key] = subscription;
                    }else if(subMap[key].created_at < subscription.created_at){
                        subMap[key] = subscription;
                    }
                }

				let currency;
				if(Object.values(subMap)[0].invoices.length){
					currency = Object.values(subMap)[0].invoices[0].currency;
				}else{
					currency = 'aud';
				}

				const formatter = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: currency,
					currencyDisplay: 'narrowSymbol'
				});

                return Object.values(subMap).map(subscription => {

                    let cost = 0;
                    let currency;
                    if(subscription.invoices){
                        for(const invoice of subscription.invoices){
							if(!currency) currency = invoice.currency;
							if(invoice.paid !== 1) {
								const origAmount = invoice.amount;
								let amount = invoice.amount;
								for(const discount of invoice.discounts){
									if(discount.type == 'percent'){
										amount -= origAmount * (discount.discount/100);
									}
									else if(discount.type == 'fixed'){
										amount -= discount.discount;
									}
								}

                                cost += amount;
                            }
                        }
                    }

                    if(cost){
                        return <div>{formatter.format(cost/100).replace(/\D00$/, '')}</div>
                    }
                    return <div>$0</div>
                });
            }
        },
        {
            title: 'Total',
            //key: 'paid',
            //dataIndex: 'paid',
            width: 120,
            //sorter: true,
			align:'right',
			searchable: 'range',
            render: function(text, record) {
                const subMap = {};
                for(const subscription of record.subscriptions){
                    const key = subscription.plan.for+'-'+subscription.plan.name;
                    if(subMap[key] == undefined){
                        subMap[key] = subscription;
                    }else if(subMap[key].created_at < subscription.created_at){
                        subMap[key] = subscription;
                    }
                }

                return Object.values(subMap).map(subscription => {
                    let cost = 0;
                    let currency;
					for(const invoice of subscription.invoices){
						if(!currency) currency = invoice.currency;
						const origAmount = invoice.amount;
						let amount = invoice.amount;
						for(const discount of invoice.discounts){
							if(discount.type == 'percent'){
								amount -= origAmount * (discount.discount/100);
							}
							else if(discount.type == 'fixed'){
								amount -= discount.discount;
							}
						}

						cost += amount;
					}


                    if(cost){
						const formatter = new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: currency,
							currencyDisplay: 'narrowSymbol'
						});

                        return <div>
							<span style={{flex:1, paddingRight:5}}>{formatter.format(cost/100).replace(/\D00$/, '')}</span>
							<span style={{fontSize:"10px"}}>{currency.toUpperCase()}</span>
                        </div>
                    }
                    return <div>∞</div>
                });
            }
        },
        {
            title: 'Credit',
            key: 'credit',
            dataIndex: 'credit',
			width: 200,
            //sorter: true,
            align:'left',
			render: (text, record) => {



				const subMap = {};
				for (const subscription of record.subscriptions) {
					const key = subscription.plan.for + '-' + subscription.plan.name;
					if (subMap[key] == undefined) {
						subMap[key] = subscription;
					} else if (subMap[key].created_at < subscription.created_at) {
						subMap[key] = subscription;
					}
				}

				return <div style={{textAlign: "left"}}>
					{Object.values(subMap).map(subscription => (
						subscription.discounts.length
							? subscription.discounts.map(discount => (
								<div style={{display: "flex"}}>
									<div style={{flex: 1}}>{discount.name}</div>
								</div>
							))
							: <div>&nbsp;</div>
					))}
				</div>
			}
        },
        {
            title: 'Next Payment',
            key: 'nextPaymentTS',
            dataIndex: 'nextPaymentTS',
            width: 220,
            sorter: true,
            render: function(text, record) {
                if(!record.nextPaymentTS){
                    return <div style={{color:"grey"}}>
                        {record.subscriptions.map(subscription => {
                            return <div>{subscription.status}</div>
                        })}
                    </div>
                }
                let days = Math.ceil((record.nextPaymentTS-Date.now()/1000)/86400);
                return <div style={{display:"flex",color:(days<0)?'red':'initial'}}>
                    <Moment format="Do MMM YYYY" unix style={{flex:1}}>{record.nextPaymentTS}</Moment>
                    <div>( {days} day{(days>1)?'s':''} )</div>
                </div>
            }
        },
        /*
        {
            title: 'Last Emailed',
            key: 'emailed',
            dataIndex: 'emailed',
            width: "150px",
            sorter: true,
            render: function(text, record) {


                let days = parseInt((Date.now()/1000-record.latest_email)/86400);

                return <div>
                    {record.latest_email
                    && <span>
                        <Moment format="Do MMM YYYY" unix>{record.latest_email}</Moment>
                        <div>( {days} day{(days>1)?'s':''} )</div>
                    </span>
                    }
                </div>
            }
        },
        */
		{
			title: 'Actions',
            key: 'actions',
            width: "80px",
            fixed: 'right',
            render: (text, record) => (
				<div>
					<span><Link to={"/student/"+record.id}><Button type="primary"  shape="round"><i className="far fa-edit"/></Button></Link></span>
				</div>
			),
		},
	];

    function onShowFinishedChange(e){
        setShowFinished(!e.target.checked);
    }

    const [startMonth, setStartMonth] = useState("");
    const [startYear, setStartYear] = useState("");
    function toggleStartMonth(newStartMonth){
        if(startMonth === newStartMonth){
            setStartMonth("");
        }else{
            setStartMonth(newStartMonth);
        }
    }
    function toggleStartYear(newStartYear){
        if(startYear === newStartYear){
            setStartYear("");
        }else{
            setStartYear(newStartYear);
        }
        setStartMonth("");
    }


    const onSelectOptions = [
        {
            name: "Delete",
            value: "delete",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["delete",selectedKeys]);
            }
        },
        {
            name: "Set Active",
            value: "setActive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setActive",selectedKeys]);
            }
        },
        {
            name: "Set Inactive",
            value: "setInactive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setInactive",selectedKeys]);
            }
        }
    ];

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function getStats(){
        let index;
        if(startMonth){
            index = startYear+'-'+startMonth;
        }else if(startYear){
            index = startYear;
        }else if(plans.total){
            index = "total";
        }else{
            return "";
        }

        return <div style={{backgroundColor:"#d9dee5", padding:20, width:360}}>
            <div style={{display:"flex"}}><div style={{width:200}}>Students</div><div>{plans[index].userCount}</div></div>
            <div style={{display:"flex"}}><div style={{width:200}}>Total Amount Paid</div><div>${numberWithCommas(plans[index].totalAmountPaid/100)}</div></div>
            <div style={{display:"flex"}}><div style={{width:200}}>Total Amount Unpaid</div><div>${numberWithCommas(plans[index].totalAmountUnPaid/100)}</div></div>
            <div style={{display:"flex"}}><div style={{width:200}}>Total Amount</div><div>${numberWithCommas((plans[index].totalAmountPaid+plans[index].totalAmountUnPaid)/100)}</div></div>
        </div>
    }

	return (

        <div className="content_inner" style={{maxWidth:2000,paddingTop:40}}>
            {false && <>
                <div style={{display:"flex", paddingBottom:20,justifyContent:"center"}}>
                    {Object.entries(startDates).map(([year,dates]) =>

                        <button
                            type="button"
                            className={"ant-btn ant-btn-round "+(year===startYear?"btn-teal":"btn-lightgrey")+" ant-btn-lg"}
                            onClick={()=>toggleStartYear(year)}
                        >{year}</button>

                    )}
                </div>
                <div style={{display:"flex", paddingBottom:10,justifyContent:"center", minHeight:70}}>
                    {Object.entries(startDates).map(([year,dates]) => {
                        if (startYear && year == startYear) {
                            return Object.entries(dates).map(([month, date]) =>
                                <button
                                    type="button"
                                    className={"ant-btn ant-btn-round " + (month === startMonth ? "ant-btn-primary" : "ant-btn-default") + " ant-btn-lg"}
                                    onClick={() => toggleStartMonth(month)}
                                >{date}</button>
                            )
                        }
                    })}
                </div>
                <div style={{display:"flex", paddingBottom:70,justifyContent:"center", minHeight:225}}>
                    {getStats()}

                </div>
            </>}


            <div style={{float:"right",position:"relative",top:-40, display:"flex"}}>

                <div style={{textAlign:"right"}}>
                    <Checkbox checked={!showFinished} onChange={onShowFinishedChange}>Hide Finished</Checkbox>
                </div>

                <Link to="/user/new" activeClassName="current" style={{marginLeft:"auto"}}>
                    <Button icon={<i className="far fa-user"></i>}>&nbsp;Create Student </Button>
                </Link>
            </div>

            <SearchTable
                columns={columns}
                scroll={{ x: 1650 }}
                url="/students"
              //  onSelect={onSelectOptions}
                filters={{showFinished:showFinished,startYear:startYear,startMonth:startMonth}}
                pagination={{showSizeChanger: true}}
				className="noPadding"
            />
        </div>
	);
};