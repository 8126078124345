import React, {useContext, useEffect, useState} from 'react';
import API2 from "../../components/api2";
import InvoiceForm from "../../forms/Invoice"
import {NavLink, useParams} from "react-router-dom";

import {
    Tabs,
    Button,
    Spin
} from 'antd';
import moment from "moment/moment";
import {Form} from "antd/lib/index";
import BreadCrumbContext from "../../contexts/breadCrump";
import { UserOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;


export default function Invoice(props) {

    let invoiceId  = props.match.params.invoiceId;
    let userId     = props.match.params.userId
    if(invoiceId == 'new') invoiceId = null;


    let defaultTab = "1";

    const AP = API2();

    const [form] = Form.useForm();
    const [invoice, setInvoice] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>,
            <Spin size="small"/>,
            (invoiceId)?<Spin size="small"/>:<span><i className="fas fa-user"/> Create Invoice</span>
        ]);
    }, []);

    useEffect(() => {
        if(invoiceId) {
            AP.get('/invoice/' + invoiceId)
                .then(function (res) {

                    setBreadCrumbs([
                        <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>,
                        <NavLink to={"/user/"+res.user.id}><span><UserOutlined/> {res.user.name}</span></NavLink>,
                        <span><i className="fas fa-user"/> Edit Invoice</span>
                    ]);

                    setInvoice(res);
                    form.setFieldsValue(res);
                    setIsLoading(false);
                });
        }else if(userId){
            AP.get('/user/' + userId)
                .then(function (res) {

                    setBreadCrumbs([
                        <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>,
                        <NavLink to={"/user/"+res.id}><span><UserOutlined/> {res.name}</span></NavLink>,
                        <span><i className="fas fa-user"/> Create Invoice</span>
                    ]);

                    setInvoice({user:res});
                    setIsLoading(false);
                });
        }else{
            setInvoice({});
            setIsLoading(false);
        }
    }, []);

    return(
        <div className="content_inner">

            <InvoiceForm invoice={invoice} {...props}/>

        </div>
	);
}