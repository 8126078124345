import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import moment from 'moment'
import countryList from "react-select-country-list";


export default function DailyStats(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-users"/> Daily Stats</span>
        ]);
	}, []);



	const columns = [
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            sorter: true,
            render: function(text, record) {
                return <div style={{display:"flex",fontSize:30}}>
                    <Moment format="DD MMM YYYY" unix style={{flex:1}}>{record.created_at}</Moment>
                </div>
            }
        },
		{
			title: 'Actions',
            key: 'actions',
            width: 150,
            render: (text, record) => (
				<div>
					<span><Link to={"/dailystats/"+record.id}><Button type="primary" shape="round"><i className="far fa-edit"/></Button></Link></span>
				</div>
			),
		},
	];

	function onSelect(selectedRowKeys: React.Key[], selectedRows: DataType[]){
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }

    const onSelectOptions = [
        {
            name: "Delete",
            value: "delete",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["delete",selectedKeys]);
            }
        },
        {
            name: "Set Active",
            value: "setActive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setActive",selectedKeys]);
            }
        },
        {
            name: "Set Inactive",
            value: "setInactive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setInactive",selectedKeys]);
            }
        }
    ];

	return (

        <div className="content_inner" style={{maxWidth:1300,paddingTop:40}}>
            <div style={{float:"right",position:"relative",top:-40}}>
                <Link to="/user/new" activeClassName="current" style={{marginLeft:"auto"}}>
                    <Button icon={<i className="far fa-user"></i>}>&nbsp;Create User </Button>
                </Link>
            </div>

            <SearchTable
                columns={columns}
                url="/dailystats"
                //onSelect={onSelectOptions}
            />
        </div>
	);
};