import React, { useState, useEffect,useContext  } from "react";
import { Button,Input, Checkbox, notification } from 'antd';
import {Link} from "react-router-dom";
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';

export default function Resources(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
    const AP = API2();

	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fad fa-certificate"/> Certificates</span>
        ]);
	}, []);

    function onChange(subscription,type,value){

        AP.post('/subscription/'+subscription.id,{[type]:value})
            .then(function (res) {
                notification.success({
                    message: `Saved`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }

	const columns = [
		{
			title: 'Name',
            dataIndex: 'first_name',
			key: 'name',
        	align:'left',
            sorter: true,
            render: (text, record) => (
                <div>{record.first_name} {record.last_name}</div>
            ),
		},
		{
			title: 'Email Sent',
            dataIndex: 'cert_email_sent_at',
			key: 'cert_email_sent_at',
			align:'center',
            width: 150,
            sorter: true,
            render: (text, record) => {

                return <Checkbox 
                    defaultChecked={record.subscription.cert_email_sent_at} 
                    onChange={e=>onChange(record.subscription,'cert_email_sent_at',e.target.checked)}
                />;

                return <>
                    {record.subscription.cert_email_sent_at  && <i className="fas fa-check" style={{color:"green"}}/>}
                    {!record.subscription.cert_email_sent_at  && <i className="fas fa-times" style={{color:"red"}}/>}
                </>;
            }
		},
		{
			title: 'Letter Sent',
			key: 'cert_letter_sent_at ',
            dataIndex: 'cert_letter_sent_at',
			align:'center',
            width: 150,
            sorter: true,
            render: (text, record) => {
                return <Checkbox 
                    defaultChecked={record.subscription.cert_letter_sent_at} 
                    onChange={e=>onChange(record.subscription,'cert_letter_sent_at',e.target.checked)}
                />;

                return <>
                    {record.subscription.cert_letter_sent_at  && <i className="fas fa-check" style={{color:"green"}}/>}
                    {!record.subscription.cert_letter_sent_at  && <i className="fas fa-times" style={{color:"red"}}/>}
                </>;
            }
		},
		{
			title: 'Cohort Start Date',
            dataIndex: 'cohort_date',
			key: 'cohort_date',
			align:'center',
            width: 180,
            searchable: true,
            sorter: true,
            render: (text, record) => {
                return <Moment format="DD/MM/YYYY" unix>{record.subscription.plan.starts_at}</Moment>;
            },
		},
        {
			title: 'Actions',
            key: 'actions',
            width: "80px",
            fixed: 'right',
            render: (text, record) => (
				<div>
					<span><Link to={"/user/"+record.id+"/subscriptions"}><Button type="primary"  shape="round"><i className="far fa-edit"/></Button></Link></span>
				</div>
			),
		},
	];



	return (
		<div style={{paddingTop:40, maxWidth:1000, margin:"auto"}}>
            <SearchTable
                columns={columns}
                url="/certificates"
                scroll={{ x: "max-content" }}
            />
        </div>
	);
};