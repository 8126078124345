import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input,Spin,Card,Checkbox,Collapse } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import moment from 'moment'
import countryList from "react-select-country-list";
import ComparisonGraph from "../../graphs/comparisonChart";
import ProgressGraph from "../../graphs/progressChart";
import {Form, notification} from "antd/lib/index";

const { Panel } = Collapse;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

export default function DailyStat(props) {

    const AP = API2();
    const [form] = Form.useForm();
	const setBreadCrumbs = useContext(BreadCrumbContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const [newMembers, setNewMembers] = React.useState([]);

    const [pendingTerminationMembers, setPendingTerminationMembers] = React.useState([]);
    const [cancelledMembers, setCancelledMembers] = React.useState([]);
    const [deregisteredMembers, setDeregisteredMembers] = React.useState([]);
    const [outstandingMembers, setOutstandingMembers] = React.useState([]);
    const [expectedMembers, setExpectedMembers] = React.useState([]);



    const [revenueByRegion, setRevenueByRegion] = React.useState([]);
    const [data, setData] = React.useState({});

    const [showRevenueByRegion, setShowRevenueByRegion] = useState(false);

	useEffect(() => {




        AP.get('/dailystats/'+props.match.params.id)
            .then(function(res){

				setBreadCrumbs([
					<Link to="/dailystats"><span><i className="fas fa-users"/> Daily Stats</span></Link>,
					<span><i className="fas fa-user"/> <Moment format="DD MMMM YYYY" unix>{res.date}</Moment></span>
				]);

                setNewMembers(res.new_members);
                setPendingTerminationMembers(res.pending_cancel_members);
                setCancelledMembers(res.cancelled_members);
                setDeregisteredMembers(res.deregistered_members);
                setOutstandingMembers(res.outstanding_members);
                setExpectedMembers(res.expected_members);
                setRevenueByRegion(res.revenue_by_region);
                setData(res);
                setIsLoading(false);
            })

	}, []);


	let newMembersColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'first_name',
            sorter: true,
            align:'left',
            render: (text, record) => {
				return <div style={{display: "flex"}}>
					<div><Avatar src={(record.image) ? record.image.endpoint : null}
								 icon={<i className="far fa-user"></i>} size="large"/></div>
					<div style={{paddingLeft: "20px"}}>
						<div>{record.first_name} {record.last_name}</div>
						<div>{record.email}</div>
					</div>
				</div>
			}
        },
        {
            title: 'Mobile',
            key: 'mobile',
            dataIndex: 'mobile',
            width: "150px",
            sorter: true,
        },
        {
            title: 'Subscription',
            key: 'subscription',
            dataIndex: 'subscription',
            width: "150px",
            sorter: true,
            render: function(text, record) {
                return record.subscriptions.map(subscription => (
                    <div>{subscription.plan.name}</div>
                ));
            }
        },/*
        {
            title: 'Renewal Month',
            key: 'renewalMonth',
            width: "150px",
            sorter: false,
            render: function(text, record) {
                return record.subscriptions.map(subscription => {
                    if(!subscription.invoices || !subscription.invoices.length) return '';
                    return <div>{(subscription.plan.name == 'Annual')
                        ? <Moment format="MMMM" unix>{subscription.invoices[0].start_at}</Moment>
                        : '-'}</div>
                });
            }
        }*/
    ];
	let unaccountedForColumns = [
		{
			title: 'Name',
			key: 'name',
			dataIndex: 'first_name',
			sorter: true,
			align:'left',
			render: (text, record) => (
				<div style={{display:"flex"}}>
					<div><Avatar src={(record.user.image)?record.user.image.endpoint:null} icon={<i className="far fa-user"></i>} size="large"/></div>
					<div style={{paddingLeft:"20px"}}>
						<div>{record.user.first_name} {record.user.last_name}</div>
						<div>{record.user.email}</div>
					</div>
				</div>
			)
		},
		{
			title: 'Details',
			key: 'details',
			width: 300,
			sorter: true,
			render: (text, record) => {
			    if(record.expected) return 'Missing';
				return 'Extra';
            }
		},
		{
			title: 'Mobile',
			key: 'mobile',
			dataIndex: 'user.mobile',
			width: "150px",
			sorter: true,
		},
		{
			title: 'Subscription',
			key: 'subscription',
			dataIndex: 'subscription',
			width: "150px",
			sorter: true,
			render: function(text, record) {
				return <div>{record.subscription.plan.name}</div>;
			}
		},
		{
			title: 'Invoice Number',
			key: 'invoiceNumber',
			width: "100px",
			sorter: false,
			render: function(text, record) {
				return <div>{record.number} ({record.id})</div>
			}
		}
	];
    let outstandingMembersColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'first_name',
            sorter: (a, b) => {return (!a.first_name)?-1:a.first_name.localeCompare(b.first_name)},
            align:'left',
            render: (text, record) => (
                <div style={{display:"flex"}}>
                    <div><Avatar src={(record.image)?record.image.endpoint:null} icon={<i className="far fa-user"></i>} size="large"/></div>
                    <div style={{paddingLeft:"20px"}}>
                        <div>{record.name}</div>
                        <div>{record.email}</div>
                    </div>
                </div>
            )
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            sorter: (a, b) => {return (!a.subscriptions[0])?-1:a.subscriptions[0].status.localeCompare(b.subscriptions[0].status)},
            align:'left',
            render: function(text, record) {

                return record.subscriptions.map(function(s){
                    return <div>{s.status}</div>
                });
            }
        },
        {
            title: 'Mobile',
            key: 'mobile',
            dataIndex: 'mobile',
            width: "150px",
            sorter: (a, b) => {return (!a.mobile)?-1:a.mobile.localeCompare(b.mobile)},
        },
        {
            title: 'Subscription',
            key: 'subscription',
            dataIndex: 'subscription',
            width: 200,
            sorter: (a, b) => {return (!a.subscriptions[0])?-1:a.subscriptions[0].plan.name.localeCompare(b.subscriptions[0].plan.name)},
            render: function(text, record) {
                return record.subscriptions.map(subscription => (
                    <div>{subscription.plan.name}</div>
                ));
            }
        }
    ];

	function onShowRevenueByRegion(e){
        setShowRevenueByRegion(e.target.checked);
    }

    function saveForm(data){
        setIsSaving(true);

        let url = '/endofmonth/'+props.match.params.date;


        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }

    function getRevenueColumns(withRegion){
	    let columns = [];
        columns.push({
            title: withRegion?'Region':'Plan',
            key: 'name',
            dataIndex: 'name',
            align:'left',
            defaultSortOrder:'descend'
        });
        if(withRegion){
            columns.push({
                title: 'Plan',
                key: 'plan',
                dataIndex: 'plan',
                align:'left',
            });
        }
        /*
        columns.push({
            title: 'Start Of Month',
            key: 'predicted',
            dataIndex: 'predicted',
            align:'left',
            render: function(text, record) {

                console.log(record);
                return <div style={{textAlign:"left"}}>
                    <div>{'$'+(record.predicted/100)}</div>
                    <div>({record.predictedCount})</div>
                </div>
            }
        });
        */
		columns.push({
			title: 'Expected',
			key: 'expected',
			dataIndex: 'expected',
			align:'left',
			render: function(text, record) {
				return <div style={{textAlign:"left"}}>
					<div>{'$'+(record.expected/100)}</div>
					<div>({record.expectedCount})</div>
				</div>
			}
		});
        columns.push({
            title: 'Actual',
            key: 'actual',
            dataIndex: 'actual',
            render: function(text, record) {
                return <div style={{textAlign:"left"}}>
                    <div>{'$'+(record.actual/100)}</div>
                    <div>(Current Month: {record.currentCount}, Past Months: {(record.actualCount-record.currentCount)})</div>
                </div>
            }
        });
        columns.push({
            title: 'Overdue',
            key: 'noncompliant',
            dataIndex: 'noncompliant',
            width:130,
            render: function(text, record) {
                return <div style={{textAlign:"left",width:70}}>
                    <div>{'$'+(record.failed/100)}</div>
                    <div>({record.failedCount})</div>
                </div>
            }
        });
        columns.push({
            title: <span style={{color:"#b9b9b9"}}>Past Overdue</span>,
            key: 'noncompliant',
            dataIndex: 'noncompliant',
            width:130,
            render: function(text, record) {
                return <div style={{textAlign:"left",color:"#b9b9b9"}}>
                    <div>{'$'+(record.totalFailed/100)}</div>
                    <div>({record.totalFailedCount})</div>
                </div>
            }
        });


        if(data.revenue.monthly.pendingCount || data.revenue.yearly.pendingCount) {
            columns.push({
                title: 'Pending',
                key: 'pending',
                dataIndex: 'pending',
                render: function (text, record) {
                    return <div style={{pending:"left"}}>
                        <div>{'$'+(record.pending/100)}</div>
                        <div>({record.pendingCount})</div>
                    </div>
                }
            });
        }
        columns.push({
            title: 'Unaccounted For',
            key: 'unaccountedfor',
            dataIndex: 'unaccountedfor',
            render: function(text, record) {
                return <div style={{pending:"left"}}>
                    <div>&nbsp;</div>
                    <div>({record.unaccountedCount})</div>
                </div>
            }
        });
	    return columns;
    }
    function getRevenueByRegionData(){

	    const totalRow = {
            name:'Total',
            plan: '',
            predicted:0,
            predictedCount:0,
            expected:0,
            expectedCount:0,
            actual:0,
            actualCount:0,
            currentCount:0,
            failed:0,
            failedCount:0,
            totalFailed:0,
            totalFailedCount:0,
            continuedFailed:0,
            continuedFailedCount:0,
            pending:0,
            pendingCount:0,
            unaccounted:0,
            unaccountedCount:0
        }

	    let resp = [];
        Object.entries(data.revenue_by_region).map(function([plan,planData]){
            return Object.entries(planData).map(function([region,regionData]){

                regionData.predicted = parseInt(regionData.predicted);
                regionData.predictedCount = parseInt(regionData.predictedCount);
                regionData.expected = parseInt(regionData.expected);
                regionData.expectedCount = parseInt(regionData.expectedCount);
                regionData.actual = parseInt(regionData.actual);
                regionData.actualCount = parseInt(regionData.actualCount);
                regionData.currentCount = parseInt(regionData.currentCount);
                regionData.failed = parseInt(regionData.failed);
                regionData.failedCount = parseInt(regionData.failedCount);
                regionData.totalFailed = parseInt(regionData.totalFailed);
                regionData.totalFailedCount = parseInt(regionData.totalFailedCount);
                regionData.continuedFailed = parseInt(regionData.continuedFailed);
                regionData.continuedFailedCount = parseInt(regionData.continuedFailedCount);
                regionData.pending = parseInt(regionData.pending);
                regionData.pendingCount = parseInt(regionData.pendingCount);

                totalRow.predicted += regionData.predicted;
                totalRow.predictedCount += regionData.predictedCount;
                totalRow.expected += regionData.expected;
                totalRow.expectedCount += regionData.expectedCount;
                totalRow.actual += regionData.actual;
                totalRow.actualCount += regionData.actualCount;
                totalRow.currentCount += regionData.currentCount;
                totalRow.failed += regionData.failed;
                totalRow.failedCount += regionData.failedCount;
                totalRow.totalFailed += regionData.totalFailed;
                totalRow.totalFailedCount += regionData.totalFailedCount;
                totalRow.continuedFailed += regionData.continuedFailed;
                totalRow.continuedFailedCount += regionData.continuedFailedCount;
                totalRow.pending += regionData.pending;
                totalRow.pendingCount += regionData.pendingCount;
                totalRow.unaccounted += Math.abs(
                    regionData.expected
                    -regionData.actual
                    -regionData.failed
                    -regionData.pending
                );
                totalRow.unaccountedCount += (
                    regionData.expectedCount
                    -regionData.currentCount
                    -regionData.failedCount
                    -regionData.pendingCount
                );

                resp.push({
                    name:region,
                    plan: plan,
                    predicted:regionData.predicted,
                    predictedCount:regionData.predictedCount,
                    expected:regionData.expected,
                    expectedCount:regionData.expectedCount,
                    actual:regionData.actual,
                    actualCount:parseInt(regionData.actualCount),
                    currentCount:parseInt(regionData.currentCount),
                    failed:regionData.failed,
                    failedCount:regionData.failedCount,
                    totalFailed:regionData.totalFailed,
                    totalFailedCount:regionData.totalFailedCount,
                    continuedFailed:regionData.continuedFailed,
                    continuedFailedCount:regionData.continuedFailedCount,
                    pending:regionData.pending,
                    pendingCount:regionData.pendingCount,
                    unaccounted:Math.abs(
                        regionData.expected
                        -regionData.actual
                        -regionData.failed
                        -regionData.pending
                    ),
                    unaccountedCount:(
                        regionData.expectedCount
                        -regionData.currentCount
                        -regionData.failedCount
                        -regionData.pendingCount
                    )
                });
            })
        });
        resp.sort((a, b) => (a.name == b.name)?((a.plan > b.plan) ? 1 : -1):   ((a.name > b.name) ? 1 : -1));

        return resp.concat(totalRow);
    }

    function downloadRawData(){
        AP.file('/export/EOMData?date='+props.match.params.date);
    }
    function downloadRawTransactions(){
        AP.file('/export/EOMTransactions?date='+props.match.params.date);
    }

    return (

        <div className="content_inner" style={{maxWidth:1300,paddingTop:40}}>

            {!isLoading && <>
                <h3>Members</h3>

                <div style={{display:"flex",gap:20,justifyContent:"center"}}>
                    <div title="Monthly Members" bordered={false} style={{ width: 300, textAlign:"center" }} className="dashboard-card">
                        <h6>Monthly Members</h6>
                        <p style={{fontSize:"30px",textAlign:"center"}}>{data.user_stats.month.total}</p>
                        <p style={{fontSize:"12px"}}>
                            <div>new: {data.user_stats.month.new}</div>
                            <div>deregistered: {data.user_stats.month.deregistered}</div>
							<div>cancelled: {data.user_stats.month.cancelled}</div>
							<div>debt: {data.user_stats.month.debt}</div>
                        </p>
                    </div>
                    <div title="Yearly Members" bordered={false} style={{ width: 300, textAlign:"center" }} className="dashboard-card">
						<h6>Yearly Members</h6>
                        <p style={{fontSize:"30px",textAlign:"center"}}>{data.user_stats.year.total}</p>
						<p style={{fontSize:"12px"}}>
                            <div>new: {data.user_stats.year.new}</div>
                            <div>deregistered: {data.user_stats.year.deregistered}</div>
							<div>cancelled: {data.user_stats.year.cancelled}</div>
							<div>debt: {data.user_stats.year.debt}</div>
                        </p>
                    </div>
                    <div title="Total Members" bordered={false} style={{ width: 300, textAlign:"center" }} className="cardFlex dashboard-card">
						<div style={{display:"flex",flexDirection:"column",height:"100%"}}>
                            <h6>Total Members</h6>
                            <p style={{fontSize:"30px",textAlign:"center"}}>{data.user_stats.Total.total}</p>
							<p style={{fontSize:"12px"}}>
                                <div>new: {data.user_stats.month.new+data.user_stats.year.new}</div>
                                <div>deregistered: {data.user_stats.month.deregistered+data.user_stats.year.deregistered}</div>
                                <div>cancelled:  {data.user_stats.month.cancelled+data.user_stats.year.cancelled}</div>
                            </p>
                        </div>
                    </div>
                </div>


				<div style={{marginTop:80}} className="dashboard-card">
                    <div style={{display:"flex"}}>
                        <h3 style={{flex:1}}>Revenue</h3>
                        <Checkbox checked={showRevenueByRegion} onChange={onShowRevenueByRegion}>By Region</Checkbox>
                    </div>







                    {!showRevenueByRegion &&
                    <Table
                        rowKey="id"
                        pagination={false}
                        dataSource={[
                            {
                                name:'Monthly',
                                predicted:data.revenue.monthly.predicted,
                                predictedCount:data.revenue.monthly.predictedCount,
                                expected:data.revenue.monthly.expected,
                                expectedCount:data.revenue.monthly.expectedCount,
                                actual:data.revenue.monthly.actual,
                                actualCount:parseInt(data.revenue.monthly.actualCount),
                                currentCount:parseInt(data.revenue.monthly.currentCount),
                                failed:data.revenue.monthly.failed,
                                failedCount:data.revenue.monthly.failedCount,
                                totalFailed:data.revenue.monthly.totalFailed,
                                totalFailedCount:data.revenue.monthly.totalFailedCount,
                                continuedFailed:data.revenue.monthly.continuedFailed,
                                continuedFailedCount:data.revenue.monthly.continuedFailedCount,
                                pending:data.revenue.monthly.pending,
                                pendingCount:data.revenue.monthly.pendingCount,
                                unaccounted:Math.abs(
                                    data.revenue.monthly.expected
                                    -data.revenue.monthly.actual
                                    -data.revenue.monthly.failed
                                    -data.revenue.monthly.pending
                                ),
                                unaccountedCount:(
                                    data.revenue.monthly.expectedCount
                                    -data.revenue.monthly.currentCount
                                    -data.revenue.monthly.failedCount
                                    -data.revenue.monthly.pendingCount
                                )
                            },
                            {
                                name:'Yearly',
                                predicted:data.revenue.yearly.predicted,
                                predictedCount:data.revenue.yearly.predictedCount,
                                expected:data.revenue.yearly.expected,
                                expectedCount:data.revenue.yearly.expectedCount,
                                actual:data.revenue.yearly.actual,
                                actualCount:parseInt(data.revenue.yearly.actualCount),
                                currentCount:parseInt(data.revenue.yearly.currentCount),
                                failed:data.revenue.yearly.failed,
                                failedCount:data.revenue.yearly.failedCount,
                                totalFailed:data.revenue.yearly.totalFailed,
                                totalFailedCount:data.revenue.yearly.totalFailedCount,
                                continuedFailed:data.revenue.yearly.continuedFailed,
                                continuedFailedCount:data.revenue.yearly.continuedFailedCount,
                                pending:data.revenue.yearly.pending,
                                pendingCount:data.revenue.yearly.pendingCount,
                                unaccounted:Math.abs(
                                    data.revenue.yearly.expected
                                    -data.revenue.yearly.actual
                                    -data.revenue.yearly.failed
                                    -data.revenue.yearly.pending
                                ),
                                unaccountedCount:(
                                    data.revenue.yearly.expectedCount
                                    -data.revenue.yearly.currentCount
                                    -data.revenue.yearly.failedCount
                                    -data.revenue.yearly.pendingCount
                                )
                            },
                            {
                                name:'Total',
                                predicted:parseInt(data.revenue.monthly.predicted)+parseInt(data.revenue.yearly.predicted),
                                predictedCount:parseInt(data.revenue.monthly.predictedCount)+parseInt(data.revenue.yearly.predictedCount),
                                expected:parseInt(data.revenue.monthly.expected)+parseInt(data.revenue.yearly.expected),
                                expectedCount:parseInt(data.revenue.monthly.expectedCount)+parseInt(data.revenue.yearly.expectedCount),
                                actual:parseInt(data.revenue.monthly.actual)+parseInt(data.revenue.yearly.actual),
                                actualCount:parseInt(data.revenue.monthly.actualCount)+parseInt(data.revenue.yearly.actualCount),
                                currentCount:parseInt(data.revenue.monthly.currentCount)+parseInt(data.revenue.yearly.currentCount),
                                failed:parseInt(data.revenue.monthly.failed)+parseInt(data.revenue.yearly.failed),
                                failedCount:parseInt(data.revenue.monthly.failedCount)+parseInt(data.revenue.yearly.failedCount),
                                totalFailed:parseInt(data.revenue.monthly.totalFailed)+parseInt(data.revenue.yearly.totalFailed),
                                totalFailedCount:parseInt(data.revenue.monthly.totalFailedCount)+parseInt(data.revenue.yearly.totalFailedCount),
                                continuedFailed:parseInt(data.revenue.monthly.continuedFailed)+parseInt(data.revenue.yearly.continuedFailed),
                                continuedFailedCount:parseInt(data.revenue.monthly.continuedFailedCount)+parseInt(data.revenue.yearly.continuedFailedCount),
                                pending:parseInt(data.revenue.monthly.pending)+parseInt(data.revenue.yearly.pending),
                                pendingCount:parseInt(data.revenue.monthly.pendingCount)+parseInt(data.revenue.yearly.pendingCount),
                                unaccounted:Math.abs(
                                    (parseInt(data.revenue.monthly.expected)+parseInt(data.revenue.yearly.expected))
                                    -(parseInt(data.revenue.monthly.actual)+parseInt(data.revenue.yearly.actual))
                                    -(parseInt(data.revenue.monthly.failed)+parseInt(data.revenue.yearly.failed))
                                    -(parseInt(data.revenue.monthly.pending)+parseInt(data.revenue.yearly.pending))
                                ),
                                unaccountedCount:(
                                    (parseInt(data.revenue.monthly.expectedCount)+parseInt(data.revenue.yearly.expectedCount))
                                    -(parseInt(data.revenue.monthly.currentCount)+parseInt(data.revenue.yearly.currentCount))
                                    -(parseInt(data.revenue.monthly.failedCount)+parseInt(data.revenue.yearly.failedCount))
                                    -(parseInt(data.revenue.monthly.pendingCount)+parseInt(data.revenue.yearly.pendingCount))
                                )
                            }
                        ]}
                        columns={getRevenueColumns()}
                    />
                    }

                    {showRevenueByRegion &&
                    <Table
                        rowKey="id"
                        pagination={false}
                        dataSource={getRevenueByRegionData()}

                            /*
                            [

                            {
                                name:'Total',
                                predicted:parseInt(data.revenue_by_region.monthly.predicted)+parseInt(data.revenue_by_region.yearly.predicted),
                                predictedCount:parseInt(data.revenue_by_region.monthly.predictedCount)+parseInt(data.revenue_by_region.yearly.predictedCount),
                                expected:parseInt(data.revenue_by_region.monthly.expected)+parseInt(data.revenue_by_region.yearly.expected),
                                expectedCount:parseInt(data.revenue_by_region.monthly.expectedCount)+parseInt(data.revenue_by_region.yearly.expectedCount),
                                actual:parseInt(data.revenue_by_region.monthly.actual)+parseInt(data.revenue_by_region.yearly.actual),
                                actualCount:parseInt(data.revenue_by_region.monthly.actualCount)+parseInt(data.revenue_by_region.yearly.actualCount),
                                currentCount:parseInt(data.revenue_by_region.monthly.currentCount)+parseInt(data.revenue_by_region.yearly.currentCount),
                                failed:parseInt(data.revenue_by_region.monthly.failed)+parseInt(data.revenue_by_region.yearly.failed),
                                failedCount:parseInt(data.revenue_by_region.monthly.failedCount)+parseInt(data.revenue_by_region.yearly.failedCount),
                                totalFailed:parseInt(data.revenue_by_region.monthly.totalFailed)+parseInt(data.revenue_by_region.yearly.totalFailed),
                                totalFailedCount:parseInt(data.revenue_by_region.monthly.totalFailedCount)+parseInt(data.revenue_by_region.yearly.totalFailedCount),
                                continuedFailed:parseInt(data.revenue_by_region.monthly.continuedFailed)+parseInt(data.revenue_by_region.yearly.continuedFailed),
                                continuedFailedCount:parseInt(data.revenue_by_region.monthly.continuedFailedCount)+parseInt(data.revenue_by_region.yearly.continuedFailedCount),
                                pending:parseInt(data.revenue_by_region.monthly.pending)+parseInt(data.revenue_by_region.yearly.pending),
                                pendingCount:parseInt(data.revenue_by_region.monthly.pendingCount)+parseInt(data.revenue_by_region.yearly.pendingCount),
                                unaccounted:Math.abs(
                                    (parseInt(data.revenue_by_region.monthly.expected)+parseInt(data.revenue_by_region.yearly.expected))
                                    -(parseInt(data.revenue_by_region.monthly.actual)+parseInt(data.revenue_by_region.yearly.actual))
                                    -(parseInt(data.revenue_by_region.monthly.failed)+parseInt(data.revenue_by_region.yearly.failed))
                                    -(parseInt(data.revenue_by_region.monthly.pending)+parseInt(data.revenue_by_region.yearly.pending))
                                ),
                                unaccountedCount:(
                                    (parseInt(data.revenue_by_region.monthly.expectedCount)+parseInt(data.revenue_by_region.yearly.expectedCount))
                                    -(parseInt(data.revenue_by_region.monthly.currentCount)+parseInt(data.revenue_by_region.yearly.currentCount))
                                    -(parseInt(data.revenue_by_region.monthly.failedCount)+parseInt(data.revenue_by_region.yearly.failedCount))
                                    -(parseInt(data.revenue_by_region.monthly.pendingCount)+parseInt(data.revenue.yearly.pendingCount))
                                )
                            }
                        ]*/
                        columns={getRevenueColumns(true)}
                    />
                    }
                </div>

                <div style={{paddingTop:20, display:"flex", gap:20}}>
                    <Button type="secondary" shape="round" onClick={downloadRawTransactions} style={{marginLeft:"auto"}}><i className="far fa-download"/>&nbsp;Export Raw Transactions</Button>
                    <Button type="primary" shape="round" onClick={downloadRawData}><i className="far fa-download"/>&nbsp;Export Raw Data</Button>
                </div>

				<div style={{marginTop:60}} className="dashboard-card">
					<ProgressGraph data={data.progress_graph_data}/>
				</div>



				<Collapse bordered={false} defaultActiveKey={['1']} style={{marginTop: 80}} className="dashboard-card">
					<Panel header={<><span>Unaccounted For ({(data.unaccounted_for_invoices.length)})</span></>} key="UnaccountedFor" style={{fontSize: 30}}>
						<Table
							rowKey="id"
							pagination={false}
							dataSource={data.unaccounted_for_invoices}
							columns={unaccountedForColumns}
						/>
					</Panel>
				</Collapse>

                <Collapse bordered={false} defaultActiveKey={['1']} style={{marginTop: 80}} className="dashboard-card">
                    <Panel header={<><span>Expected Members ({(expectedMembers.length)})</span> <div style={{fontSize:14,paddingLeft:20}}><div>Monthly:{(data.user_stats.month.expected)}</div><div>Yearly:{(data.user_stats.year.expected)}</div></div></>} key="ExpectedMembers" style={{fontSize: 30}}>
                        <Table
                            rowKey="id"
                            pagination={false}
                            dataSource={expectedMembers}
                            columns={newMembersColumns}
                        />
                    </Panel>
                </Collapse>

                <Collapse bordered={false} defaultActiveKey={['1']} style={{marginTop:80}} className="dashboard-card">
                    <Panel header={"Pending Termination ("+(data.user_stats.month.pending_cancel+data.user_stats.year.pending_cancel)+")"} key="PendingTermination" style={{fontSize:30}}
                           header={<><span>Pending Termination ({(data.user_stats.month.pending_cancel+data.user_stats.month.pending_deregister+data.user_stats.year.pending_cancel+data.user_stats.year.pending_deregister)})</span> <div style={{fontSize:14,paddingLeft:20}}><div>Monthly:{(data.user_stats.month.pending_cancel+data.user_stats.month.pending_deregister)}</div><div>Yearly:{(data.user_stats.year.pending_cancel+data.user_stats.year.pending_deregister)}</div></div><div style={{fontSize:14,paddingLeft:20}}><div>Cancelled:{(data.user_stats.month.pending_cancel+data.user_stats.year.pending_cancel)}</div><div>Deregister:{(data.user_stats.month.pending_deregister+data.user_stats.year.pending_deregister)}</div></div></>}
                    >
                        <Table
                            rowKey="id"
                            pagination={false}
                            dataSource={pendingTerminationMembers}
                            columns={newMembersColumns}
                        />
                    </Panel>
                </Collapse>

                <Collapse bordered={false} defaultActiveKey={['1']} style={{marginTop:80}} className="dashboard-card">
                    <Panel header={"New Members ("+(data.user_stats.month.new+data.user_stats.year.new)+")"} key="NewMembers" style={{fontSize:30}}>
                        <Table
                            rowKey="id"
                            pagination={false}
                            dataSource={newMembers}
                            columns={newMembersColumns}
                        />
                    </Panel>
                </Collapse>


                <Collapse bordered={false} defaultActiveKey={['1']} style={{marginTop:80}} className="dashboard-card">
                    <Panel header={"Cancelled Members ("+(data.user_stats.month.cancelled+data.user_stats.year.cancelled)+")"} key="CancelledMembers" style={{fontSize:30}}>
                        <Table
                            rowKey="id"
                            pagination={false}
                            dataSource={cancelledMembers}
                            columns={newMembersColumns}
                        />
                    </Panel>
                </Collapse>

                <Collapse bordered={false} defaultActiveKey={['1']} style={{marginTop:80}} className="dashboard-card">
                    <Panel header={"Deregistered Members ("+(data.user_stats.month.deregistered+data.user_stats.year.deregistered)+")"} key="DeregisteredMembers" style={{fontSize:30}}>
                        <Table
                            rowKey="id"
                            pagination={false}
                            dataSource={deregisteredMembers}
                            columns={newMembersColumns}
                        />
                    </Panel>
                </Collapse>

                {false &&
                <Collapse bordered={false} defaultActiveKey={['1']} style={{marginTop: 80}} className="dashboard-card">
                    <Panel header="Non-Compliant Members (?)" key="NonCompliantMembers" style={{fontSize: 30}}>
                        <Table
                            rowKey="id"
                            pagination={false}
                            dataSource={[]}
                            columns={newMembersColumns}
                        />
                    </Panel>
                </Collapse>
                }

                <Collapse bordered={false} defaultActiveKey={['1']} style={{marginTop: 80}} className="dashboard-card">
                    <Panel header={<><span>Overdue Members ({(data.user_stats.month.outstanding+data.user_stats.year.outstanding)})</span> <div style={{fontSize:14,paddingLeft:20}}><div>Monthly:{(data.user_stats.month.outstanding)}</div><div>Yearly:{(data.user_stats.year.outstanding)}</div></div></>} key="OutstandingMembers" style={{fontSize: 30}}>
                        <Table
                            rowKey="id"
                            pagination={false}
                            dataSource={outstandingMembers}
                            columns={outstandingMembersColumns}
                        />
                    </Panel>
                </Collapse>

                {false &&
                <Collapse bordered={false} defaultActiveKey={['1']} style={{marginTop: 80}} className="dashboard-card">
                    <Panel header="Deregistered Members (?)" key="DeregistratedMembers" style={{fontSize: 30}}>
                        <Table
                            rowKey="id"
                            pagination={false}
                            dataSource={[]}
                            columns={newMembersColumns}
                        />
                    </Panel>
                </Collapse>
                }



                <h3 style={{marginTop:"80px"}}>Notes</h3>


                <Form
                    {...formItemLayout}
                    form={form}
                    name="eof"
                    onFinish={saveForm}
                    scrollToFirstError
                    style={{ margin:"auto"}}
                >


                    <Form.Item
                        name="notes"
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea rows={8} />
                    </Form.Item>

                </Form>
            </>
            }

        </div>
	);
};