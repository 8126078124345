import '../App.css';
import {BrowserRouter, NavLink , Route, Switch} from "react-router-dom";
import Routes from "../components/Routes";


function NoLayout(props) {

	return (
        <BrowserRouter>
            <Routes/>


            <link rel="stylesheet" id="gforms_reset_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formreset.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_formsmain_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formsmain.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_ready_class_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/readyclass.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_browsers_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/browsers.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="global-styles-inline-css" href="/css/global-styles-inline.css"></link>
            {false && <link rel="stylesheet" id="other-css" href="https://www.sportsnutritionassociation.com/wp-content/themes/scsnassoc/assets/others.css?ver=170601.1.0.2" media="all"/>}
            <link rel="stylesheet" id="theme-css" href="/css/wp_style.css" media="all"/>


        </BrowserRouter>
	);
}

export default NoLayout;



