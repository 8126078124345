import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin,
    Checkbox
} from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {Modal, notification} from "antd/lib/index";
import Moment from 'react-moment';
import dayjs from "dayjs";


const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function InvoiceForm(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
	const [subscriptionId, setSubscriptionId] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const AP = API2();


    useEffect(() => {

        props.form.resetFields();

        if(props.invoiceId) {
            AP.get('/invoice/'+props.invoiceId)
                .then(function (res) {

                    setSubscriptions([]);

                    res.date = [];
                    if (res.start_at) {
                        res.date.push(dayjs(res.start_at * 1000));
                    } else {
                        res.date.push(null);
                    }

                    if (res.end_at) {
                        res.date.push(dayjs(res.end_at * 1000));
                    } else {
                        res.date.push(null);
                    }

                    if(!res.paid) {
                        res.paid = 0;
                    }

                    if(!res.prevent_fees)          res.prevent_fees          = false;
                    if(!res.prevent_notifications) res.prevent_notifications = false;
                    res.is_primary = res.is_primary?true:false;

                    props.form.setFieldsValue(res);
                    setIsLoading(false);
                });
        }else{
            AP.get('/user/'+props.user.id+'/subscriptions?pageSize=9999')
                .then(function (res) {
                    setSubscriptions(res.data);

                    props.form.setFieldsValue({
                        prevent_fees:false,
                        prevent_notifications:false,
						is_primary:false,
						paid:0,
                    });
                });

            setIsLoading(false);
        }


    }, [props.invoiceId]);

	useEffect(() => {
		if(!subscriptionId) return true;

		AP.get('/subscription/'+subscriptionId+'/newInvoice')
			.then(function (res) {
			    let resp = props.form.getFieldsValue();

				resp.date = [];
				if (res.start_at) {
					resp.date.push(dayjs(res.start_at * 1000));
				} else {
					resp.date.push(null);
				}

				if (res.end_at) {
					resp.date.push(dayjs(res.end_at * 1000));
				} else {
					resp.date.push(null);
				}

				props.form.setFieldsValue({...resp});
			});


	}, [subscriptionId]);

    useEffect(() => {
        if(props.triggerDelete) deleteForm();
    }, [props.triggerDelete]);

    function saveForm(data){

        setIsSaving(true);

        if(!props.invoiceId) {
            data.user_id = props.user.id;
        }

        if(data.date[0]){
            data.start_at = data.date[0].unix();
        }
        if(data.date[1]){
            data.end_at = data.date[1].unix();
        }
        delete data.date;

        let url = '/invoice';
        if(props.invoiceId) {
            url += '/'+props.invoiceId;
        }

        AP.post(url,data).then(function(res){

            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            props.onFinish();
        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    props.form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }

    function deleteForm(){

        if(!props.invoiceId) return false;

		confirm({
			title: 'Are you sure you want to delete the invoice?',
			icon: <ExclamationCircleOutlined />,
			onOk() {

				setIsSaving(true);

				let url = '/invoice/'+props.invoiceId;

				AP.delete(url).then(function(res){

					notification.success({
						message: `Deleted`,
						placement: 'bottomRight',
						duration:1,
						closeIcon : (<div></div>)
					});

					props.onFinish();
				}).catch(function(res){
					setIsSaving(false);
					if(res.response.data.errors) {
						let errorList = {};
						for (let error of res.response.data.errors) {
							if (!errorList[error.input]) errorList[error.input] = [];
							errorList[error.input].push(error.message);
						}
						for (let [input, errors] of Object.entries(errorList)) {
							props.form.setFields([
								{
									name: input,
									errors: errors,
								},
							]);
						}
					}
				});

			},
			onCancel() {
				return;
			},
		});




    }

    return(
        <Form
            id="myForm"
            {...formItemLayout}
            form={props.form}
            name="invoice"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >

            {subscriptions.length>0 &&
            <Form.Item
                name="subscription_id"
                label="Subscription"
                rules={[
                    {
                        required: true,
                        message: 'Please select a subscription',
                    }
                ]}
            >
                <Select
                    style={{width: '100%'}}
                    placeholder="Please select"
                    onChange={(v)=>setSubscriptionId(v)}
                >
                    {subscriptions.map(subscription => (
                        <Option value={subscription.id}>
                            <div style={{display: "flex"}}>
                                <div style={{flex: 1}}>{subscription.plan.name}</div>
                                <div>{(subscription.plan.starts_at
                                    ? <Moment format="Do MMM YYYY h:mm a" unix>{subscription.plan.starts_at}</Moment>
                                    : <Moment format="YYYY" unix>{subscription.starts_at}</Moment> )}
                                </div>
                            </div>
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            }

            <Form.Item
                name="date"
                label="Date Range"
                rules={[
                    {
                        required: true,
                        message: 'Please select a date range',
                    }
                ]}
            >
                <RangePicker showTime allowEmpty={[false,true]} />
            </Form.Item>

            <Form.Item
                name="amount"
                label="Amount"
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="currency"
                label="Currency"
                rules={[
                    {
                        required: true,
                        message: 'Please select a date range',
                    }
                ]}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    style={{margin:0}}
                >
                    {['aud','usd','nzd','eur','cad'].map(currency => (
                        <Option value={currency}>{currency.toUpperCase()}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="prevent_fees"
                label="Prevent Dishonor Fee"
                valuePropName="checked"
                style={{margin:0}}
            >
                <Checkbox></Checkbox>
            </Form.Item>

            <Form.Item
                name="prevent_notifications"
                label="Prevent Notifications"
                valuePropName="checked"
                style={{margin:0}}
            >
                <Checkbox></Checkbox>
            </Form.Item>

            <Form.Item
                name="is_primary"
                label="Primary Payment"
                valuePropName="checked"
            >
                <Checkbox></Checkbox>
            </Form.Item>

            <Form.Item
                name="paid"
                label="Paid"
            >
                <Select
                    placeholder="Select a option and change input text above"
                >
                    <Option value={0}>Not Paid</Option>
                    <Option value={1}>Paid</Option>
                </Select>
            </Form.Item>

        </Form>
	);
}