import React from "react";

export default function StaticUnregistered(props) {

    return (

		<div className="nutritionists-list">
            <div className="row" style={{display:"flex", flexWrap:"wrap",gap:20}}>
                <div className="nutritionist nutri-post ei1 odd first unregistered">
                    <div className="block" style={{display:"flex", flexDirection:"column"}}>
                        <h3>
                            <div style={{textAlign:"center", fontSize:"24px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>Craig McDonald</div>
                            </div>
                        </h3>
                        <div className="suburb meta-data" style={{display:"flex"}}><i className="fab fa-instagram"
                                style={{padding: "3px 3px 0px 0px"}}></i>
                            <div style={{minWidth:110, fontWeight: "bold"}}>Instagram:</div>
                            <div style={{width:190, display:"flex", alignItems: "center", fontSize:"17px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>@iamcraigmcdonald</div>
                            </div>
                        </div>
                        <div className="suburb meta-data" style={{display:"flex"}}><i className="fab fa-instagram"
                                style={{padding: "3px 3px 0px 0px"}}></i>
                            <div style={{minWidth:110, fontWeight: "bold"}}>Website:</div>
                            <div style={{width: 190, display: "flex", alignItems: "center", fontSize: "12px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>https://www.nustrength.com.au</div>
                            </div>
                        </div>
                        <div>
                            <div className="country meta-data" style={{display:"flex"}}><em className="fa fa-globe" aria-hidden="true"
                                    style={{marginLeft:-2, padding: "3px 3px 0px 0px"}}></em>
                                <div style={{minWidth:110, fontWeight: "bold"}}>Country:</div>
                                <div style={{width:185, fontSize: "18px"}}>
                                    <div style={{display:"block", whiteSpace:"nowrap"}}>Australia</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nutritionist nutri-post ei1 odd first unregistered">
                    <div className="block" style={{display:"flex", flexDirection:"column"}}>
                        <h3>
                            <div style={{textAlign:"center", fontSize:"24px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>Reilly Meyer</div>
                            </div>
                        </h3>
                        <div className="suburb meta-data" style={{display:"flex"}}><i className="fab fa-instagram"
                                style={{padding: "3px 3px 0px 0px"}}></i>
                            <div style={{minWidth:110, fontWeight: "bold"}}>Instagram:</div>
                            <div style={{width: 190, display: "flex", alignItems: "center", fontSize: "18px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>@ramcoaching</div>
                            </div>
                        </div>
                        <div>
                            <div className="country meta-data" style={{display:"flex"}}><em className="fa fa-globe" aria-hidden="true"
                                    style={{marginLeft:-2, padding: "3px 3px 0px 0px"}}></em>
                                <div style={{minWidth:110, fontWeight: "bold"}}>Country:</div>
                                <div style={{width:185, fontSize: "18px"}}>
                                    <div style={{display:"block", whiteSpace:"nowrap"}}>Australia</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nutritionist nutri-post ei1 odd first unregistered">
                    <div className="block" style={{display:"flex", flexDirection:"column"}}>
                        <h3>
                            <div style={{textAlign:"center", fontSize:"24px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>Sharman Vickery</div>
                            </div>
                        </h3>
                        <div className="suburb meta-data" style={{display:"flex"}}><i className="fab fa-instagram"
                                style={{padding: "3px 3px 0px 0px"}}></i>
                            <div style={{minWidth:110, fontWeight: "bold"}}>Instagram:</div>
                            <div style={{width:190, display: "flex", alignItems: "center", fontSize: "13px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>@sharmanvickerycoaching</div>
                            </div>
                        </div>
                        <div>
                            <div className="country meta-data" style={{display:"flex"}}><em className="fa fa-globe" aria-hidden="true"
                                    style={{marginLeft:-2, padding: "3px 3px 0px 0px"}}></em>
                                <div style={{minWidth:110, fontWeight: "bold"}}>Country:</div>
                                <div style={{width:185, fontSize: "18px"}}>
                                    <div style={{display:"block", whiteSpace:"nowrap"}}>Australia</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nutritionist nutri-post ei1 odd first unregistered">
                    <div className="block" style={{display:"flex", flexDirection:"column"}}>
                        <h3>
                            <div style={{textAlign:"center", fontSize:"24px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>Nicole Kovacic</div>
                            </div>
                        </h3>
                        <div className="suburb meta-data" style={{display:"flex"}}><i className="fab fa-instagram"
                                style={{padding: "3px 3px 0px 0px"}}></i>
                            <div style={{minWidth:110, fontWeight: "bold"}}>Website:</div>
                            <div style={{width: 190, display: "flex", alignItems: "center", fontSize: "8px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>https://www.elev8mma.com.au/nicole_kovacic
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="country meta-data" style={{display:"flex"}}><em className="fa fa-globe" aria-hidden="true"
                                    style={{marginLeft:-2, padding: "3px 3px 0px 0px"}}></em>
                                <div style={{minWidth:110, fontWeight: "bold"}}>Country:</div>
                                <div style={{width:185, fontSize: "18px"}}>
                                    <div style={{display:"block", whiteSpace:"nowrap"}}>Australia</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nutritionist nutri-post ei1 odd first unregistered">
                    <div className="block" style={{display:"flex", flexDirection:"column"}}>
                        <h3>
                            <div style={{textAlign:"center", fontSize:"24px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>Ellanor Warner</div>
                            </div>
                        </h3>
                        <div className="suburb meta-data" style={{display:"flex"}}><i className="fab fa-instagram"
                                style={{padding: "3px 3px 0px 0px"}}></i>
                            <div style={{minWidth:110, fontWeight: "bold"}}>Website:</div>
                            <div style={{width: 190, display: "flex", alignItems: "center", fontSize: "11px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>https://www.sugdenimpact.com</div>
                            </div>
                        </div>
                        <div>
                            <div className="country meta-data" style={{display:"flex"}}><em className="fa fa-globe" aria-hidden="true"
                                    style={{marginLeft:-2, padding: "3px 3px 0px 0px"}}></em>
                                <div style={{minWidth:110, fontWeight: "bold"}}>Country:</div>
                                <div style={{width:185, fontSize: "18px"}}>
                                    <div style={{display:"block", whiteSpace:"nowrap"}}>Australia</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nutritionist nutri-post ei1 odd first unregistered">
                    <div className="block" style={{display:"flex", flexDirection:"column"}}>
                        <h3>
                            <div style={{textAlign:"center", fontSize:"24px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>Harley Sugden</div>
                            </div>
                        </h3>
                        <div className="suburb meta-data" style={{display:"flex"}}><i className="fab fa-instagram"
                                style={{padding: "3px 3px 0px 0px"}}></i>
                            <div style={{minWidth:110, fontWeight: "bold"}}>Website:</div>
                            <div style={{width: 190, display: "flex", alignItems: "center", fontSize: "11px"}}>
                                <div style={{display:"block", whiteSpace:"nowrap"}}>https://www.sugdenimpact.com</div>
                            </div>
                        </div>
                        <div>
                            <div className="country meta-data" style={{display:"flex"}}><em className="fa fa-globe" aria-hidden="true"
                                    style={{marginLeft:-2, padding: "3px 3px 0px 0px"}}></em>
                                <div style={{minWidth:110, fontWeight: "bold"}}>Country:</div>
                                <div style={{width:185, fontSize: "18px"}}>
                                    <div style={{display:"block", whiteSpace:"nowrap"}}>Australia</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}