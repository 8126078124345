import React, { useState, useEffect,useContext  } from "react";
import BreadCrumbContext from "../../contexts/breadCrump";
import Moment from 'react-moment';
import SearchTable from "../../components/SearchTable";

export default function EmailHistory(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([
			<span><i className="fas fa-envelope"/> Email</span>,
            <span><i className="fas fa-columns"/> Template</span>
		]);
	}, []);

	const columns = [
		{
			title: 'Type',
			key: 'type',
            dataIndex: 'type',
			sortable: true,
            align:'left',
		},
		{
			title: 'To',
			key: 'email',
            dataIndex: 'email',
			sortable: true,
			align:'left',
            render: (text, record) => (
                <div>
                    <div>{(record.user)?record.user.first_name+' '+record.user.last_name:''}</div>
                    <div>{record.email}</div>
                </div>
            )
		},
		{
			title: 'Sent At',
			key: 'created_at',
            dataIndex: 'created_at',
			sortable: true,
            align:'left',
            render: (text, record) => (
                <div>
                    <Moment format="DD/MM/YYYY hh:mm A" unix>{record.created_at}</Moment>
                </div>
            ),
		},
		{
			title: 'Actions',
            key: 'actions',
            render: (text, record) => (
				<div>

				</div>
			),
		},
	];

	return (
		<div style={{marginTop:"60px"}}>
            <SearchTable columns={columns} url="/email/history"/>
		</div>
	);
};