import React from "react";
import {Button } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { Textfit } from 'react-textfit';

export default function UnregisteredCard(props) {

    return (

		<div className={"nutritionist nutri-post ei1 odd first unregistered"} style={props.style}>
			<div className="block" style={{display:"flex", flexDirection:"column"}}>
				<h3><Textfit
					mode="single"
					max={24}
					style={{textAlign:"center"}}
				>{props.nutritionist.name}</Textfit>
				</h3>
				{props.nutritionist?.social_media?.instagram && <>
					<div className="suburb meta-data" style={{display:"flex"}}>
						<i className="fab fa-instagram" style={{padding:"3px 3px 0 0"}}/>
						<div style={{minWidth:110,fontWeight:"bold"}}>Instagram:</div>
						<Textfit mode="single" max={18} style={{width:190, display:"flex", alignItems:"center"}}>{props.nutritionist.social_media.instagram}</Textfit>
					</div>	
				</>}
				{props.nutritionist?.social_media?.website && <>
					<div className="suburb meta-data" style={{display:"flex"}}>
						<i className="fab fa-instagram" style={{padding:"3px 3px 0 0"}}/>
						<div style={{minWidth:110,fontWeight:"bold"}}>Website:</div>
						<Textfit mode="single" max={18} style={{width:190, display:"flex", alignItems:"center"}}>{props.nutritionist.social_media.website}</Textfit>
					</div>	
				</>}
				<div>
					<div className="country meta-data" style={{display:"flex"}}>
						<em className="fa fa-globe" aria-hidden="true" style={{marginLeft:"-2px", padding:"3px 3px 0 0"}}></em>
						
						<div style={{minWidth:110,fontWeight:"bold"}}>Country:</div>
						<Textfit mode="single" max={18} style={{width:185}}>{props.nutritionist.country}</Textfit>
					</div>
				</div>


			</div>
		</div>
	);
}