import React from 'react';
import { Layout, Menu, Row, Col } from 'antd';

const { Footer } = Layout;

//import './sider.less';

class FooterNav extends React.Component {

	render() {
		return (
			<Footer style={{ textAlign: 'center',padding:"0" }}>

                <div className="site-footer ifot sec">
                    <div className="ifot-wg sec">
                        <div className="main">
                            <div className="row">
                                <div className="ifot-wg-a footer-col g12">
                                    <div className="footer-logo">
                                        <a href="https://www.sportsnutritionassociation.com/">
                                            <img
                                                src="/images/logo-inner.png"
                                                alt="Sports Nutrition Association"/>
                                        </a>
                                    </div>
                                    <div className="wg wg-foot wg-foot-a widget_nav_menu">
                                        <div className="menu-footer-menu-container">
                                            <ul id="menu-footer-menu" className="menu">
                                                <li id="menu-item-36"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-36 first">
                                                    <a href="https://www.sportsnutritionassociation.com/"
                                                       aria-current="page">Home</a></li>
                                                <li id="menu-item-38"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-38">
                                                    <a href="https://www.sportsnutritionassociation.com/about/">About</a>
                                                </li>
                                                <li id="menu-item-39"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-39">
                                                    <a href="https://www.sportsnutritionassociation.com/advisory-board/">Advisory Board</a></li>
                                                <li id="menu-item-184"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-184">
                                                    <a target="_blank" rel="noopener"
                                                       href="https://www.sportsnutritionistregistry.com/enrol/">Study With Us</a></li>
                                                <li id="menu-item-185"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-185">
                                                    <a target="_blank" rel="noopener"
                                                       href="https://www.sportsnutritionistregistry.com/">Registry</a>
                                                </li>
                                                <li id="menu-item-40"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-40 last">
                                                    <a href="https://www.sportsnutritionassociation.com/contact/">Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="wg wg-foot wg-foot-a widget_text">
                                        <div className="textwidget">
                                            <div className="socials smp-sc">
                                                <span className="sp s-fb">
                                                    <a href="https://www.facebook.com/Sports-Nutrition-Association-106708661319888" target="_blank" title="Facebook" rel="noopener">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </span>
                                                <span
                                                    className="sp s-ig"><a
                                                    href="https://www.instagram.com/sportsnutritionassociation/"
                                                    target="_blank" title="Instagram" rel="noopener">
                                                    <i className="fab fa-instagram"></i>
                                                </a></span><span
                                                className="sp s-yt"><a
                                                href="https://www.youtube.com/channel/UCvxTmrQKgCxccrDIt-pUYVw"
                                                target="_blank" title="YouTube" rel="noopener">
                                                <i className="fab fa-youtube"></i>
                                            </a></span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="ifot-cr sec">
                        <div className="main">
                            <div className="copyinfo afterfix"><p>Copyright 2022 Sports Nutrition Association<span
                                className="sep"> | </span> <span className="siteby">Web Design <a
                                href="https://www.lethalgraphics.com.au/" rel="nofollow" target="_blank">Perth</a> by <a
                                href="https://www.lethal.com.au/" rel="nofollow"
                                target="_blank">Lethal Digital</a></span></p></div>
                        </div>
                    </footer>
                </div>
			</Footer>
		);
	}
}

export default FooterNav;