import React, { useState, useEffect,useContext  } from "react";
import BreadCrumbContext from "../../contexts/breadCrump";
import RegisterInternalForm from '../../forms/registerInternal';

export default function Members(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-users"/> Member</span>,
            <span><i className="fas fa-users"/> Registration</span>
        ]);
	}, []);

	return (

        <div className="content_inner" style={{maxWidth:870,paddingTop:40}}>
            <RegisterInternalForm {...props}/>
        </div>
	);
};