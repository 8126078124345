import React, {useState} from "react";
import AdminDashboardMembers from "../components/AdminDashboardMembers";
import AdminDashboardStudents from "../components/AdminDashboardStudents";
import {
    Button,
} from 'antd';
import AdminDashboardAssociation from "../components/AdminDashboardAssociation";
import useToken from "../hooks/useToken";

export default function Dashboard(props) {

    const { user:authUser } = useToken();

    const localUserType = localStorage.getItem('dashboardUserType');
    const [userType, setUserType] = useState(localUserType?localUserType:'members');

    function setUT(type){
        localStorage.setItem('dashboardUserType',type);
        setUserType(type)
    }

    return (
        <div style={{paddingRight:20, width:"100%", position:"relative", top:-40}}>

            <div style={{display:"flex", gap:10, justifyContent:"center"}}>

                {false && <>
                    {authUser.hasPermissions(["student_access","member_access"]) &&
                    <Button type={(userType == "association") ? "primary" : "default"} size="large" shape="round" onClick={() => setUT("association")}>Association</Button>
                    }
                    {authUser.hasPermissions("member_access") &&
                    <Button type={(userType == "members") ? "primary" : "default"} size="large" shape="round" onClick={() => setUT("members")}>Members</Button>
                    }
                    {authUser.hasPermissions("student_access") &&
                    <Button type={(userType == "students") ? "primary" : "default"} size="large" shape="round" onClick={() => setUT("students")}>Students</Button>
                    }
                </>}
            </div>

            <div style={{paddingTop:50}}>

                {authUser.hasPermissions("member_access") &&
                <AdminDashboardMembers/>
                }

                {false && <>
                {authUser.hasPermissions("member_access") && userType == "members" &&
                <AdminDashboardMembers/>
                }

                {authUser.hasPermissions("student_access") && userType == "students" &&
                <AdminDashboardStudents/>
                }

                {authUser.hasPermissions(["student_access","member_access"]) && userType == "association" &&
                <AdminDashboardAssociation/>
                }
                </>}
            </div>
        </div>
    );
}