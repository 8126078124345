import React, {Component, useContext, useEffect, useState, useRef, createRef } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumbContext from "../contexts/breadCrump";
import { Link } from "react-router-dom";
import API2 from "../components/api2";
import {
    Table,
    Input,
    Button,
    Card,
    Avatar,
    Progress,
    Tooltip,
    Select,
    Checkbox,
    DatePicker,
    Modal,
    Dropdown,
    Menu
} from 'antd';

import { DownOutlined } from '@ant-design/icons';
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";

import useToken from "../hooks/useToken";
import SearchTable from "../components/SearchTable";

import ReactDOM from 'react-dom';
import { Bar,Column,Line } from '@ant-design/plots';

import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import moment from 'moment';
import axios from "axios/index";


const { Option } = Select;
const { RangePicker } = DatePicker;
const ReactGridLayout = WidthProvider(RGL);

//navigator.clipboard.writeText('Copy this text to clipboard')

export default function AdminDashboardStudents(props) {

    var CanvasJSReact = require('../assets/canvasjs.react');
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.default.CanvasJSChart;

    const [data, setData] = useState(false);
    const AP = API2();
    const history = useHistory();
    const { user, setUser } = useToken();

    const [regByType, setRegByType] = useState('region');
    const [regBySplit, setRegBySpit] = useState('');

    const [regByData, setRegByData] = useState([]);
    const [regionRegistrationData, setRegionRegistrationData] = useState([]);
    const [regionRegistrationSplitData, setRegionRegistrationSplitData] = useState([]);
    const [totalRegistrations, setTotalRegistrations] = useState({monthly:0,yearly:0});
    const [userStats, setUserStats] = useState([]);
    const [newRegistrationData, setNewRegistrationData] = useState([]);
    const [newRegistrationTableData, setNewRegistrationTableData] = useState([]);

    const [incomeByType, setIncomeByType] = useState('region');
    const [incomeData, setIncomeData] = useState([]);
    const [countryIncomeData, setCountryIncomeData] = useState([]);
    const [incomeMonth, setIncomeMonth] = useState('');
    const [incomeYear, setIncomeYear] = useState('');

    const [yearlyDueData, setYearlyDueData] = useState([]);
    const [overdueSummaryData, setOverdueSummaryData] = useState([]);

    const [comparisonGraphTI, setComparisonGraphTI] = useState([]);
    const [comparisonGraphNS, setGraphGirthDataNS] = useState([]);
    const [comparisonGraphES, setGraphGirthDataES] = useState([]);
    const [comparisonGraphOptions2, setComparisonGraphOptions2] = useState({totalIncome:false,newSubscriptions:true,endedSubscriptions:true,basePeriod:"1month",comparePeriod:""});

    const [comparisonGraphData, setComparisonGraphData] = useState([]);
    const [comparisonGraphOptions, setComparisonGraphOptions] = useState({totalIncome:true,newSubscriptions:true,endedSubscriptions:true,basePeriod:"1month",comparePeriod:""});
    const [comparisonGraphBaseTime, setComparisonGraphBaseTime] = useState([]);
    const [comparisonGraphCompareTime, setComparisonGraphCompareTime] = useState();


    const [isLoading, setIsLoading] = useState(true);
    const [dashboardLayout, setDashboardLayout] = useState([]);
    const [dashboardEdit, setDashboardEdit] = useState(false);
    const [dashboardVerticalCompact, setdashboardVerticalCompact] = useState(true);
    const [dashboardAddModal, setDashboardAddModal] = useState(false);
    const [gridRef, setGridRef] = useState(true);
    const [overdueMemberPaginate, setOverdueMemberPaginate] = useState(true);

	const setBreadCrumbs = useContext(BreadCrumbContext);

	function saveDashboard(layout){

	    if(isLoading) return true;

	    /*
        if (global.localStorage){
            global.localStorage.setItem(
                user.id+":dashboard",
                JSON.stringify({
                    layout: layout,
                    verticalCompact: dashboardVerticalCompact,
                    overdueMemberPaginate: overdueMemberPaginate
                })
            );
        }
        */

        AP.post('/my/dashboard',{
                "dashboard" : {
                    for: "students",
                    layout: layout,
                    verticalCompact: dashboardVerticalCompact,
                    overdueMemberPaginate: overdueMemberPaginate
                }
            })
            .then(function(res){

            });
    }

    useEffect(() => {
        if(!regByData){
            return true;
        }

        let RRD = {};

        if(regByType == 'region'){

            let currentStatuses = {};
            let currentPeriods = {};


            let countryData      = {};
            let regionNames = {
                'AS' : 'Asia',
                'AU' : 'Australia',
                'CA' : 'Canada',
                'EU' : 'Europe',
                'NZ' : 'New Zealand',
                'US' : 'United Sates Of America'
            };
            for (const [k, v] of Object.entries(regionNames)) {
                countryData[k] = {
                    name:v,
                    count:0,
                    statuses:{},
                    periods:{}
                };
            }


            for(let d of regByData){

                if(['AU','CA','NZ'].includes(d.iso)){
                    countryData[d.iso].count += d.count;
                    currentStatuses = countryData[d.iso].statuses;
                    currentPeriods  = countryData[d.iso].periods;
                }
                else if(['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','GB'].includes(d.iso)){
                    countryData['EU'].count += d.count;
                    currentStatuses = countryData['EU'].statuses;
                    currentPeriods  = countryData['EU'].periods;
                }
                else if(['AF','AM','AZ','BH',',BT','BN','KH','CN','CX','CC','IO','GE','HK','IN','ID','IR','IQ','IL','JP','JO','KZ','KW','KG','LA','LB','MO','MY','MV','MN','MM','NP','KP','OM','PK','PS','PH','QA','SA','SG','KR','LK','SY','TW','TJ','TH','TR','TM','AE','UZ','VN','YE'].includes(d.iso)){
                    countryData['AS'].count += d.count;
                    currentStatuses = countryData['AS'].statuses;
                    currentPeriods  = countryData['AS'].periods;
                }
                else{
                    countryData['US'].count += d.count;
                    currentStatuses = countryData['US'].statuses;
                    currentPeriods  = countryData['US'].periods;
                }

                for (const [s, c] of Object.entries(d.statuses)) {
                    if(typeof currentStatuses[s] == 'undefined' ){
                        currentStatuses[s] = 0;
                    }
                    currentStatuses[s] += c;
                }
                for (const [s, c] of Object.entries(d.periods)) {
                    if(typeof currentPeriods[s] == 'undefined' ){
                        currentPeriods[s] = 0;
                    }
                    currentPeriods[s] += c;
                }

            }

            RRD = Object.values(countryData);
        }else{
            RRD = regByData;
        }

        let splitRRD = [];
        for(let i of RRD){
            for (const [k, v] of Object.entries(i.periods)) {
                splitRRD.push({
                    "name": i.name,
                    "count": v,
                    "period": k
                });
            }
        }

        setRegionRegistrationData(RRD);
        setRegionRegistrationSplitData(splitRRD);

    }, [regByData,regByType]);


    useEffect(() => {

        if(!incomeData || !incomeData.length){
            return true;
        }

        let newData = [];

        if(incomeByType == 'region'){

            let asYA = 0;
            let asMA = 0;
            let auYA = 0;
            let auMA = 0;
            let caYA = 0;
            let caMA = 0;
            let euYA = 0;
            let euMA = 0;
            let nzYA = 0;
            let nzMA = 0;
            let usYA = 0;
            let usMA = 0;
            let asYAA = 0;
            let asMAA = 0;
            let auYAA = 0;
            let auMAA = 0;
            let caYAA = 0;
            let caMAA = 0;
            let euYAA = 0;
            let euMAA = 0;
            let nzYAA = 0;
            let nzMAA = 0;
            let usYAA = 0;
            let usMAA = 0;


            let asMC = 0;
            let asYC = 0;
            let auMC = 0;
            let auYC = 0;
            let caMC = 0;
            let caYC = 0;
            let euMC = 0;
            let euYC = 0;
            let nzMC = 0;
            let nzYC = 0;
            let usMC = 0;
            let usYC = 0;

            for(let d of incomeData){
                if(d.iso == 'AU'){
                    auMA += d.monthly_amount;
                    auYA += d.yearly_amount;
                    auMAA += d.monthly_amount_actual;
                    auYAA += d.yearly_amount_actual;
                    auMC += d.monthly_count;
                    auYC += d.yearly_count;
                }
                else if(d.iso == 'CA'){
                    caMA += d.monthly_amount;
                    caYA += d.yearly_amount;
                    caMAA += d.monthly_amount_actual;
                    caYAA += d.yearly_amount_actual;
                    caMC += d.monthly_count;
                    caYC += d.yearly_count;
                }
                else if(['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','GB'].includes(d.iso)){
                    euMA += d.monthly_amount;
                    euYA += d.yearly_amount;
                    euMAA += d.monthly_amount_actual;
                    euYAA += d.yearly_amount_actual;
                    euMC += d.monthly_count;
                    euYC += d.yearly_count;
                }
                else if(d.iso == 'NZ'){
                    nzMA += d.monthly_amount;
                    nzYA += d.yearly_amount;
                    nzMAA += d.monthly_amount_actual;
                    nzYAA += d.yearly_amount_actual;
                    nzMC += d.monthly_count;
                    nzYC += d.yearly_count;
                }
                else if(['AF','AM','AZ','BH',',BT','BN','KH','CN','CX','CC','IO','GE','HK','IN','ID','IR','IQ','IL','JP','JO','KZ','KW','KG','LA','LB','MO','MY','MV','MN','MM','NP','KP','OM','PK','PS','PH','QA','SA','SG','KR','LK','SY','TW','TJ','TH','TR','TM','AE','UZ','VN','YE'].includes(d.iso)){
                    asMA += d.monthly_amount;
                    asYA += d.yearly_amount;
                    asMAA += d.monthly_amount_actual;
                    asYAA += d.yearly_amount_actual;
                    asMC += d.monthly_count;
                    asYC += d.yearly_count;
                }
                else{
                    usMA += d.monthly_amount;
                    usYA += d.yearly_amount;
                    usMAA += d.monthly_amount_actual;
                    usYAA += d.yearly_amount_actual;
                    usMC += d.monthly_count;
                    usYC += d.yearly_count;
                }

            }

            newData = [
                {
                    name: 'Asia',
                    monthly_amount:asMA,
                    yearly_amount:asYA,
                    monthly_amount_actual:asMAA,
                    yearly_amount_actual:asYAA,
                    monthly_count:asMC,
                    yearly_count:asYC
                },
                {
                    name: 'Australia',
                    monthly_amount:auMA,
                    yearly_amount:auYA,
                    monthly_amount_actual:auMAA,
                    yearly_amount_actual:auYAA,
                    monthly_count:auMC,
                    yearly_count:auYC
                },
                {
                    name: 'Canada',
                    monthly_amount:caMA,
                    yearly_amount:caYA,
                    monthly_amount_actual:caMAA,
                    yearly_amount_actual:caYAA,
                    monthly_count:caMC,
                    yearly_count:caYC
                },
                {
                    name: 'Europe',
                    monthly_amount:euMA,
                    yearly_amount:euYA,
                    monthly_amount_actual:euMAA,
                    yearly_amount_actual:euYAA,
                    monthly_count:euMC,
                    yearly_count:euYC
                },
                {
                    name: 'New Zealand',
                    monthly_amount:nzMA,
                    yearly_amount:nzYA,
                    monthly_amount_actual:nzMAA,
                    yearly_amount_actual:nzYAA,
                    monthly_count:nzMC,
                    yearly_count:nzYC
                },
                {
                    name: 'United Sates Of America',
                    monthly_amount:usMA,
                    yearly_amount:usYA,
                    monthly_amount_actual:usMAA,
                    yearly_amount_actual:usYAA,
                    monthly_count:usMC,
                    yearly_count:usYC
                }
            ];


        }else{
            newData = incomeData;
        }

        let totalRow = {
            monthly_count: 0,
            yearly_count: 0
        };
        for (let r of newData) {
            totalRow.monthly_count += r.monthly_count;
            totalRow.yearly_count += r.yearly_count;
        }


        setCountryIncomeData(newData);

        setTotalRegistrations({monthly:totalRow.monthly_count,yearly:totalRow.yearly_count});

    }, [incomeData,incomeByType]);

    useEffect(() => {
        saveDashboard(dashboardLayout);
    }, [overdueMemberPaginate]);



    useEffect(() => {

        AP.get('/my/dashboard/chart/comparison?for=students',comparisonGraphOptions)
            .then(function(res){

                const ti = [];
                const ts = [];
                const es = [];
                for(let p of res){
                    const date = new Date(p.date*1000);

                    if(p.category == 'Total Income'){
                        ti.push({"x":date,y:p.value});
                    }
                    else if(p.category == 'Total Subscriptions'){
                        ts.push({"x":date,y:p.value});
                    }
                    else if(p.category == 'Ended Subscriptions'){
                        es.push({"x":date,y:p.value});
                    }
                }

                setComparisonGraphTI(ti);
                setGraphGirthDataNS(ts);
                setGraphGirthDataES(es);


                setComparisonGraphData(res);
            });

    }, [comparisonGraphOptions]);

    useEffect(() => {

        const graphOptions = {
            axisX:{
                valueFormatString: "DD MMM YYYY",
            },
            axisY: [],
            axisY2: [],
            backgroundColor: "transparent",
            data: []
        };


        if(comparisonGraphOptions.totalIncome) {

            graphOptions.axisY.push({
                title: "Total Income",
                lineColor: "#4f81bc",
                titleFontColor: "#4f81bc",
                labelFontColor: "#4f81bc"
            });

            graphOptions.data.push({
                type: "splineArea",
                showInLegend: true,
                axisYIndex: 0, //Defaults to Zero
                name: "Total Income",
                dataPoints: comparisonGraphTI,
                xValueFormatString: "DD MMM YYYY",
                lineColor: "#4f81bc",
                markerColor:"#4f81bc",
                fillOpacity: .9
            });

        }

        if(comparisonGraphOptions.newSubscriptions || comparisonGraphOptions.endedSubscriptions) {
            graphOptions.axisY2.push({
                title: "New\\Ended Subscriptions",
                lineColor: "black",
                titleFontColor: "black",
                labelFontColor: "black"
            });
        }

        if(comparisonGraphOptions.newSubscriptions) {



            graphOptions.data.push({
                type: "column",
                showInLegend: true,
                axisYIndex: 1, //Defaults to Zero
                axisYType: "secondary",
                name: "New Subscriptions",
                dataPoints: comparisonGraphNS,
                xValueFormatString: "DD MMM YYYY",
                lineColor: "green",
                markerColor:"green",
                color:"green",
                fillOpacity: .3,
                maximum: 10
            });

        }
        if(comparisonGraphOptions.endedSubscriptions) {

            graphOptions.data.push({
                type: "column",
                showInLegend: true,
                axisYIndex: 1, //Defaults to Zero
                axisYType: "secondary",
                name: "Ended Subscriptions",
                dataPoints: comparisonGraphES,
                xValueFormatString: "DD MMM YYYY",
                lineColor: "red",
                markerColor:"red",
                color:"red",
                fillOpacity:.3,
                maximum: 10
            });
        }

        console.log(["go",graphOptions]);

        setComparisonGraphOptions2(graphOptions);

    }, [comparisonGraphTI,comparisonGraphNS,comparisonGraphES]);

    useEffect(() => {

        setBreadCrumbs([<span><i className="fas fa-home"></i> Dashboard</span>]);

        AP.get('/dashboard?for=students')
            .then(function (res) {

                setData(res);
                if(res.invite_url) res.invite_url = window.location.origin+res.invite_url;

                setRegByData(res.regionSubscriptions);

                setIncomeData(res.countryIncome);

                setUserStats(res.userStats);

                let formattedYearlyDue = [];
                for(let i in res.yearlyDue){
                    formattedYearlyDue.push({
                        date:i,
                        count:res.yearlyDue[i]
                    });
                }

                setYearlyDueData(formattedYearlyDue);

                let ods = [];
                setOverdueSummaryData(res.overdueSummaryData);


                let formattedNewRegistrations = [];
                let formattedNewRegistrationsTable = {};
                for(const [k, nrM] of Object.entries(res.newRegistrations)){
                    for(const [date, count] of Object.entries(nrM)) {
                        formattedNewRegistrations.push({
                            category: k,
                            date: date,
                            count: count
                        });

                        if(formattedNewRegistrationsTable[date]){
                            formattedNewRegistrationsTable[date][k] = count;
                        }else{
                            formattedNewRegistrationsTable[date] = {};
                            formattedNewRegistrationsTable[date].month = date;
                            formattedNewRegistrationsTable[date][k] = count;
                        }

                    }
                }

                setNewRegistrationData(formattedNewRegistrations);
                setNewRegistrationTableData(Object.values(formattedNewRegistrationsTable));





                if(res.dashboard && res.dashboard.members){
                    const data = res.dashboard.members;

                    setOverdueMemberPaginate(data.overdueMemberPaginate);
                    setDashboardLayout(data.layout);
                }else{
                    setDashboardLayout(getDefaultLayout());
                }



                setIsLoading(false);
            });
    }, []);




    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    let overdueColumns = [];
    let overdueSummaryColumns = [];
    let statusColumns = [];
    let periodColumns = [];
    let yearlyDueColumns = [];
    let incomeColumns = [];
    let newRegistrationColumns = [];
    if(user.type=="admin") {
        overdueColumns = [
            {
                title: 'Name',
                key: 'users.first_name',
                dataIndex: 'users.first_name',
                sorter: (a, b) => {
                    return (!a.first_name) ? -1 : a.first_name.localeCompare(b.first_name)
                },
                align: 'left',
                render: (text, record) => (
                    <div>
                        <span><Avatar src={(record.image) ? record.image.endpoint : null}
                                      icon={<i className="far fa-user"></i>} size="large"/></span>
                        <span style={{paddingLeft: "20px"}}>{record.first_name+" "+record.last_name}</span>
                    </div>
                )
            },
            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                sorter: (a, b) => {
                    return (!a.email) ? -1 : a.email.localeCompare(b.email)
                },
                align: 'left'
            },
            {
                title: 'Plan',
                key: 'plan',
                dataIndex: 'plan',
                sorter: true,
                align: 'left',
                width: '150px'
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                sorter: true,
                align: 'left',
                width: '150px'
            },
            {
                title: 'Missing',
                key: 'missing',
                dataIndex: 'missing',
                width: '150px',
        //        sortOrder: 'descend',
                sorter: true,
                render: function(text, record){
                    return <div style={{textAlign:"left"}}>
                        <div style={{display:"flex"}}>
                            <div style={{width:76}}>Payments:</div><div>{record.missing}</div>
                        </div>
                    </div>
                }
            },
            {
                title: 'Amount',
                key: 'amount',
                dataIndex: 'amount',
                width: '150px',
                sorter: true,
                render: function(text, record){
                    return <div>${record.amount / 100}</div>
                }
            },
            {
                title: 'Payment Attempts',
                key: 'attempts',
                dataIndex: 'attempts',
                width: '160px',
                defaultSortOrder: 'descend',
                sorter: true
            },
            {
                title: 'Actions',
                key: 'actions',
                width: '100px',
                render: (text, record) => (
                    <div>
                        <Link to={"/user/"+record.id}>
                            <span><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></span>
                        </Link>
                    </div>
                ),
            },
        ];

        overdueSummaryColumns = [
            {
                title: 'Billing Period',
                key: 'billing_period',
                dataIndex: 'billing_period',
                sorter: (a, b) => {
                    return (!a.billing_period) ? -1 : a.billing_period.localeCompare(b.billing_period)
                },
                align: 'left',
                render: (text, record) => (
                    record.billing_period
                )
            },{
                title: 'Missing Payments',
                key: 'missing_payments',
                dataIndex: 'missing_payments',
                sorter: (a, b) => a.missing_payments - b.missing_payments,
                align: 'left',
                render: (text, record) => (
                    record.missing_payments
                )
            },{
                title: 'Count',
                key: 'count',
                dataIndex: 'count',
                sorter: (a, b) => a.count - b.count,
                align: 'left',
                render: (text, record) => (
                    record.count
                )
            },{
                title: 'Amount Overdue',
                key: 'total',
                dataIndex: 'total',
                sorter: (a, b) => a.total - b.total,
                align: 'left',
                render: (text, record) => (
                    '$'+(record.total/100)
                )
            },{
                title: 'Fees',
                key: 'missing_payments',
                dataIndex: 'missing_payments',
                sorter: (a, b) => a.missing_payments - b.missing_payments,
                align: 'left',
                render: function(text, record) {
                    if(!record.feeCount) return "";
                    return <div>{record.feeCount} x ${(record.feeTotal/record.feeCount)/100} = ${record.feeTotal/100}</div>
                }
            }
        ];

        statusColumns = [
            {
                title: 'Country',
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => {
                    return (!a.name) ? -1 : a.name.localeCompare(b.name)
                },
                align: 'left',
                render: (text, record) => (
                    <div>{record.name}</div>
                )
            },
            {
                title: 'Active',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.statuses.active}</div>
                )
            },
            {
                title: 'On Hold',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.statuses["on-hold"]}</div>
                )
            },
            {
                title: 'Pending Cancel',
                align: 'left',
                width: 130,
                render: (text, record) => (
                    <div>{record.statuses["pending-cancel"]}</div>
                )
            },
            {
                title: 'Canceled',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.statuses.cancelled}</div>
                )
            },
            {
                title: 'Pending Deregistered',
                align: 'left',
                width: 130,
                render: (text, record) => (
                    <div>{record.statuses["pending-deregister"]}</div>
                )
            },
            {
                title: 'Deregistered',
                align: 'left',
                width: 130,
                render: (text, record) => (
                    <div>{record.statuses["deregistered"]}</div>
                )
            },
            {
                title: 'Total',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.count}</div>
                ),
                className: "totalColumn"
            },
        ];

        periodColumns = [
            {
                title: 'Country',
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => {
                    return (!a.name) ? -1 : a.name.localeCompare(b.name)
                },
                align: 'left',
                render: (text, record) => (
                    <div>{record.name}</div>
                )
            },
            {
                title: 'Monthly',
                align: 'left',
                width: 150,
                render: (text, record) => (
                    <div>{record.periods.monthly}</div>
                )
            },
            {
                title: 'Yearly',
                align: 'left',
                width: 150,
                render: (text, record) => (
                    <div>{record.periods.yearly}</div>
                )
            },
            {
                title: 'Total',
                align: 'left',
                width: 100,
                render: (text, record) => (
                    <div>{record.count}</div>
                ),
                className: "totalColumn"
            },
        ];

        incomeColumns = [
            {
                title: 'Country',
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => {
                    return (!a.name) ? -1 : a.name.localeCompare(b.name)
                },
                align: 'left',
                render: (text, record) => (
                    <div>{record.name}</div>
                )
            },
            {
                title: 'Monthly',
                align: 'left',
                width:200,
                render: function(text, record){
                    return <>
                        <div style={{display:"flex", fontWeight:"bold",fontSize:18}}>
                            <div style={{width:70}}>Actual:</div>
                            <div>
                                {record.monthly_amount_actual.toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                })}
                            </div>
                        </div>
                        <div style={{display:"flex",fontSize:12}}>
                            <div style={{width:70}}>Estimated:</div>
                            <div>
                                {record.monthly_amount.toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                })}
                            </div>
                        </div>
                    </>
                }
            },
            {
                title: 'Yearly',
                align: 'left',
                width:200,
                render: function(text, record){
                    return <>
                        <div style={{display:"flex", fontWeight:"bold",fontSize:18}}>
                            <div style={{width:70}}>Actual:</div>
                            <div>
                                {record.yearly_amount_actual.toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                })}
                            </div>
                        </div>
                        <div style={{display:"flex",fontSize:12}}>
                            <div style={{width:70}}>Estimated:</div>
                            <div>
                                {record.yearly_amount.toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                })}
                            </div>
                        </div>
                    </>
                }
            }
        ];

        yearlyDueColumns = [
            {
                title: 'Date',
                key: 'date',
                dataIndex: 'date',
                sorter: (a, b) => {
                    return (!a.date) ? -1 : a.date.localeCompare(b.date)
                },
                align: 'left',
                render: (text, record) => (
                    <div>{record.date}</div>
                )
            },
            {
                title: 'Count',
                align: 'left',
                width: 200,
                render: (text, record) => (
                    <div>{record.count}</div>
                )
            }
        ];

        newRegistrationColumns = [
            {
                title: 'Billing Period',
                key: 'month',
                dataIndex: 'month',
                sorter: (a, b) => {
                    return (!a.month) ? -1 : a.month.localeCompare(b.month)
                },
                align: 'left',
                render: (text, record) => (
                    record.month
                )
            },
            {
                title: 'Monthly',
                key: 'monthly',
                dataIndex: 'monthly',
                width: 200
            },
            {
                title: 'Yearly',
                key: 'yearly',
                dataIndex: 'yearly',
                width: 200
            }
        ];
    }

    function getRegByConfig(){

        if(regBySplit == ''){
            return {
                data: regionRegistrationData,
                xField: 'count',
                yField: 'name',
                legend: {
                    position: 'top-left',
                },
                barBackground: {
                    style: {
                        fill: 'rgba(0,0,0,0.1)',
                    },
                },
                interactions: [
                    {
                        type: 'active-region',
                        enable: false,
                    },
                ],
                label: {
                    position: 'middle',
                    // 'left', 'middle', 'right'

                }
            }
        }
        else if(regBySplit == 'period'){
            return {
                data: regionRegistrationSplitData,
                isStack: true,
                xField: 'count',
                yField: 'name',
                seriesField: 'period',
                legend: {
                    position: 'top-left',
                },
                barBackground: {
                    style: {
                        fill: 'rgba(0,0,0,0.1)',
                    },
                },
                interactions: [
                    {
                        type: 'active-region',
                        enable: false,
                    },
                ],
                label: {
                    position: 'middle',
                    // 'left', 'middle', 'right'

                }
            }
        }
    }

    /* On Change */
    function regByChange(value){
        setRegByType(value);
    }
    function regBySplitChange(value){
        setRegBySpit(value);
    }
    function dashboardEditChange(e){
        setDashboardEdit(e.target.checked);
    }
    function dashboardVerticalCompactChange(e){
        setdashboardVerticalCompact(e.target.checked);
    }
    function overdueMemberPaginateChange(e){
        setOverdueMemberPaginate(e.target.checked);
    }
    function dashboardRemoveItem(i){
        setDashboardLayout(_.reject(dashboardLayout, { i: i }));
    }
    function dashboardAddItem(l){
        l.y = Infinity;
        l.x = 0;

        dashboardLayout.push(l);
        setDashboardLayout(dashboardLayout);

    }
    function incomeByChange(value){
        setIncomeByType(value);
    }
    function onLayoutChange(layout) {
        saveDashboard(layout);
        //setDashboardLayout(layout);
    }
    function onLayoutReset() {
        setDashboardLayout(getDefaultLayout());
    }
    function onIncomeMonthChange(value) {
        var date = new Date(value);
        setIncomeMonth(date.getFullYear()+'-'+(date.getMonth()+1));
    }

    function setComparisonGraphValue(name,value){
        comparisonGraphOptions[name] = value;
        setComparisonGraphOptions({...comparisonGraphOptions});


        if(name=="basePeriod"){

            if(value == "7days"){
                setComparisonGraphBaseTime([
                    moment().subtract(7, 'd'),
                    moment()
                ]);
            }
            else if(value == "1month"){
                setComparisonGraphBaseTime([
                    moment().subtract(1, 'M'),
                    moment()
                ]);
            }
            else if(value == "3months"){
                setComparisonGraphBaseTime([
                    moment().subtract(3, 'M'),
                    moment()
                ]);
            }
            else if(value == "1year"){
                setComparisonGraphBaseTime([
                    moment().subtract(1, 'Y'),
                    moment()
                ]);
            }
        }

        return true;
    }




    function getDefaultLayout(){

        return [
            {
                "x": 0,
                "y": 0,
                "w": 12,
                "h": 28,
                "i": "overdueMembersTable",
                "type": "overdueMembersTable"
            },
            {
                "x": 4,
                "y": 28,
                "w": 4,
                "h": 5,
                "i": "totalRegistrationsCount",
                "type": "totalRegistrationsCount"
            },
            {
                "x": 7,
                "y": 48,
                "w": 5,
                "h": 19,
                "i": "registrationsGraph",
                "type": "registrationsGraph"
            },
            {
                "x": 0,
                "y": 33,
                "w": 6,
                "h": 15,
                "i": "registrationsStateTable",
                "type": "registrationsStateTable"
            },
            {
                "x": 6,
                "y": 33,
                "w": 6,
                "h": 15,
                "i": "registrationsPeriodTable",
                "type": "registrationsPeriodTable"
            },
            {
                "x": 0,
                "y": 48,
                "w": 6,
                "h": 19,
                "i": "incomePeriodTable",
                "type": "incomePeriodTable"
            },
            {
                "x": 1,
                "y": 67,
                "w": 5,
                "h": 21,
                "i": "yearlyDueGraph",
                "type": "yearlyDueGraph"
            },
            {
                "x": 7,
                "y": 77,
                "w": 3,
                "h": 22,
                "i": "yearlyDueTable",
                "type": "yearlyDueTable"
            },
            {
                "x": 0,
                "y": 99,
                "w": 12,
                "h": 8,
                "i": "overdueSummaryTable",
                "type": "overdueSummaryTable"
            },{
                "x": 0,
                "y": 107,
                "w": 6,
                "h": 12,
                "i": "newRegistrationsGraph",
                "type": "newRegistrationsGraph"
            },{
                "x": 6,
                "y": 107,
                "w": 6,
                "h": 12,
                "i": "newRegistrationsTable",
                "type": "newRegistrationsTable"
            },{
                "x": 0,
                "y": 119,
                "w": 12,
                "h": 12,
                "i": "comparisonGraph",
                "type": "comparisonGraph"
            }


        ];
    }
    function GenerateWidget(props){
        let item = props.item;
        let i = props.index;

        if(item.i == 'overdueMembersTable'){

            return <div key={item.i}>
                <h4>Overdue Members</h4>
                {dashboardEdit &&
                    <div style={{textAlign:"right"}}>
                        <Checkbox checked={overdueMemberPaginate} onChange={overdueMemberPaginateChange}>Auto Paginate</Checkbox>
                    </div>
                }
                <div style={{paddingTop:30}}>
                    <SearchTable
                        columns={overdueColumns}
                        url="/users/overdue?for=students"
                        container={gridRef}
                        containerIndex={i}
                        rowHeight={overdueMemberPaginate?86:null}
                        pagination={overdueMemberPaginate?null:{ pageSize: 99999}}
                        className="noPadding"
                        formatData={function(data){console.log(["???? HERE",data]); return  data}}
                    />
                </div>
            </div>
        }
        else if(item.i == 'registrationsGraph'){
            return <div key={item.i}>
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>
                        Registrations by <Select className="massSelect" style={{width:100}} defaultValue="region" onChange={regByChange}>
                        <Option value="region">Region</Option>
                        <Option value="country">Country</Option>
                    </Select>
                    </h4>
                    <div>
                        Split By <Select className="massSelect" style={{width:100}} defaultValue="" onChange={regBySplitChange}>
                        <Option value="">None</Option>
                        <Option value="period">Period</Option>
                    </Select>
                    </div>
                </div>
                <Bar {...getRegByConfig()}/>
            </div>
        }
        else if(item.i == 'totalRegistrationsCount'){
            return <div key={item.i}>

                <div style={{display:"flex",gap:20,justifyContent:"center"}}>
                    {Object.entries(userStats).map(function(d){

                        let total = 0;
                        let statusHtml = Object.entries(d[1]).map(function(statuses){
                            total += statuses[1];
                            return <div>{statuses[0]}: {statuses[1]}</div>
                        });

                        return <Card title={d[0]+" Students"} bordered={false} style={{ width: 300 }} className="cardFlex">
                            <div style={{display:"flex",flexDirection:"column",height:"100%"}}>
                                <p style={{fontSize:"30px",textAlign:"center"}}>{total}</p>
                                <p style={{fontSize:"12px",flex:1, display:"flex", flexDirection:"column"}}>
                                    {statusHtml}
                                </p>
                                <div style={{borderBottom:"1px solid lightgrey", width:"80%",margin:"auto", position:"relative", top:"-6px"}}></div>
                                <p>
                                    Overdue: {data.userStatsOverdue[d[0]]}
                                </p>
                            </div>
                        </Card>
                    })}
                </div>
            </div>
        }
        else if(item.i == 'registrationsStateTable'){
            return <div key={item.i}>
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>Registrations by Status</h4>
                    <div>
                        <Select className="massSelect" style={{width:100}} defaultValue="region" onChange={regByChange}>
                            <Option value="region">Region</Option>
                            <Option value="country">Country</Option>
                        </Select>
                    </div>
                </div>

                <Table
                    dataSource={regionRegistrationData}
                    columns = {statusColumns}
                    rowKey="name"
                    rowClassName={(record, index) => ((record.name=="Total")?"totalRow":"")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    summary={pageData => {

                        let totalActive = 0;
                        let totalOnHold = 0;
                        let totalPendingCancel = 0;
                        let totalCanceled = 0;
                        let totalPendingDeregister = 0;
                        let totalDeregistered = 0;
                        let totalTotal = 0;

                        for(let p of pageData){
                            totalActive            += p.statuses.active||0;
                            totalOnHold            += p.statuses["on-hold"]||0;
                            totalPendingCancel     += p.statuses["pending-cancel"]||0;
                            totalCanceled          += p.statuses.cancelled||0;
                            totalPendingDeregister += p.statuses["pending-deregister"]||0;
                            totalDeregistered      += p.statuses["deregistered"]||0;
                            totalTotal             += p.count;
                        };

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{backgroundColor:"#d3d3d3"}}>
                                    <Table.Summary.Cell >
                                        <div style={{textAlign:"left"}}>Total</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalActive}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalOnHold}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalPendingCancel}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalCanceled}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalPendingDeregister}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalDeregistered}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalTotal}</div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                    scroll={{ y: 360 }}
                    className="scrollTable"
                />

            </div>
        }
        else if(item.i == 'registrationsPeriodTable'){
            return <div key={item.i}>
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>Registrations by Billing Period</h4>
                    <div>
                        <Select className="massSelect" style={{width:100}} defaultValue="region" onChange={regByChange}>
                            <Option value="region">Region</Option>
                            <Option value="country">Country</Option>
                        </Select>
                    </div>
                </div>

                <Table
                    dataSource={regionRegistrationData}
                    columns = {periodColumns}
                    rowKey="name"
                    rowClassName={(record, index) => ((record.name=="Total")?"totalRow":"")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    summary={pageData => {

                        let totalMonthly = 0;
                        let totalYearly = 0;
                        let totalTotal = 0;

                        for(let p of pageData){
                            totalMonthly += p.periods.monthly||0;
                            totalYearly  += p.periods.yearly||0;
                            totalTotal   += p.count;
                        };

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{backgroundColor:"#d3d3d3"}}>
                                    <Table.Summary.Cell >
                                        <div style={{textAlign:"left"}}>Total</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalMonthly}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalYearly}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalTotal}</div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                    scroll={{ y: 360 }}
                    className="scrollTable"
                />

            </div>
        }
        else if(item.i == 'incomePeriodTable'){
            return <div key={item.i}>
                <div style={{display:"flex",gap:10}}>
                    <h4 style={{flex:1}}>Income by Status</h4>
                    <div>
                        <DatePicker defaultValue={moment()} format={'YYYY-MM'}  onChange={onIncomeMonthChange} picker="month" />
                    </div>
                    <div>
                        <Select
                            className="massSelect"
                            style={{width: 100}}
                            defaultValue="region"
                            onChange={incomeByChange}
                        >
                            <Option value="region">Region</Option>
                            <Option value="country">Country</Option>
                        </Select>
                    </div>
                </div>

                <div style={{paddingTop:30}}>
                    <SearchTable
                        columns={incomeColumns}
                        url="/dashboard/income"
                        search={false}
                        filters={{'date':incomeMonth}}
                        //pagination={false}
                        formatData={function(incomeData){

                            let newData = [];

                            if(incomeByType == 'region'){

                                let asYA = 0;
                                let asMA = 0;
                                let auYA = 0;
                                let auMA = 0;
                                let caYA = 0;
                                let caMA = 0;
                                let euYA = 0;
                                let euMA = 0;
                                let nzYA = 0;
                                let nzMA = 0;
                                let usYA = 0;
                                let usMA = 0;
                                let asYAA = 0;
                                let asMAA = 0;
                                let auYAA = 0;
                                let auMAA = 0;
                                let caYAA = 0;
                                let caMAA = 0;
                                let euYAA = 0;
                                let euMAA = 0;
                                let nzYAA = 0;
                                let nzMAA = 0;
                                let usYAA = 0;
                                let usMAA = 0;


                                let asMC = 0;
                                let asYC = 0;
                                let auMC = 0;
                                let auYC = 0;
                                let caMC = 0;
                                let caYC = 0;
                                let euMC = 0;
                                let euYC = 0;
                                let nzMC = 0;
                                let nzYC = 0;
                                let usMC = 0;
                                let usYC = 0;

                                for(let d of incomeData){
                                    if(d.iso == 'AU'){
                                        auMA += d.monthly_amount;
                                        auYA += d.yearly_amount;
                                        auMAA += d.monthly_amount_actual;
                                        auYAA += d.yearly_amount_actual;
                                        auMC += d.monthly_count;
                                        auYC += d.yearly_count;
                                    }
                                    else if(d.iso == 'CA'){
                                        caMA += d.monthly_amount;
                                        caYA += d.yearly_amount;
                                        caMAA += d.monthly_amount_actual;
                                        caYAA += d.yearly_amount_actual;
                                        caMC += d.monthly_count;
                                        caYC += d.yearly_count;
                                    }
                                    else if(['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','GB'].includes(d.iso)){
                                        euMA += d.monthly_amount;
                                        euYA += d.yearly_amount;
                                        euMAA += d.monthly_amount_actual;
                                        euYAA += d.yearly_amount_actual;
                                        euMC += d.monthly_count;
                                        euYC += d.yearly_count;
                                    }
                                    else if(d.iso == 'NZ'){
                                        nzMA += d.monthly_amount;
                                        nzYA += d.yearly_amount;
                                        nzMAA += d.monthly_amount_actual;
                                        nzYAA += d.yearly_amount_actual;
                                        nzMC += d.monthly_count;
                                        nzYC += d.yearly_count;
                                    }
                                    else if(['AF','AM','AZ','BH',',BT','BN','KH','CN','CX','CC','IO','GE','HK','IN','ID','IR','IQ','IL','JP','JO','KZ','KW','KG','LA','LB','MO','MY','MV','MN','MM','NP','KP','OM','PK','PS','PH','QA','SA','SG','KR','LK','SY','TW','TJ','TH','TR','TM','AE','UZ','VN','YE'].includes(d.iso)){
                                        asMA += d.monthly_amount;
                                        asYA += d.yearly_amount;
                                        asMAA += d.monthly_amount_actual;
                                        asYAA += d.yearly_amount_actual;
                                        asMC += d.monthly_count;
                                        asYC += d.yearly_count;
                                    }
                                    else{
                                        usMA += d.monthly_amount;
                                        usYA += d.yearly_amount;
                                        usMAA += d.monthly_amount_actual;
                                        usYAA += d.yearly_amount_actual;
                                        usMC += d.monthly_count;
                                        usYC += d.yearly_count;
                                    }

                                }

                                newData = [
                                    {
                                        name: 'Asia',
                                        monthly_amount:asMA,
                                        yearly_amount:asYA,
                                        monthly_amount_actual:asMAA,
                                        yearly_amount_actual:asYAA,
                                        monthly_count:asMC,
                                        yearly_count:asYC
                                    },
                                    {
                                        name: 'Australia',
                                        monthly_amount:auMA,
                                        yearly_amount:auYA,
                                        monthly_amount_actual:auMAA,
                                        yearly_amount_actual:auYAA,
                                        monthly_count:auMC,
                                        yearly_count:auYC
                                    },
                                    {
                                        name: 'Canada',
                                        monthly_amount:caMA,
                                        yearly_amount:caYA,
                                        monthly_amount_actual:caMAA,
                                        yearly_amount_actual:caYAA,
                                        monthly_count:caMC,
                                        yearly_count:caYC
                                    },
                                    {
                                        name: 'Europe',
                                        monthly_amount:euMA,
                                        yearly_amount:euYA,
                                        monthly_amount_actual:euMAA,
                                        yearly_amount_actual:euYAA,
                                        monthly_count:euMC,
                                        yearly_count:euYC
                                    },
                                    {
                                        name: 'New Zealand',
                                        monthly_amount:nzMA,
                                        yearly_amount:nzYA,
                                        monthly_amount_actual:nzMAA,
                                        yearly_amount_actual:nzYAA,
                                        monthly_count:nzMC,
                                        yearly_count:nzYC
                                    },
                                    {
                                        name: 'United Sates Of America',
                                        monthly_amount:usMA,
                                        yearly_amount:usYA,
                                        monthly_amount_actual:usMAA,
                                        yearly_amount_actual:usYAA,
                                        monthly_count:usMC,
                                        yearly_count:usYC
                                    }
                                ];


                            }else{
                                newData = incomeData;
                            }


                            return newData;
                        }}
                        rowKey="name"
                        pagination={{ pageSize: 99999}}
                        container={gridRef}
                        containerIndex={i}
                        className="scrollTable"
                    />
                </div>

            </div>
        }
        else if(item.i == 'yearlyDueGraph'){
            return <div key={item.i}>
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>
                        Yearly Subscriptions Due
                    </h4>
                </div>
                <Column
                    data   = {yearlyDueData}
                    xField = 'date'
                    yField = 'count'
                    label  = {{
                        position: 'middle',
                        // 'top', 'bottom', 'middle',
                        style: {
                            fill: '#FFFFFF',
                            opacity: 0.6
                        }
                    }}
                    xAxis = {{
                        label: {
                            autoHide: true,
                            autoRotate: false,
                        },
                    }}
                />


            </div>
        }
        else if(item.i == 'yearlyDueTable'){
            return <div key={item.i}>
                <h4>Yearly Subscriptions Due</h4>
                <Table
                    dataSource={yearlyDueData}
                    columns = {yearlyDueColumns}
                    rowKey="date"
                    rowClassName={(record, index) => ((record.date=="Total")?"totalRow":"")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    summary={pageData => {
                        let totalCount = 0;

                        for(let p of pageData){
                            totalCount += p.count;
                        };

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{backgroundColor:"#d3d3d3"}}>
                                    <Table.Summary.Cell >
                                        <div style={{textAlign:"left"}}>Total</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>{totalCount}</div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                    rowClassName={function(record,index){
                        if(record.date.substr(0,'4')==="Jan-"){
                            return 'dividerRow';
                        }
                        return '';
                    }}
                />
            </div>
        }
        else if(item.i == 'overdueSummaryTable'){
            return <div key={item.i}>
                <h4>Overdue Summary</h4>

                <Table
                    dataSource={overdueSummaryData}
                    columns={overdueSummaryColumns}
                    rowKey="id"
                    rowClassName={(record, index) => ((record.date == "Total") ? "totalRow" : "")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    summary={pageData => {

                        let totalMissingM = 0;
                        let totalMissingMonthly = 0;
                        let totalMissingY = 0;
                        let totalMissingYearly = 0;
                        let totalCountMonthly = 0;
                        let totalCountYearly = 0;
                        let totalFeeCountMonthly = 0;
                        let totalFeeCountYearly = 0;
                        let totalFeeMonthly = 0;
                        let totalFeeYearly = 0;
                        let totalMonthly = 0;
                        let totalYearly = 0;
                        let totalTotal = 0;

                        for(let p of pageData){
                            if(p.billing_period=="monthly"){
                                totalMissingMonthly  += p.missing_payments;
                                totalCountMonthly    += p.count;
                                totalMissingM        += p.missing_payments*p.count;
                                totalFeeCountMonthly += p.feeCount;
                                totalFeeMonthly      += p.feeTotal;
                                totalMonthly         += p.total;
                            }else{
                                totalMissingYearly   += p.missing_payments;
                                totalCountYearly     += p.count;
                                totalMissingY        += p.missing_payments*p.count;
                                totalFeeCountYearly  += p.feeCount;
                                totalFeeYearly       += p.feeTotal;
                                totalYearly          += p.total;
                            }
                            totalTotal             += p.total;
                        };

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{backgroundColor:"#d3d3d3"}}>
                                    <Table.Summary.Cell >
                                        <div style={{textAlign:"left"}}>Total</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>Monthly: {totalMissingM} &nbsp;&nbsp;&nbsp;Yearly: {totalMissingY} &nbsp;&nbsp;&nbsp;<span style={{fontWeight:"bold"}}>Total</span>: {totalMissingM+totalMissingY}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>Monthly: {totalCountMonthly} &nbsp;&nbsp;&nbsp;Yearly: {totalCountYearly} &nbsp;&nbsp;&nbsp;<span style={{fontWeight:"bold"}}>Total</span>: {totalCountMonthly+totalCountYearly}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>Monthly: ${totalMonthly/100} &nbsp;&nbsp;&nbsp;Yearly: ${totalYearly/100} &nbsp;&nbsp;&nbsp;<span style={{fontWeight:"bold"}}>Total</span>: ${totalTotal/100}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell style={{textAlign:"left"}}>
                                        <div style={{textAlign:"left"}}>Monthly: {totalFeeCountMonthly} x ${totalFeeMonthly/totalFeeCountMonthly/100} = ${totalFeeMonthly/100} &nbsp;&nbsp;&nbsp;Yearly: {totalFeeCountYearly} x ${totalFeeYearly/totalFeeCountYearly/100} = ${totalFeeYearly/100} &nbsp;&nbsp;&nbsp;<span style={{fontWeight:"bold"}}>Total</span>: {totalFeeCountMonthly+totalFeeCountYearly} x ? = ${(totalFeeMonthly+totalFeeYearly)/100}</div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
            </div>
        }
        else if(item.i == 'newRegistrationsGraph'){
            return <div key={item.i}>
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>
                        New Subscriptions Graph
                    </h4>
                </div>
                <Line
                    data   = {newRegistrationData}
                    xField = 'date'
                    yField = 'count'
                    seriesField = 'category'
                    label  = {{
                        position: 'middle',
                        // 'top', 'bottom', 'middle',
                        style: {
                            fill: '#FFFFFF',
                            opacity: 0.6
                        }
                    }}
                    xAxis = {{
                        label: {
                            autoHide: true,
                            autoRotate: false,
                        },
                    }}
                />


            </div>
        }
        else if(item.i == 'newRegistrationsTable'){
            return <div key={item.i}>
                <div style={{display:"flex"}}>
                    <h4 style={{flex:1}}>New Subscriptions Table</h4>
                </div>


                <Table
                    dataSource={newRegistrationTableData}
                    columns={newRegistrationColumns}
                    rowKey="month"
                    rowClassName={(record, index) => ((record.name == "Total") ? "totalRow" : "")}
                    // loading={loading}
                    //  onChange={tableChange}
                    // tableLayout="fixed"
                    //   rowKey="id"
                    //  {...props}
                    pagination={false}
                    scroll={{y: 360}}
                    className="scrollTable"
                />


            </div>
        }
        else if(item.i == 'comparisonGraph'){
            return <div key={item.i}>
                <div style={{display:"flex",gap:40}}>
                    <h4>Comparison Charts</h4>

                    <div style={{display:"flex"}}>
                        <div style={{border:"1px solid #d9d9d9",borderRadius:10, display:"flex",backgroundColor:"white"}}>
                            <div style={{padding:9,borderRight:"1px solid #d9d9d9"}}>
                                <Select value={comparisonGraphOptions["basePeriod"]} style={{ width: 120,border:"none" }} onChange={function(value, dateStrings){setComparisonGraphValue("basePeriod",value);}} className="massSelect noBorder">
                                    <Option value="today">Today</Option>
                                    <Option value="7days">Last 7 Days</Option>
                                    <Option value="1month">Last month</Option>
                                    <Option value="3months">Last 3 months</Option>
                                    <Option value="1year">Last year</Option>
                                    <Option value="all">All Time</Option>
                                </Select>
                            </div>
                            <div style={{padding:9}}>
                                <RangePicker
                                    style={{border:"none"}}
                                    value={comparisonGraphBaseTime}
                                />

                            </div>
                        </div>
                        <div style={{padding:10,fontSize:14}}>compared to</div>
                        <div style={{border:"1px solid #d9d9d9",borderRadius:10, display:"flex",backgroundColor:"white"}}>
                            <div style={{padding:9,borderRight:"1px solid #d9d9d9"}}>
                                <Select value={comparisonGraphOptions["comparePeriod"]} style={{ width: 120,border:"none" }} onChange={function(value, dateStrings){setComparisonGraphValue("comparePeriod",value);}} className="massSelect noBorder">
                                    <Option value="">None</Option>
                                    <Option value="previous">Previous Period</Option>
                                    <Option value="custom">Custom</Option>
                                </Select>
                            </div>
                            <div style={{padding:9}}>
                                <DatePicker
                                    onChange={function onChange(date, dateString) {}}
                                    value={comparisonGraphCompareTime}
                                    style={{border:"none"}}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{marginLeft:"auto"}}>
                        <Checkbox
                            onChange={function dashboardEditChange(e){ setComparisonGraphValue("totalIncome",e.target.checked); }}
                            checked={comparisonGraphOptions['totalIncome']}
                        >Total Income</Checkbox>
                        <Checkbox
                            onChange={function dashboardEditChange(e){ setComparisonGraphValue("newSubscriptions",e.target.checked); }}
                            checked={comparisonGraphOptions['newSubscriptions']}
                        >New Subscription</Checkbox>
                        <Checkbox
                            onChange={function dashboardEditChange(e){ setComparisonGraphValue("endedSubscriptions",e.target.checked); }}
                            checked={comparisonGraphOptions['endedSubscriptions']}
                        >Ended Subscription</Checkbox>
                    </div>

                </div>

                <CanvasJSChart options={comparisonGraphOptions2} />

                {false &&
                <Line
                    data={comparisonGraphData}
                    xField='date'
                    yField='value'
                    seriesField='category'
                    label={{
                        position: 'middle',
                        // 'top', 'bottom', 'middle',
                        style: {
                            fill: '#FFFFFF',
                            opacity: 0.6
                        }
                    }}
                    xAxis={{
                        label: {
                            autoHide: true,
                            autoRotate: false,
                        },
                    }}
                />
                }

            </div>
        }
        else

        return '';
    }

    return (
        <div style={{paddingRight:20, width:"100%", position:"relative"}}>

            {user.type=="member" &&
            <div style={{paddingTop:"50px"}}>
                <div className="woocommerce-MyAccount-content">
                    <div className="woocommerce-notices-wrapper"></div>
                    <p>Hello <strong>{user.name}</strong></p>

                </div>
            </div>
            }

            <div style={{display:"flex",position:"absolute", right:0, top:-80}}>
                <Checkbox onChange={dashboardEditChange}>Edit Dashboard</Checkbox>
                {dashboardEdit &&
                <div style={{display:"flex",flex:1}}>
                    <Checkbox checked={dashboardVerticalCompact} onChange={dashboardVerticalCompactChange}>Vertical Compact</Checkbox>
                    <Button type="primary" size="small" shape="round" onClick={()=>setDashboardAddModal(true)}>Add Item</Button>
                    <Button type="danger" size="small" shape="round" onClick={onLayoutReset} style={{marginLeft:"auto"}}>Reset Layout</Button>
                </div>
                }
            </div>
            {!isLoading &&
            <ReactGridLayout
                layout={dashboardLayout}
                onLayoutChange={onLayoutChange}
                verticalCompact={dashboardVerticalCompact}
                isDraggable={dashboardEdit}
                isResizable={dashboardEdit}
                //innerRef={function(ref){console.log(["ref2",ref])}}
                ref={function(ref){if(!ref)return; setGridRef(ref);}}
                className="layout"
                items={5}
                rowHeight={30}
                cols={12}
            >
                {dashboardLayout.map(function(l,i) {

                    return (
                        <div key={l.i}>
                            {dashboardEdit &&
                            <div style={{
                                position: "absolute",
                                right: "2px",
                                top: 0,
                                zIndex: 1
                            }}>
                                <Button
                                    className="remove"
                                    type="danger" size="small" shape="round"
                                    onClick={() => dashboardRemoveItem(l.i)}
                                ><i className="fas fa-times"></i></Button>
                            </div>
                            }
                            <GenerateWidget item={l} index={i}/>

                        </div>
                    );
                })}

            </ReactGridLayout>
            }

            <Modal
                visible={dashboardAddModal}
                title="Add Dashboard Item"
                onCancel={()=>setDashboardAddModal(false)}
                //centered={true}
                footer={<Button type="default" size="small" shape="round" onClick={()=>setDashboardAddModal(false)}>Done</Button>}
            >
                {_.map(getDefaultLayout(), function(l) {

                    let onDashboard = false;
                    for(let i of dashboardLayout){
                        if(i.i == l.i){
                            onDashboard = true;
                            break;
                        }
                    }

                    let x = dashboardLayout;

                    return (
                        <div style={{display:"flex",paddingBottom:10}} key={l.i}>
                            <div style={{flex:1}}>{l.i}</div>
                            <div><Button type="primary" size="small" shape="round" disabled={onDashboard} onClick={()=>dashboardAddItem(l)}>Add</Button></div>
                        </div>
                    );
                })}
            </Modal>


            {user.type=="admin" &&
                <>
                </>
            }
        </div>
    );
}