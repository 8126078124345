import React, {useEffect, useState} from 'react';

import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import countryList from "react-select-country-list";
import { Link } from "react-router-dom";
import TermsAndConditionsText from "../pages/TermsAndConditionsText2";
import Autocomplete from "react-google-autocomplete";

import { useHistory } from "react-router-dom";
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
	Card,
    Modal,
	Spin,
    Alert
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import useToken from "../hooks/useToken";
import API2 from "../components/api2";
import {notification} from "antd/lib/index";
import moment from "moment/moment";
import useWindowDimensions from "../components/useWindowDimensions";

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const { Meta } = Card;

export default function Register(props) {

    const [form] = Form.useForm();
    const { user, setUser, geo } = useToken();
    const AP = API2();
    const { width } = useWindowDimensions();
    
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [plans, setPlans] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [modelOpen, setModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const history = useHistory();
    const [country, setCountry] = useState(false);
    const [place, setPlace] = useState(null);
    const [exchangeRates, setExchangeRates] = useState(null);

    let isIframe   = window.location.pathname.substr(0,8) == '/iframe/';

    useEffect(() => {

        if(history.location.state){
            form.setFieldsValue(history.location.state);
		}else{
            let defaultCountry = null;
            const cBits = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/");
            if(cBits[0]){
                for(const c of countryList().getData()){
                    if(c.label == cBits[0]){
                        defaultCountry = c.value;
                        setCountry(defaultCountry);
                        break;
                    }
                }
            }
            form.setFieldsValue({
                country:defaultCountry
            });
        }

        let url = '/plans?for=member';
        if(geo){
            url += '&currency='+geo.currency.code;
        }

        AP.get(url)
            .then(function (res) {

                for (let plan of res.data) {
                    const payments = [];
                    for (let payment of plan.payments) {
						payment.discount = 0;
						const billingRounds = payment.billing_rounds;
						payment.billing_rounds = 1;
                        for(let i = 0; i < billingRounds; i++){
							payments.push({...payment});
                        }
                    }
					plan.payments = payments;
                }

                setPlans(res.data);
                if(res.currencies){
                    setExchangeRates(res.currencies);
                }

                let p = props.match.params.plan;
                if(p) {
                    for (let plan of res.data) {
                        if (plan.name == p) setSelectedPlan(plan.id);
                    }
                }

				setTimeout(function(){
                    setIsLoading(false);
				},10);

            });
    }, []);

    const [discountPlan, setDiscountPlan] = useState('');
    const [couponMsg, setCouponMsg] = useState('');

    const options = {
        componentRestrictions: { country: country },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["address"]
    };


    const handleSubmit = async (data) => {
        
        data.plan_id  = selectedPlan;

        if(!place){
            form.setFields([
                {
                    name: 'address',
                    errors: ['An address needs to be selected from the dropdown'],
                },
            ]);
            return false;
        }

        data.place = place;

        setIsSaving(true);

        //check email \ password
        AP.post('/registerCheck',data)
            .then(function(res){

                setIsSaving(false);
                props.onSave(data);
                /*
				setUser(res.user, res.access_token);
				//window.location.replace("/dashboard");
                if(isIframe){
                    window.parent.location.href = "https://sportsnutritionassociation.com/sign-up-thank-you";
                }else{
                    window.location.replace("https://sportsnutritionassociation.com/sign-up-thank-you");
                }
                */

            })
            .catch(function(res){
                setIsSaving(false);
                if(res.response.data.errors) {
                    let errorList = {};
                    for (let error of res.response.data.errors) {
                        if (!errorList[error.input]) errorList[error.input] = [];
                        errorList[error.input].push(error.message);
                    }
                    for (let [input, errors] of Object.entries(errorList)) {
                        form.setFields([
                            {
                                name: input,
                                errors: errors,
                            },
                        ]);
                    }
                }
            });
    };

	function onFormChange(changedFields, allFields){
		let name  = changedFields[0].name[0];
		let value = changedFields[0].value;
	}

	function checkCoupon(){
		let coupon = form.getFieldValue("coupon");

        for(let p of plans){
            for(let pa of p.payments) {
                pa.discount = 0;
            }
        }

		if(!coupon){
			setPlans(plans);
            setCouponMsg("");
			return;
        }

        AP.get('/coupon/check/'+coupon)
            .then(function (res) {

            	if(res.total_records){
            		for(let plan of plans){
            			for(let pcm of res.data[0].plan_coupon_maps){
            				if(pcm.plan_id == plan.id){
            				    let usesCount = 0;
                                for(let payment of plan.payments){
									if(res.data[0].uses && res.data[0].uses <= usesCount) continue;

									let d;
									if(res.data[0].type == 'fixed'){
										d = res.data[0].discount;
									}
									else{
										d = payment.amount*(res.data[0].discount/100);
									}
									payment.discount = d;

									usesCount++;
                                }
							}
						}
					}

                    setCouponMsg(<span style={{color:"green"}}><i className="fas fa-check-circle"></i></span>);
				}else{
                    setCouponMsg(<span style={{color:"red"}}><i className="fas fa-times-circle"></i></span>);
				}

                setPlans(plans);
            });

	}

    return(
    	<>
			<Form

				form={form}
				name="register"
				onFinish={handleSubmit}
				initialValues={{
					residence: ['zhejiang', 'hangzhou', 'xihu'],
					prefix: '86',
				}}
				scrollToFirstError
				style={{ margin:"auto", padding:10}}
				onFieldsChange={onFormChange}
                className="style1"
			>


				<h3>User Details</h3>
                <div className="ant-row col2">
                    <div>
                        <Form.Item
                            name="first_name"
                            rules={[
                                {
                                    required:true,
                                    message:'Please input your first name!',
                                    whitespace:true
                                }
                            ]}
                        >
                            <Input placeholder="First Name"/>
                        </Form.Item>
					</div>
                    <div>
                        <Form.Item
                            name="last_name"
                            rules={[
                                {
                                    required:true,
                                    message:'Please input your last name!',
                                    whitespace:true
                                }
                            ]}
                        >
                            <Input placeholder="Last Name"/>
                        </Form.Item>
                    </div>
                </div>

                <div>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            }
                        ]}
                    >
                        <Input placeholder="Email Address" readOnly={!!isLoading2} onFocus={()=>setIsLoading2(false)}/>
                    </Form.Item>
                </div>

                <div>
                    <Form.Item
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your country!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            autoComplete="none"
                            placeholder="Country"
                            filterOption={function(input, option){
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            onChange={(e) => setCountry(e)}
                        >
                            {countryList().getData().map(item => (
                                <Select.Option key={item.value} value={item.value}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div>
                    {country &&
                    <Form.Item
                        name="address"
                        rules={[
                            {
                                required:true,
                                message:'Required!'
                            }
                        ]}
                    >
                        <Autocomplete
                            placeholder="Address"
                            apiKey={'AIzaSyB81mQ2qjrgeCBEb-FsKtzKusncSPlu_nQ'}
                            onPlaceSelected={(place, inputRef, autocomplete) => {
                                if(!place.address_components){
                                    setPlace(null);
                                    return false;
                                }
                                form.setFieldValue('address',inputRef.value);
                                setPlace(place);
                            }}
                            className="ant-input"
                            type="text"
                            options={options}
                            disabled={!country}
                        />
                    </Form.Item>
                    }
                    {!country &&
                    <Form.Item
                        label="Address"
                    >
                        <Input
                            disabled
                            placeholder="Please select country first"
                        />
                    </Form.Item>
                    }
                </div>

                <div className="ant-row demo-row">
                    <div style={{flex:1, minWidth:(width<700)?"100%":null}}>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password placeholder="Password" style={{padding:"17px 15px 18px"}} readOnly={!!isLoading2} onFocus={()=>setIsLoading2(false)}/>
                        </Form.Item>
                    </div>
                    <div style={{flex:1, minWidth:(width<700)?"100%":null}}>
                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Confirm Password" style={{padding:"17px 15px 18px"}} readOnly={!!isLoading2} onFocus={()=>setIsLoading2(false)}/>
                        </Form.Item>
                    </div>
                </div>

                <h3  style={{paddingTop:50}}>Nutritionist Details</h3>


                <div className="ant-row col2">
                    <div>
                        <Form.Item
                            name="company"
                        >
                            <Input placeholder="Company" />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            name="accreditation-level"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your accreditation level!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Accreditation Level"
                            >

                                <Select.Option key={"provisional"} value={"provisional"}>
                                    Provisional
                                </Select.Option>
                                <Select.Option key={"open"} value={"open"}>
                                    Open
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>

                <h3 style={{paddingTop:50}}>Subscription</h3>

                <div>Please choose between the following two yearly registration options:</div>
                <div className="ant-row demo-row">
                    <div style={{flex:1, minWidth:(width<700)?"100%":null}}>
                        <Form.Item
                            name="plan_id"
                            rules={[
                                {
                                    required:true,
                                    message:'Required!',
                                    whitespace:true,
                                    type: "number"
                                }
                            ]}
                        >
                            {isLoading && <Spin size="small"/>
                            ||
                            <Select
                                placeholder={<div style={{position:"relative"}}>Select A Plan</div>}
                                onChange={function(e){setSelectedPlan(e)}}
								className="autoHeight"
                            >
                                {plans.map(plan => {

                                    const mergedPayments = [];
                                    let lastPayment = false;
                                    for(const payment of plan.payments){
                                        if(!lastPayment || lastPayment.amount != payment.amount || lastPayment.discount != payment.discount){
											lastPayment = {...payment};
											mergedPayments.push(lastPayment);
                                        }else{
											lastPayment.billing_rounds++;
                                        }
                                    }
                                    const mergedPaymentsHtml = [];
                                    const currency = plan.payments[0].currency.toUpperCase();

                                    

                                    let formatter;
                                    if(exchangeRates){
                                        formatter = new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: geo.currency.code,
                                            currencyDisplay: 'narrowSymbol'
                                        }); 
                                    }

                                    for(const mergedPayment of mergedPayments){
                                        const amount = Math.round((mergedPayment.amount - mergedPayment.discount)) / 100;
										mergedPaymentsHtml.push(<div>
                                            <div style={{fontWeight:"bold"}}>
                                                <span>{mergedPayment.billing_rounds} {mergedPayment.billing_duration}ly payment{mergedPayment.billing_rounds>1?'s':''} of </span>
                                                <span>${amount} </span>
                                                <span style={{fontSize: "10px"}}>{plan.payments[0].currency.toUpperCase()} </span>
                                            </div>                                            
                                                
                                            {!!exchangeRates && <div className="ant-card-meta-description" style={{textAlign:"center"}}>
                                                (estimated {formatter.format(Math.round(amount*exchangeRates[plan.payments[0].currency])).replace(/\D00$/, '')} {geo.currency.code})
                                            </div>}
                                            
                                        </div>);
                                    }

                                    if(plan.payments[0].billing_duration == 'month'){
                                        return <Option key={"plan_option_" + plan.id} value={plan.id}>
                                            <div style={{padding:"5px 0", display: "flex"}}>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginRight: "10px",
                                                        display: "flex",
                                                        justifyItems: "center"
                                                    }}
                                                >
                                                    <div style={{textAlign:"center", display:"flex", alignItems:"center"}}>
														<i 
                                                            className={"fad fa-calendar-" + ((plan.payments[0].billing_duration === "month") ? "alt" : "day")} 
                                                            style={{fontSize: 30, width: 80, justifyContent:"center", alignItems:"center", display:"flex"}}
                                                        />
                                                    </div>
                                                    
													<div style={{flex:1, textAlign:"center"}}>
                                                        <div>{mergedPaymentsHtml}</div>
													</div>
                                                    
                                                </div>
                                            </div>
                                        </Option>;
                                    }
                                    else {
                                        return <Option key={"plan_option_" + plan.id} value={plan.id}>
                                            <div style={{padding:"5px 0", display: "flex"}}>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginRight: "10px",
                                                        display: "flex",
                                                        justifyItems: "center"
                                                    }}
                                                >
                                                    <i 
                                                        className={"fad fa-calendar-" + ((plan.payments[0].billing_duration === "month") ? "alt" : "day")}
                                                        style={{fontSize: 30, width: 80, justifyContent:"center", alignItems:"center", display:"flex"}}
                                                    />
													<div style={{flex:1, textAlign:"center"}}>
                                                        <div>{mergedPaymentsHtml}</div>
														<div className="ant-card-meta-description" style={{paddingTop:10}}>{plan.description}</div>
													</div>
                                                </div>
                                            </div>
                                        </Option>;
                                    }
                                })}

                            </Select>
                            }
                        </Form.Item>
                    </div>
                    <div style={{flex:1, minWidth:(width<700)?"100%":null}}>
                        <div style={{display:"flex"}}>
                            <div style={{position:"relative",flex:2}}>
                                <Form.Item
                                    name="coupon"
                                    labelCol = {{
                                        xs: {span: 24},
                                        sm: {span: 10}
                                    }}
								>
                                    <Input placeholder="Coupon" onKeyPress={function(event){
                                        if (event.key === 'Enter') {
                                            checkCoupon();
                                            event.preventDefault();
                                            return false;
                                        }
                                    }}/>
                                </Form.Item>
                                <span style={{position:"absolute", right:10, top:9, fontSize:30}}>{couponMsg}</span>
                            </div>
                            <Button 
                                size={"large"} 
                                onClick={() => checkCoupon()} 
                                style={{margin:9,borderRadius:5}}
                            >Apply</Button>
                        </div>
                    </div>
                </div>
                <Alert message="Please note the yearly registration subscription is set to auto-renew, any changes to this can be made through your account." type="warning" />

                {/*
                <h3 style={{paddingTop:50}}>Payment Details</h3>
                <Row gutter="20" style={{justifyContent:"center"}}>
                    <Col className="zoomed">
                        <Cards
                            cvc={cardCvc}
                            expiry={cardExpiry}
                            focused={cardFocus}
                            name={cardName}
                            number={cardNumber}
                        />
                    </Col>

                    <Col flex="1" style={{minWidth:400}}>
                        <div style={{paddingTop:"20px"}}>
                            <Form.Item
                                name="cardnumber"
                                rules={[
                                    {
                                        required:true,
                                        message:'Required!',
                                        whitespace:true
                                    }
                                ]}
                            >
                                <Input
                                    type="tel"
                                    placeholder="Number"
                                    onFocus={handleInputFocus}
                                />
                            </Form.Item>

                            <Form.Item
                                name="cardname"
                                rules={[
                                    {
                                        required:true,
                                        message:'Required!',
                                        whitespace:true
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="Name"
                                    onFocus={handleInputFocus}
                                />
                            </Form.Item>

                            <Row gutter="20">
                                <Col style={{width:"125px"}}>

                                    <Form.Item
                                name="cardexpiry"
                                rules={[
                                    {
                                        required:true,
                                        message:'Required!',
                                        whitespace:true
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="Expiry"
                                    onFocus={handleInputFocus}
                                />
                            </Form.Item>
                                </Col>
                                <Col flex={"1"}>

                                    <Form.Item
                                        name="cardcvc"
                                        rules={[
                                            {
                                                required:true,
                                                message:'Required!',
                                                whitespace:true
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder="CVC"
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputFocus}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


                <div className="ant-row demo-row">
					<Form.Item style={{flex:1}}
						name="agreement"
						valuePropName="checked"
						rules={[
							{
								validator: (_, value) =>
									value ? Promise.resolve() : Promise.reject('Accepting agreement is required'),
							},
						]}
					>
						<Checkbox style={{  zoom:1.5}}>
							<span>I have read and agree to the website </span>
							<a onClick={()=>setModalOpen(true)} style={{borderBottom:"1px solid #50A6F1", color:"black"}}>
								terms and conditions
							</a>
						</Checkbox>
					</Form.Item>



				</div>
                */}

                <div style={{display:"flex", justifyContent:"right",marginTop:40}}>
                    <Button
                        type="primary"
                        shape="round"
                        size={"large"}
                        loading={isSaving}
                        style={{padding:"20px 40px", height:"auto"}}
                        onClick={()=>(form.submit())}
                    >Next</Button>
                </div>
			</Form>

            <Modal
                visible={modelOpen}
                title={<h3>Terms and Conditions - Sports Nutrition Group</h3>}
                onCancel={()=>setModalOpen(false)}
				//centered={true}
                width={"80vw"}
                footer={<Button type="primary" size="small" shape="round" onClick={()=>setModalOpen(false)}>Close</Button>}
            >
				<div  style={{maxHeight:"70vh", overflow:"auto"}}>
                	<TermsAndConditionsText/>
				</div>
            </Modal>
		</>
    )
}

