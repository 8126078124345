import React, {useContext, useEffect, useRef, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin,
    Row,
    Col,
    Card
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";
import countryList from "react-select-country-list";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import LocationAutoComplete from "../components/LocationAutoComplete";
import Autocomplete from "react-google-autocomplete";
import useToken from '../hooks/useToken';
import useWindowDimensions from "../components/useWindowDimensions";



const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function UserForm(props) {

    const { user:authUser } = useToken();
    const { width } = useWindowDimensions();

    const setBreadCrumbs = useContext(BreadCrumbContext);
    const [form] = Form.useForm();
    const [user, setUser] = useState(props.user);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

    const [files, setFiles] = useState([]);
    const [userType, setUserType] = useState([]);
    const [country, setCountry] = useState(false);
    const [place, setPlace] = useState(null);

    const [paymentMethod, setPaymentMethod]   = useState(null);
    const [cardCvc,    setCardCvc]    = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardFocus,  setCardFocus]  = useState('');
    const [cardName,   setCardName]   = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardIssuer , setCardIssuer ] = useState('');
    const [cardPreview , setCardPreview ] = useState(false);

    const AP = API2();
    useEffect(() => {

        if(props.user.id){
            const data = props.user;
            if(data.type == "admin"){
                data.is_admin = "yes";
            }else{
                data.is_admin = "no";
            }

            data.address_auto = '';

            form.setFieldsValue(data);
            let u = props.user;

            setCountry(u.country);

            if(props.user.payment_method) {
                setPaymentMethod(props.user.payment_method);

                setCardPreview(true);
                setCardNumber('************'+props.user.payment_method.last4);
                setCardIssuer(props.user.payment_method.brand);

                setCardExpiry(props.user.payment_method.exp_month+'/'+props.user.payment_method.exp_year);
            }

            if (u.profile_image) {
                setFiles([{
                    uid: u.profile_image.id,
                    name: u.profile_image.name,
                    status: 'done',
                    url: u.profile_image.endpoint,
                    thumbUrl: u.profile_image.endpoint
                }]);
            }
        }else{
            form.setFieldsValue({is_admin:'no'});
        }


    }, [props.user]);

    function saveForm(data){

        setIsSaving(true);

        if(data.dob){
            data.dob = data.dob.unix();
        }

        //get profile image value
        data['profile_image_id'] = null;
        for(let f of files){
            if(f.attachment_id) data['profile_image_id'] = f.attachment_id;
            else data['profile_image_id'] = f.uid;
        }

        data.place = place;

        let url = '/user';
        if(props.user.id) {
            url += '/'+props.user.id;
        }


        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            if(!props.user.id) {
                history.push("/user/" + res.id);
            }
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });;
    }

    const handleInputFocus = (e) => {
        if(e.type == "focus") {
            let id = e.target.id.substr(12);
            setCardFocus(id);
        }else{
            setCardFocus(null);
        }
    }

    function onFormChange(changedFields, allFields){

        let name  = changedFields[0].name[0];
        let value = changedFields[0].value;

        if(name=='cardcvc')    setCardCvc(value);
        if(name=='cardexpiry') setCardExpiry(value);
        if(name=='cardname')   setCardName(value);
        if(name=='cardnumber') setCardNumber(value);
    }
    function clearCard(){
        setCardPreview(false);
        setCardExpiry('');
        setCardNumber('');
    }

    const countryRef = useRef();
    const options = {
        componentRestrictions: { country: country },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["address"]
    };
    /*
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            countryRef.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            //countryRef.current.value = "YAY";
            console.log({ place });
        });
    }, []);
*/
    return(
        <Form
            {...formItemLayout}
            form={form}
            name="profile"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto",paddingBottom:70}}
            onFieldsChange={onFormChange}
        >
            {authUser.hasPermissions('user_permissions') &&
            <Form.Item
                name="is_admin"
                label="Is Admin"
                rules={[
                    {
                        required: true,
                        message: 'Please select if admin',
                    }
                ]}
            >
                <Select
                    placeholder="Select a option and change input text above"
                    onChange={(value) => {
                        setUserType(value)
                    }}
                >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                </Select>
            </Form.Item>
            }


            <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap:0}}>
                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-6">
                    <label htmlFor="profile_first_name" className="ant-form-item-required" title="First Name">First Name</label>
                </div>
                <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <Form.Item
                            name="first_name"
                            rules={[
                                {
                                    required:true,
                                    message:'Please input your first name!',
                                    whitespace:true
                                }
                            ]}
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                            }}
                            style={{
                                flex:"1 1 40%",

                            }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Last Name"
                            rules={[
                                {
                                    required:true,
                                    message:'Please input your last name!',
                                    whitespace:true
                                }
                            ]}
                            labelCol = {{
                                xs: { span: 24 },
                                sm: { span: 8 },
                            }}
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 16 },
                            }}
                            style={{
                                flex:"1 1 60%",
                                minWidth:(width<575)?'100%':''
                            }}
                        >
                            <Input/>
                        </Form.Item>
                    </div>
                </div>
            </div>

            <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap:0}}>
                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-6">
                    <label htmlFor="profile_email" className="ant-form-item-required" title="Last Name">E-mail</label>
                </div>
                <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                }
                            ]}
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                            }}
                            style={{flex:"1 1 40%"}}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                            labelCol = {{
                                xs: { span: 24 },
                                sm: { span: 8 },
                            }}
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 16 },
                            }}
                            style={{
                                flex:"1 1 60%",
                                minWidth:(width<575)?'100%':''
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </div>
            </div>

            <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap:0}}>
                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-6">
                    <label htmlFor="profile_gender" className="ant-form-item-required" title="Gender">Gender</label>
                </div>
                <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <Form.Item
                            name="gender"
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                            }}
                            style={{flex:"1 1 40%"}}
                        >
                            <Select>
                                <Select.Option key="any" value=""></Select.Option>
                                <Select.Option key={"male"} value={"male"}>
                                    Male
                                </Select.Option>
                                <Select.Option key={"female"} value={"female"}>
                                    Female
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="dob"
                            label="Date Of Birth"
                            help="DD/MM/YYYY"
                            labelCol = {{
                                xs: { span: 24 },
                                sm: { span: 8 },
                            }}
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 16 },
                            }}
                            style={{
                                flex:"1 1 60%",
                                minWidth:(width<575)?'100%':''
                            }}
                        >
                            <DatePicker format="DD/MM/YYYY"/>
                        </Form.Item>
                    </div>
                </div>
            </div>



            <Form.Item
                name="profile_image_id"
                label="Profile Image"
            >
                <Upload files={files} maxCount={1} setFiles={setFiles}/>
            </Form.Item>

            <Form.Item
                name="password"
                label="Password"
            >
                <Input.Password placeholder={(props.user.id)?"Leave empty to not change":""} style={{padding:"16px 15px 16px"}}/>
            </Form.Item>


            <h4 style={{paddingTop:50,textAlign:"center"}}>Location Details</h4>

            <Form.Item
                name="country"
                label="Country"
                rules={[
                    {
                        required: true
                    },
                ]}
            >
                <Select
                    showSearch
                    autoComplete="none"
                    filterOption={function(input, option){
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    onChange={(e) => setCountry(e)}
                >
                    {countryList().getData().map(item => (
                        <Select.Option key={item.value} value={item.value}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {country &&
                <Form.Item
                    name="address"
                    label="Address"
                >
                    <Autocomplete
                        apiKey={'AIzaSyB81mQ2qjrgeCBEb-FsKtzKusncSPlu_nQ'}
                        onPlaceSelected={(place, inputRef, autocomplete) => {
                            debugger;
                            form.setFieldValue('address',inputRef.value);
                            setPlace(place);
                        }}
                        className="ant-input"
                        type="text"
                        ref={countryRef}
                        options={options}
                        disabled={!country}
                        defaultValue={props.user.address}
                    />
                </Form.Item>
            }
            {!country &&
            <Form.Item
                label="Address"
            >
                <Input />
            </Form.Item>
            }





            <h4 style={{paddingTop:50,textAlign:"center"}}>Credit Card</h4>
            <Form.Item
                label=" "
            >

                <Row gutter="20">
                    <Col className="zoomed">
                        <Cards
                            cvc={cardCvc}
                            expiry={cardExpiry}
                            focused={cardFocus}
                            name={cardName}
                            number={cardNumber}
                            issuer={cardPreview?cardIssuer:'...'}
                            preview={cardPreview}
                        />
                    </Col>

                    {cardPreview &&
                    <Col flex="1">
                        <Button type="primary"  shape="round" size={"large"} onClick={() => clearCard()} style={{margin:9}}>Change</Button>
                    </Col>
                    }

                    {!cardPreview &&
                    <Col flex="1">
                        <div style={{paddingTop: "20px"}}>
                            <Form.Item
                                name="cardnumber"
                                rules={[{required: false}]}
                            >
                                <Input
                                    type="tel"
                                    placeholder="Number"
                                    onFocus={handleInputFocus}
                                />
                            </Form.Item>

                            <Form.Item
                                name="cardname"
                                rules={[{required: false}]}
                            >
                                <Input
                                    placeholder="Name"
                                    onFocus={handleInputFocus}
                                />
                            </Form.Item>

                            <Row gutter="20">
                                <Col style={{width: "125px"}}>

                                    <Form.Item
                                        name="cardexpiry"
                                        rules={[{required: false}]}
                                    >
                                        <Input
                                            placeholder="Expiry"
                                            onFocus={handleInputFocus}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col flex={"1"}>

                                    <Form.Item
                                        name="cardcvc"
                                        rules={[{required: false}]}
                                    >
                                        <Input
                                            placeholder="CVC"
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputFocus}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    }
                </Row>

            </Form.Item>

            <div>
                <Button type="primary" htmlType="submit" style={{float:"right"}} loading={isSaving} style={{float:"right"}}>{(isSaving)?'Saving':'Save'}</Button>
            </div>
        </Form>
	);
}