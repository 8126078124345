import React, {useEffect, useState} from 'react';
import countryList from "react-select-country-list";
import TermsAndConditionsText from "../pages/TermsAndConditionsText";

import { useHistory } from "react-router-dom";
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
	Card,
    Modal,
	Spin
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import useToken from "../hooks/useToken";
import API2 from "../components/api2";
import {notification} from "antd/lib/index";
import moment from "moment/moment";
import ReCAPTCHA from "react-google-recaptcha";
import Autocomplete from "react-google-autocomplete";
import RegistryCard from "../pages/Registry/RegistryCard";

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};
export default function RequestNutritionistForm(props) {

    const [form] = Form.useForm();
    const AP = API2();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [nutritionistRequestId, setNutritionistRequestId] = useState(null);
    const [captureValue, setCaptureValue] = useState(false);
    const [serverError, setServerError] = useState("");
    const history = useHistory();
    const [country, setCountry] = useState(false);
    const [place, setPlace] = useState(null);

    const locationOptions = {
        componentRestrictions: { country: country },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["locality"]
    };

    useEffect(() => {

        let defaultCountry = null;
        const cBits = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/");
        if(cBits[0]){
            for(const c of countryList().getData()){
                if(c.label == cBits[0]){
                    defaultCountry = c.value;
                    setCountry(defaultCountry);
                    break;
                }
            }
        }


        form.setFieldsValue({
			range:0,
			accreditation_level:"",
			training_location:"",
            nutritionist_gender:"",
            country:defaultCountry
        });
    }, []);

    useEffect(() => {
        setTimeout(function(){
            setIsLoading(false);
        },10);
    }, []);

    function captureOnChange(value) {
        setCaptureValue(value);
    }


    const handleSubmit = async (data) => {
        if(!captureValue){
            return false;
        }
        setIsSaving(true);

        data.capture = captureValue;
        data.place = place;

        AP.post('/nutritionists',data)
            .then(function(res){

                notification.success({
                    message: `Request Sent`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });

                setIsSaving(false);
                setNutritionistRequestId(res.id);
                setIsSaved(res.nutritionists);
                if(!!res.nutritionists.length){
                    props.setRequestHasUsers(true);
                }
                

            })
            .catch(function(res){
                setIsSaving(false);
                if(res.response.data.errors) {
                    let errorList = {};
                    for (let error of res.response.data.errors) {
                        if (!errorList[error.input]) errorList[error.input] = [];
                        errorList[error.input].push(error.message);
                    }
                    for (let [input, errors] of Object.entries(errorList)) {
                        form.setFields([
                            {
                                name: input,
                                errors: errors,
                            },
                        ]);
                    }
                }else if(res.response.data.message){
                    setServerError(res.response.data.message);
                }
            });
    };


    const rootContainer = document.getElementById('root');

    function emailNutritionist(user){
        
        //setIsSaving(true);

        const data = {
            nutritionistRequestId: nutritionistRequestId,
            capture: captureValue
        };

        let url = '/nutritionist/'+user.id+'/emailRequestNutritionist';

        AP.post(url,data).then(function(res){

            notification.success({
                message: `Email Sent`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            //props.form.resetFields();
            //props.onFinish();

        }).catch(function(res){
            //setIsSaving(false);
        });
    }

    return(

    	<>
            {isSaved && <>
                {isSaved.length>0 &&
                    <div>
                        <div style={{paddingBottom:40}}>
                            <h1 style={{textAlign:"center"}}>
                                <i className="fas fa-check" style={{color:"#01a5f8"}}/> Nutritionists found
                            </h1>
                            <div>
                                {isSaved.length > 1 && <>
                                    <span>Below are {isSaved.length} nutritionists that may be able to support you. Please click the email button on any you would like to talk to.</span>
                                </>}
                            </div>
                        </div>
                        <div className="nutritionists-list">
							<div className="row" style={{display:"flex", flexWrap:"wrap", gap:20}}>
                                {isSaved && isSaved.length && isSaved.map(function(nutritionist){
                                    return <RegistryCard 
                                        nutritionist={nutritionist} 
                                        setEmailModelUser={emailNutritionist} 
                                        style={{backgroundColor:"white"}}
                                        emailAsButton={true}
                                    />;
                                })}
                            </div>
                        </div>
                    </div>
                }
				{isSaved.length === 0 && <>
                    <div>
                        <div style={{paddingBottom:40}}>
                            <h1 style={{textAlign:"center"}}>
                                <i className="fas fa-times" style={{color:"red"}}/> Requests Not Sent
                            </h1>
                            <div>
                                Unfortunately no nutritionists matched your search criteria. <Button type="primary" size="small" shape="round" onClick={()=>setIsSaved(null)}>Retry</Button>
                            </div>
                        </div>
                    </div>
                </>}
            </>

            ||
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={handleSubmit}
                initialValues={{
                    residence: ['zhejiang', 'hangzhou', 'xihu'],
                    prefix: '86',
                }}
                scrollToFirstError
            >


                <h3>Personal Details</h3>
                <Form.Item
                    name="first_name"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                            whitespace: true
                        }
                    ]}
                    className="required"
                >
                    <Input placeholder="First Name"/>
                </Form.Item>

                <Form.Item
                    name="last_name"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                            whitespace: true
                        }
                    ]}
                    className="required"
                >
                    <Input placeholder="Last Name"/>
                </Form.Item>


                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Required',
                        }
                    ]}
                    className="required"
                >
                    <Input placeholder="E-mail" readOnly={isLoading ? "true" : ""}/>
                </Form.Item>

                <Form.Item
                    name="mobile"
                >
                    <Input placeholder="Mobile"/>
                </Form.Item>


                <h3>Location</h3>

                <Form.Item
                    name="country"
                    rules={[
                        {
                            required: true,
                            message: 'Please select your country!',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        autoComplete="none"
                        filterOption={function(input, option){
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        placeholder="Country"
                        onChange={(e) => setCountry(e)}
                    >
                        {countryList().getData()
                            .filter(item => {
                                //todo: replace this with a query?
                                return ["AU","AT","NZ","US","IT","ZW","IE","CH","CA","SG","TW","MY","AE","KW"].includes(item.value);
                            })
                            .map(item => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>


                {country &&
                <Form.Item
                    name="suburb"
                    rules={[
                        {
                            required:true,
                            message:'Required!'
                        }
                    ]}
                >
                    <Autocomplete
                        apiKey={'AIzaSyB81mQ2qjrgeCBEb-FsKtzKusncSPlu_nQ'}
                        onPlaceSelected={(place, inputRef, autocomplete) => {
                            form.setFieldValue('suburb',inputRef.value);
                            setPlace(place);
                        }}
                        placeholder="Suburb"
                        className="ant-input"
                        type="text"
                        options={locationOptions}
                        disabled={!country}
                    />
                </Form.Item>
                }
                {!country &&
                <Form.Item>
                    <Input
                        disabled
                        placeholder="Please select country first"
                    />
                </Form.Item>
                }


                <Form.Item
                    name="range"
                >
                    <Select
                        autoComplete="none"
                        placeholder="Range from suburb"
                    >
                        <Select.Option key="0" value={0}>
                            Exact
                        </Select.Option>
                        <Select.Option key="10" value={10}>
                            10 km
                        </Select.Option>
                        <Select.Option key="20" value={20}>
                            20 km
                        </Select.Option>
                        <Select.Option key="50" value={50}>
                            50 km
                        </Select.Option>
                        <Select.Option key="100" value={100}>
                            100 km
                        </Select.Option>
                    </Select>
                </Form.Item>


                <h3>Requirements</h3>
                <Form.Item
                    name="accreditation_level"
                    label="Accreditation Level"
                >
                    <Select
                        getPopupContainer={() => rootContainer}
                    >
                        <Select.Option key="any" value="">
                            Any
                        </Select.Option>
                        <Select.Option key={"provisional"} value={"provisional"}>
                            Provisional
                        </Select.Option>
                        <Select.Option key={"open"} value={"open"}>
                            Open
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="training_location"
                    label="Training Location"
                >
                    <Select
                        getPopupContainer={() => rootContainer}
                    >
                        <Select.Option key="any" value="">
                            Any
                        </Select.Option>
                        <Select.Option key={"inperson"} value={"inperson"}>
                            In Person
                        </Select.Option>
                        <Select.Option key={"online"} value={"online"}>
                            Online
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="nutritionist_gender"
                    label="Nutritionist Gender"
                >
                    <Select
                        getPopupContainer={() => rootContainer}
                    >
                        <Select.Option key="any" value="">
                            Any
                        </Select.Option>
                        <Select.Option key={"male"} value={"male"}>
                            Male
                        </Select.Option>
                        <Select.Option key={"female"} value={"female"}>
                            Female
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="additional_info"
                >
                    <TextArea placeholder="Additional Information" rows={4}/>
                </Form.Item>


                <div style={{display:"flex", justifyContent:"center", paddingBottom:20}}>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC}
                        onChange={captureOnChange}
                    />
                </div>

                {serverError &&
                <div style={{color:"red"}}>{serverError}</div>
                }

                <div>
                    <div>
                        <Button type="primary" htmlType="submit" shape="round" size={"large"} loading={isSaving}
                                style={{float: "right"}}>{(isSaving) ? 'Sending' : 'Request Introduction'}</Button>
                    </div>
                </div>
            </Form>
            }
		</>
    )
}

