import React, { useState, useRef, useEffect  } from 'react';
import useToken from '../../hooks/useToken';
import API2 from "../../components/api2";

import RegisterForm from '../../forms/register';
import MyCheckoutForm from '../../forms/MyCheckoutForm';
import useWindowDimensions from "../../components/useWindowDimensions";

import {
    Steps 
} from 'antd';


export default function CreateAccount(props) {

    const AP = API2();
	const { setUser } = useToken();
    const { width } = useWindowDimensions();
    const myRef = useRef(null);
    const heightRef = useRef(null);
    const isIframe = window.location.pathname.substring(0, 8) === '/iframe/';

    const [isSaving, setIsSaving] = useState(false);
    const [currentStep, setCurrentStep] = useState('info');
    const [memberData, setMemberData] = useState(null);

    useEffect(() => {
        if(currentStep=='payment'){
            window.parent.postMessage(
                {
                    type: 'scrollTop'
                },
                '*'
            );
        }
    }, [currentStep]);

    useEffect(() => {
        updateHeight();
    }, []);

    function saveMemberData(data){
		setMemberData(data);
        setCurrentStep('payment');

        window.scrollTo({
            top: myRef.current.offsetTop,
            behavior: 'smooth'
        });
	}
    function paymentSuccess(pi){

        AP.post('/register',{
            memberData: memberData,
            intent:pi
        })
            .then(function(res){

                setIsSaving(false);
                setCurrentStep('success');
                
				setUser(res.user, res.access_token);
				//window.location.replace("/dashboard");
                if(isIframe){
                    window.parent.location.href = "https://sportsnutritionassociation.com/sign-up-thank-you";
                }else{
                    window.location.replace("https://sportsnutritionassociation.com/sign-up-thank-you");
                }
            })
            .catch(function(res){
                setIsSaving(false);
            });
	}

    function updateHeight(){
        if(isIframe){
            setTimeout(()=>{
                window.parent.postMessage(
                    {
                        type: 'adjustIframeHeight',
                        height: heightRef.current.offsetHeight
                    },
                    '*'
                );
                updateHeight();

                /*
                    <script>
                        window.addEventListener('message', function(event) {
                        if (event.data.type === 'adjustIframeHeight') {
                            var iframe = document.querySelector('iframe');
                            if (iframe) {
                            iframe.style.height = (event.data.height+100) + 'px';
                            }
                        }
                        else if (event.data.type === 'scrollTop') {
                            var iframe = document.querySelector('iframe');
                            const rect = iframe.getBoundingClientRect();
                            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                            
                            window.scrollTo({
                                top: rect.top + scrollTop,
                                behavior: 'smooth'
                            });
                        }
                        });
                    </script>
                */
                
            },1000);
        }
    }
    


	return(

		<div className={isIframe?"content_inner":"content_inner_padded"} style={{backgroundColor:"white",height:"100%"}}>

            {!isIframe &&
                <div className="ibn ibn-sb sec ibn-large">
                    <div className="ebn ud-bn ud-pt obg uix-alter ebn-p9x sec">
                        <div className="cpnw cov xg">
                            <div className="main">
                                <div className="cpn al-g cl-g bg-g wd-g">
                                    <div className="title">
                                        <h1>Become A Member
                                            <div className="breadcrumb">
                                                <span className="separator"> &gt; </span>
                                                <span className="current">ibn-large</span>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg hs-bg is-basic" data-height="auto"
                            style={{"backgroundImage": "url('/images/becomeMember.png')"}}>
                            <div className="over tpb op01" style={{"opacity": "0.01"}}></div>
                        </div>
                    </div>
                </div>
            }

            <div ref={myRef} className="content_narrow" style={{padding:"50px 10px 0 10px",height:"100%", width:840, maxWidth:"100%"}}>
                <div ref={heightRef} >
                    <Steps
                        items={[
                            {
                                title: 'Membership Details',
                                status: (currentStep=='info')?'process':'finish',
                                icon: <i className="fad fa-clipboard-list-check"/>,
                            },
                            {
                                title: 'Payment Details',
                                status: (currentStep=='info')?'wait':(currentStep=='payment')?'process':'finish',
                                icon: <i className="fad fa-money-check-edit-alt"/>,
                            },
                            {
                                title: 'Success',
                                status: (['info','payment'].includes(currentStep))?'wait':'process',
                                icon: <i className="fas fa-check"/>,
                            }
                        ]}
                        style={{
                            textAlign:(width<700)?"center":"left"
                        }}
                    />
                    {currentStep=='info' && <RegisterForm {...props} onSave={saveMemberData}/>}
                    {currentStep=='payment' && <>
                        <div
                            style={{
                                maxWidth:500,
                                margin: "50px auto 0 auto"
                            }}
                        >
                            <MyCheckoutForm 
                                memberData={memberData}
                                onSuccess={paymentSuccess}
                                for="member"
                            />
                        </div>
                    </>}
                    {currentStep=='success' && <div>success stuff here</div>}
                </div>
			</div>

		</div>
	)
}