import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Select,
    Checkbox,
    DatePicker,
} from 'antd';
import moment from "moment/moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function ComparisonGraph(props) {


    const AP = API2();

    var CanvasJSReact = require('../assets/canvasjs.react');
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.default.CanvasJSChart;

	const [comparisonGraphData, setComparisonGraphData] = useState({'ti':[], 'tiC':[], 'tmi':[], 'tmiC':[], 'tai':[], 'taiC':[], 'eti':[], 'etmi':[], 'etai':[]});

    const [comparisonGraphNS, setGraphGirthDataNS] = useState([]);
    const [comparisonGraphES, setGraphGirthDataES] = useState([]);
    const [canvasOptions, setCanvasOptions] = useState({
		totalIncome:false,
		monthlyIncome:false,
		annualIncome:false,
        expectedTotalIncome:false,
		expectedTotalMonthlyIncome:false,
		expectedTotalAnnualIncome:false,
        newSubscriptions:true,
        endedSubscriptions:true,
        basePeriod:"1month",
        comparePeriod:""
    });

    const [comparisonGraphOptions, setComparisonGraphOptions] = useState({
        totalIncome:true,
		monthlyIncome:false,
		annualIncome:false,
        expectedTotalIncome:true,
		expectedTotalMonthlyIncome:false,
		expectedTotalAnnualIncome:false,
        newSubscriptions:true,
        endedSubscriptions:true,
        basePeriod:"1month",
        comparePeriod:""
    });
    const [comparisonGraphBaseTime, setComparisonGraphBaseTime] = useState([]);
    const [comparisonGraphCompareTime, setComparisonGraphCompareTime] = useState();

    useEffect(() => {


        if(props.options){
            if(props.options.basePeriod) comparisonGraphOptions.basePeriod = props.options.basePeriod;
            if(props.options.startAt) comparisonGraphOptions.startAt = props.options.startAt;
            if(props.options.endAt) comparisonGraphOptions.endAt = props.options.endAt;
            setComparisonGraphOptions({...comparisonGraphOptions});
        }


    }, [props]);


    useEffect(() => {

        const graphOptions = {
            axisX:{
                valueFormatString: "DD MMM YYYY",
            },
            axisY: [],
            axisY2: [],
            backgroundColor: "transparent",
            data: []
        };




        if(comparisonGraphOptions.totalIncome) {

            graphOptions.axisY.push({
                title: "Total Income",
                lineColor: "#4f81bc",
                titleFontColor: "#4f81bc",
                labelFontColor: "#4f81bc"
            });

            graphOptions.data.push({
                type: "splineArea",
                showInLegend: true,
                axisYIndex: 0, //Defaults to Zero
                name: "Total Income",
                dataPoints: comparisonGraphData['ti'],
                xValueFormatString: "DD MMM YYYY",
                lineColor: "#4f81bc",
                markerColor:"#4f81bc",
                fillOpacity: .9,
                toolTipContent: "{x}: ${y} <span style='color:grey;'>(N:{countNew} R:{countRepeat})</span>",
            });

            if(comparisonGraphData['tiC'].length){
				graphOptions.axisY.push({
					title: "Total Income Compare",
					lineColor: "#4f81bc",
					titleFontColor: "#4f81bc",
					labelFontColor: "#4f81bc"
				});

				graphOptions.data.push({
					type: "splineArea",
					showInLegend: true,
					axisYIndex: 0, //Defaults to Zero
					name: "Total Income Compare",
					dataPoints: comparisonGraphData['tiC'],
					xValueFormatString: "DD MMM YYYY",
					lineColor: "#4f81bc",
					markerColor:"#4f81bc",
					fillOpacity: .9,
					toolTipContent: "{x}: ${y} <span style='color:grey;'>(N:{countNew} R:{countRepeat})</span>",
				});
            }

        }
		if(comparisonGraphOptions.monthlyIncome) {

			graphOptions.axisY.push({
				title: "Total Monthly Income",
				lineColor: "#34547b",
				titleFontColor: "#34547b",
				labelFontColor: "#34547b"
			});

			graphOptions.data.push({
				type: "splineArea",
				showInLegend: true,
				axisYIndex: 0, //Defaults to Zero
				name: "Total Income",
				dataPoints: comparisonGraphData['tmi'],
				xValueFormatString: "DD MMM YYYY",
				lineColor: "#34547b",
				markerColor:"#34547b",
				fillOpacity: .5,
				toolTipContent: "{x}: ${y} <span style='color:grey;'>(N:{countNew} R:{countRepeat})</span>",
			});

			if(comparisonGraphData['tmiC'].length){
				graphOptions.axisY.push({
					title: "Total Income Compare",
					lineColor: "#4f81bc",
					titleFontColor: "#4f81bc",
					labelFontColor: "#4f81bc"
				});

				graphOptions.data.push({
					type: "splineArea",
					showInLegend: true,
					axisYIndex: 0, //Defaults to Zero
					name: "Total Monthly Income Compare",
					dataPoints: comparisonGraphData['tmiC'],
					xValueFormatString: "DD MMM YYYY",
					lineColor: "#4f81bc",
					markerColor:"#4f81bc",
					fillOpacity: .5,
					toolTipContent: "{x}: ${y} <span style='color:grey;'>(N:{countNew} R:{countRepeat})</span>",
				});
			}

		}
		if(comparisonGraphOptions.annualIncome) {

			graphOptions.axisY.push({
				title: "Total Annual Income",
				lineColor: "#18293c",
				titleFontColor: "#18293c",
				labelFontColor: "#18293c"
			});

			graphOptions.data.push({
				type: "splineArea",
				showInLegend: true,
				axisYIndex: 0, //Defaults to Zero
				name: "Total Income",
				dataPoints: comparisonGraphData['tai'],
				xValueFormatString: "DD MMM YYYY",
				lineColor: "#18293c",
				markerColor:"#18293c",
				fillOpacity: .5,
				toolTipContent: "{x}: ${y} <span style='color:grey;'>(N:{countNew} R:{countRepeat})</span>",
			});

			if(comparisonGraphData['taiC'].length){
				graphOptions.axisY.push({
					title: "Total Income Compare",
					lineColor: "#4f81bc",
					titleFontColor: "#4f81bc",
					labelFontColor: "#4f81bc"
				});

				graphOptions.data.push({
					type: "splineArea",
					showInLegend: true,
					axisYIndex: 0, //Defaults to Zero
					name: "Total Annual Income Compare",
					dataPoints: comparisonGraphData['taiC'],
					xValueFormatString: "DD MMM YYYY",
					lineColor: "#4f81bc",
					markerColor:"#4f81bc",
					fillOpacity: .5,
					toolTipContent: "{x}: ${y} <span style='color:grey;'>(N:{countNew} R:{countRepeat})</span>",
				});
			}

		}

        if(comparisonGraphOptions.expectedTotalIncome) {

            graphOptions.axisY.push({
                title: "Expected Total Income",
                lineColor: "#8b4fbc",
                titleFontColor: "#8b4fbc",
                labelFontColor: "#8b4fbc"
            });

            graphOptions.data.push({
                type: "splineArea",
                showInLegend: true,
                axisYIndex: 0, //Defaults to Zero
                name: "Expected Total Income",
                dataPoints: comparisonGraphData['eti'],
                xValueFormatString: "DD MMM YYYY",
                lineColor: "#8b4fbc",
                markerColor:"#8b4fbc",
                color: "rgb(136 89 189 / 36%)",
                fillOpacity: 0,
                toolTipContent: "{x}: ${y} <span style='color:grey;'>(N:{countNew} R:{countRepeat})</span>",
            });
        }


		for(const period of ['Monthly','Annual']){

			const paramStr = 'expectedTotal'+period+'Income';

			if(comparisonGraphOptions[paramStr]) {

				let lineColor;
				let markerColor;
				let color;
				let dataStr;
				if(period == 'Monthly'){
					dataStr = 'etmi';
					lineColor = '#5a3762';
					markerColor = '#5a3762';
					color = "rgb(94 58 107 / 69%)";
				}else{
					dataStr = 'etai';
					lineColor = '#6b49b1';
					markerColor = '#6b49b1';
					color = "rgb(124 76 141 / 69%)";
				}

				graphOptions.axisY.push({
					title: "Expected Total "+period+" Income",
					lineColor: lineColor,
					titleFontColor: lineColor,
					labelFontColor: lineColor
				});

				graphOptions.data.push({
					type: "splineArea",
					showInLegend: true,
					axisYIndex: 0, //Defaults to Zero
					name: "Expected Total "+period+" Income",
					dataPoints: comparisonGraphData[dataStr],
					xValueFormatString: "DD MMM YYYY",
					lineColor: lineColor,
					markerColor:markerColor,
					color: color,
					fillOpacity: 0,
					toolTipContent: "{x}: ${y} <span style='color:grey;'>(N:{countNew} R:{countRepeat})</span>",
				});

			}
		}

        if(comparisonGraphOptions.newSubscriptions || comparisonGraphOptions.endedSubscriptions) {
            graphOptions.axisY2.push({
                title: "New\\Ended Subscriptions",
                lineColor: "black",
                titleFontColor: "black",
                labelFontColor: "black"
            });
        }

        if(comparisonGraphOptions.newSubscriptions) {



            graphOptions.data.push({
                type: "column",
                showInLegend: true,
                axisYIndex: 1, //Defaults to Zero
                axisYType: "secondary",
                name: "New Subscriptions",
                dataPoints: comparisonGraphNS,
                xValueFormatString: "DD MMM YYYY",
                lineColor: "green",
                markerColor:"green",
                color:"green",
                fillOpacity: .3,
                maximum: 10
            });

        }
        if(comparisonGraphOptions.endedSubscriptions) {

            graphOptions.data.push({
                type: "column",
                showInLegend: true,
                axisYIndex: 1, //Defaults to Zero
                axisYType: "secondary",
                name: "Ended Subscriptions",
                dataPoints: comparisonGraphES,
                xValueFormatString: "DD MMM YYYY",
                lineColor: "red",
                markerColor:"red",
                color:"red",
                fillOpacity:.3,
                maximum: 10
            });
        }

        setCanvasOptions(graphOptions);

    }, [comparisonGraphData,comparisonGraphNS,comparisonGraphES]);

    useEffect(() => {

        AP.get('/my/dashboard/chart/comparison?for=members',comparisonGraphOptions)
            .then(function(res){


				const ti = [], tiC = [];
				const tmi = [], tmiC = [];
				const tai = [], taiC = [];
                const eti = [],etiC = [];
				const etmi = [],etmiC = [];
				const etai = [],etaiC = [];
                const ts = [], tsC = [];
                const es = [], esC = [];
                for(let p of res){
                    const date = new Date(p.date*1000);

                    if(p.category == 'Total Income'){
                        ti.push({"x":date,y:p.value, countRepeat:p.countRepeat, countNew: p.countNew});
                    }
                    else if(p.category == 'Compare Total Income'){
						tiC.push({"x":date,y:p.value, countRepeat:p.countRepeat, countNew: p.countNew});
					}
					else if(p.category == 'Monthly Income'){
						tmi.push({"x":date,y:p.value, countRepeat:p.countRepeat, countNew: p.countNew});
					}
					else if(p.category == 'Compare Monthly Income'){
						tmiC.push({"x":date,y:p.value, countRepeat:p.countRepeat, countNew: p.countNew});
					}
					else if(p.category == 'Annual Income'){
						tai.push({"x":date,y:p.value, countRepeat:p.countRepeat, countNew: p.countNew});
					}
					else if(p.category == 'Compare Annual Income'){
						taiC.push({"x":date,y:p.value, countRepeat:p.countRepeat, countNew: p.countNew});
					}
                    else if(p.category == 'Expected Total Income'){
                        eti.push({"x":date,y:p.value, countRepeat:p.countRepeat, countNew: p.countNew});
                    }
					else if(p.category == 'Expected Total Monthly Income'){
						etmi.push({"x":date,y:p.value, countRepeat:p.countRepeat, countNew: p.countNew});
					}
					else if(p.category == 'Expected Total Annual Income'){
						etai.push({"x":date,y:p.value, countRepeat:p.countRepeat, countNew: p.countNew});
					}
                    else if(p.category == 'Total Subscriptions'){
                        ts.push({"x":date,y:p.value});
                    }
                    else if(p.category == 'Ended Subscriptions'){
                        es.push({"x":date,y:p.value});
                    }
                }

				setComparisonGraphData({
					'ti' : ti,
					'tiC' : tiC,
					'tmi' : tmi,
					'tmiC' : tmiC,
					'tai' : tai,
					'taiC' : taiC,
					'eti' : eti,
					'etmi' : etmi,
					'etai' : etai,
                });

                setGraphGirthDataNS(ts);
                setGraphGirthDataES(es);
            });

    }, [comparisonGraphOptions]);

    function setComparisonGraphValue(name,value){
        comparisonGraphOptions[name] = value;

        let startAt = null;
        let endAt = null;


        if(name=="basePeriod"){

			if(value == "today"){
				startAt = moment().utc( true );
				endAt = moment().utc( true );
			}
			else if(value == "today"){
				startAt = moment();
				endAt = moment();
			}
			else if(value == "7days"){
                startAt = moment().subtract(7, 'd');
                endAt = moment();
            }
			else if(value == "month"){
				startAt = moment().startOf('month').utc( true );
				endAt = moment().endOf('month').utc( true );
			}
			else if(value == "1month"){
				startAt = moment().subtract(1, 'M');
				endAt = moment();
			}
            else if(value == "3months"){
				startAt = moment().subtract(3, 'M');
				endAt = moment();
            }
            else if(value == "1year"){
				startAt = moment().subtract(1, 'Y');
				endAt = moment();
            }


			//<Option value="all">All Time</Option>

			setComparisonGraphBaseTime([
				startAt,
				endAt
			]);
			comparisonGraphOptions['startAt'] = startAt.unix();
			comparisonGraphOptions['endAt'] = endAt.unix();
        }



		setComparisonGraphOptions({...comparisonGraphOptions});


        return true;
    }

    function changeBaseTime(e){
		setComparisonGraphBaseTime(e);

		comparisonGraphOptions['basePeriod'] = 'custom';
		comparisonGraphOptions['startAt'] = e[0].unix();
		comparisonGraphOptions['endAt'] = e[1].unix();

		setComparisonGraphOptions({...comparisonGraphOptions});
    }


    return(
        <>
            <div style={{display:"flex",gap:40}}>
                <h4>Comparison Charts</h4>

                {(!props.options || !props.options.basePeriod) &&
                <div style={{display: "flex", marginLeft:"auto"}}>
                    <div style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: 10,
                        display: "flex",
                        backgroundColor: "white"
                    }}>
                        <div style={{padding: 9, borderRight: "1px solid #d9d9d9"}}>
                            <Select value={comparisonGraphOptions["basePeriod"]} style={{width: 120, border: "none"}}
                                    onChange={function (value, dateStrings) {
                                        setComparisonGraphValue("basePeriod", value);
                                    }} className="massSelect noBorder">
                                <Option value="today">Today</Option>
                                <Option value="month">This Month</Option>
                                <Option value="7days">Last 7 Days</Option>
                                <Option value="1month">Last month</Option>
                                <Option value="3months">Last 3 months</Option>
                                <Option value="1year">Last year</Option>
                                <Option value="all">All Time</Option>
                                <Option value="custom">Custom</Option>
                            </Select>
                        </div>
                        <div>
                            <RangePicker
                                style={{border: "none"}}
                                value={comparisonGraphBaseTime}
                                onChange={(e)=>changeBaseTime(e)}
                            />

                        </div>
                    </div>
                    <div style={{padding: 10, fontSize: 14}}>compare to</div>
                    <div style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: 10,
                        display: "flex",
                        backgroundColor: "white"
                    }}>
                        <div style={{padding: 9, borderRight: "1px solid #d9d9d9"}}>
                            <Select value={comparisonGraphOptions["comparePeriod"]} style={{width: 120, border: "none"}}
                                    onChange={function (value, dateStrings) {
                                        setComparisonGraphValue("comparePeriod", value);
                                    }} className="massSelect noBorder">
                                <Option value="">None</Option>
                                <Option value="previous">Previous Period</Option>
                                <Option value="custom">Custom</Option>
                            </Select>
                        </div>
                        <div>
                            <DatePicker
                                onChange={function onChange(date, dateString) {
                                }}
                                value={comparisonGraphCompareTime}
                                style={{border: "none"}}
                            />
                        </div>
                    </div>
                </div>
                }

            </div>



			<div style={{display:"flex", justifyContent:"right", padding:"20px 0", gap:10}}>
                <div style={{border:"1px solid lightgrey", borderRadius:10, padding:10, position:"relative"}}>
                    <div style={{position:"absolute", top:-14, left:20, backgroundColor:"white", padding:"0 4px"}}>Income</div>
                    <Checkbox
                        onChange={function dashboardEditChange(e){ setComparisonGraphValue("totalIncome",e.target.checked); }}
                        checked={comparisonGraphOptions['totalIncome']}
                    >Total</Checkbox>
                    <Checkbox
                        onChange={function dashboardEditChange(e){ setComparisonGraphValue("monthlyIncome",e.target.checked); }}
                        checked={comparisonGraphOptions['monthlyIncome']}
                    >Monthly</Checkbox>
                    <Checkbox
                        onChange={function dashboardEditChange(e){ setComparisonGraphValue("annualIncome",e.target.checked); }}
                        checked={comparisonGraphOptions['annualIncome']}
                    >Annual</Checkbox>
                </div>
				<div style={{border:"1px solid lightgrey", borderRadius:10, padding:10, position:"relative"}}>
					<div style={{position:"absolute", top:-14, left:20, backgroundColor:"white", padding:"0 4px"}}>Expected</div>
                    <Checkbox
                        onChange={function dashboardEditChange(e){ setComparisonGraphValue("expectedTotalIncome",e.target.checked); }}
                        checked={comparisonGraphOptions['expectedTotalIncome']}
                    >Total</Checkbox>
					<Checkbox
						onChange={function dashboardEditChange(e){ setComparisonGraphValue("expectedTotalMonthlyIncome",e.target.checked); }}
						checked={comparisonGraphOptions['expectedTotalMonthlyIncome']}
					>Monthly</Checkbox>
					<Checkbox
						onChange={function dashboardEditChange(e){ setComparisonGraphValue("expectedTotalAnnualIncome",e.target.checked); }}
						checked={comparisonGraphOptions['expectedTotalAnnualIncome']}
					>Annual</Checkbox>
                </div>
				<div style={{border:"1px solid lightgrey", borderRadius:10, padding:10, position:"relative"}}>
					<div style={{position:"absolute", top:-14, left:20, backgroundColor:"white", padding:"0 4px"}}>Subscriptions</div>
                    <Checkbox
                        onChange={function dashboardEditChange(e){ setComparisonGraphValue("newSubscriptions",e.target.checked); }}
                        checked={comparisonGraphOptions['newSubscriptions']}
                    >New</Checkbox>
                    <Checkbox
                        onChange={function dashboardEditChange(e){ setComparisonGraphValue("endedSubscriptions",e.target.checked); }}
                        checked={comparisonGraphOptions['endedSubscriptions']}
                    >Ended</Checkbox>
                </div>
			</div>

            <CanvasJSChart options={canvasOptions} />
        </>
	);
}