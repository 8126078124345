import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {
    Form,Input,
} from 'antd';

export default function Certificate(props) {

    const [form] = Form.useForm();
    const history = useHistory();


    function getStartedClicked(data){
        history.push({
            pathname: '/signUp',
            state: data
        });
    }


    return (
        <div className="content_inner_padded" >
            <div className="container">

                <div className="ibn ibn-sb sec">
                    <div className="ebn ud-bn ud-pt obg uix-alter ebn-p321x sec">
                        <div className="cpnw cov xg">
                            <div className="main">
                                <div className="cpn al-g cl-g bg-g wd-g">
                                    <div className="title">
                                        <h1>Certificate in Applied Sports Nutrition
                                            <div className="breadcrumb"><span className="separator"> &gt; </span><span
                                                className="current">Certificate in Applied Sports Nutrition</span></div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg hs-bg is-basic" data-height="auto" style={{"background-image":"url(https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/cert-sna.jpg)"}}>
                            <div className="over tpb op01" style={{"opacity":"0.01"}}></div>
                        </div>
                    </div>
                </div>

                <div className="site-wrap ipg iopg ipg-certificate-in sec">
                    <div className="wrap wrap-wd wrap-page gb-sd no-aside sec">
                        <div className="icontent sec">
                            <article className="epc epg">
                                <div className="ientry">
                                    <div
                                        className="section sec-sc sec basic row-xd sec-rg sec-intro ud-pad tpd-s bpd-s sec-a162813z">
                                        <div className="main">
                                            <div className="content">
                                                <div className="ecn ec-sc ec-a476485z">
                                                    <div className="content">
                                                        <h3 style={{"text-align":"center"}}>Sports Nutrition is the merger
                                                            of the two worlds of; Applied Exercise Physiology and
                                                            Applied Nutrition Sciences, and its ongoing development and
                                                            innovation.</h3>
                                                    </div>
                                                </div>
                                                <span className="gaps gap-sc"></span>
                                                <p><img className=" wp-image-344 aligncenter"
                                                        src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/circle-diagram-1.jpg"
                                                        alt="" width="882" height="589"
                                                        srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/circle-diagram-1.jpg 1400w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/circle-diagram-1-600x401.jpg 600w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/circle-diagram-1-764x510.jpg 764w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/circle-diagram-1-1160x775.jpg 1160w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/circle-diagram-1-768x513.jpg 768w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/circle-diagram-1-404x270.jpg 404w"
                                                        sizes="(max-width: 882px) 100vw, 882px"/></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section sec-sc sec basic row-xd sec-rg sec-who-is light ud-pad tpd-s bpd-s ud-bgc plx-bottom sec-a881041z" style={{"paddingTop":"100px", "paddingBottom":"100px", "backgroundColor":"#000"}}>
                                        <div className="main">
                                            <div className="content">
                                                <div className="eclm clm-sc g6 ec-content-left">
                                                    <div className="content">
                                                        <h3>About the Certificate</h3>
                                                        <p>The Certificate in Applied Sports Nutrition has been reverse
                                                            engineered to meet the minimum requirements for practising
                                                            in Sports Nutrition.</p>
                                                        <p>It is the combination and synergy of applied Nutrition
                                                            Physiology and applied Exercise Physiology subjects (as seen
                                                            above) that forms the foundation on Sports Nutrition.
                                                            Students that complete this program become eligible to
                                                            register, insure, and practise as Accredited Sports
                                                            Nutritionists provisionally while they ascertain through
                                                            practise and experi&shy;ence in the field if&nbsp; this is
                                                            the career path for them.</p>
                                                        <span className="gaps gap-sc"></span>
                                                    </div>
                                                </div>
                                                <div className="eclm clm-sc g6 ec-content-right">
                                                    <div className="content">
                                                        <p>The majority of graduates and Sports Nutrition professionals
                                                            will be working in some form of private practise, which is
                                                            heavily dependent on your skills as a service provider to
                                                            get clients on board and generate buy-in from clients to
                                                            adhere to plans.</p>
                                                        <p>Rather than more traditional models the Association
                                                            recognises that spending 4-5 years, and $50,000+ to become
                                                            qualified and practise, only to find out that private
                                                            practise (the majority of work in applied sciences and
                                                            dietetics is private practise) isn’t for them. We accept
                                                            that private practise isn’t for everyone and don’t intend to
                                                            sell you on a one size fits all pipe dream, but if this is
                                                            the career for you, we will need further study after
                                                            3-years.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="section sec-sc sec basic row-xd sec-rg sec-intro ud-pad tpd-s bpd-s sec-a405127z">
                                        <div className="main">
                                            <div className="content">
                                                <h2 style={{"text-align":"center"}}>A Road Map To</h2>
                                                <p style={{"text-align":"center"}}></p><h2 className="title tl-sc"><span>Certificate in Applied Sports Nutrition</span>
                                            </h2><p></p>
                                                <span className="gaps gap-sc"></span>

                                                <p><img loading="lazy" className=" wp-image-339 aligncenter"
                                                        src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/road-map.jpg"
                                                        alt="" width="1102" height="344"
                                                        srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/road-map.jpg 1800w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/road-map-600x187.jpg 600w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/road-map-920x287.jpg 920w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/road-map-1380x431.jpg 1380w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/road-map-768x240.jpg 768w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/road-map-1536x480.jpg 1536w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/06/road-map-460x144.jpg 460w"
                                                        sizes="(max-width: 1102px) 100vw, 1102px"/></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section sec-sc sec basic row-xd sec-rg sec-sport ud-pad tpd-s bpd-m ud-bgc plx-bottom sec-a687482z" style={{"paddingTop":"100px", "paddingBottom":"140px", "backgroundColor":"#f9f9f9"}}>
                                        <div className="main">
                                            <div className="content">
                                                <h2 style={{"text-align":"center"}}>Frequently Asked Questions</h2>
                                                <div id="accordion-17" className="accordion-area ">
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name active"
                                                        href="#accordion-7145"><span className="left-arrow"></span>How
                                                        long does the course run for?</a>
                                                        <div id="accordion-7145"
                                                             className="accordion-section-content open"
                                                             style={{"display":"block"}}>
                                                            <div className="content">
                                                                <p>The certificate program runs for 15-20 weeks,
                                                                    depending on assessment completion and
                                                                    submission.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section">
                                                        <a className="accordion-section-title name" href="#accordion-6635">
                                                            <div>
                                                                <span className="left-arrow"></span>If I have done some previous study can I get credit for it?
                                                            </div>
                                                        </a>
                                                        <div id="accordion-6635" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Yes certain university subjects and other private
                                                                    courses are eligible for certain credit (not full)
                                                                    as very few universities are currently running full
                                                                    Applied Sports Nutrition programs at an undergrad
                                                                    level.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section">
                                                        <a
                                                        classNa
                                                        me="accordion-section-title name" href="#accordion-4670"><span
                                                        className="left-arrow"></span>Is the course delivered
                                                        online?</a>
                                                        <div id="accordion-4670" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Yes, the course is fully online with over 10 hours of
                                                                    lecture content for you to view, and other practise
                                                                    exams &amp; case study assignments to work on. If
                                                                    you are in a city where a practical is not being run
                                                                    during your intake, then instead of attending you
                                                                    are allowed to submit video evidence for
                                                                    assessment.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-8057"><span
                                                        className="left-arrow"></span>Is any prior study required for
                                                        this program?</a>
                                                        <div id="accordion-8057" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>No, but if you have not attended a sports science
                                                                    university program then additional texts and units
                                                                    are required so that you understand the basic
                                                                    applied sciences.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-8324"><span
                                                        className="left-arrow"></span>What can I do with this cert?</a>
                                                        <div id="accordion-8324" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Upon completion you will be eligible to register as
                                                                    an Accredited Sports Nutritionist (see registration
                                                                    section of FAQ for further information) as a
                                                                    registered Accredited Sports Nutritionist you are
                                                                    now eligible to insure for Sports Nutrition
                                                                    Programming.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section sec-sc sec basic row-xd sec-rg light sec-blue-strip ud-pad ud-bgc sec-a132994z" style={{"paddingTop":"65px", "paddingBottom":"65px", "backgroundColor":"#01a5f8"}}>
                                        <div className="main">
                                            <div className="content">
                                                <p style={{"text-align":"center"}}>
                                                    <a href="https://www.sportsnutritionassociation.com/product/annual-registration/" className="button btn-fill btn-normal">
                                                        <span>Registration</span>
                                                    </a>
                                                    <a href="https://www.sportsnutritionistregistry.com/" className="button btn-normal" target="_blank">
                                                        <span>Find A Nutritionist</span>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>



            </div>

        </div>
    );
}