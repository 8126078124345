import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";




const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function ResourceForm(props) {

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

    const setBreadCrumbs = useContext(BreadCrumbContext);

    const AP = API2();

    useEffect(() => {
        if(props.resource){
            if(props.resource.r_membership === null) props.resource.r_membership = "";
            if(props.resource.r_open === null) props.resource.r_open = "";

            if(props.resource.attachment_id) {

                setFiles([{
                    uid: props.resource.attachment.id,
                    name: props.resource.attachment.name,
                    status: 'done',
                    url: props.resource.attachment.endpoint,
                    thumbUrl: props.resource.attachment.endpoint
                }]);
            }

            if(props.resource.r_membership === 1) props.resource.r_membership = "yes";
            else if(props.resource.r_membership === 0) props.resource.r_membership = "no";

            if(props.resource.r_open === 1) props.resource.r_open = "yes";
            else if(props.resource.r_open === 0) props.resource.r_open = "no";
        }
        form.setFieldsValue(props.resource);
    }, [props.resource]);

    function saveForm(data){
        setIsSaving(true);

        let url = '/resource';
        if(props.resource.id) {
            url += '/'+props.resource.id;
        }


        //get profile image value
        data['attachment_id'] = null;
        for(let f of files){
            if(f.attachment_id) data['attachment_id'] = f.attachment_id;
            else data['attachment_id'] = f.uid;
        }



        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            history.push("/resource/"+res.id);
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="resource"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'Please select a type',
                    }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="description"
                label="Description"
            >
                <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item
                name="attachment_id"
                label="File"
            >
                <Upload files={files} maxCount={1} setFiles={setFiles}/>
            </Form.Item>

            <Form.Item
                name="link"
                label="Link"
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="r_membership"
                label="Requires Membership"
            >
                <Select>
                    <Option value="">Either</Option>
                    <Option value="no">No</Option>
                    <Option value="yes">Yes</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="r_open"
                label="Requires Open"
            >
                <Select>
                    <Option value="">Either</Option>
                    <Option value="no">No</Option>
                    <Option value="yes">Yes</Option>
                </Select>
            </Form.Item>


            <div>
                <Button type="primary" htmlType="submit" style={{float:"right"}} loading={isSaving} style={{float:"right"}}>{(isSaving)?'Saving':'Save'}</Button>
            </div>
        </Form>
    );
}