import React, {Component, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Button } from 'antd';
import {
    Row,Col,Card,Spin
} from 'antd';
import API2 from "../../components/api2";
import countryList from "react-select-country-list";

export default function About(props) {

    const AP = API2();


    const [isLoading, setIsLoading] = useState(true);
    const [plans, setPlans] = useState(false);

    useEffect(() => {

        AP.get('/plans?for=member')
            .then(function (res) {
                setPlans(res.data);
                setIsLoading(false);
            });

    }, []);


    return (
        <div className="content_inner_padded" style={{height:"100%"}}>

            <div className="site-wrap ipg ishop ishop-single ipg-single sec" style={{paddingTop:"50px",height:"100%"}}>

                <div className="main">
                    <div className="wrap wrap-rg no-aside wrap-shop row">
                        <div className="icontent g12 iwc">
                            <div className="woocommerce-notices-wrapper"></div>
                            <div id="product-145"
                                 className="post-145 product type-product status-publish has-post-thumbnail product_cat-members first instock shipping-taxable purchasable product-type-variable-subscription">
                                <div className="g6 iwc-photo no-gallery">
                                    <div
                                        className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-5 images"
                                        data-columns="5"
                                        style={{"opacity":"1", "transition":"opacity 0.25s ease-in-out 0s"}}>
                                        <figure className="woocommerce-product-gallery__wrapper">
                                            <div
                                                data-thumb="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-600x400.jpg"
                                                data-thumb-alt="" className="woocommerce-product-gallery__image"
                                                style={{"position":"relative", "overflow":"hidden"}}
                                                data-o_data-thumb="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-200x200.jpg">
                                                <a href="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition.jpg"
                                                   data-o_href="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition.jpg"><img
                                                    width="600" height="400"
                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-600x400.jpg"
                                                    className="wp-post-image" alt="" loading="lazy"
                                                    title="nutrition" data-caption=""
                                                    data-src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition.jpg"
                                                    data-large_image="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition.jpg"
                                                    data-large_image_width="1920" data-large_image_height="1280"
                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-600x400.jpg 600w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-765x510.jpg 765w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-1163x775.jpg 1163w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-768x512.jpg 768w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-1536x1024.jpg 1536w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-405x270.jpg 405w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition.jpg 1920w"
                                                    sizes="(max-width: 600px) 100vw, 600px"
                                                    data-o_src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-600x400.jpg"
                                                    data-o_height="400" data-o_width="600"
                                                    data-o_srcset="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-600x400.jpg 600w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-765x510.jpg 765w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-1163x775.jpg 1163w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-768x512.jpg 768w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-1536x1024.jpg 1536w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition-405x270.jpg 405w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition.jpg 1920w"
                                                    data-o_sizes="(max-width: 600px) 100vw, 600px"
                                                    data-o_title="nutrition" data-o_data-caption="" data-o_alt=""
                                                    data-o_data-src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition.jpg"
                                                    data-o_data-large_image="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition.jpg"
                                                    data-o_data-large_image_width="1920"
                                                    data-o_data-large_image_height="1280"/></a><img
                                                role="presentation" alt=""
                                                src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/nutrition.jpg"
                                                className="zoomImg"
                                                style={{"position":"absolute", "top":"0px", "left":"0px", "opacity":"0", "width":"1920px", "height":"1280px", "border":"none", "max-width":"none", "max-height":"none"}}/>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                                <div className="g6 iwc-content">
                                    <div className="summary entry-summary">
                                        <h1 className="product_title entry-title">Annual Registration</h1>

                                        <script>
                                            document.domain = 'sportsnutritionassociation.com';
                                        </script>

                                        <iframe src="https://stagingapi.sportsnutritionassociation.com/testing.html" sandbox="allow-scripts allow-top-navigation allow-top-navigation-by-user-activation allow-forms allow-popups allow-same-origin"></iframe>

                                        <div style={{display:"flex",marginTop:40}}>

                                            {isLoading && <Spin size="small"/>}
                                            {!isLoading && <>
                                                {plans.map(plan => (
                                                    <Card type="inner" title={plan.name} style={{ width:300,marginRight:5 }}>
                                                        <div style={{display:"flex"}}>
                                                            <h2 style={{flex:1}}>${plan.payments[0].amount/100} <span style={{fontSize:10}}>USD/{plan.payments[0].billing_duration}</span></h2>
                                                            <div>{plan.description}</div>
                                                        </div>
                                                        <div style={{display:"flex",justifyContent:"end", paddingTop:"20px"}}>
                                                            <Link to={"/signUp/"+plan.name}>
                                                                <Button type="primary" shape="round" size={"large"}>Select</Button>
                                                            </Link>
                                                        </div>
                                                    </Card>
                                                ))}
                                            </>}
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="woo-up-rel sec">
                    <div className="main"></div>
                </div>
            </div>




        </div>
    );
}