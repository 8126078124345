import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment';
import dayjs from "dayjs";
import BreadCrumbContext from "../contexts/breadCrump";



const { RangePicker } = DatePicker;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function LogEmailForm(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const AP = API2();


    useEffect(() => {

        props.form.resetFields();

        if(props.emailLogId) {
            AP.get('/invoice/'+props.invoiceId)
                .then(function (res) {

                    res.date = [];
                    if (res.start_at) {
                        res.date.push(dayjs(res.start_at * 1000));
                    } else {
                        res.date.push(null);
                    }

                    if (res.end_at) {
                        res.date.push(dayjs(res.end_at * 1000));
                    } else {
                        res.date.push(null);
                    }

                    if(!res.paid) {
                        res.paid = '';
                    }

                    props.form.setFieldsValue(res);
                    setIsLoading(false);
                });
        }else{
            setIsLoading(false);
        }
    }, [props.emailLogId]);


    function saveForm(data){

        setIsSaving(true);

        if(!props.emailLogId) {
            data.user_id = props.user.id;
        }

        let url = '/email/history';
        if(props.emailLogId) {
            url += '/'+props.emailLogId;
        }

        AP.post(url,data).then(function(res){

            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            props.onFinish();
        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    props.form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }



    function deleteForm(data){

        if(!props.invoice.id) return false;

        setIsSaving(true);

        let url = '/invoice/'+props.invoice.id;

        AP.delete(url).then(function(res){

            notification.success({
                message: `Deleted`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            props.history.replace({ pathname: '/user/'+props.invoice.user.id});
        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    props.form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }

    return(
        <Form
            id="myForm"
            {...formItemLayout}
            form={props.form}
            name="emailLog"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                name="type"
                label="Type"
            >
                <Input />
            </Form.Item>

        </Form>
	);
}