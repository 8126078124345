import React, {Component, useContext, useEffect, useState} from "react";
import BreadCrumbContext from "../../contexts/breadCrump";
import '../../css/zoom.css';
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Button,
} from 'antd';


import { QuestionCircleOutlined,AudioOutlined  } from '@ant-design/icons';
import API from "../../components/api";


const { Search } = Input;
const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

export default function APIKey(props) {
    const [form] = Form.useForm();
    const [formData, setFormData] = useState([]);
    const [resp, isLoading, isError, doPost] = API('POST','/key');
    const [data, dataIsLoading, dataIsError] = API('GET','/key');

    useEffect(() => form.resetFields(), [resp]);

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([
			<span><i className="fas fa-book"/> API</span>,
			<span><i className="fas fa-fa-key"/> Access Key</span>
		]);
	}, []);

    useEffect(() => {
        setFormData(data);
        form.setFieldsValue(data);
    }, [data]);

    useEffect(() => {
        if(!resp) return true;
        setFormData(resp);
        form.setFieldsValue(resp);
    }, [resp]);


    function regenerateSecret(){
        doPost();
    }
    function copyKey(){
        navigator.clipboard.writeText(formData.api_key);
    }
    function copySecret(){
        navigator.clipboard.writeText(formData.api_secret_key);
    }

    return (
        <div style={{ width:"800px",margin:"auto"}}>
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={props.onFinish}
                //initialValues={{apikey:"123","api_secret":"321",zzz:"ccc"}}
                scrollToFirstError
            >

                <Form.Item
                    label="API KEY"
                >
					<Row stype={{display:"flex"}}>
						<Col flex="1">
                            <Form.Item
                                name="api_key"
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
						</Col>
						<Col><Button  type="primary" onClick={copyKey}><i className="far fa-copy"/></Button></Col>
					</Row>
                </Form.Item>

                <Form.Item
                    label="API Secret"
                >

                    <Row stype={{display:"flex"}}  gutter="20">
                        <Col flex="1">


                            <Row stype={{display:"flex"}}>
                                <Col flex="1">
                                    <Form.Item
                                        name="api_secret_key"
                                    >
                                        <Input.Password readOnly={true}/>
                                    </Form.Item>
                                </Col>
                                <Col><Button  type="primary" onClick={copySecret}><i className="far fa-copy"/></Button></Col>
                            </Row>
						</Col>
                        <Col><Button type="primary" shape="round" onClick={regenerateSecret}>Regenerate</Button></Col>
                    </Row>
                </Form.Item>

            </Form>

            <h1>JWT Generation</h1>
            <p>The JWT is created by the api consumer. This allows the consumer to set some options like expiry and scope.  </p>

            <h3 style={{paddingTop:20}}>JWT Options</h3>
            <p><b>Expires:</b> Unix timestamp of when JWT expires</p>
            <p><b>Scope:</b> What this JWT is allowed to do</p>
            <ul>
                <li><div style={{display:"inline-flex"}}><div style={{width:200}}>controller</div><div> =>  {`[{"controller":"coach"}]`}</div></div></li>
                <li><div style={{display:"inline-flex"}}><div style={{width:200}}>action</div><div> =>  {`[{"action":"read"}]`}</div></div></li>
                <li><div style={{display:"inline-flex"}}><div style={{width:200}}>param</div><div> =>  {`[{"param":{"coach_id":1}}]`}</div></div></li>
                <li></li>
                <li><div style={{display:"inline-flex"}}><div style={{width:200}}>controller + action</div><div> =>  {`[{"controller":"coach","action":"read"}]`}</div></div></li>
                <li><div style={{display:"inline-flex"}}><div style={{width:200}}>controller + action + param</div><div> =>  {`[{"controller":"coach","action":"read","param":{"coach_id":1}}]`}</div></div></li>
                <li><div style={{display:"inline-flex"}}><div style={{width:200}}>controller + action + param</div><div> =>  {`[{"controller":"coach","action":"read","param":{"coach_id":[1,5,65]}}]`}</div></div></li>
                <li></li>
                <li><div style={{display:"inline-flex"}}><div style={{width:200}}>action or action</div><div> =>  {`[{"action":"read"},{"action":"update"}]`}</div></div></li>
                <li><div style={{display:"inline-flex"}}><div style={{width:200}}>action or action</div><div> =>  {`[{"action":["read","update"]}]`}</div></div></li>
            </ul>

            <h3 style={{paddingTop:20}}>PHP Generation Example</h3>
            <code style={{backgroundColor:"#eee",border:"1px solid #999", display:"block", padding:20}}>
                <pre>{`
public function generateJWT($key, $secret, $exp=false,$scope=false){
    if(!$exp) $exp = time()+99999999;

    $header = json_encode(['typ' => 'JWT', 'alg' => 'HS256']);
    $payload = [
    'iss' => $key,
    'exp' => $exp
    ];
    if($scope){
        $payload['scope'] = $scope;
    }
    $payload = json_encode($payload);



    // Encode Header to Base64Url String
    $base64UrlHeader = str_replace(['+', '/', '='], ['-', '_', ''], base64_encode($header));

    // Encode Payload to Base64Url String
    $base64UrlPayload = str_replace(['+', '/', '='], ['-', '_', ''], base64_encode($payload));

    // Create Signature Hash
    $signature = hash_hmac('sha256', $base64UrlHeader . "." . $base64UrlPayload, $secret, true);

    // Encode Signature to Base64Url String
    $base64UrlSignature = str_replace(['+', '/', '='], ['-', '_', ''], base64_encode($signature));

    // Create JWT
    return $base64UrlHeader . "." . $base64UrlPayload . "." . $base64UrlSignature;
}
`}
                </pre>
            </code>


        </div>
    );
}

