import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    Checkbox
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";
import countryList from "react-select-country-list";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';




const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function UserPermissionsForm(props) {

    const setBreadCrumbs = useContext(BreadCrumbContext);
    const [form] = Form.useForm();
    const [user, setUser] = useState(props.user);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

    const AP = API2();
    useEffect(() => {
        const data = {};

        if(user.permissions) {
            for (const p of user.permissions) {
                data[p] = true;
            }
        }

        form.setFieldsValue({permissions:data});
    }, [props.user]);

    function saveForm(data){
        setIsSaving(true);


        let url = '/user/'+props.user.id+'/permissions';

        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            if(!props.user.id) {
                history.push("/user/" + res.id);
            }
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });;
    }

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="profile"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto",paddingBottom:70}}
        >

            <Form.Item
                name={["permissions", 'user_permissions']}
                valuePropName="checked"
            >
                <Checkbox>User Permissions</Checkbox>
            </Form.Item>

            <Form.Item
                name={["permissions", 'staff_access']}
                valuePropName="checked"
            >
                <Checkbox>Staff</Checkbox>
            </Form.Item>

            <Form.Item
                name={["permissions", 'member_access']}
                valuePropName="checked"
            >
                <Checkbox>Members</Checkbox>
            </Form.Item>

            <Form.Item
                name={["permissions", 'student_access']}
                valuePropName="checked"
            >
                <Checkbox>Students</Checkbox>
            </Form.Item>

            <Form.Item
                name={["permissions", 'reporting']}
                valuePropName="checked"
            >
                <Checkbox>Reporting</Checkbox>
            </Form.Item>

            <div>
                <Button type="primary" htmlType="submit" style={{float:"right"}} loading={isSaving} style={{float:"right"}}>{(isSaving)?'Saving':'Save'}</Button>
            </div>
        </Form>
	);
}