import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";

export default function Coupons(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-ticket-alt"/> Coupons</span>
        ]);
	}, []);

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => {return (!a.name)?-1:a.name.localeCompare(b.name)},
        	align:'left'
		},
		{
			title: 'Used For',
			key: 'for',
			align:'left',
			width: 100,
			render: function(text, record) {
				const fors = [];
				record.plan_coupon_maps.map((pcm)=> {
					if(!pcm.plan) return;
					if (!fors.includes(pcm.plan.for)) fors.push(pcm.plan.for);
				});
				return fors.map((forz)=>(
					<div style={{textTransform:"capitalize"}}>{forz}</div>
				));
			},
		},
		{
			title: 'Code',
			key: 'code',
			dataIndex: 'code',
			sorter: (a, b) => {return (!a.code)?-1:a.code.localeCompare(b.code)},
			align:'left',
			width: 200
		},
		{
			title: 'Amount',
			key: 'discount',
            dataIndex: 'discount',
            sorter: (a, b) => {return (!a.discount)?-1:a.discount.localeCompare(b.discount)},
            render: function(text, record) {
			    let discount = record.discount;
				let align;


			    if(record.type == 'fixed'){
                    discount = '$'+(discount/100);
					align = 'left';
                }else{
                    discount = (Math.round(discount*100)/100)+'%';
					align = 'right';
                }
			    return <div style={{textAlign:align}}>{discount}</div>
            },
            width: 120
},
		{
			title: 'Actions',
            key: 'actions',
            render: (text, record) => (
				<div>
					<span><NavLink to={"/coupon/"+record.id}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
				</div>
			),
            width: 150
		},
	];

	return (
		<div className="content_inner">
			<div style={{paddingTop:40}}>
				<div style={{float:"right",position:"relative",top:-40}}>
					<NavLink to="/coupon/new" activeClassName="current" style={{marginLeft:"auto"}}>
						<Button icon={<i className="fal fa-ticket-alt"/>}>&nbsp;Create Coupon </Button>
					</NavLink>
				</div>

				<SearchTable columns={columns} url="/coupons"/>
			</div>
		</div>
	);
};