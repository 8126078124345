import React, { Component } from "react";
import {Link} from 'react-router-dom';

class Pricing extends Component {
	render() {
		return (
			<div class="main">
				<div class="_w-pricing-hero">
					<div class="pricing-hero-tabs-content">
						<h1 class="f-p-35 _600">Investing in quality software makes your business more profitable</h1>
						<h2 class="f-p-24 _400">On average, new Wodify customers make an extra $1,000 per month by
							attracting clients, improving retention, and saving time.</h2></div>
				</div>
				<div class="_w-pricing-tabs">
					<div data-duration-in="300" data-duration-out="100" class="w-tabs">
						<div class="pricing-hero-tabs w-tab-menu" role="tablist"><a data-w-tab="Tab 1" id="tab-gym"
																					data-title="Investing in quality software makes your business more profitable"
																					data-sub="On average, new Wodify customers make an extra $1,000 per month by attracting clients, improving retention, and saving time."
																					class="pricing-tab-hero-item w-inline-block w-tab-link w--current"
																					href="#w-tabs-0-data-w-pane-0"
																					role="tab"
																					aria-controls="w-tabs-0-data-w-pane-0"
																					aria-selected="true">
							<div>GYM SOFTWARE</div>
						</a><a data-w-tab="Tab 2" id="tab-events" data-title="Hassle-free pricing for your gym events"
							   data-sub="Choose to pass on fees to participants or cover them yourself."
							   class="pricing-tab-hero-item w-inline-block w-tab-link" tabindex="-1"
							   href="#w-tabs-0-data-w-pane-1" role="tab" aria-controls="w-tabs-0-data-w-pane-1"
							   aria-selected="false">
							<div>EVENTS SOFTWARE</div>
						</a></div>
						<div class="_w-pricing-tabs-content w-tab-content">
							<div data-w-tab="Tab 1" class="w-tab-pane w--tab-active" id="w-tabs-0-data-w-pane-0"
								 role="tabpanel" aria-labelledby="tab-gym">
								<div class="_w-pricing-table w-hidden-small w-hidden-tiny">
									<div class="_w-pricing-full-table">
										<div>
											<div class="_w-pricing-sec-detail no-border">
												<div class="_w-pricing-detail-col left"></div>
												<div class="_w-pricing-detail-col"></div>
												<div class="_w-pricing-detail-col"></div>
												<div class="_w-pricing-detail-col popular"><img
													src="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5cf80def04912063b75d9d81_pricing_mostpop.svg"
													alt="" class="_w-pricing-popular"/>
													<div class="f-p-15 _600 inline">Most Popular</div></div>
											</div>
										</div>
										<div class="_w-pricing-grey-header rel">
											<div class="_w-pricing-detail-col left"><img
												src="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5b02a2197bb4bb7767c2a1a2_Wodify_Core%402x.png"
												width="109" alt=""/></div>
											<div class="_w-pricing-detail-col"><h3 class="f-p-35">Engage</h3>
												<div class="f-p-15">For small gyms with big dreams. The essentials to
													get up and running.
												</div>
											</div>
											<div class="_w-pricing-detail-col"><h3 class="f-p-35">Grow</h3>
												<div class="f-p-15">For gyms ready to grow. The tools to put you in
													total control.
												</div>
											</div>
											<div class="_w-pricing-detail-col purple fix-pad"><h3
												class="f-p-35">Promote</h3>
												<div class="f-p-15">For gyms ready to harness the full power of Wodify.
													Automation, marketing &amp; more.
												</div>
											</div>
										</div>
										<div class="_w-pricing-grey-header rel">
											<div class="_w-pricing-detail-col left">
												<div class="f-p-15 _600">MONTHLY PRICE</div>
											</div>
											<div class="_w-pricing-detail-col">
												<div>
													<div class="std-row center-mobile">
														<div id="engagePrice"
															 class="pricing-col-top-price price-engage">$109
														</div>
														<div class="pricing-col-top-currency">/mo</div>
													</div>
													<div class="std-row margin-top-15">
														<div
															class="w-embed w-script">
															<span
															class="hs-cta-wrapper"
															id="hs-cta-wrapper-6d911e0f-61ff-4efa-ac14-10efa605e295">
																<span
															class="hs-cta-node hs-cta-6d911e0f-61ff-4efa-ac14-10efa605e295"
															id="hs-cta-6d911e0f-61ff-4efa-ac14-10efa605e295"
															style={{"visibility": "visible"}} data-hs-drop="true">
																	<a
															id="cta_button_7337548_cc29322e-e990-40b5-a16b-f9b084990288"
															class="cta_button "
															href="https://book.wodify.com/cs/c/?cta_guid=cc29322e-e990-40b5-a16b-f9b084990288&amp;signature=AAH58kGc-441KSPWTLDswS8kLATbwqoiHg&amp;placement_guid=6d911e0f-61ff-4efa-ac14-10efa605e295&amp;click=7fb651ae-4fd9-4d25-9587-819a2756a73c&amp;hsutk=50b18746dfd632cde82d118cd29ea173&amp;canon=https%3A%2F%2Fwww.wodify.com%2Fpricing&amp;utm_referrer=https%3A%2F%2Fwww.wodify.com%2F&amp;portal_id=7337548&amp;redirect_url=APefjpG9HVZ0tJJwtiFKktIl3SM34s5u5SFcRZvdpl1x5wO4aylCYoBibSjnQa8q5xn1boKZKjedkqE1KsARBE2fjurOHKmTLpZk02cH_2QH9GQP1S4ADVswYybC_26srieA1t1-rME7Z3opbE0uoLBDoSqUVfSxPQbYO9c8XWAeW9kquOwxMKldr--qKi-enfi0nv_3xfDlexiGrEj8iqHjPaxCjuJ7DGSH9YqEEwT41K1Ui1TlsLnxbYCPYTs2KzUqlK5JqQpimsPReNw8kPh8QtjRwSvz5g&amp;__hstc=3148015.50b18746dfd632cde82d118cd29ea173.1612398240204.1612398240204.1612401935409.2&amp;__hssc=3148015.3.1612401935409&amp;__hsfp=1552327343"

															cta_dest_link="https://book.wodify.com/en/book-a-demo-wodify-core"
															title="GET ENGAGE"><span style={{"fontSize": "14px"}}><strong>GET ENGAGE</strong></span></a>
														</span>
														</span>
														</div>
													</div>
												</div>
											</div>
											<div class="_w-pricing-detail-col">
												<div>
													<div class="std-row center-mobile">
														<div id="growPrice"
															 class="pricing-col-top-price price-grow">$209
														</div>
														<div class="pricing-col-top-currency">/mo</div>
													</div>
													<div class="std-row margin-top-15">
														<div
															class="w-embed w-script"><span
															class="hs-cta-wrapper"
															id="hs-cta-wrapper-defc3c3a-0c2b-4a2e-8b5c-8d772a22201f"><span
															class="hs-cta-node hs-cta-defc3c3a-0c2b-4a2e-8b5c-8d772a22201f"
															id="hs-cta-defc3c3a-0c2b-4a2e-8b5c-8d772a22201f"
															style={{"visibility": "visible"}} data-hs-drop="true"><a
															id="cta_button_7337548_31495e9e-1e68-48d4-8355-68ad3d04f5c8"
															class="cta_button "
															href="https://book.wodify.com/cs/c/?cta_guid=31495e9e-1e68-48d4-8355-68ad3d04f5c8&amp;signature=AAH58kHKI65iY4E6XA0DEfgCdsQJH2uqKQ&amp;placement_guid=defc3c3a-0c2b-4a2e-8b5c-8d772a22201f&amp;click=484fe329-c5ca-45bb-ac72-c55fcff89965&amp;hsutk=50b18746dfd632cde82d118cd29ea173&amp;canon=https%3A%2F%2Fwww.wodify.com%2Fpricing&amp;utm_referrer=https%3A%2F%2Fwww.wodify.com%2F&amp;portal_id=7337548&amp;redirect_url=APefjpGcyyxaDcJ_IR5n85os6tj9hdXh37PHemSraSfBZfLSmiBSCMHpVBPRE3r1vhZUMxvpDxpq401pviljGubnX6idGLuJzPRzfzxDYJ_Q_hUpFbS9sclhh9qKWEO6WkBn9BTqJUEdY7385sx25XdOEJ1hP5LGWsID3sWJXsIke6FOPHpcHDn5yHkwgEUbWqk7wz_pdkVwywpeRG87nNZES-fgyLwJHcrpllNg5xmuocggKnb84QHHu4eKGzdIr4ztv5y9HEpCCXhFcv18X44Xe4qHRIUbNg&amp;__hstc=3148015.50b18746dfd632cde82d118cd29ea173.1612398240204.1612398240204.1612401935409.2&amp;__hssc=3148015.3.1612401935409&amp;__hsfp=1552327343"

															cta_dest_link="https://book.wodify.com/en/book-a-demo-wodify-core"
															title="GET GROW"><strong><span style={{"fontSize": "14px"}}>GET GROW</span></strong></a></span></span></div>
													</div>
												</div>
											</div>
											<div class="_w-pricing-detail-col purple">
												<div>
													<div class="std-row center-mobile">
														<div id="promotePrice"
															 class="pricing-col-top-price price-promote">$419
														</div>
														<div class="pricing-col-top-currency">/mo</div>
													</div>
													<div class="std-row margin-top-15">
														<div
															class="w-embed w-script"><span
															class="hs-cta-wrapper"
															id="hs-cta-wrapper-3f24825c-6ff6-4883-b8bc-69eda1826de5"><span
															class="hs-cta-node hs-cta-3f24825c-6ff6-4883-b8bc-69eda1826de5"
															id="hs-cta-3f24825c-6ff6-4883-b8bc-69eda1826de5"
															style={{"visibility": "visible"}} data-hs-drop="true"><a
															id="cta_button_7337548_ed8229f8-c737-4470-9613-f9e63b52414f"
															class="cta_button "
															href="https://book.wodify.com/cs/c/?cta_guid=ed8229f8-c737-4470-9613-f9e63b52414f&amp;signature=AAH58kEsc0MI1NSr8EU5rLQjBdJpgbPyNA&amp;placement_guid=3f24825c-6ff6-4883-b8bc-69eda1826de5&amp;click=c8db7506-3669-47a0-b2ad-ed0a34de9bc3&amp;hsutk=50b18746dfd632cde82d118cd29ea173&amp;canon=https%3A%2F%2Fwww.wodify.com%2Fpricing&amp;utm_referrer=https%3A%2F%2Fwww.wodify.com%2F&amp;portal_id=7337548&amp;redirect_url=APefjpHKXbjDsQpG4WZBhr_8qeulReipDzJ1iMhI8joYMsyxfN7Wf_jJ58QNLeRXexnMyFEv9VAPKXxCweekDlwN4QRoFbCeIH05FUVvfLQqDUaZAdpdiNxC0nS6kEglnDi3uvivlcfMEqtD1yUUGidPPJABa_NtyYraC-kgH1Ljf0JDFIGl2wnWzxZveyo_7Y5-krcKSLUOMbqbMUE4b5AruR0dryGRhqrQoA6Yc2RSFDCd6XkvQNecy8x0UprbRZMHavzPymygZfRqhtZSYl0PHmBBzS7OlA&amp;__hstc=3148015.50b18746dfd632cde82d118cd29ea173.1612398240204.1612398240204.1612401935409.2&amp;__hssc=3148015.3.1612401935409&amp;__hsfp=1552327343"

															cta_dest_link="https://book.wodify.com/en/book-a-demo-wodify-core"
															title="GET PROMOTE"><span style={{"fontSize": "14px"}}><strong>GET PROMOTE</strong></span></a></span></span></div>
													</div>
												</div>
											</div>
										</div>
										<div class="_w-pricing-grey-header sticky">
											<div class="_w-pricing-detail-col left"><img
												src="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5b02a2197bb4bb7767c2a1a2_Wodify_Core%402x.png"
												width="109" alt=""/></div>
											<div class="_w-pricing-detail-col"><h3 class="f-p-35">Engage</h3>
												<div class="std-row margin-top-15">
													<div
														class="w-embed w-script"><span
														class="hs-cta-wrapper"
														id="hs-cta-wrapper-6d911e0f-61ff-4efa-ac14-10efa605e295"><span
														class="hs-cta-node hs-cta-6d911e0f-61ff-4efa-ac14-10efa605e295"
														id="hs-cta-6d911e0f-61ff-4efa-ac14-10efa605e295"
														style={{"visibility": "visible"}} data-hs-drop="true"><a
														id="cta_button_7337548_cc29322e-e990-40b5-a16b-f9b084990288"
														class="cta_button "
														href="https://book.wodify.com/cs/c/?cta_guid=cc29322e-e990-40b5-a16b-f9b084990288&amp;signature=AAH58kGc-441KSPWTLDswS8kLATbwqoiHg&amp;placement_guid=6d911e0f-61ff-4efa-ac14-10efa605e295&amp;click=7fb651ae-4fd9-4d25-9587-819a2756a73c&amp;hsutk=50b18746dfd632cde82d118cd29ea173&amp;canon=https%3A%2F%2Fwww.wodify.com%2Fpricing&amp;utm_referrer=https%3A%2F%2Fwww.wodify.com%2F&amp;portal_id=7337548&amp;redirect_url=APefjpG9HVZ0tJJwtiFKktIl3SM34s5u5SFcRZvdpl1x5wO4aylCYoBibSjnQa8q5xn1boKZKjedkqE1KsARBE2fjurOHKmTLpZk02cH_2QH9GQP1S4ADVswYybC_26srieA1t1-rME7Z3opbE0uoLBDoSqUVfSxPQbYO9c8XWAeW9kquOwxMKldr--qKi-enfi0nv_3xfDlexiGrEj8iqHjPaxCjuJ7DGSH9YqEEwT41K1Ui1TlsLnxbYCPYTs2KzUqlK5JqQpimsPReNw8kPh8QtjRwSvz5g&amp;__hstc=3148015.50b18746dfd632cde82d118cd29ea173.1612398240204.1612398240204.1612401935409.2&amp;__hssc=3148015.3.1612401935409&amp;__hsfp=1552327343"

														cta_dest_link="https://book.wodify.com/en/book-a-demo-wodify-core"
														title="GET ENGAGE"><span style={{"fontSize": "14px"}}><strong>GET ENGAGE</strong></span></a></span></span></div>
												</div>
											</div>
											<div class="_w-pricing-detail-col"><h3 class="f-p-35">Grow</h3>
												<div class="std-row margin-top-15">
													<div
														class="w-embed w-script"><span
														class="hs-cta-wrapper"
														id="hs-cta-wrapper-defc3c3a-0c2b-4a2e-8b5c-8d772a22201f"><span
														class="hs-cta-node hs-cta-defc3c3a-0c2b-4a2e-8b5c-8d772a22201f"
														id="hs-cta-defc3c3a-0c2b-4a2e-8b5c-8d772a22201f"
														style={{"visibility": "visible"}} data-hs-drop="true"><a
														id="cta_button_7337548_31495e9e-1e68-48d4-8355-68ad3d04f5c8"
														class="cta_button "
														href="https://book.wodify.com/cs/c/?cta_guid=31495e9e-1e68-48d4-8355-68ad3d04f5c8&amp;signature=AAH58kHKI65iY4E6XA0DEfgCdsQJH2uqKQ&amp;placement_guid=defc3c3a-0c2b-4a2e-8b5c-8d772a22201f&amp;click=484fe329-c5ca-45bb-ac72-c55fcff89965&amp;hsutk=50b18746dfd632cde82d118cd29ea173&amp;canon=https%3A%2F%2Fwww.wodify.com%2Fpricing&amp;utm_referrer=https%3A%2F%2Fwww.wodify.com%2F&amp;portal_id=7337548&amp;redirect_url=APefjpGcyyxaDcJ_IR5n85os6tj9hdXh37PHemSraSfBZfLSmiBSCMHpVBPRE3r1vhZUMxvpDxpq401pviljGubnX6idGLuJzPRzfzxDYJ_Q_hUpFbS9sclhh9qKWEO6WkBn9BTqJUEdY7385sx25XdOEJ1hP5LGWsID3sWJXsIke6FOPHpcHDn5yHkwgEUbWqk7wz_pdkVwywpeRG87nNZES-fgyLwJHcrpllNg5xmuocggKnb84QHHu4eKGzdIr4ztv5y9HEpCCXhFcv18X44Xe4qHRIUbNg&amp;__hstc=3148015.50b18746dfd632cde82d118cd29ea173.1612398240204.1612398240204.1612401935409.2&amp;__hssc=3148015.3.1612401935409&amp;__hsfp=1552327343"

														cta_dest_link="https://book.wodify.com/en/book-a-demo-wodify-core"
														title="GET GROW"><strong><span
														style={{"fontSize": "14px"}}>GET GROW</span></strong></a></span></span></div>
												</div>
											</div>
											<div class="_w-pricing-detail-col purple"><h3 class="f-p-35">Promote</h3>
												<div class="std-row margin-top-15">
													<div
														class="w-embed w-script"><span
														class="hs-cta-wrapper"
														id="hs-cta-wrapper-3f24825c-6ff6-4883-b8bc-69eda1826de5"><span
														class="hs-cta-node hs-cta-3f24825c-6ff6-4883-b8bc-69eda1826de5"
														id="hs-cta-3f24825c-6ff6-4883-b8bc-69eda1826de5"
														style={{"visibility": "visible"}} data-hs-drop="true"><a
														id="cta_button_7337548_ed8229f8-c737-4470-9613-f9e63b52414f"
														class="cta_button "
														href="https://book.wodify.com/cs/c/?cta_guid=ed8229f8-c737-4470-9613-f9e63b52414f&amp;signature=AAH58kEsc0MI1NSr8EU5rLQjBdJpgbPyNA&amp;placement_guid=3f24825c-6ff6-4883-b8bc-69eda1826de5&amp;click=c8db7506-3669-47a0-b2ad-ed0a34de9bc3&amp;hsutk=50b18746dfd632cde82d118cd29ea173&amp;canon=https%3A%2F%2Fwww.wodify.com%2Fpricing&amp;utm_referrer=https%3A%2F%2Fwww.wodify.com%2F&amp;portal_id=7337548&amp;redirect_url=APefjpHKXbjDsQpG4WZBhr_8qeulReipDzJ1iMhI8joYMsyxfN7Wf_jJ58QNLeRXexnMyFEv9VAPKXxCweekDlwN4QRoFbCeIH05FUVvfLQqDUaZAdpdiNxC0nS6kEglnDi3uvivlcfMEqtD1yUUGidPPJABa_NtyYraC-kgH1Ljf0JDFIGl2wnWzxZveyo_7Y5-krcKSLUOMbqbMUE4b5AruR0dryGRhqrQoA6Yc2RSFDCd6XkvQNecy8x0UprbRZMHavzPymygZfRqhtZSYl0PHmBBzS7OlA&amp;__hstc=3148015.50b18746dfd632cde82d118cd29ea173.1612398240204.1612398240204.1612401935409.2&amp;__hssc=3148015.3.1612401935409&amp;__hsfp=1552327343"

														cta_dest_link="https://book.wodify.com/en/book-a-demo-wodify-core"
														title="GET PROMOTE"><span style={{"fontSize": "14px"}}><strong>GET PROMOTE</strong></span></a></span></span></div>
												</div>
											</div>
										</div>
										<div class="_w-pricing-sec">
											<div id="addons-tab" class="_w-pricing-sec-top">
												<div class="f-p-15 _600">ADD-ONS</div>
												<img
													src="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5cfa3e24e1b6581b9c95116c_pricing_arrow.svg"
													alt="" class="_w-pricing-sec-top-arrow"/></div>
											<div class="_w-pricing-sec-detail">
												<div class="_w-pricing-detail-col left">
													<div><a href="/products/core/perform" target="_blank"
															data-gatrack="Pricing Core, Click, Wodify Perform"
															class="f-p-15 _600 pink">Wodify Perform</a><a
														href="/payments" target="_blank"
														data-gatrack="Pricing Core, Click, Wodify Payments"
														class="pricing-table-link"></a></div>
													<div class="f-p-15 m-top-30">Workout tracking system for your
														clients.
													</div>
												</div>
												<div class="_w-pricing-detail-col">
													<div class="_w-pricing-val price-perform">$69/mo</div>
												</div>
												<div class="_w-pricing-detail-col">
													<div class="_w-pricing-val price-perform">$69/mo</div>
												</div>
												<div class="_w-pricing-detail-col purple">
													<div class="_w-pricing-val price-perform">$69/mo</div>
												</div>
											</div>
											<div class="_w-pricing-sec-detail">
												<div class="_w-pricing-detail-col left">
													<div><a href="/products/core/pulse" target="_blank"
															data-gatrack="Pricing Core, Click, Wodify Core"
															class="f-p-15 _600 pink">Wodify Pulse</a><a href="/payments"
																										target="_blank"
																										data-gatrack="Pricing Core, Click, Wodify Payments"
																										class="pricing-table-link"></a>
													</div>
													<div class="f-p-15 m-top-30">Real-time heart rate training for your
														members.
													</div>
												</div>
												<div class="_w-pricing-detail-col">
													<div class="_w-pricing-val price-pulse">$139/mo</div>
												</div>
												<div class="_w-pricing-detail-col">
													<div class="_w-pricing-val price-pulse">$139/mo</div>
												</div>
												<div class="_w-pricing-detail-col purple">
													<div class="_w-pricing-val price-pulse">$139/mo</div>
												</div>
											</div>
											<div class="_w-pricing-sec-detail">
												<div class="_w-pricing-detail-col left">
													<div><a href="/wod-marketplace" target="_blank"
															data-gatrack="Pricing Core, Click, WOD Marketplace"
															class="f-p-15 _600 pink">WOD Marketplace</a></div>
													<div class="f-p-15 m-top-30">Save time with world-class programming
														delivered automatically.
													</div>
												</div>
												<div class="_w-pricing-detail-col">
													<div class="_w-pricing-val price-wmp">From $149/mo</div>
												</div>
												<div class="_w-pricing-detail-col">
													<div class="_w-pricing-val price-wmp">From $149/mo</div>
												</div>
												<div class="_w-pricing-detail-col purple">
													<div class="_w-pricing-val price-wmp">From $149/mo</div>
												</div>
											</div>
											<div class="_w-pricing-sec-detail no-border">
												<div class="_w-pricing-detail-col left">
													<div><a href="/products/core/sites"
															data-gatrack="Pricing Core, Click, Wodiy Sites"
															class="f-p-15 _600 pink">Wodify Sites</a><a href="/payments"
																										target="_blank"
																										data-gatrack="Pricing Core, Click, Wodify Payments"
																										class="pricing-table-link"></a>
													</div>
													<div class="f-p-15 m-top-30">Fully integrated, hosted, and supported
														website to attract more clients.
													</div>
												</div>
												<div class="_w-pricing-detail-col">
													<div class="_w-pricing-val price-sites">$174/mo</div>
												</div>
												<div class="_w-pricing-detail-col">
													<div class="_w-pricing-val price-sites">$174/mo</div>
												</div>
												<div class="_w-pricing-detail-col purple">
													<div class="_w-pricing-val price-sites included">$174/mo</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="_w-pricing-partnerships">
									<div class="sec-wrap"><h2 class="f-p-50 _400">Stand out from the competition. <br/>Offer
										more to your members.</h2></div>
									<div class="sec-wrap-flex m-top-80">
										<div class="_w-pricing-partner"><img
											src="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5d092b1e4054dd6bcfcf0e7f_Wodify_Pulse_Horizontal_Lock-up%402x.png"
											width="339"
											sizes="(max-width: 479px) 78vw, (max-width: 767px) 339px, (max-width: 991px) 41vw, 339px"
											srcset="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5d092b1e4054dd6bcfcf0e7f_Wodify_Pulse_Horizontal_Lock-up%402x-p-500.png 500w, https://assets-global.website-files.com/57e28713ba08d28127821e9c/5d092b1e4054dd6bcfcf0e7f_Wodify_Pulse_Horizontal_Lock-up%402x.png 678w"
											alt=""/><img
											src="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5cf7d7f539f74b9950e8d92d_IMG_Pulse_Screens%402x.png"
											width="341"
											sizes="(max-width: 479px) 78vw, (max-width: 767px) 341px, (max-width: 991px) 41vw, 341px"
											srcset="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5cf7d7f539f74b9950e8d92d_IMG_Pulse_Screens%402x-p-500.png 500w, https://assets-global.website-files.com/57e28713ba08d28127821e9c/5cf7d7f539f74b9950e8d92d_IMG_Pulse_Screens%402x.png 682w"
											alt="" class="m-top-40"/>
											<div class="f-p-30 _600 m-top-40">Wodify Pulse<br/>Heart rate training</div>
											<div class="std-row center-mobile m-top-20 h-60">
												<div id="price-pulse" class="pricing-col-top-price">$139</div>
												<div class="pricing-col-top-currency">/mo</div>
											</div>
											<a href="/products/core/pulse"
											   data-gatrack="Pricing Core, Click, Learn More - Pulse"
											   class="w_link-purple m-top-20 fix-center">Learn More</a></div>
										<div class="_w-pricing-partner-sep"></div>
										<div class="_w-pricing-partner"><img
											src="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5df7b8e0779a00c610ee02b8_Wodify_WOD_Marjetplace_Horizontal_Logo%402x%20(2).png"
											width="206" alt=""/><img
											src="https://assets-global.website-files.com/57e28713ba08d28127821e9c/5df7c18de120c8d671ab48cb_IMG_WOD_Marketplace_Screens%402x%20(2)%20(1).png"
											width="324" alt="" class="m-top-40"/>
											<div class="f-p-30 _600 m-top-40">WOD Marketplace<br/> Fitness
												Programming<br/></div>
											<div class="std-row center-mobile m-top-20 h-60 middle">
												<div class="f-p-18 _600">Price varies</div>
											</div>
											<a href="/wod-marketplace"
											   data-gatrack="Pricing Core, Click, Learn More - Pulse"
											   class="w_link-purple m-top-20 fix-center">Learn More</a></div>
									</div>
								</div>
								<div class="_w-pricing-faqs">
									<div class="sec-wrap faqs"><h3 class="f-p-50 _400">Frequently Asked Questions</h3>
										<div class="_w-pricing-faqs-items w-row">
											<div class="column-16 w-col w-col-6">
												<div class="_w-pricing-faq-item top-border"><h5
													class="_w-pricing-faq-item-tit">Is there any startup cost?</h5>
													<div class="_w-pricing-faqs-desc" style={{"display": "none"}}>We charge a
														small on-boarding fee to get started with Wodify. As part of
														this process we’ll build out your membership templates and
														schedule, import all of your clients and customize your software
														to fit your business like a glove.
													</div>
												</div>
												<div class="_w-pricing-faq-item"><h5 class="_w-pricing-faq-item-tit">Is
													there any training for new Wodify customers?</h5>
													<div class="_w-pricing-faqs-desc" style={{"display": "none"}}>Of course!
														Our premium onboarding process involves a ton of hands-on
														training. After we build out your software, you’ll go through
														our comprehensive online training module, followed by a 1 on 1
														training call with one of our Customer Success Agents to ensure
														you’re fully equipped to run your business more efficiently than
														ever! We also realize that learning new software is a lot to
														take in, so for the first month after training you’ll receive
														tips, tricks, and reminders every couple days via email
														— &nbsp;just because we want to make sure you really get the
														most out of your new investment!
													</div>
												</div>
												<div class="_w-pricing-faq-item"><h5 class="_w-pricing-faq-item-tit">How
													does pricing work for multiple locations?</h5>
													<div class="_w-pricing-faqs-desc" style={{"display": "none"}}>Please <a
														href="https://book.wodify.com/en/book-a-demo-wodify-core"
														class="pricing-faqs-link">book a call</a> with someone from our
														team to talk about special multi-location pricing.
													</div>
												</div>
											</div>
											<div class="column-17 w-col w-col-6">
												<div class="_w-pricing-faq-item top-border-no-mobile"><h5
													class="_w-pricing-faq-item-tit">Are there contracts?</h5>
													<div class="_w-pricing-faqs-desc" style={{"display": "none"}}>Nope! There
														is no minimum commitment and all of our services are month to
														month.
													</div>
												</div>
												<div class="_w-pricing-faq-item"><h5 class="_w-pricing-faq-item-tit">Can
													I move between plans any time?</h5>
													<div class="_w-pricing-faqs-desc" style={{"display": "none"}}>Definitely!
														Our goal is to work in perfect lockstep with your business and
														meet your needs as they arise.
													</div>
												</div>
												<div class="_w-pricing-faq-item"><h5 class="_w-pricing-faq-item-tit">But
													switching from other software is such a pain, right?</h5>
													<div class="_w-pricing-faqs-desc" style={{"display": "none"}}>Not when
														you switch to Wodify! After switching thousands of customers
														over from other software we’ve got the formula down pat. We make
														everything painless with our premium onboarding process.
													</div>
												</div>
												<div class="_w-pricing-faq-item"><h5 class="_w-pricing-faq-item-tit">Is
													there a monthly payment processing fee?</h5>
													<div class="_w-pricing-faqs-desc" style={{"display": "none"}}>With Wodify
														Payments, there is no monthly fee for processing. You just pay a
														modest transaction fee for each payment that you process.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Pricing;