import React, {Component, useContext, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {
	Collapse,Tabs
} from 'antd';
import BreadCrumbContext from "../../../contexts/breadCrump";
import API2 from "../../../components/api2";
import AuthCard from "./bits/authCard"

export default function APIDocumentationAction(props) {
    const AP = API2();
    const [action, setAction] = useState([]);
    const [loading, setLoading] = useState(true);
    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
    	if(!props.menu.length) return true;

        let group;
        let controller;
        let action;
        let endpoint;
        if(props.match.params.action){
            group       = props.match.params.group;
            controller  = props.match.params.controller;
            action      = props.match.params.action;
            endpoint    = group+'/'+'/';
        }else{
            controller  = props.match.params.group;
            action      = props.match.params.controller;
        }

        let menuController;
        for(let i of props.menu){
        	if(group){
				if(i.type=="group" && i.key==group){
                    for(let c of i.controllers) {

                        if(c.type=="controller" && c.key==controller){
                            menuController = c;
                            break;
                        }
                    }
				}
			}else{
                if(i.type=="controller" && i.key==controller){
                    menuController = i;
                    break;
                }
			}
		}

        let menuAction;
		for(let a of menuController.actions){
            if(a.key==action){
                menuAction = a;
                break;
            }
		}

        setBreadCrumbs([
            <span><i className="fas fa-book"/> API</span>,
            <span><i className="fas fa-info-circle"/> Documentation</span>
        ]);

        AP.options(menuController.endpoint)
            .then(function(res){
                for(let a of res.actions){
                    if(a.key==menuAction.key){
                        setAction(a);
                        break;
                    }
                }
                setLoading(false);
            });
    }, [props.menu,props.menuKey]);


    function makeSchemaInner(param){
		return <>
				<div className="flex-1">
					<div className="flex items-baseline">
						<div className="mr-3 bold">{param.name}</div>
						<div className="JSV-rowType"><span className={"sl--"+param.type}>{param.type}{(param.objects)?' ['+param.objects+']':''}</span>
						</div>
					</div>
					<div className="MarkdownViewer markdown-body text-muted text-sm">
						<div className="HtmlViewer">
							<p>{param.description}</p>
						</div>
					</div>
					{param.options &&
					<div className="MarkdownViewer markdown-body text-muted text-sm">
						<div className="HtmlViewer">
							<p>Options:<br/>
								{param.options.map(option =>
                                    <>
										<code>{option}</code><br/>
                                    </>
								)}
							</p>
						</div>
					</div>
					}
				</div>
				{param.required &&
				<div className="text-right text-sm pr-3 max-w-sm">
					<div><span className="JSV-required bold">required</span></div>
				</div>
				}
            </>;
    }
    function makeSchema(data){
        let resp = [];
        for(let param of data){
            let inner = makeSchemaInner(param);

            if(param.type=="array"){
                resp.push(
                    <Collapse style={{backgroundColor:"white",border:"none"}}>
                        <Collapse.Panel header={inner} key="1">
							{param.schema.map(child =>
                                <>
                                    <p>{makeSchemaInner(child)}</p>
                                </>
							)}

                        </Collapse.Panel>
                    </Collapse>
                );
            }else{
				resp.push(
				    <div style={{padding:"10px 0", borderBottom:"1px solid lightgray",display:"flex"}}>
                        {inner}
                    </div>
                );
            }
        }
        return resp;
    }

    return (
		<div>
            {!loading &&
            <div className="HubBlockList">
                <div className="HubBlock HubBlock--text flex is-viewing is-padded">
                    <div className="HubBlock-inner flex-1 w-full">
                        <div className="TextBlock-header">
                            <div className="flex flex-wrap items-center">
                                <div
                                    className={"TextBlock-header-token token--" + action.method.toLowerCase()}>{action.method}</div>
                                <h1 className="TextBlock-header-title wb-bw">{action.endpoint}</h1>
                            </div>
                        </div>
                        <div className="HubBlock-content">
                            <div className="MarkdownViewer markdown-body">
                                <div className="HtmlViewer">
                                    <p>A Zoom account can have one or more users. Use this API to list users on your
                                        account.<br/><br/>
                                        <strong>Scopes:</strong> <code>company admin</code><br/>
                                    </p>
                                    <p>
                                        <strong> <a className="cursor-pointer"
                                                    href="https://marketplace.zoom.us/docs/api-reference/rate-limits#rate-limits">Rate
                                            Limit Label</a>:</strong>
                                        <code>Medium</code>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AuthCard/>

                {false &&
				<>
                    <h2 style={{marginTop: "40px"}}>Request Parameters</h2>

                    <Collapse accordion style={{marginTop: "40px"}}>
                        <Collapse.Panel header="6 Query Parameters" key="2">
                            <div className="JSV us-t u-schemaColors">
                                <div
                                    className="JSV-row JSV-row--0 flex relative py-2 cursor-pointer is-expanded has-validations">
                                    <div className="flex-1">
                                        <div className="flex items-baseline">
                                            <div className="mr-3 bold">status</div>
                                            <div className="JSV-rowType"><span className="sl--string">string</span>
                                            </div>
                                        </div>
                                        <div className="MarkdownViewer markdown-body text-muted text-sm">
                                            <div className="HtmlViewer"><p>User statuses:<br/><code>active</code> -
                                                Users
                                                with an active status.<br/><code>inactive</code> - Users who are
                                                deactivated.<br/><code>pending</code> -
                                                Users with a pending status.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right text-sm pr-3 max-w-sm">
                                        <div></div>
                                        <div className="text-muted"><b>Allowed Values:</b> active, inactive, pending
                                        </div>
                                        <div className="text-muted"><b>default:</b> active</div>
                                    </div>
                                </div>
                                <div
                                    className="JSV-row JSV-row--0 flex relative py-2 cursor-pointer is-expanded has-validations">
                                    <div className="flex-1">
                                        <div className="flex items-baseline">
                                            <div className="mr-3 bold">page_size</div>
                                            <div className="JSV-rowType"><span className="sl--integer">integer</span>
                                            </div>
                                        </div>
                                        <div className="MarkdownViewer markdown-body text-muted text-sm">
                                            <div className="HtmlViewer"><p>The number of records returned within a
                                                single
                                                API call.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right text-sm pr-3 max-w-sm">
                                        <div></div>
                                        <div className="text-muted"><b>default:</b> 30</div>
                                        <div className="text-muted"><b>maximum:</b> 300</div>
                                    </div>
                                </div>
                                <div className="JSV-row JSV-row--0 flex relative py-2 is-expanded">
                                    <div className="flex-1">
                                        <div className="flex items-baseline">
                                            <div className="mr-3 bold">role_id</div>
                                            <div className="JSV-rowType"><span className="sl--string">string</span>
                                            </div>
                                        </div>
                                        <div className="MarkdownViewer markdown-body text-muted text-sm">
                                            <div className="HtmlViewer"><p>Unique identifier for the role. Provide this
                                                parameter if you would like to filter the response by a specific role.
                                                You
                                                can retrieve Role IDs from <a className="cursor-pointer"
                                                                              href="https://marketplace.zoom.us/docs/api-reference/zoom-api/roles/roles">List
                                                    Roles</a> API.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right text-sm pr-3 max-w-sm"></div>
                                </div>
                                <div className="JSV-row JSV-row--0 flex relative py-2 is-expanded">
                                    <div className="flex-1">
                                        <div className="flex items-baseline">
                                            <div className="mr-3 bold">page_number</div>
                                            <div className="JSV-rowType"><span className="sl--string">string</span>
                                            </div>
                                        </div>
                                        <div className="MarkdownViewer markdown-body text-muted text-sm">
                                            <div className="HtmlViewer"><p>The page number of the current page in the
                                                returned records.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right text-sm pr-3 max-w-sm"></div>
                                </div>
                                <div className="JSV-row JSV-row--0 flex relative py-2 is-expanded">
                                    <div className="flex-1">
                                        <div className="flex items-baseline">
                                            <div className="mr-3 bold">include_fields</div>
                                            <div className="JSV-rowType"><span className="sl--string">string</span>
                                            </div>
                                        </div>
                                        <div className="MarkdownViewer markdown-body text-muted text-sm">
                                            <div className="HtmlViewer"><p>Provide a value for this field if you would
                                                like
                                                to see the following attribute in the response of this API call:<br/>
                                            </p>
                                                <p><code>custom_attributes</code>: Returns custom attributes that are
                                                    associated with the user.<br/><code>host_key</code>: Returns <a
                                                        className="cursor-pointer"
                                                        href="https://support.zoom.us/hc/en-us/articles/205172555-Using-your-host-key"
                                                        target="_blank">host key</a> of the user.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right text-sm pr-3 max-w-sm"></div>
                                </div>
                                <div className="JSV-row JSV-row--0 flex relative py-2 is-expanded">
                                    <div className="flex-1">
                                        <div className="flex items-baseline">
                                            <div className="mr-3 bold">next_page_token</div>
                                            <div className="JSV-rowType"><span className="sl--string">string</span>
                                            </div>
                                        </div>
                                        <div className="MarkdownViewer markdown-body text-muted text-sm">
                                            <div className="HtmlViewer"><p>The next page token is used to paginate
                                                through
                                                large result sets. A next page token will be returned whenever the set
                                                of
                                                available results exceeds the current page size. The expiration period
                                                for
                                                this token is 15 minutes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right text-sm pr-3 max-w-sm"></div>
                                </div>
                            </div>
                        </Collapse.Panel>
                    </Collapse>
				</>
                }

				{action.parameters &&
                <>
					<h2 style={{marginTop: "40px"}}>Request Parameters</h2>
					<Collapse accordion style={{marginTop: "40px"}}>
						{action.parameters.path &&
						<Collapse.Panel header="Path Parameters" key="path_params">
							<pre>
								{makeSchema(action.parameters.path)}
							</pre>
						</Collapse.Panel>
						}
						{action.parameters.query &&
						<Collapse.Panel header="Query Parameters" key="query_params">
							<div className="JSV us-t u-schemaColors">
								{makeSchema(action.parameters.query)}
							</div>
						</Collapse.Panel>
						}
					</Collapse>
                </>
				}

                {action.body &&
				<>
                    <h2 style={{marginTop: "40px"}}>Request Body</h2>
                    <Collapse accordion style={{marginTop: "40px"}}>
                        <Collapse.Panel header="Example" key="body_example">
							<pre>
								{JSON.stringify(action.body_example, null, 4)}
							</pre>
						</Collapse.Panel>
                        <Collapse.Panel header="Schema" key="body_schema">
                            <div className="JSV us-t u-schemaColors">
                                {makeSchema(action.body)}
                            </div>
                        </Collapse.Panel>
                    </Collapse>
				</>
                }

				{action.responses &&
                <>
					<h2 style={{marginTop: "40px"}}>Responses</h2>
					<div>

						<Tabs defaultActiveKey="1" className="ant-tabs-box">
							{action.responses && action.responses.map(response =>
								<Tabs.TabPane tab={response.name} key={response.type}>
									<Collapse accordion>
										{response.example &&
										<Collapse.Panel header="Example" key="1">
											<pre className="line-numbers language-json"
												 data-line="">{JSON.stringify(response.example, null, 4)}</pre>
										</Collapse.Panel>
										}
										{response.schema &&
										<Collapse.Panel header="Schema" key="2">
											<div className="JSV us-t u-schemaColors">
												{makeSchema(response.schema)}
											</div>
										</Collapse.Panel>
										}
										{response.description &&
                                        <div style={{padding:10}}>{response.description}</div>
										}
									</Collapse>
								</Tabs.TabPane>
							)}
						</Tabs>
					</div>
                </>
				}
            </div>
            }
		</div>
    );
}

