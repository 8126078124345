import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from 'antd';
import {
    Form,Input,
} from 'antd';

export default function Insurance(props) {

    const [form] = Form.useForm();
    const history = useHistory();


    function getStartedClicked(data){
        history.push({
            pathname: '/signUp',
            state: data
        });
    }


    return (
        <div className="content_inner_padded" >
            <div className="container">

                <div className="ibn ibn-sb sec">
                    <div className="ebn ud-bn ud-pt obg uix-alter ebn-p321x sec">
                        <div className="cpnw cov xg">
                            <div className="main">
                                <div className="cpn al-g cl-g bg-g wd-g">
                                    <div className="title">
                                        <h1>Certificate in Applied Sports Nutrition
                                            <div className="breadcrumb"><span className="separator"> &gt; </span><span
                                                className="current">Insurance And Registration</span></div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg hs-bg is-basic" data-height="auto" style={{"background-image":"url(https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/adversary-banner.jpg)"}}>
                            <div className="over tpb op01" style={{"opacity":"0.01"}}></div>
                        </div>
                    </div>
                </div>

                <div className="site-wrap ipg iopg ipg-insurance-and sec">
                    <div className="wrap wrap-wd wrap-page gb-sd no-aside sec">
                        <div className="icontent sec">
                            <article className="epc epg">
                                <div className="ientry">
                                    <div className="section sec-sc sec basic row-xd sec-rg light sec-blue-strip ud-pad ud-bgc sec-a349792z" style={{paddingTop:65, paddingBottom:65, backgroundColor:"#01a5f8"}}>
                                        <div className="main">
                                            <div className="content">
                                                <p style={{textAlign:"center"}}>
                                                    <Link to="/about" className="button btn-fill btn-normal">
                                                        <div >Registration</div>
                                                    </Link>
                                                    <a href="https://www.sportsnutritionistregistry.com/" className="button btn-normal" target="_blank">
                                                        <span>Find A Nutritionist</span>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="section sec-sc sec basic row-xd sec-rg sec-sport ud-pad tpd-s bpd-m ud-bgx ud-bgc plx-right plx-aside sec-a756443z">
                                        <div className="main">
                                            <div className="content">
                                                <div className="columns row-sc row">
                                                    <div className="eclm clm-sc g6 ec-content-left">
                                                        <div className="content">
                                                            <h2>Registration</h2>
                                                            <p>After completing the Certificate in Applied Sports
                                                                Nutrition you will become eligible to register with the
                                                                Sports Nutrition Association as an Accredited Sports
                                                                Nutritionist. As an Accredited Sports Nutritionist, you
                                                                become eligible to insure yourself for sports nutrition
                                                                programming, meal plans, and supplement program for your
                                                                clients. This is the only non-dietetic pathway to become
                                                                an Accredited Sports Nutritionist, please refer to the
                                                                Body’s latest Insurance and Registration Position
                                                                Statement for any more information.</p>
                                                            <p>Registration as a Sports Nutritionist is US$399/year
                                                                (paid either upfront or a monthly option is
                                                                available).</p>
                                                            <p>Continued education is extremely important however there
                                                                are no extra CECs. It is our belief as our members it is
                                                                our responsibility to keep you up to date with the
                                                                content and actually receive something for your
                                                                registration fees, and as such at the Sports Nutrition
                                                                Association we invest in the future of its members,
                                                                providing course refreshers to our members annually. For
                                                                members who deregister, reinstatement of registration is
                                                                available and the reinstatement process is assessed on a
                                                                case by case process, with fees ranging from US$599 for
                                                                full re-enrolment in the certificate depending on the
                                                                period of time they were unregistered.</p>
                                                            <a href="https://www.sportsnutritionassociation.com/product/annual-registration/"
                                                               className="button btn-normal"><span>Register Today </span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="plx-bg in-right" style={{backgroundImage:"url(https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/registration.jpg)"}}>
                                            <div className="over-sec ov-bk" style={{opacity:"0.01"}}></div>
                                        </div>
                                    </div>
                                    <div className="section sec-sc sec basic row-xd sec-rg sec-sport sec-pain light ud-pad tpd-l bpd-l ud-bgx ud-bgc plx-left plx-aside sec-a724440z"style={{paddingTop:290, paddingBottom:295, backgroundColor:"#01a5f8"}}>
                                        <div className="main">
                                            <div className="content">
                                                <div className="columns row-sc row">
                                                    <div className="eclm clm-sc g6 ec-content-right">
                                                        <div className="content">
                                                            <h2>Insurance</h2>
                                                            <p>We recommend partnering with Arthur J Gallagher as they
                                                                are the only insurer who works with us on CPD audit
                                                                compliance which is a high priority for insurance being
                                                                valid.</p>
                                                            <p>The Sports Nutrition Association uses Arthur J Gallagher
                                                                insurers for cover of its members, depending on the
                                                                scope of their business activities. Bodybuilding,
                                                                powerlifting, and strength &amp; conditioning are all
                                                                included activities in the insurance.</p>
                                                            <p>All insurance is assessed and awarded individually on a
                                                                case by case instance, so no fixed quotes can be
                                                                provided. The approximate beginning price for all
                                                                Accredited Sports Nutritionists and PTs/Coaches
                                                                (covering all training scope as well) starts around the
                                                                A$280/year mark for nutrition only and from A$450/year
                                                                for comprehensive training and nutrition policies.</p>
                                                            <a href="https://www.sportsnutritionassociation.com/contact/"
                                                               className="button btn-normal"><span>Learn More </span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="plx-bg in-left" style={{backgroundImage:"url(https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/pt-running.jpg)"}}>
                                            <div className="over-sec ov-bk" style={{opacity:"0.01"}}></div>
                                        </div>
                                    </div>
                                    <div className="section sec-sc sec basic row-xd sec-rg no-udx">
                                        <div className="main">
                                            <div className="content">
                                                <h4 style={{textAlign:"center"}}>Educational Providers,
                                                    Programs, &amp; Pathway Accreditation Validity within Australia,
                                                    USA, UK and NZ.</h4>
                                                <span className="gaps gap-sc"></span>
                                                <p><img className="alignnone wp-image-491 size-full"
                                                        src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/07/association-pathway-chart.jpg"
                                                        alt="" width="2536" height="1784"/></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section sec-sc sec basic row-xd sec-rg sec-sport ud-pad tpd-s bpd-m ud-bgc plx-bottom sec-a755192z" style={{paddingTop:100, paddingBottom:140, backgroundColor:"#f9f9f9"}}>
                                        <div className="main">
                                            <div className="content">
                                                <h2 style={{textAlign:"center"}}>Accreditation Eligibility</h2>
                                                <div id="accordion-44" className="accordion-area ">
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name active"
                                                        href="#accordion-7706"><span className="left-arrow"></span>Precision
                                                        Nutrition (and any other certificate 3 or 4 in nutrition or
                                                        dietetic coaching)</a>
                                                        <div id="accordion-7706"
                                                             className="accordion-section-content open"
                                                             style={{display:"block"}}>
                                                            <div className="content">
                                                                <p>This covers the above and the nutrition for Personal
                                                                    Trainers programs that range from 1/2 day CEC
                                                                    (fitness) Seminars/Courses through to, 3-month
                                                                    programs. These are often (but not always) centred
                                                                    around weight management through behaviours.
                                                                    Depending on the program 0 -1 module of the
                                                                    certificate will be eligible for RPL Credit.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-7989"><span
                                                        className="left-arrow"></span>The ISSN Exams
                                                        (CISSN &amp; SNS)</a>
                                                        <div id="accordion-7989" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Programs inclusive of this such as but not limited
                                                                    too Recomp and MP, have merit via their ISSN
                                                                    inclusion, but are fixed systems that only allow
                                                                    education within said platforms, I.e. muscle
                                                                    increases with ‘x’ weight on your 1rm deadlift, and
                                                                    nutrient timing is essential for all hypertrophy.
                                                                    Rather than educating the mechanics of how these
                                                                    things work, and other physiological processes via
                                                                    furthermore in-depth foundational education thus
                                                                    creating a reliance on these fixed systems. The ISSN
                                                                    component is guaranteed to be recognised for 1-2
                                                                    modules of RPL Credit for the certificate
                                                                    program.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-4599"><span
                                                        className="left-arrow"></span>Clean Health (Poliquin, Bio Print
                                                        etc.)</a>
                                                        <div id="accordion-4599" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Are fixed programs like those mentioned above, but do
                                                                    not include the background education from the ISSN
                                                                    Journal, nor any measurable assessment like the ISSN
                                                                    exams. These are not recognised, and hormonal
                                                                    manipulation of nutrition, or somatotype nutrition
                                                                    is not a part of an Accredited Sports Nutritionists’
                                                                    scope, or any other entry-level allied health or
                                                                    fitness professional for that matter. These programs
                                                                    receive no credit for RPL.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-5472"><span
                                                        className="left-arrow"></span>Mac Nutrition Uni(versal)</a>
                                                        <div id="accordion-5472" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Is not an accredited university (unlike the name
                                                                    implies), but is a private education provider and
                                                                    provides a very solid framework for global nutrition
                                                                    education. It covers many topics effectively at a
                                                                    fundamental level. It covers some sports, gen pop,
                                                                    and even some chronic disease. Unfortunately however
                                                                    because it is so broad and does touch on chronic
                                                                    disease intervention within Australia the minimum
                                                                    requirement for any registered professional to work
                                                                    with chronic disease is a 4-year minimum, and the
                                                                    Sports Nutrition education is not in-depth enough to
                                                                    meet the minimum compliance of the Certificate.
                                                                    Grads receive 3 modules RPL Credit into the
                                                                    Certificate from MNU.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-9074"><span
                                                        className="left-arrow"></span>Bachelor in Health Sciences
                                                        (nutrition variants)</a>
                                                        <div id="accordion-9074" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Depending on the university you study at and how you
                                                                    major, these programs provide in-depth education and
                                                                    clinical practise in specialist population
                                                                    management of nutrition. This mainly focuses on
                                                                    Chronic Disease prevention and sometimes treatment
                                                                    (depending on the degree and level). Some Sports
                                                                    Nutrition electives are available but often so
                                                                    rarely. Grads of these programs are eligible to
                                                                    register as Nutritionists, however,
                                                                    Sports &amp; Body-Composition is not included in
                                                                    their covered Activities, and as such further
                                                                    specific study is required. Graduates of these
                                                                    programs receive 50-70% of the Certificate Program
                                                                    credited through RPL pending academic transcript
                                                                    submission.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-8771"><span
                                                        className="left-arrow"></span>Accredited Practising
                                                        Dietitians</a>
                                                        <div id="accordion-8771" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>These are graduates of the Masters in Dietetics
                                                                    University Programs within Australia. They are
                                                                    registered with the DAA (Dietitians Association of
                                                                    Australia) and are covered for all populations and
                                                                    clinical treatment of specific chronic disease. Like
                                                                    the undergrad health science nutritionists, sports
                                                                    subjects as electives re the only option to sports
                                                                    specific study during the program so education here
                                                                    may be limited. Total study time of 5 years
                                                                    full-time.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-7110"><span
                                                        className="left-arrow"></span>Accredited Sports Dietitians</a>
                                                        <div id="accordion-7110" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>These are dietitians who have completed their
                                                                    master’s program and have since spent 2 years
                                                                    educating themselves in the field of Sports
                                                                    Nutrition, a total study time of 7-8 years
                                                                    full-time. This is the highest level of
                                                                    accreditation.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-1427"><span
                                                        className="left-arrow"></span>Certificate in Applied Sports
                                                        Nutrition</a>
                                                        <div id="accordion-1427" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Is the entry-level qualification that provides
                                                                    provisional accreditation (which lasts for up to 3
                                                                    years) for those members without a relevant health
                                                                    science or sports science tertiary degree (either a
                                                                    Gdip or Bachelors as a minimum). It thoroughly
                                                                    covers the foundations of Applied Sports Nutrition
                                                                    Practise, with no clinical aspects of chronic
                                                                    disease treatment &amp; management, while providing
                                                                    thorough training on the screening referral triage
                                                                    process to allied health specialists where health
                                                                    risk is present. For those members with no prior
                                                                    study in order to achieve full accreditation, they
                                                                    are required to enrol into either a graduate diploma
                                                                    program or relevant university program. The body has
                                                                    been fortunate enough to provide this provisional
                                                                    timeline to allow its members to establish
                                                                    experience practising and thus enabling them to
                                                                    ascertain whether or not this is a long term career
                                                                    for them without accruing tens of thousands of
                                                                    dollars in student loans, or committing to years of
                                                                    study before finding out this isn’t the career path
                                                                    for them.</p>
                                                                <p>NB* All graduates of the Certificate are guaranteed
                                                                    entry into the IOPN Graduate Diploma Program.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-3922"><span
                                                        className="left-arrow"></span>Graduate Diplomas in Applied
                                                        Sports Nutrition (The IOPN &amp; IOC)</a>
                                                        <div id="accordion-3922" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Is the complete level of the minimum standard for
                                                                    full accreditation as an Accredited Sports
                                                                    Nutritionist. These programs are extensively
                                                                    thorough in their specific coverage of Sports
                                                                    Performance, body composition, and energy
                                                                    availability metabolism. These programs and
                                                                    instituters are of the highest calibre in Sports
                                                                    Nutrition internationally but do not provide any
                                                                    clinical expertise in treating or managing chronic
                                                                    disease. Individuals who complete either of these
                                                                    programs receive full credit and RPL for their
                                                                    accreditations only need to complete the health risk
                                                                    assessment and dietetic referral modules to comply
                                                                    with Australian Standards.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="section sec-sc sec basic row-xd sec-rg sec-sport ud-pad tpd-s bpd-m plx-bottom sec-a362824z">
                                        <div className="main">
                                            <div className="content">
                                                <h2 style={{textAlign:"center"}}>Frequently Asked Questions</h2>
                                                <div id="accordion-79" className="accordion-area ">
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name active"
                                                        href="#accordion-6121"><span className="left-arrow"></span>Can I
                                                        call myself a Sports Nutritionist without being registered? </a>
                                                        <div id="accordion-6121"
                                                             className="accordion-section-content open"
                                                             style={{display:"block"}}>
                                                            <div className="content">
                                                                <p>Short answer is no. Sports Nutritionist, and
                                                                    Accredited Sports Nutritionist are protected terms
                                                                    that in a professional setting carry with them
                                                                    defined liability and responsibility for best
                                                                    practise and duty of care.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-3345"><span
                                                        className="left-arrow"></span>What are the benefits of being
                                                        registered? </a>
                                                        <div id="accordion-3345" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Additional member area access on the sports nutrition
                                                                    association website which includes; risk screening
                                                                    tools, EA and nutritional calculators, discount to
                                                                    recommended programs &amp; resources, Annual CPD
                                                                    (continuing professional development) which is
                                                                    conducted by the Association for the members, and
                                                                    certificate course content updates/refreshers,
                                                                    discount to the Sports Nutrition United States
                                                                    events, and most importantly eligibility to Register
                                                                    and Insure as a Sports Nutritionist.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-6251"><span
                                                        className="left-arrow"></span>How much does registration cost?
                                                    </a>
                                                        <div id="accordion-6251" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>US$399/year or US$39 per month</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-2866"><span
                                                        className="left-arrow"></span>What do I need to do to maintain
                                                        my registration? </a>
                                                        <div id="accordion-2866" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>Annual CPD (delivered in October), and practise in
                                                                    line with the body’s Practising Standards and Scope
                                                                    Documents.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-section"><a
                                                        className="accordion-section-title name" href="#accordion-2018"><span
                                                        className="left-arrow"></span>What happens if my registration
                                                        lapses? </a>
                                                        <div id="accordion-2018" className="accordion-section-content">
                                                            <div className="content">
                                                                <p>If it lapses for up to 0-6 months simply paying the
                                                                    outstanding balance and updating the body and
                                                                    insurer of any relevant information is all that is
                                                                    required. For any period greater than 6-months a
                                                                    mandatory refresher course of $899 (smaller than the
                                                                    certificate) is required to be completed prior to
                                                                    being eligible to renew. This ensures that you are
                                                                    practising with all relevant principles and
                                                                    standards in line with the body’s current
                                                                    stances.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section sec-sc sec basic row-xd sec-rg light sec-blue-strip ud-pad ud-bgc sec-a524825z" style={{paddingTop:65, paddingBottom:65, backgroundColor:"#01a5f8"}}>
                                        <div className="main">
                                            <div className="content">
                                                <p style={{textAlign:"center"}}>
                                                    <Link to="/about" className="button btn-fill btn-normal">
                                                        <div >Registration</div>
                                                    </Link>
                                                    <a href="https://www.sportsnutritionistregistry.com/" className="button btn-normal" target="_blank">
                                                        <span>Find A Nutritionist</span>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p>&nbsp;</p>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>



            </div>

        </div>
    );
}