import React, {useEffect, useState} from 'react';

import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import countryList from "react-select-country-list";
import { Link } from "react-router-dom";
import TermsAndConditionsText from "../pages/TermsAndConditionsText";

import { useHistory } from "react-router-dom";
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
	Card,
    Modal,
	Spin,
    Alert
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import useToken from "../hooks/useToken";
import API2 from "../components/api2";
import {notification} from "antd/lib/index";
import moment from "moment/moment";

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const { Meta } = Card;

export default function Registerz(props) {

    const [form] = Form.useForm();
    const { user, setUser } = useToken();
    const AP = API2();
    const [isLoading, setIsLoading] = useState(true);
    const [plans, setPlans] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [modelOpen, setModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const history = useHistory();

    useEffect(() => {
        if(history.location.state){
            form.setFieldsValue(history.location.state);
		}

        AP.get('/plans?for=member')
            .then(function (res) {

                for (let plan of res.data) {
                    for (let payment of plan.payments) {
                        payment.discount = 0;
                    }
                }

                setPlans(res.data);

                let p = props.match.params.plan;
                if(p) {
                    for (let plan of res.data) {
                        if (plan.name == p) setSelectedPlan(plan.id);
                    }
                }else{
                    setSelectedPlan(res.data[0].id);
                }

				setTimeout(function(){
                    setIsLoading(false);
				},10);

            });
    }, []);



	const [cardCvc,    setCardCvc]    = useState('');
	const [cardExpiry, setCardExpiry] = useState('');
	const [cardFocus,  setCardFocus]  = useState('');
	const [cardName,   setCardName]   = useState('');
	const [cardNumber, setCardNumber] = useState('');

    const [discountPlan, setDiscountPlan] = useState('');
    const [couponMsg, setCouponMsg] = useState('');


    const handleSubmit = async (data) => {
        setIsSaving(true);
        data.plan = selectedPlan;

        AP.post('/registerInternal',data)
            .then(function(res){

                notification.success({
                    message: `Saved`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });

                setIsSaving(false);

				window.location.replace("/dashboard");

            })
            .catch(function(res){
                setIsSaving(false);
                if(res.response.data.errors) {
                    let errorList = {};
                    for (let error of res.response.data.errors) {
                        if (!errorList[error.input]) errorList[error.input] = [];
                        errorList[error.input].push(error.message);
                    }
                    for (let [input, errors] of Object.entries(errorList)) {
                        form.setFields([
                            {
                                name: input,
                                errors: errors,
                            },
                        ]);
                    }
                }
            });
    };

	const handleInputFocus = (e) => {
        if(e.type == "focus") {
            let id = e.target.id.substr(13);
            setCardFocus(id);
        }else{
            setCardFocus(null);
        }
	}

	function onFormChange(changedFields, allFields){

		let name  = changedFields[0].name[0];
		let value = changedFields[0].value;

		if(name=='cardcvc')    setCardCvc(value);
		if(name=='cardexpiry') setCardExpiry(value);
		if(name=='cardname')   setCardName(value);
		if(name=='cardnumber') setCardNumber(value);
	}

	function checkCoupon(){
		let coupon = form.getFieldValue("coupon");

        for(let p of plans){
            for(let pa of p.payments) {
                pa.discount = 0;
            }
        }

		if(!coupon){
			setPlans(plans);
            setCouponMsg("");
			return;
        }

        AP.get('/coupon/check/'+coupon)
            .then(function (res) {

            	if(res.total_records){

            		for(let plan of plans){

            			for(let pcm of res.data[0].plan_coupon_maps){
            				if(pcm.plan_id == plan.id){
                                for(let payment of plan.payments){
									let d;
									if(res.data[0].type == 'fixed'){
										d = res.data[0].discount;
									}
									else{
										d = payment.amount*(res.data[0].discount/100);
									}
									payment.discount = d;
                                }
							}
						}
					}

                    setCouponMsg(<span style={{color:"green"}}><i className="fas fa-check-circle"></i></span>);
				}else{
                    setCouponMsg(<span style={{color:"red"}}><i className="fas fa-times-circle"></i></span>);
				}

                setPlans(plans);
            });

	}

    return(
    	<>
			<Form

				form={form}
				name="register"
				onFinish={handleSubmit}
				initialValues={{
					residence: ['zhejiang', 'hangzhou', 'xihu'],
					prefix: '86',
				}}
				scrollToFirstError
				style={{ margin:"auto"}}
				onFieldsChange={onFormChange}
			>

                <h3  style={{paddingTop:50}}>Nutritionist Details</h3>

                <div>
                    <Form.Item
                        name="company"
                    >
                        <Input placeholder="Company" />
                    </Form.Item>
                </div>
                <div>
                    <Form.Item
                        name="accreditation-level"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your accreditation level!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Accreditation Level"
                        >

                            <Select.Option key={"provisional"} value={"provisional"}>
                                Provisional
                            </Select.Option>
                            <Select.Option key={"open"} value={"open"}>
                                Open
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </div>


                <h3  style={{paddingTop:50}}>Payment Details</h3>
                <div>Please choose between the following two yearly registration options:</div>
                <div className="ant-row demo-row" style={{gap:20}}>
                    <div style={{flex:1, minWidth:400}}>
                        <Form.Item
                            name="plan"
                            rules={[
                                {
                                    required:true,
                                    message:'Required!',
                                    whitespace:true,
                                    type: "number"
                                }
                            ]}
                        >
                            {isLoading && <Spin size="small"/>
                            ||
                            <Select
                                placeholder={<div style={{top:16, position:"relative"}}>Select A Plan</div>}
                                onChange={function(e){setSelectedPlan(e)}}
                                className="autoHeight"
                            >
                                {plans.map(plan => {

                                    if(plan.payments[0].billing_duration == 'month'){
                                        return <Option key={"plan_option_" + plan.id} value={plan.id}
                                                       style={{height: "60px !important"}}>
                                            <div style={{padding: 20, display: "flex", height: "60px"}}>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginRight: "10px",
                                                        display: "flex",
                                                        justifyItems: "center",
                                                        height: 40
                                                    }}
                                                >
                                                    <i className={"fad fa-calendar-" + ((plan.payments[0].billing_duration === "month") ? "alt" : "day")}
                                                       style={{fontSize: 30, width: 80}}/>
                                                    <Meta title={<>
                                                        <span>12 Monthly Payments of </span>
                                                        <span>${Math.round((plan.payments[0].amount - plan.payments[0].discount)) / 100} </span>
                                                        <span
                                                            style={{fontSize: "10px"}}>{plan.payments[0].currency.toUpperCase()} </span>
                                                    </>} description={plan.description}/>
                                                </div>
                                            </div>
                                        </Option>;
                                    }
                                    else {
                                        return <Option key={"plan_option_" + plan.id} value={plan.id}
                                                       style={{height: "60px !important"}}>
                                            <div style={{padding: 20, display: "flex", height: "60px"}}>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginRight: "10px",
                                                        display: "flex",
                                                        justifyItems: "center",
                                                        height: 40
                                                    }}
                                                >
                                                    <i className={"fad fa-calendar-" + ((plan.payments[0].billing_duration === "month") ? "alt" : "day")}
                                                       style={{fontSize: 30, width: 80}}/>
                                                    <Meta
                                                        title={<>
                                                            <span>Upfront for 12 months. ${Math.round((plan.payments[0].amount - plan.payments[0].discount)) / 100} </span>
                                                            <span style={{fontSize: "10px"}}>{plan.payments[0].currency.toUpperCase()} </span>
                                                        </>}
                                                        description={<div style={{position:"relative", top:-14, textAlign:"center"}}>{plan.description}</div>}
                                                    />
                                                </div>
                                            </div>
                                        </Option>;
                                    }
                                })}

                            </Select>
                            }
                        </Form.Item>
                    </div>
                    <div style={{flex:1,minWidth:300}}>
                        <div style={{display:"flex"}}>
                            <div style={{position:"relative",flex:2}}>
                                <Form.Item
                                    name="coupon"
                                    labelCol = {{
                                        xs: {span: 24},
                                        sm: {span: 10}
                                    }}
                                >
                                    <Input placeholder="Coupon" onKeyPress={function(event){
                                        if (event.key === 'Enter') {
                                            checkCoupon();
                                            event.preventDefault();
                                            return false;
                                        }
                                    }}/>
                                </Form.Item>
                                <span style={{position:"absolute", right:10, top:9, fontSize:30}}>{couponMsg}</span>
                            </div>
                            <Button type="primary"  shape="round" size={"large"} onClick={() => checkCoupon()} style={{margin:9}}>Apply</Button>
                        </div>
                    </div>
                </div>
                <Alert message="Please note the yearly registration subscription is set to auto-renew, any changes to this can be made through your account." type="warning" />


                <div style={{padding:"60px 20px 40px 20px",textAlign:"center"}}>Due to the way billing is processed.<br/>You are required to reenter your card details. You can use the same details entered while enrolling as a student</div>
                <Form.Item>

                    <Row gutter="20">
                        <Col className="zoomed">
                            <Cards
                                cvc={cardCvc}
                                expiry={cardExpiry}
                                focused={cardFocus}
                                name={cardName}
                                number={cardNumber}
                            />


                        </Col>




                        <Col flex="1">
                            <div style={{paddingTop:"20px"}}>
                                <Form.Item
                                    name="cardnumber"
                                    rules={[
                                        {
                                            required:true,
                                            message:'Required!',
                                            whitespace:true
                                        }
                                    ]}
                                >
                                    <Input
                                        type="tel"
                                        placeholder="Number"
                                        onFocus={handleInputFocus}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="cardname"
                                    rules={[
                                        {
                                            required:true,
                                            message:'Required!',
                                            whitespace:true
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder="Name"
                                        onFocus={handleInputFocus}
                                    />
                                </Form.Item>

                                <Row gutter="20">
                                    <Col style={{width:"125px"}}>

                                        <Form.Item
                                            name="cardexpiry"
                                            rules={[
                                                {
                                                    required:true,
                                                    message:'Required!',
                                                    whitespace:true
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder="Expiry"
                                                onFocus={handleInputFocus}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col flex={"1"}>

                                        <Form.Item
                                            name="cardcvc"
                                            rules={[
                                                {
                                                    required:true,
                                                    message:'Required!',
                                                    whitespace:true
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder="CVC"
                                                onFocus={handleInputFocus}
                                                onBlur={handleInputFocus}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Form.Item>


                <div className="ant-row demo-row">
					<Form.Item style={{flex:1}}
						name="agreement"
						valuePropName="checked"
						rules={[
							{
								validator: (_, value) =>
									value ? Promise.resolve() : Promise.reject('Accepting agreement is required'),
							},
						]}
						{...tailFormItemLayout}
					>
						<Checkbox>
							<span>I have read and agree to the website </span>
							<a onClick={()=>setModalOpen(true)} style={{color:"blue"}}>
								terms and conditions
							</a>
						</Checkbox>
					</Form.Item>


					<div>
						<Button type="primary" htmlType="submit"  shape="round" size={"large"} loading={isSaving} style={{float:"right"}}>{(isSaving)?'Saving':'Register Now'}</Button>
					</div>
				</div>
			</Form>

            <Modal
                visible={modelOpen}
                title={<h3>Terms and Conditions - Sports Nutrition Group</h3>}
                onCancel={()=>setModalOpen(false)}
				//centered={true}
                width={"80vw"}
                footer={<Button type="primary" size="small" shape="round" onClick={()=>setModalOpen(false)}>Close</Button>}
            >
				<div  style={{maxHeight:"70vh", overflow:"auto"}}>
                	<TermsAndConditionsText/>
				</div>
            </Modal>
		</>
    )
}

