import React, {useEffect, useState} from 'react';

import Upload from "../components/Uploader";

import { useHistory } from "react-router-dom";
import {
    Form,
    Input,
    Select,
    Checkbox,
    Button,
    Alert
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';

import API2 from "../components/api2";
import {notification} from "antd/lib/index";

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


export default function DeregisterForm(props) {


    const AP = API2()

    let [form] = Form.useForm();
    if(props.form){
        form = props.form;
    }

    const [subscription,setSubscription] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [files, setFiles] = useState([]);
    const history = useHistory();


    useEffect(() => {

        if(!props.subscription) {
			let url;
			if (props.subscriptionId) {
				url = '/subscription/' + props.subscriptionId + '/deregister';
			} else {
				url = '/subscription/deregister';
			}

			AP.get(url).then(function (resp) {
				form.setFieldsValue(resp);
				setSubscription(resp.subscription);
			});
		}else{
			form.setFieldsValue(props.deregData);
            setSubscription(props.subscription);
        }

    }, []);

    function saveForm(data){
        setIsSaving(true);

        let url;
        if(subscription && subscription.id){
            url = '/subscription/'+subscription.id+'/deregister';
        }else{
            url = '/subscription/deregister';
        }

        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            props.onFinish();
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });;
    }

    let paid = 0;
	let paidAmount = 0;
	let unpaid = 0;
	let unpaidAmount = 0;
    if(subscription) {

        for(const invoice of subscription.invoices){
            if(invoice.paid){
				paid++
				paidAmount += invoice.fullAmount;
            }else{
				unpaid++
				unpaidAmount += invoice.fullAmount;
            }
        }
    }

    return(
    	<>
            <h3>Deregistration Form</h3>

            <div>

                <p>Deregistration is the process to end your SNA Membership. To start the process you will be required to:</p>
                <ol style={{listStyle:"auto",paddingLeft:30}}>
                    <li>Read and accept the warning</li>
					<li>Answer the questions</li>
					<li>Read and accept the waiver</li>
                </ol>


            </div>



            <Form
                {...formItemLayout}
                form={form}
                name="deregister"
                onFinish={saveForm}
                scrollToFirstError
                style={{ margin:"auto",paddingBottom:70,fontSize:"18px"}}
            >

                <h5 style={{paddingTop:50}}>Warning</h5>
                <div style={{paddingLeft:50}}>
                    <p>If you are practising you should have both registration and insurance to protect yourself and your clients as both are typically legal requirements under 'reasonable duty of care' in the majority of regions we operate in.
                        By both holding yourself out to be holding a professional registration or providing services that an existing precedence of registration and insurance exists, you may be opening yourself up for client damages, fines of up to tens of thousands of dollars per infraction, and potential criminal charges. As was demonstrated in late 2021 in Adelaide with Trainer Norman Low ( - holding out to be a sports nutritionist and dietitian when he was neither)</p>
                    <p>There is a duty of care that Sports Nutrition Association is obligated to follow. If we suspect someone as practicing Sports Nutrition without cover we are required to report them. It is strongly advised to remain a member if you plan to continue to practice.</p>
                </div>
                <Form.Item
                    style={{flex:1, justifyContent:"center"}}
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Reading warning is required'),
                        },
                    ]}
                    validateTrigger='onBlur'
                >
                    <Checkbox style={{fontSize:"20px"}}>
                        I have read and understand the warning
                    </Checkbox>
                </Form.Item>



                <h5 style={{paddingTop:50}}>Questions</h5>

				{!!subscription && !!unpaid && <>

					<Alert
						style={{marginBottom:40}}
						message={<h5 style={{textAlign:"center"}}>Incomplete Subscription</h5>}
                        style={{maxWidth:930, margin:"auto",marginBottom:20}}
						description={
                            <>
								<p>As part of the terms and conditions of this subscription. Subscriptions are a yearly membership regardless if paid monthly or annually.</p>
								<p>You are currently {paid} payment{paid>1?'s':''} out of {paid+unpaid} into the current year's subscription.</p>
							</>
						}
						type="warning"
					/>

                    <Form.Item
                        name="payment"
                        label="How would you like to finish your current subscription"
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                        wrapperCol = {{
                            xs: { span: 8 },
                            sm: { span: 8 },
                        }}
                    >
                        <Select>
                            <Option value="finish">Let remaining {unpaid} invoices finish at the scheduled dates</Option>
                            <Option value="payout">Pay out the remaining ${Math.round(unpaidAmount)/100} immediately</Option>
                        </Select>
                    </Form.Item>

                </>

				}

                <Form.Item
                    name="practicing"
                    label="Are you still practicing sports nutrition"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter a value',
                        }
                    ]}
					wrapperCol = {{
						xs: { span: 6 },
						sm: { span: 6 },
					}}
                >
                    <Select
                        placeholder="Please Select"
						wrapperCol = {{
							xs: { span: 6 },
							sm: { span: 6 },
						}}
                    >
                        <Option value="no">No</Option>
                        <Option value="yes">Yes</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="insured"
                    label="Are you currently insured with Gallagher"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter a value',
                        }
                    ]}
                    validateTrigger='onBlur'
					wrapperCol = {{
						xs: { span: 6 },
						sm: { span: 6 },
					}}
                >
                    <Select
                        placeholder="Please Select"
                    >
                        <Option value="no">No</Option>
                        <Option value="yes">Yes</Option>
                    </Select>
                </Form.Item>

				<Form.Item
					name="removed_material"
					label="Have you removed any material relating to Accredited Sports Nutritionist, or Sports Nutritionist on your social media, website, marketing etc if there is any for us to confirm in our deregistration compliance checks?"
					rules={[
						{
							required: true,
							message: 'It is required that you have removed all SNA material to proceed',
						}
					]}
					validateTrigger='onBlur'
					wrapperCol = {{
						xs: { span: 6 },
						sm: { span: 6 },
					}}
				>
					<Select
						placeholder="Please Select"
					>
						<Option value="">No</Option>
						<Option value="yes">Yes</Option>
					</Select>
				</Form.Item>


                <Form.Item
                    name="social_media"
                    label="Social Media Handles"
                >
                    <Input.TextArea rows={4} placeholder="New Line Per Handle" />
                </Form.Item>



                <Form.Item
                    name="business_website"
                    label="Business Website"
                >
                    <Input/>
                </Form.Item>


                <h5 style={{paddingTop:50}}>Waiver</h5>
                <div  style={{width:900,margin:"auto"}}>
                    <div style={{backgroundColor:"#ebebeb",padding:20}}>
                        <h6>Voluntary resignation of formal registration, accreditation & due cover as a Sports Nutritionist release.</h6>
                        <h7>Definitions:</h7>
                        <p>
                            <b>Adequate Registration</b>
                            <span>- Any professional registration with either a national dietetic
                        association, national sports dietetic association, national nutrition association requiring a
                            minimum of a bachelor's or equivalent qualification/training of their respective members.
                            </span>
                        </p>
                        <p>
                            <ul style={{listStyle:"auto",paddingLeft:17}}>
                                <li>
                                    <div style={{padding:"30px 0 20px 0"}}>Indemnity</div>
                                    <div>
                                        <ul style={{listStyle:"lower-roman",paddingLeft:17}}>
                                            <li>
                                                You are liable for and agree to indemnify, defend and hold us harmless for and against
                                                any and all claims, liabilities, suits, actions and expenses, including costs of litigation and
                                                legal
                                                costs, resulting directly or indirectly from:
                                                (1) any information that is not accurate, up to date or complete or is misleading or a
                                                misrepresentation;
                                                (2) us providing the Material to you, your use of the Material or your enrolment in the
                                                Course;
                                                (3) you practising as an unregistered sports nutritionist;
                                                (4) any breach of the terms of your enrolment; and
                                                (5) any misuse of the information obtained from the Course or from us by you.
                                            </li>
                                            <li>You agree to cooperate with us (at your own expense) in the handling of disputes,
                                                complaints, investigations or litigation that arise as a result of your use of your use of the
                                                Material
                                                including but not limited to disputes, complaints, investigations or litigation that arises out of
                                                or
                                                relates to incorrect information you have given us.</li>
                                            <li>The obligations under this clause will survive termination of these Terms.</li>
                                        </ul>
                                    </div>

                                </li>
                                <li>
                                    <div style={{padding:"30px 0 20px 0"}}>Registration and Insurance</div>
                                    <div>
                                        <ul style={{listStyle:"lower-roman",paddingLeft:17}}>
                                            <li>You acknowledge and agree that in line with the agreed enrolment terms and conditions
                                                you will not practise as a sports nutritionist or provide
                                                services in relation to the same without complying with the Accredited Sports Nutritionist
                                                registration and insurance requirements, or any other equivalent Adequate Registration.</li>
                                            <li>As per the previously agreed enrolment terms, if you practice and hold yourself out as
                                                Sports Nutritionist or provide services in relation to the same without Adequate Registration
                                                and insured as per SNA’s requirements, you may be reported to the relevant authorities and
                                                government agencies. Where based on their investigation fines may apply. This is in line
                                                with SNA’s previously stated and mutually agreed upon regulatory, quality control &
                                                information sharing obligations.</li>
                                            <li>Upon being registered with SNA, SNA will provide you with
                                                further information in relation to your registration and insurance requirements.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div style={{padding:"30px 0 20px 0"}}>Limitation of Liability</div>
                                    <div>
                                        <ul style={{listStyle:"lower-roman",paddingLeft:17}}>
                                            <li>To the fullest extent permitted by law, we shall not be liable to you (or to anyone else)
                                                for any costs, expenses, loss of or damage to goodwill, loss of sales or business, loss or
                                                damage
                                                (whether direct, indirect or consequential, and whether economic or other) arising from your
                                                completion of the course, any accreditation and registration, or any termination of the rights
                                                granted
                                                to you under, or otherwise in connection with, any agreement with us.</li>
                                        </ul>
                                    </div>

                                </li>
                                <li>
                                    <div style={{padding:"30px 0 20px 0"}}>Reinstatement</div>
                                    <div>
                                        <ul style={{listStyle:"lower-roman",paddingLeft:17}}>
                                            <li>You agree to pay all fees for reinstatement in the event your registration or insurance policies have lapsed. Reinstatement is assessed on a case by case basis and is not guaranteed to all prior members. Reinstatement starts from $599USD and increases incrementally with every additional year the member has not been registered for.</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </p>
                    </div>
                    <Form.Item
                        style={{flex:1, justifyContent:"center"}}
                        name="waiver"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject('Reading and accepting the waiver is required'),
                            },
                        ]}
                        validateTrigger='onBlur'
                    >
                        <Checkbox style={{fontSize:"20px"}}>
                            I have read and accept the waiver
                        </Checkbox>
                    </Form.Item>
                </div>

                <p style={{paddingTop:50}}>
					<div>Please note, an association team member will be reviewing your deregistration application within the next 10 business days.</div>
					<div>Please ensure that you have actioned all of the required and agreed components of deregistration.</div>
					<div>In the event that there are any outstanding items that are still yet to be completed a team member will reach out and notify you of these.</div>
					<div>Registration payments will continue until this process has been fully completed and verified by the association team member.</div>
					<div>Thank you for working with us to ensure that the compliance stays high to protect current, future members and the general public, and we wish you success in your future endeavours.</div>
				</p>

                {!props.form &&
                <div>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{float: "right"}}
                        loading={isSaving}
                        style={{float: "right"}}
                    >{(isSaving) ? 'Submitting' : 'Submit'}</Button>
                </div>
                }
            </Form>
		</>
    )
}

