import React, { Component } from "react";

class Contact extends Component {
	render() {

		return (

            <div className="content_inner">


                <div className="container">
                    <div className="container_inner default_template_holder clearfix" style={{"paddingTop":"50px"}}>
                        <div className="contact_detail">
                            <div className="contact_section contact_section_position_center">
                                <h2>Get In Contact</h2>
                                <div className="separator small center"></div>
                                <h4>If you are interested in or have questions about becoming a Sports Nutrition
                                    Specialist, learning the Metabolic Health Systems or using the screen and program,
                                    please get in contact with us below.</h4>
                            </div>
                            <div className="contact_info">
                                <div className="vc_row wpb_row section 0" style={{"textAlign":"left"}}>
                                    <div className=" full_section_inner clearfix">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div role="form" className="wpcf7" id="wpcf7-f78-p102-o1"
                                                         lang="en-US" dir="ltr">
                                                        <div className="screen-reader-response"></div>
                                                        <form action="/contact/#wpcf7-f78-p102-o1" method="post"
                                                              className="wpcf7-form default" noValidate="novalidate">
                                                            <div style={{"display":"none"}}>
                                                                <input type="hidden" name="_wpcf7" value="78"/>
                                                                <input type="hidden" name="_wpcf7_version" value="4.9.2"/>
                                                                <input type="hidden" name="_wpcf7_locale" value="en_US"/>
                                                                <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f78-p102-o1"/>
                                                                <input type="hidden" name="_wpcf7_container_post" value="102"/>
                                                            </div>
                                                            <p>Name<br/>
                                                                <span
                                                                    className="wpcf7-form-control-wrap your-name">
                                                                    <input type="text" name="your-name" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"/>
                                                                </span>
                                                            </p>
                                                            <p>Email<br/>
                                                                <span className="wpcf7-form-control-wrap email">
                                                                    <input type="email" name="email" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false"/>
                                                                </span>
                                                            </p>
                                                            <p>Mobile<br/>
                                                                <span className="wpcf7-form-control-wrap mobile">
                                                                    <input type="text" name="mobile" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"/>
                                                                </span>
                                                            </p>
                                                            <p>What City Do You Live In?<br/>
                                                                <span className="wpcf7-form-control-wrap City">
                                                                    <input type="text" name="City" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"/>
                                                                </span>
                                                            </p>
                                                            <p>Best Contact Time<br/>
                                                                <span className="wpcf7-form-control-wrap best-contact-time">
                                                                    <input type="text" name="best-contact-time" value="" size="40" className="wpcf7-form-control wpcf7-text" aria-invalid="false"/>
                                                                </span>
                                                            </p>
                                                            <p>Additional Comments/Questions<br/>
                                                                <span className="wpcf7-form-control-wrap comments">
                                                                    <input type="text" name="comments" value="" size="40" className="wpcf7-form-control wpcf7-text" aria-invalid="false"/>
                                                                </span>
                                                            </p>
                                                            <p>
                                                                <input type="submit" value="Send" className="wpcf7-form-control wpcf7-submit"/>
                                                                <span className="ajax-loader"></span>
                                                            </p>
                                                            <div className="wpcf7-response-output wpcf7-display-none"></div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

		);
	}
}

export default Contact;