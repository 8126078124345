import React from 'react';

class ErrorBoundary extends React.Component {


    

  state = { hasError: false, errorInfo: null };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Send the error details to your backend here.

    //

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const location = window?.parent?.location||window.location;

    fetch(`${apiUrl}/errorLog`, {
      method: 'POST',
        body: JSON.stringify({
            location: location?.href||null,
            error: error.toString(),
            stack: errorInfo.componentStack
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });
  }

  render() {
    
    if (this.state.hasError) {
      // You can render a custom fallback UI here
      return <div style={{width:"100%",height:"100%", display:"flex", backgroundColor:"white"}}>
            <div style={{margin:"auto"}}>
                <img src="/images/logo-inner.png"/>
                <h1>Something went wrong.</h1>
                <span>An error report has been sent end we will look into it asap</span>
            </div>
        </div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
