import React, {} from "react";

//import "./../css/termsAndConditions.css"



export default function TermsAndConditionsText(props) {


    return (
        
        <div className="woocommerce-terms-and-conditions" style={{padding:15}}>
            <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/>
            <ol id="l1">
                <li>
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"justify", paddingTop:10}}>Definitions and Interpretation</h1>
                    <ol id="l2">
                        <li>
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>
                                <span className="p">In these Terms and Conditions, a reference to the following words has the meaning set out adjacent to them, unless the context in which the words are used requires otherwise:</span>
                            </h2>
                        </li>
                        <li>
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>
                            Application <span className="p">means your application to enrol in a course that is offered by Sports Nutrition Group (or any of its constituent companies), which may without limitation  take  the  form  of  an online enrolment application available through the website of a Sports Nutrition Group company.</span>
                            </h2>
                        </li>
                        <li data-list-text="3">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>
                            Accreditation <span className="p">means the status conferred upon satisfaction of specific requirements outlined in the Membership Schedule and the subsequent approval of one or more of the members of the Sports Nutrition Group (at the discretion of the Sports Nutrition Group).</span>
                        </h2></li>
                        <li data-list-text="4">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>
                            Agreement <span className="p">means the Registration Form, your Application, any Direct Debit Request and Direct Debit Agreement that you have completed, these Terms and Conditions  and  any  schedule, annexure or other attachment and any other practice, rules, or directions reasonably provided by the Sports Nutrition Group.</span>
                        </h2></li>
                        <li data-list-text="5">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>
                            Conﬁdential Information <span className="p">includes   any information which either party accesses or which is communicated to one party (Receiving Party) by the other  (Disclosing  Party)  in  the  course  of  Your Accreditation, Registered Membership, and completion of the Course which:</span>
                        </h2>
                            <ol type="a">
                                <li data-list-text="(a)">
                                    <p style={{paddingTop:6, paddingLeft:10}}>is identified as confidential; or</p>
                                </li>
                                <li data-list-text="(b)">
                                    <p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>which the Receiving Party should reasonably expect to be confidential.</p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text="6">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"43pt", textIndent:"-36pt", textAlign:"justify"}}>
                                <span className="p">It includes, but is not limited to:</span>
                            </h2>
                            <div>
                                <ol type="a">
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>any trade secrets or information relating to the Sports Nutrition Group, other Registered Members, or clients;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>client lists or requirements;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>suppliers;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>terms of trade;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>pricing lists;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>pricing structures;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>marketing information and plans;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>Intellectual Property;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>inventions;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>business plans or dealings;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>technical data;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>employees or officers;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>financial information and plans;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>designs;</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>research activities; and</p></li>
                                    <li><p style={{paddingTop:6, paddingLeft:10}}>software, and the source code of any such software, of the Sports Nutrition Group.</p></li>
                                </ol>
                            </div>
                        </li>
                        <li data-list-text="7">
                            <p style={{paddingTop:"6pt", paddingLeft:"43pt", textIndent:"-36pt", textAlign:"left"}}>
                            It does not include information which:</p>
                            <ol type="a">
                                <li><p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>is
                                    generally available in the public domain; and/or</p></li>
                                <li><p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>is
                                    required
                                    to be disclosed by law by the Receiving Party.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="8">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt",textAlign:"left"}}>
                            Course <span className="p">means the course detailed in the Enrolment Schedule.</span></h2>
                        </li>
                        <li data-list-text="9">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt",textAlign:"left"}}>
                            Deferral and Extension Fees <span className="p">means those fees set out in the Enrolment Schedule.</span>
                        </h2></li>
                        <li data-list-text="10">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Direct
                            Debit
                            Request <span className="p">means an application to request that Sports Nutrition Group directly debit payments from your nominated banking account, which will take the form prescribed by Sports Nutrition Group from time to time.</span>
                        </h2></li>
                        <li data-list-text="11">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Direct
                            Debit
                            Agreement <span className="p">means the agreement provided to you by Sports Nutrition Group enabling it to directly debit payments from your nominated banking account, which will take the form prescribed by Sports Nutrition Group from time to time.</span>
                        </h2></li>
                        <li data-list-text="12">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Dishonour
                            Fee <span
                                className="p">means the Dishonour Fee referred to in the Membership Schedule.</span>
                        </h2></li>
                        <li data-list-text="13">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Facilities <span
                            className="p">includes but is not limited to tools, equipment, machinery, weights, or other amenities of one or more members of the Sports Nutrition Group at the Location.</span>
                        </h2></li>
                        <li data-list-text="14">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Insurance <span
                            className="p">means the insurance required to be held by each Registered Member pursuant to the requirements of the Sports Nutrition Group as amended from time-to-time, which at the time of these Terms &amp; Conditions is provided by Arthur J Gallagher &amp; Co.</span>
                        </h2></li>
                        <li data-list-text="15">
                            <h2 style={{paddingtop:"1pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Intellectual
                            Property <span className="p">means  any  and  all intellectual and industrial property rights throughout the world, whether subsisting now or in the future and includes all copyright and analogous rights, all rights in relation to inventions (including patent rights), registered and unregistered trademarks, designs (whether or not registered or registrable), circuit layouts, trade names, trade secrets, business names, company names or internet domain names and copyright.</span>
                        </h2></li>
                        <li data-list-text="16">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Location <span
                            className="p">means any establishment or venue where the Services are carried out.</span>
                        </h2></li>
                        <li data-list-text="17">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Membership
                            Fees
                            <span className="p">means the annual fee payable in order for you to maintain your status as a Registered Member, calculated in accordance with clause 7.2.</span>
                        </h2></li>
                        <li data-list-text="18">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Membership
                            Schedule
                            <span className="p">means the membership schedule attached at Schedule 1.</span></h2>
                        </li>
                        <li data-list-text="19">
                            <h2 style={{paddingLeft:"43pt", textIndent:"-36pt", lineHeight:"8pt", textAlign:"left"}}>Registration
                            Form
                            <span className="p">means    the    Registration   </span><span className="s4">&nbsp;</span>
                        </h2>
                            <p style={{paddingLeft:"7pt",textIndent:"0pt", textAlign:"left"}}>Form submitted with and for
                                Your
                                application for Accreditation and Registered Membership;</p></li>
                        <li data-list-text="20">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Registered Membership
                                <span className="p">means Your membership with the Association, after approval by one or more of the Sports Nutrition Group (as its discretion), creating a Registered Member.</span>
                        </h2></li>
                        <li data-list-text="21">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Reinstatement
                            Fee
                            <span className="p">means the reinstatement fee contained in the Membership Schedule.</span>
                        </h2></li>
                        <li data-list-text="22">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Services <span
                            className="p">means the service offerings You provide as a Registered Member.</span></h2>
                        </li>
                        <li data-list-text="23">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Sports Nutrition Group
                                <span className="p">means Sports Nutrition Association Pte. Ltd (UEN 201931455D) and Sports Nutrition Australia Pty Ltd (ACN 621 972 638) and Regulatory Body for Sports Nutrition Australia Inc (ABN 96 569 070 212), or any of them as the context of these terms requires..</span>
                            </h2>
                        </li>
                    </ol>
                </li>
                <li>
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Acceptance and Commencement</h1>
                    <ol id="l7">
                        <li data-list-text="(a)"><p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>This
                            Agreement
                            commences on the date that:</p>
                            <ol type="i">
                                <li><p style={{paddingTop:6, paddingLeft:10}}>in relation to your Application to participate in a Course, Sports Nutrition Group accepts your Application to enrol in the relevant Course; or</p></li>
                                <li><p style={{paddingTop:6, paddingLeft:10}}>in relation to your application to become a Registered Member, Sports Nutrition Group accepts your Registration Form.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>By
                            making an
                            Application or submitting a Registration Form, you are agreeing to be bound by these Terms
                            and the terms
                            of our Agreement.</p></li>
                    </ol>
                    <ol id="l9">
                        <li data-list-text="24">
                            <h2 style={{paddingTop:"6pt", paddingLeft:"43pt", textIndent:"-36pt", textAlign:"left"}}>You/Your <span className="p">means the Registered Member.</span></h2>
                            <h2 style={{textIndent:"0pt", lineHeight:"9pt", }}>1.1 Interpretation</h2>
                            <ol id="l10">
                                <li data-list-text="(a)"><p style={{paddingLeft:"36pt", textIndent:"-28pt", lineHeight:"10pt", textAlign:"left"}}>Words denoting the singular number include the plural, and vice versa.</p>
                                </li>
                                <li data-list-text="(b)">
                                    <p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Words denoting any gender include all genders.</p>
                                </li>
                                <li data-list-text="(c)">
                                    <p style={{paddingTop:6, paddingLeft:10}}>All references to “$” and “dollars” and “USD” are to the lawful currency of the United States of America. Unless otherwise states all monetary figures are in the lawful currency of the United States of America.</p>
                                </li>
                                <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>Headings
                                    are for
                                    convenience only and do not affect interpretation.</p></li>
                                <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>References to
                                    clauses and sub-clauses are references to clauses and sub-clauses of these Terms &amp; Conditions.</p>
                                </li>
                                <li data-list-text="(f)"><p style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"justify"}}>Where
                                    any of the Parties hereto comprises more than one person, any covenants, agreement,
                                    or
                                    obligation undertaken, or benefits received by virtue of this Deed, shall be
                                    undertaken, or
                                    received by all of them jointly and each of them severally.</p></li>
                                <li data-list-text="(g)"><p style={{paddingTop:6, paddingLeft:10}}>
                                    Reference to any legislation or any section or provision thereof includes any
                                    statutory
                                    modification or re-enactment, or any statutory provision substituted including any
                                    ordinances,
                                    by-laws, regulations, or other statutory instruments issued under such
                                    legislation.</p></li>
                                <li data-list-text="(h)"><p style={{paddingTop:6, paddingLeft:10}}>
                                    References to Sports Nutrition Group include references to each company forming the
                                    Sports
                                    Nutrition Group as the context and circumstances require.</p></li>
                                <li data-list-text="(i)"><p style={{paddingtop:"5pt", paddingLeft:"36pt", textIndent:"-28pt", lineheight:"12pt", textAlign:"justify"}}>
                                    Other forms of defined words have corresponding meanings.</p></li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li data-list-text="3.">
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", lineHeight:"11pt", textAlign:"left"}}>Scope of This Agreement</h1>
                    <p style={{paddingtop:"5pt", paddingLeft:"7pt", textIndent:"0pt", lineheight:"12pt", textAlign:"justify"}}>This Agreement does not create any fiduciary, employment, agency, or partnership relationship between You and any or all of the members of the Sports Nutrition Group.</p>

                </li>
                <li data-list-text="4.">
                    <h1 style={{paddingLeft:"28pt", textIndent:"-28pt", lineHeight:"13pt"}}>
                    Application, Membership Schedule and Approval Process</h1>
                    <ol type="a">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>Sports
                            Nutrition
                            Group retains absolute discretion as to whether an Application or Registration Form that You
                            submit is
                            approved, and whether You are ultimately granted Accreditation and Registered
                            Membership.</p></li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>Sports
                            Nutrition
                            Group will inform You via email whether it has or has not approved Your Application or
                            Registration
                            Form.</p></li>
                        <li data-list-text="(c)"><p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>If Sports
                            Nutrition
                            Group is not satisfied that:</p>
                            <ol type="i">
                                <li data-list-text="(i)"><p style={{paddingTop:6, paddingLeft:10}}>You have met with the necessary prerequisites to participate in a Course; or</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:6, paddingLeft:10}}>
                                    Accreditation and Registered Membership should be conferred,</p>
                                    <p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"0pt", textAlign:"justify"}}>it
                                        will
                                        provide written notice to You. Sports Nutrition Group is not obliged to provide
                                        You with
                                        reasons for its decision.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>Sports Nutrition Group reserves the right at its complete discretion to issue you with either a “provisional” or “open” Registered Membership, which will be based off Sports Nutrition Group’s assessment of your skills and qualifications.</p></li>
                        <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>Where
                            Sports
                            Nutrition Group has issued you with a provisional Registered Membership, you will be
                            entitled to provide
                            the following Services as a Registered Member:</p>
                            <ol type="i">
                                <li data-list-text="(i)"><p style={{paddingTop:6, paddingLeft:10}}>work with low-risk general population clientele only;</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:6, paddingLeft:10}}>no work with athlete clients; and</p></li>
                                <li data-list-text="(iii)"><p style={{paddingTop:6, paddingLeft:10}}>eligible for Insurance through Sports Nutrition Group’s provider.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="(f)"><p style={{paddingTop:6, paddingLeft:10}}>Where Sports Nutrition Group has issued you with an open Registered Membership, you will be entitled to provide the following Services as a Registered Member:</p>
                            <ol type="i">
                                <li data-list-text="(i)"><p style={{paddingTop:6, paddingLeft:10}}>fully
                                    accredited to practice as a Sports Nutritionist;</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:6, paddingLeft:10}}>work with athletes from all sports, including low-moderate risk clientele;</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:6, paddingLeft:10}}>eligible to enrol in further weight making accreditation programs offered by Sports Nutrition Group;</p></li>

                            </ol>
                        </li>
                        <li data-list-text="(g)"><p style={{paddingTop:6, paddingLeft:10}}>eligible to enrol in contest preparation accreditation programs offered by Sports Nutrition Group; and</p></li>
                        <li data-list-text="(h)"><p style={{paddingTop:6, paddingLeft:10}}>eligible for Insurance through Sports Nutrition Groups’ insurance provider.</p></li>
                    </ol>

                    <p style={{paddingLeft:"64pt", textIndent:"0pt",textAlign:"left"}}></p>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="5.">
                    <h1
                    style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Eligibility</h1>
                    <ol type="a">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>You must
                            be over
                            the age of eighteen (18) to participate in a Course provided by the Sports Nutrition Group
                            unless
                            written permission from a parent or guardian is supplied.</p></li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>Sports
                            Nutrition
                            Group reserves the right to set educational prerequisites at its discretion that you must
                            satisfy in
                            order to participate in a Course.</p></li>
                        <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>Without limiting subparagraph (b), in order to submit an Application for a Course it is a prerequisite of Sports Nutrition Group that you either have enrolled in and completed:</p>
                            <ol type="i">
                                <li data-list-text="(i)"><p style={{paddingTop:6, paddingLeft:10}}>a
                                    Graduate Diploma in Applied Sports Nutrition; or</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:6, paddingLeft:10}}>a Bachelor in Exercise Physiology (Biochemistry and Physiology Major).</p>

                                </li>
                            </ol>
                        </li>
                        <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>Without
                            limiting subclause 5(b), it is a prerequisite of any Course that You have an English
                            literacy
                            score of at least 5.5.</p></li>
                        <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>In
                            order
                            to become a Registered Member You must have submitted Your Registration Form prior
                            to the
                            commencement of the sixth week of the Course.</p></li>
                        <li data-list-text="(f)"><p style={{paddingTop:6, paddingLeft:10}}>By
                            submitting Your Registration Form You acknowledge, warrant, and agree that You are
                            eligible to
                            practice as a sports nutritionist and provide the services in relation to same.</p>
                        </li>
                        <li data-list-text="(g)"><p style={{paddingTop:6, paddingLeft:10}}>You warrant that all information provided in the Registration Form and to the Sports Nutrition Group via other forms of communication is, to the best of Your knowledge, true, accurate and complete, and agree to maintain this standard of communication throughout the registration process, any Course in which you participate and Registered Membership generally.</p></li>
                        <li data-list-text="(h)"><p style={{paddingTop:6, paddingLeft:10}}>Sports
                            Nutrition Group may at their absolute discretion request additional information,
                            supporting
                            documentation and professional references, in addition to the information presented
                            on the
                            Registration Form or any Application, in order to further assess Your eligibility to
                            participate
                            in a Course, for Accreditation and to become a Registered Member.</p></li>
                    </ol>
                </li>
                <li data-list-text="6.">
                    <h1
                    style={{paddingLeft:"36pt", textIndent:"-28pt", lineheight:"12pt", textAlign:"left"}}>
                    Conduct</h1>
                    <ol id="l20">
                        <li data-list-text="6.1"><h2 style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Registered
                            Member</h2>
                            <ol id="l21">
                                <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                    must
                                    provide the Services with due care and skill, diligence, eﬃciency, honesty, and with
                                    integrity.
                                    Unethical or improper behaviour is not acceptable and must be reported to Sports
                                    Nutrition Group
                                    via email or telephone.</p></li>
                                <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                    must, at all times, comply with any and all of each and all of the members of the
                                    Sports
                                    Nutrition Group’s code of ethics and professional conduct, position statement on
                                    practicing
                                    standards, risk assessment and triage processes, created from time to time and any
                                    other
                                    documents produced by members of the Sports Nutrition Group in relation to the
                                    conduct of a
                                    Registered Member.</p></li>
                                <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                    must
                                    not engage in any conduct that might mislead, deceive, or confuse any person in
                                    relation to, or
                                    otherwise misrepresent, the nature, status, scope or effect of Your Registered
                                    Membership or
                                    Accreditation.</p></li>
                                <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                    must
                                    not delegate Your Registered Membership or Accreditation, or the activities</p>
                                    <p style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"0pt",textAlign:"left"}}>associated
                                        with Registered Membership or Accreditation, to any other person.</p></li>
                                <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                    must
                                    not copy, reproduce, or duplicate the original accreditation certificate issued to
                                    You by any
                                    one or all of the members of the Sports Nutrition Group.</p></li>
                            </ol>
                            <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                            <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                    </ol>
                </li>
                <li data-list-text="7.">
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Extension
                    Policy and
                    Deferments</h1>
                    <ol id="l22">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>You are
                            required
                            to complete the Course and all assessments within the timeframe set for the Course and
                            assessments.</p>
                        </li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>If you
                            need
                            additional time to continue beyond the end date of your Course or assessment, an application
                            for course
                            extension must be made prior to the end date or submission date (whichever is applicable).
                            The Sports
                            Nutrition Group is not obliged to extend your enrolment or assessment submission date if you
                            have not
                            made appropriate arrangements or course progression prior to the end date.</p></li>
                        <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>If the
                            extension
                            is not approved, Sports Nutrition Group reserves the right to withdraw you from the Course
                            without
                            notice or to fail You for the relevant assessment or unit.</p></li>
                        <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>Each
                            application
                            for deferment will be considered on its merits and will be granted or refused by the Sports
                            Nutrition
                            Group in its sole discretion (including the period of the deferment).</p></li>
                        <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>Should a
                            deferment be granted, all fees paid will then be held in trust for the deferment period and
                            any funds
                            held on trust will not be transferrable to any other course qualification, person, or
                            entity.</p></li>
                        <li data-list-text="(f)"><p style={{paddingTop:6, paddingLeft:10}}>On the
                            expiration of the deferment period, any funds not utilised in continuing training or Course
                            will be
                            retained by Sports Nutrition Group.</p></li>
                    </ol>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="8.">
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Membership
                    Fees and
                    Payment</h1>
                    <ol id="l23">
                        <li data-list-text="8.1"><h2 style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Membership
                            Fees</h2>
                            <ol id="l24">
                                <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>The
                                    Membership Fees required for you to become or remain a Registered Member, and to
                                    Maintain your
                                    Accreditation will be notified to you:</p>
                                    <ol id="l25">
                                        <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            initially at the time of submitting your Registration Form; and</p></li>
                                        <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            subsequently on an annual basis at the time renewal of your Registered
                                            Membership
                                            becomes due.</p></li>
                                    </ol>
                                </li>
                                <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>Sports
                                    Nutrition Group reserves the right to increase Membership Fees at its discretion
                                    and, where an
                                    increase occurs, will provide you with notice of that increase prior to the renewal
                                    of your
                                    Registered Membership falling due.</p></li>
                                <li data-list-text="(c)"><p style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"justify"}}>If
                                    no
                                    notification is provided to you as contemplated by clauses 7.4(a)(i) or 7.4(a)(ii),
                                    the
                                    Membership Fees that you will be charged will be calculated on the following
                                    basis:</p>
                                    <ol id="l26">
                                        <li data-list-text="(i)"><p style={{paddingTop:6, paddingLeft:10}}>an
                                            upfront annual fee of $399.00; or</p></li>
                                        <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            if Sports Nutrition Group has approved a fortnightly payment plan, a
                                            fortnightly fee of
                                            $21.50; or</p></li>
                                        <li data-list-text="(iii)"><p style={{paddingTop:6, paddingLeft:10}}>if
                                            Sports Nutrition Group has approved a weekly payment plan, a weekly fee
                                            of</p>
                                            <p style={{paddingLeft:"64pt", textIndent:"0pt",textAlign:"left"}}>$12.30.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                    acknowledge and agree that the Membership Fee is an annual fee and, accordingly, in
                                    circumstances where you choose to cease being a Registered Member, you are still
                                    required to pay
                                    out the remainder of any annual fee due and owing.</p></li>
                                <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>If
                                    you
                                    have failed to complete or respond to any compulsory professional development or
                                    ongoing
                                    education module prescribed by Sports Nutrition Group in a relevant period as
                                    required by clause
                                    9(b) and as a consequence Sports Nutrition Group are required to:</p>
                                    <ol id="l27">
                                        <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            undertake additional administrative processes in order to make contact with
                                            you
                                            regarding the compulsory professional development or ongoing education, or
                                            otherwise to
                                            determine the status of your Accreditation and Membership; or</p></li>
                                        <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            engage a third party in order to contact you regarding any of the matters in
                                            clause
                                            8.4(e)(i),</p>
                                            <p style={{paddingTop:6, paddingLeft:10}}>
                                                then you acknowledge and agree that Sports Nutrition Group reserves the
                                                right at its
                                                discretion to add a reasonable administrative fee to your Membership
                                                Fee, in order
                                                to cover the additional fees incurred by Sports Nutrition Group.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li data-list-text="(f)"><p style={{paddingTop:6, paddingLeft:10}}>The
                                    additional administrative fee as contemplated by clause 8.4(e) will be automatically
                                    applied to
                                    your next billing cycle and charged to your prescribed method of payment.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="8.2"><h2 style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Payments
                            Generally</h2>
                            <ol id="l28">
                                <li data-list-text="(a)"><p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>You
                                    acknowledge and agree that:</p>
                                    <ol id="l29">
                                        <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            a surcharge of 2% will apply to all credit card payments that are processed
                                            by Sports
                                            Nutrition Group; and</p></li>
                                        <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            Direct debits are subject to the Direct Debit Request and Direct Debit
                                            Agreement that
                                            you are provided by Sports Nutrition Group at the time of submitting and
                                            Application or
                                            Registration</p>
                                            <p style={{paddingtop:"1pt", paddingLeft:"64pt", textIndent:"0pt", textAlign:"justify"}}>
                                                Form, or if a third-party direct debit provider is used, the third
                                                party’s direct
                                                debit terms and conditions.</p></li>
                                    </ol>
                                </li>
                                <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>If
                                    any
                                    payment of Fees that you are required to make to Sports Nutrition Group is
                                    dishonoured and
                                    reinstatement is required, then you will be liable to pay the following:</p>
                                    <ol id="l30">
                                        <li data-list-text="(i)"><p style={{paddingTop:6, paddingLeft:10}}>a
                                            Dishonour Fee of $49.00; and</p></li>
                                        <li data-list-text="(ii)"><p style={{paddingTop:6, paddingLeft:10}}>A
                                            Reinstatement Fee of $599.</p></li>
                                    </ol>
                                </li>
                                <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                    acknowledge that the amounts referred to in 8.2(b)(ii) are a genuine estimate of the
                                    administrative fees incurred by Sports Nutrition Group in addressing a dishonoured
                                    payment and
                                    subsequently reinstating You as a Registered Member.</p></li>
                                <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>Your
                                    first Payment for a Course will be payable on the date that approval of Your
                                    Application is
                                    granted for the Course, and the recurring Payments will be due on the same date of
                                    the following
                                    month. .</p></li>
                                <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>Any
                                    fee
                                    deferral or extension fees are payable on the same date that Your deferral or
                                    extension is
                                    agreed.</p></li>
                                <li data-list-text="(f)"><p style={{paddingTop:6, paddingLeft:10}}>It
                                    is
                                    Your responsibility to ensure that on each payment due date there are suﬃcient
                                    cleared funds in
                                    Your nominated account to meet Your scheduled payment. Should Your credit or debit
                                    card payment
                                    decline on the agreed date, Sports Nutrition Group reserves its right to continue
                                    attempts to
                                    process Your payment.</p></li>
                                <li data-list-text="(g)"><p style={{paddingTop:6, paddingLeft:10}}>If
                                    a
                                    payment is declined at the time of attempting to process the payment, Sports
                                    Nutrition Group
                                    will attempt to process the declined amount once every twenty-four (24) hours for
                                    seventy-two
                                    (72)</p></li>
                            </ol>
                            <p style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"0pt",textAlign:"left"}}>interest
                                and the
                                Reinstatement Fee prior to your Registered Membership being reinstated.</p>
                            <ol id="l31">
                                <li data-list-text="(k)"><p style={{paddingTop:6, paddingLeft:10}}>If
                                    a
                                    payment is declined after your Registered Membership has been reinstated, the
                                    process and
                                    consequences referred to in this clause 7.5 are repeated. If Your Registered
                                    Membership is
                                    cancelled for a second time, Your Registered Membership may not be reinstated, and
                                    reinstatement
                                    is at the sole discretion of Sports Nutrition Group.</p></li>
                                <li data-list-text="(l)"><p style={{paddingTop:6, paddingLeft:10}}>Should
                                    Your credit or debit card expire or be cancelled, it is Your responsibility to
                                    provide Sports
                                    Nutrition Group with new card details to ensure payments continue to be successfully
                                    debited.</p></li>
                                <li data-list-text="(m)"><p style={{paddingTop:6, paddingLeft:10}}>Sports
                                    Nutrition Group may charge interest at the rate of 12.5% per annum on any payment
                                    that remains
                                    unpaid fourteen (14) days after the payment was due. Sports Nutrition Group reserves
                                    the right
                                    to engage external debt collection services for the collection of unpaid debts, and
                                    the right to
                                    commence legal proceedings for any outstanding amounts owed to any member of Sports
                                    Nutrition
                                    Group. You will be liable for all costs involved, including legal fees, on a
                                    solicitor/client
                                    basis.</p></li>
                                <li data-list-text="(n)"><p style={{paddingTop:6, paddingLeft:10}}>Sports
                                    Nutrition Group’s pricing structure, payment methods, payment processes and these
                                    Terms and
                                    Conditions may be amended at any given time at the Sports Nutrition Group’s
                                    reasonable
                                    discretion.</p></li>
                                <li data-list-text="(o)"><p style={{paddingtop:"5pt", paddingLeft:"36pt", textIndent:"-28pt", lineheight:"12pt", textAlign:"justify"}}>
                                    All changes will apply from the date that the amended or new Terms and Conditions
                                    are posted on
                                    the website of a member of the Sports Nutrition Group or are provided to You,
                                    whichever is
                                    earlier.</p></li>
                            </ol>
                            <p style={{paddingLeft:"36pt", textIndent:"0pt", lineHeight:"9pt", textAlign:"left"}}>hours
                                (three (3) times
                                in three (3) days). <span className="s4">&nbsp;&nbsp; </span></p>
                            <ol id="l32">
                                <li data-list-text="(h)"><p style={{paddingTop:6, paddingLeft:10}}>If
                                    a
                                    payment remains outstanding and in arrears after an attempt to process that payment
                                    in the
                                    manner described at (g), a member of the Sports Nutrition Group (at its discretion)
                                    will charge
                                    You the Dishonour Fee and You agree to pay the Dishonour Fee in addition to the
                                    payment.</p>
                                </li>
                                <li data-list-text="(i)"><p style={{paddingTop:6, paddingLeft:10}}>If
                                    You
                                    fail to pay the outstanding payment and the Dishonour Fee and any interest payable
                                    within thirty
                                    (30) days of the original declined payment, Sports Nutrition Group will cancel your
                                    Registered
                                    Membership and access to any online platform, resource or communication channel
                                    hosted by any of
                                    the Sports Nutrition Group.</p></li>
                                <li data-list-text="(j)"><p style={{paddingTop:6, paddingLeft:10}}>If
                                    Your
                                    Registered Membership is cancelled it will need to be reinstated. If You request
                                    that Your
                                    Registered Membership is reinstated, You must pay all outstanding payments,
                                    Dishonour Fees,</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li data-list-text="9.">
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"justify"}}>Duration
                    and Renewal
                    of Accreditation and Registered Membership</h1>
                    <ol id="l33">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>Subject
                            to
                            compliance with these Terms and Conditions, Accreditation and Registered Membership
                            continues until the
                            expiry date referenced on the accreditation certificate provided to you at the time your
                            Registered
                            Membership is approved or as set out in the Membership Schedule.</p></li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>Your
                            Accreditation and Registered Membership will be automatically renewed each renewal year,
                            subject to:</p>
                            <ol id="l34">
                                <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>Your
                                    continued compliance with the terms of this Agreement;</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>Your
                                    satisfaction and completion of all continuing professional development and
                                    educational modules
                                    prescribed by Sports</p>
                                    <p style={{paddingtop:"1pt", paddingLeft:"64pt", textIndent:"0pt",textAlign:"left"}}>Nutrition
                                        Group within the relevant period; and</p></li>
                                <li data-list-text="(iii)"><p style={{paddingTop:6, paddingLeft:10}}>Your
                                    payment of all Membership Fees.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>You
                            shall inform
                            the Association via email or telephone thirty (30) days prior to the end of each renewal
                            year, if You do
                            not intend to renew Your Accreditation or Registered Membership for the following renewal
                            year.</p></li>
                    </ol>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="10.">
                    <h1
                    style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"justify"}}>Suspension Or
                    Termination of Accreditation and Registered Membership</h1>
                    <ol id="l35">
                        <li data-list-text="10.1"><h2 style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Termination
                            Generally</h2>
                            <ol id="l36">
                                <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                    may
                                    terminate Your Accreditation and Registered Membership at any time by giving the
                                    Association at
                                    least thirty (30) days written notice. The notice must be sent to the email address
                                    nominated by
                                    Sports Nutrition Group from time to time.</p></li>
                                <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>In
                                    addition to the specific right afforded at 8.5(i), the Sports Nutrition Group may
                                    suspend or
                                    terminate Your Accreditation and Registered Membership if:</p>
                                    <ol id="l37">
                                        <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            You breach any of the terms set out in this Agreement;</p></li>
                                        <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            You require Sports Nutrition Group to act unlawfully or unethically;</p>
                                        </li>
                                        <li data-list-text="(iii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            the Sports Nutrition Group reasonably believes that Registered Membership is
                                            no longer
                                            appropriate; or</p></li>
                                        <li data-list-text="(iv)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                            fail to make Payment.</p></li>
                                    </ol>
                                </li>
                                <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>If
                                    Your
                                    Accreditation and Registered Membership is terminated by either You or by the Sports
                                    Nutrition
                                    Group pursuant to 8.5(i) or 1.1(a), You agree that the Sports Nutrition Group is
                                    entitled to all
                                    payments that would have been due for the remainder of the current membership term
                                    at the time
                                    of termination (if any).</p></li>
                                <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>On
                                    termination of this Agreement, You agree to promptly return (where possible) or
                                    delete or
                                    destroy (where not possible to return) all of the members of the Sports Nutrition
                                    Groups’
                                    Confidential Information and Intellectual Property, and all documents containing or
                                    relating to
                                    all of the members of the Sports Nutrition Groups’ Confidential Information and
                                    Intellectual
                                    Property, and comply with all deregistration processes set out by the Sports
                                    Nutrition
                                    Group.</p></li>
                                <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>Any
                                    accrued rights or responsibility of You or the Sports Nutrition Group are not
                                    affected by the
                                    termination of this Agreement.</p></li>
                                <li data-list-text="(f)"><p style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"justify"}}>On
                                    termination of this Agreement, the members of the Sports Nutrition Group will remove
                                    Your access
                                    to all Registered Membership Services including but not limited to online platforms
                                    and
                                    communication channels, face to face workshops and events and the members of the
                                    Sports
                                    Nutrition Group will not be liable for any claims related to the removal of Your
                                    access to
                                    these, Your Registered Membership or Accredited status.</p></li>
                                <li data-list-text="(g)"><p style={{paddingTop:6, paddingLeft:10}}>No
                                    refund is provided if this Agreement, Your Accreditation or Registered Membership is
                                    terminated
                                    or suspended for any reason.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="10.2"><h2 style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Prohibition
                            on
                            Continued Association</h2>
                            <ol id="l38">
                                <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>Upon
                                    termination of this Agreement or your Membership with the Association for any reason
                                    pursuant to
                                    these Terms and Conditions you acknowledge and agree that you must immediately cease
                                    and forever
                                    refrain (except to the extent that your Membership is renewed) from:</p>
                                    <ol id="l39">
                                        <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            representing, asserting or otherwise holding out in any way that you are
                                            associated with
                                            Sports Nutrition Group;</p></li>
                                        <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            indicating to your own customers or the public generally that you are an
                                            accredited or
                                            approved provider of services or training methods that are supported or
                                            endorsed by
                                            Sports Nutrition Group (acknowledging that such services and training
                                            methods constantly
                                            evolve and develop and the skills you obtained through your Course may no
                                            longer be
                                            current);</p></li>
                                        <li data-list-text="(iii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            representing, asserting or holding out in any other form that you are a
                                            “Sports
                                            Nutritionist” in the context of the training and certification provided by
                                            Sports
                                            Nutrition Group;</p></li>
                                        <li data-list-text="(iv)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            using any of the Sports Nutrition Group Intellectual Property and, in
                                            particular, any
                                            trade mark or certification trade mark of Sports Nutrition Group.</p></li>
                                    </ol>
                                </li>
                                <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>You
                                    acknowledge and agree that the above prohibitions are reasonably necessary for
                                    Sports Nutrition
                                    Group to:</p>
                                    <ol id="l40">
                                        <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            protect its business and the goodwill and reputation in its business and
                                            trade marks;
                                            and</p></li>
                                        <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>
                                            comply with its own regulatory and quality control and assurance
                                            obligations.</p></li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                            <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                    </ol>
                </li>
                <li data-list-text="11.">
                    <h1
                    style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Facilities</h1>
                    <p style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>Any use of the
                        Sports
                        Nutrition Groups’ property, Facilities or Locations are at Your own risk, and you indemnify them
                        against any
                        and all claims, damages, costs, or liabilities that may arise as a consequence of Your use of
                        the property,
                        Facilities or Location.</p>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="12.">
                    <h1
                    style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Privacy</h1>
                    <ol id="l41">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>It is a
                            requirement of the <i>Privacy Act 1988 </i>that You are informed about the collection of
                            Your personal
                            information and how the Association may use it.</p></li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>All
                            personal
                            information that is collected by Sports Nutrition Group (including any of the individual
                            entities
                            comprising that group) will be collected, stored and used in accordance with the Sports
                            Nutrition Group
                            Privacy Policy available on the website of each Sports Nutrition Group company.</p></li>
                        <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>The
                            personal
                            information You provide during the term of this Agreement is being collected for the purpose
                            of
                            processing Your Registration and Accreditation. You have a right to access and make
                            alterations to Your
                            personal information.</p></li>
                        <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>The
                            Association
                            agrees not to disclose Your personal information to any third party unless or except:</p>
                            <ol id="l42">
                                <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>as
                                    required for the legitimate interests of the Sports Nutrition Group business;</p>
                                </li>
                                <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>to
                                    the
                                    extent that the disclosure relates only to the fact that you have previously
                                    participated in
                                    programs offered by Sports Nutrition Association, that your Accreditation is either
                                    current or
                                    non-current, that you are no longer a Member of the Association, or that you are no
                                    longer
                                    aﬃliated with or endorsed by the Sports Nutrition Group;</p></li>
                                <li data-list-text="(iii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>legally
                                    required to do so or to its professional advisors.</p></li>
                            </ol>
                        </li>
                    </ol>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="13.">
                    <h1
                    style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Publicity</h1>
                    <ol id="l43">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>You must
                            not
                            make any public comment or remark that is, or is likely to be, disparaging, derogatory or
                            harmful to
                            Sports Nutrition Group regarding:</p>
                            <ol id="l44">
                                <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>the
                                    nature and quality of the education services provided by Sports Nutrition Group;</p>
                                </li>
                                <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>the
                                    membership and association services provided by the Association; and</p></li>
                                <li data-list-text="(iii)"><p style={{paddingtop:"1pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>any
                                    of
                                    the directors, agents employees or other personnel of the Sports Nutrition
                                    Group.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>Where
                            you have a
                            complaint or criticism you must notify Sports Nutrition Group of the complaint or criticism
                            in writing (<b>Complaint
                                Notice</b>).</p></li>
                        <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>Upon
                            receipt of
                            a Complaint Notice Sports Nutrition Group will contact You within ten (10) business days to
                            resolve the
                            matters the subject of the Complaint Notice.</p></li>
                        <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>For the
                            avoidance of doubt this clause 15 applies only in respect of public comments or remarks as
                            contemplated
                            by clause 15(a). Any disputes regarding the terms of this Agreement or the performance of
                            the
                            obligations of the parties under this Agreement must be dealt with in accordance with clause
                            16.</p>
                        </li>
                    </ol>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"7pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="14.">
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Conﬁdential
                    Information</h1>
                    <p style={{paddingTop:"6pt", paddingLeft:"7pt", textIndent:"0pt", textAlign:"justify"}}>You agree not to
                        disclose
                        the Confidential Information of the Sports Nutrition Group to any third party, to use all
                        reasonable
                        endeavours to the Confidential Information of the Sports Nutrition Group from any unauthorised
                        disclosure,
                        and only to use the Confidential Information for the purpose for which it was disclosed or
                        provided by
                        Sports Nutrition Group to You, and not for any other purpose.</p>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"7pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="15.">
                    <h1
                    style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Intellectual
                    Property</h1>
                    <ol id="l45">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>The work
                            and
                            materials that Sports Nutrition Group provides to you in carrying out the Services contains
                            material
                            which is owned by or licensed to Sports Nutrition Group , or owned by third parties, and is
                            protected by
                            Australian and international laws (<b>Materials</b>). Sports Nutrition Group owns the
                            copyright in all
                            creative, literary and original works incorporated in the Materials that Sports Nutrition
                            Group has
                            created, including any sports nutrition programs, meal plans, exercise regimes, supplement
                            programs,
                            business plans, social media strategies and any other documents or concepts provided in
                            either spoken or
                            written form within the scope of Your Accreditation and Registered Membership with Sports
                            Nutrition
                            Group, including those disseminated during professional development seminars.</p></li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>You
                            agree that
                            Sports Nutrition Group owns all intellectual property rights in its Materials, and that
                            nothing in these
                            Terms and Conditions constitutes a transfer of any intellectual property ownership rights in
                            Sports
                            Nutrition Group Materials, except as stated in these Terms and Conditions or with Sports
                            Nutrition
                            Group’s written permission. Your use of Sports Nutrition Group’s Materials does not grant
                            You a license,
                            or</p>
                            <p style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"0pt", textAlign:"justify"}}>act as
                                a right of
                                use, of any of the intellectual property in the Materials, whether registered or
                                unregistered,
                                except as stated in these Terms and Conditions or with the Association’s written
                                permission.</p>
                        </li>
                        <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>If You
                            are
                            provided with any Materials during Your Accreditation or Registered Membership, then the
                            copyright in
                            these Materials shall remain vested with Sports Nutrition Group and shall only be used at
                            Sports
                            Nutrition Group’s discretion. You must not:</p>
                            <ol id="l46">
                                <li data-list-text="(i)"><p style={{paddingTop:6, paddingLeft:10}}>alter
                                    or
                                    modify any of the Materials;</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>create
                                    derivative works from the Materials; or</p></li>
                                <li data-list-text="(iii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>use
                                    Sports Nutrition Group’s Materials for commercial purposes such as on sale to third
                                    parties,</p>
                                    <p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>without
                                        Sports Nutrition Group’s prior express written permission.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>You
                            acknowledge
                            and agree that You must not use the Sports Nutrition Group’s Materials to create a business
                            that is
                            similar to that of the Sports Nutrition Group, If You do so, Sports Nutrition Group reserves
                            the right
                            to immediately</p></li>
                        <li data-list-text="(e)"><p style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"justify"}}>Sports
                            Nutrition
                            Group may from time-to-time amend or change the required Insurance to be held by Registered
                            Members.
                            Sports Nutrition Group agrees to take reasonable steps to notify you of any change.</p></li>
                        <li data-list-text="(f)"><p style={{paddingTop:6, paddingLeft:10}}>You
                            shall obtain
                            and maintain valid and enforceable public liability, professional indemnity insurance
                            policies to cover
                            any potential liabilities of:</p>
                            <ol id="l47">
                                <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>You,
                                    in
                                    the scope of Your professional duties as a sports nutritionist; and</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>any
                                    of
                                    the members of the Sports Nutrition Group, arising out of any breach of this
                                    Agreement, or any
                                    act or omission committed by You, as if each member was a jointly insured party
                                    under each of
                                    those policies.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="(g)"><p style={{paddingTop:6, paddingLeft:10}}>For the
                            avoidance of doubt, at the time of these Terms &amp; Conditions, the Insurance fulfils the
                            requirements
                            of (e).</p></li>
                        <li data-list-text="(h)"><p style={{paddingtop:"5pt", paddingLeft:"36pt", textIndent:"-28pt", lineheight:"12pt", textAlign:"justify"}}>
                            You agree to, upon request, provide the Association with certificates of currency and any
                            other evidence
                            of Your compliance with this clause 15 that the Association may reasonably require.</p></li>
                    </ol>
                    <p style={{paddingLeft:"36pt", textIndent:"0pt", lineHeight:"8pt", textAlign:"left"}}>terminate Your
                        Registered
                        Membership andr the <span className="s4">&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
                    <p style={{paddingLeft:"36pt", textIndent:"0pt",textAlign:"left"}}>Agreement.</p>
                    <ol id="l48">
                        <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>Sports
                            Nutrition
                            Group reserves its right to pursue any loss or damage suffered as a result of copyright or
                            Intellectual
                            Property infringement by You or any third party.</p></li>
                        <li data-list-text="(f)"><p style={{paddingTop:6, paddingLeft:10}}>This
                            clause
                            survives termination of the Agreement.</p></li>
                    </ol>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="16.">
                    <h1
                    style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Insurance</h1>
                    <ol id="l49">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>You
                            agree to
                            obtain and maintain the Insurance throughout the course of Your Registered Membership.</p>
                        </li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>The cost
                            associated with obtaining Insurance is set by the third party provider of Sports Nutrition
                            Group and
                            will be notified to You at the time of obtaining that Insurance.</p></li>
                        <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>You agree that,
                            should Your Insurance lapse, any member of the Sports Nutrition Group may at their sole
                            discretion and
                            without notice to You, report the lapse to the Insurance provider, the consequence of this
                            being Your
                            Insurance will be cancelled.</p></li>
                        <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>In the
                            event
                            that your Insurance is cancelled, Sports Nutrition Group reserves the right to cancel your
                            Registered
                            Membership and Accreditation.</p></li>
                    </ol>
                </li>
                <li data-list-text="17.">
                    <h1
                    style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>
                    Indemnity</h1>
                    <ol id="l50">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>You are
                            liable
                            for and agree to indemnify, defend, and hold the Sports Nutrition Group harmless for and
                            against any and
                            all claims, liabilities, suits, actions, and expenses, including costs of litigation and
                            legal costs,
                            resulting directly or indirectly from:</p>
                            <ol id="l51">
                                <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>any
                                    information that is not accurate, up to date or complete or is misleading or a
                                    misrepresentation;</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>the
                                    Sports Nutrition Group (or an individual member) providing You with Accreditation
                                    and Registered
                                    Membership;</p></li>
                                <li data-list-text="(iii)"><p style={{paddingTop:6, paddingLeft:10}}>any
                                    breach
                                    of this Agreement; and</p></li>
                                <li data-list-text="(iv)"><p style={{paddingTop:6, paddingLeft:10}}>you
                                    delivering Services to the public.</p></li>
                            </ol>
                        </li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>You
                            agree to
                            cooperate with the Sports Nutrition Group (at Your own expense) in the handling of disputes,
                            complaints,
                            investigations, or litigation that arise as a result of Your Services including but not
                            limited to
                            disputes, complaints, investigations, or litigation that arises out of or relates to
                            incorrect
                            information You have given the Sports Nutrition Group.</p></li>
                        <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>The
                            obligations
                            under this clause will survive termination of this Agreement.</p></li>
                    </ol>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="18.">
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Limitation
                    of
                    Liability</h1>
                    <ol id="l52">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>To the
                            fullest
                            extent permitted by law, the Sports Nutrition Group shall not be liable to You for any
                            costs, expenses,
                            loss of or damage to goodwill, loss of sales or business, loss, or damage (whether direct,
                            indirect, or
                            consequential, and whether economic or other) arising from Your Accreditation and Registered
                            Membership,
                            or any termination of the rights granted to You under, or otherwise in connection with, this
                            Agreement.</p></li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>The
                            Sports
                            Nutrition Group’s liability to You, whether in contract, tort (including negligence), breach
                            of
                            statutory duty, or otherwise, arising in connection with this Agreement, shall at all times
                            be limited
                            to the amount of the Payments made by you under this Agreement.</p></li>
                    </ol>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="19.">
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Dispute
                    Resolution
                    (Mediation)</h1>
                    <ol id="l53">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>If a
                            dispute
                            (<b>Dispute</b>) arises between the parties to this Agreement which they cannot resolve,
                            then the party
                            claiming that a Dispute has arisen must deliver to the other parties a notice containing
                            particulars of
                            the Dispute (<b>Dispute Notice</b>).</p></li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>During
                            the
                            period of 10 Business Days after delivery of the Dispute Notice, or any longer period agreed
                            in writing
                            by the parties to the Dispute (<b>Initial Period</b>), the parties to the Dispute
                            (<b>Participants</b>)
                            must meet and use their reasonable endeavours and act in good faith in an attempt to resolve
                            the
                            Dispute.</p></li>
                        <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>If the
                            Participants cannot resolve the Dispute then unless they all agree otherwise, the
                            Participants must
                            appoint a mediator to mediate the Dispute in accordance with the rules of the Resolution
                            Institute. All
                            Participants must participate in the mediation in good faith.</p></li>
                        <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>The
                            mediator
                            must be agreed on by the Participants within 10 Business Days after the Dispute Notice is
                            given to the
                            Participants and if they cannot agree within that time the mediator will be nominated by the
                            president
                            of the Resolution Institute.</p></li>
                        <li data-list-text="(e)"><p style={{paddingTop:"6pt", paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>The
                            mediation
                            concludes when:</p>
                            <ol id="l54">
                                <li data-list-text="(i)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>all
                                    the
                                    Participants agree in writing on a resolution of the Dispute; or</p></li>
                                <li data-list-text="(ii)"><p style={{paddingTop:"6pt", paddingLeft:"64pt", textIndent:"-28pt", textAlign:"justify"}}>a
                                    Participant, not earlier than 20 Business Days after appointment of the mediator,
                                    has given 5
                                    Business Days’ notice to the other Participants and to the mediator, terminating the
                                    mediation,
                                    and that 5 Business Days has expired without all the Participants agreeing in
                                    writing on a
                                    resolution of the issue.</p></li>
                            </ol>
                        </li>
                    </ol>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"7pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="20.">
                    <h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>Goods and
                    Services
                    Tax</h1>
                    <ol id="l55">
                        <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>Unless
                            otherwise
                            expressly stated, all amounts stated to be payable under this document are exclusive of
                            goods and
                            services tax (<b>GST</b>). If GST is imposed on any supply made under or in accordance with
                            this
                            document, then the GST payable must be paid to the supplier as an additional amount by the
                            recipient of
                            the supply, provided the supplier provides a tax invoice in respect of the taxable supply.
                        </p></li>
                        <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>If a
                            party is
                            entitled to be reimbursed or receive compensation for any of its costs, expenses or
                            liabilities then the
                            amount to be paid is to be reduced by the input tax credits to which that party is entitled
                            to receive
                            in relation to those amounts.</p></li>
                    </ol>
                    <p style={{textIndent:"0pt", textAlign:"left"}}><br/></p>
                    <p style={{paddingLeft:"7pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/></li>
                <li data-list-text="21."><h1 style={{paddingLeft:"36pt", textIndent:"-28pt", textAlign:"left"}}>General
                    Matters</h1>
                </li>
            </ol>
            <ol id="l56">
                <li data-list-text="(a)"><p style={{paddingTop:6, paddingLeft:10}}>You
                    must not assign, novate or otherwise transfer any of your rights or obligations under this document
                    without the
                    prior written consent of Sports Nutrition Group.</p></li>
                <li data-list-text="(b)"><p style={{paddingTop:6, paddingLeft:10}}>
                    Neither party is liable for any breach of its obligations under this document to the extent that the
                    breach
                    resulted from any event that is outside the reasonable control of the affected party and could not
                    have been
                    prevented by that party taking reasonable steps or overcome by the exercise of reasonable diligence
                    and at a
                    reasonable cost (including lack of supply, industrial action, fire, riot, war, embargo, civil
                    commotion for act
                    of God) provided that the affected party:</p>
                    <ol id="l57">
                        <li data-list-text="(i)"><p style={{paddingTop:6, paddingLeft:10}}>promptly
                            notifies
                            the other party of the event (with appropriate details); and</p></li>
                        <li data-list-text="(ii)"><p style={{paddingTop:6, paddingLeft:10}}>takes all
                            reasonable steps to work around or reduce the effects of the event.</p></li>
                    </ol>
                </li>
                <li data-list-text="(c)"><p style={{paddingTop:6, paddingLeft:10}}>Any
                    amendments to this Agreement must be made in writing and signed by the parties.</p></li>
                <li data-list-text="(d)"><p style={{paddingTop:6, paddingLeft:10}}>You
                    agree that the Sports Nutrition Group, may use email to communicate with You and may use the email
                    address that
                    You provide for that purpose.</p></li>
                <li data-list-text="(e)"><p style={{paddingTop:6, paddingLeft:10}}>You
                    agree to release the Sports Nutrition Group from any liability for any loss which You might incur if
                    an email is
                    intercepted or corrupted during transmission, or if a document which any of the members of the
                    Sports Nutrition
                    Group prepared for You is altered by You or any other party without the written consent of the
                    Sports Nutrition
                    Group.</p></li>
                <li data-list-text="(f)"><p style={{paddingTop:6, paddingLeft:10}}>This
                    Agreement is governed by the laws of Queensland, Australia and You agree to submit to</p>
                    <p style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"0pt",textAlign:"left"}}>the non-exclusive
                        jurisdiction
                        of the courts of Queensland, Australia.</p></li>
                <li data-list-text="(g)"><p style={{paddingTop:6, paddingLeft:10}}>
                    Unless this document expressly states otherwise, a party may in its absolute discretion, give
                    conditionally or
                    unconditionally or withhold, any</p>
                    <p style={{paddingtop:"1pt", paddingLeft:"36pt", textIndent:"0pt",textAlign:"left"}}>consent under this
                        document.
                        To be effective any consent under this document must be in writing.</p></li>
                <li data-list-text="(h)"><p style={{paddingTop:6, paddingLeft:10}}>Any
                    provision of this Agreement that is illegal, void, or unenforceable will be severed without
                    prejudice to the
                    balance of the provisions.</p></li>
            </ol>
        </div>
	);
}