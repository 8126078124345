import React, { useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    DatePicker,
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import {notification} from "antd/lib/index";



const { RangePicker } = DatePicker;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};



export default function SupportForm(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const AP = API2();


    useEffect(() => {

        props.form.resetFields();

        let type = props.type;
        if(!type) type = 'general';

        let res = {area:type};
        props.form.setFieldsValue(res);

    }, [props.type]);


    function saveForm(data){

        setIsSaving(true);



        AP.post('/my/support',data).then(function(res){

            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            setIsSaving(false);
            props.onFinish();

        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    props.form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });

    }


    return(
        <Form
            id="myForm"
            {...formItemLayout}
            form={props.form}
            name="invoice"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                name="area"
                label="Area"
            >
                <Select>
                    <Option value="general">General</Option>
                    <Option value="technical">Technical</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="description"
                label="Description"
                rules={[
                    {
                        required: true,
                        message: 'Please provide a description of your issue',
                    }
                ]}
            >
                <Input.TextArea rows={4} placeholder="Please give information about your issue here." />
            </Form.Item>



        </Form>
    );
}