import React, {useContext, useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import useToken from "../../hooks/useToken";


import BreadCrumbContext from "../../contexts/breadCrump";
import RegistrySettingsForm from "../../forms/RegistrySettings";

export default function RegistrySettings(props) {

  	const setBreadCrumbs = useContext(BreadCrumbContext);
    const { user } = useToken();

	useEffect(() => {
		setBreadCrumbs([
			<NavLink to="/clients"><i className="fas fa-user"></i> Registry Settings </NavLink>
		]);
	}, []);

	return(
		<div className="content_inner">
            <RegistrySettingsForm user={user} {...props}/>
		</div>
	)
}

