import React, {useContext, useEffect, useState} from 'react';
import UserForm from "../forms/User"
import {NavLink} from "react-router-dom";
import {
    Tabs,
    Spin
} from 'antd';


import BreadCrumbContext from "../contexts/breadCrump";
import moment from "moment/moment";
import API2 from "../components/api2";
import RegistrySettingsForm from "../forms/RegistrySettings";
import dayjs from "dayjs";

const { TabPane } = Tabs;


export default function CreateClientAccount(props) {

    const AP = API2();
	const setBreadCrumbs = useContext(BreadCrumbContext);
    const [user, setUser] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    let defaultTab = props.match.params.tab?props.match.params.tab:"general";

	useEffect(() => {
		setBreadCrumbs([
			<NavLink to="/clients"><i className="fas fa-user"></i> Profile </NavLink>
		]);

        AP.get('/profile')
            .then(function (res) {

                let isStudent = false;
                let isMember  = false;
                res.subscriptions.map(function(subscription) {
                    if (subscription.plan.for == 'student') res.isStudent = true;
                    else if (subscription.plan.for == 'member') res.isMember = true;
                });


                if (res.dob) {
                    res.dob = dayjs(res.dob * 1000);
                }
                setUser(res);
                setIsLoading(false);
            });
	}, []);

	return(
		<div className="content_inner">

            {isLoading &&
                <div style={{display:"flex",justifyContent:"center",paddingTop:40}}>
                    <Spin/>
                </div>
            || <>

            <UserForm user={user} {...props}/>

            </>}
		</div>
	)
}

