import React, { useState, useEffect,useContext  } from "react";
import { Table, Button } from 'antd';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from '../../components/api2';

export default function LinkedCompanies(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([<span><i className="fas fa-mobile-alt"></i> Linked Apps</span>]);
	}, []);

    const AP = API2();

    const [data, setData] = useState(false);
    useEffect(() => {
        AP.get('/companies')
            .then(function(res){
                setData(res)
            });
    }, []);

    const [linkResp, setLinkResp] = useState(false);
    function link(id){
        AP.post('/company/'+id+'/link')
			.then(function(res){
                for (var i in data) {
                    if (data[i].id == id) {
                        data[i].linked = true;
                        break;
                    }
                }
                setData(data);
                setLinkResp(res);
            });
	}
    function unlink(id){
        AP.post('/company/'+id+'/unlink')
            .then(function(res){
                for (var i in data) {
                    if (data[i].id == id) {
                        data[i].linked = false;
                        break;
                    }
                }
                setData(data);
                setLinkResp(res);
            });
    }

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            align: 'left',
		},
		{
			title: 'Actions',
            key: 'actions',
            width: '140px',
            align: 'center',
            render: (text, record) => (
				<div>
                    {(!record.linked)
						? <span><Button type="primary" icon={<i className="fas fa-link"></i>} onClick={() => link(record.id)} style={{width:"90px"}}>&nbsp;Link</Button></span>
                    	: <span><Button type="danger" icon={<i className="fas fa-unlink"></i>} onClick={() => unlink(record.id)} style={{width:"90px"}}>&nbsp;Unlink</Button></span>
                    }
				</div>
			),
		},
	];



	return (
		<div>
            <Table dataSource={data} columns={columns} />
		</div>
	);
};