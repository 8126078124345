import React, {useContext, useEffect, useState} from 'react';
import API2 from "../../components/api2";
import CouponForm from "../../forms/Coupon"
import {NavLink, useParams} from "react-router-dom";

import {
    Tabs,
    Button,
    Spin
} from 'antd';
import moment from "moment/moment";
import {Form} from "antd/lib/index";
import BreadCrumbContext from "../../contexts/breadCrump";
import { UserOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;


export default function Coupon(props) {

    let id = props.match.params.couponid;
    if(id == 'new') id = null;


    let defaultTab = "1";

    const AP = API2();

    const [coupon, setCoupon] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/coupons"><span><i className="fas fa-users"/> Coupons</span></NavLink>,
            (id)?<Spin size="small"/>:<span><i className="fas fa-user"/> Create Coupon</span>
        ]);
    }, []);

    useEffect(() => {
        if(id) {
            AP.get('/coupon/' + id)
                .then(function (res) {

                    setBreadCrumbs([
                        <NavLink to="/coupons"><span><i className="fas fa-users"/> Coupons</span></NavLink>,
                        <span><UserOutlined/> {res.name}</span>
                    ]);

                    setCoupon(res);
                    setIsLoading(false);
                });
        }else{
			setCoupon({plan_options:[]});
			setIsLoading(false);
            /*
            AP.get('/plans')
                .then(function (res) {

                    setCoupon({plan_options:res.data});
                    setIsLoading(false);
                });
            */

        }
    }, []);

    return(
        <div className="content_inner">

			{!!coupon &&
                <CouponForm coupon={coupon}/>
			}

        </div>
	);
}