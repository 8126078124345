import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import moment from 'moment'
import countryList from "react-select-country-list";


export default function DailyTests(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-users"/> Daily Stats</span>
        ]);
	}, []);



	const columns = [
        {
            title: 'Type',
            key: 'type',
            dataIndex: 'type',
            sorter: true,
            render: function(text, record) {
                if(record.type == "D_MissingTrans") return <div>Missing Transaction: (paid invoices without transactions)</div>;
                else if(record.type == "D_ErrorTransactions") return <div>Error Transactions: Transactions that have errored</div>;
                else if(record.type == "D_DoublePay") return <div>Double Paid: Invoices that have been paid multiple times</div>;
                else if(record.type == "D_DoubleSubscription") return <div>Double Subscriptions: Multiple overlapping subscriptions</div>;
                else return record.type;
            }
        },{
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            sorter: true,
            width:200,
            align:"center",
            render: function(text, record) {
                return <div>
                    <Moment format="DD MMM YYYY" unix style={{flex:1}}>{record.created_at}</Moment>
                </div>
            }
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            sorter: true,
            width:200,
            align:"center",
            render: function(text, record) {
                if(record.stats.count == 0){
                    return <i className="fas fa-check" style={{color:"green"}}/>;
                }else{
                    return <i className="fas fa-times" style={{color:"red"}}/>;
                }
            }
        },
		{
			title: 'Actions',
            key: 'actions',
            width: 150,
            align:"center",
            render: (text, record) => (
				<div>
					<span><Link to={"/dailystats/"+record.id}><Button type="primary" shape="round"><i className="far fa-edit"/></Button></Link></span>
				</div>
			),
		},
	];
    const subColumns = [
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            sorter: true,
            align:"center",
            render: function(text, record) {
                return <div>
                    <Moment format="DD MMM YYYY" unix style={{flex:1}}>{record.created_at}</Moment>
                </div>
            }
        },{
            title: 'Details',
            key: 'stats',
            dataIndex: 'stats',
            sorter: true,
            align:"center",
            render: function(text, record) {
                return <div>
                    {JSON.stringify(record.stats)}
                </div>
            }
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            sorter: true,
            width:200,
            align:"center",
            render: function(text, record) {
                if(record.stats.count == 0){
                    return <i className="fas fa-check" style={{color:"green"}}/>;
                }else{
                    return <i className="fas fa-times" style={{color:"red"}}/>;
                }
            }
        },
		{
			title: 'Actions',
            key: 'actions',
            width: 150,
            align:"center",
            render: (text, record) => (
				<div>
					<span><Link to={"/dailystats/"+record.id}><Button type="primary" shape="round"><i className="far fa-edit"/></Button></Link></span>
				</div>
			),
		},
	];

    const onSelectOptions = [
        {
            name: "Delete",
            value: "delete",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["delete",selectedKeys]);
            }
        },
        {
            name: "Set Active",
            value: "setActive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setActive",selectedKeys]);
            }
        },
        {
            name: "Set Inactive",
            value: "setInactive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setInactive",selectedKeys]);
            }
        }
    ];

	return (

        <div className="content_inner" style={{maxWidth:1300,paddingTop:40}}>
            <SearchTable
                columns={columns}
                url="/dailytests"
                expandable={{
                    expandedRowRender: function(record){
                        return <div style={{marginTop:40}}>
                                <SearchTable
                                columns={subColumns}
                                url={"/dailytests/"+record.type}
                            />
                        </div>
                    }
                }}
            />
        </div>
	);
};