import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input, Space } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import countryList from "react-select-country-list";

export default function Members(props) {

    const AP = API2();


    const setBreadCrumbs = useContext(BreadCrumbContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [discountOptions, setDiscountOptions] = useState([]);

	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-users"/> Users</span>
        ]);

        AP.get('/discounts?for=member')
            .then(function (res) {
                let options = [];
                for(let o of res){
                    options.push({text:o,value:o});
                }

                setDiscountOptions(options);
                setIsLoaded(true);
            });

	}, []);

    const regionMap = {
        'australia': 'Australia',
        'newzealand': 'New Zealand',
        'unitedstates': 'United States',
        'canada': 'Canada',
        'asia': 'Asia',
        'europe': 'Europe'
    };

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'first_name',
            sorter: true,
        	align:'left',
            searchable: true,
            width: "200px",
            fixed: 'left',
            render: (text, record) => (
                <div style={{display:"flex"}}>
                    <div>
                        <Avatar src={(record.image)?record.image.endpoint:null} icon={<i className="far fa-user"></i>} size="large" style={{opacity:record.image?1:.2}}/>
                    </div>
                    <div style={{paddingLeft:"20px",whiteSpace:"nowrap",overflow:"hidden"}}>
                        <div>{record.name}</div>
                        <div style={{overflow:"hidden",textOverflow:"ellipsis"}}>{record.email}</div>
                    </div>
                </div>
            )
		},
		{
			title: 'No',
			key: 'users.id',
			dataIndex: 'id',
			width: 70,
			sorter: true,
			align:'left',
			searchable: true
		},
        {
            title: 'Region',
            key: 'region',
            dataIndex: 'region',
            width: "100px",
            sorter: true,
            filters: [
                {
                    text: 'Australia',
                    value: 'australia',
                },
                {
                    text: 'New Zealand',
                    value: 'newzealand'
                },
                {
                    text: 'United States',
                    value: 'unitedstates'
                },
                {
                    text: 'Canada',
                    value: 'canada'
                },
                {
                    text: 'Asia',
                    value: 'asia'
                },
                {
                    text: 'Europe',
                    value: 'europe'
                }
            ],
            //fixed: 'left',
            //responsive: ["sm"],
            render: (text, record) => {
                if (regionMap[text]) return regionMap[text];
                return text;
            }
        },
        /*
        {
            title: 'Mobile',
            key: 'mobile',
            dataIndex: 'mobile',
            width: "80px",
            sorter: true,
            searchable: true,
            //responsive: ["xxl"]
        },
        */
        {
            title: 'Subscriptions',
            key: 'subscription',
            dataIndex: 'subscription',
            width: "150px",
            sorter: true,
            filters: [
                {
                    text: 'Annual',
                    value: 'annual',
                },
                {
                    text: 'Monthly',
                    value: 'monthly'
                }
            ],
            align:"left",
          //  responsive: ["lg"],
           // filterMode: 'tree',
           // filterSearch: true,
            render: function(text, record) {

                const subMap = {};
                for(const subscription of record.subscriptions){
                    const key = subscription.plan.for+'-'+subscription.plan.name;
                    if(subMap[key] == undefined){
                        subMap[key] = subscription;
                    }else if(subMap[key].created_at < subscription.created_at){
                        subMap[key] = subscription;
                    }
                }

                return Object.values(subMap).map(subscription => {
                    let linkStr = '';
                    if(subscription.linkedData){
                        linkStr = ' (D'+subscription.linkedData.depth+')';
                    }

                    return <div style={{display:"flex"}}>
                        (<div style={{width:60, textAlign:"center"}}>{subscription.plan.for}</div>)
                        <div style={{paddingLeft:10}}>
                            {subscription.plan.name} {linkStr} {subscription.plan.starts_at && <>&nbsp;<Moment format="MMMYY" unix>{subscription.plan.starts_at}</Moment></>}
                        </div>
                    </div>
                });
            }
        },

        {
            title: 'Status',
            key: 'subscription.status',
            dataIndex: 'billing_status',
            width: 80,
            sorter: true,
            align:'left',
			filters: [
				{
					text: 'Active',
					value: 'active',
				},
				{
					text: 'Pending Cancel',
					value: 'pending-cancel'
				},
				{
					text: 'Cancelled',
					value: 'cancelled'
				},
				{
					text: 'Completed',
					value: 'completed'
				},
				{
					text: 'Debt',
					value: 'debt'
				},
				{
					text: 'Pending Deregistered',
					value: 'pending-deregistered'
				},
				{
					text: 'Deregistered',
					value: 'deregistered'
				},
				{
					text: 'Hibernating',
					value: 'hybernating'
				},
				{
					text: 'On Hold',
					value: 'on-hold'
				}
			],
            render: function(text, record) {

                const subMap = {};
                for(const subscription of record.subscriptions){
                    const key = subscription.plan.for+'-'+subscription.plan.name;
                    if(subMap[key] == undefined){
                        subMap[key] = subscription;
                    }else if(subMap[key].created_at < subscription.created_at){
                        subMap[key] = subscription;
                    }
                }

                return Object.values(subMap).map(subscription => (
                    <div>{subscription.status}</div>
                ));
            }
        },
        {
            title: 'Discounts',
            //key: 'discount',
            dataIndex: 'discount.name',
            width: 100,
            sorter: true,
            filters: discountOptions,
            //responsive: ["xxl"],
            render: (text, record) => {



                const subMap = {};
                for (const subscription of record.subscriptions) {
                    const key = subscription.plan.for + '-' + subscription.plan.name;
                    if (subMap[key] == undefined) {
                        subMap[key] = subscription;
                    } else if (subMap[key].created_at < subscription.created_at) {
                        subMap[key] = subscription;
                    }
                }

                return <div style={{textAlign: "left"}}>
                    {Object.values(subMap).map(subscription => (
                        subscription.discounts.length
                            ? subscription.discounts.map(discount => (
                                <div style={{display: "flex"}}>
                                    <div style={{flex: 1}}>{discount.name}</div>
                                    <div>( {((discount.type == "fixed") ? "$" : "") + parseFloat(discount.discount) + ((discount.type == "percent") ? "%" : "")} )</div>
                                </div>
                            ))
                            : <div/>
                    ))}
                </div>
            }
        },
        {
            title: 'Next Payment',
            key: 'nextPaymentTS',
            dataIndex: 'nextPaymentTS',
            width: "150px",
            sorter: true,
            //responsive: ["xl"],
			searchable: true,
            render: function(text, record) {
                if(!record.nextPaymentTS){
                    return <div style={{color:"grey"}}>
                    {record.subscriptions.map(subscription => {
                        return <div>{subscription.status}</div>
                    })}
                    </div>
                }
                let days = Math.ceil((record.nextPaymentTS-Date.now()/1000)/86400);
                return <div style={{display:"flex",color:(days<0)?'red':'initial'}}>
                        <Moment format="Do MMM YYYY" unix style={{flex:1}}>{record.nextPaymentTS}</Moment>
                        <div>( {days} day{(days>1)?'s':''} )</div>
                    </div>
            }
        },
		{
			title: 'Actions',
            key: 'actions',
            width: "80px",
            fixed: 'right',
            render: (text, record) => (
				<div>
					<span><Link to={"/member/"+record.id}><Button type="primary" shape="round"><i className="far fa-edit"/></Button></Link></span>
				</div>
			),
		},
	];

	function onSelect(selectedRowKeys: React.Key[], selectedRows: DataType[]){
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }

    const onSelectOptions = [
        {
            name: "Delete",
            value: "delete",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["delete",selectedKeys]);
            }
        },
        {
            name: "Set Active",
            value: "setActive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setActive",selectedKeys]);
            }
        },
        {
            name: "Set Inactive",
            value: "setInactive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setInactive",selectedKeys]);
            }
        }
    ];

	return (
        <div className="content_inner" style={{maxWidth:1600,paddingTop:40}}>
            <div style={{float:"right",position:"relative",top:-40}}>
                <Link to="/user/new" activeClassName="current" style={{marginLeft:"auto"}}>
                    <Button icon={<i className="far fa-user"></i>}>&nbsp;Create Member </Button>
                </Link>
            </div>

            {isLoaded &&
            <SearchTable
                columns={columns}
                scroll={{ x: 1600 }}
                url="/members"
                onSelect={onSelectOptions}
                pagination={{showSizeChanger: true}}
                className="noPadding"
            />
            }
        </div>
	);
};