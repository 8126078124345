import React, { useEffect,useContext  } from "react";
import { Button } from 'antd';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import Moment from 'react-moment';
import SearchTable from "../../components/SearchTable";
import useToken from "../../hooks/useToken";

export default function EmailTemplates(props) {

    const { user, setUser } = useToken();
	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([
			<span><i className="fas fa-envelope"/> Email</span>,
            <span><i className="fas fa-columns"/> Template</span>
		]);
	}, []);

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
			sortable: true,
		},
		{
			title: 'Type',
			key: 'type',
            dataIndex: 'type',
			sortable: true,
			width:250
		},
		{
			title: 'Subject',
			key: 'subject',
            dataIndex: 'subject',
			sortable: true
		},
        {
            title: 'Active',
            key: 'active',
            dataIndex: 'active',
            sortable: true,
			width: 80,
            render: (text, record) => {
            	if(!record.status || record.status=='inactive') return '';
                return <div>
						<span className="fa-stack" style={{verticalAlign: "top"}}>
								<i className="fas fa-circle fa-stack-2x" style={{color: "green"}} title="Active"/>
								<i className="fa fa-check fa-stack-1x" style={{color: "white"}} title="Active"/>
						</span>
                    </div>;
            },
        },
		/*
        {
            title: 'Custom',
            key: 'active',
            dataIndex: 'active',
            sortable: true,
            width: 90,
            render: (text, record) => {
                if(!record.access || record.access=='default') return '';
                return <div>
						<span className="fa-stack" style={{verticalAlign: "top"}}>
								<i className="fas fa-circle fa-stack-2x" style={{color: "grey"}} title="Active"/>
								<i className="fa fa-check fa-stack-1x" style={{color: "white"}} title="Active"/>
						</span>
                </div>;
            },
        },
		*/
		{
			title: 'Updated',
			key: 'updated_at',
            dataIndex: 'updated_at',
			sortable: true,
            render: (text, record) => (
                <div>
                    <Moment format="DD/MM/YYYY hh:mm A" unix>{record.updated_at}</Moment>
                </div>
            ),
		},
		{
			title: 'Actions',
            key: 'actions',
			width: 80,
            render: (text, record) => {
                let canEdit = user.type == "admin" || record.access == "custom";

                return <div>
                    <span><NavLink to={"/email/template/" + record.id}><Button type={(canEdit)?"primary":"secondary"} size="small" shape="round">{(canEdit)?<i className="far fa-edit"/> : <i className="fas fa-eye"/>}</Button></NavLink></span>
                </div>
            },
		},
	];

	return (
        <div style={{paddingTop: 50}}>
            <div style={{float:"right",position:"relative",top:-40}}>
                <NavLink to="/email/template/new" activeClassName="current" style={{marginLeft:"auto"}}>
					<Button icon={<i className="fas fa-plus"></i>}>
							&nbsp;Create Templates
					</Button>
                </NavLink>
			</div>
            <SearchTable columns={columns} url="/email/templates"/>
		</div>
	);
};