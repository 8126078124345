import React, { Component } from "react";

class SportsNutrition extends Component {
	render() {

		return (
            <div className="content_inner">
                <div className="title_outer title_without_animation" data-height="200">
                    <div className="title title_size_medium  position_center " style={{"height":"200px"}}>
                        <div className="image not_responsive"></div>
                        <div className="title_holder" style={{"paddingTop":"100px", "height":"100px"}}>
                            <div className="container">
                                <div className="container_inner clearfix">
                                    <div className="title_subtitle_holder">
                                        <h1><span>Clinical Sports Nutrition Services</span></h1>
                                        <span className="separator small center"></span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full_width">
                    <div className="full_width_inner">
                        <div className="vc_row wpb_row section 0 grid_section" style={{"textAlign":"left"}}>
                            <div className=" section_inner clearfix">
                                <div className="section_inner_margin clearfix">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                    <div className="wpb_wrapper">
                                                        <h4 style={{"textAlign":"center"}}>Book your consultation with an
                                                            Accredited Clinical Sports Nutritionist today and experience
                                                            the difference.</h4>
                                                        <p>&nbsp;</p>
                                                        <p>Unlike the current Australian fitness industries Personal
                                                            Trainers who aren’t trained in any form of nutrition
                                                            (regardless of what they claim), and dietitians who are
                                                            clinically trained in treating and preventing disease risk,
                                                            Accredited Sports Nutritionists &amp; Clinical Sports
                                                            Nutritionists are trained specifically in Evidence Based
                                                            Practise to improve your; performance, body-composition and
                                                            metabolic function. These professionals are experts in their
                                                            fields.</p>
                                                        <p>&nbsp;</p>
                                                        <p>From $30/wk for check-in supervision and $99/30 minute
                                                            appointment **NB some initial consults may incur a $250
                                                            setup fee for detailed assessments and testing</p>

                                                    </div>
                                                </div>
                                                <div className="separator  transparent   "
                                                     style={{"marginTop":"60px", "marginBottom":"2px"}}></div>
                                                <div className="separator  transparent   "
                                                     style={{"marginTop":"15px", "marginBottom":"2px"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row wpb_row section 0 vc_custom_1534566040266 full_screen_section"
                             style={{"backgroundColor":"rgba(255,255,255,0.29)", "textAlign":"left"}}>
                            <div className=" full_section_inner clearfix">
                                <div className="wpb_column vc_column_container vc_col-sm-2">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper"></div>
                                    </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-8">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="separator  transparent   "
                                                 style={{"marginTop":"30px", "marginBottom":"2px"}}></div>
                                            <div className="separator  transparent   "
                                                 style={{"marginTop":"30px", "marginBottom":"2px"}}></div>
                                            <div className="separator  transparent   "
                                                 style={{"marginTop":"30px", "marginBottom":"2px"}}></div>

                                            <div className="wpb_text_column wpb_content_element ">
                                                <div className="wpb_wrapper">
                                                    <h2 style={{"textAlign":"center"}}><span style={{"color":"#ffffff"}}>WE WILL MOTIVATE YOU, SUPPORT YOU, AND PROGRAM YOU TOWARDS ACHIEVING YOUR GOALS.</span>
                                                    </h2>

                                                </div>
                                            </div>
                                            <div className="separator  transparent   "
                                                 style={{"marginTop":"60px", "marginBottom":"2px"}}></div>
                                            <div className="separator  transparent   "
                                                 style={{"marginTop":"60px", "marginBottom":"2px"}}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-2">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row wpb_row section 0 grid_section" style={{"textAlign":"left"}}>
                            <div className=" section_inner clearfix">
                                <div className="section_inner_margin clearfix">
                                    <div className="wpb_column vc_column_container vc_col-sm-3">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper"></div>
                                        </div>
                                    </div>
                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="separator  transparent   "
                                                     style={{"marginTop":"60px", "marginBottom":"2px"}}></div>
                                                <div className="q_team" style={{"backgroundColor":"#000000"}}>
                                                    <div className="q_team_inner">
                                                        <div className="q_team_image"><img src="http://metabolichealth.com.au/wp-content/uploads/2015/02/B65A8629-142-use-small.jpg" alt=""/></div>
                                                        <div className="q_team_text">
                                                            <div className="q_team_text_inner">
                                                                <div className="q_team_title_holder"><h3
                                                                    className="q_team_name" style={{"color":"#ffffff"}}>ALEX
                                                                    THOMAS</h3><span style={{"color":"#ffffff"}}>Clinical Sports Nutritionist</span>
                                                                </div>
                                                            </div>
                                                            <div className="q_team_social_holder"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="separator  transparent   "
                                                     style={{"marginTop":"15px", "marginBottom":"15px"}}></div>

                                                <div
                                                    className="q_accordion_holder clearfix wpb_content_element accordion without_icon  not-column-inherit ui-accordion ui-widget ui-helper-reset"
                                                    data-active-tab="" data-collapsible="yes" data-border-radius=""
                                                    role="tablist">

                                                    <h5 className="clearfix title-holder ui-accordion-header ui-helper-reset ui-state-default ui-corner-all"
                                                        role="tab" id="ui-accordion-1-header-0"
                                                        aria-controls="ui-accordion-1-panel-0" aria-selected="false"
                                                        tabIndex="0"><span className="accordion_mark left_mark"><span
                                                        className="accordion_mark_icon"></span></span><span
                                                        className="tab-title">About</span><span
                                                        className="accordion_mark right_mark"><span
                                                        className="accordion_mark_icon"></span></span></h5>
                                                    <div
                                                        className="accordion_content no_icon ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom"
                                                        id="ui-accordion-1-panel-0"
                                                        aria-labelledby="ui-accordion-1-header-0" role="tabpanel"
                                                        aria-expanded="false" aria-hidden="true" style={{"display":"none"}}>
                                                        <div className="accordion_content_inner">

                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p>Alex has 10 years of industry experience and is a
                                                                        specialist in the fields of
                                                                        Sports&nbsp; Nutrition in Australia. He has
                                                                        worked with many elite level athletes from
                                                                        National and International Bodybuilding and
                                                                        Physique Sport athletes, as well as Professional
                                                                        Rugby &amp; Major League Baseball athletes. All
                                                                        the way to aspiring athletes and general
                                                                        population fat loss and metabolism
                                                                        clientele.</p>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <h5 className="clearfix title-holder ui-accordion-header ui-helper-reset ui-state-default ui-corner-all"
                                                        role="tab" id="ui-accordion-1-header-1"
                                                        aria-controls="ui-accordion-1-panel-1" aria-selected="false"
                                                        tabIndex="-1"><span className="accordion_mark left_mark"><span
                                                        className="accordion_mark_icon"></span></span><span
                                                        className="tab-title">Certifications</span><span
                                                        className="accordion_mark right_mark"><span
                                                        className="accordion_mark_icon"></span></span></h5>
                                                    <div
                                                        className="accordion_content no_icon ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom"
                                                        id="ui-accordion-1-panel-1"
                                                        aria-labelledby="ui-accordion-1-header-1" role="tabpanel"
                                                        aria-expanded="false" aria-hidden="true" style={{"display":"none"}}>
                                                        <div className="accordion_content_inner">

                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p>Exercise Science Major</p>
                                                                    <p>Graduate Diploma in Applied Sports Nutrition</p>
                                                                    <p>CISSN</p>
                                                                    <p>Australian Ambassador and Advisory Board member
                                                                        of the ISSN</p>
                                                                    <p>Co Creator of the MHS</p>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="separator  transparent   "
                                                     style={{"marginTop":"50px", "marginBottom":"2px"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpb_column vc_column_container vc_col-sm-3">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row wpb_row section 0 vc_custom_1534570435242" style={{"textAlign":"left"}}>
                            <div className=" full_section_inner clearfix">
                                <div className="wpb_column vc_column_container vc_col-sm-2">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper"></div>
                                    </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-8">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="separator  transparent   "
                                                 style={{"marginTop":"50px", "marginBottom":"2px"}}></div>
                                            <div
                                                className="vc_text_separator wpb_content_element full separator_align_center">
                                                <div style={{"backgroundColor":"#55a330", "color":"#ffffff"}}><span>READY TO START?</span>
                                                </div>
                                            </div>
                                            <div className="separator  transparent   "
                                                 style={{"marginTop":"50px", "marginBottom":"2px"}}></div>
                                            <div role="form" className="wpcf7" id="wpcf7-f634-p617-o1" lang="en-US"
                                                 dir="ltr">
                                                <div className="screen-reader-response"></div>
                                                <form action="/sports-nutrition-brisbane/#wpcf7-f634-p617-o1"
                                                      method="post" className="wpcf7-form" noValidate="novalidate">
                                                    <div style={{"display":"none"}}>
                                                        <input type="hidden" name="_wpcf7" value="634"/>
                                                        <input type="hidden" name="_wpcf7_version" value="4.9.2"/>
                                                        <input type="hidden" name="_wpcf7_locale" value="en_US"/>
                                                        <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f634-p617-o1"/>
                                                        <input type="hidden" name="_wpcf7_container_post" value="617"/>
                                                    </div>
                                                    <p>Name<br/>
                                                        <span className="wpcf7-form-control-wrap your-name">
                                                            <input type="text" name="your-name" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"/>
                                                        </span>
                                                    </p>
                                                    <p>Email<br/>
                                                        <span className="wpcf7-form-control-wrap email">
                                                            <input type="email" name="email" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false"/>
                                                        </span>
                                                    </p>
                                                    <p>Mobile<br/>
                                                        <span className="wpcf7-form-control-wrap mobile">
                                                            <input type="text" name="mobile" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"/>
                                                        </span>
                                                    </p>
                                                    <p>What Suburb Do You Live In?<br/>
                                                        <span className="wpcf7-form-control-wrap suburb">
                                                            <input type="text" name="suburb" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"/>
                                                        </span>
                                                    </p>
                                                    <p>Best Contact Time<br/>
                                                        <span className="wpcf7-form-control-wrap best-contact-time">
                                                            <input type="text" name="best-contact-time" value="" size="40" className="wpcf7-form-control wpcf7-text" aria-invalid="false"/>
                                                        </span>
                                                    </p>
                                                    <p>What Are Your Goals?<br/>
                                                        <span className="wpcf7-form-control-wrap goals">
                                                            <textarea name="goals" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" aria-invalid="false"></textarea>
                                                        </span>
                                                    </p>
                                                    <p>Any Other Information<br/>
                                                        <span className="wpcf7-form-control-wrap other-information">
                                                            <textarea name="other-information" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" aria-invalid="false"></textarea>
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <input type="submit" value="Send" className="wpcf7-form-control wpcf7-submit"/>
                                                        <span className="ajax-loader"></span>
                                                    </p>
                                                    <div className="wpcf7-response-output wpcf7-display-none"></div>
                                                </form>
                                            </div>
                                            <div className="separator  transparent" style={{"marginTop":"50px", "marginBottom":"2px"}}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-2">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
		);
	}
}

export default SportsNutrition;