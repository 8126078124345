import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import moment from 'moment'
import countryList from "react-select-country-list";


export default function Exports(props) {


    const AP = API2();

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-users"/> End Of Month</span>
        ]);
	}, []);


	const data = [
        {
            name: 'Debt Users',
            type: 'debtUsers'
        },
        {
            name: 'All Users',
            type: 'allUsers'
        },
        {
            name: 'Raw Enrolments',
            type: 'rawEnrolments'
        }
    ];

    function downloadExport(type){
        AP.file('/export/'+type);
    }

	const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            sorter: true,
            align:'left'
        },
		{
			title: 'Actions',
            key: 'actions',
            width: "200px",
            render: (text, record) => (
				<div>
                    <Button type="primary" size="small" shape="round" onClick={()=>(downloadExport(record.type))}>
                        <i className="far fa-download"/>&nbsp;Download
                    </Button>
				</div>
			),
		},
	];

	return (

        <div className="content_inner" style={{maxWidth:1300,paddingTop:40}}>
            <SearchTable
                columns={columns}
                data={data}
            />
        </div>
	);
};