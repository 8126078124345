import React, {Component, useContext, useEffect} from "react";

import RegisterAppForm from '../forms/registerApp';

export default function ExpressInterest() {

    return (
		<div className="content_inner">
			<p  className={"rowPaddingLeft"} style={{paddingBottom:"40px"}}>An expressing of interest will send an email to your preferred App\Website letting them know you are interested in integrating Metabolic Health services into them.</p>
            <RegisterAppForm/>
        </div>
    );
}