import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    Checkbox,
    Spin
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";
import countryList from "react-select-country-list";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import useToken from "../hooks/useToken";




const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
};


export default function RegistrySettingsForm(props) {

    const { user:authUser } = useToken();
    const setBreadCrumbs = useContext(BreadCrumbContext);
    const [form] = Form.useForm();
    const [user, setUser] = useState(props.user);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [specializations, setSpecializations] = useState(false);
    const history = useHistory();

    const AP = API2();



    useEffect(() => {
        const data = {training_location:null};

        if(user.registry_settings){
            for (const p in user.registry_settings) {
                data[p] = user.registry_settings[p];
            }
        }

        data.specializations = [];
        if(user.specializations){
            for (const s of user.specializations) {
                data.specializations.push(s.name);
            }
        }

        form.setFieldsValue({
            registry_settings:data,
            practicing_unregistered: props.user?.practicing_unregistered||0
        });
    }, [props.user]);

    useEffect(() => {
        AP.get('/specializations')
            .then(function (res) {
                setSpecializations(res.data);
            });
    }, []);

    function saveForm(data){
        setIsSaving(true);

        let url = '/user/'+props.user.id+'/registrySettings';

        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            if(!props.user.id) {
                history.push("/user/" + res.id);
            }
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }

    const rootContainer = document.getElementById('root');

    if(!specializations){
        return <Spin/>
    }

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="profile"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto",paddingBottom:70}}
        >
            {authUser.type==='admin' && <>
                <h4 style={{paddingLeft:100,margin:0}}>Admin</h4>

                <Form.Item
                    name={'practicing_unregistered'}
                    label="Practicing Unregistered"
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 8 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 6 },
                    }}
                    valuePropName="checked"
                    style={{marginBottom:0}}
                >
                    <Checkbox/>
                </Form.Item>

                {user.accreditation_level === "open" && <>
                    <Form.Item
                        name={["registry_settings", 'specializations']}
                        label="Specialties"
                        labelCol={{
                            xs: { span: 24 },
                            sm: { span: 8 },
                        }}
                        wrapperCol={{
                            xs: { span: 24 },
                            sm: { span: 6 },
                        }}
                    >
                        <Select
                            mode="tags"
                            getPopupContainer={() => rootContainer}
                        >
                            {specializations.map(specialization=>(
                                <Select.Option key={specialization.name} value={specialization.name}>
                                    {specialization.name}
                                </Select.Option>
                            ))}
                            
                        </Select>
                    </Form.Item>
                </>}
            </>}

            <h4 style={{paddingLeft:100,margin:"20px 0 0 0"}}>Preferences</h4>


            <Form.Item
                name={["registry_settings", 'appear_in_registry']}
                label="Appear In Registry"
                labelCol={{
                    xs: { span: 24 },
                    sm: { span: 8 },
                }}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 6 },
                }}
                valuePropName="checked"
				style={{marginBottom:0}}
            >
                <Checkbox/>
            </Form.Item>



            <Form.Item
                name={["registry_settings", 'training_location']}
                label="Training Location"
                labelCol={{
                    xs: { span: 24 },
                    sm: { span: 8 },
                }}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 6 },
                }}
				style={{marginBottom:10}}
            >
                <Select
                    getPopupContainer={() => rootContainer}
                >
                    <Select.Option key="any" value={null}>
                        Any
                    </Select.Option>
                    <Select.Option key={"inperson"} value={"inperson"}>
                        In Person
                    </Select.Option>
                    <Select.Option key={"online"} value={"online"}>
                        Online
                    </Select.Option>
                </Select>
            </Form.Item>

            <h4 style={{paddingLeft:100,margin:"20px 0 0 0"}}>Privacy</h4>

            <Form.Item
                name={["registry_settings", 'allow_email']}
                label="Share Email"
                valuePropName="checked"
                style={{marginBottom:-20}}
            >
                <Checkbox/>
            </Form.Item>

            <Form.Item
                name={["registry_settings", 'show_profile_image']}
                label="Share Profile Image"
                valuePropName="checked"
				style={{marginBottom:-20}}
            >
                <Checkbox disabled={!user.profile_image_id && !user.registry_settings?.show_profile_image}/>
            </Form.Item>

            <Form.Item
                name={["registry_settings", 'show_suburb']}
                label="Share Suburb"
                valuePropName="checked"
				style={{marginBottom:-20}}
            >
                <Checkbox disabled={!user.suburb && !user.registry_settings?.show_suburb}/>
            </Form.Item>

            <Form.Item
                name={["registry_settings", 'show_state']}
                label="Share State"
                valuePropName="checked"
				style={{marginBottom:-20}}
            >
                <Checkbox disabled={!user.state && !user.registry_settings?.show_state}/>
            </Form.Item>

            <Form.Item
                name={["registry_settings", 'show_postcode']}
                label="Share Postcode"
                valuePropName="checked"
				style={{marginBottom:-20}}
            >
                <Checkbox disabled={!user.postcode && !user.registry_settings?.show_postcode}/>
            </Form.Item>

            <Form.Item
                name={["registry_settings", 'show_gender']}
                label="Share Gender"
                valuePropName="checked"
				style={{marginBottom:-20}}
            >
                <Checkbox disabled={!user.gender && !user.registry_settings?.show_gender}/>
            </Form.Item>


            <div>
                <Button type="primary" htmlType="submit" style={{float:"right"}} loading={isSaving} style={{float:"right"}}>{(isSaving)?'Saving':'Save'}</Button>
            </div>
        </Form>
	);
}