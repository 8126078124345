import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import API from "../components/api";
import useToken from "../hooks/useToken";
import {notification} from "antd/lib/index";
import API2 from "../components/api2";
import moment from "moment/moment";


const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function Register(props) {
    const [form] = Form.useForm();
    const AP = API2();
    const history = useHistory();
    const { user, setUser } = useToken();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
       if(props.coach){
           let data = {};
           if(user) data = user;

           data.coach_name = props.coach.name;

           if(data.dob && Number.isInteger(data.dob)) {
               data.dob = moment(data.dob * 1000);
           }
           setData(data);
           form.setFieldsValue(data);
           setLoading(false);
       }
    }, [props]);


    const handleSubmit = async (data) => {

        let url;
        if(user && user.id){
            url = '/client/'+user.id;
        }else{
            url = '/coach/'+props.coach.id+'/client';
        }

        if(data.dob) {
            data.dob = data.dob.unix();
        }

        AP.post(url,data)
            .then(function(res){
                notification.success({
                    message: `Saved`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });

                if(user && user.id){
                    user.pending = false;
                    setUser(user);
                    history.push("/coach/"+props.coach.id+"/riskAssessment")
                }else{
                    setUser(res.user, res.access_token);
                    window.location.replace("/dashboard");
                }

            })
            .catch(function(res){
                if(res.response.data.errors) {
                    let errorList = {};
                    for (let error of res.response.data.errors) {
                        if (!errorList[error.input]) errorList[error.input] = [];
                        errorList[error.input].push(error.message);
                    }
                    for (let [input, errors] of Object.entries(errorList)) {
                        form.setFields([
                            {
                                name: input,
                                errors: errors,
                            },
                        ]);
                    }
                }
            });
    };



    return(
        <>

            {!loading && (
                props.coach && (
                    <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={handleSubmit}
                    scrollToFirstError
                    style={{margin: "auto"}}
                >

                    <Form.Item
                        name='coach_name'
                        label="Coach Name"
                    >
                        <Input className="readOnly" readOnly/>
                    </Form.Item>

                    <Form.Item
                        name="first_name"
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your first name!',
                                whitespace: true
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="last_name"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your last name!',
                                whitespace: true
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                    <Form.Item
                        name="gender"
                        label="Gender"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a gender',
                            }
                        ]}
                    >
                        <Select
                            placeholder="Select a option and change input text above"
                        >
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                        </Select>
                    </Form.Item>

                        <Form.Item
                            name="mobile"
                            label="Mobile"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="dob"
                            label="Date Of Birth"
                            help="DD/MM/YYYY"
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <DatePicker format="DD/MM/YYYY"/>
                        </Form.Item>

                        <Form.Item
                            name="height"
                            label="Height"
                        >
                            <Input addonAfter="cm"/>
                        </Form.Item>

                        <Form.Item
                            name="weight"
                            label="Weight"
                        >
                            <Input addonAfter="kg"/>
                        </Form.Item>



                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <div style={{display: "flex"}}>
                        <Button type="primary" htmlType="submit" style={{marginLeft: "auto"}}>
                            {data.id?'Update Details':'Register'}
                        </Button>
                    </div>
                </Form>
                )
                || <div>Invalid Join Url</div>
            )
            }

        </>

    )
}

