import React, { useState, useEffect,useContext  } from "react";
import { Button,Modal } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../contexts/breadCrump";
import API2 from "../components/api2";
import TermsAndConditionsText2 from "./TermsAndConditionsText2";
import {notification} from "antd/lib/index";

export default function TermsAndConditions(props) {


    const AP = API2();
    const [modelOpen, setModalOpen] = useState(false);
    const [status, setStatus] = useState(true);

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-users"/> Terms And Conditions</span>
        ]);

        AP.get('/my/user')
            .then(function (user) {
                setStatus(user.requesting_cancel);
            });

	}, []);


	return (
        <div className="content_inner" style={{paddingTop: 50}}>

            <TermsAndConditionsText2/>

        </div>
	);
};