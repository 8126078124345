import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Select,
    Checkbox,
    DatePicker,
} from 'antd';
import moment from "moment/moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function ComparisonGraph(props) {


    const AP = API2();

    var CanvasJSReact = require('../assets/canvasjs.react');
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.default.CanvasJSChart;

	const [comparisonGraphData, setComparisonGraphData] = useState({'ti':[], 'tiC':[], 'tmi':[], 'tmiC':[], 'tai':[], 'taiC':[], 'eti':[], 'etmi':[], 'etai':[]});

    const [comparisonGraphNS, setGraphGirthDataNS] = useState([]);
    const [comparisonGraphES, setGraphGirthDataES] = useState([]);
    const [canvasOptions, setCanvasOptions] = useState({

    });

    const [comparisonGraphOptions, setComparisonGraphOptions] = useState({

    });
    const [comparisonGraphBaseTime, setComparisonGraphBaseTime] = useState([]);
    const [comparisonGraphCompareTime, setComparisonGraphCompareTime] = useState();


    useEffect(() => {

        const graphOptions = {
            axisX:{
                valueFormatString: "DD MMM YYYY",
            },
            axisY: [],
            axisY2: [],
            backgroundColor: "transparent",
            data: []
        };

		for(const p of props.data.currentExpected){
			p.x = new Date(p.x*1000);
			p.y = p.y/100;
		}

		for(const p of props.data.currentActual){
			p.x = new Date(p.x*1000);
			p.y = p.y/100;
		}

		for(const p of props.data.pastActual){
			p.x = new Date(p.x*1000);
			p.y = p.y/100;
		}


		graphOptions.axisY.push({
			title: "Actual Expected",
			lineColor: "#8b4fbc",
			titleFontColor: "#8b4fbc",
			labelFontColor: "#8b4fbc",
			valueFormatString: "#,##0.##",
			prefix: "$"
		});

		graphOptions.data.push({
			type: "line",
			showInLegend: true,
			axisYIndex: 0, //Defaults to Zero
			name: "Actual Expected",
			dataPoints: props.data.currentExpected,
			xValueFormatString: "DD MMM",
			lineColor: "#8b4fbc",
			markerColor:"#8b4fbc",
			fillOpacity: .9,
			toolTipContent: "{x}: ${y}",
		});


		graphOptions.axisY.push({
			title: "Actual Current",
			lineColor: "#4f81bc",
			titleFontColor: "#4f81bc",
			labelFontColor: "#4f81bc",
			valueFormatString: "#,##0.##"
		});

		graphOptions.data.push({
			type: "line",
			showInLegend: true,
			axisYIndex: 0, //Defaults to Zero
			name: "Actual Current",
			dataPoints: props.data.currentActual,
			xValueFormatString: "DD MMM",
			lineColor: "#4f81bc",
			markerColor:"#4f81bc",
			fillOpacity: .9,
			toolTipContent: "{x}: ${y}",
		});


		graphOptions.axisY.push({
			title: "Actual Past",
			lineColor: "#4f81bc",
			titleFontColor: "#34547b",
			labelFontColor: "#34547b",
			valueFormatString: "#,##0.##"
		});

		graphOptions.data.push({
			type: "line",
			showInLegend: true,
			axisYIndex: 0, //Defaults to Zero
			//axisYType: "secondary",
			name: "Actual Past",
			dataPoints: props.data.pastActual,
			xValueFormatString: "DD MMM",
			lineColor: "#34547b",
			markerColor:"#34547b",
			fillOpacity: .9,
			toolTipContent: "{x}: ${y}",
		});

		graphOptions.axisX = {
			valueFormatString: "DD MMM"
		}

        setCanvasOptions(graphOptions);

    }, []);



    return(
        <>
            <div style={{display:"flex",gap:40}}>
                <h4>Progress Charts</h4>
            </div>

            <CanvasJSChart options={canvasOptions} />
        </>
	);
}