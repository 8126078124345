import '../App.css';
import {BrowserRouter, NavLink , Route, Switch } from "react-router-dom";
import Footer from "../layout/footer";
import RegistryPage from "../pages/Registry/Registry";



function RegistyLayout(props) {

	return (
		<BrowserRouter>
            <div className="wrapper_inner">
                <div className="content">
                    <Switch>
                        <Route path="/:region?" component={RegistryPage}/>
                    </Switch>
                </div>
                <Footer></Footer>
            </div>

            <link rel="stylesheet" id="gforms_reset_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formreset.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_formsmain_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formsmain.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_ready_class_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/readyclass.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_browsers_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/browsers.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="global-styles-inline-css" href="/css/global-styles-inline.css"></link>
            <link rel="stylesheet" id="theme-css" href="/css/wp_style.css" media="all"/>

		</BrowserRouter>
	);
}

export default RegistyLayout;



