import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input,Radio,Divider,Select, Space } from 'antd';
import {NavLink} from "react-router-dom";
import API2 from "./api2";
import useWindowDimensions from "./useWindowDimensions";
import { SearchOutlined } from '@ant-design/icons';


const { Option } = Select;


export default function SearchTable(props) {

    const [loading, setLoading] = useState(true);
    const [isInitialising, setIsInitialising] = useState(true);
    const [data, setData] = useState([]);
    const AP = API2();


    let tableId = window.location.pathname;
    if(props.id){
        tableId = props.id+tableId;
    }

    let defaultPageSize;
    if(localStorage) defaultPageSize = localStorage.getItem(tableId+'-pageSize');
    if(!defaultPageSize) defaultPageSize = 10;


    const [dataSize, setDataSize] = useState(0);
    const [pageSize, setPageSize] = useState(parseInt(defaultPageSize));
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState();
    const [paginationSettings, setPaginationSettings] = useState({});
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [filters, setFilters] = useState();
	const [queryFilters, setQueryFilters] = useState(props.filters);
    const [sorter, setSorter] = useState();
    const [searchText, setSearchText] = useState('');
    const [selectable, setSelectable] = useState(false);
    const [bulkAction, setBulkAction] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [hasSearch, setHasSearch] = useState(true);
    const [containerHeight, setContainerHeight] = useState(false);
    const [scroll, setScroll] = useState(props.scroll);
    const [forceReload, setForceReload] = useState('');

    const { height, width } = useWindowDimensions();

    function search(value){
        // setPagination(1); why?
        setSearchText(value);
    }

	function tableChange(p, f, s) {
        setPagination(p);
        setFilters(f);
        setSorter(s);
        setPageSize(p.pageSize);
        if(localStorage){
            localStorage.setItem(tableId + '-pageSize', p.pageSize);
        }
    }

    const rowSelection = {
        onChange: function(selectedRowKeys: React.Key[], selectedRows: DataType[]){
            setSelectedKeys(selectedRowKeys);
            setSelectedData(selectedRows);
        }
        /*
        getCheckboxProps: (record: DataType) => ({
            disabled: record.id === 1083, // Column configuration not to be checked
            name: record.name,
        }),
        */
    };



    useEffect(() => {
        if(props.search === false){
            setHasSearch(false);
        }

        let pag = {
            position: [(numberOfPages<=1)?'none':'bottomRight'],
            pageSize: pageSize,
            total:    dataSize,
           // hideOnSinglePage: true
        };

        if(props.pagination){
            if(props.pagination.position) pag.position = props.pagination.position;
            if(props.pagination.pageSize){
                pag.pageSize = props.pagination.pageSize;
                setPageSize(pag.pageSize);
            }
            if(props.pagination.total) pag.total = props.pagination.total;
            if(props.pagination.showSizeChanger){
                pag.showSizeChanger = props.pagination.showSizeChanger;
                pag.position = ['bottomRight'];
            }
        }
        setPaginationSettings(pag);

    }, [pageSize,dataSize]);

    //check columns
    useEffect(() => {
        let cols = [];

        //check if actually changed
        if(JSON.stringify(columns) == JSON.stringify(props.columns)) return true;

        myloop:
            for(let c of props.columns){

                if(c.defaultSortOrder){
                    /*
                    {
                        "column": {
                        "title": "Starts At",
                            "key": "start_at",
                            "dataIndex": "start_at",
                            "align": "left",
                            "sorter": true,
                            "defaultSortOrder": "descend"
                    },
                        "order": "ascend",
                        "field": "start_at",
                        "columnKey": "start_at"
                    }
                    */

                    setSorter({
                        "order": c.defaultSortOrder,
                        "field": c.dataIndex,
                        "columnKey": c.key
                    });

                }

                if(c.searchable){

					var searchInput1;
					var searchInput2;

                    if(c.searchable == 'range'){
						c.filterDropdown = ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
						    let bits = [];
						    if(selectedKeys[0]){
								bits = selectedKeys[0].split(",");
                            }

							return <div style={{padding: 8}}>
								<div style={{display: "flex"}}>
									<Input
										ref={node => {
											searchInput1 = node;
										}}
										placeholder="`Min Value`"
										value={bits[0]}
										onChange={e => {
											bits[0] = e.target.value ? [e.target.value] : null;
											setSelectedKeys([bits.join(",")]);
                                        }}
										onPressEnter={() => confirm()}
										style={{marginBottom: 8, display: 'block'}}
									/>
									<span>-</span>
									<Input
										ref={node => {
											searchInput2 = node;
										}}
										placeholder="`Max Value`"
										value={bits[1]}
										onChange={e => {
											bits[1] = e.target.value ? [e.target.value] : null;
											setSelectedKeys([bits.join(",")]);
										}}
										onPressEnter={() => confirm()}
										style={{marginBottom: 8, display: 'block'}}
									/>
								</div>
								<Space>
									<Button
										type="primary"
										onClick={() => confirm()}
										icon={<SearchOutlined/>}
										size="small"
										style={{width: 90}}
									>
										Search
									</Button>
									<Button onClick={function () {
										setSelectedKeys("");
										confirm();
									}} size="small" style={{width: 90}}>
										Reset
									</Button>
								</Space>
							</div>
						};
                    }
                    else {

						c.filterDropdown = ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
							<div style={{padding: 8}}>
								<Input
									ref={node => {
										searchInput1 = node;
									}}
									placeholder={`Search Name`}
									value={selectedKeys[0]}
									onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
									onPressEnter={() => confirm()}
									style={{marginBottom: 8, display: 'block'}}
								/>
								<Space>
									<Button
										type="primary"
										onClick={() => confirm()}
										icon={<SearchOutlined/>}
										size="small"
										style={{width: 90}}
									>
										Search
									</Button>
									<Button onClick={function () {
										setSelectedKeys("");
										confirm();
									}} size="small" style={{width: 90}}>
										Reset
									</Button>
								</Space>
							</div>
						);
					}

                    c.filterIcon = filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;

                    c.onFilterDropdownVisibleChange = visible => {
                        if (visible) {
                            setTimeout(() => searchInput1.select(), 100);
                        }
                    }
                }

                if(c.responsive) {
                    for (let wi in c.responsive) {
                        let w = c.responsive[wi];
                        if (w == 'max_md' && width > 768) continue myloop;
                        else if (w == 'max_lg' && width > 992) continue myloop;
                        else if (w == 'max_xl' && width > 1200) continue myloop;

                        if(["max_md","max_lg","max_xl"].includes(w)){
                            c.responsive.splice(wi, 1);
                            if(!c.responsive.length) c.responsive = null;
                        }
                    }
                }



                cols.push(c);
            }

        setColumns(cols);

        getData();

    }, [props.columns]);

	useEffect(() => {
		if(JSON.stringify(props.filters) === JSON.stringify(queryFilters)) return true;

        setQueryFilters(props.filters);
        getData();

	}, [props.filters]);

    useEffect(() => {
        getData();
    }, [pagination,filters,sorter,searchText,pageSize]);

    useEffect(() => {
        if(props.container === true) return;
        if(!props.container || !props.container.elementRef || !props.container.elementRef.current) return;
        if(props.container.elementRef.current.children){
            setContainerHeight(props.container.elementRef.current.children[props.containerIndex].offsetHeight);
        }
    }, [props.container]);

    useEffect(() => {
        if(!containerHeight) return false;

        console.log(["props.rowHeight",props.rowHeight]);

        if(props.rowHeight){
            let x = (containerHeight - 150)/props.rowHeight;
            //not sure about this
           // debugger;
           // setIsInitialising(false);
            setPageSize(parseInt(x));
        }
        else{
            const localScroll = scroll?scroll:{};
			localScroll.y = (containerHeight-210);
            setScroll({...localScroll});
            getData();
        }

    }, [containerHeight]);

    useEffect(() => {
        if(props.forceReload && props.forceReload == forceReload) return true;
        getData();

    }, [props.forceReload]);

    useEffect(() => {
        if(props.data) {
            setIsInitialising(false);
            getData();
        }
    }, []);

    useEffect(() => {
        if(isInitialising) return true;
        getData();
    }, [isInitialising]);

    useEffect(() => {
        //if(props.container) return true;
        setTimeout(function(){
            setIsInitialising(false);
        },1);
    }, []);

    function massEditClick(){
        if(selectable){
            runBulkAction();
        }
        setSelectable(!selectable);
    }
    function runBulkAction(){
        if(selectedKeys.length === 0) return true;

        for(let o of props.onSelect){
            if(o.value==bulkAction){
                o.onSelect(selectedKeys,selectedData);
                break;
            }
        }
        setSelectedKeys([]);
    }

    function getData(){
        if(isInitialising){
            return false;
        }

     //   debugger;
       // if(loading) return true;

        //check if still waiting when dynamic height
        if(props.container && !containerHeight) return true;
        if(!props.url && !props.data) return true;

        setLoading(true);


        let url = props.url;
        let params = [];

        if(pagination) {
            if(pagination.current)  params.push(['page',pagination.current]);
            if(pagination.pageSize) params.push(['pageSize',pagination.pageSize]);
        }else if(props.pagination !== false){
            params.push(['pageSize',pageSize]);
        }

        if(searchText) params.push(['search',searchText]);

        if(sorter && sorter.order){
            params.push(['sort',sorter.field]);
            params.push(['sortOrder',sorter.order]);
        }


        if(props.filters){
            for(let k in props.filters){
                params.push([k,props.filters[k]]);
            }
        }
        if(filters) {
            for (const [k, value] of Object.entries(filters)) {
                if(value != null) {
                    params.push([k, value]);
                }
            }
        }


        if(props.data){
            let respFunc = function(res){
                if(props.formatData){
                    res.data = props.formatData(res.data);
                }

                setNumberOfPages(res.total_records/pageSize);

                setData(res.data);
                setDataSize(res.total_records);
                setLoading(false);
            };
            if(typeof props.data == "function"){
                return props.data(params,respFunc);
            }else{
                setData(props.data);
                setDataSize(props.data.length);
                setLoading(false);
            }

            return;
        }

        if(params){
            let paramsArr = [];
            for(let x of params){
                paramsArr.push(x[0]+'='+x[1]);
            }

            if(url.indexOf("?") === -1) {
                url += "?" + paramsArr.join("&");
            }else{
                url += "&" + paramsArr.join("&");
            }
        }

        AP.get(url)
            .then(function(res){
                if(props.formatData){
                    res.data = props.formatData(res.data);
                }

                setNumberOfPages(res.total_records/pageSize);

                setData(res.data);
                setDataSize(res.total_records);
                setLoading(false);
            });
    }

    const columnsz = [
        {
            title: 'Age',
            dataIndex: 'age',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.age - b.age,
        }
    ];

	return (
        <>
            <div style={{position: "relative", top: -40, display:"flex", alignItems:"center"}}>
                {hasSearch &&
                <Input.Search
                    placeholder="Search..."
                    onSearch={search}
                    allowClear={true}
                    style={{width: "300px"}}
                />
                }

                {props.onSelect &&
                <>
                    <Button
                        icon={<i className=" fad fa-repeat-1"></i>}
                        type="default"
                        shape="round"
                        style={{marginLeft:60}}
                        onClick={massEditClick}
                        className={selectable?"btn-blue":""}
                    >&nbsp;Mass Edit</Button>
                    {selectable &&
                    <Select
                        style={{width: 200, marginLeft: 20}}
                        placeholder="Bulk Action"
                        className="massSelect"
                        onChange={(v) => (setBulkAction(v))}
                        //disabled={!paymentMethod}
                    >

                        {props.onSelect.map(option =>
                            <Option value={option.value}>{option.name}</Option>
                        )}
                    </Select>
                    }
                </>
                }
            </div>
            {!isInitialising &&
            <Table
                dataSource={data}
                loading={loading}
                onChange={tableChange}
                tableLayout="fixed"
                rowSelection={(selectable) ? rowSelection : ''}
                rowKey={props.rowKey ? props.rowKey : "id"}
                {...props}
                columns={columns}
                scroll={scroll}
                pagination={paginationSettings}
                className={(numberOfPages <= 1) ? props.className + ' noPagination' : props.className}
            />
            }
		</>
	);
};