import React, { useState, useEffect,useContext  } from "react";
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import EmailTemplateForm from '../../forms/emailTemplate';
import API2 from "../../components/api2";
import { Spin } from 'antd';

export default function EmailTemplate(props) {
    const [data, setData] = useState(false);

    let id = props.match.params.id;

    const AP = API2();
    useEffect(() => {
            AP.get('/email/template/' + id)
                .then(function (res) {
                    setData(res);
                });
    }, []);

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([
			<span><i className="fas fa-envelope"/> Email</span>,
            <NavLink to="/email/templates"><i className="fas fa-columns"/> Templates</NavLink>,
			(id!='new')?(data)?<span><i className="far fa-window-maximize"/> {data.template.name}</span>:<Spin size="small" />:<span><i className="fas fa-plus"/> New</span>
		]);
	}, [data]);


	return (
        <div className="content_inner">
            <EmailTemplateForm id={id} data={data}></EmailTemplateForm>
        </div>
	);
};