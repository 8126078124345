import React from "react";
import {
	Collapse
} from 'antd';

export default function AuthCard() {
    return (
		<>
			<h2 style={{marginTop: "40px"}}>Authorization</h2>

			<Collapse accordion style={{marginTop: "40px"}}>
				<Collapse.Panel header="oauth2 - authorizationCode" key="1">
					<ul>
						<li><strong>Flow</strong>: authorizationCode</li>
						<li><strong>Authorization URL</strong>: https://zoom.us/oauth/authorize</li>
						<li><strong>Token URL</strong>: https://zoom.us/oauth/token</li>
					</ul>
				</Collapse.Panel>
			</Collapse>
		</>
    );
}

