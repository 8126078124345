import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input,Checkbox } from 'antd';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import {Modal} from "antd/lib/index";
import { ExclamationCircleOutlined  } from '@ant-design/icons';
import {notification} from "antd/lib/index";

const { confirm } = Modal;


export default function PlanList(props) {

    const AP = API2();
	const setBreadCrumbs = useContext(BreadCrumbContext);
    const [showPrivate, setShowPrivate] = useState(true);
    const [forceReload, setForceReload] = useState(0);
    const [pageLoading, setPageLoading] = useState(true);

	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-box-usd"/> Plans</span>
        ]);
        setPageLoading(false);
	}, []);

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
            sorter: true,
        	align:'left'
		},
        {
            title: 'For',
            key: 'for',
            dataIndex: 'for',
            sorter: true,
            align:'left',
            width: '200px'
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount',
            sorter: true,
            align:'left',
            width: '200px',
            render: function(text, record) {
                if (!record.cost) {
                    let amount = 0;
                    let per = '';
                    for (let payment of record.payments) {
                        let l = (payment.billing_rounds) ? payment.billing_rounds : 1;
                        let n = (payment.billing_length) ? payment.billing_length : 1;
                        amount += payment.amount * l * n;
                        per = payment.billing_duration;
                    }
                    return <div>${amount / 100} per {record.payments.length == 1 ? per : 'mixed'}</div>
                }

                let html = [];
                const usedCur = [];
                if (record.regional) {
                    for (let [region, cost] of Object.entries(record.cost)) {

                        if (usedCur.includes(cost[1])) continue;
                        usedCur.push(cost[1]);

                        html.push(<div key={region + "_amount_col"}>${cost[0] / 100} {cost[1]}</div>);
                    }
                }else{
                    html.push(<div>${record.cost[0] / 100} {record.cost[1]}</div>);
                }

                return html
            }
        },
        {
            title: 'Starts On',
            key: 'starts_at',
            dataIndex: 'starts_at',
            sorter: true,
            align:'left',
            width: '200px',
            defaultSortOrder: 'ascend',
            render: function(text, record) {
				if(!record.starts_at) return '';
                return <Moment format="Do MMM YYYY" unix>{record.starts_at}</Moment>
            }
        },
		{
			title: 'Actions',
            key: 'actions',
            render: (text, record) => (
				<div>
					<span><NavLink to={record.for == "member"?"/plan/"+record.id:"/planGroup/"+record.name}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
				</div>
			),
            width: '200px'
		},
	];
    const historyColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            sorter: true,
            align:'left'
        },
        {
            title: 'For',
            key: 'for',
            dataIndex: 'for',
            sorter: true,
            align:'left',
            width: '200px'
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount',
            sorter: true,
            align:'left',
            width: '200px',
            render: function(text, record) {

                let html;
                let amount = 0;
                const usedCur = [];
                for (let [region, cost] of Object.entries(record.cost)) {
                    if(usedCur.includes(cost[1])) continue;
                    usedCur.push(cost[1]);

                    html += <div key={region+"_amount_col"}>${cost[0]/100} {cost[1]}</div>
                }

                return html
            }
        },
        {
            title: 'Replaced On',
            key: 'deleted_at',
            dataIndex: 'deleted_at',
            sorter: true,
            align:'left',
            width: '200px',
            render: (text, record) => (
                <Moment format="Do MMM YYYY h:mm a" unix>{record.deleted_at}</Moment>
            ),
        },
    ];

    function onShowPrivateChange(e){
        setShowPrivate(e.target.checked);
    }

    const onSelectOptions = [
        {
            name: "Delete",
            value: "delete",
            onSelect: function(selectedKeys,SelectedData){

                const ids = [];
                for(const row of SelectedData){
                    ids.push(...row.ids.split(','));
                }

                confirm({
                    title: 'Are you sure you want to delete '+(ids.length)+' plans?',
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                        AP.delete('/plans',{ids:ids})
                            .then(function(res){
                                notification.success({
                                    message: `Deleted`,
                                    placement: 'bottomRight',
                                    duration:1,
                                    closeIcon : (<div></div>)
                                });

                                setForceReload(forceReload+1);
                            })
                            .catch(function(res){
                                console.log(res);
                            });



                    },
                    onCancel() {
                        return;
                    },
                });
            }
        }
    ];

	return (
        <div className="content_inner">
            <div style={{paddingTop:40}}>
                <div style={{float:"right",position:"relative",top:-40, display:"flex"}}>

                    <div style={{paddingRight:20}}>
                        <Checkbox checked={showPrivate} onChange={onShowPrivateChange}>Show Private</Checkbox>
                    </div>

                    <NavLink to="/plan/new" activeClassName="current" style={{marginLeft:"auto"}}>
                        <Button icon={<i className="fal fa-box-usd"/>}>&nbsp;Create Plan </Button>
                    </NavLink>
                </div>

                <SearchTable
                    columns={columns}
                    url="/plans?group=name"
                    pagination={{pageSize: 99999}}
                    onSelect={onSelectOptions}
                    filters={{showPrivate: showPrivate}}
                    expandable={{
                        expandedRowRender: function (record) {

                            return <div style={{padding: 10}}>

                                {((record.history.length ? true : false) && <>

                                        <h4 style={{textAlign: 'left'}}>History</h4>
                                        <Table
                                            dataSource={record.history}
                                            tableLayout="fixed"
                                            pagination={false}
                                            rowKey="id"
                                            columns={historyColumns}
                                        />
                                    </>
                                ) || <h4 style={{paddingTop: 20, textAlign: 'left'}}>No History</h4>}
                            </div>
                        },
                    }}
                    rowClassName={function (record, index) {
                        if (record.private) {
                            return "greyRow";
                        }
                        return "";
                    }}
                    forceReload={forceReload}
                />

            </div>
		</div>
	);
};