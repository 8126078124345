import React, {} from "react";

import "./../css/termsAndConditions2.css"



export default function TermsAndConditionsText2(props) {


    return (
        <div className="woocommerce-terms-and-conditions" style={{padding:15}}>
			<div className="elementor-container elementor-column-gap-default importedTermsAndConditions">
				<div
					className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6022b4f"
					data-id="6022b4f" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div
							className="elementor-element elementor-element-9be214c elementor-widget elementor-widget-heading"
							data-id="9be214c" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">1. Definitions and
									Interpretation</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-13f3bc0 elementor-widget elementor-widget-text-editor"
							data-id="13f3bc0" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p dir="ltr"
								   style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
									<span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>In these Terms and Conditions, a reference to the following words has the meaning set out adjacent to them, unless the context in which the words are used requires otherwise:</span>
								</p><p dir="ltr"
									   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Application</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}> means your application to enrol in a course that is offered by Sports Nutrition Group (or any of its constituent companies), which may without limitation take the form of an online enrolment application available through the website of a Sports Nutrition Group company.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Accreditation</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>&nbsp;means the status conferred upon satisfaction of specific requirements outlined in the Membership Schedule and the subsequent approval of one or more of the members of the Sports Nutrition Group (at the discretion of the Sports Nutrition Group).&nbsp;&nbsp;</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Agreement</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>&nbsp;means the Registration Form, your Application,&nbsp; any Direct Debit Request and Direct Debit Agreement that you have completed, these Terms and Conditions and any schedule, annexure or other attachment and any other practice, rules, or directions reasonably provided by the Sports Nutrition Group.&nbsp;</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Confidential Information</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>&nbsp;includes any information which either party accesses or which is communicated to one party (Receiving Party) by the other (Disclosing Party) in the course of Your Accreditation, Registered Membership, and completion of the Course which:</span>
							</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>is identified as confidential; or</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>which the Receiving Party should reasonably expect to be confidential.</span>
									</p></li>
								</ol>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}} start="2">
									<li dir="ltr"
										style={{listStyleType:"decimal", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre",marginLeft:"-18pt"}}
										aria-level="1">&nbsp;</li>
								</ol>
								<p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
									<span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>It includes, but is not limited to:</span>
								</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>any trade secrets or information relating to the Sports Nutrition Group, other Registered Members, or clients;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>client lists or requirements;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>suppliers;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>terms of trade;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>pricing lists;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>pricing structures;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>marketing information and plans;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Intellectual Property;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>inventions;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>business plans or dealings;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>technical data;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>employees or officers;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>financial information and plans;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>designs;&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>research activities; and</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>software, and the source code of any such software, of the Sports Nutrition Group.</span>
									</p></li>
								</ol>
								<p><b id="docs-internal-guid-db32f634-7fff-662f-4a4b-7afcd049beab"
									  style={{fontWeight:"normal"}}>&nbsp;</b></p><p dir="ltr"
																					style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>It does not include information which:</span>
							</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}} start="17">
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>is generally available in the public domain; and/or</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="2"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>is required to be disclosed by law by the Receiving Party.</span>
									</p></li>
								</ol>
								<p><b style={{fontWeight:"normal"}}>&nbsp;</b></p><p dir="ltr"
																					style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Course </span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means the course detailed in the Enrolment Schedule.&nbsp;</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Deferral and Extension Fees</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}> means those fees set out in the Enrolment Schedule.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Direct Debit Request</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}> means an application to request that Sports Nutrition Group directly debit payments from your nominated banking account, which will take the form prescribed by Sports Nutrition Group from time to time.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Direct Debit Agreement</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}> means the agreement&nbsp; provided to you by Sports Nutrition Group enabling it to directly debit payments from your nominated banking account, which will take the form prescribed by Sports Nutrition Group from time to time.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Dishonour Fee </span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means the Dishonour Fee referred to in the Membership Schedule.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Enrolment Fees</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}> mean the fees payable by you to Sports Nutrition Group for participation in a Course, as calculated in accordance with clause 8.1.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Facilities&nbsp;</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>includes but is not limited to tools, equipment, machinery, weights, or other amenities of one or more members of the Sports Nutrition Group at the Location.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Insurance </span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means the insurance required to be held by each Registered Member pursuant to the requirements of the Sports Nutrition Group as amended from time-to-time, which at the time of these Terms &amp; Conditions is provided by Arthur J Gallagher &amp; Co.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Intellectual Property</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>&nbsp;means any and all intellectual and industrial property rights throughout the world, whether subsisting now or in the future and includes all copyright and analogous rights, all rights in relation to inventions (including patent rights), registered and unregistered trademarks, designs (whether or not registered or registrable), circuit layouts, trade names, trade secrets, business names, company names or internet domain names and copyright.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Location&nbsp;</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means any establishment or venue where the Services are carried out.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Membership Fees </span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means the annual fee payable in order for you to maintain your status as a Registered Member, calculated in accordance with clause 8.4.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Membership Schedule&nbsp;</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means the membership schedule attached at Schedule 1.&nbsp;&nbsp;</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Registration Form</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>&nbsp;means the Registration Form submitted with and for Your application for Accreditation and Registered Membership;</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Registered Membership</span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>&nbsp;means Your membership with the Association, after approval by one or more of the Sports Nutrition Group (as its discretion), creating a Registered Member.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Reinstatement Fee </span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means the reinstatement fee contained in the Membership Schedule.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Services </span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means the service offerings You provide as a Registered Member.</span>
							</p><p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"36pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
								<span
									style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group </span><span
								style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means Sports Nutrition Association Pte. Ltd (UEN 201931455D) and Sports Nutrition Australia Pty Ltd (ACN 621 972 638) and Regulatory Body for Sports Nutrition Australia Inc (ABN 96 569 070 212), or any of them as the context of these terms requires..</span>
							</p><p><span
								style={{fontSize:"9.5pt", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You/Your </span><span
								style={{fontSize:"9.5pt", color:"#000000", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>means the Registered Member. </span>
							</p></div>
						</div>
						<div
							className="elementor-element elementor-element-d0a79e7 elementor-widget elementor-widget-heading"
							data-id="d0a79e7" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h3 className="elementor-heading-title2 elementor-size-default">1.1 Interpretation</h3>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-4c5fbca elementor-widget elementor-widget-text-editor"
							data-id="4c5fbca" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Words denoting the singular number include the plural, and vice versa.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Words denoting any gender include all genders.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>All references to “$” and “dollars” and “USD” are to the lawful currency of the United States of America. Unless otherwise states all monetary figures are in the lawful currency of the United States of America.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Headings are for convenience only and do not affect interpretation.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>References to clauses and sub-clauses are references to clauses and sub-clauses of these Terms &amp; Conditions.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Where any of the Parties hereto comprises more than one person, any covenants, agreement, or obligation undertaken, or benefits received by virtue of this Deed, shall be undertaken, or received by all of them jointly and each of them severally.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Reference to any legislation or any section or provision thereof includes any statutory modification or re-enactment, or any statutory provision substituted including any ordinances, by-laws, regulations, or other statutory instruments issued under such legislation.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>References to Sports Nutrition Group include references to each company forming the Sports Nutrition Group as the context and circumstances require.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Other forms of defined words have corresponding meanings.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-ed87738 elementor-widget elementor-widget-heading"
							data-id="ed87738" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">2. Acceptance and
									Commencement</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-e4c81c2 elementor-widget elementor-widget-text-editor"
							data-id="e4c81c2" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>This Agreement commences on the date that:</span>
									</p>
										<ol style={{paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>in relation to your Application to participate in a Course, Sports Nutrition Group accepts your Application to enrol in the relevant Course; or</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>in relation to your application to become a Registered Member, Sports Nutrition Group accepts your Registration Form.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>By making an Application or submitting a Registration Form, you are agreeing to be bound by these Terms and the terms of our Agreement.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-bf44769 elementor-widget elementor-widget-heading"
							data-id="bf44769" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">3. Scope of This
									Agreement</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-24af88f elementor-widget elementor-widget-text-editor"
							data-id="24af88f" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p><span id="docs-internal-guid-d4367425-7fff-bd86-840c-d1550d9c1d2a"><span
									style={{fontSize:"9.5pt", color:"#000000", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>This Agreement does not create any fiduciary, employment, agency, or partnership relationship between You and any or all of the members of the Sports Nutrition Group.</span></span>
								</p></div>
						</div>
						<div
							className="elementor-element elementor-element-d4e747e elementor-widget elementor-widget-heading"
							data-id="d4e747e" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">4. Variation</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-7f01733 elementor-widget elementor-widget-text-editor"
							data-id="7f01733" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group may from time to time unilaterally make modifications, variations or amendments (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Variations</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>) to this Agreement.&nbsp;</span>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Such Variations may be necessary where, for example, our complimentary service providers (such as insurance providers) make variations to their own terms or agreements to which we are a party.</span>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Where it is feasible for us to do so, we will provide you with thirty (30) days’ notice of the proposed Variation to these terms. If you do not agree to the proposed Variation, notwithstanding any other provision of this Agreement, you will be able to terminate this Agreement by providing us written notice, with such termination taking effect from the date that the Variation is to become effective, without penalty.</span>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If we do not receive a notice from you objecting to a Variation pursuant to 4(c), and you continue to request our Services or operating as a Member of Sports Nutrition Group, you will be deemed to have accepted the Variation.</span>
									</li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-a879142 elementor-widget elementor-widget-heading"
							data-id="a879142" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">5. Application,
									Membership Schedule and Approval Process</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-1b62584 elementor-widget elementor-widget-text-editor"
							data-id="1b62584" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li style={{listStyleType:"none"}}>
										<ol>
											<li style={{listStyleType:"none"}}>
												<ol style={{paddingInlineStart:48}}>
													<li dir="ltr"
														style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
														aria-level="3"><p dir="ltr"
																		  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																		  role="presentation"><span
														style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group retains absolute discretion as to whether an Application or Registration Form that You submit is approved, and whether You are ultimately granted Accreditation and Registered Membership.</span>
													</p></li>
													<li dir="ltr"
														style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
														aria-level="3"><p dir="ltr"
																		  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																		  role="presentation"><span
														style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group will inform You via email whether it has or has not approved Your Application or Registration Form.&nbsp;</span>
													</p></li>
													<li dir="ltr"
														style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
														aria-level="3"><p dir="ltr"
																		  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																		  role="presentation"><span
														style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If Sports Nutrition Group is not satisfied that:</span>
													</p>
														<ol style={{paddingInlineStart:48}}>
															<li dir="ltr"
																style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
																aria-level="4"><p dir="ltr"
																				  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																				  role="presentation"><span
																style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You have met with the necessary prerequisites to participate in a Course; or</span>
															</p></li>
															<li dir="ltr"
																style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
																aria-level="4"><p dir="ltr"
																				  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																				  role="presentation"><span
																style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Accreditation and Registered Membership should be conferred,</span>
															</p></li>
														</ol>
													</li>
												</ol>
											</li>
										</ol>
									</li>
								</ol>
								<p dir="ltr"
								   style={{lineHeight:"1.2", marginLeft:"28.35pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
									<span
										style={{fontSize:"9.5pt", color:"#000000", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>it will provide written notice to You. Sports Nutrition Group is not obliged to provide You with reasons for its decision.</span>
								</p>
								<ol>
									<li style={{listStyleType:"none"}}>
										<ol style={{paddingInlineStart:48}} start="4">
											<li dir="ltr"
												style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
												aria-level="3"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group reserves the right at its complete discretion to issue you with either a “provisional” or “open” Registered Membership, which will be based off Sports Nutrition Group’s assessment of your skills and qualifications.</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
												aria-level="3"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Where Sports Nutrition Group has issued you with a provisional Registered Membership, you will be entitled to provide the following Services as a Registered Member:</span>
											</p>
												<ol style={{paddingInlineStart:48}}>
													<li dir="ltr"
														style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
														aria-level="4"><p dir="ltr"
																		  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																		  role="presentation"><span
														style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>work with low-risk general population clientele only;</span>
													</p></li>
													<li dir="ltr"
														style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
														aria-level="4"><p dir="ltr"
																		  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																		  role="presentation"><span
														style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>no work with athlete clients; and</span>
													</p></li>
													<li dir="ltr"
														style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
														aria-level="4"><p dir="ltr"
																		  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																		  role="presentation"><span
														style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>eligible for Insurance through Sports Nutrition Group’s provider.</span>
													</p></li>
												</ol>
											</li>
											<li dir="ltr"
												style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
												aria-level="3"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Where Sports Nutrition Group has issued you with an open Registered Membership, you will be entitled to provide the following Services as a Registered Member:</span>
											</p>
												<ol style={{paddingInlineStart:48}}>
													<li dir="ltr"
														style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
														aria-level="4"><p dir="ltr"
																		  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																		  role="presentation"><span
														style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>fully accredited to practice as a Sports Nutritionist;</span>
													</p></li>
													<li dir="ltr"
														style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
														aria-level="4"><p dir="ltr"
																		  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																		  role="presentation"><span
														style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>work with athletes from all sports, including low-moderate risk clientele;</span>
													</p></li>
													<li dir="ltr"
														style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
														aria-level="4"><p dir="ltr"
																		  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																		  role="presentation"><span
														style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>eligible to enrol in further weight making accreditation programs offered by Sports Nutrition Group;</span>
													</p></li>
												</ol>
											</li>
											<li dir="ltr"
												style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
												aria-level="3"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>eligible to enrol in contest preparation accreditation programs offered by Sports Nutrition Group; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
												aria-level="3"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>eligible for Insurance through Sports Nutrition Groups’ insurance provider.</span>
											</p></li>
										</ol>
									</li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-a512880 elementor-widget elementor-widget-heading"
							data-id="a512880" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">6. Eligibility</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-a1ec39b elementor-widget elementor-widget-text-editor"
							data-id="a1ec39b" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must be over the age of eighteen (18) to participate in a Course provided by the Sports Nutrition Group unless written permission from a parent or guardian is supplied.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group reserves the right to set educational prerequisites at its discretion that you must satisfy in order to participate in a Course.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Without limiting subparagraph (b), in order to submit an Application for a Course it is a prerequisite of Sports Nutrition Group that you either have enrolled in and completed:</span>
									</p>
										<ol style={{paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>a Graduate Diploma in Applied Sports Nutrition; or</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>a Bachelor in Exercise Physiology (Biochemistry and Physiology Major).</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Without limiting subclause 5(b), it is a prerequisite of any Course that You have an English literacy score of at least 5.5.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>In order to become a Registered Member You must have submitted Your Registration Form prior to the commencement of the second week of the Course.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>By submitting Your Registration Form You acknowledge, warrant, and agree that You are eligible to practice as a sports nutritionist and provide the services in relation to same.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You warrant that all information provided in the Registration Form and to the Sports Nutrition Group via other forms of communication is, to the best of Your knowledge, true, accurate and complete, and agree to maintain this standard of communication throughout the registration process, any Course in which you participate and Registered Membership generally.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group may at their absolute discretion request additional information, supporting documentation and professional references, in addition to the information presented on the Registration Form or any Application, in order to further assess Your eligibility to participate in a Course, for Accreditation and to become a Registered Member.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-796828e elementor-widget elementor-widget-heading"
							data-id="796828e" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">7. Conduct</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-273254c elementor-widget elementor-widget-heading"
							data-id="273254c" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h3 className="elementor-heading-title2 elementor-size-default">7.1 Course Participation</h3></div>
						</div>
						<div
							className="elementor-element elementor-element-baac594 elementor-widget elementor-widget-text-editor"
							data-id="baac594" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge and agree when you enrol in a Course that:</span>
									</p>
										<ol style={{paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must not engage in any conduct that might mislead, deceive, confuse, or otherwise misrepresent to any person the nature of your qualifications, that status of your enrolment in a Course, the status of your Membership (to the extent that You have not yet been granted Membership) or the status of any Accreditation that You hold;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must at all times behave respectfully towards all other Course members, lecturers and markers;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You will observe all cultural and religious sensitivities of other members of Courses as well as lecturers or markers; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You will not act in a manner that is disruptive or may be reasonably objectionable to other participants in your course or Members of Sports Nutrition Group; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You will comply with all reasonable directions of Sports Nutrition Group regarding your conduct and etiquette generally.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge and agree that your failure to comply with the standards of behaviour as prescribed by clause 6.1(a) will entitle Sports Nutrition Group, in its sole discretion, to terminate your enrolment without refund in any Course in which you are participating.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-ab7d86e elementor-widget elementor-widget-heading"
							data-id="ab7d86e" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h3 className="elementor-heading-title2 elementor-size-default">7.2 Registered
									Member</h3></div>
						</div>
						<div
							className="elementor-element elementor-element-2dc5b7a elementor-widget elementor-widget-text-editor"
							data-id="2dc5b7a" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must provide the Services with due care and skill, diligence, efficiency, honesty, and with integrity. Unethical or improper behaviour is not acceptable and must be reported to Sports Nutrition Group via email or telephone.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must, at all times, comply with any and all of each and all of the members of the Sports Nutrition Group’s &nbsp; code of ethics and professional conduct, position statement on practicing standards, risk assessment and triage processes, created from time to time and any other documents produced by members of the Sports Nutrition Group in relation to the conduct of a Registered Member.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must not engage in any conduct that might mislead, deceive, or confuse any person in relation to, or otherwise misrepresent, the nature, status, scope or effect of Your Registered Membership or Accreditation.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must not delegate Your Registered Membership or Accreditation, or the activities associated with Registered Membership or Accreditation, to any other person.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must not copy, reproduce, or duplicate the original accreditation certificate issued to You by any one or all of the members of the Sports Nutrition Group. </span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-560014b elementor-widget elementor-widget-heading"
							data-id="560014b" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">8. Extension Policy and
									Deferments </h2></div>
						</div>
						<div
							className="elementor-element elementor-element-c4bff3b elementor-widget elementor-widget-text-editor"
							data-id="c4bff3b" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You are required to complete the Course and all assessments within the timeframe set for the Course and assessments.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If you need additional time to continue beyond the end date of your Course or assessment, an application for course extension must be made prior to the end date or submission date (whichever is applicable). The Sports Nutrition Group is not obliged to extend your enrolment or assessment submission date if you have not made appropriate arrangements or course progression prior to the end date.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If the extension is not approved, Sports Nutrition Group reserves the right to withdraw you from the Course without notice or to fail You for the relevant assessment or unit.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Each application for deferment will be considered on its merits and will be granted or refused by the Sports Nutrition Group in its sole discretion (including the period of the deferment).&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Should a deferment be granted, all fees paid will then be held in trust for the deferment period and any funds held on trust will not be transferrable to any other course qualification, person, or entity.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>On the expiration of the deferment period, any funds not utilised in continuing training or Course will be retained by Sports Nutrition Group. </span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-52e7da0 elementor-widget elementor-widget-heading"
							data-id="52e7da0" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">9. Enrolment Fees,
									Membership Fees and Payment</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-adb401a elementor-widget elementor-widget-heading"
							data-id="adb401a" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h3 className="elementor-heading-title2 elementor-size-default">9.1 Enrolment Fees</h3>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-49b429b elementor-widget elementor-widget-text-editor"
							data-id="49b429b" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The Enrolment Fees for Your participation in a Course will be notified to you at the time of making your Application.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>In the event that no Enrolment Fees were specified in the process of making your Application, the Enrolment Fees will be calculated as follows:</span>
									</p>
										<ol style={{paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>where you have made a single upfront payment for participating in the Course – $4,100.00;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>if you have entered into a 9 week payment plan – nine equal payments of $499.00, commencing from the date of acceptance of your Application;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>if you have entered into a 7 month payment plan – 7 equally monthly payments of $699.00 commencing from the date of acceptance of your Application; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>if you have entered into an 18-month payment plan – an initial payment of $599.00 followed by 17 monthly payments of $350.00, commencing from the date of acceptance of your Application.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>We may at our discretion require You to pay a deposit at the time that You enrol into a Course. The deposit will comprise a percentage of the total Enrolment Fees payable (</span><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontWeight:"bold", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Deposit</span><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>).</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>In the event that You:</span>
									</p>
										<ol style={{paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>have enrolled in a Course and prior to the commencement of the Course we terminate Your enrolment, we will refund to You the full amount of any Deposit;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>have enrolled in a Course and prior to the commencement of the Course, You choose to terminate Your enrolment, We will refund to You the Deposit less a $399.00 administration fee.; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>have enrolled in a Course and choose to withdraw from the Course following the commencement of that Course, You acknowledge and agree that the Deposit will be non-refundable.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge and agree that the non-refund of deposit monies pursuant to 9.1(d)(ii) or 9.1(d)(iii) is reasonable and does not constitute a penalty, but is a genuine pre-estimate of the costs incurred by Sports Nutrition Group and the loss suffered as a consequence of your withdrawal at those times.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-18a4818 elementor-widget elementor-widget-heading"
							data-id="18a4818" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title2 elementor-size-default">9.2 Deferral and
									Extension Fees</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-e8b57db elementor-widget elementor-widget-text-editor"
							data-id="e8b57db" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Where You are late to submit or complete any content, module or assessment that forms part of a Course (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Late Submissions</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>), Sports Nutrition Group reserves the right to charge additional fees to You. These additional fees relate to the additional time, expense and cost incurred by Sports Nutrition Group in engaging third parties to assess and grade submissions and modules outside of designated Course deadlines.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Additional fees associated with Late Submissions will be advise to you at the time of submitting your Application. If no additional Late Submission fee is specified at the date of filing your Application, the additional fees for Late Submission will be calculated on the following basis:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Lectures completed by extended due date (in homework timeline) $299.00;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Quizzes completed by extended due date $299.00;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Risk Assessment completed by extended due date $299.00;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Case Studies x 7 completed by extended due date $199.00 per case study. </span>
											</p></li>
										</ol>
									</li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-5be05a1 elementor-widget elementor-widget-heading"
							data-id="5be05a1" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title2 elementor-size-default">9.3 Failure to Meet
									Competency</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-90fe91e elementor-widget elementor-widget-text-editor"
							data-id="90fe91e" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You are permitted six (6) complimentary resubmissions for work or assessment following receipt of assessor feedback (</span><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontWeight:"bold", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Complimentary Resubmission</span><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>). There is a maximum of one Complimentary Resubmission per work or assessment item.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>In the event that You require an additional resubmission in respect of a work or assessment item (for example, where your resubmitted work again fails to meet a satisfactory standard), you are entitled to request a paid resubmission which will be charged at $259USD (</span><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontWeight:"bold", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Paid Resubmission</span><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>). You are entitled to request a maximum of two Paid Resubmissions per Course.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Each Resubmission must be made within 7-days of feedback being sent to You (</span><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontWeight:"bold", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Resubmission Due Date).</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Any submission filed after the Resubmission Due Date (including where additional submissions are required as a result of your initial submission being assessed as inadequate) will entitle Sports Nutrition Group to charge additional late submission fees as set out in 9.2(b).&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group reserves the right at any time after Your initial submission to refuse to accept a Complimentary Resubmission or a Paid Resubmission from You and to notify You that you have failed the relevant Course (</span><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontWeight:"bold", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Course Failure</span><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>).</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge and agree that a Course Failure does not entitle you to any refund of fees that have been paid in respect of your enrolment and participation in that Course.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-40b32d6 elementor-widget elementor-widget-heading"
							data-id="40b32d6" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title2 elementor-size-default">9.4 Membership Fees</h2>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-f55f873 elementor-widget elementor-widget-text-editor"
							data-id="f55f873" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li style={{listStyleType:"none"}}><br/>
										<ol>
											<li style={{listStyleType:"none"}}>&nbsp;</li>
										</ol></li>
								</ol>
								<div><p>&nbsp;</p>
									<ol style={{paddingInlineStart:48}}>
										<li dir="ltr"
											style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.35pt"}}
											aria-level="3"><p dir="ltr"
															  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
															  role="presentation"><span
											style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The Membership Fees required for you to become or remain a Registered Member, and to Maintain your Accreditation will be notified to you:</span>
										</p>
											<ol style={{paddingInlineStart:48}}>
												<li dir="ltr"
													style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
													aria-level="4"><p dir="ltr"
																	  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																	  role="presentation"><span
													style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>initially at the time of submitting your Registration Form; and</span>
												</p></li>
												<li dir="ltr"
													style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
													aria-level="4"><p dir="ltr"
																	  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																	  role="presentation"><span
													style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>subsequently on an annual basis at the time renewal of your Registered Membership becomes due.</span>
												</p></li>
											</ol>
										</li>
										<li dir="ltr"
											style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
											aria-level="3"><p dir="ltr"
															  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
															  role="presentation"><span
											style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group reserves the right to increase Membership Fees at its discretion and, where an increase occurs, will provide you with notice of that increase prior to the renewal of your Registered Membership falling due.</span>
										</p></li>
										<li dir="ltr"
											style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
											aria-level="3"><p dir="ltr"
															  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
															  role="presentation"><span
											style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If no notification is provided to you as contemplated by clauses 8.4(a)(i) or 8.4(a)(ii), the Membership Fees that you will be charged will be calculated on the following basis:</span>
										</p>
											<ol style={{paddingInlineStart:48}}>
												<li dir="ltr"
													style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
													aria-level="4"><p dir="ltr"
																	  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																	  role="presentation"><span
													style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>an upfront annual fee of $399.00; or</span>
												</p></li>
												<li dir="ltr"
													style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
													aria-level="4"><p dir="ltr"
																	  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																	  role="presentation"><span
													style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}><span
													style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fontVariantAlternates:"normal", verticalAlign:"baseline"}}>if Sports Nutrition Group has approved a monthly payment plan, a monthly fee of $39.99;</span></span>
												</p></li>
											</ol>
										</li>
										<li dir="ltr"
											style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
											aria-level="3"><p dir="ltr"
															  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
															  role="presentation"><span
											style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge and agree that the Membership Fee is an annual fee and, accordingly, in circumstances where you choose to cease being a Registered Member, you are still required to pay out the remainder of any annual fee due and owing.</span>
										</p></li>
										<li dir="ltr"
											style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
											aria-level="3"><p dir="ltr"
															  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
															  role="presentation"><span
											style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If you have failed to complete or respond to any compulsory professional development or ongoing education module prescribed by Sports Nutrition Group in a relevant period as required by clause 9(b) and as a consequence Sports Nutrition Group are required to:</span>
										</p>
											<ol style={{paddingInlineStart:48}}>
												<li dir="ltr"
													style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
													aria-level="4"><p dir="ltr"
																	  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																	  role="presentation"><span
													style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>undertake additional administrative processes in order to make contact with you regarding the compulsory professional development or ongoing education, or otherwise to determine the status of your Accreditation and Membership; or</span>
												</p></li>
												<li dir="ltr"
													style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
													aria-level="4"><p dir="ltr"
																	  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																	  role="presentation"><span
													style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>engage a third party in order to contact you regarding any of the matters in clause 8.4(e)(i), then you acknowledge and agree that Sports Nutrition Group reserves the right at its discretion to add a reasonable administrative fee to your Membership Fee of US$20.00 per calendar month, in order to cover the additional fees incurred by Sports Nutrition Group.</span>
												</p></li>
											</ol>
										</li>
										<li dir="ltr"
											style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
											aria-level="3"><p dir="ltr"
															  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
															  role="presentation"><span
											style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The additional administrative fee as contemplated by clause 8.4(e) will be automatically applied to your next billing cycle and charged to your prescribed method of payment.</span>
										</p></li>
									</ol>
									<p>&nbsp;</p></div>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-449873b elementor-widget elementor-widget-heading"
							data-id="449873b" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title2 elementor-size-default">9.5 Payments
									Generally</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-7351d62 elementor-widget elementor-widget-text-editor"
							data-id="7351d62" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge and agree that:</span>
									</p>
										<ol style={{paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>a surcharge of 2% will apply to all credit card payments that are processed by Sports Nutrition Group; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Direct debits are subject to the Direct Debit Request and Direct Debit Agreement that you are provided by Sports Nutrition Group at the time of submitting and Application or Registration Form, or if a third-party direct debit provider is used, the third party’s direct debit terms and conditions.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If any payment of Fees that you are required to make to Sports Nutrition Group is dishonoured and reinstatement is required, then you will be liable to pay the following:</span>
									</p>
										<ol style={{paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>a Dishonour Fee of $49.00; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>a Reinstatement Fee of $499.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge that the amounts referred to in 8.5(b)(ii) are a genuine estimate of the administrative fees incurred by Sports Nutrition Group in addressing a dishonoured payment and subsequently reinstating You as a Registered Member.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Your first Payment for a Course will be payable on the date that approval of Your Application is granted for the Course, and the recurring Payments will be due on the same date of the following month.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Any fee deferral or extension fees are payable on the same date that Your deferral or extension is agreed.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>It is Your responsibility to ensure that on each payment due date there are sufficient cleared funds in Your nominated account to meet Your scheduled payment. Should Your credit or debit card payment decline on the agreed date, Sports Nutrition Group reserves its right to continue attempts to process Your payment.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If a payment is declined at the time of attempting to process the payment, Sports Nutrition Group will attempt to process the declined amount once every twenty-four (24) hours for seventy-two (72) hours (three (3) times in three (3) days).</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If a payment remains outstanding and in arrears after an attempt to process that payment in the manner described at (g), a member of the Sports Nutrition Group (at its discretion) will charge You the Dishonour Fee and You agree to pay the Dishonour Fee in addition to the payment.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If You fail to pay the outstanding payment and the Dishonour Fee and any interest payable within thirty (30) days of the original declined payment, Sports Nutrition Group will cancel your Registered Membership and access to any online platform, resource or communication channel hosted by any of the Sports Nutrition Group.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If Your Registered Membership is cancelled it will need to be reinstated. If You request that Your Registered Membership is reinstated, You must pay all outstanding payments, Dishonour Fees, interest and the Reinstatement Fee prior to your Registered Membership being reinstated.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If a payment is declined after your Registered Membership has been reinstated, the process and consequences referred to in this clause 7.5 are repeated. If Your Registered Membership is cancelled for a second time, Your Registered Membership may not be reinstated, and reinstatement is at the sole discretion of Sports Nutrition Group.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Should Your credit or debit card expire or be cancelled, it is Your responsibility to provide Sports Nutrition Group with new card details to ensure payments continue to be successfully debited.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group may charge interest at the rate of 12.5% per annum on any payment that remains unpaid fourteen (14) days after the payment was due. Sports Nutrition Group reserves the right to engage external debt collection services for the collection of unpaid debts, and the right to commence legal proceedings for any outstanding amounts owed to any member of Sports Nutrition Group. You will be liable for all costs involved, including legal fees, on a solicitor/client basis.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group reserves the right to engage external debt collection services for the collection of unpaid debts, and the right to commence legal proceedings for any outstanding amounts owed to any member of Sports Nutrition Group. You acknowledge that you will be liable for all costs and expenses involved in our recovery of unpaid debts, including legal fees; on a solicitor/client basis, as well as the costs that we incur in appointing an agent or debt collection firm to recover the unpaid debt. We reserve at our discretion the right to provide any information regarding any unpaid debt or credit position to relevant credit reporting agencies.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group’s pricing structure, payment methods, payment processes and these Terms and Conditions may be amended at any given time at the Sports Nutrition Group’s reasonable discretion.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>All changes will apply from the date that the amended or new Terms and Conditions are posted on the website of a member of the Sports Nutrition Group or are provided to You, whichever is earlier.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-38ab171 elementor-widget elementor-widget-heading"
							data-id="38ab171" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">10. Duration and Renewal
									of Accreditation and Registered Membership</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-85d6e73 elementor-widget elementor-widget-text-editor"
							data-id="85d6e73" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Subject to compliance with these Terms and Conditions, Accreditation and Registered Membership continues until the expiry date referenced on the accreditation certificate provided to you at the time your Registered Membership is approved or as set out in the Membership Schedule.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Your Accreditation and Registered Membership will be automatically renewed each calendar year, subject to:</span>
									</p>
										<ol style={{paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Your continued compliance with the terms of this Agreement;&nbsp;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Your satisfaction and completion of all continuing professional development and educational modules prescribed by Sports Nutrition Group within the relevant period; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Your payment of all Membership Fees.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You shall inform the Association via submission through the online member portal thirty (30) days prior to the end of Your twelve (12) month registration period, if You do not intend to renew Your Accreditation or Registered Membership for the following calendar year using the deregistration request form.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-c7c3edb elementor-widget elementor-widget-heading"
							data-id="c7c3edb" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">11. Suspension Or
									Termination of Accreditation and Registered Membership</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-000b10e elementor-widget elementor-widget-heading"
							data-id="000b10e" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title2 elementor-size-default">11.1 Termination
									Generally</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-0b2a47b elementor-widget elementor-widget-text-editor"
							data-id="0b2a47b" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You may terminate Your Accreditation and Registered Membership at any time by giving the Association at least thirty (30) days written notice. </span><span
										style={{backgroundColor:"transparent", fontSize:"9.5pt", letterSpacing:0}}>You shall inform the Association via submission through the online member portal </span><span
										style={{backgroundColor:"transparent", fontSize:"9.5pt", letterSpacing:0}}>using the deregistration request form.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>In addition to the specific right afforded at 8.5(i), the Sports Nutrition Group may suspend or terminate Your Accreditation and Registered Membership if:</span>
									</p>
										<ol style={{paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You breach any of the terms set out in this Agreement;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You require Sports Nutrition Group to act unlawfully or unethically;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>the Sports Nutrition Group reasonably believes that Registered Membership is no longer appropriate; or</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-25.65pt", paddingLeft:"10.35pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You fail to make Payment.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If Your Accreditation and Registered Membership is terminated by either You or by the Sports Nutrition Group pursuant to 8.5(i) or 10.1(a), You agree that the Sports Nutrition Group is entitled to all payments that would have been due for the remainder of the current membership term at the time of termination (if any).</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge and agree that the fees payable pursuant to clause 11.1(c) account for the genuine loss that will be suffered by Sports Nutrition Group as a result of the related termination and do not constitute, and must not be construed as, a penalty.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>On termination of this Agreement, You agree to promptly return (where possible) or delete or destroy (where not possible to return) all of the members of the Sports Nutrition Groups’ Confidential Information and Intellectual Property, and all documents containing or relating to all of the members of the Sports Nutrition Groups’ Confidential Information and Intellectual Property.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Any accrued rights or responsibility of You or the Sports Nutrition Group are not affected by the termination of this Agreement.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>On termination of this Agreement, the members of the Sports Nutrition Group will remove Your access to all Registered Membership Services including but not limited to online platforms and communication channels, face to face workshops and events and the members of the Sports Nutrition Group will not be liable for any claims related to the removal of Your access to these, Your Registered Membership or Accredited status.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", color:"#000000", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", marginLeft:"-18pt",paddingLeft:"10.35pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", backgroundColor:"transparent", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>No refund is provided if this Agreement, Your Accreditation or Registered Membership is terminated or suspended for any reason. </span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-4f509ae elementor-widget elementor-widget-heading"
							data-id="4f509ae" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title2 elementor-size-default">11.2 Prohibition on
									Continued Association</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-7370a86 elementor-widget elementor-widget-text-editor"
							data-id="7370a86" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Upon termination of this Agreement or your Membership with the Association for any reason pursuant to these Terms and Conditions you acknowledge and agree that you must immediately cease and forever refrain (except to the extent that your Membership is renewed) from:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>representing, asserting or otherwise holding out in any way that you are associated with Sports Nutrition Group;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>indicating to your own customers or the public generally that you are an accredited or approved provider of services or training methods that are supported or endorsed by Sports Nutrition Group (acknowledging that such services and training methods constantly evolve and develop and the skills you obtained through your Course may no longer be current);</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>representing, asserting or holding out in any other form that you are a “Sports Nutritionist” in the context of the training and certification provided by Sports Nutrition Group;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>using any of the Sports Nutrition Group Intellectual Property and, in particular, any trade mark or certification trade mark of Sports Nutrition Group.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge and agree that the above prohibitions are reasonably necessary for Sports Nutrition Group to:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>protect its business and the goodwill and reputation in its business and trade marks; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>comply with its own regulatory and quality control and assurance obligations.</span>
											</p></li>
										</ol>
									</li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-b001cf0 elementor-widget elementor-widget-heading"
							data-id="b001cf0" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">12. Facilities</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-f9e9444 elementor-widget elementor-widget-text-editor"
							data-id="f9e9444" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>Any use of the Sports Nutrition Groups’ property, Facilities or Locations are at Your
									own risk, and you indemnify them against any and all claims, damages, costs, or
									liabilities that may arise as a consequence of Your use of the property, Facilities
									or Location.</p></div>
						</div>
						<div
							className="elementor-element elementor-element-7df64ec elementor-widget elementor-widget-heading"
							data-id="7df64ec" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">13. Privacy</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-27d9bc0 elementor-widget elementor-widget-text-editor"
							data-id="27d9bc0" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>It is a requirement of the&nbsp;</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"italic", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Privacy Act 1988</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>&nbsp;that You are informed about the collection of Your personal information and how the Association may use it.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>All personal information that is collected by Sports Nutrition Group (including any of the individual entities comprising that group) will be collected, stored and used in accordance with the Sports Nutrition Group Privacy Policy available on the website of each Sports Nutrition Group company.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The personal information You provide during the term of this Agreement is being collected for the purpose of processing Your Registration and Accreditation. You have a right to access and make alterations to Your personal information.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The Association agrees not to disclose Your personal information to any third party unless or except:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>as required for the legitimate interests of the Sports Nutrition Group business;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>to the extent that the disclosure relates only to the fact that you have previously participated in programs offered by Sports Nutrition Association, that your Accreditation is either current or non-current, that you are no longer a Member of the Association, or that you are no longer affiliated with or endorsed by the Sports Nutrition Group;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>legally required to do so or to its professional advisors. </span>
											</p></li>
										</ol>
									</li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-359b2ff elementor-widget elementor-widget-heading"
							data-id="359b2ff" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">14. Publicity</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-cbc9470 elementor-widget elementor-widget-text-editor"
							data-id="cbc9470" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must not make any public comment or remark that is, or is likely to be, disparaging, derogatory&nbsp; or harmful to Sports Nutrition Group regarding:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>the nature and quality of the education services provided by Sports Nutrition Group;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>the membership and association services provided by the Association; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>any of the directors, agents, employees or other personnel of the Sports Nutrition Group.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Where you have a complaint or criticism you must notify Sports Nutrition Group of the complaint or criticism in writing (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Complaint Notice</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>).</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Upon receipt of a Complaint Notice Sports Nutrition Group will contact You within ten (10) business days to resolve the matters the subject of the Complaint Notice.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>For the avoidance of doubt this clause 13 applies only in respect of public comments or remarks as contemplated by clause 13(a). Any disputes regarding the terms of this Agreement or the performance of the obligations of the parties under this Agreement must be dealt with in accordance with clause 19. </span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-8525bc7 elementor-widget elementor-widget-heading"
							data-id="8525bc7" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">15. Confidential
									Information</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-2c5f048 elementor-widget elementor-widget-text-editor"
							data-id="2c5f048" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>You agree not to disclose the Confidential Information of the Sports Nutrition Group
									to any third party, to use all reasonable endeavours to the Confidential Information
									of the Sports Nutrition Group from any unauthorised disclosure, and only to use the
									Confidential Information for the purpose for which it was disclosed or provided by
									Sports Nutrition Group to You, and not for any other purpose.</p></div>
						</div>
						<div
							className="elementor-element elementor-element-ff51f2e elementor-widget elementor-widget-heading"
							data-id="ff51f2e" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">16. Intellectual
									Property</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-87fe40b elementor-widget elementor-widget-text-editor"
							data-id="87fe40b" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The work and materials that Sports Nutrition Group provides to you in carrying out the Services contains material which is owned by or licensed to Sports Nutrition Group , or owned by third parties, and is protected by Australian and international laws (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Materials</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>). Sports Nutrition Group owns the copyright in all creative, literary and original works incorporated in the Materials that Sports Nutrition Group has created, including any sports nutrition programs, meal plans, exercise regimes, supplement programs, business plans, social media strategies and any other documents or concepts provided in either spoken or written form within the scope of Your Accreditation and Registered Membership with Sports Nutrition Group, including those disseminated during professional development seminars.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You agree that Sports Nutrition Group owns all intellectual property rights in its Materials, and that nothing in these Terms and Conditions constitutes a transfer of any intellectual property ownership rights in Sports Nutrition Group&nbsp; Materials, except as stated in these Terms and Conditions or with Sports Nutrition Group’s written permission. Your use of Sports Nutrition Group’s Materials does not grant You a license, or act as a right of use, of any of the intellectual property in the Materials, whether registered or unregistered, except as stated in these Terms and Conditions or with the Association’s written permission.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If You are provided with any Materials during Your Accreditation or Registered Membership, then the copyright in these Materials shall remain vested with Sports Nutrition Group and shall only be used at Sports Nutrition Group’s discretion. You must not:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>alter or modify any of the Materials;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>create derivative works from the Materials; or</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>use Sports Nutrition Group’s Materials for commercial purposes such as on sale to third parties,</span>
											</p></li>
										</ol>
									</li>
								</ol>
								<p dir="ltr"
								   style={{lineHeight:"1.2",textIndent:"56.692913385826756pt", textAlign:"justify", marginTop:"0pt",marginBottom:"6.5pt"}}>
									<span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>without Sports Nutrition Group’s prior express written permission.</span>
								</p><p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}} start="4">
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You acknowledge and agree that You must not use the Sports Nutrition Group’s Materials to create a business that is similar to that of the Sports Nutrition Group, If You do so, Sports Nutrition Group reserves the right to immediately terminate Your Registered Membership and the Agreement.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group reserves its right to pursue any loss or damage suffered as a result of copyright or Intellectual Property infringement by You or any third party.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>This clause survives termination of the Agreement.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-64c2e6b elementor-widget elementor-widget-heading"
							data-id="64c2e6b" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">17. Insurance</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-1464a64 elementor-widget elementor-widget-text-editor"
							data-id="1464a64" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You agree to obtain and maintain the Insurance throughout the course of Your Registered Membership.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The cost associated with obtaining Insurance is set by the third party provider of Sports Nutrition Group and will be notified to You at the time of obtaining that Insurance.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You agree that, should Your Insurance lapse, any member of the Sports Nutrition Group may at their sole discretion and without notice to You, report the lapse to the Insurance provider, the consequence of this being Your Insurance will be cancelled.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>In the event that your Insurance is cancelled, Sports Nutrition Group reserves the right to cancel your Registered Membership and Accreditation.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group may from time-to-time amend or change the required Insurance to be held by Registered Members. Sports Nutrition Group agrees to take reasonable steps to notify you of any change.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You shall obtain and maintain valid and enforceable public liability, professional indemnity insurance policies to cover any potential liabilities of:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You, in the scope of Your professional duties as a sports nutritionist; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>any of the members of the Sports Nutrition Group, arising out of any breach of this Agreement, or any act or omission committed by You, as if each member was a jointly insured party under each of those policies.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>For the avoidance of doubt, at the time of these Terms &amp; Conditions, the Insurance fulfils the requirements of 16(f).</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You agree to, upon request, provide the Association with certificates of currency and any other evidence of Your compliance with this clause 16 that the Association may reasonably require.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-1658176 elementor-widget elementor-widget-heading"
							data-id="1658176" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">18. Indemnity</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-ea7df4e elementor-widget elementor-widget-text-editor"
							data-id="ea7df4e" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You are liable for and agree to indemnify, defend, and hold the Sports Nutrition Group harmless for and against any and all claims, liabilities, suits, actions, and expenses, including costs of litigation and legal costs, resulting directly or indirectly from:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>any information that is not accurate, up to date or complete or is misleading or a misrepresentation;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>the Sports Nutrition Group (or an individual member) providing You with Accreditation and Registered Membership;</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>any breach of this Agreement; and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>you delivering Services to the public.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You agree to cooperate with the Sports Nutrition Group (at Your own expense) in the handling of disputes, complaints, investigations, or litigation that arise as a result of Your Services including but not limited to disputes, complaints, investigations, or litigation that arises out of or relates to incorrect information You have given the Sports Nutrition Group.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The obligations under this clause will survive termination of this Agreement.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-196f184 elementor-widget elementor-widget-heading"
							data-id="196f184" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">19. Limitation of
									Liability</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-7caf3e4 elementor-widget elementor-widget-text-editor"
							data-id="7caf3e4" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>To the fullest extent permitted by law, the Sports Nutrition Group shall not be liable to You for any costs, expenses, loss of or damage to goodwill, loss of sales or business, loss, or damage (whether direct, indirect, or consequential, and whether economic or other) arising from Your Accreditation and Registered Membership, or any termination of the rights granted to You under, or otherwise in connection with, this Agreement.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The Sports Nutrition Group’s liability to You, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising in connection with this Agreement, shall at all times be limited to the amount of the Payments made by you under this Agreement.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-01349da elementor-widget elementor-widget-heading"
							data-id="01349da" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">20. Dispute Resolution
									(Mediation)</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-1da8c36 elementor-widget elementor-widget-text-editor"
							data-id="1da8c36" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If a dispute (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Dispute</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>) arises between the parties to this Agreement which they cannot resolve, then the party claiming that a Dispute has arisen must deliver to the other parties a notice containing particulars of the Dispute (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Dispute Notice</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>).</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>During the period of 10 Business Days after delivery of the Dispute Notice, or any longer period agreed in writing by the parties to the Dispute (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Initial Period</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>), the parties to the Dispute (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Participants</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>) must meet and use their reasonable endeavours and act in good faith in an attempt to resolve the Dispute.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If the Participants cannot resolve the Dispute then unless they all agree otherwise, the Participants must appoint a mediator to mediate the Dispute in accordance with the rules of the Resolution Institute. All Participants must participate in the mediation in good faith.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The mediator must be agreed on by the Participants within 10 Business Days after the Dispute Notice is given to the Participants and if they cannot agree within that time the mediator will be nominated by the president of the Resolution Institute.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>The mediation concludes when:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>all the Participants agree in writing on a resolution of the Dispute; or</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>a Participant, not earlier than 20 Business Days after appointment of the mediator, has given 5 Business Days’ notice to the other Participants and to the mediator, terminating the mediation, and that 5 Business Days has expired without all the Participants agreeing in writing on a resolution of the issue.</span>
											</p></li>
										</ol>
									</li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-0a4a8d2 elementor-widget elementor-widget-heading"
							data-id="0a4a8d2" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">21. Goods and Services
									Tax</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-0ddc0cb elementor-widget elementor-widget-text-editor"
							data-id="0ddc0cb" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Unless otherwise expressly stated, all amounts stated to be payable under this document are exclusive of goods and services tax (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>GST</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>). If GST is imposed on any supply made under or in accordance with this document, then the GST payable must be paid to the supplier as an additional amount by the recipient of the supply, provided the supplier provides a tax invoice in respect of the taxable supply.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If a party is entitled to be reimbursed or receive compensation for any of its costs, expenses or liabilities then the amount to be paid is to be reduced by the input tax credits to which that party is entitled to receive in relation to those amounts.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-ec55641 elementor-widget elementor-widget-heading"
							data-id="ec55641" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">22. General Matters and
									Variation</h2></div>
						</div>
						<div
							className="elementor-element elementor-element-ce9be16 elementor-widget elementor-widget-heading"
							data-id="ce9be16" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h3 className="elementor-heading-title2 elementor-size-default">22.1 General Matters</h3>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-ac6a323 elementor-widget elementor-widget-text-editor"
							data-id="ac6a323" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You must not assign, novate or otherwise transfer any of your rights or obligations under this document without the prior written consent of Sports Nutrition Group.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Neither party is liable for any breach of its obligations under this document to the extent that the breach resulted from any event that is outside the reasonable control of the affected party and could not have been prevented by that party taking reasonable steps or overcome by the exercise of reasonable diligence and at a reasonable cost (including lack of supply, industrial action, fire, riot, war, embargo, civil commotion for act of God) provided that the affected party:</span>
									</p>
										<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>promptly notifies the other party of the event (with appropriate details); and</span>
											</p></li>
											<li dir="ltr"
												style={{listStyleType:"lower-roman", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-25.650000000000006pt", paddingLeft:"10.350000000000001pt"}}
												aria-level="4"><p dir="ltr"
																  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
																  role="presentation"><span
												style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>takes all reasonable steps to work around or reduce the effects of the event.</span>
											</p></li>
										</ol>
									</li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Any amendments to this Agreement must be made in writing and signed by the parties.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You agree that the Sports Nutrition Group, may use email to communicate with You and may use the email address that You provide for that purpose.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>You agree to release the Sports Nutrition Group from any liability for any loss which You might incur if an email is intercepted or corrupted during transmission, or if a document which any of the members of the Sports Nutrition Group prepared for You is altered by You or any other party without the written consent of the Sports Nutrition Group.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>This Agreement is governed by the laws of Queensland, Australia and You agree to submit to the non-exclusive jurisdiction of the courts of Queensland, Australia.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Unless this document expressly states otherwise, a party may in its absolute discretion, give conditionally or unconditionally or withhold, any consent under this document. To be effective any consent under this document must be in writing.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Any provision of this Agreement that is illegal, void, or unenforceable will be severed without prejudice to the balance of the provisions.</span>
									</p></li>
								</ol>
							</div>
						</div>
						<div
							className="elementor-element elementor-element-4985abe elementor-widget elementor-widget-heading"
							data-id="4985abe" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h3 className="elementor-heading-title2 elementor-size-default">22.2 Variation</h3></div>
						</div>
						<div
							className="elementor-element elementor-element-eb2e4df elementor-widget elementor-widget-text-editor"
							data-id="eb2e4df" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<p>&nbsp;</p>
								<ol style={{marginTop:0, marginBottom:0, paddingInlineStart:48}}>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Sports Nutrition Group may from time to time unilaterally make modifications, variations or amendments (</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:"bold", fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Variations</span><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>) to this Agreement.&nbsp;</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Such Variations may be necessary where, for example, our complimentary service providers (such as insurance providers) make variations to their own terms or agreements to which we are a party.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>Where it is feasible for us to do so, we will provide you with thirty (30) days’ notice of the proposed Variation to these terms. If you do not agree to the proposed Variation, notwithstanding any other provision of this Agreement, you will be able to terminate this Agreement by providing us written notice, with such termination taking effect from the date that the Variation is to become effective, without penalty.</span>
									</p></li>
									<li dir="ltr"
										style={{listStyleType:"lower-alpha", fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre", marginLeft:"-18pt", paddingLeft:"10.350000000000001pt"}}
										aria-level="3"><p dir="ltr"
														  style={{lineHeight:"1.2", textAlign:"justify", marginTop:"0pt", marginBottom:"6.5pt"}}
														  role="presentation"><span
										style={{fontSize:"9.5pt", fontFamily:"Corbel,sans-serif", color:"#000000", backgroundColor:"transparent", fontWeight:400, fontStyle:"normal", fontVarient:"normal", fontDecoration:"none", verticalAlign:"baseline", whiteSpace:"pre-wrap"}}>If we do not receive a notice from you objecting to a Variation pursuant to 4(c), and you continue to request our Services or operating as a Member of Sports Nutrition Group, you will be deemed to have accepted the Variation.</span>
									</p></li>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
	);
}