import React, {} from "react";

//import "./../css/termsAndConditions.css"



export default function StudentIndemnityReleaseText(props) {


    return (
        
        <div className="woocommerce-terms-and-conditions" style={{padding:15}}>
            <p style={{paddingLeft:"8pt", textIndent:"0pt",lineHeight:"1pt", textAlign:"left"}}/>
            <h4>In CONSIDERATION of the Organiser permitting me to participate in the training course I agree with it as follows:</h4>
            <ol>
                <li>I UNDERSTAND that participating in any type of training, course or activity may present varying forms of RISK and possible hazards and I voluntarily ACCEPT the risk of damage consequent upon or arising from my entry as a student, and the use of the Organiser’s facilities.</li>
                <li>I WILL NOT SUE the Organisers for any negligence, tort, breach of contractual or any other legal or equitable rights howsoever caused, and this indemnity will extend to and include any damage arising from my participation in a training course and from my use of the Organiser’s facilities and I INDEMNIFY the Organisers in respect of the same.</li>
                <li>I WILL abide by the Rules and Regulations of the Organisers as to the training and to the use of the Organiser’s facilities and the directions of the Organiser’s officials including the right to terminate or cancel my training and the use of the Organiser’s facilities at any time and for any reason.</li>
                <li>THE PERSONAL INFORMATION I have supplied to the Organiser regarding my qualifications, experience and any other matter associated with the training is true and correct and I have READ AND UNDERSTOOD all of the clauses of this agreement before accepting the same and before my use of the Organiser’s facilities or before any participation in training.</li>
            </ol>
        </div>
	);
}