import React, {Component, useContext, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";

import BreadCrumbContext from "../../contexts/breadCrump";
import {
	Collapse,Menu
} from 'antd';
import APIDocumentationMenu from "./Documentation/Menu";
import APIDocumentationUsersList from "./Documentation/Users/List";
import APIDocumentationUsersCreate from "./Documentation/Users/Create";
import APIDocumentationUsersRead from "./Documentation/Users/Read";
import APIDocumentationUsersUpdate from "./Documentation/Users/Update";
import APIDocumentationUsersDelete from "./Documentation/Users/Delete";
import APIDocumentationAction from "./Documentation/Action"

import '../../css/documentation.css';
import API2 from "../../components/api2";




export default function APIIFrame(props) {
    const AP = API2();
    const [menu, setMenu] = useState([]);
    const rootSubmenuKeys = [];
    const [openKeys, setOpenKeys] = React.useState([]);
    const [selectedKeys, setSelectedKeys] = React.useState([]);

    //const [menuController, setMenuController] = useState([]);
    const [menuKey, setMenuKey] = useState([]);

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([
			<span><i className="fas fa-book"/> API</span>,
		    <span><i className="fas fa-info-circle"/> Documentation</span>
        ]);

        AP.get('/documentation/menu')
            .then(function(res){
                setMenu(res);
            });


        let group;
        let controller;
        let action;
        let openKey;
        let selectedKey;
        if(props.match.params.action){
            group       = props.match.params.group;
            controller  = props.match.params.controller;
            action      = props.match.params.action;
            openKey     = group;
            selectedKey = group+"_"+controller+"_"+action;
        }else{
            controller  = props.match.params.group;
            action      = props.match.params.controller;
            openKey     = controller;
            selectedKey = controller+"_"+action;
        }

        if(menuKey!=selectedKey) {
            setMenuKey(selectedKey);
        }

        setOpenKeys([openKey]);
	}, []);

    const ct = {
        GET: <span style={{color:"green",float:"right",fontSize:"10px"}}>GET</span>,
        POST: <span style={{color:"blue",float:"right",fontSize:"10px"}}>POST</span>,
        PATCH: <span style={{color:"purple",float:"right",fontSize:"10px"}}>PATCH</span>,
        DELETE: <span style={{color:"red",float:"right",fontSize:"10px"}}>DELETE</span>
    };

    function menuChange(e){
        setMenuKey(e.key);
        console.log(e);
    }



	function buildMenu(){
return;
        return (
            <Menu
                style={{ width: 256 }}
                mode="inline"
                openKeys={openKeys}
                selectedKeys={[menuKey]}
                onClick={menuChange}
                onOpenChange={onOpenChange}
            >
                {menu.map(item => {
                    {rootSubmenuKeys.push(item.key)}
                    return <Menu.SubMenu key={item.key} title={item.name}>
                            {item.actions && item.actions.map(action =>
                                <Menu.Item key={item.key + "_" + action.key}><NavLink
                                    to={"/api/documentation/" + item.key + "/" + action.key}
                                    activeClassName="current">{action.name}{ct[action.method]}</NavLink></Menu.Item>
                            )}
                            {item.controllers && item.controllers.map(controller =>
                                <Menu.ItemGroup key={item.key + "_" + controller.key} title={controller.name}>
                                    {controller.actions && controller.actions.map(action =>
                                        <Menu.Item key={item.key + "_" + controller.key + "_" + action.key}><NavLink
                                            to={"/api/documentation/" + item.key + "/" + controller.key + "/" + action.key}
                                            activeClassName="current">{action.name}{ct[action.method]}</NavLink></Menu.Item>
                                    )}
                                </Menu.ItemGroup>
                            )}
                        </Menu.SubMenu>
                    }
                )}
            </Menu>
        );
	}
    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <div style={{display:"flex"}}>
			<div>
				{buildMenu()}
			</div>
			<div className="content_inner">
				{props.match.params.controller
				&& <APIDocumentationAction menu={menu} {...props} menuKey={menuKey}/>
				||
				<>
                    <h1>IFrame Access</h1>
                    <p>To make integration easier we have provided access to some pages via an iframe</p>
                    <p>These pages won't contain any logos or navigation. You can always create your own version of these pages using the api's. These are just provided for ease of use</p>
                    <p>To access any of these pages you need to provide a valid json web token (JWT) as the query param "jwt"<br/>
                        I.e.   address/to/page?jwt=aWebTokenHere</p>
                    <h3 style={{paddingTop:40}}>It is strongly recommended that you make a new JWT with a short expiring and limited scope to avoid security issues.</h3>

                    <h3 style={{paddingTop:40}}>IFrame accessible pages</h3>
                    <ul>
                        <li>{`/iframe/coach/{coach_id}/client/{client_id}/riskAssessment`}</li>
                        <li>{`/iframe/client/{client_id}/coach/{coach_id}/riskAssessment`}</li>

                    </ul>

				</>
				}
			</div>
        </div>
    );
}

