import React, {useContext, useEffect, useState} from 'react';
import API2 from "../../components/api2";
import DiscountForm from "../../forms/Discount"
import {NavLink, useParams} from "react-router-dom";

import {
    Tabs,
    Button,
    Spin
} from 'antd';
import moment from "moment/moment";
import {Form} from "antd/lib/index";
import BreadCrumbContext from "../../contexts/breadCrump";
import { UserOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;


export default function Discount(props) {

    let discountId  = props.match.params.discountId;
    let userId     = props.match.params.userId
    if(discountId == 'new') discountId = null;


    let defaultTab = "1";

    const AP = API2();

    const [form] = Form.useForm();
    const [discount, setDiscount] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>,
            <Spin size="small"/>,
            (discountId)?<Spin size="small"/>:<span><i className="fas fa-user"/> Create Discount</span>
        ]);
    }, []);

    useEffect(() => {
        if(discountId) {
            AP.get('/discount/' + discountId)
                .then(function (res) {

                    setBreadCrumbs([
                        <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>,
                        <NavLink to={"/user/"+res.user.id}><span><UserOutlined/> {res.user.name}</span></NavLink>,
                        <span><i className="fas fa-user"/> Edit Discount</span>
                    ]);

                    setDiscount(res);
                    form.setFieldsValue(res);
                    setIsLoading(false);
                });
        }else if(userId){
            AP.get('/user/'+userId)
                .then(function (res) {
                    setBreadCrumbs([
                        <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>,
                        <NavLink to={"/user/"+res.id}><span><UserOutlined/> {res.name}</span></NavLink>,
                        <span><i className="fas fa-user"/> Create Discount</span>
                    ]);

                    setDiscount({user:res});
                    setIsLoading(false);
                });
        }else{
            setDiscount({});
            setIsLoading(false);
        }
    }, []);

    return(
        <div className="content_inner">
            <DiscountForm discount={discount} {...props}/>
        </div>
	);
}