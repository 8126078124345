import React, { useState } from 'react';
import { Layout, Menu, Dropdown, Button, Avatar, Modal  } from 'antd';
//import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SupportForm from "../forms/Support"
//import './sider.less';
import {NavLink} from "react-router-dom";
import useToken from '../hooks/useToken';
import useWindowDimensions from "../components/useWindowDimensions";
import {Form} from "antd/lib/index";
import AutoComplete from "../components/AutoComplete";

const { Header } = Layout;
const { SubMenu } = Menu;

export default function HeaderNav(props) {


	//const navigate = useNavigate();
	const history = useHistory();


    const { height, width } = useWindowDimensions();

	let self = this;
    const { user:authUser, setUser, RestoreUser } = useToken();


    const [supportModalOpen, setSupportModalOpen] = React.useState(false);
    const [modalForm]     = Form.useForm();


	function logout(){
        props.setUser(false);
		history.push("/login");
	}

	function restoreUser(){
        RestoreUser();
        window.location.replace("/dashboard");
	}

	const menu = (
		<Menu>
			<Menu.Item>
				<NavLink to="/profile" activeClassName="current">
					<i className="fas fa-user-edit"></i> Profile
				</NavLink>
			</Menu.Item>
            <Menu.Item>
                <a onClick={()=>setSupportModalOpen(true)}>
                    <i className="fad fa-hands-helping"/> Support
                </a>
            </Menu.Item>
			<Menu.Item>
				<a onClick={logout}>
					<i className="fas fa-sign-out-alt"></i> Logout
				</a>
			</Menu.Item>
		</Menu>
	);


	const [searchValue, setSearchValue] = useState('');

	return (
		<Header style={{ padding: 0, backgroundColor:"white", color: "#fff" }}>
			<div className="" style={{display:"flex"}}>
                {width < 768 &&
				<>
					<div style={{flex:1}}/>
                    <div style={{paddingRight:"15px"}}>
                        {localStorage && localStorage.getItem('originalUser') &&
                        <Button size="large" shape="round" className="btn-teal" style={{margin: 11}} onClick={() => restoreUser()}>Restore User</Button>
                        }
                        <Dropdown overlay={menu} placement="bottomLeft">
                            <Button size="large" style={{height:"50px", borderRadius:"4px",padding:"0 7px",margin: 5}}>
                                <Avatar icon={<i className="far fa-user"></i>} size="large"/>
                            </Button>
                        </Dropdown>
                    </div>
				</>
                }
                {width >= 768 &&
				<>
					<div style={{flex: "1", margin:"0 60px", justifyContent:"center", display:"flex", width:"100%"}}>
						{authUser.type == "admin" &&
						<AutoComplete
							mode="multiple"
							value={searchValue}
							onChange={(data) => {
								setSearchValue(data);
							}}
							placeholder=""
							url={"/search"}
							onSelect={(url) => {
								setSearchValue("");
								history.push(url);
							}}
							className="massSelect searchIcon roundedBorders"
							style={{
								width: '100%',
							}}
						/>
						}
					</div>
                    <div style={{paddingRight:"15px"}}>
                        {localStorage && localStorage.getItem('originalUser') &&
                        <Button size="large" shape="round" className="btn-teal" style={{margin: 11}} onClick={() => restoreUser()}>Restore User</Button>
                        }
                        <Dropdown overlay={menu} placement="bottomLeft">
                            <Button size="large" style={{height:"50px", borderRadius:"15px",margin: 5}}>
                                <Avatar src={(authUser.profile_image)?authUser.profile_image.endpoint:null} icon={<i className="far fa-user"></i>} size="large"/>
                                <span style={{paddingLeft:"5px"}}>{authUser.name}</span>
                            </Button>
                        </Dropdown>
                    </div>
				</>
                }

			</div>

            <Modal
                visible={supportModalOpen}
                onOk={() => {modalForm.submit();}}
                onCancel={()=>setSupportModalOpen(false)}
                title="Support"
                okText="Save"
                cancelText="Close"
				//centered={true}
            >
                <SupportForm form={modalForm} onFinish={()=>setSupportModalOpen(false)}/>
            </Modal>
		</Header>
	);

}