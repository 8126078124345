import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {
    Form,Input,
} from 'antd';

export default function CodeOfConduct(props) {

    const [form] = Form.useForm();
    const history = useHistory();


    function getStartedClicked(data){
        history.push({
            pathname: '/signUp',
            state: data
        });
    }


    return (
        <div className="content_inner_padded" >
            <div className="container">

                <div className="ibn ibn-sb sec">
                    <div className="ebn ud-bn ud-pt obg uix-alter ebn-p9x sec">
                        <div className="cpnw cov xg">
                            <div className="main">
                                <div className="cpn al-g cl-g bg-g wd-g">
                                    <div className="title">
                                        <h1>Code Of Ethics And Professional Conduct
                                            <div className="breadcrumb">
                                                <span className="separator"> &gt; </span>
                                                <span className="current">Code Of Ethics And Professional Conduct</span>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg hs-bg is-basic" data-height="auto" style={{"backgroundImage":"url('https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/adversary-banner.jpg')"}}>
                            <div className="over tpb op01" style={{"opacity":"0.01"}}></div>
                        </div>
                    </div>
                </div>

                <div className="section sec-sc sec basic row-xd sec-rg light sec-blue-strip ud-pad ud-bgc sec-a132994z" style={{"paddingTop":"65px", "paddingBottom":"65px", "backgroundColor":"#01a5f8"}}>
                    <div className="main">
                        <div className="content">
                            <p style={{"text-align":"center"}}>
                                <a href="https://www.sportsnutritionassociation.com/product/annual-registration/" className="button btn-fill btn-normal">
                                    <span>Registration</span>
                                </a>
                                <a href="https://www.sportsnutritionistregistry.com/" className="button btn-normal" target="_blank">
                                    <span>Find A Nutritionist</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>


                <div className="section sec-sc sec basic row-xd sec-rg sec-who-is" style={{backgroundColor:"white"}}>
                    <div className="main">
                        <div className="content">
                            <p style={{textAlign:"left"}}></p><h2 className="title tl-sc"><span>Preamble</span></h2>
                            <p></p>
                            <p>Sports Nutrition Association and its Professionals believe it is in the best interests of
                                the profession and the public it serves to have a Code of Ethics and Professional
                                Conduct in place that provides guidance to its Professionals in their professional
                                practice and conduct.<span className="Apple-converted-space">&nbsp; </span>The
                                Professionals have voluntarily adopted this Code to reflect the values and ethical
                                principles guiding the profession and to set forth commitments and obligations of the
                                Professional to the public, the clients, colleagues, and all others to which they
                                provide Sports Nutrition services or advice.</p>
                            <p>When providing services the Professional adheres to the core values of customer focus,
                                integrity, innovation, social responsibility, and diversity.<span
                                    className="Apple-converted-space">&nbsp; </span>Science-based decisions, derived
                                from the best available research and evidence, are the underpinnings of ethical conduct
                                and practice.</p>
                            <p>This Code applies to the Professional who may act in a variety of capacities, provides
                                general principles and specific ethical standards for situations that may be encountered
                                in practice.<span className="Apple-converted-space">&nbsp; </span>The primary goal is
                                the protection of the individuals, groups, organizations and communities with whom the
                                Professional interacts with.<span className="Apple-converted-space">&nbsp; </span>It is
                                also intended to enhance the effectiveness of SNA in supporting its mission.</p>
                            <p>When enrolling for a certification or otherwise becoming a Certified Member, all
                                Professionals agree to abide by the Code.</p>
                            <h2 className="title tl-sc"><span>Definitions</span></h2>
                            <p>The following definitions will apply wherever used in the Code of Ethics and Professional
                                Conduct:</p>
                            <p><b>Candidate</b> means any person who is in the process of registering / enrolling for a
                                SNA certification, including those who completed the registration process.</p>
                            <p><b>Certified Member</b> means a member holding a current SNA Registration.</p>
                            <p><b>Code </b>means this<b> </b>Code of Ethics and Professional Conduct.</p>
                            <p><b>CPD </b>means the continuing professional development as mandated by SNA or otherwise
                                required for the Certified Member’s SNA Registration.</p>
                            <p><b>Professional </b>means any person who is a Candidate or Certified Member of SNA.</p>
                            <p><b>SNA means </b>Sports Nutrition Association.</p>
                            <p><b>SNA Certification</b> means the relevant SNA certification granted to the Candidate by
                                SNA.</p>
                            <p><b>Sports Nutrition </b>means the broad disciplinary field that focuses on the science
                                behind and application of proper nutrition for general weight management, body
                                composition management and manipulation, nutrition during exercise, athletic
                                competition; the need for nutritional supplements among athletes (if any); and the role
                                of proper nutrition and dietary supplements in enhancing an athlete’s performance.</p>
                            <h2 className="title tl-sc"><span>Principles and Standards</span></h2>
                            <p>The principles and standards set out below are written generally and do not address every
                                situation encountered by the Professional.<span
                                    className="Apple-converted-space">&nbsp; </span>The circumstances of a situation
                                will determine the interpretation and application of a given principle or standard as it
                                relates to the Code.</p>
                            <h3>Competence</h3>
                            <p>The Professional shall:</p>
                            <ol>
                                <li>practice using evidence-based approaches within the areas of competence or SNA
                                    Certification and continuously develop and enhance expertise including but not
                                    limited through CPD;
                                </li>
                            </ol>
                            <ol start="2">
                                <li>only provide Sports Nutrition services or advice that they are qualified to provide
                                    through their relevant SNA Certification;
                                </li>
                            </ol>
                            <ol start="3">
                                <li>assess the validity and applicability of scientific evidence without personal or
                                    commercial bias;
                                </li>
                            </ol>
                            <ol start="4">
                                <li>interpret, apply, participate in and / or generate research to enhance practice,
                                    innovation and discovery;
                                </li>
                            </ol>
                            <ol start="5">
                                <li>make evidence based practice decisions, taking into account the unique values and
                                    circumstances of the client in combination with the Professional’s expertise and
                                    judgement;
                                </li>
                            </ol>
                            <ol start="6">
                                <li>recognize and exercise professional judgement within the limits of individual
                                    qualifications and collaborate with others, seek counsel and make referrals as
                                    appropriate;
                                </li>
                            </ol>
                            <ol start="7">
                                <li>act in a caring and respectful manner, mindful of individual differences, gender,
                                    cultural and ethnic diversity.
                                </li>
                            </ol>
                            <h3>Integrity</h3>
                            <p>The Professional shall:</p>
                            <ol>
                                <li><b></b>disclose any conflicts of interest, including financial interests in products
                                    or services that are recommended;
                                </li>
                            </ol>
                            <ol start="2">
                                <li><b></b>refrain from accepting gifts or services which potentially influence or which
                                    may give the appearance of influencing professional judgement;
                                </li>
                            </ol>
                            <ol start="3">
                                <li>comply with all applicable laws and regulations;</li>
                            </ol>
                            <ol start="4">
                                <li>be familiar with SNA by-laws, applicable codes, policies, procedures, rules,
                                    standards and guidelines;
                                </li>
                            </ol>
                            <ol start="5">
                                <li>maintain and appropriately use credentials according to directives issued by SNA
                                    from time to time or otherwise in accordance with the SNA Registration;
                                </li>
                            </ol>
                            <ol start="6">
                                <li>not misrepresent, the nature, status, scope or effect of their SNA Registration or
                                    SNA affiliation;
                                </li>
                            </ol>
                            <ol start="7">
                                <li>respect intellectual property rights, including citation and recognition of ideas
                                    and work of others;
                                </li>
                            </ol>
                            <ol start="8">
                                <li>provide accurate and truthful communications and not engage in misleading or
                                    deceptive conduct;
                                </li>
                            </ol>
                            <ol start="9">
                                <li>report inappropriate behavior or treatment of a client by another Professional;</li>
                            </ol>
                            <ol start="10">
                                <li>document and bill to most accurately reflect the character and extent of services
                                    delivered;
                                </li>
                            </ol>
                            <ol start="11">
                                <li>not do anything or behave in any manner that may bring the Professional or SNA in
                                    disrepute;
                                </li>
                            </ol>
                            <ol start="12">
                                <li>not engage in illegal or improper use of the SNA documents, SNA Certification or any
                                    SNA trademarks or designations;
                                </li>
                            </ol>
                            <ol start="13">
                                <li>respect the client’s autonomy and safeguard client confidentiality according to laws
                                    and regulations; and
                                </li>
                            </ol>
                            <ol start="14">
                                <li>implement appropriate measures to protect personal health information using
                                    appropriate techniques and otherwise in accordance with laws and regulations.
                                </li>
                            </ol>
                            <h3>Professionalism</h3>
                            <p>The Professional shall:</p>
                            <ol>
                                <li>respect the values, rights, knowledge and skills of colleagues and other
                                    professionals;
                                </li>
                            </ol>
                            <ol start="2">
                                <li>demonstrate respect, constructive dialogue, civility and professionalism in all
                                    communication, including social media;
                                </li>
                            </ol>
                            <ol start="3">
                                <li>refrain from communicating false, fraudulent, deceptive or disparaging or unfair
                                    statements or claims;
                                </li>
                            </ol>
                            <ol start="4">
                                <li>refrain from verbal / physical / emotional / sexual harassment;</li>
                            </ol>
                            <ol start="5">
                                <li>contribute to the advancement and competence of colleagues, SNA and Sports
                                    Nutrition.
                                </li>
                            </ol>
                            <span className="gaps gap-sc"></span>
                            <a href="/snassoc/terms/" className="button btn-normal"><span>Terms &amp; Conditions</span></a>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    );
}