import React from 'react';
import { useHistory } from "react-router-dom";


export default function Url() {

    const history = useHistory();

    let baseUrl = '';
    if(window.location.pathname.substring(0, 8) === '/iframe/'){
        baseUrl = '/iframe';
    }

	const setUrl = (url) => {
        history.replace({pathname: baseUrl+url})
    };


    const obj = {};
    obj.setUrl = setUrl;

	return obj
}