import React, {useEffect, useState} from 'react';
import useToken from '../hooks/useToken';
import {NavLink} from "react-router-dom";

import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import API2 from "../components/api2";




async function loginUser(credentials) {



	return fetch('api/login', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	})
		.then(data => data.json())
}


export default function Login(props) {

	const AP = API2();
	const [form] = Form.useForm();
	const { user, setUser } = useToken();
	const [username, setUserName] = useState();
	const [password, setPassword] = useState();

	const handleSubmit = async ( values: any) => {
		AP.post('/login',values)
			.then(function(res){
				setUser(res.user, res.access_token);

				if(props.location.state && props.location.state.origLocation){
                    window.location.replace(props.location.state.origLocation.pathname);
                }else{
                    window.location.replace("/dashboard");
                }

			})
			.catch(function (error) {
				form.setFields([
					{
						name: 'password',
						errors: ['Email or Password is incorrect'],
					},
				]);
			});
	}

	return(
		<div className="content_inner_padded" style={{height:"100%"}}>

            <div className="ibn ibn-sb sec">
                <div className="ebn ud-bn ud-pt obg uix-alter ebn-p9x sec" style={{maxHeight:300}}>
                    <div className="cpnw cov xg">
                        <div className="main">
                            <div className="cpn al-g cl-g bg-g wd-g">
                                <div className="title">
                                    <h1>Members Area</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg hs-bg is-basic" data-height="auto" style={{"backgroundImage":"url('https://sportsnutritionassociation.com/wp-content/uploads/2022/11/advisory-board-banner.jpg')"}}>
                        <div className="over tpb op01" style={{"opacity":"0.01"}}></div>
                    </div>
                </div>
            </div>

            <div className="site-wrap ipg ishop ipg-member-login sec" style={{paddingTop:50,height:"100%"}}>
                <div className="main">
                    <div className="wrap wrap-rg no-aside wrap-page wrap-shop gb-wd-sd row">
                        <div className="icontent g12 iwc">
                            <article className="epc epg">
                                <div className="ientry">
                                    <div className="woocommerce">
                                        <div className="woocommerce-notices-wrapper"></div>

                                        <div className="u-columns col2-set" style={{display:"flex",gap:20,justifyContent:"center"}} id="customer_login">

                                            <div className="u-column1 col-1" style={{flex:1,display:"flex",flexDirection:"column",maxWidth:470}}>

                                                {false &&
                                                <h2>Login</h2>
                                                }

                                                <div style={{border:"1px solid #d3ced2",padding:20,textAlign:"left",borderRadius:5,flex:1}}>
                                                    <Form
                                                        form={form}
                                                        name="normal_login"
                                                        className="login-form"
                                                        initialValues={{remember: true}}
                                                        onFinish={handleSubmit}
                                                        style={{ width:"290px",margin:"auto",display:"flex",flexDirection:"column",height:"100%"}}
                                                    >
                                                        <Form.Item
                                                            name="username"
                                                            rules={[{required: true, message: 'Please input your Email!'}]}
                                                        >
                                                            <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="password"
                                                            rules={[{required: true, message: 'Please input your Password!'}]}
                                                        >
                                                            <Input
                                                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                                                type="password"
                                                                placeholder="Password"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item style={{flex:1}}>
                                                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                                                <Checkbox>Remember me</Checkbox>
                                                            </Form.Item>

                                                            <NavLink exact to="/forgotPassword" className="login-form-forgot" activeClassName="current">
                                                                <span>Forgot password</span>
                                                            </NavLink>
                                                        </Form.Item>

                                                        <Row>
                                                            <Col style={{marginLeft:"auto"}}>

                                                                    <Button type="primary" htmlType="submit" className="login-form-button" shape="round" size="large">
                                                                        Log in
                                                                    </Button>

                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>

                                            </div>



                                            {false &&
                                            <div className="u-column2 col-2" style={{flex: 1, gap: 20}}>

                                                <h2>Register</h2>

                                                <form method="post"
                                                      className="woocommerce-form woocommerce-form-register register"
                                                      style={{
                                                          border: "1px solid #d3ced2",
                                                          padding: 20,
                                                          textAlign: "left",
                                                          borderRadius: 5
                                                      }}>


                                                    <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                        <label htmlFor="reg_email">Email address&nbsp;<span
                                                            className="required">*</span></label>
                                                        <input type="email"
                                                               className="woocommerce-Input woocommerce-Input--text input-text"
                                                               name="email" id="reg_email" autoComplete="email"
                                                               value=""
                                                               style={{width: "100%"}}
                                                        /></p>


                                                    <p>A password will be sent to your email address.</p>


                                                    <div className="woocommerce-privacy-policy-text"><p>Your personal
                                                        data will be used to support your experience throughout this
                                                        website, to manage access to your account, and for other
                                                        purposes described in our <a
                                                            href="https://www.sportsnutritionassociation.com/?page_id=3"
                                                            className="woocommerce-privacy-policy-link" target="_blank">privacy
                                                            policy</a>.</p>
                                                    </div>
                                                    <Row>
                                                        <Col style={{marginLeft: "auto"}}>
                                                            <Button type="primary" htmlType="submit"
                                                                    className="login-form-button" shape="round"
                                                                    size="large">
                                                                Register
                                                            </Button>
                                                        </Col>
                                                    </Row>


                                                </form>

                                            </div>
                                            }

                                        </div>

                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>




		</div>
	)
}

