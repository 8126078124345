import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin,
    Table,
    Modal,
	Tabs,
	Checkbox
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import Moment from 'react-moment';
import BreadCrumbContext from "../contexts/breadCrump";

import DiscountForm from "./Discount"
import PenaltyForm from "./Penalty"


const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane }  = Tabs;




export default function SubscriptionForm(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [subscription, setSubscription] = useState({});
    const [plans, setPlans] = useState([]);
    const [discounts, setDiscounts] = useState([]);
	const [discount, setDiscount] = useState([]);
	const [discountDelete, setDiscountDelete] = useState(false);
	const [discountModalIsOpen, setDiscountModalIsOpen] = React.useState(false);
	const [penalties, setPenalties] = useState([]);
	const [penalty, setPenalty] = useState([]);
	const [penaltyDelete, setPenaltyDelete] = useState(false);
	const [penaltyModalIsOpen, setPenaltyModalIsOpen] = React.useState(false);
	const [endAt, setEndAt] = useState(false);

    const [discountForm] = Form.useForm();
	const [penaltyForm] = Form.useForm();


	let defaultTab = "general";//props.match.params.tab?props.match.params.tab:"general";


    const AP = API2();

    const discountColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            align:'left'
        },
        {
            title: 'Discount',
            key: 'discount',
            dataIndex: 'discount',
            align:'left',
            width: "110px",
            render: function(text, record) {
                let discount = parseFloat(record.discount);
                if (record.type == 'fixed') {
                    discount = '$' + (discount)/100;
                } else {
                    discount = discount + '%';
                }
                return <div>{discount}</div>
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            width:"100px",
            render: function(record){

                if(record.is_credit) return;

                return <div>
                    <Button type="primary" size="small" shape="round" onClick={() => openDiscountModal(record)}><i className="far fa-edit"/></Button>
                </div>
            }
        }


    ];
	const penaltyColumns = [
		{
			title: 'Name',
			key: 'name',
			dataIndex: 'name',
			align:'left'
		},
		{
			title: 'Amount',
			key: 'amount',
			dataIndex: 'amount',
			align:'left',
			width: "110px",
			render: function(text, record) {
				let discount = parseFloat(record.amount);
				if (record.type == 'fixed') {
					discount = '$' + (discount)/100;
				} else {
					discount = discount + '%';
				}
				return <div>{discount}</div>
			}
		},
		{
			title: 'Actions',
			key: 'actions',
			width:"100px",
			render: function(record){
				return <div>
					<Button type="primary" size="small" shape="round" onClick={() => openPenaltyModal(record)}><i className="far fa-edit"/></Button>
				</div>
			}
		}


	];


	//get plans
    useEffect(() => {

        props.form.resetFields();

        let url = '/plans?showPrivate=true&sort=starts_at&pageSize=9999';
        if(props.subscription && props.subscription.id){
            url += '&subscriptionId='+props.subscription.id;

			props.subscription.auto_renew = !props.subscription.do_not_renew;
        }

        if(props.user){
			url += '&userId='+props.user.id;
		}

		setSubscription(props.subscription);
		setDiscounts(props.subscription.discounts);
		setPenalties(props.subscription.penalties);

		if(props.subscription) {
			props.subscription.date = [];
			if (props.subscription.created_at) {
				props.subscription.date.push(moment(props.subscription.created_at * 1000));
			} else {
				props.subscription.date.push(null);
			}

			if (props.subscription.end_at) {
				props.subscription.end_at = moment(props.subscription.end_at*1000 );
			}
			setEndAt(props.subscription.end_at);

			props.form.setFieldsValue(props.subscription);
			setIsLoading(false);
		}else{
			setSubscription({});
		}

        AP.get(url)
            .then(function (res) {
                setPlans(res.data);

				setIsLoading(false);
            });



    }, [props.subscription]);


    let formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };



    function saveForm(data){

        setIsSaving(true);

        /*
        if(data.date[0]){
            data.start_at = data.date[0].unix();
        }
        */
        delete data.date;

        let url = '/subscription';
        if(props.subscription) {
            url += '/'+props.subscription.id;
        }else{
            data.user_id = props.user.id;
        }

        if(data.end_at){
            data.end_at = data.end_at.unix()+36000;
        }

        AP.post(url,data).then(function(res){

            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            props.onFinish();
        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    props.form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }

    function openDiscountModal(discount){
        setDiscount(discount);
        setDiscountModalIsOpen(true);
    }
	function openPenaltyModal(penalty){
		setPenalty(penalty);
		setPenaltyModalIsOpen(true);
	}

    function closeDiscountModal(res) {

        if(res) {
            if(res.delete){
                for (let d in discounts) {
                    if (discounts[d].id == res.id) {
                        discounts.splice(d,1);
                        break;
                    }
                }
            }
            else {
                let wasFound = false;
                for (let d in discounts) {
                    if (discounts[d].id == res.id) {
                        wasFound = true;
                        discounts[d] = res;
                        break;
                    }
                }
                if (!wasFound) {
                    discounts.push(res)
                }
            }

            setDiscounts([...discounts]);
        }
        setDiscountModalIsOpen(false);
    }
	function closePenaltyModal(res) {

		if(res) {
			if(res.delete){
				for (let p in penalties) {
					if (penalties[p].id == res.id) {
						penalties.splice(p,1);
						break;
					}
				}
			}
			else {
				let wasFound = false;
				for (let p in penalties) {
					if (penalties[p].id == res.id) {
						wasFound = true;
						discounts[p] = res;
						break;
					}
				}
				if (!wasFound) {
					penalties.push(res)
				}
			}

			setPenalties([...penalties]);
		}
		setPenaltyModalIsOpen(false);
	}



    function deleteForm(){

        if(!props.subscription.id) return false;

        setIsSaving(true);

        let url = '/subscription/'+props.subscription.id;

        AP.delete(url).then(function(res){

            notification.success({
                message: `Deleted`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setDiscountModalIsOpen(false);
			setPenaltyModalIsOpen(false);
        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    props.form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }

    function getStatusOptions(){
        if(props.subscription){
            if(props.subscription.plan.for == "member"){
                return [
                    <Option value="active">Active</Option>,
                    <Option value="on-hold">On Hold</Option>,
					<Option value="completed">Completed</Option>,
                    <Option value="pending-cancel">Pending Cancel</Option>,
                    <Option value="cancelled">Cancelled</Option>,
                    <Option value="pending-deregister">Pending Deregister</Option>,
                    <Option value="deregistered">Deregistered</Option>,
                    <Option value="debt">Debt</Option>,
                    <Option value="hybernating">Hybernating</Option>
                ];
            }
            if(props.subscription.plan.for == "student"){
                return [
                    <Option value="active">Active</Option>,
                    <Option value="on-hold">On Hold</Option>,
					<Option value="completed">Completed</Option>,
                    <Option value="pending-cancel">Pending Cancel</Option>,
                    <Option value="cancelled">Cancelled</Option>,
                    <Option value="debt">Debt</Option>,
                    <Option value="hybernating">Hybernating</Option>
                ];
            }
        }
        return [
            <Option value="active">Active</Option>,
            <Option value="on-hold">On Hold</Option>,
            <Option value="pending-deregister">Pending Deregister</Option>,
            <Option value="completed">Completed</Option>,
            <Option value="hybernating">Hybernating</Option>
        ];
    }
console.log(['subscription',subscription]);
    return <>

		<Tabs
			defaultActiveKey={defaultTab}
			onChange={key => {
				//props.history.replace({ pathname: "/user/"+id+"/"+key});
			}}
			renderTabBar={(props, TabNavList) => (
				<TabNavList {...props} mobile={false} />
			)}
			style={{top: -20, position:"relative"}}
		>

			<TabPane tab="General" key="general" style={{paddingTop:20}}>
				<Form
					id="subscriptionForm"
					{...formItemLayout}
					form={props.form}
					name="subscription"
					onFinish={saveForm}
					scrollToFirstError
					style={{ margin:"auto"}}
				>
					<Form.Item
						name="plan_id"
						label="Plan"
						rules={[
							{
								required: true,
								message: 'Please select a plan',
							}
						]}
					>
						<Select
							showSearch
							autoComplete="none"
							filterOption={function(input, option){
								const bits = input.split(" ");
								const searchStr = option.searchStr.toLowerCase();
								for(const bit of bits){
									if(searchStr.indexOf(bit.toLowerCase()) === -1) return false;
								}
								return true;
							}}
							placeholder="Select a option and change input text above"
						>
							{plans.map(plan => <Option value={plan.id} key={plan.id} searchStr={plan.name+' '+moment(plan.starts_at * 1000).format("Do MMM YYYY")} disabled={!plan.hasStripeAcc}>
								<div style={{display:"flex"}}>
									<span style={{flex:1}}>{plan.name}</span>
									{plan.starts_at?<span>
										<Moment format="Do MMM YYYY" plan>{plan.starts_at*1000}</Moment>
									</span>:''}
								</div>
							</Option>)}
						</Select>
					</Form.Item>


					<Form.Item
						name="status"
						label="Status"
					>

						<Select
							placeholder="Select a option and change input text above"
						>
							{getStatusOptions()}
						</Select>
					</Form.Item>

					<Form.Item
						name="credits"
						label="Credits"
					>
						<Select
							placeholder=""
						>
							<Option value="0">None</Option>
							<Option value="1">1</Option>
							<Option value="2">2</Option>
							<Option value="3">3</Option>
							<Option value="4">4</Option>
							<Option value="5">5</Option>
							<Option value="6">6</Option>
							<Option value="7">7</Option>
							<Option value="8">8</Option>
							<Option value="9">9</Option>
							<Option value="10">10</Option>
							<Option value="11">11</Option>
							<Option value="12">12</Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="end_at"
						label="End At"
						help="DD/MM/YYYY"
					>
						<DatePicker format="DD/MM/YYYY" onChange={(e) => setEndAt(e)}/>
					</Form.Item>

					{endAt &&
						<Form.Item
							name="end_status"
							label="End Status"
						>
							<Select>
								<Option value="cancelled">Cancelled</Option>
								<Option value="deregistered">Deregistered</Option>
								<Option value="completed">Completed</Option>
							</Select>
						</Form.Item>
					}

					{!!props.subscription && !!props.subscription.plan.auto_renew &&
						<Form.Item style={{flex:1}}
							name="auto_renew"
							label="Auto Renew"
							valuePropName="checked"
						>
							<Checkbox style={{  zoom:1.5, padding: "9px 0"}}/>
						</Form.Item>
					}

					{![1,2].includes(subscription.plan_id) && <>
						<Form.Item style={{flex:1, marginBottom:0}}
							name="cert_email_sent_at"
							label="Certificate Email Sent"
							valuePropName="checked"
						>
							<Checkbox style={{  zoom:1.5, padding: "9px 0"}}/>
						</Form.Item>

						<Form.Item style={{flex:1, marginBottom:0}}
							name="cert_letter_sent_at"
							label="Certificate Letter Sent"
							valuePropName="checked"
						>
							<Checkbox style={{  zoom:1.5, padding: "9px 0"}}/>
						</Form.Item>
					</>}

				</Form>
			</TabPane>
			{props.subscription && <>
				<TabPane tab={<span>Discounts <span className="fa-stack" style={{fontSize:".8em"}}><i className="fa fa-circle fa-stack-2x" style={{color:"lightgrey"}}/><strong className="fa-stack-1x calendar-text">{discounts.length}</strong></span></span>} key="discounts" style={{paddingTop:20}}>
					<div style={{ display:"flex"}}>
						<div style={{marginLeft: "auto", position: "relative", top: -10}}>
							<Button
								icon={<i className="fad fa-tags"></i>}
								onClick={() => openDiscountModal(false)}
							>&nbsp;Add Discount </Button>
						</div>
					</div>

					<Table
						dataSource={discounts}
						tableLayout="fixed"
						pagination={false}
						rowKey="id"
						columns={discountColumns}
					/>
				</TabPane>
				<TabPane tab={<span>Penalties <span className="fa-stack" style={{fontSize:".8em"}}><i className="fa fa-circle fa-stack-2x" style={{color:"lightgrey"}}/><strong className="fa-stack-1x calendar-text">{penalties.length}</strong></span></span>} key="penalties" style={{paddingTop:20}}>
					<div style={{ display:"flex"}}>
						<div style={{marginLeft: "auto", position: "relative", top: -10}}>
							<Button
								icon={<i className="fad fa-money-check-edit-alt"></i>}
								onClick={() => openPenaltyModal(false)}
							>&nbsp;Add Penalty </Button>
						</div>
					</div>

					<Table
						dataSource={penalties}
						tableLayout="fixed"
						pagination={false}
						rowKey="id"
						columns={penaltyColumns}
					/>
				</TabPane>
			</>}

		</Tabs>

		<Modal
			visible={discountModalIsOpen}
			onOk={() => {discountForm.submit();}}
			onCancel={closeDiscountModal}
			title={discount?"Edit Discount":"Create Discount"}
			okText="Save"
			cancelText="Close"
			width={800}
			footer={
				<div style={{display:"flex"}}>
					<Button key="delete" danger onClick={function(){setDiscountDelete(true)}}>
						Delete
					</Button>
					<Button key="back" onClick={function(){closeDiscountModal();}} style={{marginLeft:"auto"}}>
						Close
					</Button>
					<Button key="submit" type="primary" onClick={function(){discountForm.submit();}}>
						Save
					</Button>
				</div>
			}
			//centered={true}
		>
			<DiscountForm form={discountForm} discount={discount} subscription={props.subscription} onFinish={closeDiscountModal} triggerDelete={discountDelete}/>
		</Modal>
		<Modal
			visible={penaltyModalIsOpen}
			onOk={() => {penaltyForm.submit();}}
			onCancel={closePenaltyModal}
			title={discount?"Edit Penalty":"Create Penalty"}
			okText="Save"
			cancelText="Close"
			width={550}
			footer={
				<div style={{display:"flex"}}>
					<Button key="delete" danger onClick={function(){setPenaltyDelete(true)}}>
						Delete
					</Button>
					<Button key="back" onClick={closePenaltyModal} style={{marginLeft:"auto"}}>
						Close
					</Button>
					<Button key="submit" type="primary" onClick={function(){penaltyForm.submit();}}>
						Save
					</Button>
				</div>
			}
			//centered={true}
		>
			<PenaltyForm form={penaltyForm} penalty={penalty} subscription={props.subscription} onFinish={closePenaltyModal} triggerDelete={penaltyDelete}/>
		</Modal>
	</>;
}