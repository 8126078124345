import React, { useState, useEffect, useContext  } from "react";
import { Select, Spin, AutoComplete as AntAutoComplete  } from 'antd';
import {NavLink} from "react-router-dom";
import API2 from "./api2";

let timeoutId = false;

export default function AutoComplete(props) {


    const AP = API2();

	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState([]);
	const fetchRef = React.useRef(0);
	//const [timeoutId, setTimeoutId] = useState(false);
	const fetcher = (value) => {

		fetchRef.current += 1;
		const fetchId = fetchRef.current;


		AP.get(props.url+'?search='+value)
			.then(function (newOptions) {

				setOptions([]);
				setFetching(true);

				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}

				const opts = [];
				for (let [groupName, ops] of Object.entries(newOptions)) {

					const groupOpts  = [];
					for (let [url, label] of Object.entries(ops)) {
						groupOpts.push({ label:label, value: url });

					}
					opts.push({
						label:groupName,
						options: groupOpts,
					});

				}

			//	debugger;



				setOptions(opts);
				setFetching(false);

			});

	};

	const fetcherDelay = (value) => {

		if(timeoutId){
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(function(){
			fetcher(value);
		},300);
	}


	return (
		<div style={{display:"flex",maxWidth:690, width:"100%"}}>
			<div style={{color:"black", position:"relative", left:25, zIndex:9, top:3}}>
				<i className="fas fa-search"/>
			</div>
			<div style={{flex:1}}>
				<AntAutoComplete
					options={options}
					style={{ width: 200 }}
					onSearch={fetcherDelay}
					{...props}
				/>
				{/*
				<Select
					labelInValue
					filterOption={false}
					onSearch={fetcher}
					notFoundContent={fetching ? <Spin size="small" /> : null}
					{...props}
					options={options}
				/>
				*/}
			</div>
		</div>
	);
};