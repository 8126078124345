import React, {Component, useContext, useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import { Line } from '@ant-design/charts';
import { Link } from "react-router-dom";
import { Tabs , Spin, Divider, List, Form, Input, Switch, Button, Modal, Select } from 'antd';
import Url from "../../hooks/Url";
import "../../css/registry.css"

import { UserOutlined } from '@ant-design/icons';
import API2 from "../../components/api2";
import RequestNutritionistForm from "../../forms/requestNutritionist";
import { Textfit } from 'react-textfit';
import PublicSendEmailForm from "../../forms/PublicSendEmail";
import RegistryCard from "./RegistryCard";
import UnregisteredCard from "./UnregisteredCard";
import StaticUnregistered from "./StaticUnregistered";
import useWindowDimensions from "../../components/useWindowDimensions";


const { TabPane } = Tabs;


export default function Registry(props) {

    const [nutritionists, setNutritionists] = useState([]);
    const { width } = useWindowDimensions();
	const [form] = Form.useForm();
    const AP  = API2();
    const URL = Url();
	const setBreadCrumbs = useContext(BreadCrumbContext);
    const [active, setActive] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [region, setRegion] = useState(props.match.params.region);
    const [searching, setSearching] = useState(false);
    const [emailModelUser, setEmailModelUser] = useState(false);
    const [requestHasUsers, setRequestHasUsers] = useState(false);
    const [showUnregistered, setShowUnregistered] = useState(null);
    const [specializations, setSpecializations] = useState([]);
    const [searchOpen, setSearchOpen] = useState(true);
    const [searchProvisional, setSearchProvisional] = useState(true);
    const [searchSpecialization, setSearchSpecialization] = useState(null);

    const [createEmailModalForm] = Form.useForm();

    function emailSent(){
        setEmailModelUser(false);
    }


    let host = window.location.hostname;
    let isRegistry = ['sna.local','staging.sportsnutritionistregistry.com','www.sportsnutritionistregistry.com'].includes(host);
    let isIframe   = window.location.pathname.substr(0,8) == '/iframe/';
    if(isIframe){
        isRegistry = true;
    }


    function getData(){

        setSearching(true);
        let params = [];
        if(searchText){
            params.push('search='+searchText);
        }
        if(!showUnregistered && props.match.params.region){
            params.push('users.region="'+props.match.params.region.toLowerCase()+'"');
        }

        if(showUnregistered){
            params.push('unregistered=1');
        }

        if(searchOpen){
            params.push('open=1');
        }
        if(searchProvisional){
            params.push('provisional=1');
        }
        if(searchSpecialization){
            params.push(`specialization=${searchSpecialization}`);
        }

        let paramStr = '';
        if(params){
            paramStr = '?'+params.join("&");
        }

        AP.get('/nutritionists'+paramStr)
            .then(function(res){
                setNutritionists(res.data);
                setSearching(false);
            });
    }

    function search(value){
        setSearchText(value);
    }

    function setRegionFunc(newRegion){
        if(region==newRegion){
            newRegion = '';
        }

        if(isIframe){
            URL.setUrl('/nutritionists/'+newRegion);
        }
        else if(isRegistry) {
            URL.setUrl('/' + newRegion);
            //props.history.replace({pathname: '/' + newRegion})
        }else{
            URL.setUrl('/nutritionists/' + newRegion);
            //props.history.replace({pathname: '/nutritionists/' + newRegion})
        }
    }

    useEffect(() => {
        setRegion(props.match.params.region);
        if(!searchText && !props.match.params.region){
            setNutritionists([]);
            return;
        }

        getData();
    }, [searchText,props.match, searchOpen, searchProvisional, searchSpecialization]);

    useEffect(() => {
        if(showUnregistered===null)return;
        //getData();
    }, [showUnregistered]);

    useEffect(() => {
        AP.get('/specializations')
            .then(function (res) {
                setSpecializations(res.data);
            });
    }, []);

    return (

        <div className="content_inner" style={{height:"100%", fontFamily:"'Poppins', Arial, Helvetica, sans-serif"}}>
            <div className="site-wrap ipg iopg sec" style={{height:"100%"}}>
                <div className="wrap wrap-wd no-aside sec">
                    <div className="icontent sec">
                        <div className="sec sec-top-nutrition">
                            <div className="top-nutrition">
                                <div className="wg wg-top widget_text">
                                    <div className="textwidget" style={{display:"flex"}}>
                                        <div style={{paddingTop:(isRegistry?0:'85'),backgroundImage:"linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(https://www.sportsnutritionistregistry.com/wp-content/uploads/2020/11/banner.jpg)", flex:1, backgroundRepeat:"no-repeat", backgroundPosition:"50% 50%", backgroundSize:"cover", width: "100%"}}>
                                            <div className="section sec-sc sec basic row-xd sec-rg sec-ov light ud-pad tpd-s bpd-l ud-bgx">
                                                <div className="main">
                                                    <div className="content">
                                                        <p>
                                                            <a href="https://www.sportsnutritionistregistry.com/">
                                                                <img
                                                                    loading="lazy" className="sports-logo aligncenter wp-image-39"
                                                                    src="/images/sports_nutritionist_logo.png"
                                                                    alt="" width="368" height="188"
                                                                    srcSet="/images/sports_nutritionist_logo.png"
                                                                    sizes="(max-width: 368px) 100vw, 368px"
                                                                />
                                                            </a>
                                                        </p>
                                                        <span className="gaps gap-sc"></span>
                                                        <Textfit
                                                            mode="single"
                                                            maxz={24}
                                                            style={{textAlign:"center", fontWeight:700}}
                                                        >Find a local, qualified &amp; insured sports nutritionist</Textfit>
                                                        <p style={{textAlign:"center"}}>International database for Accredited Sports Nutritionists.</p>
                                                        {(!searchText && !region) && <p style={{textAlign:"center"}}>All Accredited Sports Nutritionists have met the minimum educational and professional requirements to register as either Provisionally or Openly Accredited members.</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section sec-sc sec basic row-xd sec-rg sec-top-outdent ud-pad" style={{paddingTop:0,paddingBottom:0}}>
                                                <div className="main">
                                                    <div className="content">

                                                        <div style={{display:"flex", justifyContent:"center", paddingBottom:20, gap:30, flexWrap:"wrap"}}>
                                                            <Button className="ant-btn-giant" type={showUnregistered?"":"primary"} onClick={()=>(setShowUnregistered(false))} style={{width:371}}>Practicing</Button>
                                                            <Button className="ant-btn-giant" type={showUnregistered?"primary":""} onClick={()=>(setShowUnregistered(true))}>Non-affiliated Practitioner List</Button>
                                                        </div>
                                                            
                                                        {!showUnregistered && <>
                                                            <div className="nutritionist-sec sec">
                                                                <div className="search-block sec">
                                                                    <div className="search-block-indent">
                                                                        <div method="post" className="search-form-widget" style={{border:"1px solid #000", backgroundColor:"#fff", color:"#999999",borderRadius:50,padding:10,display:"flex",fontSize:18,height:90,boxSizing:"border-box"}}>
                                                                            <Input.Search
                                                                                placeholder="Enter Sports Nutritionists details/location or select a country below"
                                                                                className="search-input"
                                                                                prefix={<i className="fas fa-search" style={{color:"#08a8f9"}}></i>}
                                                                                onSearch={search}
                                                                                allowClear={true}
                                                                                enterButton=<button className="button btn-round-blue" type="button" id="searchsubmit">Search</button>
                                                                            />
                                                                        </div>
                                                                        <div className="nutritionist-cat-block sec">
                                                                            <ul
                                                                                className="nutritionist-cats"
                                                                                style={{display:"flex",justifyContent:"center",padding:30,margin:0, flexWrap:"wrap"}}
                                                                            >
                                                                                <li className="category australia first">
                                                                                    <Button
                                                                                        type={(region=='Australia')?'primary':'text'}
                                                                                        className={(region=='Australia')?"":"btn-text-lightblue"}
                                                                                        shape="round"
                                                                                        size={"large"}
                                                                                        style={{height:50,color:"white","fontSize":24}}
                                                                                        onClick={() => setRegionFunc("Australia")}
                                                                                    >Australia</Button>
                                                                                </li>
                                                                                <li className="category united-states">
                                                                                    <Button
                                                                                        type={(region=='UnitedStates')?'primary':'text'}
                                                                                        className={(region=='UnitedStates')?"":"btn-text-lightblue"}
                                                                                        shape="round"
                                                                                        size={"large"}
                                                                                        style={{height:50,color:"white","fontSize":24}}
                                                                                        onClick={() => setRegionFunc("UnitedStates")}
                                                                                    >United States</Button>
                                                                                </li>
                                                                                <li className="category new-zealand">
                                                                                    <Button
                                                                                        type={(region=='NewZealand')?'primary':'text'}
                                                                                        className={(region=='NewZealand')?"":"btn-text-lightblue"}
                                                                                        shape="round" size={"large"}
                                                                                        style={{height:50,color:"white","fontSize":24}}
                                                                                        onClick={() => setRegionFunc("NewZealand")}
                                                                                    >New Zealand</Button>
                                                                                </li>
                                                                                <li className="category asia">
                                                                                    <Button
                                                                                        type={(region=='Asia')?'primary':'text'}
                                                                                        className={(region=='Asia')?"":"btn-text-lightblue"}
                                                                                        shape="round" size={"large"}
                                                                                        style={{height:50,color:"white","fontSize":24}}
                                                                                        onClick={() => setRegionFunc("Asia")}
                                                                                    >Asia</Button>
                                                                                </li>
                                                                                <li className="category europe">
                                                                                    <Button
                                                                                        type={(region=='Europe')?'primary':'text'}
                                                                                        className={(region=='Europe')?"":"btn-text-lightblue"}
                                                                                        shape="round" size={"large"}
                                                                                        style={{height:50,color:"white","fontSize":24}}
                                                                                        onClick={() => setRegionFunc("Europe")}
                                                                                    >Europe</Button>
                                                                                </li>
                                                                                <li className="category canada last">
                                                                                    <Button
                                                                                        type={(region=='Canada')?'primary':'text'}
                                                                                        className={(region=='Canada')?"":"btn-text-lightblue"}
                                                                                        shape="round" size={"large"}
                                                                                        style={{height:50,color:"white","fontSize":24}}
                                                                                        onClick={() => setRegionFunc("Canada")}
                                                                                    >Canada</Button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div 
                                                                            className="nutritionist-cat-block sec" 
                                                                            style={{color:"white", display:(width>1000)?"flex":null, justifyContent:"center", gap:50, alignItems:"center", marginBottom:30}}

                                                                        >
                                                                            <div>
                                                                                <Switch checked={searchOpen} className="blue_gray" onChange={checked=>setSearchOpen(checked)} /> Open Registration
                                                                            </div>
                                                                            <div>
                                                                                <Switch checked={searchProvisional} className="blue_gray" onChange={checked=>setSearchProvisional(checked)} /> Provisional Registration
                                                                            </div>
                                                                            <div>
                                                                                {!specializations.length && <>
                                                                                    <Spin/>
                                                                                </>}
                                                                                {!!specializations.length && <div style={{display:"flex", width:300, alignItems:"center",gap:10}}>
                                                                                    <div>Specialization:</div>
                                                                                    <Select style={{flex:1}} className="autoHeight" value={searchSpecialization} onChange={val=>setSearchSpecialization(val)}>
                                                                                            <Select.Option key="all" value={null} onChange={val=>setSearchSpecialization(val)}>All</Select.Option>
                                                                                        {specializations.map(specialization=>(
                                                                                            <Select.Option key={specialization.name} value={specialization.name}>
                                                                                                {specialization.name}
                                                                                            </Select.Option>
                                                                                        ))}
                                                                                    </Select>
                                                                                
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section sec-sc sec basic row-xd sec-rg sec-top-outdent ud-pad" style={{paddingTop:30,paddingBottom:0}}>
                            <div className="main">
                                <div className="content">
                                    <div id="datafetch" className="datafetch sec">

                                        {(searchText || region || showUnregistered) && <>
                                            {showUnregistered && <>
                                                <div style={{paddingBottom:40}}>
                                                    <p>The Sports Nutrition Association is committed to ensuring that the highest standard of professional practice is delivered to clients by its members. In order to do so, the Sports Nutrition Association has developed governance and educational frameworks that it requires members to comply with in order to maintain their registration.This list only encompasses practitioners that were, either at one point in time, members of SNA but have now ceased to be affiliated with the association. Or have claimed publicly to be recognised endorsed, registered or Accredited by the Association, or the use of it's mark/s (Accredited Sports Nutritionist tms).We have developed the below Non-affiliated Practitioner List. To enable clients and the public generally to easily ascertain whether a practitioner is affiliated with Sports Nutrition Association, it's registration requirements.</p>
                                                    <p>
                                                        <span>Such as but not limited to:</span>
                                                        <ul style={{listStyle:"initial", marginLeft:41}}>
                                                            <li><i>Education minimum standards,</i></li>
                                                            <li><i>Professional codes of conduct,</i></li>
                                                            <li><i>Scope of practice &amp;</i></li>
                                                            <li><i>Insurance coverage for protecting themselves &amp; their clients generally.</i></li>
                                                        </ul>
                                                    </p>
                                                    <p>It is important to note that this list no way implies, or is taken to be construed as, commentary on the suitability of the relevant practitioner to engage in professional practice. The intent of the SNA Non-affiliated Practitioner list is to identify to individuals and the public that the relevant practitioner is not affiliated or associated with the Sports Nutrition Association, and that the Sports Nutrition Association exercises no quality control or supervision over these relevant practitioners.</p>
                                                </div>
                                            </>}
                                            
                                            {!!showUnregistered && <div className="datafetch-block"><StaticUnregistered/></div>}
                                            {!showUnregistered && <>
                                                <div className="datafetch-block">
                                                    <div className="nutritionists-list">
                                                        <div className="row" style={{display:"flex", flexWrap:"wrap", gap:20}}>
                                                            {searching && <div style={{justifyContent:"center", display:"flex", paddingTop:90}}>
                                                                <Spin size="large"/>
                                                            </div>}


                                                            {!searching && nutritionists.map(function (nutritionist) {
                                                                if(!showUnregistered){
                                                                    return <RegistryCard nutritionist={nutritionist} setEmailModelUser={setEmailModelUser}/>;
                                                                }else{
                                                                    return <UnregisteredCard nutritionist={nutritionist} setEmailModelUser={setEmailModelUser}/>;
                                                                }
                                                                
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {!searching && !nutritionists.length &&
                                                    <div className="datafetch-block" style={{padding: "70px 0"}}>
                                                        <div className="no-data"
                                                            style={{textAlign: "center", maxWidth: 750, margin: "0 auto"}}>
                                                            <p><strong>No nutritionists found.</strong><br/>Please try using a state
                                                                or
                                                                country, or hit the country button (above) of your
                                                                choice for a consolidated list of Accredited Sports
                                                                Nutritionists near you!</p>
                                                        </div>
                                                    </div>
                                                }
                                            </>}

                                            

                                            <hr/>
                                        </>}

                                        {(!searchText && !region && !showUnregistered) && <>
                                            <div class="nutritionist-search" style={{display: "flex"}}>
                                                {!requestHasUsers && <>
                                                    <div style={{padding: 30, flex: 1}}>
                                                        <img src="/images/accreditation_wheel.jpg"/>
                                                        {false && <>                                                <h4
                                                            style={{
                                                                textAlign: "center",
                                                                color: "#1890ff",
                                                                paddingTop: 30
                                                            }}>Levels of Accreditation</h4>
                                                            <p>The Sports Nutrition Association’s levels of accreditation
                                                                are
                                                                outlined below, the certificate enables Provisional
                                                                Accreditation for 3 years after completing the program,
                                                                whereby
                                                                they must enroll in either the Graduate Diploma or another
                                                                Relevant** Exercise Physiology or Specialist Dietetics
                                                                program.</p>
                                                            <p>Members who already hold a degree in this are exempt and
                                                                receive
                                                                full accreditation immediately.
                                                            </p>
                                                        </>
                                                        }

                                                        
                                                        <h3 style={{paddingTop: 20}}>
                                                            <Textfit
                                                                mode="single"
                                                                //style={{textAlign:"center", fontWeight:700}}
                                                            >Typical rates by service providers <span style={{fontSize:".6em"}}>in USD</span></Textfit>

                                                        </h3>
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            flexDirection: "column",
                                                            fontSize:"20px"
                                                        }}>
                                                            <div style={{display: "flex"}}>
                                                                <div style={{width: 200}}>Provisional:</div>
                                                                <div>$25-100/wk</div>
                                                            </div>
                                                            <div style={{display: "flex"}}>
                                                                <div style={{width: 200}}>Open:</div>
                                                                <div>$60-200/wk</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}

                                                <div style={{
                                                    flex: 1,
                                                    padding: 30,
                                                    backgroundColor: "#f4f4f6",
                                                    borderRadius: 20,
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                    display: "flex"
                                                }}>
                                                    <RequestNutritionistForm setRequestHasUsers={setRequestHasUsers}/>
                                                </div>
                                            </div>

                                            <hr/>

                                            <div className="content" style={{paddingBottom: 50}}>
                                                <p>The Certificate program is the entry level education program for
                                                    eligibility to register as a provisionally Accredited Sports
                                                    Nutritionist. This Certificate comprises 12 subjects across
                                                    Exercise &amp; Nutrition Physiology and Sports Nutrition
                                                    Practise.</p>
                                                <p>All openly accredited members have at the very least completed; a
                                                    Graduate Diploma or Bachelors in a relevant Nutrition or Exercises
                                                    Science program (and any subjects not covered in their programs are
                                                    made up in the certificate), and have at least 2-years full time
                                                    practise in the industry.</p>
                                                <p>All provisionally covered members are eligible for registration for
                                                    up to 3-years before being required to enrol into further study at
                                                    the Graduate Diploma or Bachelors level in one of the aforementioned
                                                    programs.</p>
                                                <p>To maintain Professional Registration it is compulsory that all
                                                    members complete Annual CPD(Continuing Professional Development –
                                                    provided by the Association), annual Practicing Audits, and be
                                                    insured with the Sports Nutritionist policy that complies with
                                                    regionally specific insurance requirements . Every Member in this
                                                    registry meets these standards and is listed at their respective
                                                    level of Professional Registration.</p>
                                                <p>If you’re interested in joining the ranks of our current professional
                                                    members by becoming an Accredited Sports Nutritionist yourself,
                                                    please click the button below.</p>
                                                <p style={{"textAlign": "center"}}>
                                                    <a
                                                        href="/enrol"
                                                        className="button btn-normal"
                                                        target="_blank">
                                                        <span>Enrol Today</span>
                                                    </a>
                                                </p>
                                            </div>
                                        </>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!!emailModelUser && <>
                <Modal
                    title={<h3>Email Nutritionist</h3>}
                    onOk={() => {
                        createEmailModalForm.submit();
                    }}
                    onCancel={() => setEmailModelUser(false)}
                    //centered={true}
                    width={600}
                    footer={
                        <>
                            <Button type="secondary" size="small" shape="round"
                                    onClick={() => setEmailModelUser(false)}>Cancel</Button>
                            <Button type="primary" size="small" shape="round"
                                    onClick={() => createEmailModalForm.submit()}>Send</Button>
                        </>
                    }
                >
                    <div style={{maxHeight: "70vh", overflow: "auto"}}>
                        <div style={{fontSize: "20px"}}>Nutritionist: <span style={{fontWeight: "bold"}}>{emailModelUser.name}</span></div>
                        <div style={{paddingTop: 20}}>
                            <PublicSendEmailForm form={createEmailModalForm} user={emailModelUser} onFinish={emailSent}/>
                        </div>
                    </div>
                </Modal>
            </>}
        </div>
	);
}