import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';

export default function Resources(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);

	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-cloud-download"/> Resources</span>
        ]);
	}, []);

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => {return (!a.name)?-1:a.name.localeCompare(b.name)},
        	align:'left'
		},
		{
			title: 'Requires Membership',
			key: 'r_membership',
            dataIndex: 'r_membership',
            sorter: (a, b) => {return (!a.r_membership)?-1:a.r_membership.localeCompare(b.r_membership)},
			align:'left',
            width: '300px'
		},
        {
            title: 'Requires Open',
            key: 'r_open',
            dataIndex: 'r_open',
            sorter: (a, b) => {return (!a.r_open)?-1:a.r_open.localeCompare(b.r_open)},
            align:'left',
            width: '300px'
        },
        {
            title: 'Updated At',
            key: 'updated_at',
            dataIndex: 'updated_at',
            align:'left',
            render: (text, record) => (
                <Moment format="Do MMM YYYY h:mm a" unix>{record.updated_at}</Moment>
            ),
        },
		{
			title: 'Actions',
            key: 'actions',
            render: (text, record) => (
				<div>
					<span><NavLink to={"/resource/"+record.id}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
				</div>
			),
            width: '200px'
		},
	];



	return (
		<div style={{paddingTop:40}}>
            <div style={{float:"right",position:"relative",top:-40}}>
                <NavLink to="/resource/new" activeClassName="current" style={{marginLeft:"auto"}}>
                    <Button icon={<i className="fas fa-plus"/>}>&nbsp;Create Resource </Button>
                </NavLink>
            </div>

            <SearchTable
                columns={columns}
                url="/resources"
                scroll={{ x: "max-content" }}
            />
        </div>
	);
};