import React from "react";
import {Button, Tag } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { Textfit } from 'react-textfit';

export default function RegistryCard(props) {

    return (

		<div className={"nutritionist nutri-post ei1 odd first"+(props.nutritionist.practicing_unregistered?' unregistered':'')} style={{borderRadius:10, ...props.style}}>
			<div className="block" style={{display:"flex", flexDirection:"column"}}>

				{props.nutritionist.profileImage &&
					<div style={{display:"flex",justifyContent:"center",flex:1}}>
						<span
							className="ant-avatar ant-avatar-lg ant-avatar-circle ant-avatar-image ant-avatar-icon"
							style={{width:"100%",height:100, textAlign:"center"}}
						>
							<img 
								src={props.nutritionist.profileImage.endpoint}
								style={{maxHeight:"100%",objectFit:"contain",width:"100%"}}
							/>
						</span>
					</div>
				}
				<h3>
					<Textfit
						mode="single"
						max={24}
						style={{textAlign:"center"}}
					>
						{props.nutritionist.name}
					</Textfit>
				</h3>
				<div className="accreditation meta-data" style={{display:"flex"}}>
					<em className="fad fa-award" style={{marginLeft:-2, padding:"3px 3px 0 0"}} aria-hidden="true"></em>
					<div style={{minWidth:140,fontWeight:"bold"}}>Accreditation:</div>
					<span>{props.nutritionist.practicing_unregistered?'UNREGISTERED':props.nutritionist.accreditation_level}</span>
				</div>

				{!!props?.nutritionist?.specializations?.length && <>
					<div className="specializations meta-data" style={{display:"flex"}}>
						<em className="fa fa-certificate" style={{marginLeft:-2, padding:"3px 3px 0 0"}} aria-hidden="true"></em>
						<div style={{minWidth:140,fontWeight:"bold"}}>Specializations:</div>
						{props.nutritionist.specializations.map(s=>(
							<Tag style={{borderRadius:10, lineHeight:"24px"}}>{s}</Tag>
						))}
						
					</div>
				</>}
				<div style={{flex:1, display:"flex", flexDirection:"column"}}>
					{!!props.nutritionist.suburb &&
					<div className="city meta-data" style={{display:"flex"}}>
						<i className="fas fa-map-marker" style={{padding:"3px 3px 0 0"}}/>
						<div style={{minWidth:140,fontWeight:"bold"}}>Suburb:</div>
						<Textfit mode="single" max={18} style={{width:160}}>{props.nutritionist.suburb}</Textfit>
					</div>
					}
					{!!props.nutritionist.state &&
					<div className="state meta-data" style={{display:"flex"}}>
						<i className="fas fa-map-marker-alt"  style={{padding:"3px 3px 0 0"}}/>
						<div style={{minWidth:140,fontWeight:"bold"}}>State:</div>
						<Textfit mode="single" max={18} style={{width:160}}>{props.nutritionist.state}</Textfit>
					</div>
					}
					<div className="country meta-data" style={{display:"flex"}}>
						<em className="fa fa-globe" aria-hidden="true" style={{marginLeft:"-2px", padding:"3px 3px 0 0"}}></em>
						<div style={{minWidth:140,fontWeight:"bold"}}>Country:</div>
						<Textfit mode="single" max={18} style={{width:155}}>{props.nutritionist.country}</Textfit>
					</div>
					{!!props.nutritionist.allowEmail &&
					<div className="country meta-data" style={{marginTop:"auto"}}>
						<Button
							type={!!props?.emailAsButton?"primary":"secondary"}
							onClick={()=>(props.setEmailModelUser(props.nutritionist))}
							shape="round"
                            className="email-nutritionist-btn"
						>
							<i className="far fa-envelope"/>&nbsp;Email
						</Button>
					</div>
					}
				</div>


			</div>
		</div>
	);
}