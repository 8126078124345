import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {
    Form,Input,
} from 'antd';

export default function TermsAndConditions(props) {

    const [form] = Form.useForm();
    const history = useHistory();


    function getStartedClicked(data){
        history.push({
            pathname: '/signUp',
            state: data
        });
    }


    return (
        <div className="content_inner_padded" >
            <div className="container">

                <div className="ibn ibn-sb sec">
                    <div className="ebn ud-bn ud-pt obg uix-alter ebn-p9x sec">
                        <div className="cpnw cov xg">
                            <div className="main">
                                <div className="cpn al-g cl-g bg-g wd-g">
                                    <div className="title">
                                        <h1>Terms And Conditions Of Accreditation And Registered Membership
                                            <div className="breadcrumb">
                                                <span className="separator"> &gt; </span>
                                                <span className="current">Terms And Conditions Of Accreditation And Registered Membership</span>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg hs-bg is-basic" data-height="auto" style={{"backgroundImage":"url('https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/adversary-banner.jpg')"}}>
                            <div className="over tpb op01" style={{"opacity":"0.01"}}></div>
                        </div>
                    </div>
                </div>

                <div className="site-wrap ipg iopg ipg-terms sec">
                    <div className="main">
                        <div className="wrap wrap-rg no-aside wrap-page gb-wd-sd row">
                            <div className="icontent g12">
                                <article className="epc epg">
                                    <div className="ientry">
                                        <h3>1. Interpretation</h3>
                                        <p>In these Terms and Conditions, a reference to the following words has the
                                            meaning set out adjacent to them, unless the context in which the words are
                                            used requires otherwise:</p>
                                        <ul>
                                            <li className="first"><strong>Accreditation</strong> means the status
                                                conferred upon satisfaction of specific requirements outlined in the
                                                Membership Schedule and the subsequent approval of SNA;
                                            </li>
                                            <li><strong>Agreement </strong>means the Registration Form, Membership
                                                Schedule, these Terms and Conditions including any schedule, annexure or
                                                other attachment;
                                            </li>
                                            <li><strong>Confidential Information</strong> includes any information which
                                                either party accesses or which is communicated to one party (Receiving
                                                Party) by the other (Disclosing Party) in the course of your
                                                Accreditation and Registered Membership and which:
                                                <ul>
                                                    <li className="first">is identified as confidential; or</li>
                                                    <li>which the Receiving Party should reasonably expect to be
                                                        confidential.
                                                    </li>
                                                    <li>It includes, but is not limited to: any trade secrets or
                                                        information relating to SNA, other Registered Members or
                                                        clients; client lists or requirements; suppliers; terms of
                                                        trade; pricing lists; or pricing structures; marketing
                                                        information and plans; Intellectual Property; inventions;
                                                        business plans or dealings; technical data; employees or
                                                        officers; financial information and plans; designs; research
                                                        activities; software and the source code of any such software;
                                                        of the Contractor, Company or any Group Company.
                                                    </li>
                                                    <li>It does not include information which:</li>
                                                    <li>is generally available in the public domain;</li>
                                                    <li className="last">is required to be disclosed by law by the
                                                        Receiving Party.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li><strong>Facilities </strong>includes but is not limited to tools,
                                                equipment, machinery, weights or other amenities of SNA at the Location;
                                            </li>
                                            <li><strong>Intellectual Property</strong> means any and all intellectual
                                                and industrial property rights throughout the world, whether subsisting
                                                now or in the future and includes all copyright and analogous rights,
                                                all rights in relation to inventions (including patent rights),
                                                registered and unregistered trademarks, designs (whether or not
                                                registered or registrable), circuit layouts, trade names, trade secrets,
                                                business names, company names or internet domain names and copyright.
                                            </li>
                                            <li><strong>Location </strong>means any establishment(s) or venue where the
                                                Services are carried out;
                                            </li>
                                            <li><strong>Payment</strong> means the amount, method and frequency referred
                                                to in the Membership Schedule;
                                            </li>
                                            <li><strong>Membership Schedule </strong>means the Membership Schedule to
                                                which these Terms and Conditions are attached;
                                            </li>
                                            <li><strong>Registration Form</strong> means the Registration Form submitted
                                                for your application for Accreditation and Membership.
                                            </li>
                                            <li><strong>Registered Membership</strong> means the membership conferred
                                                upon approval of SNA, creating a <strong>Registered Member</strong>;
                                            </li>
                                            <li><strong>Services </strong>means the service offerings available to you
                                                as a Registered Member of SNA, as outlined in the Membership Schedule;
                                            </li>
                                            <li><strong>SNA</strong> means Sports Nutrition Australia and includes
                                                reference to terms as ‘we’, ‘us’ or ‘our’;
                                            </li>
                                            <li className="last"><strong>You</strong> means the Registrant or Registered
                                                Member.
                                            </li>
                                        </ul>
                                        <h3>2. Commencement</h3>
                                        <p>This Agreement commences on the date that You are accepted as a Registered
                                            Member by SNA.</p>
                                        <h3>3. Scope of this Agreement</h3>
                                        <p>This Agreement does not create any fiduciary, employment, agency or
                                            partnership relationship between You and SNA.</p>
                                        <h3>4. Membership Schedule and Approval Process</h3>
                                        <ul>
                                            <li className="first">We retain discretion as to whether the Registration
                                                Form is approved and whether you are granted an Accreditation and
                                                Registered Membership.
                                            </li>
                                            <li className="last">We will confirm to you via email whether we have or
                                                have not approved the Registration Form. If we are not satisfied that
                                                Accreditation and Registered Membership should be conferred, we will
                                                provide written notice to You. We are not obliged to provide You with
                                                reasons for our decision.
                                            </li>
                                        </ul>
                                        <h3>5. Eligibility</h3>
                                        <ul>
                                            <li className="first">By submitting your Registration Form You acknowledge
                                                and agree that You are eligible to practice as a sports nutritionist or
                                                provide Services in relation to same.
                                            </li>
                                            <li>You warrant that all information provided in the Registration Form, the
                                                Membership Schedule and via other forms of communication is, to the best
                                                of your knowledge, true, accurate and complete, and agree to maintain
                                                this standard of communication throughout the registration process and
                                                membership generally.
                                            </li>
                                            <li className="last">SNA may in its absolute discretion request additional
                                                information, supporting documentation and professional references (if
                                                required) in order to assess your eligibly for Accreditation and to
                                                become a Registered Member.
                                            </li>
                                        </ul>
                                        <h3>6. Conduct as a Registered Member of SNA</h3>
                                        <ul>
                                            <li className="first">You must provide the Services with due care and skill,
                                                diligence, efficiency, honesty, and with integrity. Unethical or
                                                improper behaviour is not acceptable and must be reported to us via
                                                email or telephone.
                                            </li>
                                            <li>You must, at all times, comply with SNA’s Position Statement on
                                                Practicing Standards, risk assessment and triage processes, and its Code
                                                of Ethics.
                                            </li>
                                            <li>You must not engage in any conduct that might mislead, deceive or
                                                confuse any person in relation to, or otherwise misrepresent, the
                                                nature, status, scope or effect of your Registered Membership or
                                                Accreditation with us.
                                            </li>
                                            <li>You must not delegate your Registered Membership or Accreditation, or
                                                the activities associated with Registered Membership or Accreditation,
                                                to any other person.
                                            </li>
                                            <li className="last">You must not copy, reproduce or duplicate the original
                                                SNA Accreditation Certificate issued by us.
                                            </li>
                                        </ul>
                                        <h3>7. Membership Fees and Payment</h3>
                                        <ul>
                                            <li className="first">Your first Payment will be payable on the date that
                                                approval of your Registration Form is granted, and the recurring
                                                Payments will be due on the same date of the following month or as
                                                otherwise set out in the Membership Schedule or agreed from time to
                                                time.
                                            </li>
                                            <li>It is your responsibility to ensure that on each Payment due date there
                                                are sufficient cleared funds in your nominated account to meet your
                                                scheduled Payment. Should your credit or debit card Payment decline on
                                                the agreed Payment date, we reserve the right to continue attempts to
                                                process your Payment. We will attempt to process the declined amount
                                                each day until successful. If You fail to clear the arrears prior to the
                                                following week / month’s debit, You will be charged twice on the agreed
                                                date to clear the arrears. If this Payment declines and You fall into
                                                arrears for more than one calendar month, we reserve the right to
                                                suspend this Agreement effective immediately and will deny You access to
                                                any online platforms or communication channels. Once You have cleared
                                                your arrears this Agreement may be reactivated. We reserve the right to
                                                charge an administration fee of $50 for each declined Payment.
                                            </li>
                                            <li>Should your credit or debit card expire or be cancelled, it is your
                                                responsibility to provide us with new card details to ensure Payments
                                                continue to be successfully debited.
                                            </li>
                                            <li>We may charge interest at the rate of 12.5% per annum on any amounts
                                                unpaid fourteen (14) calendar days after the Payment due date. We
                                                reserve the right to engage external debt collection Services for the
                                                collection of unpaid and undisputed debt, and the right to commence
                                                legal proceedings for any outstanding amounts owed to us. You will be
                                                liable for all costs involved, including legal fees where relevant.
                                            </li>
                                            <li className="last">Our pricing structure, Payment methods, Payment
                                                processes and these Terms and Conditions may be amended at any given
                                                time at our reasonable discretion. All other changes will apply from the
                                                date that the amended or new Terms and Conditions are posted on our
                                                website or are provided to You, whichever is earlier.
                                            </li>
                                        </ul>
                                        <h3>8. Duration and Renewal of Accreditation and Registered Membership</h3>
                                        <ul>
                                            <li className="first">Subject to compliance with these Terms and Conditions,
                                                Accreditation and Registered Membership continues until the expiry date
                                                referenced on the Accreditation Certificate or as set out in the
                                                Membership Schedule.
                                            </li>
                                            <li className="last">Your Accreditation and Registered Membership will be
                                                automatically renewed each calendar year, subject of course to your
                                                continued compliance with the terms of this Agreement. You shall inform
                                                us via email or telephone if You do not intend to renew your
                                                Accreditation or Registered Membership for the following calendar year.
                                            </li>
                                        </ul>
                                        <h3>9. Suspension or Termination of Accreditation and Registered Membership</h3>
                                        <ul>
                                            <li className="first">You may terminate your Accreditation and Registered
                                                Membership at any time by giving us at least thirty (30) days written
                                                notice. The notice must be sent to the email address nominated by us
                                                from time to time.
                                            </li>
                                            <li>We may suspend or terminate your Accreditation and Registered Membership
                                                if:
                                                <ul>
                                                    <li className="first">You breach any of the terms set out in this
                                                        Agreement;
                                                    </li>
                                                    <li>You require us to act unlawfully or unethically;</li>
                                                    <li>We reasonably believe that Registered Membership is no longer
                                                        appropriate; or
                                                    </li>
                                                    <li className="last">You fail to make Payment.</li>
                                                </ul>
                                            </li>
                                            <li>If your Accreditation and Registered Membership is terminated by either
                                                of us You agree, we are entitled to all Payments up for the full
                                                membership period.
                                            </li>
                                            <li>On termination of this Agreement You agree to promptly return (where
                                                possible) or delete or destroy (where not possible to return), our
                                                Confidential Information and Intellectual Property, and/or documents
                                                containing or relating to our Confidential Information and Intellectual
                                                Property.
                                            </li>
                                            <li>Any accrued rights or responsibility of us or You are not affected by
                                                the termination of this Agreement.
                                            </li>
                                            <li className="last">On termination of this Agreement, we will remove your
                                                access to all Registered Membership Services including but not limited
                                                to online platforms and communication channels, face to face workshops
                                                and events and we will not be liable for any claims related to the
                                                removal of your access to these, your Registered Membership or
                                                Accredited status.
                                            </li>
                                        </ul>
                                        <h3>10. Facilities</h3>
                                        <ul>
                                            <li className="last first">Any use of our property, Facilities or Locations
                                                are at your own risk and you indemnify us against any and all claims,
                                                damages, costs or liabilities that may arise as a consequence of your
                                                use of the property, Facilities or Location.
                                            </li>
                                        </ul>
                                        <h3>11. Privacy</h3>
                                        <ul>
                                            <li className="first">It is a requirement of the <em>Privacy Act
                                                1988</em> that You are informed about the collection of your personal
                                                information and how we may use it. The personal information You provide
                                                during the term of this Agreement is being collected for the purpose of
                                                processing your Registration and Accreditation. You have a right to
                                                access and make alterations to your personal information.
                                            </li>
                                            <li className="last">We agree not to disclose your personal information to
                                                any third party. We will use your information for the reason it was
                                                disclosed, including the purpose of providing the Course to you, and not
                                                for any other purpose without your prior consent.
                                            </li>
                                        </ul>
                                        <h3>12. Confidential Information</h3>
                                        <ul>
                                            <li className="last first">You agree not to disclose our Confidential
                                                Information to any third party; to use all reasonable endeavours to
                                                protect our Confidential Information from any unauthorised disclosure;
                                                and only to use the Confidential Information for the purpose for which
                                                it was disclosed or provided by us to You, and not for any other
                                                purpose.
                                            </li>
                                        </ul>
                                        <h3>13. Intellectual Property</h3>
                                        <ul>
                                            <li className="first">The work and materials that we provide to you in
                                                carrying out the Service contains material which is owned by or licensed
                                                to us, or owned by third parties, and is protected by Australian and
                                                international laws (<strong>Materials</strong>). We own the copyright in
                                                all creative, literary and original works incorporated in our Materials
                                                that we have created, including any sports nutrition programs, meal
                                                plans, exercise regimes, supplement programs, business plans, social
                                                media strategies and any other documents or concepts provided in either
                                                spoken or written form within the scope of your Accreditation and
                                                Registered Membership with SNA, including those disseminated during
                                                professional development seminars and the MHS Client Management
                                                Platform.
                                            </li>
                                            <li>You agree that we own all intellectual property rights in our Materials,
                                                and that nothing in these General Terms constitutes a transfer of any
                                                intellectual property ownership rights in our Materials, except as
                                                stated in these General Terms or with our written permission. Your use
                                                of our Materials does not grant you a license, or act as a right of use,
                                                any of the intellectual property in the Materials, whether registered or
                                                unregistered, except as stated in these General Terms or with our
                                                written permission.
                                            </li>
                                            <li className="last">If You are provided with any Materials during your
                                                Accreditation or Registered Membership, then the copyright in these
                                                Materials shall remain vested with us and shall only be used at our
                                                discretion. You must not:
                                                <ul>
                                                    <li className="first">alter or modify any of the Materials;</li>
                                                    <li>create derivative works from the Materials; or</li>
                                                    <li className="last">use our Materials for commercial purposes such
                                                        as on sale to third parties
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p>without our prior express written permission.</p>
                                        <ul>
                                            <li className="first">You acknowledge and agree that you must not use our
                                                Material to create a business that is similar to ours. If you do so, we
                                                reserve the right to immediately terminate your License.
                                            </li>
                                            <li>We reserve our right to pursue any loss or damage suffered as a result
                                                of copyright or Intellectual Property infringement by You or any third
                                                party.
                                            </li>
                                            <li className="last">This clause survives termination of this Agreement.
                                            </li>
                                        </ul>
                                        <h3>14. Insurance</h3>
                                        <ul>
                                            <li className="first">You shall obtain and maintain valid and enforceable
                                                public liability, professional indemnity insurance policies to cover any
                                                potential liabilities of:
                                                <ul>
                                                    <li className="first">You, in the scope of your professional duties
                                                        as a Sports Nutritionist; and
                                                    </li>
                                                    <li className="last">SNA, arising out of any breach of this
                                                        Agreement, or any act or omission committed by You, as if SNA
                                                        were a jointly insured party under each of those policies.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Upon request, provide us with certificates of currency and any other
                                                evidence of those insurance policies that we may reasonably require.
                                            </li>
                                            <li className="last">If required by SNA, you must obtain and maintain your
                                                insurances through an insurer nominated by SNA.
                                            </li>
                                        </ul>
                                        <h3>15. Indemnity</h3>
                                        <ul>
                                            <li className="first">You are liable for and agree to indemnify, defend and
                                                hold us harmless for and against any and all claims, liabilities, suits,
                                                actions and expenses, including costs of litigation and legal costs,
                                                resulting directly or indirectly from:
                                                <ul>
                                                    <li className="first">any information that is not accurate, up to
                                                        date or complete or is misleading or a misrepresentation;
                                                    </li>
                                                    <li>us providing You with Accreditation and Registered Membership;
                                                    </li>
                                                    <li>any breach of this Agreement; and</li>
                                                    <li className="last">you delivering Services to the public.</li>
                                                </ul>
                                            </li>
                                            <li>You agree to cooperate with us (at your own expense) in the handling of
                                                disputes, complaints, investigations or litigation that arise as a
                                                result of you’re your Services including but not limited to disputes,
                                                complaints, investigations or litigation that arises out of or relates
                                                to incorrect information You have given us.
                                            </li>
                                            <li className="last">The obligations under this clause will survive
                                                termination of this Agreement.
                                            </li>
                                        </ul>
                                        <h3>16. Limitation of Liability</h3>
                                        <ul>
                                            <li className="first">To the fullest extent permitted by law, we shall not
                                                be liable to You for any costs, expenses, loss of or damage to goodwill,
                                                loss of sales or business, loss or damage (whether direct, indirect or
                                                consequential, and whether economic or other) arising from your
                                                Accreditation and Registered Membership, or any termination of the
                                                rights granted to You under, or otherwise in connection with, this
                                                Agreement.
                                            </li>
                                            <li className="last">Our liability to You whether in contract, tort
                                                (including negligence), breach of statutory duty, or otherwise, arising
                                                in connection with this Agreement, shall at all times be limited to the
                                                amount of Payments made by you under this Agreement.
                                            </li>
                                        </ul>
                                        <h3>17. General Matters</h3>
                                        <ul>
                                            <li className="first">Any amendments to this Agreement must be made in
                                                writing or if agreed verbally between us, must be confirmed in writing
                                                within a reasonable time after such verbal agreement.
                                            </li>
                                            <li>You agree that we may use email to communicate with You and may use the
                                                email address that You provide to us for that purpose.
                                            </li>
                                            <li>You agree to release us from any liability for any loss which You might
                                                incur if an email is intercepted or corrupted during transmission or if
                                                a document which we prepare for You is altered by You or any other party
                                                without our written consent.
                                            </li>
                                            <li>This Agreement is governed by the laws of your local region and each of
                                                us agree to submit to the non-exclusive jurisdiction of the courts of
                                                your local region.
                                            </li>
                                            <li className="last">This Agreement may be executed, in original form, by
                                                facsimile transmission or by electronic signature, and in any number of
                                                counterparts each of which will be considered to constitute the same
                                                instrument.
                                            </li>
                                        </ul>
                                        <p>Any provision of this Agreement that is illegal, void or unenforceable will
                                            be severed without prejudice to the balance of the provisions.</p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}