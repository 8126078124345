import React, { useState, useEffect  } from "react";
import useToken from "../hooks/useToken";



export default function API(method, url, initData) {

	//function get(initialUrl, initialData){
		const [resp, setResp]           = useState(initData);
		const [isLoading, setIsLoading] = useState(false);
		const [isError, setIsError]     = useState(false);
		const [data, setData]           = useState(false);

		const {token } = useToken();


		function run(data){
			if(!data) data = [];
            setData(data);
		}

    function replacer(key, value) {
        // Filtering out properties
        if (typeof(value) === 'undefined') {
            value = null;
        }
        return value;
    }

		useEffect(() => {
			const fetchData = async () => {
				if(method != "GET" && !data){
					return false;
				}

				setIsError(false);
				setIsLoading(true);

				let headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
				};
				if(token){
					headers['Authorization'] = 'Bearer '+token

				}
                let options = {
                    method: method,
                    //    mode: 'no-cors',
                    headers: headers
                };
                if(data){
                    options.body = JSON.stringify(data,replacer);
                }


                try {
					fetch(process.env.REACT_APP_API_ENDPOINT+url, options)
                        .then(function(response){
                            if(response.status >= 300){
                                setIsError(true);
                            }
                            return response.json();

                        })
						.then(function(resp){
                            setResp(resp);
                            return resp;

						});
				} catch (error) {
					setIsError(true);
				}
                setData(false);
				setIsLoading(false);
			};

			fetchData();
		}, [data]);

		return [resp, isLoading, isError, run];
	//}



	//return {
	//	"Get": get
	//}

};