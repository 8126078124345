import React, {useContext, useEffect, useState} from 'react';
import { UserOutlined } from '@ant-design/icons';
import API2 from "../components/api2";
import {
    Button,
    Card
} from 'antd';


import { QuestionCircleOutlined } from '@ant-design/icons';
import BreadCrumbContext from "../contexts/breadCrump";


export default function DataExport() {

    const AP = API2();
	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([
            <span><i className="fas fa-file-export"></i> Data Export </span>
		]);
	}, []);

	function getData(type){
        AP.file('/export/'+type);
	}


	return(
		<div className="content_inner">

            <Card style={{ width: 600 }}>
				<div style={{display:"flex"}}>
					<p style={{flex:"1"}}>Client Risk Assessment</p>
					<p><Button type="primary" onClick={() => getData('clientRiskAssessment')}><i className="fas fa-file-download"/>&nbsp;Download</Button></p>
				</div>
            </Card>
		</div>
	)
}

