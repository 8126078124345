import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import countryList from "react-select-country-list";

export default function Staff(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-users"/> Users</span>
        ]);
	}, []);

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'first_name',
            sorter: true,
        	align:'left',
			searchable: true,
            render: (text, record) => (
                <div style={{display:"flex"}}>
                    <div><Avatar src={(record.image)?record.image.endpoint:null} icon={<i className="far fa-user"></i>} size="large"/></div>
                    <div style={{paddingLeft:"20px"}}>
                        <div>{record.name}</div>
                        <div>{record.email}</div>
                    </div>
                </div>
            )
		},
        {
            title: 'Mobile',
            key: 'mobile',
            dataIndex: 'mobile',
            width: "150px",
            sorter: true,
			searchable: true,
        },
		{
			title: 'Actions',
            key: 'actions',
            width: "200px",
            render: (text, record) => (
				<div>
					<span><Link to={"/user/"+record.id}><Button type="primary" shape="round"><i className="far fa-edit"/></Button></Link></span>
				</div>
			),
		},
	];

	function onSelect(selectedRowKeys: React.Key[], selectedRows: DataType[]){
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }

    const onSelectOptions = [
        {
            name: "Delete",
            value: "delete",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["delete",selectedKeys]);
            }
        },
        {
            name: "Set Active",
            value: "setActive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setActive",selectedKeys]);
            }
        },
        {
            name: "Set Inactive",
            value: "setInactive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setInactive",selectedKeys]);
            }
        }
    ];

	return (

        <div className="content_inner" style={{maxWidth:1300,paddingTop:40}}>
            <div style={{float:"right",position:"relative",top:-40}}>
                <Link to="/user/new" activeClassName="current" style={{marginLeft:"auto"}}>
                    <Button icon={<i className="far fa-user"></i>}>&nbsp;Create User </Button>
                </Link>
            </div>

            <SearchTable columns={columns} url="/staff" onSelect={onSelectOptions}/>
        </div>
	);
};