import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,Checkbox,
    Select,
    Button,
    DatePicker,
    Table,
    Tabs
} from 'antd';

import { UserOutlined,ExclamationCircleOutlined  } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {Modal, notification} from "antd/lib/index";
import moment from 'moment'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
const { TabPane } = Tabs;


const { confirm } = Modal;
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

//import { FormInstance } from 'antd/lib/form';
//const EditableContext = React.createContext<FormInstance<any> | null>(null);



export default function PlanForm(props) {

    const [form] = Form.useForm();
	const [id, setId] = useState(props.plan.id);
	const [user, setUser] = useState(props.user);
    const [isLoading, setIsLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [regionalPayments, setRegionalPayments] = useState({});
    const [files, setFiles] = useState([]);
    const [userType, setUserType] = useState([]);
    const [payMode, setPayMode] = useState([]);
    const [forr, setFor] = useState("");
    const [saveMode, setSaveMode] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [isRegional, setIsRegional] = useState(false);
    const [disabledRegions, setDisabledRegions] = useState({});
    const [subscriptions, setSubscriptions] = useState({});
    const [productsByPriceId, setProductsByPriceId] = useState({});
    const history = useHistory();



    const AP = API2();
    useEffect(() => {

        if(props.plan) {
            let couponIds = [];
            for (let pcm of props.plan.plan_coupon_maps) {
                couponIds.push(pcm.coupon_id);
            }
            props.plan.coupons = couponIds;
        }

        let data = props.plan;
        if(!data) data = {};

        if(data.payments) {
            for (let payment of data.payments) {
                payment.amount /= 100;
                payment.email_days_notify = [];
                if(payment.notifications && payment.notifications.length){
                    for (let notification of payment.notifications) {
                        payment.email_days_notify.push(notification.days);
                    }
                }
                payment.email_days_notify = payment.email_days_notify.join(',');
            }
        }
        if(!data.private) data.private = false;
        if(!data.auto_renew) data.auto_renew = false;

        if(props.plan) {
            const regionalPayments = {};
            const payments = [];
            let i = {};
            props.plan.regionalPayments = {};
            if(props.plan.payments) {
                for (const p of props.plan.payments) {
                    if(p.region){
                        if (!regionalPayments[p.region]) regionalPayments[p.region] = [];
                        regionalPayments[p.region].push(p);

                        if (!i[p.region]) i[p.region] = 0;
                        props.plan.regionalPayments[p.region + "." + i[p.region]] = p;
                        i[p.region]++;

                        props.plan[p.region + "_currency"] = p.currency;
                    }
                    else{
                        props.plan["currency"] = p.currency;
                        payments.push(p);                        
                    }
                }

                data.payments = payments;
                setRegionalPayments(regionalPayments);
                setPayments(payments);
            }

            /*
            if (data.regional) {
                let i = {};
                props.plan.regionalPayments = {};
                const regionalPayments = {};
                for (const r of props.plan.payments) {
                    if (!r.region) continue;
                    if (!regionalPayments[r.region]) regionalPayments[r.region] = [];
                    regionalPayments[r.region].push(r);

                    if (!i[r.region]) i[r.region] = 0;
                    props.plan.regionalPayments[r.region + "." + i[r.region]] = r;
                    i[r.region]++;

                    props.plan[r.region + "_currency"] = r.currency;
                }
                setRegionalPayments(regionalPayments);
            } else {
                props.plan.regionalPayments = {};
                if(props.plan.payments) {
                    for (const r of props.plan.payments) {
                        props.plan["currency"] = r.currency;
                    }
                }
                setPayments(props.plan.payments);
            }
            */
        }

        if(!data.pay_mode) {
            data.pay_mode = 'payments';
        }
        //check payments
        if(data.pay_mode === 'subscriptions'){
            for(const p of data.payments){
                data[p.region+'_stripe_price_id']  = p.stripe_price_id;
                data[p.region+'_billing_length']   = p.billing_length;
                data[p.region+'_billing_duration'] = p.billing_duration;
            }
        }

        if(data.for){
            setFor(data.for);
        }

        if(data.disabled_regions === undefined){
            setDisabledRegions({
                'asia':true,
                'australia':true,
                'europe':true,
                'newzealand':true,
                'unitedstates':true,
                'canada':true,
            });
        }else {
            setDisabledRegions(data.disabled_regions);
        }

        if(!data.tags) data.tags = [];

        form.setFieldsValue(data);

        setPayMode(data.pay_mode);
        setIsPrivate(props.plan.private?true:false);
        setIsRegional(props.plan.regional);

    }, [props.plan]);

    useEffect(() => {
        if(!props.products) return true;

        const productsByPriceId = {};

        for(const region in props.products){
            for(const ffor in props.products[region]) {
                for(const product of props.products[region][ffor]) {
                    for(const price of product.prices) {
                        productsByPriceId[price.id] = price;
                    }
                }
            }
        }
        setProductsByPriceId({...productsByPriceId});

    }, [props.products]);




    const SortableItem = SortableElement(props => <tr {...props} />);
    const SortableBody = SortableContainer(props => <tbody {...props} />);
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(Object.values(props.plan.payments), oldIndex, newIndex).filter(
                el => !!el,
            );

            let i = 0;
            for(let d of newData){
                d.order = i;
                i++;
            }

            props.plan.payments = newData;
            form.setFieldsValue({...props.plan});

            console.log(["a",props.plan.payments]);
        }
    };



    let DraggableContainer = props => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}

            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        if(!id) return <tr {...restProps} />
        const index = props.plan.payments.findIndex(x => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} style={style} />;
    };

    const EditableCell = ({
           title,
           editable,
           children,
           dataIndex,
           record,
           handleSave,
           ...restProps
       }) => {
        const [editing, setEditing] = useState(false);

        useEffect(() => {
            /*
            if (editing) {
                inputRef.current!.focus();
            }
            */
        }, [editing]);

        if(!record) return <td {...restProps}>{children}</td>;

        let i;

        if(record.region){
            for(i in regionalPayments[record.region]){
                if(regionalPayments[record.region][i].id == record.id) break;
            }
            i = record.region+'.'+i;
        }else{
            for(i in payments){
                if(payments[i].id == record.id) break;
            }
        }

        let input;
        if(dataIndex == "billing_duration"){
            input = <Select>
                <Option value="day">Day</Option>
                <Option value="month">Month</Option>
                <Option value="week">Week</Option>
                <Option value="year">Year</Option>
            </Select>
        }else if(dataIndex == "billing_start"){
            input = <Select>
                <Option value="after_last">{props.plan.payments[0] === record?"Immediately":"After Last"}</Option>
                <Option value="week_before_plan_start">Week Before Plan Start</Option>
                <Option value="plan_start">Plan Start</Option>
                <Option value="date">Date</Option>
                <Option value="deposit">Deposit</Option>
            </Select>
        }else if(dataIndex == "notice_email"){
            input = <Checkbox/>
        }else{
            input = <Input/>
        }

        return <td {...restProps}>
            <Form.Item
                style={{ margin: 0 }}
                //name={dataIndex}
                name={[record.region?"regionalPayments":"payments",i, dataIndex]}
            >
                {input}
            </Form.Item>
        </td>;
    };



    let paymentColumns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            editable: true
        },
        {
            title: 'Billing Length',
            dataIndex: 'billing_length',
            key: 'billing_length',
            editable: true,
        },
        {
            title: 'Billing Duration',
            dataIndex: 'billing_duration',
            key: 'billing_duration',
            editable: true,
        },
        {
            title: 'Billing Start',
            dataIndex: 'billing_start',
            key: 'billing_start',
            editable: true,
        },
        {
            title: 'Billing Rounds',
            dataIndex: 'billing_rounds',
            key: 'billing_rounds',
            editable: true,
        },
        {
            title: 'Notify Email',
            dataIndex: 'notice_email',
            key: 'notice_email',
            editable: false,
            render: (text, record) => {
                if (!record.email_days_notify) return '';
                return <span><i className="fas fa-check"/></span>
            }

        },
        {
            title: 'Actions',
            render: function(record){

                let i;
                if(record.region){
                    for(i in regionalPayments[record.region]){
                        if(regionalPayments[record.region][i].id == record.id) break;
                    }
                    i = record.region+'.'+i;
                }else {
                    for (i in payments) {
                        if (payments[i].id == record.id) break;
                    }
                }

                return <div>
                    <Button htmlType="button" shape="round" type="primary" size="small" danger style={{marginLeft:"auto"}} onClick={() => deleteRow(record)}>
                        <i className="fas fa-trash"/>
                    </Button>
                    <Form.Item
                        style={{ display: "none" }}
                        name={[record.region?"regionalPayments":"payments",i, "id"]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        style={{ display: "none" }}
                        name={[record.region?"regionalPayments":"payments",i, "order"]}
                    >
                        <Input/>
                    </Form.Item>
                </div>
            }
        }
    ];
    paymentColumns = paymentColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title
            }),
        };
    });

    let defaultTab = "region_asia";

    function addRow(){

        let f = form.getFieldsValue();
        let pms = [];

        if(f.payments) {
            pms = [].concat(Object.values(f.payments));
        }

        pms.push({
            id:      Math.random()*-1,
            plan_id: null,
            order:   pms.length
        });
        f.payments = pms;

        form.setFieldsValue(f);
        setPayments(pms);
    }
    function addRegionRow(region){

        let f = form.getFieldsValue();

        let pms = [];
        if(regionalPayments[region]) {
            //pms = [].concat(Object.values(regionalPayments[region]));
            pms = f.regionalPayments;
        }else{
            regionalPayments[region] = [];
        }

        const order = regionalPayments[region].length;

        const newRow = {
            id:      Math.random()*-1,
            plan_id: null,
            order:   order,
            region:  region
        };

        pms[region+'.'+order] = newRow;

        regionalPayments[region].push(newRow);
        regionalPayments[region] = [...regionalPayments[region]];

        f.regionalPayments = {...pms};

        form.setFieldsValue(f);
        setRegionalPayments({...regionalPayments});

    }
    function deleteRow(record){

        let f = form.getFieldsValue();
        let pms;
        if(record.region){
            pms = [].concat(Object.values(f.regionalPayments));
        }else{
            pms = [].concat(Object.values(f.payments));
        }

        for(let i in pms){
            if(pms[i].id == record.id){
                delete pms[i];
            }
        }

        if(record.region) {
            regionalPayments[record.region] = pms;
            f.regionalPayments = pms;
            setRegionalPayments({...regionalPayments});
        }else{
            f.payments = pms;
            setPayments(pms);
        }

        form.setFieldsValue(f);

    }

    function doSave(){
        setSaveMode('save');
        form.submit();
    }
    function doAlter(){
        setSaveMode('alter');
        form.submit();
    }
    function doClone(){
        setSaveMode('clone');
        form.submit();
    }
    function doDelete(){

        if(!id) return false;

        confirm({
            title: 'Are you sure you want to delete the plan',
            icon: <ExclamationCircleOutlined />,
            onOk() {

                setIsSaving(true);

                let url = '/plan/'+id;

                AP.delete(url).then(function(res){

                    notification.success({
                        message: `Deleted`,
                        placement: 'bottomRight',
                        duration:1,
                        closeIcon : (<div></div>)
                    });

                    //props.history.replace({ pathname: '/plans'});
                    window.location.replace("/plans");

                }).catch(function(res){
                    setIsSaving(false);

                    if(res.response.data.errors) {
                        let errorList = {};
                        for (let error of res.response.data.errors) {
                            if (!errorList[error.input]) errorList[error.input] = [];
                            errorList[error.input].push(error.message);
                        }
                        for (let [input, errors] of Object.entries(errorList)) {
                            props.form.setFields([
                                {
                                    name: input,
                                    errors: errors,
                                },
                            ]);
                        }
                    }
                });

            },
            onCancel() {
                return;
            },
        });
    }

    function saveForm(data){

        setIsSaving(true);

        if(!saveMode) return false;


        data.saveMode = saveMode;
        if(data.dishonor_fee){
            data.dishonor_fee *= 100;
        }

        data.disabled_regions = disabledRegions;

        let url = '/plan';
        if(props.plan.id) {
            url += '/'+props.plan.id;
        }

        if(data.starts_at){
            data.starts_at = data.starts_at.unix(); //+36000
        }

        if(payMode === 'payments'){
            if(data.regional) {
                data.payments = [];
                if(data.regionalPayments) {
					for (let [k, v] of Object.entries(data.regionalPayments)) {
						v.region = k.split(".")[0];
						v.currency = data[v.region + "_currency"];
						data.payments.push(v);
					}
					delete data.regionalPayments;
				}

                //add missing payments
                for (let [k, v] of Object.entries(regionalPayments)) {
                    for (let v2 of v) {
                        let found = false;
                        for (let [k3, v3] of Object.entries(data.payments)) {
                            if (v2.id == v3.id) {
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            data.payments.push(v2);
                        }
                    }
                }
            }
        }
        else{
            data.payments = [];
            Object.entries({
                'asia':'Asia',
                'australia':'Australia',
                'europe':'Europe',
                'newzealand':'New Zealand',
                'unitedstates':'United States',
                'canada':'Canada',
            }).map(function([key, index]) {
                data.payments.push({
                    region:key,
                    stripe_price_id: data[key+'_stripe_price_id'],
                    billing_duration: data[key+'_billing_duration'],
                    billing_length: data[key+'_billing_length'],
                });
            });
        }

        if(data.payments) {
            let alteredPayments = [];
            for (let payment of Object.values(data.payments)) {
                payment = {...payment};
                payment.amount *= 100;
                if(!data.regional){
                    payment.currency = data.currency;
                }
                alteredPayments.push(payment);
            }
            data.payments = alteredPayments;
        }

        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            if(!props.plan.id || props.plan.id != res.id) {
				props.plan.id = res.id;
                //setId(res.id);
                history.push("/plan/"+res.id);
            }
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });

        setSaveMode(false);
    }

    function regionalChange(e){
        setIsRegional(e.target.checked);
    }

    function privateChange(e){
        setIsPrivate(e.target.checked);
    }

    function setDisabled(region,disabled){
        disabledRegions[region] = disabled;
        setDisabledRegions({...disabledRegions});
    }
    function setSubscription(region,subscription){
        subscriptions[region] = subscription;
        setSubscriptions({...subscriptions});
    }


    const studentOptions = [];

    const memberOptions = [
        {value:'annual',label:'annual'},
        {value:'month',label:'month'},
        {value:'members',label:'members'},
    ];

    

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="plan"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'Please select a type',
                    }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="for"
                label="For"
                rules={[
                    {
                        required: true,
                        message: 'Please select',
                    }
                ]}
            >
                <Select
                    placeholder="Select a option and change input text above"
                    onChange={(e)=>(setFor(e))}
                >
                    <Option value="member">Member</Option>
                    <Option value="student">Student</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="starts_at"
                label="Starts At"
                help="DD/MM/YYYY"
            >
                <DatePicker 
                    //format="DD/MM/YYYY" 
                    showTime={true}
                />
            </Form.Item>

			<Form.Item
				name="private"
				label="Is Private"
				valuePropName="checked"
				style={{marginBottom:0}}
			>
				<Checkbox onChange={privateChange}/>
			</Form.Item>

			<Form.Item
				name="is_disabled"
				label="Is Disabled"
				valuePropName="checked"
				style={{marginBottom:0}}
			>
				<Checkbox onChange={privateChange}/>
			</Form.Item>

            <div style={{borderTop:"1px solid lightgray", margin:"30px 0"}}/>

            <Form.Item
                name="coupons"
                label="Valid Coupons"
                style={{paddingTop:20}}
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                >
                    {props.plan && props.plan.coupon_options.map(function(coupon){
                        return <Option value={coupon.id}>{coupon.name}</Option>
                    })}
                </Select>
            </Form.Item>

            <div style={{borderTop:"1px solid lightgray", margin:"30px 0"}}/>


            <Form.Item
                name="pay_mode"
                label="Payment Mode"
            >
                <Select
                    onChange={(e) => (setPayMode(e))}
                >
                    <Option value="payments">Payments \ Invoices</Option>
                    <Option value="subscriptions">Subscriptions</Option>
                </Select>
            </Form.Item>

            {payMode == 'payments' && <>

                <Form.Item
                    name="auto_renew"
                    label="Auto Renew"
                    valuePropName="checked"
                    style={{marginBottom:0}}
                >
                    <Checkbox/>
                </Form.Item>

				<Form.Item
					name="renew_reminder_days"
					label="Renew Reminder (days before due)"
					style={{marginBottom:0}}
				>
					<Input />
				</Form.Item>

                < Form.Item
                    name="dishonor_fee"
                    label="Dishonor Fee"
                    style={{paddingTop:20}}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="dishonor_fee_delay"
                    label="Dishonor Fee Delay"
                >
                    <Input />
                </Form.Item>
            </>}








            <Form.Item
                name="regional"
                label="Is Regional"
                valuePropName="checked"
            >
                <Checkbox onChange={regionalChange}/>
            </Form.Item>

            {!!isRegional && <>

                <Tabs defaultActiveKey={defaultTab} centered>
                    {Object.entries({
                        'asia':'Asia',
                        'australia':'Australia',
                        'europe':'Europe',
                        'newzealand':'New Zealand',
                        'unitedstates':'United States',
                        'canada':'Canada',
                        }).map(function([key, index]) {
                        return <TabPane tab={index} key={"region_"+key} style={{backgroundColor:"#eaeef4"}}>

                            <Form.Item
                                wrapperCol = {{
                                    xs: { span: 24 },
                                    sm: { span: 24 },
                                }}
                                style={{margin:0}}
                            >
                                <div style={{width:110, right:0, position:"relative", marginLeft:"auto"}}>
                                    Disabled
                                    <Checkbox
                                        onChange={(value)=>(setDisabled(key,value.target.checked))}
                                        checked={disabledRegions[key]}
                                    />
                                </div>
                            </Form.Item>

                            {!disabledRegions[key] && <>
                                {payMode == 'payments' && <>
                                    <Form.Item
                                        name={key + "_currency"}
                                        label="Currency"
                                    >
                                        <Select
                                            style={{width: '100%'}}
                                            placeholder="Please select"
                                        >
                                            {['aud', 'usd', 'nzd', 'eur', 'cad'].map(currency => (
                                                <Option value={currency}>{currency}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <div style={{display: "flex", paddingTop: 50, paddingBottom: 10}}>
                                        <Button htmlType="button" shape="round" type="primary" className="btn-teal"
                                                style={{marginLeft: "auto"}} onClick={() => addRegionRow(key)}>
                                            <i className="fas fa-plus"/>
                                        </Button>
                                    </div>
                                    <Form.Item
                                        label="Payments"
                                        wrapperCol={{
                                            xs: {span: 24},
                                            sm: {span: 18},
                                        }}
                                    >
                                        <Table
                                            rowKey="id"
                                            pagination={false}
                                            components={{
                                                body: {
                                                    //row: EditableRow,
                                                    cell: EditableCell,
                                                    wrapper: DraggableContainer,
                                                    row: DraggableBodyRow,
                                                },
                                            }}
                                            rowClassName={() => 'editable-row'}
                                            bordered
                                            dataSource={regionalPayments[key]}
                                            columns={paymentColumns}

                                            expandable={{
                                                expandedRowRender: function (record) {
                                                    let i;
                                                    if (record.region) {
                                                        for (i in regionalPayments[record.region]) {
                                                            if (regionalPayments[record.region][i].id == record.id) break;
                                                        }
                                                        i = record.region+'.'+i;
                                                    } else {
                                                        for (i in payments) {
                                                            if (payments[i].id == record.id) break;
                                                        }
                                                    }

                                                    return <div style={{padding: 10}}>
                                                        <div style={{width: 300}}>
                                                            <Form.Item
                                                                label="Days Notification"
                                                                style={{margin: 0}}
                                                                name={[record.region ? "regionalPayments" : "payments", i, "email_days_notify"]}
                                                            >
                                                                <Input/>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                },
                                            }}
                                        />
                                    </Form.Item>
                                </>}
                                {payMode != 'payments' && <>
                                    {!forr &&
                                    <div>Select For First</div>
                                    }
                                    {!props.products &&
                                    <div>Products missing</div>
                                    }
                                    {forr && props.products && <>
                                        <Form.Item
                                            name={key + "_stripe_price_id"}
                                            label="Subscription"
                                            style={{paddingTop: 20}}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                placeholder="Please select"
                                                onChange={(value)=>(setSubscription(key,value))}
                                            >
                                                {props.products[key][forr].map(product => (
                                                    product.prices.map(price => {
                                                        return <Option value={price.id}>
                                                            <div style={{display:"flex"}}>
                                                                <div style={{flex:1}}>{product.name}</div>
                                                                <div>- {price.recurring?'Subscription':'Single'} -</div>
                                                                <div style={{ width:70, textAlign:"right" }}>${price.unit_amount / 100} {price.currency}</div>
                                                            </div>
                                                        </Option>
                                                    })
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        {subscriptions[key] && productsByPriceId[subscriptions[key]] && productsByPriceId[subscriptions[key]].recurring &&
                                        <div className="ant-row ant-form-item ant-form-item-has-success"
                                             style={{rowGap: 0}}>
                                            <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-6">
                                                <label htmlFor="plan_australia_billing_length"
                                                       title="Duration">Duration</label>
                                            </div>
                                            <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
                                                <div style={{display: "flex", flexWrap: "wrap", gap: 20}}>
                                                    <Form.Item
                                                        name={key + "_billing_length"}
                                                        help="Leave blank to have no end"
                                                        wrapperCol={{
                                                            xs: {span: 24},
                                                            sm: {span: 24},
                                                        }}
                                                        style={{
                                                            width: 140
                                                        }}
                                                    >
                                                        <Input/>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </>
                                    }
                                </>}
                            </>}
                        </TabPane>
                    })}

                </Tabs>

            </>
            }
            {!isRegional && <>

                {payMode == 'payments' && <>
                    <Form.Item
                        name="currency"
                        label="Currency"
                        style={{paddingTop:20}}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Please select"
                        >
                            {['AUD','USD','NZD'].map(currency => (
                                <Option value={currency}>{currency}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <div style={{display:"flex",paddingTop:50,paddingBottom:10}}>
                        <Button htmlType="button" shape="round" type="primary" className="btn-teal" style={{marginLeft:"auto"}} onClick={() => addRow()}><i className="fas fa-plus"/></Button>
                    </div>

                    <Form.Item
                        label="Payments"
                        wrapperCol = {{
                            xs: { span: 24 },
                            sm: { span: 18 },
                        }}
                    >
                        <Table
                            rowKey="id"
                            pagination={false}
                            components={{
                                body: {
                                    //row: EditableRow,
                                    cell: EditableCell,
                                    wrapper: DraggableContainer,
                                    row: DraggableBodyRow,
                                },
                            }}
                            rowClassName={() => 'editable-row'}
                            bordered
                            dataSource={payments}
                            columns={paymentColumns}
                            expandable={{
                                expandedRowRender: function (record) {
                                    let i;
                                    if (record.region) {
                                        for (i in regionalPayments[record.region]) {
                                            if (regionalPayments[record.region][i].id == record.id) break;
                                        }
                                        i = record.region+'.'+i;
                                    } else {
                                        for (i in payments) {
                                            if (payments[i].id == record.id) break;
                                        }
                                    }

                                    return <div style={{padding: 10}}>
                                        <div style={{width: 300}}>
                                            <Form.Item
                                                label="Days Notification"
                                                style={{margin: 0}}
                                                name={[record.region ? "regionalPayments" : "payments", i, "email_days_notify"]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                },
                            }}
                        />
                    </Form.Item>
                </>}
                {payMode != 'payments' && <>
                    <div style={{textAlign:'center', fontWeight:'bold'}}>Subscription Billing is only available with regional pricing</div>
                </>}
            </>
            }


            <div style={{borderTop:"1px solid lightgray", margin:"30px 0"}}/>

            <Form.Item
                name="active_campaign_name"
                label="Active Campaign List"
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="tags"
                label="Email Client Tags"
            >
                <Select
                    mode="tags"
                    allowClear
                    style={{ width: '100%' }}
                    options={forr==="member"?memberOptions:forr==="student"?studentOptions:[]}
                />
            </Form.Item>

            <div style={{display:"flex",gap:5,paddingTop:20, justifyContent: "flex-end"}}>

                {props.plan.id &&
                <Button
                    key="delete"
                    danger
                    onClick={function () {
                        doDelete()
                    }}
                    style={{marginRight: "auto"}}
                >
                    Delete
                </Button>
                }

                {props.plan.id && <>
                    {false &&
                    <div style={{display: "flex", gap: 20, justifyContent: "flex-end"}}>
                        <span>Doesn't affect people currently on plan</span>
                        <Button type="secondary" htmlType="button" loading={isSaving} onClick={() => doSave()}
                                style={{width: 130}}>{(isSaving) ? 'Saving' : 'Save Version'}</Button>
                    </div>
                    }
                <div style={{display:"flex",gap:20,justifyContent:"flex-end"}}>
                    <Button type="primary" htmlType="button"  loading={isSaving} onClick={() => doAlter()} style={{width:130}}>{(isSaving)?'Saving':'Save'}</Button>
                </div>
                </>}
                {!props.plan.id && <>
                    <div style={{display:"flex",gap:20,justifyContent:"flex-end"}}>
                        <Button type="primary" htmlType="button"  loading={isSaving} onClick={() => doAlter()} style={{width:130}}>{(isSaving)?'Saving':'Save'}</Button>
                    </div>
                </>}
            </div>

        </Form>
	);
}


