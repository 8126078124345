import React, {useContext, useEffect, useState} from 'react';
import API2 from "../../components/api2";
import PlanForm from "../../forms/Plan"
import {NavLink, useParams} from "react-router-dom";

import {
    Tabs,
    Button,
    Spin
} from 'antd';
import {Form} from "antd/lib/index";
import BreadCrumbContext from "../../contexts/breadCrump";
import moment from "moment/moment";
import dayjs from "dayjs";

export default function Plan(props) {

    let id = props.match.params.planid;
    if(id == 'new') id = null;


    let defaultTab = "1";

    const AP = API2();

    const [plan, setPlan] = useState(false);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/plans"><span><i className="fas fa-box-usd"/> Plans</span></NavLink>,
            (id)?<Spin size="small"/>:<span><i className="fas fa-badge-dollar"/> Create Plan</span>
        ]);
    }, []);

    useEffect(() => {
        if(id) {
            AP.get('/plan/' + id)
                .then(function (res) {

                    setBreadCrumbs([
                        <NavLink to="/plans"><span><i className="fas fa-box-usd"/> Plans</span></NavLink>,
                        <span><i className="fas fa-badge-dollar"/> {res.name}</span>
                    ]);

                    if (res.starts_at) {
                        res.starts_at = dayjs(res.starts_at*1000 );
                    }
                    if(res.dishonor_fee) res.dishonor_fee /= 100;

                    setProducts(res.products);
                    setPlan(res);
                    setIsLoading(false);
                });
        }else{
            AP.get('/plan')
                .then(function (res) {
                    setProducts(res.products);
                    setPlan({
                        'coupon_options':res.coupon_options,
                        'plan_coupon_maps' : [],
                        'payments' : [],
                        'regionalPayments' : []
                    });
                    setIsLoading(false);
                });
        }
    }, []);

    return(
        <div className="content_inner">

            <PlanForm plan={plan} products={products}/>

        </div>
	);
}