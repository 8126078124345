import React, {useContext, useEffect, useState} from 'react';
import API2 from "../../components/api2";
import PlanForm from "../../forms/Plan"
import {NavLink, useParams} from "react-router-dom";

import {
    Tabs,
    Button,
    Spin,
	Checkbox,
	Modal,
	DatePicker,
} from 'antd';
import BreadCrumbContext from "../../contexts/breadCrump";
import moment from "moment/moment";
import Moment from 'react-moment';
import dayjs from "dayjs";
import {Form, notification} from "antd/lib/index";

const { TabPane } = Tabs;

export default function Group(props) {

    let name = props.match.params.name;


    const AP = API2();

    const [defaultTab, setDefaultTab] = useState("tab_9");
    const [plans, setPlans] = useState([]);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
	const [showDisabled, setShowDisabled] = useState(false);

	const [modalIsOpen, setModalIsOpen] = React.useState(false);
	const [newPlanModalForm]            = Form.useForm();

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/plans"><span><i className="fas fa-box-usd"/> Plans</span></NavLink>,
            (name)?<Spin size="small"/>:<span><i className="fas fa-badge-dollar"/> Create Plan Group</span>
        ]);
    }, []);

    useEffect(() => {

        if(name) {
            AP
                .get('/plans?showPrivate=true&name="'+name+'"&sort=starts_at&pageSize=9999')
                .then(function (res) {

                    setBreadCrumbs([
                        <NavLink to="/plans"><span><i className="fas fa-box-usd"/> Plans</span></NavLink>,
                        <span><i className="fas fa-badge-dollar"/> {res.name}</span>
                    ]);

                    let highestStarts = false;
                    let defaultTab = 1;
                    let now = Date.now()/1000;
                    for(const d of res.data){
                        if (d.starts_at) {
                            if((!highestStarts || d.starts_at<highestStarts) && d.starts_at>now){
                                highestStarts = d.starts_at;
                                defaultTab = "tab_"+d.id;
                            }

							d.starts_at = dayjs(d.starts_at * 1000);
                            //d.starts_at = moment(d.starts_at*1000 );
                        }
                        if(d.dishonor_fee) d.dishonor_fee /= 100;
                    }
                    setDefaultTab(defaultTab);
                    setProducts(res.products);
                    setPlans(res.data);
                    setProducts(res.products);
                    setIsLoading(false);
                });
        }else{
            setPlans([]);
            setIsLoading(false);
        }
    }, []);

	function disabledChange(e){
		setShowDisabled(e.target.checked);
	}

	function addCohort(data){
		data.starts_at = data.starts_at.unix();

		AP.post("/plan/"+plans[0].id+"/addCohort",data).then(function(res){
			notification.success({
				message: `Cohort Added`,
				placement: 'bottomRight',
				duration:1,
				closeIcon : (<div></div>)
			});
			window.location.reload();
		});

	    console.log(["adding",data]);
    }

    return(
        <div className="content_inner" style={{position:"relative"}}>
            {!!plans.length && <>
                <div style={{position:"absolute", right:0, top:13, fontSize:12, display:"flex", gap:10, zIndex: 999}}>
					<div><Button type="secondary"  size="small" shape="round" onClick={()=>(setModalIsOpen(true))}><i className="far fa-plus"/>&nbsp;Add Cohort</Button></div>
                    <div><Checkbox onChange={disabledChange}/> Show Disabled</div>
                </div>
                <Tabs defaultActiveKey={defaultTab} centered>
                    {plans.map(plan => {
						if(!showDisabled && plan.is_disabled) return null;
                        
                        return <TabPane tab={<Moment format="MMM.YY" unix>{plan.starts_at / 1000}</Moment>}
								 key={"tab_" + plan.id}>
							<PlanForm plan={plan} products={products}/>
						</TabPane>
					})}

                </Tabs>
            </>
            }


			<Modal
				open={modalIsOpen}
				onOk={() => {newPlanModalForm.submit();}}
				onCancel={()=>(setModalIsOpen(false))}
				title="Add Cohort"
				okText="Save"
				cancelText="Close"
				width={500}
                //centered={true}
				footer={
					<div style={{display:"flex"}}>
						<Button key="back" onClick={()=>(setModalIsOpen(false))} style={{marginLeft:"auto"}}>
							Close
						</Button>
						<Button key="submit" type="primary" onClick={function(){newPlanModalForm.submit();}}>
							Save
						</Button>
					</div>
				}
			>
				<Form
					id="addCohortForm"
					form={newPlanModalForm}
					name="addCohort"
					onFinish={addCohort}
					scrollToFirstError
					style={{ margin:"auto"}}
				>
					<Form.Item
						name="starts_at"
						label="Starts At"
						help="DD/MM/YYYY"
						rules={[
							{
								required: true,
								message: 'Required',
							}
						]}
					>
						<DatePicker format="DD/MM/YYYY"/>
					</Form.Item>
                </Form>
			</Modal>

        </div>
	);
}