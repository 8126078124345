import React, {useEffect, useState} from 'react';
import {
    Upload, Button
} from 'antd';
import axios from "axios/index";
import API2 from "../components/api2";
import { QuestionCircleOutlined, InboxOutlined,UploadOutlined  } from '@ant-design/icons';

export default function Uploader(props) {
    const AP = API2();

    function customRequest({action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials}) {
        function progress(p) {
            onProgress({
                percent: p
            });
        }

        return AP.post('/attachment', {'name': file.name})
            .then(function (res) {
                file.attachment_id = res.id;

                axios.request({
                    method: 'put',
                    url: res.upload_endpoint,
                    data: file,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (p) => {
                        onProgress({
                            percent: p.total
                        });
                    }
                }).then(data => {
                    onSuccess();
                });
            });
    }

    function onFileChange(data) {
        if(props.setFiles) {
            props.setFiles(data.fileList);
        }
    }

    function getIcon(file){

        let filetype = file.name.split('.').pop();

        if (file instanceof Blob && file.type.substr(0,6) == "image/" && !['tiff'].includes(file.type.substr(6)) ){
            return null
        }

        switch (filetype) {
            case "xlsx": filetype = "xls"; break;
            case "docx": filetype = "doc"; break;
            case "rar":  filetype = "zip"; break;
            case "pptx": filetype = "ppt"; break;
        }

        var iconList = ["aac","ai","aiff","avi","bmp","c","cpp","css","csv","dat","dmg","doc","dotx","dwg","dxf","eps","exe","flv","gif","h","hpp","html","ics","iso","java","jpg","js","key","less","mid","mp3","mp4","mpg","odf","ods","odt","otp","ots","ott","pdf","php","png","ppt","psd","py","qt","rar","rb","rtf","sass","scss","sql","tga","tgz","tiff","txt","wav","webm","xls","xlsx","xml","yml","zip"];

        if(iconList.includes(filetype)) {
            return <img src={"/images/icons/mimetype/"+filetype+".png"}/>;
        }else {
            return <img src={"/images/icons/mimetype/_blank.png"}/>;
        }
    }

    let atMax = props.files.length == props.maxCount;

    return <span className={(atMax)?'UploadAtMax':''}>
        <Upload.Dragger className="smallUploadArea"
                           method="PUT"
                           onChange={onFileChange}
                           customRequest={customRequest}
                           fileList={props.files}
                           maxCount={props.maxCount}
                           listType="picture"
                           iconRender={(file: UploadFile, listType?: UploadListType) => getIcon(file)}
                           props
        >

            <div>
                <div>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                </div>
                <div>
                    <p className="ant-upload-text">Click or drag {(props.maxCount==1)?'a file':'files'} to this area to upload</p>
                </div>
            </div>


        </Upload.Dragger>
    </span>;
}