import React, {Component, useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import {NavLink, useParams} from "react-router-dom";
import BreadCrumbContext from "../contexts/breadCrump";
import { Link } from "react-router-dom";
import API2 from "../components/api2";
import {
    Button,
    Table,
    Card
} from 'antd';
import { ExclamationCircleOutlined  } from '@ant-design/icons';
import {Form, notification} from "antd/lib/index";

import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";

import useToken from "../hooks/useToken";
import SearchTable from "../components/SearchTable";

import ReactDOM from 'react-dom';
import { Bar,Column } from '@ant-design/plots';

import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import Moment from 'react-moment';
import moment from 'moment';
import {Modal} from "antd/lib/index";
import useWindowDimensions from "../components/useWindowDimensions";
import SupportForm from "../forms/Support";


const { confirm } = Modal;

export default function DashboardMember(props) {

    const [data, setData] = useState(false);
    const AP = API2();
    const history = useHistory();
    const { user, setUser } = useToken();
    const { height, width } = useWindowDimensions();


    useEffect(() => {
        AP.get('/dashboard')
            .then(function (res) {
                setData(res);
            });
    }, []);


    const overdueColumns = [
        {
            title: 'Number',
            key: 'number',
            dataIndex: 'number',
            align:'left'
        },
        {
            title: 'Due Date',
            key: 'start_at',
            dataIndex: 'start_at',
            align:'left',
            render: (text, record) => (
                <Moment format="Do MMM YYYY" unix>{record.start_at}</Moment>
            ),
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount',
            align:'left',
            render: (text, record) => {
                return "$"+record.amount/100;
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width:150,
            render: (text, record) => (
                <div style={{display:"flex",gap:10}}>
                    {!record.paid &&
                    <Button
                        type="secondary"
                        className="btn-green"
                        size="small"
                        shape="round"
                        onClick={() => payInvoice(record.id)}
                    >Pay Now</Button>
                    }
                </div>
            ),
        },
    ];

    function payInvoice(invoiceId) {

        confirm({
            title: 'Are you sure you want to process this payment now?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                AP.post('/invoice/'+invoiceId+'/pay')
                    .then(function (res) {
                        if(res.status == "success"){
                            notification.success({
                                message: `Invoice Paid`,
                                placement: 'bottomRight',
                                duration:1,
                                closeIcon : (<div></div>)
                            });

                            window.location.reload();
                        }else{
                            notification.error({
                                message: `Payment Failed`,
                                placement: 'bottomRight',
                                duration:1,
                                closeIcon : (<div></div>)
                            });
                        }

                    });

            },
            onCancel() {
                return;
            },
        });


    }

    const [supportModalOpen, setSupportModalOpen] = React.useState(false);
    const [modalForm]     = Form.useForm();

    function downloadCertificate(){
        AP.file('/my/certificate');
    }


    return (
        <div className="content_inner">
            <div style={{paddingRight:20, width:"100%"}}>
            <div style={{paddingTop:"50px"}}>
                <div className="woocommerce-MyAccount-content">
                    <div className="woocommerce-notices-wrapper"></div>
                    <div style={{display:"flex"}}>
                        <div>{user.name}</div>
                        <div style={{marginLeft:"auto"}}>Member# <strong>{user.id.toString().padStart(7,'0')}</strong></div>
                    </div>

                    {data && <>
                        <div style={{display:"flex", gap:60, flexWrap:"wrap", paddingTop:30}}>
                            <div style={{ flex:1, minWidth:250, height: 160}} className="dashboard-card">
                                <h4 style={{height:60}}>
                                    <div>Overdue Invoices</div>
                                </h4>
                                <div
                                    style={{color:(data.overdueInvoices.length)?'red':'inital'}}>{data.overdueInvoices.length} invoice{data.overdueInvoices.length!==1?'s':''}
                                </div>
                                <Button
                                    type="secondary"
                                    className="btn-teal"
                                    size="large"
                                    shape="round"
                                    style={{position: "absolute", right: 10, bottom: 10}}
                                    href="/subscriptions/invoices"
                                >View</Button>
                            </div>

                            <div style={{ flex:1, minWidth:250, height: 160}} className="dashboard-card">
                                <h4>
                                    <div>Future Invoices</div>
                                    <div style={{fontWeight: "initial", fontSize: 16}}>Not due yet</div>

                                </h4>
                                <div style={{paddingTop: 10}}>{data.pendingInvoices.length} invoices</div>
                                <Button
                                    type="secondary"
                                    className="btn-teal"
                                    size="large"
                                    shape="round"
                                    style={{position: "absolute", right: 10, bottom: 10}}
                                    href="/subscriptions/invoices"
                                >View</Button>
                            </div>
                        </div>
                        {(data.isStudent && data.showAccessCourse) &&
                            <div style={{paddingTop:50}}>
                                <div style={{height: 160}}className="dashboard-card">
                                    <h4>
                                        <div>Course Access</div>

                                    </h4>
                                    <div style={{paddingTop: 10}}>

                                        <div>The course is accessible via Teachables. Click the button to the left to access.</div>
                                        <div>Please contact support (via drop down at top right of the screen) if you are unable to access the course.</div>

                                    </div>
                                    <Button
                                        type="secondary"
                                        className="btn-teal"
                                        size="large"
                                        shape="round"
                                        style={{position: "absolute", right: 10, bottom: 10}}
                                        href="https://teachable.sportsnutritionassociation.com"
                                        target="_blank"
                                    >Access Course</Button>
                                </div>
                            </div>
                        }


                        {data.isStudent && !data.isMember &&
                            <div style={{paddingTop:50}}>
                                <div style={{ minHeight: 160}}className="dashboard-card">
                                    <h4>Membership</h4>
                                    <div style={{paddingTop: 10}}>If you are looking to start practicing as a Sports Nutritionist. It is requirement that you become a SNA member.</div>
                                    <Button
                                        type="secondary"
                                        className="btn-red"
                                        size="large"
                                        shape="round"
                                        style={{position: "absolute", right: 10, bottom: 10}}
                                        href="/signUp"
                                    >Register</Button>
                                </div>
                            </div>

                        }

                        <div style={{paddingTop:50}}>
                            <div style={{ minHeight: 160}}className="dashboard-card">
                                <h4>
                                    <div>Support</div>
                                </h4>
                                <div style={{paddingTop: 10}}>Need help with a question or something not working?</div>
                                <div style={{position: "absolute", right: 10, bottom: 10, display:"flex", gap:20}}>
                                    <Button
                                        type="secondary"
                                        size="large"
                                        shape="round"
                                        onClick={()=>setSupportModalOpen("technical")}
                                    >Technical</Button>
                                    <Button
                                        type="secondary"
                                        className="btn-teal"
                                        size="large"
                                        shape="round"
                                        onClick={()=>setSupportModalOpen("general")}
                                    >General</Button>
                                </div>

                                <Modal
                                    visible={supportModalOpen}
                                    onOk={() => {modalForm.submit();}}
                                    onCancel={()=>setSupportModalOpen(false)}
                                    title="Support"
                                    okText="Save"
                                    cancelText="Close"
                                    //centered={true}
                                >
                                    <SupportForm form={modalForm} onFinish={()=>setSupportModalOpen(false)} type={supportModalOpen}/>
                                </Modal>
                            </div>
                        </div>

                        <div style={{display:"flex", gap:60, flexWrap:"wrap", paddingTop:50}}>
                            <div style={{ minHeight: 160, flex:1}}className="dashboard-card">
                                <h4>
                                    <div>Common Downloads</div>
                                </h4>
                                <div style={{paddingTop: 10}}>
                                    <ul>
                                        {data.resources.map((resource) => (
                                            <li>
                                                <div style={{display:"flex"}}>
                                                    <div style={{flex:1}}>{resource.name}</div>
                                                    <div style={{width:114}}>
                                                    {resource.name == "Certificate" &&
                                                    <span>
                                                        <Button type="primary" size="small" shape="round" onClick={downloadCertificate}><i className="far fa-download"/>&nbsp;Download</Button></span>
                                                    }
                                                    {resource.name != "Certificate" &&
                                                    <>
                                                        {resource.attachment_id &&
                                                        <span>
                                                            <a href={resource.attachment.endpoint}>
                                                                <Button type="primary" size="small" shape="round"><i className="far fa-download"/>&nbsp;Download</Button>
                                                            </a>
                                                        </span>
                                                        }
                                                        {resource.link &&
                                                        <span>
                                                            <a href={resource.link}>
                                                                <Button type="primary" size="small" shape="round" style={{width:"100%"}}>
                                                                    <i className="fal fa-paper-plane"/>
                                                                    <span style={{padding:"0 22px"}}>Visit</span>
                                                                </Button>
                                                            </a>
                                                        </span>
                                                        }
                                                    </>
                                                    }
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div style={{ minHeight: 160, flex:1}}></div>
                        </div>
                    </>}



                </div>
            </div>
        </div>
        </div>
    );
}