import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
	Form,
	Input,
	Select
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import Moment from 'react-moment';
import BreadCrumbContext from "../contexts/breadCrump";


const { Option } = Select;




export default function TransactionForm(props) {

	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [invoices, setInvoices] = useState([]);

	const AP = API2();


	let formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};

	useEffect(() => {

		props.form.resetFields();

		let url = '/invoices?subscriptionId='+props.transaction.subscription_id+'&sort=start_at&pageSize=9999';

		AP.get(url)
			.then(function (res) {
				setInvoices(res.data);

				props.form.setFieldsValue(props.transaction);
				setIsLoading(false);
			});



	}, [props.transaction.subscription_id]);



	function saveForm(data){

		setIsSaving(true);

		let url = '/transaction'+'/'+props.transaction.id;

		AP.post(url,data).then(function(res){

			notification.success({
				message: `Saved`,
				placement: 'bottomRight',
				duration:1,
				closeIcon : (<div></div>)
			});

			props.onFinish();
		}).catch(function(res){
			setIsSaving(false);

			if(res.response.data.errors) {
				let errorList = {};
				for (let error of res.response.data.errors) {
					if (!errorList[error.input]) errorList[error.input] = [];
					errorList[error.input].push(error.message);
				}
				for (let [input, errors] of Object.entries(errorList)) {
					props.form.setFields([
						{
							name: input,
							errors: errors,
						},
					]);
				}
			}
		});
	}

	return(

        <>

			<Form
				id="transactionForm"
				{...formItemLayout}
				form={props.form}
				name="transaction"
				onFinish={saveForm}
				scrollToFirstError
				style={{ margin:"auto"}}
			>

				<Form.Item
					name="invoice_id"
					label="Invoice Number"
				>
					<Select
						placeholder=""
					>
						{invoices.map(function(invoice){
							return <Option value={invoice.id}>{invoice.number}</Option>
						})}

					</Select>
				</Form.Item>

			</Form>


        </>
	);
}