import React, { Component } from "react";
import { useHistory, Link } from "react-router-dom";
import {
    Form,Input,
} from 'antd';

export default function Home(props) {

    const [form] = Form.useForm();
    const history = useHistory();

    function getStartedClicked(data){
        history.push({
            pathname: '/signUp',
            state: data
        });
    }


    return (
        <div className="content_inner" >
            <div className="container">



                <div className="site-header hd-glb hd-gs2 ihd-style2 ihd-basic cpn-glb has-sdr no-pt sec">
                    <div className="ibn ibn-ss sec">
                        <div className="nio-sdr flexslider nio-sdr-2">
                            <div className="islider udsg udmfw by-any">
                                <div className="slide slide1 ui-s1">
                                    <div className="cpnw cov cm cx vm show">
                                        <div className="main">
                                            <div className="cpn al-c cl-lt bg-g wd-g">
                                                <div className="content animate"><h2>Sports Nutrition Association</h2>
                                                    <h1>Become a Member Today</h1>
                                                </div>
                                                <div className="linkto animate">
                                                    <Link to="/about" className="button btn-cta"  onClick={() => props.setMenuStyle('light')}>
                                                        <span>Get Started</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg hs-im">
                                        <a href="https://www.sportsnutritionassociation.com/product/annual-registration/">
                                            <img src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/sports-nutrition-association-main.jpg" alt="" height="920" width="1920" style={{"filter":"brightness(0.5)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-wrap ipg ifront ipg-home sec" style={{"backgroundColor":"#f9f9f9"}}>
                    <div className="wrap wrap-wd wrap-page gb-sd no-aside sec">
                        <div className="icontent sec">
                            <article className="epc epg">
                                <div className="ientry">
                                    <div
                                        className="section sec-sc sec wider row-xd sec-rg sec-promo light ud-pad sec-a180767z" style={{"backgroundColor":"white"}}>
                                        <div className="content">
                                            <div className="columns row-sc row ec-promo-flex">
                                                <div className="eclm clm-sc g3 ec-promo-block">
                                                    <div className="content">
                                                        <p><a
                                                            href="https://sportsnutrition.org.au/certificate-in-applied-sports-nutrition/"
                                                            target="_blank" rel="noopener">
                                                            <img className="alignnone wp-image-47" src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/icon-promo_01.png" alt="" width="54" height="53"/>
                                                        </a></p>
                                                        <h4>Certificate in<br/>Applied Sports Nutrition</h4>
                                                        <p><a href="https://sportsnutrition.org.au/certificate-in-applied-sports-nutrition/" target="_blank" rel="noopener">Learn More</a></p>
                                                    </div>
                                                </div>
                                                <div className="eclm clm-sc g3 ec-promo-block">
                                                    <div className="content">
                                                        <p>
                                                            <a href="https://www.sportsnutritionistregistry.com/"
                                                               target="_blank" rel="noopener">
                                                                <img loading="lazy"
                                                                     className="alignnone wp-image-48"
                                                                     src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/icon-promo_02.png"
                                                                     alt="" width="45"
                                                                     height="45"/>
                                                            </a>
                                                        </p>
                                                        <h4>Sports Nutritionist<br/> Registry</h4>
                                                        <p><a href="https://www.sportsnutritionistregistry.com/"
                                                              target="_blank" rel="noopener">Learn More</a></p>
                                                    </div>
                                                </div>
                                                <div className="eclm clm-sc g3 ec-promo-block">
                                                    <div className="content">
                                                        <p><a href="https://www.sportsnutritionassociation.com/code-of-conduct/"><img
                                                            loading="lazy" className="alignnone wp-image-49"
                                                            src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/icon-promo_03.png"
                                                            alt="" width="66" height="65"/></a></p>
                                                        <h4>Code of Ethics &amp;<br/>Professional Conduct</h4>
                                                        <p><a href="https://www.sportsnutritionassociation.com/code-of-conduct/">Learn More</a></p>
                                                    </div>
                                                </div>
                                                <div className="eclm clm-sc g3 ec-promo-block">
                                                    <div className="content">
                                                        <p><a href="#affiliate"><img loading="lazy"
                                                                                     className="alignnone wp-image-50"
                                                                                     src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/icon-promo_04.png"
                                                                                     alt="" width="63" height="65"/></a>
                                                        </p>
                                                        <h4>Affiliates &amp; Recommended<br/>
                                                            Programs</h4>
                                                        <p><a href="#affiliate">Learn More</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section sec-sc sec basic row-xd sec-rg sec-intro" style={{"backgroundColor":"white"}}>
                                        <div className="main">
                                            <div className="content">
                                                <div className="ecn ec-sc ec-a961095z">
                                                    <div className="content">
                                                        <p><img loading="lazy"
                                                                className="alignnone wp-image-53 aligncenter"
                                                                src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/icon-sn.png"
                                                                alt="" width="74" height="81"/></p>
                                                        <h2 style={{textAlign: "center"}}>Welcome to the</h2>
                                                        <h1 style={{textAlign: "center"}}>Sports Nutrition
                                                            Association</h1>
                                                        <p style={{textAlign: "center"}}>The Sports Nutrition Association
                                                            is the Global Regulatory Body Responsible for the
                                                            Standardisation of Best Practise in the Sports Nutrition
                                                            Profession.</p>
                                                        <h4 style={{textAlign: "center"}}>Our Mission is:</h4>
                                                        <p style={{textAlign: "center"}}></p><h2 className="title tl-sc">
                                                        <span>‘Ensuring the sustainable prosperity of the Sports Nutrition Profession.’</span>
                                                    </h2><p></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section sec-sc sec basic row-xd sec-rg sec-who-is light ud-pad tpd-m bpd-m ud-bgx ud-bgc plx-right plx-aside sec-a686786z" style={{"backgroundColor":"black", paddingTop:"170px", paddingBottom:"190px"}}>
                                        <div className="main">
                                            <div className="content">
                                                <div className="columns row-sc row">
                                                    <div className="eclm clm-sc g6 ec-content-left">
                                                        <div className="content">
                                                            <h2 className="title tl-sc"><span>Who is this for?</span>
                                                            </h2>
                                                            <p><strong>If you are:</strong></p>
                                                            <ul>
                                                                <li className="first">Interested in becoming an
                                                                    Accredited Sports Nutritionist
                                                                </li>
                                                                <li>Not content with settling for minimum standards in
                                                                    education and their profession
                                                                </li>
                                                                <li className="last">Interested in furthering nutrition
                                                                    qualifications beyond the Certificate of Applied
                                                                    Sports Nutrition
                                                                </li>
                                                            </ul>
                                                            <a href="https://www.sportsnutritionassociation.com/product/annual-registration/"
                                                               className="button btn-normal"><span>Get Started</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="plx-bg in-right" style={{"backgroundImage":"url('https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/sports-nutrition-association-3.jpg')"}}>
                                            <div className="over-sec ov-bk" style={{"opacity": "0.01"}}></div>
                                        </div>
                                    </div>
                                    <div
                                        className="section sec-sc sec basic row-xd sec-rg sec-who-is light ud-pad tpd-m bpd-m ud-bgc sec-a170186z" style={{"backgroundColor":"#00A3FA", paddingTop:"170px", paddingBottom:"190px"}}>
                                        <div className="main">
                                            <div className="content">
                                                <div className="ecn ec-sc ec-a179957z">
                                                    <div className="content">
                                                        <div className="columns row-sc row ec-flex">
                                                            <div className="eclm clm-sc g8">
                                                                <div className="content">
                                                                    <h2>Enrol in your region today</h2>
                                                                    <p>Wanting to become a Registered Sports
                                                                        Nutritionist? Find out more by clicking through
                                                                        to your local region and registering today.</p>
                                                                </div>
                                                            </div>
                                                            <div className="eclm clm-sc g4 ar">
                                                                <div className="content">
                                                                    <a href="https://www.sportsnutritionassociation.com/enrol/"
                                                                       className="button btn-normal"><span>Register</span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="section sec-sc sec basic row-xd sec-rg sec-affiliate ud-pad tpd-m bpd-m ud-bgc affiliate"
                                        id="affiliate">
                                        <div className="main">
                                            <div className="content">
                                                <div className="columns row-sc row ec-affiliate-flex">
                                                    <div
                                                        className="eclm clm-sc g4 ec-affiliate-block ec-affiliate-details">
                                                        <div className="content">
                                                            <h2>Affiliates &amp; Recommended Programs</h2>
                                                            <p>Our regional affiliates in New Zealand and The U.S are
                                                                the Holistic Performance Institute and National Academy
                                                                of Metabolic Sciences.</p>
                                                            <p>Working with these institutions gives our students
                                                                discounts on some of their short courses for HPI and
                                                                access to the NCGM as part of registration as an
                                                                association member.</p>
                                                        </div>
                                                    </div>
                                                    <div className="eclm clm-sc g4 ec-affiliate-block">
                                                        <div className="content">
                                                            <p><a href="https://www.holisticperformance.institute/"
                                                                  target="_blank" rel="noopener"><img loading="lazy"
                                                                                                      className="alignnone wp-image-70"
                                                                                                      src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/affiliate_01.png"
                                                                                                      alt="" width="305"
                                                                                                      height="82"
                                                                                                      srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/affiliate_01.png 580w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/affiliate_01-300x81.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/affiliate_01-460x124.png 460w"
                                                                                                      sizes="(max-width: 305px) 100vw, 305px"/></a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="eclm clm-sc g4 ec-affiliate-block">
                                                        <div className="content">
                                                            <p><a href="https://namscert.com/" target="_blank"
                                                                  rel="noopener"><img loading="lazy"
                                                                                      className="alignnone wp-image-71"
                                                                                      src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/affiliate_02.png"
                                                                                      alt="" width="266" height="82"
                                                                                      srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/affiliate_02.png 532w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/affiliate_02-300x92.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/affiliate_02-460x142.png 460w"
                                                                                      sizes="(max-width: 266px) 100vw, 266px"/></a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
                <div className="sec newsletter" style={{"backgroundColor":"white"}}>
                    <div className="main">
                        <div className="newsletter-form">
                            <div className="wg wg-newsletter"><h4 className="wg-title">
                                <span>Subscribe to our Newsletter</span><em></em></h4>
                                <div className="textwidget">
                                    <div className="columns row-sc row ec-flex">
                                        <div className="eclm clm-sc g5">
                                            <div className="contentz">
                                                <p>Sign up to get all the latest news and updates from us</p>
                                            </div>
                                        </div>
                                        <div className="eclm clm-sc g7">
                                            <div className="content">
                                                <div
                                                    className="gf_browser_chrome gform_wrapper gform_legacy_markup_wrapper"
                                                    id="gform_wrapper_3">
                                                    <div id="gf_3" className="gform_anchor" tabIndex="-1"></div>
                                                    <form method="post" encType="multipart/form-data"
                                                          target="gform_ajax_frame_3" id="gform_3" action="/#gf_3">
                                                        <div className="gform_body gform-body">
                                                            <ul id="gform_fields_3"
                                                                className="gform_fields top_label form_sublabel_below description_below">
                                                                <li id="field_3_1"
                                                                    className="gfield gfield_contains_required field_sublabel_below field_description_below hidden_label gfield_visibility_visible last first">
                                                                    <label className="gfield_label" htmlFor="input_3_1">Email<span
                                                                        className="gfield_required"><span
                                                                        className="gfield_required gfield_required_asterisk">*</span></span></label>
                                                                    <div className="ginput_container ginput_container_email">
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="gform_footer top_label">

                                                        </div>
                                                    </form>
                                                </div>
                                                <iframe style={{"display":"none", "width":"100%", "height":"0px"}} src="about:blank"
                                                        name="gform_ajax_frame_3" id="gform_ajax_frame_3"
                                                        title="This iframe contains the logic required to handle Ajax powered Gravity Forms."></iframe>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






            </div>














            <link rel="stylesheet" id="gforms_reset_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formreset.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_formsmain_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formsmain.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_ready_class_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/readyclass.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_browsers_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/browsers.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="global-styles-inline-css" href="/css/global-styles-inline.css"></link>
            {false && <link rel="stylesheet" id="other-css" href="https://www.sportsnutritionassociation.com/wp-content/themes/scsnassoc/assets/others.css?ver=170601.1.0.2" media="all"/>}
            <link rel="stylesheet" id="theme-css" href="/wp_style.css" media="all"/>

        </div>
    );
}