import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {
    Form,Input,
} from 'antd';

export default function Contact(props) {

    const [form] = Form.useForm();
    const history = useHistory();


    function getStartedClicked(data){
        history.push({
            pathname: '/signUp',
            state: data
        });
    }


    return (
        <div className="content_inner_padded" >
            <div className="container">

                <div className="ibn ibn-sb sec">
                    <div className="ebn ud-bn ud-pt obg uix-alter ebn-p9x sec">
                        <div className="cpnw cov xg">
                            <div className="main">
                                <div className="cpn al-g cl-g bg-g wd-g">
                                    <div className="title">
                                        <h1>Code Of Ethics And Professional Conduct
                                            <div className="breadcrumb">
                                                <span className="separator"> &gt; </span>
                                                <span className="current">Code Of Ethics And Professional Conduct</span>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg hs-bg is-basic" data-height="auto" style={{"backgroundImage":"url('https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/adversary-banner.jpg')"}}>
                            <div className="over tpb op01" style={{"opacity":"0.01"}}></div>
                        </div>
                    </div>
                </div>

                <div className="section sec-sc sec basic row-xd sec-rg light sec-blue-strip ud-pad ud-bgc sec-a132994z" style={{"paddingTop":"65px", "paddingBottom":"65px", "backgroundColor":"#01a5f8"}}>
                    <div className="main">
                        <div className="content">
                            <p style={{"text-align":"center"}}>
                                <a href="https://www.sportsnutritionassociation.com/product/annual-registration/" className="button btn-fill btn-normal">
                                    <span>Registration</span>
                                </a>
                                <a href="https://www.sportsnutritionistregistry.com/" className="button btn-normal" target="_blank">
                                    <span>Find A Nutritionist</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>


                <div className="site-wrap ipg iopg ipg-contact sec">
                    <div className="wrap wrap-wd wrap-page gb-sd no-aside sec">
                        <div className="icontent sec">
                            <article className="epc epg">
                                <div className="ientry">
                                    <div
                                        className="section sec-sc sec basic row-xd sec-rg sec-contact ud-pad tpd-s bpd-s ud-bgc sec-a613802z">
                                        <div className="main">
                                            <div className="content">
                                                <div className="ecn ec-sc ec-a583860z">
                                                    <div className="content">
                                                        <h2 style={{textAlign:"center"}}>Get in touch</h2>
                                                        <p style={{textAlign:"center"}}>Please complete the form and
                                                            we’ll get back to you within 1 – 2 business days.</p>
                                                        <p style={{textAlign:"center"}}>We look forward to working with
                                                            you.</p>

                                                        <div
                                                            className="gf_browser_chrome gform_wrapper gform_legacy_markup_wrapper"
                                                            id="gform_wrapper_1">
                                                            <div id="gf_1" className="gform_anchor" tabIndex="-1"></div>
                                                            <form method="post" encType="multipart/form-data"
                                                                  target="gform_ajax_frame_1" id="gform_1"
                                                                  action="/contact/#gf_1">
                                                                <div className="gform_body gform-body">
                                                                    <ul id="gform_fields_1"
                                                                        className="gform_fields top_label form_sublabel_below description_below">
                                                                        <li id="field_1_1"
                                                                            className="gfield gf_left_half gfield_contains_required field_sublabel_below field_description_below hidden_label gfield_visibility_visible first">
                                                                            <label className="gfield_label"
                                                                                   htmlFor="input_1_1">Name<span
                                                                                className="gfield_required"><span
                                                                                className="gfield_required gfield_required_asterisk">*</span></span></label>
                                                                            <div
                                                                                className="ginput_container ginput_container_text">
                                                                                <input name="input_1" id="input_1_1"
                                                                                       type="text" value=""
                                                                                       className="medium" tabIndex="999"
                                                                                       placeholder="Name"
                                                                                       aria-required="true"
                                                                                       aria-invalid="false"/></div>
                                                                        </li>
                                                                        <li id="field_1_2"
                                                                            className="gfield gf_right_half gfield_contains_required field_sublabel_below field_description_below hidden_label gfield_visibility_visible">
                                                                            <label className="gfield_label"
                                                                                   htmlFor="input_1_2">Email<span
                                                                                className="gfield_required"><span
                                                                                className="gfield_required gfield_required_asterisk">*</span></span></label>
                                                                            <div
                                                                                className="ginput_container ginput_container_email">
                                                                                <input name="input_2" id="input_1_2"
                                                                                       type="text" value=""
                                                                                       className="medium"
                                                                                       tabIndex="1000"
                                                                                       placeholder="Email"
                                                                                       aria-required="true"
                                                                                       aria-invalid="false"/>
                                                                            </div>
                                                                        </li>
                                                                        <li id="field_1_3"
                                                                            className="gfield gf_left_half gfield_contains_required field_sublabel_below field_description_below hidden_label gfield_visibility_visible">
                                                                            <label className="gfield_label"
                                                                                   htmlFor="input_1_3">Subject<span
                                                                                className="gfield_required"><span
                                                                                className="gfield_required gfield_required_asterisk">*</span></span></label>
                                                                            <div
                                                                                className="ginput_container ginput_container_text">
                                                                                <input name="input_3" id="input_1_3"
                                                                                       type="text" value=""
                                                                                       className="medium"
                                                                                       tabIndex="1001"
                                                                                       placeholder="Subject"
                                                                                       aria-required="true"
                                                                                       aria-invalid="false"/></div>
                                                                        </li>
                                                                        <li id="field_1_4"
                                                                            className="gfield gf_right_half field_sublabel_below field_description_below hidden_label gfield_visibility_visible">
                                                                            <label className="gfield_label"
                                                                                   htmlFor="input_1_4">Phone</label>
                                                                            <div
                                                                                className="ginput_container ginput_container_phone">
                                                                                <input name="input_4" id="input_1_4"
                                                                                       type="text" value=""
                                                                                       className="medium"
                                                                                       tabIndex="1002"
                                                                                       placeholder="Phone"
                                                                                       aria-invalid="false"/></div>
                                                                        </li>
                                                                        <li id="field_1_5"
                                                                            className="gfield gfield_contains_required field_sublabel_below field_description_below hidden_label gfield_visibility_visible">
                                                                            <label className="gfield_label"
                                                                                   htmlFor="input_1_5">Country<span
                                                                                className="gfield_required"><span
                                                                                className="gfield_required gfield_required_asterisk">*</span></span></label>
                                                                            <div
                                                                                className="ginput_container ginput_container_select">
                                                                                <select name="input_5" id="input_1_5"
                                                                                        className="medium gfield_select"
                                                                                        tabIndex="1003"
                                                                                        aria-required="true"
                                                                                        aria-invalid="false">
                                                                                    <option value="" selected="selected"
                                                                                            className="gf_placeholder">Your
                                                                                        Country
                                                                                    </option>
                                                                                    <option
                                                                                        value="Australia">Australia
                                                                                    </option>
                                                                                    <option value="America">America
                                                                                    </option>
                                                                                    <option value="Asia">Asia</option>
                                                                                    <option value="Europe">Europe
                                                                                    </option>
                                                                                    <option value="New Zealand">New
                                                                                        Zealand
                                                                                    </option>
                                                                                </select></div>
                                                                        </li>
                                                                        <li id="field_1_6"
                                                                            className="gfield gfield_contains_required field_sublabel_below field_description_below hidden_label gfield_visibility_visible last">
                                                                            <label className="gfield_label"
                                                                                   htmlFor="input_1_6">Message<span
                                                                                className="gfield_required"><span
                                                                                className="gfield_required gfield_required_asterisk">*</span></span></label>
                                                                            <div
                                                                                className="ginput_container ginput_container_textarea">
                                                                                <textarea name="input_6" id="input_1_6"
                                                                                          className="textarea medium"
                                                                                          tabIndex="1004"
                                                                                          placeholder="Comment"
                                                                                          aria-required="true"
                                                                                          aria-invalid="false" rows="10"
                                                                                          cols="50"></textarea></div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="gform_footer top_label">
                                                                    <input
                                                                        type="submit" id="gform_submit_button_1"
                                                                        className="gform_button button" value="Send"
                                                                        tabIndex="1005"
                                                                        onClick="if(window[&quot;gf_submitting_1&quot;]){return false;}  window[&quot;gf_submitting_1&quot;]=true;  "
                                                                        onKeyPress="if( event.keyCode == 13 ){ if(window[&quot;gf_submitting_1&quot;]){return false;} window[&quot;gf_submitting_1&quot;]=true;  jQuery(&quot;#gform_1&quot;).trigger(&quot;submit&quot;,[true]); }"/>
                                                                    <input type="hidden" name="gform_ajax"
                                                                           value="form_id=1&amp;title=&amp;description=&amp;tabindex=999"/>
                                                                    <input type="hidden" className="gform_hidden"
                                                                           name="is_submit_1" value="1"/>
                                                                    <input type="hidden"
                                                                           className="gform_hidden"
                                                                           name="gform_submit" value="1"/>

                                                                    <input type="hidden"
                                                                           className="gform_hidden"
                                                                           name="gform_unique_id" value=""/>
                                                                    <input type="hidden"
                                                                           className="gform_hidden"
                                                                           name="state_1"
                                                                           value="WyJbXSIsIjg0Y2M0YzFiNTllMzliNjc3ZDEyZjg2NGQzOWRiOTVjIl0="/>
                                                                    <input type="hidden"
                                                                           className="gform_hidden"
                                                                           name="gform_target_page_number_1"
                                                                           id="gform_target_page_number_1"
                                                                           value="0"/>
                                                                    <input type="hidden"
                                                                           className="gform_hidden"
                                                                           name="gform_source_page_number_1"
                                                                           id="gform_source_page_number_1"
                                                                           value="1"/>
                                                                    <input type="hidden"
                                                                           name="gform_field_values"
                                                                           value=""/>

                                                                </div>
                                                            </form>
                                                        </div>
                                                      

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    );
}