import React, {useContext, useEffect, useState, useRef} from 'react';
import API2 from "../../components/api2";
import UserForm from "../../forms/User"
import UserMembershipForm from "../../forms/UserMembership"
import {Link} from "react-router-dom";
import InvoiceForm from "../../forms/Invoice"
import SubscriptionForm from "../../forms/Subscription"
import DiscountsPage from "../Discount/List";
import LogEmailForm from "../../forms/LogEmail"
import SendEmailForm from "../../forms/SendEmail"


import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import moment from "moment/moment";
import dayjs from "dayjs";
import {NavLink, useParams} from "react-router-dom";

import {
    Tabs,
    Button,
    Menu,
    Dropdown,
    Spin,
    Table,
    Modal,
    Input,
    Space,
    Checkbox
} from 'antd';
import {Form, notification} from "antd/lib/index";
import BreadCrumbContext from "../../contexts/breadCrump";
import { UserOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import UserPermissionsForm from "../../forms/UserPermissions";
import useToken from "../../hooks/useToken";
import RegistrySettingsForm from "../../forms/RegistrySettings";
import useWindowDimensions from "../../components/useWindowDimensions";
import DeregisterForm from "../../forms/deregister";
import TransactionForm from "../../forms/Transaction";

const { TabPane }  = Tabs;
const { TextArea } = Input;
const { confirm }  = Modal;

export default function User(props) {

    let id = props.match.params.id;
    if(id == 'new') id = null;

    const { setLoginAsUser } = useToken();

    let subtitle;
    let defaultTab = props.match.params.tab?props.match.params.tab:"general";

    const AP = API2();
    const { width } = useWindowDimensions();
    const { user:authUser, setUser:setAuthUser } = useToken();

    const [form]                 = Form.useForm();
    const [invoiceModalForm]     = Form.useForm();
    const [subscriptionForm]     = Form.useForm();
    const [logEmailModalForm]    = Form.useForm();
    const [createEmailModalForm] = Form.useForm();
    const [deregisterModalForm]  = Form.useForm();
	const [transactionModalForm] = Form.useForm();
    const [user, setUser] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [userType, setUserType] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [invoiceId, setInvoiceId] = React.useState(false);
    const [forceRefresh, setForceRefresh] = React.useState(0);
    const [logEmailModalIsOpen, setLogEmailModalIsOpen]               = React.useState(false);
    const [createEmailModalIsOpen, setCreateEmailModalIsOpen]         = React.useState(false);
    const [subscriptionModalIsOpen, setSubscriptionModalIsOpen]       = React.useState(false);
	const [transactionModalIsOpen, setTransactionModalIsOpen]         = React.useState(false);
	const [transaction, setTransaction]                               = React.useState(false);
    const [subscriptionModalIsLoading, setSubscriptionModalIsLoading] = React.useState(false);
	const [deregisterSubscription, setDeregisterSubscription]         = React.useState(false);
    const [subscription, setSubscription] = React.useState(false);
	const [showOldSubscriptions, setShowOldSubscriptions] = useState(false);
    const [invoiceDelete, setInvoiceDelete] = useState(false);

    const [forceEmailsReload, setForceEmailsReload] = React.useState(0);
    const [forceSubscriptionReload, setForceSubscriptionReload] = React.useState(0);

    const [isStudent, setIsStudent] = React.useState(props.path == "/student/:id");
    const [isMember, setIsMember] = React.useState(props.path == "/member/:id");

    const [notes,setNotes] = React.useState([]);
    const [addingNote, setAddingNote] = React.useState(false);

    const [invoiceFilter, setInvoiceFilter] = useState("");

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
        let navLink;
        if(props.match.path == '/member/:id'){
			navLink = <NavLink to="/members"><span><i className="fas fa-user-tie"/> Members</span></NavLink>;
        }else if(props.match.path == '/student/:id'){
			navLink = <NavLink to="/students"><span><i className="fas fa-user-graduate"/> Students</span></NavLink>;
        }else{
			navLink = <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>;
        }

        setBreadCrumbs([
			navLink,
            (id)?<Spin size="small"/>:<span><i className="fas fa-user"/> Create User</span>
        ]);


    }, []);

    useEffect(() => {
		setIsLoading(true);

        if(id) {
            AP.get('/user/' + id)
                .then(function (res) {
                    if (res.dob) {
                        res.dob = dayjs(res.dob * 1000);
                    }

					let navLink;
					if(props.match.path == '/member/:id'){
						navLink = <NavLink to="/members"><span><i className="fas fa-user-tie"/> Members</span></NavLink>;
					}else if(props.match.path == '/student/:id'){
						navLink = <NavLink to="/students"><span><i className="fas fa-user-graduate"/> Students</span></NavLink>;
					}else{
						navLink = <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>;
					}

                    setBreadCrumbs([
						navLink,
                        <span><UserOutlined/> {res.name}</span>
                    ]);

                    res.isMember  = false;
                    res.isStudent = false;

                    if(res.subscriptions){
                        for(const sub of res.subscriptions){
                            if(sub.plan.for == "member"){
                                res.isMember = true;
                            }
                            if(sub.plan.for == "student"){
                                res.isStudent = true;
                            }
                        }
                    }

                    setUser(res);
                    setNotes(res.notes);
                    form.setFieldsValue(res);

                    if (res.profile_image) {
                        setFiles([{
                            uid: res.profile_image.id,
                            name: res.profile_image.name,
                            status: 'done',
                            url: res.profile_image.endpoint,
                            thumbUrl: res.profile_image.endpoint
                        }]);
                    }
                    setIsLoading(false);
                });
        }else{
            setUser({});
            setIsLoading(false);
        }
    }, [props.match.params.id]);


    const invoiceColumns = [
        {
            title: '',
            key: 'is_primary',
            dataIndex: 'is_primary',
            align:'center',
            width:"40px",
            filters: [
                {
                    text: 'Primary Payment',
                    value: '1',
                },
                {
                    text: 'Other',
                    value: '0'
                }
            ],
            render: function(text, record) {
                if(record.is_primary) return <span><i className="fas fa-check" style={{color: "#1890ff"}}/></span>
                return "";
            }
        },
        {
            title: 'ID',
            key: 'plan',
            dataIndex: 'plan',
            align:'left',
            width:200,
            render: (text, record) => {

                let name = record.subscription.plan.name;
                if(record.is_penalty){
                    if(record.i_penalties.length === 1){
						name += ' - '+record.i_penalties[0].penalty.name;
                    }else {
						name += ' - Penalty';
					}
				}

				return <span>
                    <div>{name} ({record.number})</div>
					{record.subscription.plan.starts_at
                        ? <div style={{fontSize: 10}}><Moment format="MMM YYYY" unix>{record.subscription.plan.starts_at}</Moment></div>
                        : ""}
                </span>;
			}
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount',
            align:'left',
            width:"130px",
            render: (text, record) => {
                let amount = record.amount;

				const formatter = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: record.currency,
					currencyDisplay: 'narrowSymbol'
				});


                let refundedStr = '';
                if(record.refunded_at){
                    const refundTransaction = record.transactions.find(t=>t.name=="Refund");
                    refundedStr = `${formatter.format(refundTransaction.amount/100).replace(/\D00$/, '')} refunded`;
                }

                if(!record.i_discounts.length && !record.i_penalties.length){
                    return <span>
                        {formatter.format(amount/100).replace(/\D00$/, '')} 
                        <span style={{fontSize:"10px"}}>{record.currency.toUpperCase()}</span>
                        {!!refundedStr && <div style={{fontSize:".7em",color:"red"}}>{refundedStr}</div>}
                    </span>
                }

                if(record.i_discounts.length){
					for(const p of record.i_discounts){
						if(p.type=='fixed') amount -= parseFloat(p.amount);
						else if(['percent','fixed percent'].includes(p.type)) amount -= record.amount*parseFloat(p.amount)/100;
					}
				}
				if(record.i_penalties.length){
                    for(const p of record.i_penalties){
                        if(p.type=='fixed') amount += parseFloat(p.amount);
                        else if(p.type=='percent') amount += record.amount*parseFloat(p.amount)/100;
                    }
				}

				if(record.is_penalty){
					return <span>
                        <div>{formatter.format(amount/100).replace(/\D00$/, '')} <span style={{fontSize:"10px"}}>{record.currency.toUpperCase()}</span></div>
                    </span>
                }




				return <span>
					<div style={{textDecoration:"line-through", color:"silver"}}>{formatter.format(record.amount/100).replace(/\D00$/, '')}</div>
					<div>{formatter.format(amount/100).replace(/\D00$/, '')} <span style={{fontSize:"10px"}}>{record.currency.toUpperCase()}</span></div>
                </span>
			}
        },
        {
            title: 'Late Fee',
            key: 'late_fee',
            dataIndex: 'late_fee',
            align:'center',
            width:"90px",
            render: function(text, record) {
                if(record.prevent_fees) return <span><i className="fas fa-times" style={{color: "grey"}}/></span>
                return <span>{record.late_fee ? <i className="fas fa-check" style={{color: "green"}}/> : ''}</span>
            }
        },
        {
            title: 'Starts At',
            key: 'start_at',
            dataIndex: 'start_at',
            align:'left',
            width:140,
            //sorter: (a, b) => a.start_at - b.start_at,
            sorter: true,
            defaultSortOrder: 'descend',
            render: (text, record) => (
                <Moment format="Do MMM YYYY" unix>{record.start_at}</Moment>
            ),
        },
        {
            title: 'Ends At',
            key: 'end_at',
            dataIndex: 'end_at',
            align:'left',
            width:140,
            render: (text, record) => {
                if(!record.end_at) return "";
                return <Moment format="Do MMM YYYY" unix>{record.end_at}</Moment>;
            },
        },
        {
            title: 'State',
            key: 'status',
            dataIndex: 'status',
			width:140,
            render: (text, record) => {

                if(record.refunded_at){
                    return <div>
                        <div style={{display: "flex"}}>
                            <div style={{
                                width: 30,
                                color: 'green'
                            }}>
                                <i className="fas fa-check"/>
                            </div>
                            <div style={{textAlign:"left"}}>
                                <div>Paid</div>
                                <div style={{fontSize:".7em",color:"red"}}>(with refund)</div>
                            </div>
                        </div>
                    </div>;
                }
                
                let transaction;
                for(const tran of record.transactions){
                    if (tran.status == 'success') transaction = tran;
                }

				return <div>

                        <div style={{display: "flex"}}>
                            <div style={{
                                width: 30,
                                color: (record.paid) ? 'green' : (record.status == 'Overdue') ? 'red' : 'black'
                            }}>
                                {record.paid == 1 && <><i className="fas fa-check"></i>&nbsp;</>}
                                {!record.paid && record.status == 'Overdue' && <><i className="fas fa-times"></i>&nbsp;</>}
                                {!record.paid && record.status != 'Overdue' && <><i className="fas fa-ellipsis-h"/>&nbsp;</>}
                            </div>

                            <div>{record.status}</div>
                        </div>
                        {transaction && <div style={{paddingLeft:29, textAlign:"left", fontSize:10}}>{transaction.initiator?'Manual':'Automatic'}</div>}
                </div>
			},
        },
        {
            title: 'Actions',
            key: 'actions',
            width:"160px",
			fixed: 'right',
            render: function(text, record) {
                const handleMenuClick = (e) => {

                    if(!e.key){
                        openInvoice(record.id)
                    }
                    else if(e.key == 'email-receipt'){
                        resendReceipt(record.id);
                    }
                    else if(e.key == 'email-paymentFailed1'){
                        resendPaymentFailed(record.id,1);
                    }
                    else if(e.key == 'email-paymentFailed2'){
                        resendPaymentFailed(record.id,2);
                    }
                    else if(e.key == 'email-paymentFailed3'){
                        resendPaymentFailed(record.id,3);
                    }
                    else if(e.key == 'email-paymentOverdue1'){
                        resendOverdueRegistration(record.id,1);
                    }
                    else if(e.key == 'email-paymentOverdue2'){
                        resendOverdueRegistration(record.id,2);
                    }
                    else if(e.key == 'email-paymentDept'){
                        resendDebtCollection(record.id);
                    }
                    else if(e.key == 'sms-paymentFailed'){
                        resendPaymentFailedSMS(record.id);
                    }
                    else if(e.key == 'pay'){
                        payInvoice(record.id);
                    }
                    else {

                    }
                };

                const items = [];

                if(record.paid == 1 || record.status == 'Overdue') {

                    const emailChildren = [];
                    if(record.paid == 1) {
                        emailChildren.push({
                            label: 'Resend Receipt',
                            key: 'email-receipt'
                        });
                    }
                    if(record.status == 'Overdue'){
                        emailChildren.push({
                            label: 'Resend Payment Failed 1',
                            key: 'email-paymentFailed1'
                        });
                        emailChildren.push({
                            label: 'Resend Payment Failed 2',
                            key: 'email-paymentFailed2'
                        });
                        emailChildren.push({
                            label: 'Resend Payment Failed 3',
                            key: 'email-paymentFailed3'
                        });

                        emailChildren.push({
                            label: 'Resend Overdue 1',
                            key: 'email-paymentOverdue1'
                        });
                        emailChildren.push({
                            label: 'Resend Overdue 2',
                            key: 'email-paymentOverdue2'
                        });

                        emailChildren.push({
                            label: 'Resend Dept Collection',
                            key: 'email-paymentDept'
                        });

                        emailChildren.push({
                            label: <div style={{borderTop:"1px solid lightgray",margin:"0px 20px", height:2}}>&nbsp;</div>
                        });

                        emailChildren.push({
                            label: 'Send Payment Failed SMS',
                            key: 'sms-paymentFailed'
                        });
                    }

                    items.push({
                        label: 'Emails',
                        key: 'emails',
                        icon: <i className="far fa-at"/>,
                        children: emailChildren
                    });

                    
                }

                if(!record.paid && !['cancelled','deregistered'].includes(record.subscription.status)) {
                    items.push({
                        label: 'Pay Now',
                        key: 'pay',
                        icon: <i className="fas fa-dollar-sign"/>,
                        className:"btn-green"
                    });
                }



                const menu = (
                    <Menu
                        onClick={handleMenuClick}
                        items={items}
                    />
                );

                return <div style={{display: "flex", gap: 10}}>

                    <Dropdown overlay={menu}>
                        <Button
                            onClick={handleMenuClick}
                            type="primary"
                            shape="round"
                            icon={<i className="far fa-edit"/>}
                        >
                            <Space>
                                <div style={{width:62}}>&nbsp;Edit</div>
                                <DownOutlined/>
                            </Space>
                        </Button>
                    </Dropdown>
                </div>;
            }
        },
    ];
    const subscriptionColumns = [
        {
            title: 'Plan',
            key: 'plan',
            dataIndex: 'plan',
            align:'left',
            render: (text, record) => (
                <div>
                    <div style={{flex:1}}>{record.plan.for[0].toUpperCase()+record.plan.for.substr(1)} - {record.plan.name} {(record.plan.starts_at)?<Moment format=" (MMM YY)" unix>{record.plan.starts_at}</Moment>:''}</div>
                    <div style={{marginTop:20}}>

                        {record.aweber_subscriber_id &&
                        <a href={"https://www.aweber.com/users/leads"} target="_blank">
                            <img style={{width:40}} src="/images/icons/aweber.png"/>
                        </a>
                        }
                        {record.stripe_customer_id &&
                        <a href={"https://dashboard.stripe.com/customers/" + record.stripe_customer_id} target="_blank">
                            <img style={{width: 40}} src="/images/icons/stripe.png"/>
                        </a>
                        }
                    </div>
                </div>
            )
        },
		{
			title: 'Generation',
			key: 'generation',
			dataIndex: 'generation',
			align:'current',
			width:110
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			align:'current',
			width:"170px",
			render: (text, record) => {
				return <div style={{textTransform:"capitalize"}}>{record.status}</div>;
			}
		},
		{
			title: 'Renew',
			key: 'do_not_renew',
			dataIndex: 'do_not_renew',
			align:'center',
			width:"75px",
			render: (text, record) => {
			    if(!record.plan.auto_renew) return <i className="fa fa-times" style={{color:"lightgrey"}} title="Not Possible"/>;
			    if(record.is_old){
					if(record.do_not_renew) return <i className="fa fa-times" style={{color:"lightgrey"}} title="Not Renewing"/>;
					return <i className="fa fa-check" style={{color:"lightgrey"}} title="Renewing"/>
                }else{
					if(record.do_not_renew) return <i className="fa fa-times" style={{color:"red"}} title="Not Renewing"/>;
					return <i className="fa fa-check" style={{color:"#32a5f3"}} title="Renewing"/>
                }

			}
		},
        {
            title: 'Discounts',
            key: 'discount',
            dataIndex: 'discount',
            align:'left',
            width:200,
            render: function(text, record) {
                return record.discounts.map(discount =>
                    <div>{discount.name}</div>
                );
            }
        },

        {
            title: 'Actions',
            key: 'actions',
            width:"170px",
			className:"va-middle",
			fixed: 'right',
            render: (text, record) => {

                const handleMenuClick = (e) => {
                    if(!e.key){
                        openSubscriptionModal(record);
                    }
                    else if(e.key == 'stripe'){
                        window.open("https://dashboard.stripe.com/customers/"+record.stripe_customer_id, '_blank').focus();
                    }
                    else if(e.key == 'email-welcome'){
                        resendStudentWelcome(record.id);
                    }
                    else if(e.key == 'email-new-student'){
                        resendNewStudent(record.id);
                    }
					else if(e.key == 'email-deregister'){
						resendDeregisterRequest(record.id);
					}
					else if(e.key == 'email-renewalReminder'){
						resendRenewalReminderRequest(record.id);
					}
                    else if(e.key == 'deregister'){
                        setDeregisterSubscription(record);
                    }
                    else if(e.key == 'zappier'){
                        resendZappier(record.id);
                    }
                    else {
                        console.log('click', e);
                    }
                };

                const items = [];




				if(record.plan.for == 'member') {
					items.push({
						label: 'Deregister',
						key: 'deregister',
						icon: <i className="fal fa-file-signature"/>
					});

					let period = 'CURRENT';
					if(record.is_pending ){
						period = 'PENDING';
					}
					else if(record.is_old){
						period = 'OLD';
					}

					let children = [
						{
							label: 'Renewal Reminder Request',
							key: 'email-renewalReminder'
						}
					];

					if (record.dereg_data) {
						if (record.dereg_data){
							children.push({
								label: 'Deregister Request',
								key: 'email-deregister'
							});
						}
					}

					items.push({
						label: 'Emails',
						key: 'emails',
						icon: <i className="far fa-at"/>,
						children: children
					});

                    
				}
                else if(record.plan.for == 'student') {
                    items.push({
                        label: 'Emails',
                        key: 'emails',
                        icon: <i className="far fa-at"/>,
                        children: [
                            {
                                label: 'Welcome',
                                key: 'email-welcome'
                            },{
                                label: 'New Student',
                                key: 'email-new-student'
                            }
                        ]
                    });
                }

                items.push({
                    label: 'Resend Zappier',
                    key: 'zappier',
                    icon: <i className="fad fa-bolt"/>
                });

                const menu = (
                    <Menu
                        onClick={handleMenuClick}
                        items={items}
                    />
                );

                return <div style={{display: "flex", gap: 10}}>

                    {!!items.length &&
                    <Dropdown overlay={menu}>
                        <Button
                            onClick={handleMenuClick}
                            type="primary"
                            shape="round"
                            icon={<i className="far fa-edit"/>}
                        >
                            <Space>
                                <div style={{width: 62}}>&nbsp;Edit</div>
                                <DownOutlined/>
                            </Space>
                        </Button>
                    </Dropdown>
                    }
                    {!items.length &&
                        <Button
                            onClick={handleMenuClick}
                            type="primary"
                            shape="round"
                            icon={<i className="far fa-edit"/>}
                            style={{width:138, display:"flex", alignItems:"center"}}
                        >
                            <Space>
                                <div style={{width: 62}}>&nbsp;Edit</div>
                            </Space>
                        </Button>
                    }
                </div>
            },
        },
    ];
	const subscriptionChildrenColumns = [
		{
			title: 'Start At',
			key: 'starts_at',
			dataIndex: 'starts_at',
			align:'left',
			sorter: true,
			defaultSortOrder: 'descend',
			render: (text, record) => (
				<Moment format="Do MMM YYYY" unix>{record.starts_at}</Moment>
			),
		},
		{
			title: 'Generation',
			key: 'generation',
			dataIndex: 'generation',
			align:'center',
			width:100
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			align:'center',
			width:150,
			render: (text, record) => {
				let period = 'CURRENT';
				if(record.is_pending ){
					period = 'PENDING';
				}
				else if(record.is_old){
					period = 'OLD';
				}

				return <div >
					<div style={{textTransform:"capitalize"}}>{record.status}</div>
					<div style={{fontSize:"10px"}}>({period})</div>
				</div>;
			}
		},
        /*
		{
			title: 'Renew',
			key: 'do_not_renew',
			dataIndex: 'do_not_renew',
			align:'center',
			width:"75px",
			render: (text, record) => {
				if(!record.plan.auto_renew) return <i className="fa fa-times" style={{color:"lightgrey"}} title="Not Possible"/>;
				if(record.is_old){
					if(record.do_not_renew) return <i className="fa fa-times" style={{color:"lightgrey"}} title="Not Renewing"/>;
					return <i className="fa fa-check" style={{color:"lightgrey"}} title="Renewing"/>
				}else{
					if(record.do_not_renew) return <i className="fa fa-times" style={{color:"red"}} title="Not Renewing"/>;
					return <i className="fa fa-check" style={{color:"#32a5f3"}} title="Renewing"/>
				}

			}
		},*/
		{
			title: 'Discounts',
			key: 'discount',
			dataIndex: 'discount',
			align:'left',
			render: function(text, record) {
				return record.discounts.map(discount =>
					<div>{discount.name}</div>
				);
			}
		},
		{
			title: 'Actions',
			key: 'actions',
			width:"170px",
			render: (text, record) => {

				const handleMenuClick = (e) => {
					if(!e.key){
						openSubscriptionModal(record);
					}
					else if(e.key == 'stripe'){
						window.open("https://dashboard.stripe.com/customers/"+record.stripe_customer_id, '_blank').focus();
					}
					else if(e.key == 'email-welcome'){
						resendStudentWelcome(record.id);
					}
					else if(e.key == 'email-new-student'){
						resendNewStudent(record.id);
					}
					else if(e.key == 'email-deregister'){
						resendDeregisterRequest(record.id);
					}
					else if(e.key == 'deregister'){
						setDeregisterSubscription(record);
					}
					else {
						console.log('click', e);
					}
				};

				const items = [];

				const menu = (
					<Menu
						onClick={handleMenuClick}
						items={items}
					/>
				);

				return <div style={{display: "flex", gap: 10}}>

					{!!items.length &&
					<Dropdown overlay={menu}>
						<Button
							onClick={handleMenuClick}
							type="primary"
							shape="round"
							icon={<i className="far fa-edit"/>}
						>
							<Space>
								<div style={{width: 62}}>&nbsp;Edit</div>
								<DownOutlined/>
							</Space>
						</Button>
					</Dropdown>
					}
					{!items.length &&
					<Button
						onClick={handleMenuClick}
						type="primary"
						shape="round"
						icon={<i className="far fa-edit"/>}
						style={{width:138, display:"flex", alignItems:"center"}}
					>
						<Space>
							<div style={{width: 62}}>&nbsp;Edit</div>
						</Space>
					</Button>
					}
				</div>
			},
		},
	];
    const transactionColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            align:'left'
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount',
            align:'left',
            width: 100,
            render: (text, record) => (
                <span>${record.amount/100}</span>
            ),
        },
        {
            title: 'Date',
            key: 'created_at',
            dataIndex: 'created_at',
            align:'left',
			width:190,
            render: (text, record) => (
                <Moment format="Do MMM YYYY h:mm a" unix>{record.created_at}</Moment>
            ),
        },
        {
            title: 'Details',
            key: 'details',
            dataIndex: 'details',
            align:'left',
            render: function(text, record) {
                if(record.status == 'success' && record.external_id){
                    return <a href={"https://dashboard.stripe.com/payments/"+record.external_id} target="_blank">
						<img style={{width: 40}} src="/images/icons/stripe.png"/>
					</a>;
                }
                if(record.status != 'failed' || !record.data) return '';

                return <div>
                    <div style={{display:"flex"}}><div style={{width:100}}>Code:</div><div>{record.data.code}</div></div>
                    {record.data.decline_code &&
                    <div style={{display:"flex"}}><div style={{width:100}}>Decline Code:</div><div>{record.data.decline_code}</div></div>
                    }
                </div>
            }
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            align:'left',
            width: 100
        },
		{
			title: 'Actions',
			key: 'actions',
			width: 150,
			render: (text, record) => {
				return <div>
					<Button
						onClick={function(){openTransactionModal(record)}}
						type="primary"
						shape="round"
						icon={<i className="far fa-edit"/>}
						style={{display:"flex", alignItems:"center"}}
					>
						<Space>
							<div style={{width: 62}}>&nbsp;Edit</div>
						</Space>
					</Button>
				</div>
			},
		},
    ];
	const discountColumns = [
		{
			title: 'Name',
			key: ['discount','name'],
			dataIndex: ['discount','name'],
			align:'left'
		},
		{
			title: 'Discount',
			key: 'discount',
			dataIndex: 'discount',
			align:'left',
			width: "200px",
			render: function(text, record) {
				let discount = record.amount;
				if (record.type == 'fixed') {
					return <div>-</div>
				} else {
					discount = (Math.round(discount*100)/100)+'%';
					return <div>{discount}</div>;
				}
			}
		},
		{
			title: 'Amount',
			key: 'amount',
			align:'left',
			width: "200px",
			className:"va-middle",
			render: function(text, record) {
			    let amount;
			    if(record.type == 'fixed') amount = record.amount;
			    else amount = (record.amount/100 * record.parent.amount);

				const formatter = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: record.parent.currency,
					currencyDisplay: 'narrowSymbol'
				});

				return <><span>{(formatter.format(amount/100).replace(/\D00$/, ''))+' '}</span><span style={{fontSize:"10px"}}>{record.parent.currency.toUpperCase()}</span></>;
			}
		}
	];
	const penaltyColumns = [
		{
			title: 'Name',
			key: ['penalty','name'],
			dataIndex: ['penalty','name'],
			align:'left'
		},
		{
			title: 'Amount',
			key: 'amount',
			dataIndex: 'amount',
			align:'left',
			width: "200px",
			render: function(text, record) {
				let amount = record.amount;
				if (record.type == 'fixed') {
					amount = '$' + (amount/100);
				} else {
					amount = amount + '%';
				}
				return <div>{amount}</div>
			}
		}
	];
    const emailColumns = [
        {
            title: 'Type',
            key: 'type',
            dataIndex: 'type',
            align:'left'
        },
        {
            title: 'Success',
            key: 'success',
            dataIndex: 'success',
            align:'left',
            width: "80px",
        },
        {
            title: 'Seen At',
            key: 'seen_at',
            dataIndex: 'seen_at',
            align:'left',
            width: "120px",
            render: (text, record) => (
                <div>
                    {record.seen_at &&
                    <>
                        <div><Moment format="DD/MM/YYYY" unix>{record.seen_at}</Moment></div>
                        <div><Moment format="hh:mm:ss a" unix>{record.seen_at}</Moment></div>
                    </>
                    }
                    {!record.seen_at &&
                    <span>-</span>
                    }
                </div>
            )
        },
        {
            title: 'Date',
            key: 'created_at',
            dataIndex: 'created_at',
            sorter: (a, b) => {return (!a.created_at)?-1:a.date.localeCompare(b.created_at)},
            align:'left',
            width: "200px",
            render: (text, record) => (
                <div>
                    <Moment format="DD/MM/YYYY" unix>{record.created_at}</Moment>
                </div>
            )
        },
    ];

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };



    function payInvoice(invoiceId) {

        confirm({
            title: 'Pay Invoice?',
            icon: <ExclamationCircleOutlined />,
            onOk() {

                AP.post('/invoice/'+invoiceId+'/pay')
                    .then(function (res) {
                        notification.success({
                            message: `Invoice Paid`,
                            placement: 'bottomRight',
                            duration:1,
                            closeIcon : (<div></div>)
                        });
                        setForceRefresh(1);
                    });
            },
            onCancel() {
                return;
            },
        });


    }
    function resendReceipt(invoiceId) {

        AP.post('/invoice/'+invoiceId+'/sendReceipt')
            .then(function (res) {
                notification.success({
                    message: `Email Sent`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
    function resendPaymentFailed(invoiceId, number) {

        AP.post('/invoice/'+invoiceId+'/sendPaymentFailed',{number:number})
            .then(function (res) {
                notification.success({
                    message: `Email Sent`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
    function resendOverdueRegistration(invoiceId, number) {

        AP.post('/invoice/'+invoiceId+'/sendOverdueRegistration',{number:number})
            .then(function (res) {
                notification.success({
                    message: `Email Sent`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
    function resendDebtCollection(invoiceId) {

        AP.post('/invoice/'+invoiceId+'/sendDebtCollection')
            .then(function (res) {
                notification.success({
                    message: `Email Sent`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
    function resendPaymentFailedSMS(invoiceId) {

        AP.post('/invoice/'+invoiceId+'/sendPaymentFailedSMS')
            .then(function (res) {
                notification.success({
                    message: `SMS Sent`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }

    function resendStudentWelcome(subscriptionId) {

        AP.post('/subscription/'+subscriptionId+'/sendWelcome')
            .then(function (res) {
                notification.success({
                    message: `Email Sent`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
    function resendDeregisterRequest(subscriptionId) {

        AP.post('/subscription/'+subscriptionId+'/sendDeregisterRequest')
            .then(function (res) {
                notification.success({
                    message: `Email Sent`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
	function resendRenewalReminderRequest(subscriptionId) {

		AP.post('/subscription/'+subscriptionId+'/sendRenewalReminderRequest')
			.then(function (res) {
				notification.success({
					message: `Email Sent`,
					placement: 'bottomRight',
					duration:1,
					closeIcon : (<div></div>)
				});
			});
	}
    function resendNewStudent(subscriptionId) {

        AP.post('/subscription/'+subscriptionId+'/sendNewStudent')
            .then(function (res) {
                notification.success({
                    message: `Email Sent`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
	function resendZappier(subscriptionId) {

		AP.post('/subscription/'+subscriptionId+'/pushZappier')
			.then(function (res) {
				notification.success({
					message: `Zappier Sent`,
					placement: 'bottomRight',
					duration:1,
					closeIcon : (<div></div>)
				});
			});
	}

    

    function openInvoice(invoiceId) {
        setInvoiceId(invoiceId);
        setModalIsOpen(true);
    }
    function closeInvoiceModal() {
        setForceRefresh(forceRefresh+1);
        setModalIsOpen(false);
    }

    function openSubscriptionModal(subscription){
        setSubscription(subscription);
        setSubscriptionModalIsOpen(true);
    }
    function closeSubscriptionModal() {
        setSubscriptionModalIsLoading(false);
        setForceSubscriptionReload(Math.random());
        setForceRefresh(Math.random());
        setSubscriptionModalIsOpen(false);
    }
	function openTransactionModal(transaction){
		setTransaction(transaction);
		setTransactionModalIsOpen(true);
	}
	function closeTransactionModal() {
		//setTransactionModalIsLoading(false);
		//setForceInvoiceReload(Math.random());
		setForceRefresh(Math.random());
		setTransactionModalIsOpen(false);
	}
    function openLogEmail() {
        setLogEmailModalIsOpen(true);
    }
    function openCreateEmail() {
        setCreateEmailModalIsOpen(true);
    }
    function closeLogEmailModal() {
        setLogEmailModalIsOpen(false);
    }
	function closeCreateEmailModal() {
		setForceEmailsReload(Math.random());
		setCreateEmailModalIsOpen(false);
	}
	function closeDeregisterModal() {
		setDeregisterSubscription(false);
		setForceSubscriptionReload(Math.random());
		setForceRefresh(Math.random());
	}

    const noteTextArea = useRef(null);
    const [noteText, setNoteText] = React.useState("");
    function addNote(){
        setAddingNote(true);
    }
    function cancelNote(){
        setAddingNote(false);
        setNoteText("");
    }
    function saveNote(){
        AP.post('/note',{text:noteText,link:"user",link_id:user.id})
            .then(function (res) {

                notes.push(res);
                setNotes([...notes]);

                setNoteText("");
                setAddingNote(false);

                notification.success({
                    message: `Note Saved`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
    function deleteNote(id){
        AP.delete('/note/'+id)
            .then(function (res) {

                for( var i = 0; i < notes.length; i++){
                    if ( notes[i].id === id) {
                        notes.splice(i, 1);
                    }
                }

                setNotes([...notes]);


                notification.success({
                    message: `Note Deleted`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
    function noteTextChange(event){
        setNoteText(event.target.value);
    };

    function loginAs() {
        AP.post('/loginAs', {user_id:user.id})
            .then(function (res) {
                setLoginAsUser(res.user, res.access_token);
                window.location.replace("/dashboard");
            });
    }

    function toggleInvoiceFilter(subscription){
        if(invoiceFilter === subscription){
            setInvoiceFilter("");
        }else{
            setInvoiceFilter(subscription);
        }
    }
    function redoInvoices(subscriptionId){

        confirm({
            title: 'Are you sure you want rebuild invoices? This will replace all primary, unpaid invoices.',
            icon: <ExclamationCircleOutlined />,
            onOk() {

                // setIsSaving(true);

                let url = '/subscription/'+subscriptionId+'/rebuildInvoices';

                AP.get(url).then(function(res){

                    notification.success({
                        message: `Invoices Rebuilt`,
                        placement: 'bottomRight',
                        duration:1,
                        closeIcon : (<div></div>)
                    });

                    setForceRefresh(forceRefresh+1);
                    // setIsSaving(false);

                    //props.onFinish({id:props.discount.id,delete:true});

                });



            },
            onCancel() {
                return;
            },
        });
    }

    return(
        <div style={width>1900?{marginRight:350}:{}}>
            <div className="content_inner">

				{!!isLoading && <Spin/>}
				{!isLoading && <>
					{user && user.id &&
					<>

						<div style={{display:"flex", paddingBottom:10}}>
							<div><h3>{user.name}</h3></div>
							<div style={{marginLeft:"auto"}}>Member# <strong>{user.id.toString().padStart(7,'0')}</strong></div>
						</div>

						<div style={{display:"flex", flexWrap:"wrap"}}>
							<div style={{flex:999, paddingBottom:50, maxWidth:"100%"}}>
								<Tabs
									defaultActiveKey={defaultTab}
									centered
									onChange={key => {
										props.history.replace({ pathname: "/user/"+id+"/"+key});
									}}
									renderTabBar={(props, TabNavList) => (
										<TabNavList {...props} mobile={false} />
									)}
                                    className="tabs-bb"
								>

									<TabPane tab="General Info" key="general">

										<div style={{display:"",paddingTop:20}}>
											<div className="basicInfo" style={{display:"flex", gap:20, justifyContent:"center", flexWrap:"wrap"}}>

												<div className="dashboard-card" style={{flex:1,overflow:"hidden",minWidth:width<760?"100%":250}}>
													<div className="card-body">
														<h4>Address:</h4>
														<div>
															<div>{user.address}</div>
															<div>{user.suburb} {user.state} {user.postcode}</div>
															<div>{user.country}</div>
														</div>
													</div>
												</div>

												<div className="dashboard-card" style={{flex:1,overflow:"hidden",minWidth:width<760?"100%":250}}>
													<div className="card-body">
														<h4>Email address:</h4>
														<div>
															{user.email}
														</div>
													</div>
												</div>

												<div className="dashboard-card" style={{flex:1,overflow:"hidden",minWidth:width<760?"100%":250}}>
													<div className="card-body">
														<h4>Phone:</h4>
														<div>
															{(user.mobile)?user.mobile:'-'}
														</div>
													</div>
												</div>

											</div>
											<div className="subscriptionInfo" style={{flex:1, paddingTop:20}}>
												<UserMembershipForm user={user}/>
											</div>
										</div>


									</TabPane>
									<TabPane tab="Personal Details" key="personal">
										<UserForm user={user} {...props}/>
									</TabPane>
									<TabPane tab="Subscriptions" key="subscriptions">

										<div style={{paddingTop: 50}}>

											<div style={{float: "right", position: "relative", top: -40, display:"flex"}}>
												<Button icon={<i className="fal fa-file-invoice-dollar"></i>} onClick={() => openSubscriptionModal(false)}>
													&nbsp;Create Subscription
												</Button>
											</div>

											<SearchTable
												columns={subscriptionColumns}
												scroll={{ x: 930 }}
												url={"/user/" + id + "/subscriptions"}
												className={"subscriptionTable"}
												//filters={{showOld: showOldSubscriptions}}
												forceReload={forceSubscriptionReload}
												rowClassName={(record, index) => (!record.is_pending && !record.is_old)?"boldRow":""}
												formatData={function(data){
													const rows = {};
													for(const d of data){
														if(!rows[d.plan.for]){
															rows[d.plan.for] = {latest:null, latestActive:null, children:[]};
														}

														if(!rows[d.plan.for].latest || rows[d.plan.for].latest.starts_at < d.starts_at) rows[d.plan.for].latest = d;
                                                        const isActive = ['active','debt','hybernating','on-hold','pending-cancel','pending-deregister'].includes(d.status);
														if(isActive && (!rows[d.plan.for].latestActive || rows[d.plan.for].latestActive.starts_at < d.starts_at)) rows[d.plan.for].latestActive = d;
														rows[d.plan.for].children.push(d);
													}

													const formattedData = [];
													for (let [f, r] of Object.entries(rows)) {
														let row; 
                                                        if(r.latestActive) row = {...r.latestActive};
                                                        else row = {...r.latest};
														row.kids = r.children;
														formattedData.push(row);
													}

													return formattedData;
												}}

												expandable={{
													expandedRowRender: function(record){

                                                        return <div style={{padding:20}}>
                                                            <div style={{textAlign:"left"}}>
                                                                <h4 className="tableTitle">All Subscriptions</h4>
                                                            </div>
                                                            <Table
                                                                columns={subscriptionChildrenColumns}
                                                                dataSource={record.kids}
                                                                className={"subscriptionTable"}
                                                                rowClassName={(record, index) => (!record.is_pending && !record.is_old)?"boldRow":""}
                                                            />
                                                        </div>
                                                    },
                                                }}
                                            />
                                    </div>

									</TabPane>
									<TabPane tab="Invoices" key="invoices">

										<div style={{paddingTop:(user.subscriptions.length <= 1)?50:0}}>

											{user.subscriptions.length > 1 &&
                                                <div style={{marginBottom:30}}>
                                                    <div style={{flex:1}}>
                                                        <div style={{display:"flex", paddingBottom:10,justifyContent:"center", minHeight:70, gap:10, flexWrap:"wrap"}}>
                                                            {user.subscriptions.map((subscription) => (


                                                                    <Button
                                                                        type="button"
                                                                        className={"ant-btn ant-btn-round " + (subscription === invoiceFilter ? "ant-btn-primary" : "ant-btn-default") + " ant-btn-lg shadow-light"}
                                                                        style={{height:46, borderRadius:(subscription.plan.for=='member'?10:40)}}
                                                                        onClick={() => toggleInvoiceFilter(subscription)}
                                                                    >
                                                                        <div>{subscription.plan.name}</div>
                                                                        <div style={{fontSize:10,lineHeight:"6px"}}>
                                                                            <span>{subscription.plan.for}</span>
																			<span>
                                                                                {(subscription.plan.starts_at)
                                                                                    ?<Moment format=" (MMM YYYY)" unix>{subscription.plan.starts_at}</Moment>
                                                                                    :<Moment format=" (YYYY)" unix>{subscription.starts_at}</Moment>
                                                                                }
                                                                            </span>

                                                                        </div>
                                                                    </Button>


                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
											}

											<div>
												<div style={{display:"flex"}}>
													<Button
														icon={<i className="fal fa-file-invoice-dollar"></i>}
														onClick={() => openInvoice(false)}
														style={{marginLeft:"auto"}}
													>
														&nbsp;Create Invoice
													</Button>
												</div>

												<SearchTable
													columns={invoiceColumns}
													scroll={{ x: 930 }}
													url={"/user/"+id+"/invoices"}
													className={"invoiceTable"}
													forceReload={forceRefresh}
													pagination={{pageSize:999}}
													filters={{subscriptionId:(invoiceFilter)?invoiceFilter.id:""}}
													expandable={{
														expandedRowRender: function(record){

														for(let iDiscount of record.i_discounts){
															iDiscount.baseAmount = record.baseAmount;
														}
														for(let transaction of record.transactions){
															transaction.subscription_id = record.subscription_id;
															transaction.invoice_id = record.id;
														}

															return <div style={{padding:10}}>

															{((!!record.transactions.length) && <div>
																	<h4 className="tableTitle">Transactions</h4>
																	<Table
																		dataSource={record.transactions}
																		tableLayout="fixed"
																		pagination={false}
																		rowKey="id"
																		columns={transactionColumns}
																		style={{flex:1}}
																	/>
																</div>
															)}

															{((!!record.i_discounts.length) && <div style={{paddingTop:20}}>
																	<h4 className="tableTitle">Discounts</h4>
																	<Table
																		dataSource={record.i_discounts.map((iDiscount)=>{iDiscount.parent = record; return iDiscount})}
																		tableLayout="fixed"
																		pagination={false}
																		rowKey="id"
																		columns={discountColumns}
																		style={{flex:1}}
																	/>
																</div>
															)}

															{((!!record.i_penalties.length) && <div style={{paddingTop:20}}>
																	<h4 className="tableTitle">Penalties</h4>
																	<Table
																		dataSource={record.i_penalties}
																		tableLayout="fixed"
																		pagination={false}
																		rowKey="id"
																		columns={penaltyColumns}
																		style={{flex:1}}
																	/>
																</div>
															)}
														</div>},
												    }}
												/>
											</div>

											{(invoiceFilter || user.subscriptions.length==1) &&
											<div style={{paddingTop: 40}}>
												{user.subscriptions.map((subscription) => {
													if (invoiceFilter && invoiceFilter !== subscription) return "";
													return <Button
														type="secondary"
														danger
														size="small" shape="round"
														onClick={() => redoInvoices(subscription.id)}
													>Rebuild {subscription.plan.name} Invoices</Button>
												})}
											</div>
											}
										</div>

									</TabPane>
									<TabPane tab="Emails" key="emails">

										<div style={{paddingTop: 50}}>

											<div style={{float:"right",position:"relative",top:-40, gap:10, display:"flex"}}>
												<Button type="secondary" title="Track email sent externally" onClick={() => openLogEmail()}><i className="far fa-clipboard-check"/>&nbsp;Log Email</Button>

												<Dropdown overlay={<Menu>
													<Menu.Item key="1" onClick={() => 1}>
														Send ???
													</Menu.Item>
													<Menu.Item key="1" onClick={() => 1}>
														Send ???
													</Menu.Item>
													<Menu.Item key="1" onClick={() => 1}>
														Send ???
													</Menu.Item>
												</Menu>}>
													<Button type="secondary" ><i className="far fa-at"/>&nbsp;Send Email</Button>
												</Dropdown>

												<Button type="secondary" onClick={() => openCreateEmail()}><i className="fal fa-envelope-open-text"/>&nbsp;Create Email</Button>
											</div>

											<SearchTable
												columns={emailColumns}
												url={"/user/"+id+"/emails"}
												className={"emailTable"}
												forceReload={forceEmailsReload}
											/>
										</div>

									</TabPane>

									{user.isMember &&
									<TabPane tab="Registry Settings" key="registry_settings">

										<div style={{paddingTop: 50}}>
											<RegistrySettingsForm user={user} {...props}/>
										</div>

									</TabPane>
									}

									{authUser.hasPermissions("user_permissions") && user.type == 'admin' &&
									<TabPane tab="Permissions" key="permissions">

										<div style={{paddingTop: 50}}>
											<UserPermissionsForm user={user} {...props}/>
										</div>

                                </TabPane>
                                }
                            </Tabs>
                        </div>
                        <div style={
                                width>1900
                                    ?{flex:1, minWidth:210, padding:10, position:"absolute", width:350, right:0, marginTop:29}
                                    :width<1530
                                        ?{flex:1, minWidth:"100%", padding:10}
                                        :{flex:1, minWidth:210, padding:10, marginTop:29}
                        }>

                            <div style={{maxWidth:700, margin:"auto"}}>
                                <div style={{textAlign:"center",paddingTop:10}}><h5>Notes</h5></div>
                                    {notes &&
                                    <div className="notes">
                                        {notes.map(function(note){
                                            return <div className="note">
                                                <div className="note-header">
                                                    <div>{note.user.first_name}</div>
                                                    <div style={{marginLeft:"auto"}}><Moment format="Do MMM YYYY" unix>{note.created_at}</Moment></div>
                                                    <Button type="secondary" size="small"  danger onClick={function(){deleteNote(note.id)}}>
                                                        <i className="fas fa-trash"></i>
                                                    </Button>
                                                </div>
                                                <div style={{padding:10,whiteSpace:"pre-wrap", backgroundColor:"white"}}>{note.text}</div>
                                            </div>
                                        })}
                                    </div>
                                    }
                                    {addingNote &&
                                    <div>

                                        <TextArea rows={4} ref={noteTextArea} value={noteText} onChange={noteTextChange} />
                                        <div style={{display:"flex",paddingTop:5}}>
                                            <Button type="secondary" size="small" shape="round" onClick={() => cancelNote()}>Cancel</Button>
                                            <Button type="primary" size="small" shape="round" onClick={() => saveNote()} style={{marginLeft:"auto"}}><i className="fal fa-share"/>&nbsp;Save</Button>
                                        </div>
                                    </div>
                                    }
                                    {!addingNote &&
                                    <div style={{justifyContent: "center", display: "flex"}}>
                                        <Button type="secondary" size="small" shape="round" onClick={() => addNote()}><i className="far fa-comment-alt"/>&nbsp;Add Note</Button>
                                    </div>
                                    }

                                    <div style={{display:"flex", flexDirection:"column", gap:5, paddingTop:40, borderBottom:"1px solid lightgrey"}}>
                                        {user.type != 'admin' &&
                                        <Button type="primary" className="btn-teal" htmlType="button" onClick={() => loginAs()} shape="round">Login As User</Button>
                                        }
                                        {user.active_campaign_customer_id &&
                                        <Button type="primary" className="btn-brown"
                                                href={"https://sportsnutrition56291.activehosted.com/app/contacts/"+user.active_campaign_customer_id} target="blank"
                                                htmlType="button" shape="round">Active Campaign</Button>
                                        }
                                    </div>
                                </div>
                            </div>
						</div>


						<Modal
							visible={modalIsOpen}
							onOk={() => {invoiceModalForm.submit();}}
							onCancel={closeInvoiceModal}
							title={invoiceId?"Edit Invoice":"Create Invoice"}
							okText="Save"
							cancelText="Close"
							width={800}
							//centered={true}
							footer={
								<div style={{display:"flex"}}>
									<Button key="delete" danger onClick={function(){setInvoiceDelete(Math.random())}}>
										Delete
									</Button>
									<Button key="back" onClick={function(){closeInvoiceModal();}} style={{marginLeft:"auto"}}>
										Close
									</Button>
									<Button key="submit" type="primary" onClick={function(){invoiceModalForm.submit();}}>
										Save
									</Button>
								</div>
							}
						>
							<InvoiceForm form={invoiceModalForm} invoiceId={invoiceId} user={user} onFinish={closeInvoiceModal} triggerDelete={invoiceDelete}/>
						</Modal>

						<Modal
							visible={subscriptionModalIsOpen}
							onOk={() => {setSubscriptionModalIsLoading(true); subscriptionForm.submit();}}
							onCancel={closeSubscriptionModal}
							title={subscription?"Edit Subscription":"Create Subscription"}
							okText="Save"
							cancelText="Close"
							width={600}
							confirmLoading={subscriptionModalIsLoading}
							//centered={true}
						>
							<SubscriptionForm form={subscriptionForm} subscription={subscription} user={user} onFinish={closeSubscriptionModal}/>
						</Modal>

						<Modal
							visible={logEmailModalIsOpen}
							onOk={() => {logEmailModalForm.submit();}}
							onCancel={closeLogEmailModal}
							title="Log Email"
							okText="Save"
							cancelText="Close"
							//centered={true}
						>
							<LogEmailForm form={logEmailModalForm} user={user} onFinish={closeLogEmailModal}/>
						</Modal>

						<Modal
							visible={deregisterSubscription}
							onOk={function(){ deregisterModalForm.submit();}}
							onCancel={()=>{setDeregisterSubscription(false);}}
							title="View Deregister Info"
							okText="Save"
							cancelText="Close"
							width={1200}
							//centered={true}
						>
							<DeregisterForm form={deregisterModalForm} subscriptionId={deregisterSubscription.id} onFinish={closeDeregisterModal}/>
						</Modal>

						<Modal
							visible={createEmailModalIsOpen}
							onOk={function(){ createEmailModalForm.submit();}}
							onCancel={closeCreateEmailModal}
							title="Create Email"
							okText="Save"
							cancelText="Close"
							width={1200}
							//centered={true}
						>
							<SendEmailForm form={createEmailModalForm} user={user} onFinish={closeCreateEmailModal}/>
						</Modal>

						<Modal
							visible={transactionModalIsOpen}
							onOk={function(){ transactionModalForm.submit();}}
							onCancel={closeTransactionModal}
							title="Edit Transaction"
							okText="Save"
							cancelText="Close"
							width={600}
							//centered={true}
						>
							<TransactionForm form={transactionModalForm} transaction={transaction} onFinish={closeTransactionModal}/>
						</Modal>


					</>
					}
					{user && !user.id && user.type != "member" && <UserForm user={user} {...props}/>}
                </>}

            </div>
        </div>
	);
}