import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,Checkbox,
    Select,
    Button,
    DatePicker,
    Spin,
    Table,
    Row,
    Col,
    Tabs,
} from 'antd';


import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { NavLink,useHistory } from "react-router-dom";
import {Modal, notification} from "antd/lib/index";
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import countryList from "react-select-country-list";
import Moment from 'react-moment';

const { Option } = Select;
const { confirm } = Modal;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};


export default function UrlForm(props) {

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();


    const AP = API2();
    useEffect(() => {

        if(props.url) {
            let planIds = [];
            for (let upm of props.url.url_plan_maps) {
                planIds.push(upm.plan_id);
            }
            props.url.plans = planIds;
        }

        form.setFieldsValue(props.url);

    }, [props.url]);

    function saveForm(data){
        if(isSaving) return true;

        setIsSaving(true);



        let url = '/url';
        if(props.url.id) {
            url += '/'+props.url.id;
        }

        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            if(!props.url.id) {
                history.push("/url/"+res.id);
            }
            else if(props.url.id != res.id){
                history.push("/url/"+res.id);
            }
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }
    function doDelete(){

        if(!props.url.id) return false;

        confirm({
            title: 'Are you sure you want to delete the invite URL',
            icon: <ExclamationCircleOutlined />,
            onOk() {

                setIsSaving(true);

                let url = '/url/'+props.url.id;

                AP.delete(url).then(function(res){

                    notification.success({
                        message: `Deleted`,
                        placement: 'bottomRight',
                        duration:1,
                        closeIcon : (<div></div>)
                    });

                    //props.history.replace({ pathname: '/plans'});
                    window.location.replace("/urls");

                }).catch(function(res){
                    setIsSaving(false);

                    if(res.response.data.errors) {
                        let errorList = {};
                        for (let error of res.response.data.errors) {
                            if (!errorList[error.input]) errorList[error.input] = [];
                            errorList[error.input].push(error.message);
                        }
                        for (let [input, errors] of Object.entries(errorList)) {
                            props.form.setFields([
                                {
                                    name: input,
                                    errors: errors,
                                },
                            ]);
                        }
                    }
                });

            },
            onCancel() {
                return;
            },
        });




    }


    function copyURL(){
        navigator.clipboard.writeText(props.url.url);
    }

    const rootContainer = document.getElementById('root');


    return(
        <Form
            {...formItemLayout}
            form={form}
            name="plan"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
			onValuesChange={function(a,b){form.setFieldValue('params', {...b.params}) }}
        >
            <Form.Item
                label="URL Name"
                name="name"
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name="for"
                label="For"
                rules={[
                    {
                        required: true,
                        message: 'Please select',
                    }
                ]}
            >
                <Select
                    placeholder="Select a option and change input text above"
                    getPopupContainer={() => rootContainer}
                >
                    <Option value="member">Member</Option>
                    <Option value="student">Student</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="plans"
                label="Plans"
                style={{paddingTop:20}}
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    getPopupContainer={() => rootContainer}
                >
                    {props.url && props.url.plan_options.map(function(plan){
                        return <Option value={plan.id}>
                            <div style={{display:"flex"}}>
                                <div style={{width:100}}><Moment format="DD/MM/YYYY" unix>{plan.starts_at}</Moment></div>
                                <div>{plan.name}</div>
                            </div>
                        </Option>
                    })}
                </Select>
            </Form.Item>

            <Form.Item
                label="Private URL"
            >
                <Row stype={{display:"flex"}}>
                    <Col flex="1">
                        <Form.Item
                            name="url"
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col><Button type="primary" onClick={copyURL} style={{padding:"17px 25px 18px", height:61, left:-4}}><i className="far fa-copy"/></Button></Col>
                </Row>
            </Form.Item>

            <Form.Item
                name="single_use"
                label="Delete Once Used?"
                valuePropName="checked"
            >
                <Checkbox/>
            </Form.Item>

            <div style={{display:"flex",justifyContent:"center",padding:"20px 0 20px 210px"}}>
                <hr style={{width:"50%", borderColor:"lightgrey"}}/>
            </div>
            <h4>Params</h4>

            <Form.Item
                name={["params", "country"]}
                label="Country"
            >
                <Select
                    showSearch
                    autoComplete="none"
                    filterOption={function(input, option){
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    getPopupContainer={() => rootContainer}
                >
                {countryList().getData().map(item => (
                    <Select.Option key={item.value} value={item.value}>
                        {item.label}
                    </Select.Option>
                ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="First Name"
                name={["params", "first_name"]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Last Name"
                name={["params", "last_name"]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Email"
                name={["params", "email"]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={["params", "coupons"]}
                label="Coupons"
                style={{paddingTop:20}}
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    getPopupContainer={() => rootContainer}
                >
                    {props.url && props.url.coupon_options.map(function(coupon){
                        return <Option value={coupon.id}>{coupon.name}</Option>
                    })}
                </Select>
            </Form.Item>

            <Form.Item
                name={["params", "credits"]}
                label="Credits"
            >
                <Select
                    placeholder=""
                    getPopupContainer={() => rootContainer}
                >
                    <Option value="0">None</Option>
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                    <Option value="6">6</Option>
                    <Option value="7">7</Option>
                    <Option value="8">8</Option>
                    <Option value="9">9</Option>
                    <Option value="10">10</Option>
                    <Option value="11">11</Option>
                    <Option value="12">12</Option>
                </Select>
            </Form.Item>


            <div style={{display:"flex",gap:20,justifyContent:"flex-end", paddingTop:20}}>

                {props.url.id &&
                <Button
                    key="delete"
                    danger
                    onClick={function () {
                        doDelete()
                    }}
                    style={{marginRight: "auto"}}
                >
                    Delete
                </Button>
                }

                <Button type="primary" htmlType="submit" style={{float:"right"}} loading={isSaving} style={{float:"right"}}>{(isSaving)?'Saving':'Save'}</Button>
            </div>


        </Form>
	);
}


