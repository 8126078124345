import React, {useContext, useEffect, useState} from 'react';
import API2 from "../../components/api2";
import UrlForm from "../../forms/Url"
import {NavLink, useParams} from "react-router-dom";

import {
    Tabs,
    Button,
    Spin
} from 'antd';
import {Form} from "antd/lib/index";
import BreadCrumbContext from "../../contexts/breadCrump";
import moment from "moment/moment";

export default function Plan(props) {

    let id = props.match.params.urlid;
    if(id == 'new') id = null;


    let defaultTab = "1";

    const AP = API2();

    const [url, setUrl] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/urls"><span><i className="fas fa-box-usd"/> Urls</span></NavLink>,
            (id)?<Spin size="small"/>:<span><i className="fas fa-badge-dollar"/> Create Url</span>
        ]);
    }, []);

    useEffect(() => {
        if(id) {
            AP.get('/url/' + id)
                .then(function (res) {

                    setBreadCrumbs([
                        <NavLink to="/urls"><span><i className="fas fa-box-usd"/> Urls</span></NavLink>,
                        <span><i className="fas fa-badge-dollar"/> {res.name}</span>
                    ]);

                    setUrl(res);
                    setIsLoading(false);
                });
        }else{
            let planOptions = [];
            let couponOptions = [];

            AP.get('/plans?pageSize=9999&showPrivate=true&showDisabled=false')
                .then(function (res) {
                    planOptions = res.data;
                    setUrl({
                        'plan_options':planOptions,
                        'coupon_options':couponOptions,
                        'url_plan_maps' : []
                    });
                    setIsLoading(false);
                });

            AP.get('/coupons?pageSize=9999')
                .then(function (res) {
                    couponOptions = res.data;
                    setUrl({
                        'plan_options':planOptions,
                        'coupon_options':couponOptions,
                        'url_plan_maps' : []
                    });
                    setIsLoading(false);
                });


            setIsLoading(false);
        }
    }, []);

    return(
        <div className="content_inner">

            <UrlForm url={url}/>

        </div>
	);
}