import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input,Checkbox } from 'antd';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import {Modal} from "antd/lib/index";
import { ExclamationCircleOutlined  } from '@ant-design/icons';
import {notification} from "antd/lib/index";

const { confirm } = Modal;

export default function Coupons(props) {

    const AP = API2();
    const setBreadCrumbs = useContext(BreadCrumbContext);
    const [forceReload, setForceReload] = useState(0);
    
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-box-usd"/> Urls</span>
        ]);
	}, []);

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
            sorter: true,
        	align:'left',
            defaultSortOrder: 'ascend',
		},
        {
            title: 'For',
            key: 'for',
            dataIndex: 'for',
            sorter: true,
            align:'left',
            width: '200px'
        },
		{
			title: 'Actions',
            key: 'actions',
            render: (text, record) => (
				<div>
					<span><NavLink to={"/url/"+record.id}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
				</div>
			),
            width: '200px'
		},
	];

    const onSelectOptions = [
        {
            name: "Delete",
            value: "delete",
            onSelect: function(selectedKeys,SelectedData){

                confirm({
                    title: 'Are you sure you want to delete '+(selectedKeys.length)+' urls?',
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                        AP.delete('/urls',{ids:selectedKeys})
                            .then(function(res){
                                notification.success({
                                    message: `Deleted`,
                                    placement: 'bottomRight',
                                    duration:1,
                                    closeIcon : (<div></div>)
                                });

                                setForceReload(forceReload+1);
                            })
                            .catch(function(res){
                                console.log(res);
                            });



                    },
                    onCancel() {
                        return;
                    },
                });
            }
        }
    ];

	return (
        <div className="content_inner">
			<div style={{paddingTop:40}}>
				<div style={{float:"right",position:"relative",top:-40, display:"flex"}}>

					<NavLink to="/url/new" activeClassName="current" style={{marginLeft:"auto"}}>
						<Button icon={<i className="fal fa-box-usd"/>}>&nbsp;Create Url </Button>
					</NavLink>
				</div>

				<SearchTable
					columns={columns}
					url="/urls"
                    pagination={{pageSize:99999}}
                    onSelect={onSelectOptions}
                    forceReload={forceReload}
                />
			</div>
		</div>
	);
};