import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import useToken from './useToken';




export default function PublicRoute({ component: Component, ...rest }) {

	const { token, setToken } = useToken();

	return (
		// Show the component only when the user is logged in
		// Otherwise, redirect the user to /signin page

		<Route {...rest} render={props => (<Component {...props} />)} />
	)
}