import React, {useContext, useEffect, useRef, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment';
import dayjs from "dayjs";
import BreadCrumbContext from "../contexts/breadCrump";
import JoditEditor from "jodit-react";


const { RangePicker } = DatePicker;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};



export default function SendEmailForm(props) {

    const AP = API2();

    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const [editorState, setEditorState] = useState();
    const editor                        = useRef(null);
    const [apiErrors, setApiErrors]     = useState(false);
    const [body, setBody]               = useState();

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    useEffect(() => {

        props.form.resetFields();

        if(props.emailLogId) {
            AP.get('/invoice/'+props.invoiceId)
                .then(function (res) {

                    res.date = [];
                    if (res.start_at) {
                        res.date.push(dayjs(res.start_at * 1000));
                    } else {
                        res.date.push(null);
                    }

                    if (res.end_at) {
                        res.date.push(dayjs(res.end_at * 1000));
                    } else {
                        res.date.push(null);
                    }

                    if(!res.paid) {
                        res.paid = '';
                    }

                    props.form.setFieldsValue(res);
                    setIsLoading(false);
                });
        }else{
            setIsLoading(false);
        }
    }, [props.emailLogId]);


    function saveForm(data){

        setIsSaving(true);

        data.user_id = props.user.id;

        let url = '/email/send';

        AP.post(url,data).then(function(res){

            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            props.onFinish();
        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    props.form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }

    return(
        <Form
            id="myForm"
            {...formItemLayout}
            form={props.form}
            name="emailLog"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                name="subject"
                label="Subject"
                rules={[
                    {
                        required: true,
                        message: 'Required!',
                    }
                ]}
            >
                <Input />
            </Form.Item>


            <Form.Item
                label="Body"
            >
                <div style={{backgroundColor:"white"}}>
                    <Form.Item
                        name="body"
                        rules={[{ required: false, whitespace: true }]}
                    >
                        <JoditEditor
                            ref={editor}
                            value={body}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={function(newContent){ return setBody(newContent)}}
                            onChange={newContent => {}}
                        />
                    </Form.Item>

                </div>

            </Form.Item>

        </Form>
	);
}