import React, {Component, useContext, useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import BreadCrumbContext from "../contexts/breadCrump";
import API from "../components/api";

import { UserOutlined } from '@ant-design/icons';

import API2 from "../components/api2";
import {notification} from "antd/lib/index";
import useToken from "../hooks/useToken";
import Moment from 'react-moment';
import SearchTable from "../components/SearchTable";
import { ExclamationCircleOutlined  } from '@ant-design/icons';

import {
    Tabs,
    Form,
    Select,
    Button,
    Modal,
	Table,
} from 'antd';

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Option } = Select;



export default function Financial(props) {

	let id = props.match.params.id;
	let defaultTab = (props.location.pathname === "/subscriptions/invoices")?"invoices":"subscriptions";

    const [overview, setOverview] = useState(false);
	const [form] = Form.useForm();
    const AP = API2();
	const setBreadCrumbs = useContext(BreadCrumbContext);

    const [formData, setFormData] = useState([]);
    const { user, setUser } = useToken();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [files, setFiles] = useState([]);
    const [forceRefresh, setForceRefresh] = React.useState(0);

    const [paymentMethod, setPaymentMethod]   = useState(null);
    const [cardCvc,    setCardCvc]    = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardFocus,  setCardFocus]  = useState('');
    const [cardName,   setCardName]   = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardIssuer , setCardIssuer ] = useState('');
    const [cardPreview , setCardPreview ] = useState(false);

	useEffect(() => {

        AP.get('/profile')
            .then(function(res){
                setUser(res);
                form.setFieldsValue(res);


                if(res.payment_method) {
                    setPaymentMethod(res.payment_method);

                    setCardPreview(true);
                    setCardNumber('************'+res.payment_method.last4);
                    setCardIssuer(res.payment_method.brand);

                    setCardExpiry(res.payment_method.exp_month+'/'+res.payment_method.exp_year);
                }

                setPaymentMethod(res.payment_method);
                setIsLoading(false);
            });

    }, []);

	useEffect(() => {
		setBreadCrumbs([
			<div><i className="fas fa-users"></i> Subscriptions</div>
		]);
	}, [user]);

    function onFormChange(changedFields, allFields){

        let name  = changedFields[0].name[0];
        let value = changedFields[0].value;

        if(!value) value = '';
        if(name=='cardcvc')    setCardCvc(value);
        if(name=='cardexpiry') setCardExpiry(value);
        if(name=='cardname')   setCardName(value);
        if(name=='cardnumber') setCardNumber(value);
    }
    function clearCard(){
        setCardPreview(false);
        setCardExpiry('');
        setCardNumber('');
    }

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		},
	};

    function payInvoice(invoiceId) {

        confirm({
            title: 'Are you sure you want to process this payment now?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                AP.post('/invoice/'+invoiceId+'/pay')
                    .then(function (res) {
                        if(res.status == "success"){
                            notification.success({
                                message: `Invoice Paid`,
                                placement: 'bottomRight',
                                duration:1,
                                closeIcon : (<div></div>)
                            });
                        }else{
                            notification.error({
                                message: `Payment Failed`,
                                placement: 'bottomRight',
                                duration:1,
                                closeIcon : (<div></div>)
                            });
                        }
                        setForceRefresh(forceRefresh+1);
                    });

            },
            onCancel() {
                return;
            },
        });


    }

    function saveForm(data){

        if(paymentMethod){
            if(paymentMethod=='deleted'){
                data.payment_method = 'deleted';
            }else{
                data.payment_method = true;
            }
        }else{
            data.payment_method = false;
        }

        AP.post('/financial',data).then(function(resp){
            setUser(resp);
        });

        notification.success({
            message: `Saved`,
            placement: 'bottomRight',
            duration:1,
            closeIcon : (<div></div>)
        });


    }

    const columns = [
        {
            title: 'Number',
            key: 'number',
            dataIndex: 'number',
            align:'center',
            width:80,
            fixed:'left'
        },
        {
            title: 'Plan',
            key: 'plan',
            dataIndex: 'plan',
            align:'left',
            fixed:'left',
            render: (text, record) => (
                <span>{record.subscription.plan.name}</span>
            )
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount',
            align:'left',
            width:130,
            render: (text, record) => {
				const origAmount = record.amount;
				let amount = record.amount;

				if(record.i_discounts.length){
					for(const p of record.i_discounts){
						if(p.type=='fixed') amount -= parseFloat(p.amount);
						else if(['percent','fixed percent'].includes(p.type)) amount -= record.amount*parseFloat(p.amount)/100;
					}
				}

                if(record.i_penalties.length){
					for(const p of record.i_penalties){
						if(p.type=='fixed') amount += parseFloat(p.amount);
						else if(['percent','fixed percent'].includes(p.type)) amount += record.amount*parseFloat(p.amount)/100;
					}
				}

				return <span>${Math.round(amount) / 100} {record.currency.toUpperCase()}</span>
			}
        },
        {
            title: 'Dishonor Fee',
            key: 'late_fee',
            dataIndex: 'late_fee',
            align:'center',
            width:"110px",
            render: function(text, record) {
                if(record.prevent_fees) return <span><i className="fas fa-times" style={{color: "grey"}}/></span>
                return <span>{record.late_fee ? <i className="fas fa-check" style={{color: "green"}}/> : ''}</span>
            }
        },
        {
            title: 'Due State',
            key: 'start_at',
            dataIndex: 'start_at',
            align:'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (text, record) => (
                <Moment format="Do MMM YYYY" unix>{record.start_at}</Moment>
            ),
        },
        {
            title: 'State',
            key: 'status',
            dataIndex: 'status',
			render: (text, record) => {
				let transaction;
				for(const tran of record.transactions){
					if (tran.status == 'success') transaction = tran;
				}

				return <div>

					<div style={{display: "flex"}}>
						<div style={{
							width: 30,
							color: (record.paid) ? 'green' : (record.status == 'Overdue') ? 'red' : 'black'
						}}>
							{record.paid == 1 && <><i className="fas fa-check"></i>&nbsp;</>}
							{!record.paid && record.status == 'Overdue' && <><i className="fas fa-times"></i>&nbsp;</>}
							{!record.paid && record.status != 'Overdue' && <><i className="fas fa-ellipsis-h"/>&nbsp;</>}
						</div>

						<div>{record.status}</div>
					</div>
					{transaction && <div style={{paddingLeft:29, textAlign:"left", fontSize:10}}>{transaction.initiator?'Manual':'Automatic'}</div>}
				</div>
			},
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed:'right',
            render: (text, record) => (
                <div style={{display:"flex",gap:10}}>
                    {!record.paid &&
                    <Button
                        type="secondary"
                        className={record.status=='Overdue'?"btn-green":"btn-gray"}
                        size="small"
                        shape="round"
                        onClick={() => payInvoice(record.id)}
                    >Pay Now</Button>
                    }
                </div>
            ),
        },
    ];
	const subscriptionColumns = [
		{
			title: 'Plan',
			key: 'plan',
			dataIndex: 'plan',
			align:'left',
			fixed: 'left',
			render: (text, record) => (
				<div>{record.plan.for[0].toUpperCase()+record.plan.for.substr(1)} - {record.plan.name}</div>
			)
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			align:'center',
			width:150,
			render: (text, record) => {
				return <div style={{textTransform:"capitalize"}}>{record.status}</div>;
			}
		},
		{
			title: 'Discounts',
			key: 'discount',
			dataIndex: 'discount',
			align:'left',
			width:"150px",
			render: function(text, record) {
				return record.discounts.map(discount =>
					<div>{discount.name}</div>
				);
			}
		},
		{
			title: 'Actions',
			key: 'actions',
			width:150,
			fixed: 'right',
			render: (text, record) => (
				<div style={{display:"flex",gap:10}}>
					{record.plan.for == "member" &&
					<span>
                        <a href={"https://sportsnutritionassociation.com/deregistration-request-form"} target="_blank">
                            <Button type="primary" size="small" shape="round"><i className="far fa-edit"/>&nbsp;Deregister</Button>
                        </a>
                    </span>
					}
				</div>
			),
		},
	];
	const subscriptionChildrenColumns = [
		{
			title: 'Starts At',
			key: 'starts_at',
			dataIndex: 'created_at',
			align:'left',
			sorter: true,
			width:150,
			defaultSortOrder: 'descend',
			render: (text, record) => (
				<Moment format="Do MMM YYYY" unix>{record.starts_at}</Moment>
			),
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			align:'center',
			width:150,
			render: (text, record) => {
				let period = 'CURRENT';
				if(record.is_pending ){
					period = 'PENDING';
				}
				else if(record.is_old){
					period = 'OLD';
				}

				return <div >
					<div style={{textTransform:"capitalize"}}>{record.status}</div>
					<div style={{fontSize:"10px"}}>({period})</div>
				</div>;
			}
		},
		{
			title: 'Discounts',
			key: 'discount',
			dataIndex: 'discount',
			align:'left',
			width:"150px",
			render: function(text, record) {
				return record.discounts.map(discount =>
					<div>{discount.name}</div>
				);
			}
		},
	];

	return (
        <div className="content_inner">
			<Tabs
                defaultActiveKey={defaultTab}
                centered
                onChange={function(tab){
                    if(tab=="subscriptions"){
                        props.history.replace('/subscriptions');
                    }else{
                        props.history.replace('/subscriptions/invoices');
                    }
                }}
            >
                <TabPane tab="Subscriptions" key="subscriptions">

                    <div style={{paddingTop: 50}}>
                        <SearchTable
                            columns={subscriptionColumns}
                            scroll={{ x: "max-content" }}
                            url={"/my/subscriptions"}
							formatData={function(data){
								const rows = {};
								for(const d of data){
									if(!rows[d.plan.for]){
										rows[d.plan.for] = {latest:null, children:[]};
									}

									if(!rows[d.plan.for].latest || rows[d.plan.for].latest.starts_at < d.starts_at) rows[d.plan.for].latest = d;
									rows[d.plan.for].children.push(d);
								}

								const formattedData = [];
								for (let [f, r] of Object.entries(rows)) {
									const row = {...r.latest};
									row.kids = r.children;
									formattedData.push(row);
								}

								return formattedData;
							}}
                            className={"subscriptionTable"}
                            forceReload={forceRefresh}


							expandable={{
								expandedRowRender: function(record){

									return <div style={{padding:20}}>
										<div style={{textAlign:"left"}}>
											<h4>All Subscriptions</h4>
										</div>
										<Table
											columns={subscriptionChildrenColumns}
											dataSource={record.kids}
											className={"subscriptionTable"}
											rowClassName={(record, index) => (!record.is_pending && !record.is_old)?"boldRow":""}
										/>
									</div>
								},
							}}
                        />
                    </div>

                </TabPane>
				<TabPane tab="Invoices" key="invoices">
					<div style={{paddingTop:50}}>
                    	<SearchTable
                            columns={columns}
                            url="/my/invoices"
                            pagination={{pageSize:999}}
                            scroll={{ x: "max-content" }}
                        />
					</div>
				</TabPane>
			</Tabs>
		</div>
	);
}