import React, {useEffect, useRef, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import {notification} from "antd/lib/index";

import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment/moment";


const { RangePicker } = DatePicker;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};



export default function PublicSendEmailForm(props) {

    const AP = API2();

    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const [editorState, setEditorState] = useState();
    const editor                        = useRef(null);
    const [apiErrors, setApiErrors]     = useState(false);
    const [body, setBody]               = useState();
    const [captureValue, setCaptureValue] = useState(false);

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    useEffect(() => {
        props.form.resetFields();
    }, [props.user]);

    function captureOnChange(value) {
        setCaptureValue(value);
    }

    function saveForm(data){

        if(!captureValue){
            return false;
        }

        setIsSaving(true);

        data.capture = captureValue;
        let url = '/nutritionist/'+props.user.id+'/email';

        AP.post(url,data).then(function(res){

            notification.success({
                message: `Email Sent`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            props.form.resetFields();
            props.onFinish();

        }).catch(function(res){
            setIsSaving(false);
        });
    }

    return(
        <Form
            id="myForm"
            {...formItemLayout}
            form={props.form}
            name="emailLog"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: '',
                        whitespace: true
                    }
                ]}
                className="required"
            >
                <Input placeholder="*Your Name" />
            </Form.Item>

            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                        whitespace: true
                    }
                ]}
                className="required"
            >
                <Input placeholder="*Your Email"/>
            </Form.Item>

            <Form.Item
                name="message"
                rules={[
                    {
                        required: true,
                        message: '',
                    }
                ]}
                className="required"
            >
                <Input.TextArea rows={4} placeholder="Type message here"/>
            </Form.Item>


            <div style={{height:100, justifyContent:"center", display:"flex"}}>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC}
                    onChange={captureOnChange}
                />
            </div>

        </Form>
	);
}