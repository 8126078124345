import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {Link} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import moment from 'moment'
import countryList from "react-select-country-list";


export default function EndOfMonths(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-users"/> End Of Month</span>
        ]);
	}, []);



	const columns = [
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            width: "200px",
            sorter: true,
            render: function(text, record) {
                let days = Math.ceil((record.nextPaymentTS-Date.now()/1000)/86400);
                return <div style={{display:"flex",fontSize:30}}>
                    <Moment format="MMM YYYY" unix style={{flex:1}}>{record.date}</Moment>
                </div>
            }
        },
        {
            title: 'Members',
            key: 'monthly_members',
            width: "150px",
            render: function(text, record) {
                return <div>
                    <div>Monthly:{record.members.monthly}</div>
                    <div>Annual:{record.members.yearly}</div>
                </div>
            }
        },
        /*
        {
            title: 'Predicted Revenue',
            key: 'predicted_revenue',
            width: "150px",
            render: function(text, record) {
                return <div>
                    <div>Monthly:</div>
                    <div>Annual:</div>
                    <div>Total:</div>
                </div>
            }
        },
        */
        {
            title: 'Actual Revenue',
            key: 'total_predicted_revenue',
            width: "150px",
            render: function(text, record) {
                return <div>
                    <div>Monthly: ${record.actual.monthly/100}</div>
                    <div>Annual:  ${record.actual.yearly/100}</div>
                    <div>Total: ${(record.actual.monthly+record.actual.yearly)/100}</div>
                </div>
            }
        },
        /*
        {
            title: 'Actual Revenue By Region',
            key: 'total_predicted_revenue',
            width: "150px",
            render: function(text, record) {
                return <div>
                    <div>AU:</div>
                    <div>NZ:</div>
                    <div>US:</div>
                    <div>EU:</div>
                    <div>AS:</div>
                </div>
            }
        },
        {
            title: 'Revenue Difference',
            key: 'revenue_difference',
            dataIndex: 'revenue_difference',
            width: "150px"
        },
        */
        {
            title: 'Non Compliant Members',
            key: 'non_compliant_members',
            width: "150px",
            render: function(text, record) {
                return <div>

                </div>
            }
        },

		{
			title: 'Actions',
            key: 'actions',
            width: "200px",
            render: (text, record) => (
				<div>
					<span><Link to={"/endofmonth/"+moment(record.date*1000).format('MM-YYYY')}><Button type="primary" shape="round"><i className="far fa-edit"/></Button></Link></span>
				</div>
			),
		},
	];

	function onSelect(selectedRowKeys: React.Key[], selectedRows: DataType[]){
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }

    const onSelectOptions = [
        {
            name: "Delete",
            value: "delete",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["delete",selectedKeys]);
            }
        },
        {
            name: "Set Active",
            value: "setActive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setActive",selectedKeys]);
            }
        },
        {
            name: "Set Inactive",
            value: "setInactive",
            onSelect: function(selectedKeys,SelectedData){
                console.log(["setInactive",selectedKeys]);
            }
        }
    ];

	return (

        <div className="content_inner" style={{maxWidth:1300,paddingTop:40}}>
            <div style={{float:"right",position:"relative",top:-40}}>
                <Link to="/user/new" activeClassName="current" style={{marginLeft:"auto"}}>
                    <Button icon={<i className="far fa-user"></i>}>&nbsp;Create User </Button>
                </Link>
            </div>

            <SearchTable
                columns={columns}
                url="/endofmonth"
                //onSelect={onSelectOptions}
            />
        </div>
	);
};