import React, {useContext, useEffect, useState} from 'react';
import API2 from "../../components/api2";
import ResourceForm from "../../forms/resource"
import {NavLink, useParams} from "react-router-dom";

import {
    Tabs,
    Button,
    Spin
} from 'antd';
import moment from "moment/moment";
import {Form} from "antd/lib/index";
import BreadCrumbContext from "../../contexts/breadCrump";
import { UserOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;


export default function Resource(props) {

    let resourceId  = props.match.params.resourceId;
    if(resourceId == 'new') resourceId = null;

    let defaultTab = "1";

    const AP = API2();

    const [form]                    = Form.useForm();
    const [resource, setResource]   = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving]   = useState(false);

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/resources"><span><i className="fas fa-users"/> Users</span></NavLink>,
            (resourceId)?<Spin size="small"/>:<span><i className="fas fa-user"/> Create resource</span>
        ]);
    }, []);

    useEffect(() => {
        if(resourceId) {
            AP.get('/resource/' + resourceId)
                .then(function (res) {



                    setBreadCrumbs([
                        <NavLink to="/resources"><span><i className="fas fa-users"/> Resources</span></NavLink>,
                        <span><i className="fas fa-user"/> Edit resource</span>
                    ]);

                    setResource(res);
                    form.setFieldsValue(res);
                    setIsLoading(false);
                });
        }else{
            setResource({});
            setIsLoading(false);
        }
    }, []);

    return(
        <div className="content_inner">

            <ResourceForm resource={resource} {...props}/>

        </div>
	);
}