import './App.css';
import useToken from './hooks/useToken';
import ErrorBoundary from './components/ErrorBoundary'

import Frontend from './layout/frontend'
import Backend from './layout/backend'
import Registry from './layout/registry'
//import 'antd/dist/antd.css';
import 'antd/dist/reset.css';

import NoLayout from "./layout/noend";

function App() {
	const { user, setUser } = useToken();

    let host = window.location.hostname;
    const isRegistry = ['sna.local','www.sportsnutritionistregistry.com','staging.sportsnutritionistregistry.com'].includes(host);

    if(window.location.hostname == 'localhost'){
        const favicon = document.getElementById('favicon');
        favicon.href = '/images/favicon_local.png';
    }

    let pathName = window.location.pathname;
    if(pathName.substr(-1) == '/'){
        pathName = pathName.substr(0,pathName.length-1);
    }

    let publicPage = false;
    if(['/enrol','/signUp','/nutritionists'].includes(pathName)){
        publicPage = true;
    }

    let mainComponent;

    if(isRegistry){
        mainComponent = <Registry/>;
	}else {

        if (window.location.pathname.substring(0, 8) === '/iframe/') {
            mainComponent = <NoLayout/>;
        }
        else if (user && !publicPage) {
            mainComponent = <Backend setUser={setUser}/>;
        } else {
            mainComponent = <Frontend/>;
        }
    }
   

    return <ErrorBoundary>
        {mainComponent}
    </ErrorBoundary>
}

export default App;

/*
<NavLink>
			<div>
				<ul className="header">
					<li><NavLink  exact to="/">Home</NavLink ></li>
					<li><NavLink  to="/login">Login</NavLink ></li>
				</ul>
				<div className="content">
					<Route exact path="/" component={Home}/>
					<Route path="/login/:type?" component={Login}/>
				</div>
			</div>
		</NavLink>
 */