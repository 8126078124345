import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin,
    Modal,
    Checkbox
} from 'antd';

import { UserOutlined,ExclamationCircleOutlined  } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification, Tabs} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";


const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 9 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
    },
};



export default function PenaltyForm(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [coupons, setCoupons] = useState([]);

    const AP = API2();
    useEffect(() => {

        props.form.resetFields();



        if(props.penalty) {
            let data = props.penalty;

            if (!props.penalty.affects) data.affects = "";
            if((!props.penalty.type || props.penalty.type=="fixed") && props.penalty.amount) data.amount /= 100;

            props.form.setFieldsValue(data);
        }



    }, [props.penalty]);

    useEffect(() => {
        if(props.triggerDelete) deleteForm();
    }, [props.triggerDelete]);


    function saveForm(data){
        setIsSaving(true);

        if((!data.type || data.type=="fixed") && data.amount) data.amount *= 100;

        let url = '/penalty';
        if(props.penalty.id) {
            url += '/'+props.penalty.id;
        }else{
            data.subscription_id = props.subscription.id;
        }
        AP.post(url,data).then(function(res){

            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            props.onFinish(res);

        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    props.form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }
    function deleteForm(){

        if(!props.penalty.id) return false;

        let updateInvoice = true;

        confirm({
            title: 'Are you sure you want to delete the penalty',
            icon: <ExclamationCircleOutlined />,
            onOk() {

                setIsSaving(true);

                let url = '/penalty/'+props.penalty.id+'?updateInvoice='+updateInvoice;

                AP.delete(url).then(function(res){

                    notification.success({
                        message: `Deleted`,
                        placement: 'bottomRight',
                        duration:1,
                        closeIcon : (<div></div>)
                    });


                    setIsSaving(false);
                    props.onFinish({id:props.penalty.id,delete:true});

                }).catch(function(res){
                    setIsSaving(false);

                    if(res.response.data.errors) {
                        let errorList = {};
                        for (let error of res.response.data.errors) {
                            if (!errorList[error.input]) errorList[error.input] = [];
                            errorList[error.input].push(error.message);
                        }
                        for (let [input, errors] of Object.entries(errorList)) {
                            props.form.setFields([
                                {
                                    name: input,
                                    errors: errors,
                                },
                            ]);
                        }
                    }
                });



            },
            onCancel() {
                return;
            },
        });



    }


    return(
        <Form
            {...formItemLayout}
            form={props.form}
            name="penalty"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                name="name"
                label="Name"
            >
                <Input />
            </Form.Item>





            <Form.Item
                name="amount"
                label="Amount"
                rules={[
                    {
                        required: true,
                        message: 'Required',
                    }
                ]}
            >
				<Input addonBefore="$" addonAfter={null}/>
            </Form.Item>

			<Form.Item
				name="period"
				label="Period"
				rules={[
					{
						required: true,
						message: 'Required',
					}
				]}
			>
				<Select
					placeholder="Select a period"
				>
					<Option value="payment">As Part Of Regular Payments</Option>
					<Option value="daily">Daily</Option>
					<Option value="weekly">Weekly</Option>
					<Option value="monthly">Monthly</Option>
					<Option value="yearly">Yearly</Option>
				</Select>
			</Form.Item>

			<Form.Item
				name="uses"
				label="# Of Usages"
			>
				<Input
					placeholder="Leave empty to have unlimited uses"
				/>
			</Form.Item>



			<Form.Item
				name="retroactive"
				label="Applies Retroactively"
				valuePropName="checked"
				style={{margin:0}}
			>
				<Checkbox></Checkbox>
			</Form.Item>

        </Form>
	);
}