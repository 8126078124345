import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin,
    Modal,
    Checkbox
} from 'antd';

import { UserOutlined,ExclamationCircleOutlined  } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";


const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function DiscountForm(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [discount, setDiscounts] = useState({});
    const [coupons, setCoupons] = useState([]);
	const [discountType, setDiscountType] = useState(props.discount.type);

    const AP = API2();
    useEffect(() => {

        props.form.resetFields();



        if(props.discount) {
            let data = props.discount;

            if (!props.discount.affects) data.affects = "";
            if(props.discount.type=="fixed" && props.discount.discount) data.discount /= 100;


            const floatDiscount = parseFloat(data.discount);
            if(data.discount == floatDiscount){
				data.discount = floatDiscount;
            }

            props.form.setFieldsValue(data);
        }



    }, [props.discount]);

    useEffect(() => {
        if(props.triggerDelete) deleteForm();
    }, [props.triggerDelete]);

    useEffect(() => {

        let url = '/coupons?pageSize=9999';
        if( props.subscription){
            url += '&plan_id='+props.subscription.plan.id;
        }

        AP.get(url).then(function(res){
            setCoupons(res.data);
        });

    }, []);




    function saveForm(data){
        setIsSaving(true);

        if(data.type=="fixed" && data.discount) data.discount *= 100;

        let url = '/discount';
        if(props.discount.id) {
            url += '/'+props.discount.id;
        }else{
            data.subscription_id = props.subscription.id;
        }
        AP.post(url,data).then(function(res){

            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            props.onFinish(res);

        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    props.form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });
    }
    function deleteForm(){

        if(!props.discount.id) return false;

        let updateInvoice = true;

        confirm({
            title: 'Are you sure you want to delete the discount',
            icon: <ExclamationCircleOutlined />,
            onOk() {

                setIsSaving(true);

                let url = '/discount/'+props.discount.id+'?updateInvoice='+updateInvoice;

                AP.delete(url).then(function(res){

                    notification.success({
                        message: `Deleted`,
                        placement: 'bottomRight',
                        duration:1,
                        closeIcon : (<div></div>)
                    });


                    setIsSaving(false);
                    props.onFinish({id:props.discount.id,delete:true});

                }).catch(function(res){
                    setIsSaving(false);

                    if(res.response.data.errors) {
                        let errorList = {};
                        for (let error of res.response.data.errors) {
                            if (!errorList[error.input]) errorList[error.input] = [];
                            errorList[error.input].push(error.message);
                        }
                        for (let [input, errors] of Object.entries(errorList)) {
                            props.form.setFields([
                                {
                                    name: input,
                                    errors: errors,
                                },
                            ]);
                        }
                    }
                });



            },
            onCancel() {
                return;
            },
        });



    }

    function importCoupon(id){
        for(const c of coupons){
            if(c.id == id){
                const data = props.form.getFieldsValue();
                data.discount = c.discount;
                data.name = c.name;
                data.type = c.type;
                data.usages = c.uses;
                props.form.setFieldsValue(data);
                return;
            }
        }
    }

    return(
        <Form
            {...formItemLayout}
            form={props.form}
            name="discount"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                label="Import From Coupon"
                name="coupon_id"
            >
                <Select
                    onChange={importCoupon}
                >
                    {coupons.map((coupon) => (
                        <Option value={coupon.id}>{coupon.name}</Option>
                    ))}

                </Select>
            </Form.Item>

            <div style={{width:"100%", height:11, borderBottom:"1px solid #b3b3b3", textAlign:"center", marginBottom:30}}>
              <span style={{fontSize:15, backgroundColor:"white", padding:"0 10px"}}>
                OR
              </span>
            </div>


            <Form.Item
                name="name"
                label="Name"
            >
                <Input />
            </Form.Item>

            {false &&
            <Form.Item
                name="affects"
                label="Affects"
            >
                <Select
                    placeholder="Select a option and change input text above"
                >
                    <Option value="">All Payment Periods</Option>
                    <Option value="monthly">Monthly Payment Period</Option>
                    <Option value="yearly">Yearly Payment Period</Option>
                </Select>
            </Form.Item>
            }

            <Form.Item
                name="type"
                label="Discount Type"
                rules={[
                    {
                        required: true,
                        message: 'Please select a code',
                    }
                ]}
            >
                <Select
                    placeholder="Select a option and change input text above"
					onChange={function(e){setDiscountType(e);}}
                >
                    <Option value="percent">Total Percent</Option>
                    <Option value="fixed percent">Fixed Percent</Option>
					<Option value="fixed">Fixed Amount</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="discount"
                label={"Discount "+((discountType=='fixed')?'Amount':'Percent')}
                rules={[
                    {
                        required: true,
                        message: 'Please select a code',
                    }
                ]}
            >
				<Input addonBefore={discountType==="fixed"?"$":null} addonAfter={discountType!=="fixed"?"%":null} disabled={!discountType}/>
            </Form.Item>

			{!!discountType && discountType != 'percent' && <>
                <Form.Item
                    name="uses"
                    label="# Of Usages"
                >
                    <Input
                        placeholder="Leave empty to have unlimited uses"
                    />
                </Form.Item>

                <Form.Item
                    name="retroactive"
                    label="Applies Retroactively"
                    valuePropName="checked"
                    style={{margin:0}}
                >
                    <Checkbox></Checkbox>
                </Form.Item>
			</>}

        </Form>
	);
}