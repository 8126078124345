import '../App.css';
import {useEffect, useState} from 'react';
import {BrowserRouter, NavLink , Route, Switch } from "react-router-dom";
import useWindowDimensions from "../components/useWindowDimensions";
import Footer from "../layout/footer";
import useToken from '../hooks/useToken';
import { Menu, Button, Drawer, Radio, Space } from 'antd';
import Routes from "../components/Routes";

const { SubMenu } = Menu;


function FrontLayout(props) {
	const { user, setToken } = useToken();
    const [menuStyle, setMenuStyle] = useState("light");


    let href=window.location.pathname.split('/');


    let selectedSubMenu = [];

    if(href.length>1){
        selectedSubMenu = [href[0]];
        href = [href[1]];
    }

    const subdomain = window.location.host.split('.')[0];

    useEffect(() => {
        if(['about','signUp','certificate','insurance','advisory-board','code-of-conduct','enrol','login','nutritionists'].includes(href[0])){
            setMenuStyle('light');
        }
        else if(subdomain == 'enrol'){
            setMenuStyle('light');
        }
    }, []);
    //logo-inner.png

    const { height, width } = useWindowDimensions();
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const domainBits = window.location.host.split('.');

    let baseUrl;
    if(domainBits[0] == 'localhost:3000'){
        baseUrl = window.location.protocol+'//localhost:3000';
    }
    else if(domainBits[0] == 'enrol')    {
        baseUrl = window.location.protocol+'//member.'+domainBits[1]+'.'+domainBits[2];
    }else{
        baseUrl = '';
    }

    function CustLink(props){

        if(domainBits[0] == 'enrol'){
            return <a href={baseUrl+props.to}>{props.children}</a>
        }

        return <NavLink {...props}>{props.children}</NavLink>
    }


    const menuHtml = <>

        <Menu.Item key="" style={{marginLeft:"auto"}}>
            {false &&
            <NavLink exact to="/" activeClassName="current" onClick={() => setMenuStyle('clear')}>
                <span>Home</span>
            </NavLink>
            }
            <a href="https://www.sportsnutritionassociation.com">
                <span>Home</span>
            </a>
        </Menu.Item>

        <SubMenu key="about" title={<span>About <i className=" fas fa-angle-down"/></span>}>
            <Menu.Item key="about">
                <a href="https://www.sportsnutritionassociation.com/about">
                    <span>About Us</span>
                </a>
            </Menu.Item>
            <Menu.Item key="Certificate">
                {false &&
                <NavLink exact to="/certificate" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
                    <span>About The Certificate</span>
                </NavLink>
                }
                <a href="https://www.sportsnutritionassociation.com/certificate-in-applied-sports-nutrition">
                    <span>About The Certificate</span>
                </a>
            </Menu.Item>
            <Menu.Item key="advisoryBoard">
                {false &&
                <NavLink exact to="/advisory-board" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
                    <span>Advisory Board</span>
                </NavLink>
                }
                <a href="https://www.sportsnutritionassociation.com/advisory-board">
                    <span>Advisory Board</span>
                </a>
            </Menu.Item>
            <Menu.Item key="insurance">
                {false &&
                <NavLink exact to="/insurance" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
                    <span>Insurance and Registration</span>
                </NavLink>
                }
                <a href="https://www.sportsnutritionassociation.com/insurance-and-registration">
                    <span>Insurance and Registration</span>
                </a>
            </Menu.Item>
            <Menu.Item key="findNutritionist">
                {false &&
                <a target="_blank" rel="noopener" href="https://www.sportsnutritionistregistry.com/">
                    <span>Find a Sports Nutritionist</span>
                </a>
                }
                <a href="https://www.sportsnutritionistregistry.com">
                    <span>Find a Sports Nutritionist</span>
                </a>
            </Menu.Item>
            <Menu.Item key="events">
                <a href="https://www.sportsnutritionassociation.com/events-conference-seminars">
                    <span>Events</span>
                </a>
            </Menu.Item>
        </SubMenu>

        <SubMenu key="SubMenu" title={<span>Members <i className=" fas fa-angle-down"/></span>}>

			<Menu.Item key="becomeMember">
				<CustLink exact to="/signUp" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
					<span>Become A Member</span>
				</CustLink>
			</Menu.Item>

            <Menu.Item key="codeOfConduct">
                {false &&
                <NavLink exact to="/code-of-conduct" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
                    <span>Code of Conduct</span>
                </NavLink>
                }
                <a href="https://www.sportsnutritionassociation.com/code-of-conduct">
                    <span>Code of Conduct</span>
                </a>
            </Menu.Item>
			<Menu.Item key="ProvisionalPeriod">
				{false &&
				<NavLink exact to="/provisional-period" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
					<span>3-Year Provisional Period</span>
				</NavLink>
				}
				<a href="https://www.sportsnutritionassociation.com/accreditation-levels">
					<span>Accreditation Level</span>
				</a>
			</Menu.Item>
			<Menu.Item key="TermsAConditions">
				{false &&
				<NavLink exact to="/terms" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
					<span>Terms & Conditions</span>
				</NavLink>
				}
				<a href="https://www.sportsnutritionassociation.com/terms">
					<span>Terms & Conditions</span>
				</a>
			</Menu.Item>
			<Menu.Item key="TermsAConditions">
				<a href="https://www.sportsnutritionistregistry.com/" target="_blank">
					<span>Find A Sports Nutritionist</span>
				</a>
			</Menu.Item>
        </SubMenu>

        <Menu.Item key="a">
            {false &&
            <NavLink exact to="/contact" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
                <span>Contact</span>
            </NavLink>
            }
            <a href="https://www.sportsnutritionassociation.com/contact">
                <span>Contact</span>
            </a>
        </Menu.Item>

        {user &&
        <Menu.Item key="a">
            <NavLink exact to="/a" activeClassName="current" onClick={() => setMenuStyle('clear')}>
                <span>Members Area</span>
            </NavLink>
        </Menu.Item>
        }

        {false &&
        <Menu.Item key="nutritionists">
            <NavLink exact to="/nutritionists" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
                <span>Nutritionists</span>
            </NavLink>
        </Menu.Item>
        }
        {user && <>
            <Menu.Item key="login">
                <a href={baseUrl+"/dashboard"} activeClassName="current">
                    <span style={{background:"black", borderRadius:30, padding:"10px 15px", margin:"0 10px", color:"#fff", transition:"all 450ms", display:"block", height:38, lineHeight:"15px"}}>Members Area</span>
                </a>
            </Menu.Item>
        </>}
        {!user && <>
            <Menu.Item key="login">
                <CustLink exact to="/login" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
                    <span style={{margin:"0 25px"}}>Member Login</span>
                </CustLink>
                </Menu.Item>
            <Menu.Item key="studentLogin">
                <a href="https://sso.teachable.com/secure/158227/identity/login" style={{margin:"0 25px"}} target="_blank">
                    <span>Student Login</span>
                </a>
            </Menu.Item>



			<Menu.Item key="enrol" style={{marginLeft:"auto"}}>
				<CustLink exact to="/enrol" activeClassName="current" onClick={function(){setMenuStyle('light'); onClose()}}>
					<span style={{background:"#00a3fa", borderRadius:"30px", padding:"20px 35px", margin:"0 10px", color:"#fff", transition:"all 450ms",    display:"block", height:0, lineHeight:"5px"}}>Enrol Online</span>
				</CustLink>
			</Menu.Item>
        </>}
    </>

	return (
		<BrowserRouter>
            <div className="wrapper_inner">
                <header className={"scroll_header_top_area scrolled_not_transparent header_style_on_scroll page_header "+menuStyle} style={{"position":"absolute", "justifyContent":"center","display":"flex"}}>
                    <div className="header_inner clearfix" style={{"flex":"1"}}>

                        <div className="header_top_bottom_holder">
                            <div className="header_bottom clearfix" style={{"backgroundColor":"transparent"}}>
                                <div className="container" style={{display:"flex", height:"100px"}}>


                                    <div className="mainNavLogoContainer">
                                        <a href="/" className={"navLogo"}>
                                        </a>
                                    </div>
                                    <div style={{flex:"1", display:"flex"}}>
                                        {width > 1110 &&
                                        <Menu
                                            defaultSelectedKeys={href}
                                            mode="horizontal"
                                            overflowedIndicator={<i className="fas fa-bars" style={{fontSize: 40}}/>}
                                            theme="clear"
                                            className="main_menu"
                                            style={{
                                                position: "relative",
                                                left: "auto",
                                                float: "right",
                                                margin: "25px",
                                                flex: 1,
                                                //fontWeight: "bold"
                                            }}
                                        >
                                            {menuHtml}
                                        </Menu>
                                        }

                                        {width <= 1110 &&
                                            <>
                                                <div style={{
                                                    position:"fixed",
                                                    right:-49,
                                                    backgroundColor:"white",
                                                    top:20,
                                                    border:"1px solid lightgrey",
                                                    borderTopLeftRadius:"10px",
                                                    borderBottomLeftRadius:"10px"
                                                }}>
                                                    <Button
                                                        type="text"
                                                        style={{height:60}}
                                                        onClick={showDrawer}
                                                    ><i className="fas fa-bars" style={{fontSize: 40}}/></Button>
                                                </div>
                                                <Drawer
                                                    placement="left"
                                                    closable={false}
                                                    onClose={onClose}
                                                    visible={visible}
                                                    className="main_menu_drawer"
                                                    width={320}
                                                >
                                                    <a href="/" className="navLogo"></a>
                                                    <Menu
                                                        defaultSelectedKeys={href}
                                                        mode="vertical"
                                                        theme="clear"
                                                        style={{textAlign:"center",paddingTop:20}}
                                                    >
                                                        {menuHtml}

                                                    </Menu>
                                                </Drawer>

                                                {/*
                                                display: block;
                                                height: 40px;
                                                line-height: 0px;
                                                */}

                                            </>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="content">
                    <Routes/>
                </div>
                <Footer></Footer>
            </div>


            <link rel="stylesheet" id="gforms_reset_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formreset.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_formsmain_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/formsmain.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_ready_class_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/readyclass.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="gforms_browsers_css-css" href="https://www.sportsnutritionassociation.com/wp-content/plugins/gravityforms/legacy/css/browsers.min.css?ver=2.5.9" media="all"/>
            <link rel="stylesheet" id="global-styles-inline-css" href="/css/global-styles-inline.css"></link>
            {false && <link rel="stylesheet" id="other-css" href="https://www.sportsnutritionassociation.com/wp-content/themes/scsnassoc/assets/others.css?ver=170601.1.0.2" media="all"/>}
            <link rel="stylesheet" id="theme-css" href="/css/wp_style.css" media="all"/>


		</BrowserRouter>
	);
}

export default FrontLayout;



