import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin,
    Tag
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";




const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function CouponForm(props) {

	const AP = API2();
    const [form] = Form.useForm();
    const [user, setUser] = useState(props.user);
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [userType, setUserType] = useState([]);
	const [discountType, setDiscountType] = useState(props.coupon.type);
    const [isSaving, setIsSaving] = useState(false);
	const [inPlans, setInPlans] = useState([]);
    const history = useHistory();

    const setBreadCrumbs = useContext(BreadCrumbContext);

    useEffect(() => {

        if(props.coupon.id){
            let data = props.coupon;

            if(props.coupon.type=="fixed" && props.coupon.discount) data.discount /= 100;

            //remove trailing zeros
			const floatDiscount = parseFloat(props.coupon.discount);
			if(props.coupon.discount == floatDiscount){
				props.coupon.discount = floatDiscount;
			}

            /*
            if(props.coupon.plan_coupon_maps) {
                let planIds = [];
                for (let pcm of props.coupon.plan_coupon_maps) {
                    planIds.push(pcm.plan_id);
                }
                data.planIds = planIds;
            }
            */

            setInPlans(props.coupon.plan_options);

            form.setFieldsValue(data);
        }
    }, [props.coupon]);

    function saveForm(data){
        setIsSaving(true);

        if(data.type=="fixed" && data.discount) data.discount *= 100;

        let url = '/coupon';
        if(props.coupon.id) {
            url += '/'+props.coupon.id;
        }
        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            history.push("/coupon/"+res.id);
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });;
    }

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="coupon"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'Please select a type',
                    }
                ]}
            >
                <Input style={{width:400}}/>
            </Form.Item>

            <Form.Item
                name="code"
                label="Code"
                rules={[
                    {
                        required: true,
                        message: 'Please select a code',
                    }
                ]}
            >
                <Input style={{width:200}}/>
            </Form.Item>

            <Form.Item
                name="type"
                label="Discount Type"
                rules={[
                    {
                        required: true,
                        message: 'Please select a code',
                    }
                ]}
            >
                <Select
					onChange={function(e){setDiscountType(e);}}
                    placeholder="Select a option and change input text above"
					style={{width:200}}
                >
					<Option value="percent">Total Percent</Option>
					<Option value="fixed percent">Fixed Percent</Option>
					<Option value="fixed">Fixed Amount</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="discount"
                label="Discount Amount"
				label={"Discount "+((discountType=='fixed')?'Amount':'Percent')}
                rules={[
                    {
                        required: true,
                        message: 'Please select a code',
                    }
                ]}
            >
				<Input
                    addonBefore={discountType==="fixed"?"$":null}
                    addonAfter={discountType!=="fixed"?"%":null}
                    disabled={!discountType}
                    style={{width:200}}
                />
            </Form.Item>

			{!!discountType && discountType != 'percent' && <>
                <Form.Item
                    name="uses"
                    label="# Of Usages"
                >
                    <Input
						style={{width:200}}
                        placeholder="Leave empty to have unlimited uses"
                    />
                </Form.Item>
            </>}

            {props.coupon.id && <>
                <Form.Item
                    label="Plans Using Coupon"
                    style={{paddingTop:20}}
                >
                    <div style={{paddingTop:18, display:"flex", flexWrap:"wrap", gap:5, width:400}}>
                        {inPlans.map((plan) => (
                           <Tag color="processing">{plan.name} - ({plan.for})</Tag>
                        ))}
                    </div>
                </Form.Item>
            </>}


            <div>
                <Button type="primary" htmlType="submit" style={{float:"right"}} loading={isSaving} style={{float:"right"}}>{(isSaving)?'Saving':'Save'}</Button>
            </div>
        </Form>
	);
}