import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {
    Form,Input,
} from 'antd';

export default function AdvisoryBoard(props) {

    const [form] = Form.useForm();
    const history = useHistory();


    function getStartedClicked(data){
        history.push({
            pathname: '/signUp',
            state: data
        });
    }


    return (
        <div className="content_inner_padded" >
            <div className="container">

                <div className="ibn ibn-sb sec">
                    <div className="ebn ud-bn ud-pt obg uix-alter ebn-p9x sec">
                        <div className="cpnw cov xg">
                            <div className="main">
                                <div className="cpn al-g cl-g bg-g wd-g">
                                    <div className="title">
                                        <h1>Advisory Board
                                            <div className="breadcrumb"><span className="separator"> &gt; </span><span
                                                className="current">Advisory Board</span></div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg hs-bg is-basic" data-height="auto" style={{"backgroundImage":"url('https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/adversary-banner.jpg')"}}>
                            <div className="over tpb op01" style={{"opacity":"0.01"}}></div>
                        </div>
                    </div>
                </div>

                <div className="section sec-sc sec basic row-xd sec-rg light sec-blue-strip ud-pad ud-bgc sec-a132994z" style={{"paddingTop":"65px", "paddingBottom":"65px", "backgroundColor":"#01a5f8"}}>
                    <div className="main">
                        <div className="content">
                            <p style={{"text-align":"center"}}>
                                <a href="https://www.sportsnutritionassociation.com/product/annual-registration/" className="button btn-fill btn-normal">
                                    <span>Registration</span>
                                </a>
                                <a href="https://www.sportsnutritionistregistry.com/" className="button btn-normal" target="_blank">
                                    <span>Find A Nutritionist</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="site-wrap ipg iopg ipg-advisory-board sec">
                    <div className="wrap wrap-wd wrap-page gb-sd no-aside sec">
                        <div className="icontent sec">
                            <article className="epc epg">
                                <div className="ientry">
                                    <div className="section sec-sc sec basic row-xd sec-rg sec-team">
                                        <div className="main">
                                            <div className="content">
                                                <h2 className="title tl-sc"><span>Meet Our Team</span></h2>
                                                <h2>The people behind your success</h2>
                                                <div id="ql-a18z" className="sc-teams teamx teamx-wrap">
                                                    <div className="team-lists style-col3">
                                                        <div className="iteam team-1 ui-basic col-x3 grid4 first">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a href="https://www.sportsnutritionassociation.com/team/alex-thomas/">
                                                                    <img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/ALEX-THOMAS-SNA.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/ALEX-THOMAS-SNA.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/ALEX-THOMAS-SNA-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/ALEX-THOMAS-SNA-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/ALEX-THOMAS-SNA-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/alex-thomas/">Alex
                                                                        Thomas</a></h4><p
                                                                        className="position">President &amp; Founder</p>
                                                                        <a href="https://www.sportsnutritionassociation.com/team/alex-thomas/"
                                                                           className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/aussiesportsnutritionist/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="iteam team-2 ui-basic col-x3 grid4">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a
                                                                    href="https://www.sportsnutritionassociation.com/team/eric-helms/"><img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-eric-helms.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-eric-helms.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-eric-helms-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-eric-helms-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-eric-helms-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i
                                                                    className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/eric-helms/">Dr.
                                                                        Eric Helms</a></h4><p
                                                                        className="position">Advisory Board Member</p><a
                                                                        href="https://www.sportsnutritionassociation.com/team/eric-helms/"
                                                                        className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/helms3dmj/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="iteam team-3 ui-basic col-x3 grid4 last">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a
                                                                    href="https://www.sportsnutritionassociation.com/team/shawn-arent/"><img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Shawn-Arent.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Shawn-Arent.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Shawn-Arent-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Shawn-Arent-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Shawn-Arent-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i
                                                                    className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/shawn-arent/">Shawn
                                                                        Arent</a></h4><p className="position">Advisory
                                                                        Board Member</p><a
                                                                        href="https://www.sportsnutritionassociation.com/team/shawn-arent/"
                                                                        className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/shawnarent/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="clr"></div>
                                                        <div className="iteam team-4 ui-basic col-x3 grid4 first">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a
                                                                    href="https://www.sportsnutritionassociation.com/team/dr-joe-klemczewski/"><img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-joe-klemczewski.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-joe-klemczewski.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-joe-klemczewski-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-joe-klemczewski-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/dr-joe-klemczewski-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i
                                                                    className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/dr-joe-klemczewski/">Dr.
                                                                        Joe Klemczewski</a></h4><p
                                                                        className="position">Advisory Board Member</p><a
                                                                        href="https://www.sportsnutritionassociation.com/team/dr-joe-klemczewski/"
                                                                        className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/joeklemczewski/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="iteam team-5 ui-basic col-x3 grid4">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a
                                                                    href="https://www.sportsnutritionassociation.com/team/laurin-conlin/"><img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Lauren-conlin.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Lauren-conlin.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Lauren-conlin-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Lauren-conlin-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/Lauren-conlin-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i
                                                                    className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/laurin-conlin/">Laurin
                                                                        Conlin</a></h4><p className="position">Advisory
                                                                        Board Member</p><a
                                                                        href="https://www.sportsnutritionassociation.com/team/laurin-conlin/"
                                                                        className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/laurinconlin/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="iteam team-6 ui-basic col-x3 grid4 last">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a
                                                                    href="https://www.sportsnutritionassociation.com/team/danny-lennon/"><img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/danny-lennon.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/danny-lennon.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/danny-lennon-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/danny-lennon-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/04/danny-lennon-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i
                                                                    className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/danny-lennon/">Danny
                                                                        Lennon</a></h4><p className="position">Advisory
                                                                        Board Member</p><a
                                                                        href="https://www.sportsnutritionassociation.com/team/danny-lennon/"
                                                                        className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/dannylennon_sigma/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="clr"></div>
                                                        <div className="iteam team-7 ui-basic col-x3 grid4 first">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a
                                                                    href="https://www.sportsnutritionassociation.com/team/dr-cliff-harvey/"><img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/Cliff-Harvey.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/Cliff-Harvey.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/Cliff-Harvey-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/Cliff-Harvey-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/Cliff-Harvey-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i
                                                                    className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/dr-cliff-harvey/">Dr.
                                                                        Cliff Harvey</a></h4><p
                                                                        className="position">Advisory Board Member</p><a
                                                                        href="https://www.sportsnutritionassociation.com/team/dr-cliff-harvey/"
                                                                        className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/cliffdoggydog/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="iteam team-8 ui-basic col-x3 grid4">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a
                                                                    href="https://www.sportsnutritionassociation.com/team/matthew-white/"><img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/matt-white.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/matt-white.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/matt-white-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/matt-white-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/matt-white-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i
                                                                    className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/matthew-white/">Matthew
                                                                        White</a></h4><p className="position">Advisory
                                                                        Board Member</p><a
                                                                        href="https://www.sportsnutritionassociation.com/team/matthew-white/"
                                                                        className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/mattwhite_sportsnutrition/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="iteam team-9 ui-basic col-x3 grid4 last">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a
                                                                    href="https://www.sportsnutritionassociation.com/team/eric-trexler/"><img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/eric-trexler.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/eric-trexler.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/eric-trexler-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/eric-trexler-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/eric-trexler-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i
                                                                    className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/eric-trexler/">Eric
                                                                        Trexler</a></h4><p className="position">Advisory
                                                                        Board Member</p><a
                                                                        href="https://www.sportsnutritionassociation.com/team/eric-trexler/"
                                                                        className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/trexlerfitness/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="clr"></div>
                                                        <div className="iteam team-10 ui-basic col-x3 grid4 first">
                                                            <div className="team-block">
                                                                <div className="thumbs thumbs-team"><a
                                                                    href="https://www.sportsnutritionassociation.com/team/gabrielle-fundaro/"><img
                                                                    width="419" height="418"
                                                                    src="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/gabrielle-fundaro.png"
                                                                    className="attachment-team-thumbs size-team-thumbs wp-post-image"
                                                                    alt="" loading="lazy"
                                                                    srcSet="https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/gabrielle-fundaro.png 419w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/gabrielle-fundaro-300x300.png 300w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/gabrielle-fundaro-271x270.png 271w, https://www.sportsnutritionassociation.com/wp-content/uploads/2021/05/gabrielle-fundaro-200x200.png 200w"
                                                                    sizes="(max-width: 419px) 100vw, 419px"/><span
                                                                    className="over"><i
                                                                    className="icon-link fa fa-link"></i></span></a>
                                                                </div>
                                                                <div className="data-team">
                                                                    <div className="info"><h4 className="name"><a
                                                                        href="https://www.sportsnutritionassociation.com/team/gabrielle-fundaro/">Dr.
                                                                        Gabrielle Fundaro</a></h4><p
                                                                        className="position">Advisory Board Member</p><a
                                                                        href="https://www.sportsnutritionassociation.com/team/gabrielle-fundaro/"
                                                                        className="link">Read More</a></div>
                                                                </div>
                                                                <div className="links links-plain"><a className="s-in"
                                                                                                      href="https://www.instagram.com/vitaminphd/"
                                                                                                      target="Instagram"><i
                                                                    className="fa fa-instagram"></i></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="clr"></div>
                                                    </div>
                                                    <div className="clr"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}